import React, { FC, useState } from 'react';
import Loader from '../loader/Loader';
import { LABEL_CONSTANTS } from '../../constants/common';
import './pieChart.scss';

type pieData = {
  percentage: number;
  message: string;
};

const PieChart: FC<{
  width?: number;
  height?: number;
  loading?: boolean;
  data: pieData[];
}> = ({ width = 128, height = 128, data, loading = false }): JSX.Element => {
  const colors = ['#6B99EE', '#9747FF', '#C42B1C', '#EEA537', '#265388'];
  const [activeSlice, setActiveSlice] = useState<number | undefined>();

  const getPie = (slices: pieData[]) => {
    let offset: number = 0;

    const chart = slices.map((slice, index) => {
      const currentOffset = offset;
      offset += slice.percentage;
      return getSlice(width, height, slice.percentage, currentOffset, index);
    });

    return (
      <svg key={'pie-slice-svg-' + slices.length} className="slice">
        {chart}
      </svg>
    );
  };

  const getSlice = (circleWidth: number, circleHeight: number, percent: number, percentOffset: number, index: number) => {
    const rotationOrigin = -90;
    const degrees = percent >= 100 ? 180 : 360 * ((percent % 50) / 100);
    const degreeOffset = 360 * (percentOffset / 100);

    const centerX = circleWidth / 2;
    const centerY = circleHeight / 2;

    const startX = centerX + centerX * Math.cos(((degreeOffset + rotationOrigin) / 180) * Math.PI);
    const startY = centerY + centerY * Math.sin(((degreeOffset + rotationOrigin) / 180) * Math.PI);
    const endX = centerX + centerX * Math.cos(((degreeOffset + degrees + rotationOrigin) / 180) * Math.PI);
    const endY = centerY + centerY * Math.sin(((degreeOffset + degrees + rotationOrigin) / 180) * Math.PI);

    const path = `M ${centerX},${centerY} L${startX},${startY} A${centerX},${centerY} 1 0,1 ${endX},${endY} z`;

    let semiCircle = '';
    if (percent >= 50) {
      const semiX = centerX + centerX * Math.cos(((degreeOffset + degrees + 180 + rotationOrigin) / 180) * Math.PI);
      const semiY = centerY + centerY * Math.sin(((degreeOffset + degrees + 180 + rotationOrigin) / 180) * Math.PI);
      semiCircle = `M ${centerX},${centerY} L${endX},${endY} A${centerX},${centerY} 1 0,1 ${semiX},${semiY} z`;
    }

    return (
      <React.Fragment key={'slices-' + percent + index}>
        <path
          key={'slice-' + percent + '-' + index}
          d={path}
          onMouseEnter={() => setActiveSlice(index)}
          fill={getColor(index)}
          data-testid={`slice-${index}`}></path>
        <path
          key={'half-slice-' + percent + '-' + index}
          d={semiCircle}
          onMouseEnter={() => setActiveSlice(index)}
          fill={getColor(index)}
          data-testid={`half-slice-${index}`}></path>
      </React.Fragment>
    );
  };

  const getPercentages = (items: pieData[]) => {
    const centerX = width / 2;
    const centerY = height / 2;

    if (items.length === 0) {
      return (
        <div key={'no-items-percentage'} className="percentage" style={{ left: centerX, top: centerY }}>
          <div className="percentage-number">{LABEL_CONSTANTS.NO_ITEMS}</div>
        </div>
      );
    }

    if (items.length === 1) {
      return (
        <div key={'single-item-percentage-' + items[0].percentage} className="percentage" style={{ left: centerX, top: centerY }}>
          <div className="percentage-number">{items[0].percentage.toFixed(0)}%</div>
        </div>
      );
    }

    let offset: number = 0;

    const percentages = items.map((item, index) => {
      const rotationOrigin = -90;
      const degrees = 360 * (item.percentage / 100);

      const distanceFromCenter = item.percentage > 10 ? 0.6 : 0.8;

      const x = centerX + centerX * distanceFromCenter * Math.cos(((offset + degrees / 2 + rotationOrigin) / 180) * Math.PI);
      const y = centerY + centerY * distanceFromCenter * Math.sin(((offset + degrees / 2 + rotationOrigin) / 180) * Math.PI);

      offset += degrees;
      if (item.percentage < 5) {
        return <div key={'item-percentage-' + item.percentage + '-' + index}></div>;
      }

      return (
        <div key={'item-percentage-' + item.percentage + '-' + index} className="percentage" style={{ left: x, top: y }}>
          <div className="percentage-number">{item.percentage.toFixed(1)}%</div>
        </div>
      );
    });
    return <div key={'percentages-of-' + items.map(i => i.percentage + '-')}>{percentages}</div>;
  };

  const getColor = (index: number): string => {
    return index === 0 ? '#91BC7B' : colors[(index - 1) % colors.length];
  };

  return (
    <div
      className="pie-chart"
      style={{ width: width, height: height }}
      onMouseLeave={() => setActiveSlice(undefined)}
      data-testid="pie-chart">
      {loading ? (
        <Loader loaderSize="small" addedSpace simple />
      ) : (
        <>
          {data.map((slice, index) => {
            return (
              <div
                key={'slice-message-' + slice.percentage + '-' + index}
                className="message"
                data-testid="pie-slice"
                style={{ backgroundColor: getColor(index), opacity: index === activeSlice ? 1 : 0 }}>
                {slice.message}
              </div>
            );
          })}
          <div className="pie" style={{ opacity: activeSlice === undefined ? 1 : 0 }}>
            {getPie(data.filter(item => item.percentage > 0))}
            {getPercentages(data.filter(item => item.percentage > 0))}
          </div>
        </>
      )}
    </div>
  );
};

export default PieChart;
