import Modal from '../../../../components/modal/Modal';
import './emailChangeConfirmModal.scss';

export type EmailChangeConfirmModalProps = {
  newEmail: string;
  isOpen: boolean;
  width?: number;
  onClose: () => void;
  onConfirm: () => void;
};

function EmailChangeConfirmModal({ newEmail, isOpen, width = 600, onClose, onConfirm }: EmailChangeConfirmModalProps) {
  return (
    <div className="email-change-confirm-modal">
      <Modal width={width} isOpen={isOpen} onClose={onClose} ignoreBackdrop>
        <div className="modal-content">
          <h2 className="modal-header">{'Confirm Email Change'}</h2>

          <div className="modal-body">
            <p>Your email is your username and an important part of securing your user profile</p>
            <p>
              This change will require a password reset. By confirming this change you will be logged out. An email will be sent
              to <b>{`${newEmail}`}</b> with instructions to reset your password.
            </p>
            <p>
              The new email address will take effect when you have successfully changed your password using the link sent to the
              new email address.
            </p>
          </div>

          <div className="modal-footer">
            <button type="button" className="small inverted green button" onClick={onClose}>
              Cancel
            </button>
            <button type="button" className="small green button" onClick={onConfirm}>
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default EmailChangeConfirmModal;
