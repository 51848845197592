import Modal from '../../components/modal/Modal';
import { TeamManagementUser } from '../../types/interfaces/user.interfaces';

interface RemoveUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleRemove: () => void;
  selectedUser: TeamManagementUser | undefined;
}

const RemoveUserModal = ({ isOpen, onClose, handleRemove, selectedUser }: RemoveUserModalProps) => {
  return (
    <Modal isOpen={isOpen} width={700} onClose={onClose}>
      <div className="manage-team-modal">
        <h4>Remove Team Member</h4>
        <p>Are you sure you want to remove {selectedUser?.fullName} from your team?</p>
        <div className="footer">
          <button onClick={onClose} className="button blue inverted mr-1">
            Cancel
          </button>
          <button onClick={handleRemove} className="button red">
            Remove
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveUserModal;
