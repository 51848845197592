import { createSlice } from '@reduxjs/toolkit';
import { AuthResponse } from '../../types/interfaces/auth.interfaces';
import { decodeJwtToken } from '../../utils/decodeJwtToken';
import { getLocalAccessToken, getLocalRefreshToken, getLocalRefreshTokenExpiry } from '../../utils/localStorageHandler';
import { activeContexts } from '../../types/enums/activeContexts.enum';

const token = getLocalAccessToken() ?? '';

const initialState = {
  appContext: {
    roles: token ? [decodeJwtToken(token)?.role] : [],
    tenantId: '',
    token: token,
    refreshToken: getLocalRefreshToken(),
    refreshTokenExpiry: getLocalRefreshTokenExpiry() as Date | null | undefined,
  },
  accountContext: {
    roles: [],
    tenantId: '',
    token: '',
    refreshToken: '',
    refreshTokenExpiry: undefined,
  },
  activeContext: 'app',
} as { appContext: AuthResponse; accountContext: AuthResponse; activeContext: activeContexts };

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    storeAppAuth: (state, action) => {
      const decodedRole = decodeJwtToken(action.payload.token)?.role;

      state.appContext = {
        roles: decodedRole ? (Array.isArray(decodedRole) ? decodedRole : [decodedRole]) : [],
        tenantId: action.payload.tenantId,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        refreshTokenExpiry: action.payload.refreshTokenExpiry,
      };
    },
    storeAccountAuth: (state, action) => {
      const decodedRole = decodeJwtToken(action.payload.token)?.role;

      state.accountContext = {
        roles: decodedRole ? (Array.isArray(decodedRole) ? decodedRole : [decodedRole]) : [],
        tenantId: action.payload.tenantId,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        refreshTokenExpiry: action.payload.refreshTokenExpiry,
      };
    },

    setActiveContext: (state, action) => {
      state.activeContext = action.payload;
    },

    clearAuthAccountContext: state => {
      state.accountContext = {
        roles: [],
        tenantId: '',
        token: '',
        refreshToken: '',
        refreshTokenExpiry: undefined,
      };
    },
    clearAuth: state => {
      (state.accountContext = {
        roles: [],
        tenantId: '',
        token: '',
        refreshToken: '',
        refreshTokenExpiry: undefined,
      }),
        (state.appContext = {
          roles: [],
          tenantId: '',
          token: '',
          refreshToken: '',
          refreshTokenExpiry: undefined,
        });
    },
  },
});

export const { storeAccountAuth, storeAppAuth, setActiveContext, clearAuthAccountContext, clearAuth } = authSlice.actions;

export default authSlice.reducer;
