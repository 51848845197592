import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';
import InventoryDiscard from '../pages/inventory/InventoryDiscard';
import InventoryItem from '../pages/inventory/InventoryItem';
import InventorySell from '../pages/inventory/InventorySell';
import InventoryUse from '../pages/inventory/InventoryUse';
import InventoryWithdraw from '../pages/inventory/InventoryWithdraw';
import NotAuthorized from '../pages/error-pages/NotAuthorized';
import PageNotFound from '../pages/error-pages/PageNotFound';
import { INVENTORY_PATHS } from '../constants/routePaths';
import { PermissionService } from '../services/permission.service';

type InventoryRoutesProps = {
  permissionService: PermissionService;
};

const InventoryRoutes: FC<InventoryRoutesProps> = ({ permissionService }): JSX.Element => {
  const paymentStatus = useAppSelector(state => state.payment.addedPaymentMethod);
  const activeAccount = useAppSelector(state => state.activeAccount);

  const checkValidity = () => {
    return (
      paymentStatus != undefined &&
      paymentStatus &&
      !(activeAccount?.billingAmount.outstandingBalanceCents > 0 || activeAccount?.billingAmount.penaltyCents > 0) &&
      !activeAccount.locked
    );
  };

  return (
    <div>
      <Routes>
        <Route path={INVENTORY_PATHS.ITEM} element={<InventoryItem />} />
        <Route
          path={INVENTORY_PATHS.ITEM_ + INVENTORY_PATHS.SELL}
          element={permissionService.userHasInventoryPermission() && checkValidity() ? <InventorySell /> : <NotAuthorized />}
        />
        <Route
          path={INVENTORY_PATHS.ITEM_ + INVENTORY_PATHS.WITHDRAW}
          element={permissionService.userHasInventoryPermission() && checkValidity() ? <InventoryWithdraw /> : <NotAuthorized />}
        />
        {/* Change the below route element when we want users to access use transaction url. */}
        <Route
          path={INVENTORY_PATHS.ITEM_ + INVENTORY_PATHS.USE}
          element={permissionService.userHasInventoryPermission() && checkValidity() ? <PageNotFound /> : <NotAuthorized />}
        />
        <Route
          path={INVENTORY_PATHS.ITEM_ + INVENTORY_PATHS.DISCARD}
          element={permissionService.userHasInventoryPermission() && checkValidity() ? <InventoryDiscard /> : <NotAuthorized />}
        />
      </Routes>
    </div>
  );
};

export default InventoryRoutes;
