import { useLocation, useNavigate } from 'react-router-dom';
import BackSVG from '../svgs/Back.svg';
import { ROUTE_PATHS } from '../../constants/routePaths';

interface BackButtonProps {
  state?: any;
  onClick?: () => void;
  pagesBack?: number;
  label?: string;
}

const BackButton = ({ state, onClick, pagesBack = 1, label = 'Back' }: BackButtonProps): JSX.Element => {
  const navigate = useNavigate();

  const location = useLocation();
  const locationState = useLocation().state;
  const splitLocation: string[] = location.pathname.split('/');
  const trimmedDestination = splitLocation.slice(0, 0 - pagesBack).join('/');

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      if (locationState && locationState.returnLocation) {
        navigate(locationState.returnLocation, { state: state });
      } else {
        navigate(trimmedDestination, { state: state });
      }
    }
  };

  return (
    <div className="back-button">
      <button className="button icon" onClick={handleClick} type="button">
        <BackSVG />
      </button>
      {label}
    </div>
  );
};

export default BackButton;
