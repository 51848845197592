import { Route, Routes } from 'react-router-dom';
import { TRANSACTION_MANAGEMENT_PATHS } from '../constants/routePaths';
import TransactionView from '../components/transaction/TransactionView';
import TransactionWithdraw from '../pages/transaction-management/TransactionWithdraw';
import TransactionManagementAdjust from '../pages/transaction-management/TransactionManagementAdjust';
import CreateClientTransaction from '../pages/transaction-management/CreateClientTransaction';

const TransactionManagementRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path={TRANSACTION_MANAGEMENT_PATHS.VIEW} element={<TransactionView />} />
        <Route path={TRANSACTION_MANAGEMENT_PATHS.WITHDRAW} element={<TransactionWithdraw />} />
        <Route path={TRANSACTION_MANAGEMENT_PATHS.ADJUST} element={<TransactionManagementAdjust />} />
        <Route path={TRANSACTION_MANAGEMENT_PATHS.CREATE_CLIENT_TRANSACTION} element={<CreateClientTransaction />} />
      </Routes>
    </div>
  );
};

export default TransactionManagementRoutes;
