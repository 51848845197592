import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../constants/routePaths';
import './errorPages.scss';

const NotAuthorized = () => {
  const navigate = useNavigate();

  return (
    <div className="response-wrapper">
      <div className="response-container not-authorized">
        <h1>403</h1>
        <div className="response-info">
          <h3>Oops!!, You don't have permission to access this page.</h3>
          <p>Sorry but you do not have permission to view this directory or page</p>
          <div className="button-group">
            <button className={'button blue large'} onClick={() => navigate(ROUTE_PATHS.APP_DASHBOARD)}>
              Go To Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotAuthorized;
