export enum TransactionStatusEnum {
  Default = '',
  Complete = 'Complete',
  Pending = 'Pending',
  Hold = 'Hold',
  Canceled = 'Canceled',
  Processed = 'Processed',
}

export enum TransactionResolutionEnum {
  Default = '',
  Received = 'Received',
  Shipped = 'Shipped',
  Pickup = 'Pickup',
  Discarded = 'Discarded',
  Unusable = 'Unusable',
  Deployed = 'Deployed',
}

export enum TransactionTypeEnum {
  Default = '',
  Deposit = 'Deposit',
  Collect = 'Collect',
  Purchase = 'Purchase',
  Use = 'Use',
  Discard = 'Discard',
  Sell = 'Sell',
  Withdraw = 'Withdraw',
  CheckOut = 'CheckOut',
  CheckIn = 'CheckIn',
  Move = 'Move',
  Adjust = 'Adjust',
  Reconcile = 'Reconcile',
}
