import { FC, useState } from 'react';
import Modal from '../../../../components/modal/Modal';
import { ValidatedInput } from '../../../../components';
import { PasswordRequirementsTooltip } from '../../../../components/tooltip/customTooltips';
import { ValidatedState } from '../../../../components/custom-input/ValidatedInput';
import { PASSWORD_VALIDATORS, Validators } from '../../../../types/enums';
import { setNewPassword } from '../../../../api/userApi';
import { showToast } from '../../../../services/toast.service';
import { PromiseError, toastMessages } from '../../../../constants/errorMessages';
import './resetPasswordModal.scss';

export type ResetPasswordModalProps = {
  isOpen: boolean;
  width?: number;
  onClose: () => void;
};

const ResetPasswordModal: FC<ResetPasswordModalProps> = ({ isOpen, width = 600, onClose }) => {
  const [oldPassword, setOldPassword] = useState<ValidatedState>({ value: '', valid: false });
  const [password, setPassword] = useState<ValidatedState>({ value: '', valid: false });
  const [confirmPassword, setConfirmPassword] = useState<ValidatedState>({ value: '', valid: false });
  const [validate, setValidate] = useState<boolean>(false);

  const formIsValid = (): boolean => {
    return password.valid && password.value === confirmPassword.value && oldPassword.valid;
  };

  const resetPassword = async () => {
    setValidate(true);

    if (formIsValid()) {
      try {
        await setNewPassword({ previous: oldPassword.value, new: password.value });
        showToast.success(toastMessages.PASSWORD_RESET_SUCCESS);
      } catch (error: any) {
        if (error.code === PromiseError.BAD_REQUEST) {
          showToast.error(toastMessages.PASSWORD_CANNOT_BE_SAME);
        } else if (error.code === PromiseError.NETWORK) {
          showToast.error(toastMessages.NETWORK);
        } else {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      }
    }
    onClose();
  };

  return (
    <div className="reset-password-modal">
      <Modal isOpen={isOpen} width={width} onClose={onClose} ignoreBackdrop>
        <div className="modal-content">
          <h2 className="modal-header">Reset Password</h2>
          <p className="modal-sub-header">Set a new password for your account.</p>
          <div className="modal-body">
            <div className="input">
              <ValidatedInput
                label="Old Password "
                type="password"
                customTooltip={<PasswordRequirementsTooltip />}
                setValidatedState={setOldPassword}
                validators={[Validators.REQUIRED, ...PASSWORD_VALIDATORS]}
                validate={validate}
              />
            </div>
            <div className="input">
              <ValidatedInput
                label="New Password "
                type="password"
                customTooltip={<PasswordRequirementsTooltip />}
                setValidatedState={setPassword}
                validators={[Validators.REQUIRED, ...PASSWORD_VALIDATORS]}
                validate={validate}
              />
            </div>
            <div className="input">
              <ValidatedInput
                label="Confirm Password "
                type="password"
                customTooltip={<PasswordRequirementsTooltip />}
                setValidatedState={setConfirmPassword}
                validators={[Validators.REQUIRED]}
                validate={validate}
                matchString={password.value}
              />
            </div>
          </div>

          <div className="modal-footer">
            <button onClick={onClose} className="button red">
              Cancel
            </button>
            <button className="button green" disabled={!formIsValid()} onClick={resetPassword}>
              Reset Password
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ResetPasswordModal;
