import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ValidatedInput, { ValidatedState } from '../../components/custom-input/ValidatedInput';
import { PasswordRequirementsTooltip } from '../../components/tooltip/customTooltips';
import { PASSWORD_VALIDATORS, Validators } from '../../types/enums/validators.enum';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { toastMessages } from '../../constants/errorMessages';
import { PASSWORDS_CONSTANTS, RETURN_TO_SIGN_IN } from '../../constants/common';
import { setNewPassword } from '../../api/userApi';
import { showToast } from '../../services/toast.service';

const CreatePassword = () => {
  const [password, setPassword] = useState<ValidatedState>({ valid: true, value: '' });
  const [confirmPassword, setConfirmPassword] = useState<ValidatedState>({ valid: true, value: '' });
  const [validate, setValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    await setNewPassword({ new: confirmPassword.value })
      .then(() => {
        showToast.success(toastMessages.SUCCESSFULLY_SET_NEW_PASSWORD);
        navigate(ROUTE_PATHS.SIGN_IN);
      })
      .catch(() => {
        showToast.error(toastMessages.FAILED_TO_SET_NEW_PASSWORD);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="auth">
      <div className="content-container">
        <div className="content">
          <div className="form">
            <h1 className="welcome">{PASSWORDS_CONSTANTS.CREATE_PASSWORD}</h1>
            <p className="subheader">{PASSWORDS_CONSTANTS.SET_PASSWORD_SUBHEADER}</p>

            <form onSubmit={e => handleSubmit(e)}>
              <div className="input">
                <ValidatedInput
                  label="*Password"
                  type="password"
                  customTooltip={<PasswordRequirementsTooltip />}
                  setValidatedState={setPassword}
                  validators={[Validators.REQUIRED, ...PASSWORD_VALIDATORS]}
                  validate={validate}
                />
              </div>
              <div className="input">
                <ValidatedInput
                  label="*Confirm Password"
                  type="password"
                  customTooltip={<PasswordRequirementsTooltip />}
                  setValidatedState={setConfirmPassword}
                  validators={[Validators.REQUIRED]}
                  validate={validate}
                  matchString={password.value}
                />
              </div>

              <button
                disabled={!(password.valid && password.value === confirmPassword.value) || isLoading}
                type="submit"
                className="green button">
                {PASSWORDS_CONSTANTS.CREATE_PASSWORD}
              </button>
            </form>
            <p className="return-link">
              <Link to={ROUTE_PATHS.SIGN_IN}>{RETURN_TO_SIGN_IN}</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePassword;
