import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ManagementColumns, { ManagementColumnFilterTypes } from '../../components/management-columns/ManagementColumns';
import { FilterOption } from '../../components/custom-input/FilterDropdown';
import OpenSVG from '../../components/svgs/Open.svg';
import AddSiteModal from './AddSiteModal';
import AddTankModal from './AddTankModal';
import AddCanisterModal from './AddCanisterModal';
import EditSiteModal from './EditSiteModal';
import EditTankModal from './EditTankModal';
import EditCanisterModal from './EditCanisterModal';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { getStorageCanisterCount, getStorageCanisters, getStorageSites, getStorageTanks } from '../../api/storageItemsApi';
import { PagedResponse, StorageCanister, StorageSite, StorageTank } from '../../types/interfaces';
import { InventoryManagementCanisterContentsAutoFill } from '../inventory-management/InventoryManagementCanisterContents';
import './locationManagement.scss';

interface StorageSiteOption extends StorageSite {
  storageTankCount?: number;
}

interface StorageTankOption extends StorageTank {
  storageCanisterCount?: number;
}

interface StorageCanisterOption extends StorageCanister {
  storageCount?: number;
}

const LocationManagement: FC = () => {
  const navigate = useNavigate();

  const [totalSites, setTotalSites] = useState<number>();
  const [totalTanks, setTotalTanks] = useState<number>();
  const [totalCanisters, setTotalCanisters] = useState<number>();

  const [showFilter, setShowFilter] = useState<FilterOption<ManagementColumnFilterTypes>>({
    name: 'Active & Inactive',
    value: ManagementColumnFilterTypes.BOTH,
  });

  const [sites, setSites] = useState<StorageSiteOption[]>([]);
  const [selectedSite, setSelectedSite] = useState<StorageSiteOption>();
  const [newSiteId, setNewSiteId] = useState<number>();
  const [siteRefresh, setSiteRefresh] = useState<number>(0);

  const [tanks, setTanks] = useState<StorageTankOption[]>([]);
  const [selectedTank, setSelectedTank] = useState<StorageTankOption>();
  const [newTankId, setNewTankId] = useState<number>();
  const [tankRefresh, setTankRefresh] = useState<number>(0);

  const [canisters, setCanisters] = useState<StorageCanister[]>([]);
  const [selectedCanister, setSelectedCanister] = useState<StorageCanister>();
  const [newCanisterId, setNewCanisterId] = useState<number>();
  const [canisterRefresh, setCanisterRefresh] = useState<number>(0);

  const [offsetSites, setOffsetSites] = useState<number>(0);
  const hasAllSites: React.MutableRefObject<boolean> = useRef<boolean>(false);
  const isLoadingAdditionalSites: React.MutableRefObject<boolean> = useRef<boolean>(false);
  const callNumberSites: React.MutableRefObject<number> = useRef<number>(0);
  const [loadingSites, setLoadingSites] = useState<boolean>(false);

  const [offsetTanks, setOffsetTanks] = useState<number>(0);
  const hasAllTanks: React.MutableRefObject<boolean> = useRef<boolean>(false);
  const isLoadingAdditionalTanks: React.MutableRefObject<boolean> = useRef<boolean>(false);
  const callNumberTanks: React.MutableRefObject<number> = useRef<number>(0);
  const [loadingTanks, setLoadingTanks] = useState<boolean>(false);

  const [offsetCanisters, setOffsetCanisters] = useState<number>(0);
  const hasAllCanisters: React.MutableRefObject<boolean> = useRef<boolean>(false);
  const isLoadingAdditionalCanisters: React.MutableRefObject<boolean> = useRef<boolean>(false);
  const callNumberCanisters: React.MutableRefObject<number> = useRef<number>(0);
  const [loadingCanisters, setLoadingCanisters] = useState<boolean>(false);

  const limit: number = 10;

  useEffect(() => {
    getTotals();
  }, [newSiteId, newTankId, newCanisterId]);

  const getTotals = async () => {
    try {
      const [{ data: sitesResponse }, { data: tanksResponse }, { data: canistersResponse }] = await Promise.all([
        getStorageSites({ limit: 1 }),
        getStorageTanks({ limit: 1 }),
        getStorageCanisters({ limit: 1 }),
      ]);
      setTotalSites((sitesResponse as PagedResponse<StorageSite>).total);
      setTotalTanks((tanksResponse as PagedResponse<StorageTank>).total);
      setTotalCanisters((canistersResponse as PagedResponse<StorageCanister>).total);
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    getSites();
  }, [offsetSites]);

  useEffect(() => {
    getTanks();
  }, [offsetTanks]);

  useEffect(() => {
    getCanisters();
  }, [offsetCanisters]);

  const getSites = async () => {
    if (hasAllSites.current) {
      return;
    }
    setLoadingSites(true);
    callNumberSites.current++;
    const callNum = callNumberSites.current;
    try {
      const { data: storageSites } = await getStorageSites({
        filter: showFilter.value,
        limit: limit,
        offset: offsetSites,
        sort: 'Name',
      });
      if (callNum === callNumberSites.current) {
        const sitesList = (storageSites as PagedResponse<StorageSite>).result;
        const newSites = await Promise.all(
          sitesList.map(async (s: StorageSite) => {
            try {
              const { data: tResponse } = await getStorageTanks({ filter: 'storageSiteId eq ' + s.storageSiteId, limit: 1 });
              return { ...s, storageTankCount: (tResponse as PagedResponse<StorageTank>).total };
            } catch {
              return s;
            }
          }),
        );
        if (callNum === callNumberSites.current) {
          setSites(offsetSites > 0 ? [...sites, ...newSites] : newSites);
          hasAllSites.current = newSites.length === 0;
          setLoadingSites(false);
        }
      }
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const getTanks = async () => {
    if (hasAllTanks.current) {
      return;
    }
    setLoadingTanks(true);
    callNumberTanks.current++;
    const callNum = callNumberTanks.current;
    try {
      if (selectedSite) {
        const { data: storageTanks } = await getStorageTanks({
          filter: showFilter.value + 'storageSiteId eq ' + selectedSite!.storageSiteId.toString(),
          limit: limit,
          offset: offsetTanks,
          sort: 'Name',
        });
        if (callNum === callNumberTanks.current) {
          const tanksList = (storageTanks as PagedResponse<StorageTank>).result;
          const newTanks: StorageTankOption[] = await Promise.all(
            tanksList.map(async (t: StorageTank) => {
              try {
                const { data: cResponse } = await getStorageCanisters({
                  filter: 'storageTankId eq ' + t.storageTankId,
                  limit: 1,
                });
                return { ...t, storageCanisterCount: (cResponse as PagedResponse<StorageCanister>).total };
              } catch {
                return t;
              }
            }),
          );

          if (callNum === callNumberTanks.current) {
            setTanks(offsetTanks > 0 ? [...tanks, ...newTanks] : newTanks);
            hasAllTanks.current = newTanks.length === 0;
            setLoadingTanks(false);
          }
        }
      } else {
        setTanks([]);
      }
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      setSelectedTank(undefined);
      setSelectedCanister(undefined);
      setCanisters([]);
    }
  };

  const getCanisters = async () => {
    if (hasAllCanisters.current) {
      return;
    }
    setLoadingCanisters(true);
    callNumberCanisters.current++;
    const callNum = callNumberCanisters.current;
    try {
      if (selectedTank) {
        const { data: storageCanisters } = await getStorageCanisters({
          filter: showFilter.value + 'storageTankId eq ' + selectedTank!.storageTankId.toString(),
          limit: limit,
          offset: offsetCanisters,
          sort: 'Name',
        });
        if (callNum === callNumberCanisters.current) {
          const canisterList = (storageCanisters as PagedResponse<StorageCanister>).result;
          const newCanisters: StorageCanisterOption[] = await Promise.all(
            canisterList.map(async (t: StorageCanister) => {
              try {
                const { data: cResponse } = await getStorageCanisterCount(t.storageCanisterId);
                return { ...t, storageCount: cResponse };
              } catch {
                return t;
              }
            }),
          );

          setCanisters(offsetCanisters > 0 ? [...canisters, ...newCanisters] : newCanisters);
          hasAllCanisters.current = newCanisters.length === 0;
          setLoadingCanisters(false);
        }
      } else {
        setCanisters([]);
      }
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      setSelectedCanister(undefined);
    }
  };

  useEffect(() => {
    hasAllSites.current = false;
    setSites([]);
    setSelectedSite(undefined);
    if (offsetSites === 0) {
      getSites();
    } else {
      setOffsetSites(0);
    }
  }, [showFilter, siteRefresh]);

  useEffect(() => {
    hasAllTanks.current = false;
    setTanks([]);
    setSelectedTank(undefined);
    if (offsetTanks === 0) {
      getTanks();
    } else {
      setOffsetTanks(0);
    }
  }, [selectedSite, showFilter, tankRefresh]);

  useEffect(() => {
    hasAllCanisters.current = false;
    setCanisters([]);
    setSelectedCanister(undefined);
    if (offsetCanisters === 0) {
      getCanisters();
    } else {
      setOffsetCanisters(0);
    }
  }, [selectedTank, showFilter, canisterRefresh]);

  const loadMoreSites = () => {
    setOffsetSites(offsetSites + limit);
  };

  const loadMoreTanks = () => {
    setOffsetTanks(offsetTanks + limit);
  };

  const loadMoreCanisters = () => {
    setOffsetCanisters(offsetCanisters + limit);
  };

  useEffect(() => {
    isLoadingAdditionalSites.current = false;
  }, [sites]);

  useEffect(() => {
    const getSingleSite = async (id: number) => {
      const { data: storageSites } = await getStorageSites({ filter: `StorageSiteId eq ${id}` });
      if ((storageSites as StorageSite[]).length > 0) {
        const s = (storageSites as StorageSite[])[0];
        setSelectedSite(s);
      }
    };

    if (newSiteId) {
      getSingleSite(newSiteId);
    }
  }, [newSiteId]);

  useEffect(() => {
    isLoadingAdditionalTanks.current = false;
  }, [tanks]);

  useEffect(() => {
    const getSingleTank = async (id: number) => {
      const { data: storageTanks } = await getStorageTanks({
        filter: `StorageTankId eq ${id}`,
      });
      if ((storageTanks as StorageTank[]).length > 0) {
        const s = (storageTanks as StorageTank[])[0];
        setSelectedTank(s);

        setSites(
          sites.map((site: StorageSiteOption) => {
            if (site.storageSiteId === s.storageSiteId) {
              return { ...site, storageTankCount: (site.storageTankCount ?? 0) + 1 };
            }
            return site;
          }),
        );
      }
    };

    if (newTankId) {
      getSingleTank(newTankId);
    }
  }, [newTankId]);

  useEffect(() => {
    isLoadingAdditionalCanisters.current = false;
  }, [canisters]);

  useEffect(() => {
    const getSingleCanister = async (id: number) => {
      const { data: storageCanisters } = await getStorageCanisters({ filter: `StorageCanisterId eq ${id}` });
      if ((storageCanisters as StorageCanister[]).length > 0) {
        const s = (storageCanisters as StorageCanister[])[0];
        setSelectedCanister(s);

        setTanks(
          tanks.map((tank: StorageTankOption) => {
            if (tank.storageTankId === s.storageTankId) {
              return { ...tank, storageCanisterCount: (tank.storageCanisterCount ?? 0) + 1 };
            }
            return tank;
          }),
        );
      }
    };

    if (newCanisterId) {
      getSingleCanister(newCanisterId);
    }
  }, [newCanisterId]);

  const openCanisterContents = (canister: StorageCanister) => {
    if (selectedSite && selectedTank) {
      const autoFill: InventoryManagementCanisterContentsAutoFill = {
        site: { value: selectedSite?.storageSiteId.toString(), name: selectedSite?.name },
        tank: { value: selectedTank?.storageTankId.toString(), name: selectedTank?.name },
        canister: { value: canister.storageCanisterId.toString(), name: canister.name },
      };
      navigate(ROUTE_PATHS.APP_INVENTORY_MANAGEMENT_CANISTER_CONTENTS, { state: autoFill });
    }
  };

  return (
    <div className="location-management">
      <ManagementColumns
        header="Location Info"
        filter={showFilter}
        setFilter={setShowFilter}
        headerStats={[
          { value: totalSites, label: 'Sites' },
          { value: totalTanks, label: 'Tanks' },
          { value: totalCanisters, label: 'Canisters' },
        ]}
        columns={[
          {
            header: 'Sites',
            items: sites,
            selectedItemId: selectedSite?.storageSiteId,
            getItemId: (item: StorageSite) => item.storageSiteId,
            getItemAddModal: (close: () => void) => (
              <AddSiteModal
                close={() => {
                  setSiteRefresh(p => p + 1);
                  close();
                }}
                setSelectedId={setNewSiteId}
              />
            ),
            addItemButton: { label: 'Site' },
            getItemLabel: (site: StorageSiteOption) => (
              <>
                <span className={site.active ? '' : 'inactive'}>
                  {site.name}
                  {!site.active && <>&emsp;(Inactive)</>}
                </span>
                <span>{site.storageTankCount !== undefined && site.storageTankCount > 0 && <>({site.storageTankCount})</>}</span>
              </>
            ),
            itemSelect: site => setSelectedSite(site),
            getItemEditModal: (close: () => void, site: StorageSite) => (
              <EditSiteModal
                site={site}
                close={() => {
                  setSiteRefresh(p => p + 1);
                  close();
                }}
              />
            ),
            scroll: () => {
              if (!isLoadingAdditionalSites.current) {
                isLoadingAdditionalSites.current = true;
                loadMoreSites();
              }
            },
            loading: loadingSites,
          },
          {
            header: 'Tanks',
            items: tanks,
            selectedItemId: selectedTank?.storageTankId,
            getItemId: (item: StorageTank) => item.storageTankId,
            getItemAddModal: (close: () => void) => (
              <AddTankModal
                close={() => {
                  setTankRefresh(p => p + 1);
                  close();
                }}
                site={selectedSite!}
                setSelectedId={setNewTankId}
              />
            ),
            addItemButton: { label: 'Tank', disabled: selectedSite === undefined },
            getItemLabel: (tank: StorageTankOption) => (
              <>
                <span className={tank.active ? '' : 'inactive'}>
                  {tank.name}
                  {!tank.active && <>&emsp;(Inactive)</>}
                </span>
                <span>
                  {tank.storageCanisterCount !== undefined && tank.storageCanisterCount > 0 && <>({tank.storageCanisterCount})</>}
                </span>
              </>
            ),
            itemSelect: tank => setSelectedTank(tank),
            getItemEditModal: (close: () => void, tank: StorageTank) => (
              <EditTankModal
                close={() => {
                  setTankRefresh(p => p + 1);
                  close();
                }}
                site={selectedSite!}
                tank={tank}
              />
            ),
            scroll: () => {
              if (!isLoadingAdditionalTanks.current) {
                isLoadingAdditionalTanks.current = true;
                loadMoreTanks();
              }
            },
            loading: loadingTanks && selectedSite !== undefined,
          },
          {
            header: 'Canisters',
            items: canisters,
            selectedItemId: selectedCanister?.storageCanisterId,
            getItemId: (item: StorageCanister) => item.storageCanisterId,
            getItemAddModal: (close: () => void) => (
              <AddCanisterModal
                close={() => {
                  setCanisterRefresh(p => p + 1);
                  close();
                }}
                site={selectedSite!}
                tank={selectedTank!}
                setSelectedId={setNewCanisterId}
              />
            ),
            addItemButton: { label: 'Canister', disabled: selectedSite === undefined || selectedTank === undefined },
            getItemLabel: (canister: StorageCanisterOption) => (
              <>
                <span className={canister.active ? '' : 'inactive'}>
                  {canister.name}
                  {!canister.active && <>&emsp;(Inactive)</>}
                </span>
                <span className="canister-contents-label">
                  {canister.storageCount !== undefined && canister.storageCount > 0 && <>({canister.storageCount})</>}

                  <button
                    className="icon button"
                    onClick={() => {
                      openCanisterContents(canister);
                    }}>
                    <OpenSVG />
                  </button>
                </span>
              </>
            ),
            itemSelect: canister => setSelectedCanister(canister),
            getItemEditModal: (close: () => void, canister: StorageCanister) => (
              <EditCanisterModal
                close={() => {
                  setCanisterRefresh(p => p + 1);
                  close();
                }}
                site={selectedSite!}
                tank={selectedTank!}
                canister={canister}
              />
            ),
            scroll: () => {
              if (!isLoadingAdditionalCanisters.current) {
                isLoadingAdditionalCanisters.current = true;
                loadMoreCanisters();
              }
            },
            loading: loadingCanisters && selectedTank !== undefined,
          },
        ]}
      />
    </div>
  );
};

export default LocationManagement;
