import { useCallback, useEffect, useState } from 'react';
import { AccountSearch, FilterDropdown, Loader, NumberInput } from '../../components';
import OverrideQuantityModal from './check-out/check-out-components/OverrideQuantityModal';
import { FilterOption } from '../../components/custom-input/FilterDropdown';
import { dateFormatMMDDYYYY, redirectToTopOfThePage } from '../../utils/commonUtils';
import { Account, Animal, SpecimenInventory, SpecimenLocation, selectOption } from '../../types/interfaces';
import { getAccountAnimals } from '../../api/animalsApi';
import { getSpecimensByOwnership } from '../../api/specimensApi';
import { adminAccountUse } from '../../api/inventoryApi';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';
import {
  ANIMAL_CONSTANTS,
  BUTTON_CONSTANTS,
  LABEL_CONSTANTS,
  PAGE_HEADER_CONSTANTS,
  TABLE_HEADER_CONSTANTS,
} from '../../constants/common';
import './inventoryManagement.scss';

const InventoryManagementClinicUse = (): JSX.Element => {
  const initialState = { value: '', name: '' };

  const [animalOptions, setAnimalOptions] = useState<FilterOption<Animal>[]>([]);
  const [childKey, setChildKey] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSpecimens, setIsLoadingSpecimens] = useState<boolean>(false);
  const [isOverrideModalOpen, setIsOverrideModalOpen] = useState<boolean>(false);
  const [locationUseAmounts, setLocationUseAmounts] = useState<{ location: SpecimenLocation; amount: number }[]>([]);
  const [notes, setNotes] = useState<string>('');
  const [selectedAccount, setSelectedAccount] = useState<Account>();
  const [selectedAnimal, setSelectedAnimal] = useState<FilterOption<Animal>>();
  const [selectedSpecimenIndex, setSelectedSpecimenIndex] = useState<number>(-1);
  const [selectedSpecimenValue, setSelectedSpecimenValue] = useState<selectOption>(initialState);
  const [specimenOptions, setSpecimenOptions] = useState<selectOption[]>([initialState]);
  const [specimens, setSpecimens] = useState<SpecimenInventory[]>([]);
  const [specimenTotalUseQuantity, setSpecimenTotalUseQuantity] = useState<{ specimen: SpecimenInventory; quantity: number }>();
  const [usingInventory, setUsingInventory] = useState<boolean>(true);

  const totalLocationUseAmounts = locationUseAmounts.reduce((sum, location) => sum + location.amount, 0);

  const isValid = () => {
    return (
      totalLocationUseAmounts === specimenTotalUseQuantity?.quantity &&
      selectedAccount?.accountId &&
      totalLocationUseAmounts !== 0 &&
      totalLocationUseAmounts <= specimenTotalUseQuantity.specimen.quantityOwned &&
      locationUseAmounts.length > 0 &&
      locationUseAmounts.every(location => location.amount <= location.location.quantity)
    );
  };

  const handleSubmit = async () => {
    if (!usingInventory) return;

    const hasQuantityMismatch =
      specimenTotalUseQuantity &&
      (specimenTotalUseQuantity.quantity > 0 || totalLocationUseAmounts > 0) &&
      totalLocationUseAmounts !== specimenTotalUseQuantity?.quantity;

    if (isValid()) {
      setUsingInventory(false);

      try {
        setIsLoading(true);
        setIsOverrideModalOpen(false);

        const specimens = locationUseAmounts
          .filter(use => use.amount > 0)
          .map(location => ({
            specimenId: location.location.specimenId!,
            selectedCanisterId: location.location.storageCanisterId,
            quantityUsed: location.amount,
          }));

        await adminAccountUse(selectedAccount?.accountId!, { specimens, notes });
        showToast.success(toastMessages.SUCCESS);
        redirectToTopOfThePage();
        clearForm();
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setUsingInventory(true);
        setIsLoading(false);
      }
    } else if (hasQuantityMismatch) {
      setUsingInventory(true);
      showToast.error(toastMessages.TOTAL_QTY_MISMATCH);
    }
  };

  const clearForm = () => {
    setSelectedAccount(undefined);
    setSelectedAnimal(undefined);
    setAnimalOptions([]);
    setSpecimens([]);
    setChildKey(childKey + 1);
    setNotes('');
    setSpecimenTotalUseQuantity(undefined);
    setLocationUseAmounts([]);
  };

  useEffect(() => {
    const createFilterOptions = (animals: Animal[]) => {
      return animals.map((animal: Animal): FilterOption<Animal> => ({ name: `${animal.code}-${animal.name}`, value: animal }));
    };

    const resetAnimalSelection = () => {
      setAnimalOptions([]);
      setSelectedAnimal(undefined);
      setLocationUseAmounts([]);
    };

    const getOwnedAnimals = async () => {
      resetAnimalSelection();
      if (selectedAccount?.accountId) {
        try {
          const { data } = await getAccountAnimals(selectedAccount.accountId as number, {
            include: 'Breed.Species, Specimens, Owners',
          });
          setAnimalOptions(createFilterOptions(data as Animal[]));
        } catch {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      }
    };
    getOwnedAnimals();
  }, [selectedAccount]);

  useEffect(() => {
    const getSpecimens = async () => {
      if (selectedAnimal?.value?.animalId && selectedAccount?.accountId) {
        setIsLoadingSpecimens(true);
        try {
          setSelectedSpecimenValue(initialState);
          setSpecimenTotalUseQuantity(undefined);
          setLocationUseAmounts([]);
          const params = { include: 'Animal, SpecimenLocation' };
          const { data } = await getSpecimensByOwnership(selectedAnimal.value.animalId, selectedAccount.accountId, params);
          setSpecimens(data);
          setSpecimenOptions(
            data.map((specimen: SpecimenInventory, index) => {
              return {
                name: `${specimen.animal?.code}-${specimen.animal?.name}-  ${new Date(
                  specimen.freezeDate.toString() as string,
                ).toLocaleDateString()}- ${specimen.specimenId}`,
                value: `${index}`,
              };
            }),
          );
        } catch {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
        setIsLoadingSpecimens(false);
      }
    };
    getSpecimens();
  }, [selectedAnimal]);

  useEffect(() => {
    if (specimenTotalUseQuantity?.specimen?.specimenLocations)
      setLocationUseAmounts(
        specimenTotalUseQuantity?.specimen?.specimenLocations.map(location => ({ location: location, amount: 0 })) ?? [],
      );
  }, [specimenTotalUseQuantity?.specimen]);

  const handleSpecimenRowClass = (specimen: SpecimenInventory): string => {
    if (!specimenTotalUseQuantity || specimen.specimenId !== specimenTotalUseQuantity.specimen.specimenId) {
      return 'specimen-row';
    }

    const { quantity, specimen: selectedSpecimen } = specimenTotalUseQuantity;
    const isOverOwnedQuantity = quantity > selectedSpecimen.quantityOwned;
    const isOverAvailableQuantity = quantity > selectedSpecimen.quantityAvailable;

    if (isOverOwnedQuantity) {
      showToast.error(toastMessages.CHOSE_MORE_THAN_OWNED);
      return 'specimen-row invalid-row';
    }
    if (isOverAvailableQuantity) {
      return 'specimen-row override-row';
    }
    return 'specimen-row';
  };

  const handleLocationRowClass = useCallback(
    (location: SpecimenLocation): string => {
      const current = locationUseAmounts.find(
        locationAmount => locationAmount.location.storageCanisterId === location.storageCanisterId,
      );
      if (current && current?.amount > location.quantity) {
        showToast.error(toastMessages.CHOSE_MORE_THAN_TOTAL);
        return 'specimen-row invalid-row';
      } else {
        return 'specimen-row';
      }
    },
    [specimenTotalUseQuantity?.specimen, locationUseAmounts],
  );

  const handleSpecimenLocationAmountChange = (id: number, newAmount: number) => {
    setLocationUseAmounts(prev =>
      prev.map(location => (location.location.storageCanisterId === id ? { ...location, amount: newAmount } : location)),
    );
  };

  const renderLocationTableRows = (location: SpecimenLocation) => {
    const isTravelingTank = location.storageCanister?.storageTank?.isTravelingTank;
    const rowClass = `${handleLocationRowClass(location)} ${isTravelingTank ? 'disabled' : ''}`;
    const locationPath = `${location.storageCanister?.storageTank?.storageSite?.name}/${location.storageCanister?.storageTank?.name}/${location.storageCanister?.name}`;
    const animalDetails = `${selectedAnimal?.value.code} - ${dateFormatMMDDYYYY(specimenTotalUseQuantity?.specimen.freezeDate)}`;

    return (
      <tr className={rowClass} key={'specimen-row-' + location?.storageCanisterId}>
        <td>{locationPath}</td>
        <td>{animalDetails}</td>
        <td>{location.quantity}</td>
        <td>
          {isTravelingTank ? (
            LABEL_CONSTANTS.TRAVELING_TANK
          ) : (
            <NumberInput
              onChange={e => handleSpecimenLocationAmountChange(location.storageCanisterId, Number(e.target.value))}
              value={
                locationUseAmounts
                  .find(locationAmount => locationAmount.location.storageCanisterId === location.storageCanisterId)
                  ?.amount.toString() ?? '0'
              }
            />
          )}
        </td>
      </tr>
    );
  };

  const renderLocationMobileRow = (location: SpecimenLocation) => {
    const isTravelingTank = location.storageCanister?.storageTank?.isTravelingTank;
    const locationPath = `${location.storageCanister?.storageTank?.storageSite?.name} / ${location.storageCanister?.storageTank?.name} / ${location.storageCanister?.name}`;
    const animalDetails = `${selectedAnimal?.value.code} - ${dateFormatMMDDYYYY(specimenTotalUseQuantity?.specimen.freezeDate)}`;

    return (
      <div className="selected-storage-location" key={'specimen-row-' + location?.storageCanisterId}>
        <div className={`card max-width ${isTravelingTank ? 'disabled' : ''}`}>
          <div className="body">
            <div className="body-content">
              <div className="selected-location">
                <label>{TABLE_HEADER_CONSTANTS.LOCATION}:</label>
                <label>{locationPath}</label>
              </div>
              <div className="location-item">
                <label>{TABLE_HEADER_CONSTANTS.ITEM}:</label>
                <label>{animalDetails}</label>
              </div>
            </div>
            <hr />
            <div className="body-content">
              <div className="canister-qty">
                <label>{TABLE_HEADER_CONSTANTS.QTY_IN_CANISTER}</label>
                <label>{location.quantity}</label>
              </div>
              <div className="actual-quantity">
                {isTravelingTank ? (
                  <label>{LABEL_CONSTANTS.TRAVELING_TANK}</label>
                ) : (
                  <>
                    <label>{TABLE_HEADER_CONSTANTS.USE_QTY}</label>
                    <NumberInput
                      onChange={e => handleSpecimenLocationAmountChange(location.storageCanisterId, Number(e.target.value))}
                      value={
                        locationUseAmounts
                          .find(locationAmount => locationAmount.location.storageCanisterId === location.storageCanisterId)
                          ?.amount.toString() ?? '0'
                      }
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="inventory-management inventory-management-clinic-use">
      <div className="inventory-action card">
        {isLoading && <Loader pageLoader loaderSize={'medium'} simple />}
        <OverrideQuantityModal
          showModal={isOverrideModalOpen}
          setShowModal={setIsOverrideModalOpen}
          handleSubmit={handleSubmit}
        />
        <h1>{PAGE_HEADER_CONSTANTS.CLINIC_USE}</h1>
        <h2>{LABEL_CONSTANTS.INFORMATION}</h2>
        <div className="form-row">
          <label>{LABEL_CONSTANTS.ACCOUNT_OWNER}:</label>
          <div className="input-container">
            <AccountSearch key={childKey} showIcon onChange={(account: Account | undefined) => setSelectedAccount(account)} />
          </div>
        </div>
        <div className="form-row">
          <label>{ANIMAL_CONSTANTS.ANIMAL_NAME}:</label>
          <div className="input-container">
            <FilterDropdown
              options={animalOptions}
              value={selectedAnimal ?? { name: '', value: undefined }}
              disabled={animalOptions.length === 0 || !selectedAccount}
              onChange={setSelectedAnimal}
            />
          </div>
        </div>
        <br />

        <h2>{LABEL_CONSTANTS.SELECT_ITEM}</h2>
        {selectedAnimal && selectedAccount && (
          <div className="clinic-use-select-item">
            <div className="desk-select-item">
              <div className={'specimen-table'}>
                <table>
                  <thead>
                    <tr>
                      <th>{TABLE_HEADER_CONSTANTS.SELECT}</th>
                      <th>{TABLE_HEADER_CONSTANTS.LOT_DATE_NO}</th>
                      <th>{TABLE_HEADER_CONSTANTS.OWNED_QTY}</th>
                      <th>{TABLE_HEADER_CONSTANTS.AVAILABLE_QTY}</th>
                      <th>{TABLE_HEADER_CONSTANTS.USE_QTY}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoadingSpecimens &&
                      specimens?.map((specimen, index) => {
                        return (
                          <tr className={handleSpecimenRowClass(specimen)} key={'specimen-row-' + specimen?.specimenId}>
                            <td>
                              <input
                                type="radio"
                                checked={specimen.specimenId === specimenTotalUseQuantity?.specimen.specimenId}
                                onChange={() => setSpecimenTotalUseQuantity({ specimen: specimen, quantity: 0 })}
                              />
                            </td>
                            <td>
                              {dateFormatMMDDYYYY(specimen.freezeDate)} - {specimen.specimenId}
                            </td>

                            <td>{specimen.quantityOwned}</td>
                            <td>{specimen.quantityAvailable}</td>
                            <td>
                              <NumberInput
                                disabled={specimenTotalUseQuantity?.specimen.specimenId !== specimen.specimenId}
                                onChange={e =>
                                  setSpecimenTotalUseQuantity({ specimen: specimen, quantity: Number(e.target.value) })
                                }
                                value={specimenTotalUseQuantity?.quantity.toString() ?? ''}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    {isLoadingSpecimens && (
                      <tr>
                        <td colSpan={5}>
                          <>
                            <br />
                            <br />
                            <Loader loaderSize="small" />
                            <br />
                            <br />
                          </>
                        </td>
                      </tr>
                    )}
                    <tr
                      className={
                        specimenTotalUseQuantity?.specimen && handleSpecimenRowClass(specimenTotalUseQuantity?.specimen)
                      }>
                      <td colSpan={4}></td>
                      <td colSpan={1}>
                        <div className="quantity total-to-use">{specimenTotalUseQuantity?.quantity ?? 0}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/** Mobile View */}
            <div className="xs-select-item">
              <div className="form-row">
                <div className="input-container wide-input-container">
                  <FilterDropdown
                    placeholder={LABEL_CONSTANTS.SELECT_ONE}
                    options={specimenOptions}
                    value={selectedSpecimenValue}
                    onChange={e => {
                      setSelectedSpecimenValue(e);
                      setSpecimenTotalUseQuantity({ specimen: specimens[e.value], quantity: 0 });
                      setSelectedSpecimenIndex(Number(e.value));
                    }}
                  />
                </div>
              </div>
              {selectedSpecimenValue.name != '' && (
                <div className="selected-specimen">
                  <div className="card max-width">
                    <div className="body">
                      <div className="selected-account">
                        <label>{TABLE_HEADER_CONSTANTS.ACCOUNT_BUSINESS_FARM}:</label>
                        <label>{selectedAccount.name}</label>
                      </div>
                      <div className="owned-quantity">
                        <label>{TABLE_HEADER_CONSTANTS.OWNED_QTY}:</label>
                        <label>{specimens[selectedSpecimenIndex]?.quantityOwned}</label>
                      </div>
                      <div className="available-quantity">
                        <label>{TABLE_HEADER_CONSTANTS.AVAILABLE_QTY}:</label>
                        <label>{specimens[selectedSpecimenIndex]?.quantityAvailable}</label>
                      </div>
                      <div className="actual-quantity">
                        <label>{TABLE_HEADER_CONSTANTS.USE_QTY}:</label>
                        <NumberInput
                          disabled={
                            specimenTotalUseQuantity?.specimen.specimenId !== specimens[selectedSpecimenIndex]?.specimenId
                          }
                          onChange={e =>
                            setSpecimenTotalUseQuantity({
                              specimen: specimens[selectedSpecimenIndex],
                              quantity: Number(e.target.value),
                            })
                          }
                          value={specimenTotalUseQuantity?.quantity.toString() ?? ''}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="total-quantity">
                <label>{LABEL_CONSTANTS.TOTAL_QTY_TO_USE} :&nbsp;</label>
                {specimenTotalUseQuantity?.quantity ?? 0}
              </div>
            </div>
          </div>
        )}
        <br />

        <h2>{LABEL_CONSTANTS.ITEM_STORAGE_LOCATIONS}</h2>
        {selectedAnimal && selectedAccount && specimenTotalUseQuantity?.specimen.specimenId && (
          <div className="clinic-use-storage-locations">
            <div className="desk-storage-locations-table">
              <div className="specimen-table" key={childKey}>
                <table>
                  <thead>
                    <tr>
                      <th>{TABLE_HEADER_CONSTANTS.LOCATION}</th>
                      <th>{TABLE_HEADER_CONSTANTS.ITEM}</th>
                      <th>{TABLE_HEADER_CONSTANTS.QTY_IN_CANISTER}</th>
                      <th>{TABLE_HEADER_CONSTANTS.USE_QTY}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {specimenTotalUseQuantity.specimen.specimenLocations &&
                      specimenTotalUseQuantity.specimen.specimenLocations.map(location => renderLocationTableRows(location))}
                    <tr className={totalLocationUseAmounts > specimenTotalUseQuantity.quantity ? 'invalid-row' : ''}>
                      <td colSpan={3}></td>
                      <td colSpan={1}>
                        <div className={'quantity storage-total'}>{totalLocationUseAmounts}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/** Mobile View */}
            <div className="xs-storage-locations-table">
              {specimenTotalUseQuantity.specimen.specimenLocations &&
                specimenTotalUseQuantity.specimen.specimenLocations.map(location => renderLocationMobileRow(location))}
              <div className="storage-total">
                <label>{LABEL_CONSTANTS.SELECTED_QTY_TO_USE}:&nbsp;</label>
                {totalLocationUseAmounts}
              </div>
            </div>
          </div>
        )}
        <br />

        <div className="form-row expanded-text-area">
          <label htmlFor="notes">{LABEL_CONSTANTS.NOTES}</label>
          <textarea
            id="notes"
            name="notes"
            placeholder={LABEL_CONSTANTS.NOTES}
            value={notes}
            onChange={e => setNotes(e.target.value)}
            rows={4}
            cols={50}
          />
        </div>
        <br />
        <div className="submit-button">
          <button
            onClick={() => {
              if (
                specimenTotalUseQuantity?.specimen &&
                specimenTotalUseQuantity?.specimen.quantityAvailable < specimenTotalUseQuantity?.quantity
              ) {
                setIsOverrideModalOpen(true);
              } else {
                handleSubmit();
              }
            }}
            className={`button green small ${!isValid() && 'disabled'}`}>
            {BUTTON_CONSTANTS.SUBMIT}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InventoryManagementClinicUse;
