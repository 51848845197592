import { Route, Routes } from "react-router-dom";
import { TEAM_MANAGEMENT_PATHS } from "../constants/routePaths";
import EditTeam from "../pages/team-management/EditTeam";


const TeamManagementRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path={TEAM_MANAGEMENT_PATHS.EDIT} element={<EditTeam />} />
      </Routes>
    </div>
  );
};

export default TeamManagementRoutes;
