import NumberInput from '../../../components/custom-input/NumberInput';
import { INVENTORY_CONSTANTS, LABEL_CONSTANTS } from '../../../constants/common';

interface SetupDepositProps {
  setNotes: (e: any) => void;
  setQuantity: (e: any) => void;
  quantity?: number;
}

const SetupDeposit = ({ setNotes, setQuantity, quantity }: SetupDepositProps) => {
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setQuantity(e.target.value);
  }

  return (
    <div>
      <h5>{LABEL_CONSTANTS.SETUP_DEPOSIT}</h5>
      <p>
        {INVENTORY_CONSTANTS.ADD_INVENTORY_SUBHEADER}
        <br />
        <br />
        {INVENTORY_CONSTANTS.ADD_INVENTORY_NOTE}
      </p>
      <h5>{LABEL_CONSTANTS.DEPOSIT_DETAILS}</h5>
      <hr />
      <div className="sell-code-input">
        <label htmlFor="quantity">{LABEL_CONSTANTS.QUANTITY}</label>
        <NumberInput maxLength={6} name="quantity" onChange={handleChange} value={quantity} required />
      </div>
      <br />
      <label htmlFor="notes">*{LABEL_CONSTANTS.NOTES}</label>
      <textarea name="notes" rows={4} cols={50} maxLength={200} onChange={e => setNotes(e.target.value)} required />
    </div>
  );
};

export default SetupDeposit;
