import { AlertSVG } from '../../../components/svgs';
import { INVENTORY_CONSTANTS, VALIDATION_ALERT_CONSTANTS } from '../../../constants/common';

interface RedeemSellCodeProps {
  isInvalid: boolean;
  setSellCode: (e: any) => void;
}

const RedeemSellCode = ({ isInvalid, setSellCode }: RedeemSellCodeProps) => {
  return (
    <div>
      <h5>{INVENTORY_CONSTANTS.PURCHASE_CONGRATS}</h5>
      <input
        maxLength={8}
        className="sell-code-input"
        name="sellCode"
        placeholder="Enter Your Sell Code"
        onChange={e => setSellCode(e.target.value)}
      />
      {isInvalid && (
        <div className="sell-code-not-found">
          <AlertSVG />
          <p className="sell-code-not-found-text">{VALIDATION_ALERT_CONSTANTS.INVALID_CODE}</p>
        </div>
      )}
    </div>
  );
};

export default RedeemSellCode;
