import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import BackButton from '../../components/back-button/BackButton';
import FilterDropdown, { FilterOption } from '../../components/custom-input/FilterDropdown';
import ValidatedInput, { ValidatedState } from '../../components/custom-input/ValidatedInput';
import { AlertSVG } from '../../components/svgs';
import AnimalInfo from '../../components/animal/AnimalInfo';
import SelectSpecimens, { SelectedSpecimenQuantity } from '../../components/specimen-tables/SelectSpecimens';
import { Loader } from '../../components';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { toastMessages } from '../../constants/errorMessages';
import {
  BUTTON_CONSTANTS,
  INVENTORY_MANAGEMENT_CONSTANTS,
  LABEL_CONSTANTS,
  PAGE_HEADER_CONSTANTS,
  VALIDATION_ALERT_CONSTANTS,
} from '../../constants/common';
import { getUserByUsername } from '../../api/userApi';
import { sellToNewUser } from '../../api/inventoryApi';
import { sellToExistingUser } from '../../api/inventoryApi';
import { AccountUserInfo } from '../../types/interfaces/user.interfaces';
import { SpecimenSelection } from '../../types/interfaces/inventoryTransaction.interfaces';
import { Validators } from '../../types/enums/validators.enum';
import { SellType } from '../../types/enums/sellType.enum';
import { specifiedAccountUserHasInventoryPermission } from '../../services/permission.service';
import { filterOutDeactivatedAccounts } from '../../services/user.service';
import { showToast } from '../../services/toast.service';

const InventorySell = (): JSX.Element => {
  const { animalId } = useParams();
  const navigate = useNavigate();
  const { activeAccount, user } = useAppSelector(state => state);

  const [selectedQuantities, setSelectedQuantities] = useState<{ valid: boolean; value: SelectedSpecimenQuantity[] }>({
    valid: false,
    value: [],
  });

  const [dropdownAccounts, setDropdownAccounts] = useState<FilterOption<string>[]>([]);
  const [onAddInfoStep, setOnAddInfoStep] = useState<boolean>(false);
  const [buyerEmail, setBuyerEmail] = useState<ValidatedState>({ value: '', valid: false });
  const [buyerUserId, setBuyerUserId] = useState<number>();
  const [buyerAccount, setBuyerAccount] = useState<FilterOption<string>>({ name: '', value: '' });
  const [sellType, setSellType] = useState<FilterOption<SellType>>({ name: SellType.Sell.valueOf(), value: SellType.Sell });
  const [buyerFirstName, setBuyerFirstName] = useState<ValidatedState>({ value: '', valid: false });
  const [buyerLastName, setBuyerLastName] = useState<ValidatedState>({ value: '', valid: false });
  const [buyerPhoneNumber, setBuyerPhoneNumber] = useState<ValidatedState>({ value: '', valid: false });
  const [notes, setNotes] = useState<string>('');
  const [validate, setValidate] = useState<boolean>(false);
  const [sellingInventory, setSellingInventory] = useState<boolean>(true);
  const [foundCatalogAccount, setFoundCatalogAccount] = useState<boolean>(false);

  useEffect(() => {
    selectedQuantities.value.forEach(inventoryLot => {
      if (inventoryLot.selected && inventoryLot.quantity > inventoryLot.availableQuantity) {
        showToast.error(toastMessages.CANT_SELL_MORE_THAN_AVAIL_QTY);
      }
    });
  }, [selectedQuantities]);

  const formIsValid = (): boolean => {
    return (
      selectedQuantities.valid &&
      buyerEmail.valid &&
      (!onAddInfoStep ||
        (onAddInfoStep &&
          buyerFirstName.valid &&
          buyerLastName.valid &&
          (buyerAccount.value.length > 0 || buyerPhoneNumber.valid)))
    );
  };

  const searchByEmail = async () => {
    try {
      const response = await getUserByUsername(buyerEmail.value, { include: 'Accounts.Account,Accounts.Roles' });
      let accounts = response.data.accounts;
      accounts = filterOutDeactivatedAccounts(accounts);
      let filteredAccounts = filterOutCatalogAccounts(accounts);

      const dropdownOptions = filteredAccounts
        .filter((account: AccountUserInfo) => specifiedAccountUserHasInventoryPermission(account))
        .map(
          (account: AccountUserInfo): FilterOption<string> => ({
            name: account.account!.name,
            value: account.accountId.toString(),
          }),
        );

      if (dropdownOptions.length > 0) {
        setBuyerAccount({ name: '', value: '' });
        setBuyerUserId(response.data.userId);
        setBuyerFirstName({ valid: true, value: response.data.firstName });
        setBuyerLastName({ valid: true, value: response.data.lastName });
        setBuyerPhoneNumber({ valid: false, value: '' });
        setDropdownAccounts(dropdownOptions);
      } else {
        resetAdditionalInfo();
      }
    } catch (error) {
      resetAdditionalInfo();
    } finally {
      setOnAddInfoStep(true);
    }
  };

  const filterOutCatalogAccounts = (accounts: AccountUserInfo[]) => {
    return accounts.filter(account => {
      account?.account?.isCatalogAccount && setFoundCatalogAccount(true);
      return !account?.account?.isCatalogAccount;
    });
  };

  const resetAdditionalInfo = () => {
    setBuyerAccount({ name: '', value: '' });
    setBuyerUserId(undefined);
    setBuyerFirstName({ valid: false, value: '' });
    setBuyerLastName({ valid: false, value: '' });
    setBuyerPhoneNumber({ valid: false, value: '' });
    setDropdownAccounts([]);
  };

  const isQuantityValid = () => {
    if (selectedQuantities.value.every(inventoryLot => inventoryLot.quantity === 0)) {
      showToast.error(toastMessages.INVALID_ZERO_QUANTITY);
      return false;
    } else if (selectedQuantities.valid) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    const notesWithSellType = `${notes} - Sell Type: [${sellType.value}]`;
    e.preventDefault();

    if (!sellingInventory) return;
    if (!isQuantityValid()) return;
    setValidate(onAddInfoStep);

    if (!onAddInfoStep) {
      if (buyerEmail.valid) {
        searchByEmail();
      }
      setSellingInventory(true);
      return;
    }

    if (buyerEmail.valid && buyerFirstName.valid && buyerLastName.valid) {
      setSellingInventory(false);

      if (buyerAccount.value.length > 0 && isQuantityValid()) {
        try {
          await sellToExistingUser({
            sellerAccountId: Number(activeAccount.accountId),
            sellerUserId: user.userId,
            buyerAccountId: +buyerAccount.value,
            specimenSelections: selectedQuantities.value.map((q: SelectedSpecimenQuantity) => {
              return { specimenId: q.specimenId, quantity: q.quantity } as SpecimenSelection;
            }),
            notes: notesWithSellType,
          });
          showToast.success(toastMessages.SALE_HAS_BEEN_SUBMITTED);
          navigate(ROUTE_PATHS.APP_INVENTORY);
        } catch (error) {
          showToast.error(toastMessages.FAILED_TO_SUBMIT_SALE);
        } finally {
          setSellingInventory(true);
          resetAdditionalInfo();
        }
      } else if (buyerPhoneNumber.valid && isQuantityValid()) {
        try {
          await sellToNewUser({
            sellerAccountId: Number(activeAccount.accountId),
            sellerUserId: user.userId,
            specimenSelections: selectedQuantities.value.map((q: SelectedSpecimenQuantity) => {
              return { specimenId: q.specimenId, quantity: q.quantity } as SpecimenSelection;
            }),
            notes: notesWithSellType,
            firstName: buyerFirstName.value,
            lastName: buyerLastName.value,
            email: buyerEmail.value,
            phone: buyerPhoneNumber.value,
          });
          showToast.success(toastMessages.SALE_HAS_BEEN_SUBMITTED);
          navigate(ROUTE_PATHS.APP_INVENTORY);
        } catch (error) {
          showToast.error(toastMessages.FAILED_TO_SUBMIT_SALE);
        } finally {
          setSellingInventory(true);
        }
      } else {
        setSellingInventory(true);
      }
    }
  };

  return (
    <div className="inventory-sell">
      <BackButton />
      <div className="inventory-action card">
        <h1>{PAGE_HEADER_CONSTANTS.SELL_INVENTORY}</h1>
        <AnimalInfo animalId={animalId} showQuantityAvailable />
        <br />

        <h2>{LABEL_CONSTANTS.SELECT_ITEM_QUANTITY}</h2>
        <SelectSpecimens animalId={+animalId!} accountId={activeAccount.accountId} onChange={e => setSelectedQuantities(e)} />

        <h2>{LABEL_CONSTANTS.BUYER_INFO}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <ValidatedInput
              useId="buyer-email"
              label="Buyer Email:"
              type="email-search"
              onButtonClick={() => {
                if (buyerEmail.valid) searchByEmail();
              }}
              setValidatedState={e => {
                setBuyerEmail(e);
                setOnAddInfoStep(false);
                setFoundCatalogAccount(false);
                resetAdditionalInfo();
              }}
              validate={validate}
              validators={[Validators.REQUIRED, Validators.EMAIL]}
            />
          </div>
          {!sellingInventory && <Loader loaderSize={'small'} simple />}

          {onAddInfoStep &&
            (dropdownAccounts.length > 0 ? (
              <div>
                <div className="form-row">
                  <label>{LABEL_CONSTANTS.BUYER_FIRST_NAME}:</label>
                  <div className="input-container">
                    <label>{buyerFirstName.value}</label>
                  </div>
                </div>
                <div className="form-row">
                  <label>{LABEL_CONSTANTS.BUYER_LAST_NAME}:</label>
                  <div className="input-container">
                    <label>{buyerLastName.value}</label>
                  </div>
                </div>
                <div className="form-row">
                  <label>*{LABEL_CONSTANTS.ACCOUNT_NAME}:</label>
                  <div className="input-container">
                    <FilterDropdown
                      options={dropdownAccounts}
                      placeholder="Choose Account"
                      value={buyerAccount}
                      onChange={e => setBuyerAccount(e)}
                    />
                  </div>
                </div>
              </div>
            ) : foundCatalogAccount ? (
              <div className="validated-input-message-error">
                <AlertSVG /> {VALIDATION_ALERT_CONSTANTS.UNAUTHORIZED_CATALOG_SELL}
              </div>
            ) : (
              <div>
                <div className="validated-input-message-error">
                  <AlertSVG /> {VALIDATION_ALERT_CONSTANTS.NO_ACCOUNT_FOUND}
                </div>
                <div className="form-row">
                  <ValidatedInput
                    validatedStateForAutoFill={buyerFirstName}
                    useId="buyer-first-name"
                    label="*Buyer First Name:"
                    type="text"
                    setValidatedState={event => {
                      setBuyerFirstName({
                        value: event.value.replace(/\s+/g, ' '),
                        valid: event.valid && event.value.trim().length > 0,
                      });
                    }}
                    validators={[Validators.REQUIRED]}
                  />
                </div>
                <div className="form-row">
                  <ValidatedInput
                    validatedStateForAutoFill={buyerLastName}
                    useId="buyer-last-name"
                    label="*Buyer Last Name:"
                    type="text"
                    setValidatedState={event => {
                      setBuyerLastName({
                        value: event.value.replace(/\s+/g, ' '),
                        valid: event.valid && event.value.trim().length > 0,
                      });
                    }}
                    validators={[Validators.REQUIRED]}
                  />
                </div>
                <div className="form-row">
                  <ValidatedInput
                    validatedStateForAutoFill={buyerPhoneNumber}
                    useId="buyer-phone-number"
                    label="*Buyer Phone Number"
                    type="phone"
                    setValidatedState={setBuyerPhoneNumber}
                    validators={[Validators.REQUIRED, Validators.PHONE_LENGTH]}
                  />
                </div>
              </div>
            ))}
          <br />
          <div className="form-row">
            <label>{LABEL_CONSTANTS.SELL_TYPE}:</label>
            <div className="input-container">
              <FilterDropdown
                options={[
                  { name: SellType.Sell, value: SellType.Sell },
                  { name: SellType.Transfer, value: SellType.Transfer },
                  { name: SellType.Donate, value: SellType.Donate },
                  { name: SellType.Other, value: SellType.Other },
                ]}
                placeholder="Choose Type"
                value={sellType}
                onChange={e => setSellType(e)}
              />
            </div>
          </div>
          <div className="form-row expanded-text-area">
            <label htmlFor="inventory-notes-textarea">{LABEL_CONSTANTS.NOTES}:</label>
            <textarea value={notes} placeholder="Notes" id="inventory-notes-textarea" onChange={e => setNotes(e.target.value)} />
          </div>
          <br />

          <div className="flex-right sell-submit-section">
            {dropdownAccounts.length > 0 && (
              <p className="sell-submit-guide">{INVENTORY_MANAGEMENT_CONSTANTS.SELL_INVENTORY_FOOTER}</p>
            )}
            <button className={formIsValid() ? 'button green small' : 'button green small disabled'} type="submit">
              {BUTTON_CONSTANTS.SUBMIT}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InventorySell;
