const Persons = () => {
  return (
    <svg
      width="20"
      height="13"
      viewBox="0 0 20 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6.5C5.20435 6.5 4.44129 6.18393 3.87868 5.62132C3.31607 5.05871 3 4.29565 3 3.5C3 2.70435 3.31607 1.94129 3.87868 1.37868C4.44129 0.816071 5.20435 0.5 6 0.5C6.79565 0.5 7.55871 0.816071 8.12132 1.37868C8.68393 1.94129 9 2.70435 9 3.5C9 4.29565 8.68393 5.05871 8.12132 5.62132C7.55871 6.18393 6.79565 6.5 6 6.5ZM14 6.5C13.2044 6.5 12.4413 6.18393 11.8787 5.62132C11.3161 5.05871 11 4.29565 11 3.5C11 2.70435 11.3161 1.94129 11.8787 1.37868C12.4413 0.816071 13.2044 0.5 14 0.5C14.7956 0.5 15.5587 0.816071 16.1213 1.37868C16.6839 1.94129 17 2.70435 17 3.5C17 4.29565 16.6839 5.05871 16.1213 5.62132C15.5587 6.18393 14.7956 6.5 14 6.5ZM6 8.5C7.13517 8.49865 8.25757 8.73951 9.29224 9.20649C10.3269 9.67348 11.25 10.3558 12 11.208V12.5H0V11.208C0.749953 10.3558 1.67309 9.67348 2.70776 9.20649C3.74243 8.73951 4.86483 8.49865 6 8.5ZM14 12.5V10.452L13.5 9.885C13.1183 9.45221 12.7001 9.05307 12.25 8.692C12.8245 8.56404 13.4114 8.49965 14 8.5C15.1352 8.49865 16.2576 8.73951 17.2922 9.20649C18.3269 9.67348 19.25 10.3558 20 11.208V12.5H14Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Persons;
