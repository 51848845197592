import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackButton } from '../../components';
import PendingUseTransactionsTable from '../../components/specimen-tables/PendingUseTransactionsTable';
import OverrideQuantityModal from './check-out/check-out-components/OverrideQuantityModal';
import AccountSearch from '../../components/custom-input/AccountSearch';
import SelectStorageItems from '../../components/select-storage-items/SelectStorageItems';
import PendingUseSelectQuantityTable from '../../components/specimen-tables/PendingUseSelectQuantityTable';
import { showToast } from '../../services/toast.service';
import { StorageTankOptions } from '../../types/enums/storageTank.enum';
import { Account } from '../../types/interfaces/account.interfaces';
import { selectOption } from '../../types/interfaces/selectOption.interface';
import { SpecimenInventoryWithUseRequested, SpecimenLocation } from '../../types/interfaces/specimen.interfaces';
import { CheckOut, CheckOutSpecimen } from '../../types/interfaces/transaction.interfaces';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS, PAGE_HEADER_CONSTANTS } from '../../constants/common';
import { getUsePendingSpecimensByAccountId } from '../../api/specimensApi';
import { requestCheckOut } from '../../api/inventoryApi';
import { redirectToTopOfThePage } from '../../utils/commonUtils';

const InventoryManagementCheckOut = (): JSX.Element => {
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const [showOverrideModal, setShowOverrideModal] = useState(false);
  const [pendingUseTransactions, setPendingUseTransactions] = useState<SpecimenInventoryWithUseRequested[]>([]);
  const [loadingPendingUseTransactions, setLoadingPendingUseTransactions] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<Account>();
  const [checkOutSite, setCheckOutSite] = useState<selectOption>({ value: '', name: '' });
  const [checkOutTank, setCheckOutTank] = useState<selectOption>({ value: '', name: '' });
  const [checkOutCanister, setCheckOutCanister] = useState<selectOption>({ value: '', name: '' });
  const [selectedUseTransaction, setSelectedUseTransaction] = useState<SpecimenInventoryWithUseRequested>();
  const [checkOutSpecimens, setCheckOutSpecimens] = useState<CheckOutSpecimen[]>([]);
  const [notes, setNotes] = useState('');
  const [checkOutRowInvalid, setCheckOutRowInvalid] = useState(false);
  const [childKey, setChildKey] = useState(0);
  const [accountWasCleared, setAccountWasCleared] = useState<boolean>(false);

  const handleGetPendingUseTransactions = useCallback(async () => {
    setLoadingPendingUseTransactions(true);
    try {
      if (selectedAccount?.accountId) {
        setPendingUseTransactions([]);
        setSelectedUseTransaction(undefined);
        const { data: requestedPendingUseTransactions } = await getUsePendingSpecimensByAccountId(selectedAccount?.accountId);

        requestedPendingUseTransactions.sort((a, b) => b.inventoryTransactionId - a.inventoryTransactionId);
        setPendingUseTransactions(requestedPendingUseTransactions);
      }
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setLoadingPendingUseTransactions(false);
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (selectedAccount?.accountId) {
      handleGetPendingUseTransactions();
    }
  }, [selectedAccount, handleGetPendingUseTransactions]);

  useEffect(() => {
    if (selectedUseTransaction?.specimenLocations?.length) {
      setCheckOutSpecimens(
        selectedUseTransaction?.specimenLocations.map((location: SpecimenLocation) => {
          return {
            specimenId: selectedUseTransaction.specimenId,
            quantity: 0,
            quantityUsed: 0,
            quantityLost: 0,
            transactionLinkId: selectedUseTransaction.inventoryTransactionId,
            selectedCanisterId: location.storageCanisterId,
            destinationCanisterId: 0,
          };
        }),
      );
    }
  }, [selectedUseTransaction]);

  const handleMapCheckOutSpecimensWithCanister = useCallback(() => {
    const checkOutSpecimensCopy = [...checkOutSpecimens];
    const checkOutSpecimensWithCanister = checkOutSpecimensCopy.map(checkOutSpecimen => {
      return {
        ...checkOutSpecimen,
        destinationCanisterId: Number(checkOutCanister.value),
      };
    });
    setCheckOutSpecimens(checkOutSpecimensWithCanister);
  }, [checkOutCanister, checkOutSpecimens]);

  useEffect(() => {
    checkOutCanister.value && handleMapCheckOutSpecimensWithCanister();
  }, [checkOutCanister]);

  const isValid = () => {
    const checkOutSpecimensQuantity = checkOutSpecimens.reduce((accumulator, current) => accumulator + current.quantity, 0);
    return (
      checkOutSite.value &&
      checkOutTank.value &&
      checkOutCanister.value &&
      selectedUseTransaction &&
      selectedUseTransaction.quantityOwned >= checkOutSpecimensQuantity &&
      checkOutSpecimensQuantity > 0 &&
      !checkOutRowInvalid
    );
  };

  const clearUseStates = useCallback(() => {
    setCheckOutSite({ value: '', name: '' });
    setCheckOutTank({ value: '', name: '' });
    setCheckOutCanister({ value: '', name: '' });
    setSelectedUseTransaction(undefined);
    setCheckOutSpecimens([]);
    setNotes('');
    setCheckOutRowInvalid(false);
    setSelectedAccount(undefined);
    setChildKey(prev => prev + 1);
  }, []);

  const handleSubmit = async (override?: boolean) => {
    if (isValid()) {
      if (!override) {
        const hasMoreThanAvailableQuantity =
          checkOutSpecimens.reduce((accumulator, current) => accumulator + current.quantity, 0) >
          selectedUseTransaction?.quantityAvailable!;

        if (hasMoreThanAvailableQuantity) {
          setShowOverrideModal(true);
          return;
        }
      }
      const filteredCheckOutSpecimens = checkOutSpecimens.filter(specimen => specimen.quantity > 0);
      const checkOut: CheckOut = { specimens: filteredCheckOutSpecimens, notes: notes };
      try {
        if (!selectedAccount?.accountId) throw new Error('No Account Selected');
        await requestCheckOut(checkOut, { accountId: selectedAccount?.accountId });
        showToast.success(toastMessages.CHECK_OUT_SUCCESS);
        clearUseStates();
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
      setShowOverrideModal(false);
    }
  };

  useEffect(
    function handleAccountIsUndefined() {
      if (selectedAccount === undefined) {
        clearUseStates();
      }
    },
    [selectedAccount, clearUseStates],
  );

  return (
    <div className="inventory-management inventory-management-check-out">
      {locationState && locationState.transactionManagementFilterPreset && (
        <BackButton
          onClick={() =>
            navigate(ROUTE_PATHS.APP_TRANSACTION_MANAGEMENT, {
              state: { filterPreset: locationState.transactionManagementFilterPreset, filter: locationState.filter },
            })
          }
        />
      )}
      <OverrideQuantityModal
        showModal={showOverrideModal}
        setShowModal={setShowOverrideModal}
        handleSubmit={() => handleSubmit(true)}
      />
      <div className="inventory-action card">
        <h1>{PAGE_HEADER_CONSTANTS.CHECK_OUT_INVENTORY}</h1>
        <h2>{LABEL_CONSTANTS.SELECT_ACCOUNT}</h2>
        <div className="form-row">
          <label>{LABEL_CONSTANTS.ACCOUNT_NAME}:</label>
          <div className="input-container">
            <AccountSearch
              populateAccountObject={{
                transaction: locationState,
                accountWasCleared: accountWasCleared,
                setAccountWasCleared: setAccountWasCleared,
              }}
              key={childKey}
              showIcon
              onChange={(account: Account | undefined) => {
                setPendingUseTransactions([]);
                setCheckOutSpecimens([]);
                setSelectedAccount(account);
              }}
            />
          </div>
        </div>
        <br />

        <h2>{LABEL_CONSTANTS.PENDING_USE_TRANSACTIONS}</h2>
        {selectedAccount && (
          <PendingUseTransactionsTable
            populateByTransactionId={locationState && locationState?.inventoryTransactionId}
            useTransactions={pendingUseTransactions}
            setActivePendingUseTransaction={setSelectedUseTransaction}
            loading={loadingPendingUseTransactions}
          />
        )}
        <br />
        <h2>{LABEL_CONSTANTS.SELECT_QTY}</h2>
        {selectedUseTransaction && (
          <PendingUseSelectQuantityTable
            pendingUseTransaction={selectedUseTransaction}
            checkOutSpecimens={checkOutSpecimens}
            setCheckOutSpecimens={setCheckOutSpecimens}
            setCheckOutRowInvalid={setCheckOutRowInvalid}
          />
        )}
        <br />
        <h2>{LABEL_CONSTANTS.SELECT_TRAVELING_CANISTER}</h2>
        <SelectStorageItems
          selectedSite={checkOutSite}
          selectedTank={checkOutTank}
          selectedCanister={checkOutCanister}
          setSelectedSite={setCheckOutSite}
          setSelectedTank={setCheckOutTank}
          setSelectedCanister={setCheckOutCanister}
          storageTankOptions={StorageTankOptions.Travel}
          activeLocationsOnly
        />
        <div className="form-row expanded-text-area">
          <label htmlFor="notes">{LABEL_CONSTANTS.NOTES}</label>
          <textarea
            id="notes"
            name="notes"
            placeholder="Notes"
            value={notes}
            onChange={e => setNotes(e.target.value)}
            rows={4}
            cols={50}
          />
        </div>
        <br />
        <div className="submit-button">
          <button
            disabled={!isValid()}
            onClick={() => {
              handleSubmit();
              redirectToTopOfThePage();
            }}
            className="button green small">
            {BUTTON_CONSTANTS.SUBMIT}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InventoryManagementCheckOut;
