import React, { FC } from 'react';
import { oneBitImage } from '../../utils/commonUtils';
import DefaultProfileImage from './default-profile-image/DefaultProfileImage';
import './profileImage.scss';

type ProfileImageProps = {
  profileImageId?: string;
  profileImageBlob?: Blob;
  className?: string;
};

const ProfileImage: FC<ProfileImageProps> = ({ profileImageId, profileImageBlob, className = '' }) => {
  return profileImageId ? (
    <img
      className={`profile-image ${profileImageBlob ? className : `loading ${className}`}`}
      src={profileImageBlob ? URL.createObjectURL(profileImageBlob) : oneBitImage}
      alt="Profile Image"
    />
  ) : (
    <DefaultProfileImage className={`profile-image ${className}`} />
  );
};

export default ProfileImage;
