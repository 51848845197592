import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import BackButton from '../../components/back-button/BackButton';
import ValidatedInput, { ValidatedState } from '../../components/custom-input/ValidatedInput';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { toastMessages } from '../../constants/errorMessages';
import { PASSWORD_VALIDATORS, Validators } from '../../types/enums/validators.enum';
import { setNewPassword } from '../../api/userApi';
import { showToast } from '../../services/toast.service';

const ChangePassword = (): JSX.Element => {
  const [currentPassword, setCurrentPassword] = useState<ValidatedState>({ value: '', valid: false });
  const [password, setPassword] = useState<ValidatedState>({ value: '', valid: false });
  const [confirmPassword, setConfirmPassword] = useState<ValidatedState>({ value: '', valid: false });
  const [validate, setValidate] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    setValidate(true);

    if (password.valid && password.value === confirmPassword.value) {
      setNewPassword({ previous: currentPassword.value, new: password.value })
        .then((e: any) => {
          navigate(ROUTE_PATHS.SIGN_IN);
          showToast.success(toastMessages.SUCCESSFULLY_SET_NEW_PASSWORD);
        })
        .catch((e: Error) => {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        });
    }
  };

  return (
    <div className="auth card">
      <div className="form">
        <h1>
          <BackButton />
          Reset Password
        </h1>
        <p>Let's get started on setting a new password for your account.</p>

        <form onSubmit={e => handleSubmit(e)}>
          <div className="input">
            <ValidatedInput
              label="Current Password"
              type="password"
              setValidatedState={setCurrentPassword}
              validators={[Validators.REQUIRED]}
              validate={validate}
            />
          </div>

          <div className="input">
            <ValidatedInput
              label="New Password"
              type="password"
              setValidatedState={setPassword}
              validators={[Validators.REQUIRED, ...PASSWORD_VALIDATORS]}
              validate={validate}
            />
          </div>

          <div className="input">
            <ValidatedInput
              label="Confirm Password"
              type="password"
              setValidatedState={setConfirmPassword}
              validators={[Validators.REQUIRED, Validators.MUST_MATCH]}
              validate={validate}
              matchString={password.value}
            />
          </div>

          <br />
          <button>Update Password</button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
