import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BackButton, Loader, Modal, NumberInput, Tooltip, ValidatedInput } from '../../components';
import TransactionLabelField from '../../components/transaction/TransactionLabelField';
import FilterDropdown, { FilterOption } from '../../components/custom-input/FilterDropdown';
import { AdjustRequestBody, BulkTransaction, InventoryTransaction } from '../../types/interfaces';
import { BulkOrderAdjustSelectedSpecimenQuantity } from '../../types/interfaces/bulkOrder.interfaces';
import { TransactionTypeEnum, Validators } from '../../types/enums';
import { getTransactionById } from '../../api/transactionsApi';
import { adjustPut, bulkOrderAdjustPut } from '../../api/inventoryApi';
import { getBulkTransactionById } from '../../api/bulkTransactionApi';
import { getInventoryVarianceSummary } from '../../api/specimensApi';
import { showToast } from '../../services/toast.service';
import { dateFormatMMDDYYYY, handleResponseErrors } from '../../utils/commonUtils';
import { ROUTE_PATHS } from '../../constants/routePaths';
import {
  BUTTON_CONSTANTS,
  LABEL_CONSTANTS,
  PAGE_HEADER_CONSTANTS,
  TOOLTIP_MESSAGE_CONSTANTS,
  TRANSACTION_CONSTANTS,
  TABLE_HEADER_CONSTANTS,
} from '../../constants/common';
import { toastMessages } from '../../constants/errorMessages';
import { httpStatusCodes } from '../../constants/httpStatusCodes';
import './transactionManagement.scss';

const TransactionManagementAdjust: FC<{}> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { inventoryTransactionId, filterPreset, filter, bulkOrder } = state;
  const { transactionId } = useParams();

  const [associatedSpecimens, setAssociatedSpecimens] = useState<BulkOrderAdjustSelectedSpecimenQuantity[]>([]);
  const [bulkOrderTransaction, setBulkOrderTransaction] = useState<BulkTransaction>();
  const [displayedQuantity, setDisplayedQuantity] = useState<string>('');
  const [isIncreased, setIsIncreased] = useState<boolean>(true);
  const [isOpenReconcileModal, setIsOpenReconcileModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalErrorContent, setModalErrorContent] = useState<JSX.Element>();
  const [transaction, setTransaction] = useState<InventoryTransaction | undefined>();
  const [updatingTransaction, setUpdatingTransaction] = useState<boolean>(true);
  const [wasModified, setWasModified] = useState<boolean>(false);

  const specimenHeaders: string[] = [
    TABLE_HEADER_CONSTANTS.ANIMAL,
    TABLE_HEADER_CONSTANTS.LOT_DATE_NO,
    TABLE_HEADER_CONSTANTS.INCREASE,
    TABLE_HEADER_CONSTANTS.DECREASE,
    TABLE_HEADER_CONSTANTS.QUANTITY,
  ];

  const increaseOrDecreaseOptions: FilterOption<string | null>[] = [
    { name: 'increase', value: 'increase' },
    { name: 'decrease', value: 'decrease' },
  ];

  useEffect(() => {
    if (transactionId) {
      bulkOrder ? handleBulkOrderInventoryTransaction() : handleInventoryTransaction();
    }
  }, [transactionId]);

  const handleBulkOrderInventoryTransaction = async () => {
    try {
      setLoading(true);
      const { data: bulkOrderTransaction } = await getBulkTransactionById(+transactionId!, {
        include: 'Account,inventoryTransactions,inventoryTransactions.Specimen.Animal',
      });
      setBulkOrderTransaction(bulkOrderTransaction);
      mapInventoryTransactions(bulkOrderTransaction.inventoryTransactions!);
    } catch (error: any) {
      handleResponseErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const handleInventoryTransaction = async () => {
    try {
      setLoading(true);
      const { data: inventoryTransaction } = await getTransactionById(String(inventoryTransactionId));

      if (inventoryTransaction.transactionType === TransactionTypeEnum.Withdraw) {
        const inventoryTransactions = {
          account: inventoryTransaction.account!,
          accountId: inventoryTransaction.accountId,
          createdDatetime: inventoryTransaction.createdDatetime,
          inventoryTransactions: [inventoryTransaction],
          modifiedBy: inventoryTransaction.modifiedBy,
          modifiedDatetime: inventoryTransaction.modifiedDatetime,
          notes: inventoryTransaction.notes ?? '',
          resolution: inventoryTransaction.resolution,
          status: inventoryTransaction.status,
          totalQuantity: inventoryTransaction.changeQuantity,
          transactionType: inventoryTransaction.transactionType,
          breedDate: inventoryTransaction.breedDate,
        };

        setBulkOrderTransaction(inventoryTransactions);
        mapInventoryTransactions(inventoryTransactions.inventoryTransactions);
      } else {
        setTransaction(inventoryTransaction);
      }
    } catch (error: any) {
      handleResponseErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRadioButtons = (e: React.ChangeEvent<HTMLInputElement>) => setIsIncreased(e.target.name === 'increase');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!updatingTransaction) return;

    const isWithdraw = bulkOrder || bulkOrderTransaction?.transactionType === TransactionTypeEnum.Withdraw;

    if (isWithdraw ? !bulkOrderTransaction || !bulkOrderTransaction.accountId : !transaction || !transaction.accountId) {
      showToast.error(toastMessages.CANT_ADJUST_WITHOUT_TRANSACTION_OR_ACCOUNT_ID);
    }

    setUpdatingTransaction(false);
    setLoading(true);
    try {
      isWithdraw ? bulkOrderAdjustTransaction() : inventoryAdjustTransaction();
    } finally {
      setLoading(false);
    }
  };

  const bulkOrderAdjustTransaction = async () => {
    try {
      await bulkOrderAdjustPut(mapBulkOrderSpecimens(), bulkOrderTransaction?.account?.accountId!, bulkOrderTransaction?.notes!);
      await checkForZeroVariance(bulkOrderTransaction?.inventoryTransactions!);
    } catch (error: any) {
      handleResponseErrors(error);
    }
  };

  const inventoryAdjustTransaction = async () => {
    const payload: AdjustRequestBody = {
      specimens: [
        {
          specimenId: transaction?.specimenId,
          quantity: modifyQuantityPositiveOrNegative(transaction?.changeQuantity!),
          transactionLinkId: transaction?.inventoryTransactionId,
        },
      ],
      notes: transaction?.notes,
    };

    try {
      await adjustPut(payload, transaction?.accountId!);
      await checkForZeroVariance([transaction!]);
    } catch (error: any) {
      handleResponseErrors(error);
    }
  };

  const checkForZeroVariance = async (inventoryTransactions: InventoryTransaction[]) => {
    try {
      const specimenIds = inventoryTransactions.map(tx => Number(tx.specimen!.specimenId)).filter(id => !isNaN(id));
      const varianceChecks = await Promise.all(specimenIds.map(specimenId => getInventoryVarianceSummary(specimenId)));

      const discrepancies = specimenIds.filter(id => {
        const varianceCheck = varianceChecks.find(v => v?.data?.specimenId === id);
        return varianceCheck && varianceCheck.data.zeroSumCheck != 0;
      });

      if (discrepancies.length > 0) {
        setIsOpenReconcileModal(true);
        setModalErrorContent(
          <div>
            The inventory counts are incorrect for the following lot numbers:
            <b> {discrepancies.join(', ')}</b>. To resolve the issue, please ensure that the physical inventory counts match the
            customer ownership amounts. Note that these discrepancies might have existed prior to this transaction.
          </div>,
        );
      } else {
        handlePostUpdateActions();
      }
    } catch (err: any) {
      const error = err.response.data;
      if (error.status === httpStatusCodes[409]) {
        const detailObject = JSON.parse(error.detail.substring(error.detail.indexOf('{')));
        const errMsg = detailObject.ErrMsg;
      } else {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
      handlePostUpdateActions();
    }
  };

  const handlePostUpdateActions = () => {
    setUpdatingTransaction(true);
    navigate(ROUTE_PATHS.APP_TRANSACTION_MANAGEMENT, {
      state: { filterPreset: filterPreset, filter: filter, bulkOrder: bulkOrder },
    });
  };

  const mapBulkOrderSpecimens = (): AdjustRequestBody[] => {
    return associatedSpecimens
      .filter(specimen => specimen.quantity > 0)
      .map(specimen => {
        return {
          specimens: [
            {
              specimenId: specimen.specimenId!,
              quantity:
                specimen.isIncreasedOrDecrease === 'increase' ? Math.abs(specimen.quantity) : -Math.abs(specimen.quantity),
              transactionLinkId: specimen.inventoryTransactionId!,
            },
          ],
        };
      });
  };

  const modifyQuantityPositiveOrNegative = (quantity: number): number => {
    return isIncreased ? Math.abs(quantity) : -Math.abs(quantity);
  };

  const mapInventoryTransactions = (transactions: InventoryTransaction[]) => {
    const specimens = transactions.map(transaction => {
      return {
        inventoryTransactionId: transaction.inventoryTransactionId!,
        specimenId: transaction.specimenId!,
        quantity: 0,
        isIncreasedOrDecrease: 'increase',
      };
    });

    setAssociatedSpecimens(specimens);
  };

  const handleNotesChange = (notes: string) => {
    if (bulkOrder || bulkOrderTransaction?.transactionType === TransactionTypeEnum.Withdraw) {
      setBulkOrderTransaction({ ...bulkOrderTransaction!, notes: notes });
    } else {
      setTransaction({ ...transaction!, notes: notes });
    }
  };

  const handleQuantityChange = (quantity: number | string) => {
    if (!transaction) return;

    setWasModified(true);
    setDisplayedQuantity(String(quantity) === '0' ? '' : String(quantity));
    setTransaction({ ...transaction, changeQuantity: Number(quantity) });
  };

  const isDisabled = () => {
    return (
      !wasModified ||
      !(bulkOrder || bulkOrderTransaction?.transactionType === TransactionTypeEnum.Withdraw
        ? associatedSpecimens.some(specimen => specimen.quantity > 0)
        : Number(displayedQuantity) > 0)
    );
  };

  return (
    <div className="transaction-adjust">
      {loading ? (
        <Loader loaderSize={'small'} pageLoader />
      ) : (
        <>
          <Modal
            isOpen={isOpenReconcileModal}
            ignoreBackdrop
            onClose={() => {
              setIsOpenReconcileModal(false);
              handlePostUpdateActions();
            }}>
            <div className="sign-out-modal">
              <div className="header">
                <h4>{LABEL_CONSTANTS.VARIANCE_DETECTED}</h4>
              </div>
              <div className="body">
                <p className="modal-p-side-margin">{modalErrorContent}</p>
              </div>
              <div className="footer">
                <button
                  className="button green small"
                  onClick={() => {
                    setIsOpenReconcileModal(false);
                    handlePostUpdateActions();
                  }}>
                  {BUTTON_CONSTANTS.OK}
                </button>
              </div>
            </div>
          </Modal>
          <BackButton state={{ filterPreset, filter, bulkOrder }} pagesBack={2} />
          <div className="inventory-action card">
            <h1>{PAGE_HEADER_CONSTANTS.ADJUST_OWNERSHIP_QUANTITY}</h1>

            <form onSubmit={handleSubmit}>
              <h2>
                {TRANSACTION_CONSTANTS.TRANSACTION_INFORMATION}
                <Tooltip size="26" className="transaction-tooltip">
                  <p>{TOOLTIP_MESSAGE_CONSTANTS.ADJUST_TOOLTIP_MSG}</p>
                </Tooltip>
              </h2>

              <TransactionLabelField
                label={bulkOrder ? `${LABEL_CONSTANTS.BULK_TRANSACTION_ID}:` : `${TRANSACTION_CONSTANTS.TRANSACTION_ID}`}
                value={transactionId ?? '-'}
              />
              <TransactionLabelField
                label={`${TRANSACTION_CONSTANTS.TRANSACTION_TYPE}:`}
                value={
                  (bulkOrder || bulkOrderTransaction?.transactionType === TransactionTypeEnum.Withdraw
                    ? bulkOrderTransaction?.transactionType
                    : transaction?.transactionType) ?? '-'
                }
              />

              <TransactionLabelField
                label={`${LABEL_CONSTANTS.ACCOUNT_OWNER_ID}:`}
                value={
                  bulkOrder || bulkOrderTransaction?.transactionType === TransactionTypeEnum.Withdraw
                    ? `${bulkOrderTransaction?.accountId ?? ''}` + ' - ' + `${bulkOrderTransaction?.account?.name ?? ''}`
                    : `${transaction?.account?.accountId ?? ''}` + ' - ' + `${transaction?.account?.name ?? ''} `
                }
              />

              {bulkOrderTransaction?.transactionType === TransactionTypeEnum.Withdraw ? (
                <>
                  <div className="select-animal">
                    <h4 className="specimens-title">{LABEL_CONSTANTS.SPECIMENS}</h4>
                    <hr />
                    <div className="specimen-table desk-inventory-transactions">
                      <table>
                        <thead>
                          <tr>
                            {specimenHeaders.map((header: string, index: number) => (
                              <th key={header + index}>{header}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {bulkOrderTransaction?.inventoryTransactions?.map((transaction, index) => {
                            return (
                              <tr key={'specimen-row:' + transaction.specimen?.specimenId + '-index:' + index}>
                                <td>{`${transaction.specimen?.animal?.code} - ${transaction.specimen?.animal?.name}`}</td>
                                <td>
                                  {transaction?.specimen?.freezeDate &&
                                    `${dateFormatMMDDYYYY(transaction?.specimen?.freezeDate)} - ${
                                      transaction?.specimen?.specimenId
                                    } `}
                                </td>
                                <td>
                                  <input
                                    type="radio"
                                    name={`increase-${transaction.specimen?.specimenId}`}
                                    value="increase"
                                    onChange={event => {
                                      let newSpecimens = [...associatedSpecimens];
                                      newSpecimens[index] = {
                                        ...associatedSpecimens[index],
                                        isIncreasedOrDecrease: 'increase',
                                      };
                                      setAssociatedSpecimens(newSpecimens);
                                    }}
                                    checked={associatedSpecimens[index].isIncreasedOrDecrease === 'increase'}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="radio"
                                    name={`decrease-${transaction.specimen?.specimenId}`}
                                    value="decrease"
                                    onChange={event => {
                                      let newSpecimens = [...associatedSpecimens];
                                      newSpecimens[index] = {
                                        ...associatedSpecimens[index],
                                        isIncreasedOrDecrease: 'decrease',
                                      };
                                      setAssociatedSpecimens(newSpecimens);
                                    }}
                                    checked={associatedSpecimens[index].isIncreasedOrDecrease === 'decrease'}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    placeholder="0"
                                    value={associatedSpecimens[index].quantity > 0 ? associatedSpecimens[index].quantity : ''}
                                    onChange={e => {
                                      let newSpecimens = [...associatedSpecimens];
                                      newSpecimens[index] = { ...associatedSpecimens[index], quantity: Number(e.target.value) };
                                      setAssociatedSpecimens(newSpecimens);
                                      Number(e.target.value) > 0 && setWasModified(true);
                                    }}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={7}>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {/** Mobile View */}
                    <div className="xs-inventory-transactions">
                      {bulkOrderTransaction?.inventoryTransactions?.map((transaction, index) => {
                        return (
                          <div
                            key={'specimen-row:' + transaction.specimen?.specimenId + '-index:' + index}
                            className="card max-width">
                            <div className="inventory-transactions-row">
                              <div className="inventory-transactions-content">
                                <label>{`${transaction.specimen?.animal?.code} - ${transaction.specimen?.animal?.name}`}</label>
                                <label>
                                  {transaction?.specimen?.freezeDate &&
                                    `${dateFormatMMDDYYYY(transaction?.specimen?.freezeDate)} - ${
                                      transaction?.specimen?.specimenId
                                    } `}
                                </label>
                              </div>
                              <div className="input-increase-decrease">
                                <FilterDropdown
                                  searchPlaceholder=""
                                  options={increaseOrDecreaseOptions}
                                  value={{
                                    name: associatedSpecimens[index].isIncreasedOrDecrease,
                                    value: associatedSpecimens[index].isIncreasedOrDecrease,
                                  }}
                                  onChange={e => {
                                    let newSpecimens = [...associatedSpecimens];
                                    newSpecimens[index] = { ...associatedSpecimens[index], isIncreasedOrDecrease: e.value };
                                    setAssociatedSpecimens(newSpecimens);
                                  }}
                                />
                              </div>
                              <div className="number-input-section">
                                <label>{TABLE_HEADER_CONSTANTS.QUANTITY}</label>
                                <NumberInput
                                  value={associatedSpecimens[index].quantity}
                                  onChange={e => {
                                    let newSpecimens = [...associatedSpecimens];
                                    newSpecimens[index] = { ...associatedSpecimens[index], quantity: Number(e.target.value) };
                                    setAssociatedSpecimens(newSpecimens);
                                    Number(e.target.value) > 0 && setWasModified(true);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <TransactionLabelField
                    label={`${TABLE_HEADER_CONSTANTS.ANIMAL_NAME}:`}
                    value={`${transaction?.specimen?.animal?.code ?? '-'} - ` + transaction?.specimen?.animal?.name ?? ''}
                  />

                  <TransactionLabelField
                    label={`${TABLE_HEADER_CONSTANTS.INVENTORY_LOT_NUMBER}:`}
                    value={
                      transaction?.specimen?.specimenId +
                      ` - ${
                        transaction?.specimen?.freezeDate
                          ? new Date(transaction.specimen.freezeDate).toLocaleDateString()
                          : 'Date Error'
                      }`
                    }
                  />

                  <TransactionLabelField
                    label={
                      <div className="radio-container">
                        <p>{`I want to ${isIncreased ? 'increase' : 'decrease'} owned amount by: `}</p>
                        <div className="radios">
                          <input type="radio" name="increase" onChange={handleRadioButtons} checked={isIncreased} />
                          <p>{TABLE_HEADER_CONSTANTS.INCREASE}</p>
                          <input type="radio" name="decrease" onChange={handleRadioButtons} checked={!isIncreased} />
                          <p>{TABLE_HEADER_CONSTANTS.DECREASE}</p>
                        </div>
                      </div>
                    }
                    value={
                      <>
                        <ValidatedInput
                          label={''}
                          type="number"
                          validatedStateForAutoFill={{ valid: true, value: displayedQuantity }}
                          setValidatedState={e => {
                            const n = Number(e.value);
                            if (Number.isInteger(n) && n >= 0) handleQuantityChange(n);
                          }}
                          validators={[Validators.REQUIRED]}
                        />
                      </>
                    }
                  />
                </>
              )}

              <TransactionLabelField
                label={`${LABEL_CONSTANTS.NOTES}:`}
                value={
                  <textarea
                    name="notes"
                    id="adjust-notes"
                    cols={10}
                    onChange={e => handleNotesChange(e.target.value)}
                    placeholder={LABEL_CONSTANTS.NOTES}
                    value={
                      bulkOrder || bulkOrderTransaction?.transactionType === TransactionTypeEnum.Withdraw
                        ? `${bulkOrderTransaction?.notes ?? ''}`
                        : `${transaction?.notes ?? ''}`
                    }
                  />
                }
              />

              <div className="submit-button">
                <button type="submit" className="small green button submit" disabled={isDisabled()}>
                  {BUTTON_CONSTANTS.SUBMIT}
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default TransactionManagementAdjust;
