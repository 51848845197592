import { FC, useCallback, useEffect, useState } from 'react';
import Modal from '../../../components/modal/Modal';
import Loader from '../../../components/loader/Loader';
import StoragePlanCard from './storage-plan-card/StoragePlanCard';
import { StoragePlan } from '../../../types/interfaces/account.interfaces';
import { getStoragePlans } from '../../../api/storagePlansApi';
import { PromiseError, toastMessages } from '../../../constants/errorMessages';
import { BUTTON_CONSTANTS, STORAGE_PLAN_CONSTANTS } from '../../../constants/common';
import { showToast } from '../../../services/toast.service';
import './storagePlansModal.scss';

type StoragePlansProps = {
  isOpen: boolean;
  closeStoragePlan: () => void;
};

const StoragePlansModal: FC<StoragePlansProps> = ({ isOpen, closeStoragePlan }) => {
  const [storagePlans, setStoragePlans] = useState<StoragePlan[]>([]);
  const [splicedStoragePlans, setSplicedStoragePlans] = useState<StoragePlan[][]>([]);

  const handleGetStoragePlans = useCallback(async () => {
    try {
      const response = await getStoragePlans();
      setStoragePlans(response.data);
      const storagePlansCopy = [...response.data];
      const splicedStoragePlansArray = [];

      while (storagePlansCopy.length > 0) {
        splicedStoragePlansArray.push(storagePlansCopy.splice(0, 2));
      }
      setSplicedStoragePlans(splicedStoragePlansArray);
    } catch (error: any) {
      if (error.code === PromiseError.NETWORK) {
        showToast.error(toastMessages.NETWORK);
      } else {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  }, []);

  useEffect(() => {
    if (isOpen && storagePlans.length === 0) {
      handleGetStoragePlans();
    }
  }, [isOpen, handleGetStoragePlans, storagePlans]);

  return (
    <Modal onClose={closeStoragePlan} width={1087} isOpen={isOpen}>
      <div className="storage-plans-modal">
        <h4 className="header">{STORAGE_PLAN_CONSTANTS.STORAGE_RATES}</h4>
        <p className="subheader">{STORAGE_PLAN_CONSTANTS.STORAGE_RATES_SUBHEADER}</p>
        <br />
        <div className="storage-plans-list">
          {storagePlans.length === 0 && <Loader loaderSize="small" />}
          {storagePlans.map((plan, index) => (
            <StoragePlanCard
              key={index}
              storagePlanId={plan.storagePlanId}
              name={plan.name}
              costInCents={plan.costInCents}
              unitOfMeasurement={plan.unitOfMeasurement}
              period={plan.period}
            />
          ))}
        </div>
        <div className="xs-storage-plans-list">
          <div className="storage-plan-row">
            {splicedStoragePlans.map((splicedStoragePlan, index) => (
              <div key={index} className="row">
                {splicedStoragePlan.map((storagePlan, index) => (
                  <div key={index + storagePlan.storagePlanId} className="cell">
                    <StoragePlanCard
                      key={index}
                      storagePlanId={storagePlan.storagePlanId}
                      name={storagePlan.name}
                      costInCents={storagePlan.costInCents}
                      unitOfMeasurement={storagePlan.unitOfMeasurement}
                      period={storagePlan.period}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <br />
        <br />
        <div className="done-button">
          <button onClick={() => closeStoragePlan()} className="button green small">
            {BUTTON_CONSTANTS.CLOSE}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default StoragePlansModal;
