import { FC, useEffect, useState } from 'react';
import PagedTable from '../../sorted-table/PagedTable';
import Loader from '../../loader/Loader';
import Tooltip from '../../tooltip/Tooltip';
import { showToast } from '../../../services/toast.service';
import { centsToDollars } from '../../../services/account.service';
import { getPaymentTransactions, getPaymentTransactionsOdata } from '../../../api/paymentGatewayApi';
import { PaymentTransaction, StandardParamsToODataParams } from '../../../types/interfaces';
import { toastMessages } from '../../../constants/errorMessages';
import { APP_PATHS } from '../../../constants/routePaths';
import { LABEL_CONSTANTS, PAYMENT_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../../constants/common';
import './paymentHistory.scss';

const PaymentHistory: FC<{ accountId?: number; refresh?: number }> = ({ accountId, refresh }) => {
  const [paymentHistory, setPaymentHistory] = useState<PaymentTransaction[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const currentRouteLocation = location?.pathname?.split('/');
  const appPage = currentRouteLocation && currentRouteLocation[2];

  useEffect(() => {
    handleGetPaymentHistory();
  }, [accountId, refresh]);

  const handleGetPaymentHistory = async () => {
    try {
      setIsLoading(true);
      if (accountId) {
        const params = { filter: `accountId eq ${accountId}`, sort: '^billedDate' };
        const { data: paymentHistoryResponse } = await getPaymentTransactionsOdata(StandardParamsToODataParams(params));
        setPaymentHistory(paymentHistoryResponse);
      } else {
        const params = { sort: '^billedDate' };
        const { data: paymentHistoryResponse } = await getPaymentTransactions(params);
        setPaymentHistory(paymentHistoryResponse);
      }
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setIsLoading(false);
    }
  };

  const getPaymentMethod = (history: PaymentTransaction) => {
    return (
      <>
        {history?.method === 'Direct'
          ? PAYMENT_CONSTANTS.DIRECT
          : history?.method === 'NotAvailable'
          ? LABEL_CONSTANTS.NOT_AVAILABLE
          : `${history?.method === 'RecurringCard' ? PAYMENT_CONSTANTS.CARD : PAYMENT_CONSTANTS.ACH}` +
            `....${history?.maskedAccountNumber.slice(-4)}`}
        {(history?.method === 'Direct' || history?.method === 'NotAvailable') &&
          appPage &&
          Object.values(APP_PATHS).includes(appPage) && <Tooltip>{history?.notes ?? '-NA-'}</Tooltip>}
      </>
    );
  };

  const getPaymentStatus = (status: string) => (status === 'NotAttempted' ? 'Failed' : status ?? '-');

  const InfoLabel = ({ label, value }: { label: string; value: React.ReactNode }) => (
    <div className="payment-history-content">
      <label>{label}:</label>
      <label>{value}</label>
    </div>
  );

  return (
    <div className="payment-history-container">
      <PagedTable
        headers={[
          { displayName: TABLE_HEADER_CONSTANTS.BILL_DATE, sortValue: 'billedDate' },
          { displayName: TABLE_HEADER_CONSTANTS.AMOUNT },
          { displayName: TABLE_HEADER_CONSTANTS.AVERAGE_DAILY_BALANCE },
          { displayName: TABLE_HEADER_CONSTANTS.PAYMENT_METHOD },
          { displayName: TABLE_HEADER_CONSTANTS.REFERENCE_ID },
          { displayName: TABLE_HEADER_CONSTANTS.STATUS },
        ]}
        sortBy="^billedDate"
        filter={accountId ? `accountId eq ${accountId}` : ''}
        getData={params => {
          return accountId ? getPaymentTransactionsOdata(StandardParamsToODataParams(params)) : getPaymentTransactions(params);
        }}
        buildRow={history => {
          return [
            `${new Date(history?.billedDate).toLocaleDateString('en-US')}`,
            `$${centsToDollars(history?.costInCents)}`,
            history?.averageDailyBalance,
            getPaymentMethod(history),
            history?.transactionReferenceId,
            getPaymentStatus(history?.status),
          ];
        }}
        height={240}
        pageSize={6}
        noResultsMessage={LABEL_CONSTANTS.NO_RESULTS_FOUND}
        refresh={refresh}
      />

      <div className="xs-payment-history">
        {!isLoading && paymentHistory?.length === 0 ? (
          <div className="result-item">{LABEL_CONSTANTS.NO_RESULTS_FOUND}</div>
        ) : (
          <>
            {paymentHistory &&
              paymentHistory.map((history, index) => {
                return (
                  <div key={history?.transactionReferenceId + index} className="card payment-history-card">
                    <div className="payment-history-info">
                      <InfoLabel
                        label={TABLE_HEADER_CONSTANTS.BILL_DATE}
                        value={new Date(history?.billedDate).toLocaleDateString('en-US') ?? '-'}
                      />
                      <InfoLabel
                        label={TABLE_HEADER_CONSTANTS.AMOUNT}
                        value={`$${centsToDollars(history?.costInCents) ?? '0'}`}
                      />
                      <InfoLabel
                        label={TABLE_HEADER_CONSTANTS.AVERAGE_DAILY_BALANCE}
                        value={`${history?.averageDailyBalance ?? '-'}`}
                      />
                    </div>
                    <div className="payment-history-info">
                      <InfoLabel label={TABLE_HEADER_CONSTANTS.PAYMENT_METHOD} value={getPaymentMethod(history)} />
                      <InfoLabel
                        label={TABLE_HEADER_CONSTANTS.REFERENCE_ID}
                        value={`${history?.transactionReferenceId ?? '-'}`}
                      />
                      <InfoLabel label={TABLE_HEADER_CONSTANTS.STATUS} value={getPaymentStatus(history?.status)} />
                    </div>
                  </div>
                );
              })}
          </>
        )}
        {isLoading && <Loader addedSpace loaderSize="small" />}
      </div>
    </div>
  );
};

export default PaymentHistory;
