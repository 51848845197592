import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import BackButton from '../../components/back-button/BackButton';
import FilterDropdown from '../../components/custom-input/FilterDropdown';
import ProfileImage from '../../components/profile-image/ProfileImage';
import { Loader } from '../../components';
import { showToast } from '../../services/toast.service';
import { setActiveAccount } from '../../services/account.service';
import { updateUserInAccount } from '../../api/accountApi';
import { getUserProfileImageById } from '../../api/userApi';
import { selectOption } from '../../types/interfaces/selectOption.interface';
import { getRoleValue, hasGreaterAccess } from '../../types/enums/accountRoles.enum';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { toastMessages } from '../../constants/errorMessages';

const EditTeam: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state: location } = useLocation();
  const { optionSelectAccountRoles, accountRoles } = useAppSelector(state => state.roles);
  const accountRole = useAppSelector(state => state.auth.accountContext.roles);
  const { accountId } = useAppSelector(state => state.activeAccount);

  const { firstName, lastName, roles: userRoles, userId, username, profileImageId } = location;
  const [profileImage, setProfileImage] = useState<Blob>();
  const [updatingTeam, setUpdatingTeam] = useState<boolean>(true);
  const [accessLevel, setAccessLevel] = useState<selectOption>({
    name: '',
    value: '',
  });

  useEffect(() => {
    const loadUserImage = async () => {
      try {
        const blobImage = await getUserProfileImageById(userId, profileImageId);
        setProfileImage(blobImage);
      } catch (e) {
        setProfileImage(new Blob());
      }
    };
    if (userId) {
      loadUserImage();
    }
  }, [userId]);

  const handleUpdate = async () => {
    if (!updatingTeam) return;
    setUpdatingTeam(false);

    await updateUserInAccount(Number(accountId), userId, [accessLevel.value])
      .then(() => {
        showToast.success(toastMessages.SUCCESS);
        setActiveAccount(Number(accountId));
        navigate(ROUTE_PATHS.APP_TEAM_MANAGEMENT);
      })
      .catch(() => {
        showToast.error(toastMessages.FAILED_TO_UPDATE_TEAM_MEMBER);
      })
      .finally(() => {
        setUpdatingTeam(true);
      });
  };

  useEffect(() => {
    const currentRole = () => {
      const found = accountRoles.find(r => userRoles.split(', ')[0] === r?.name);
      if (found) {
        setAccessLevel(found);
      } else {
        setAccessLevel({
          name: '',
          value: '',
        });
      }
    };

    currentRole();
  }, [accountRoles, userRoles]);

  return (
    <>
      <BackButton />
      <div className="card user-profile">
        <div className="image-header" style={{ backgroundImage: '' }}>
          <ProfileImage profileImageBlob={profileImage} profileImageId={profileImageId} />
        </div>

        <div className="edit-user-container">
          <h3>
            Edit permissions for {firstName} {lastName}
          </h3>
          <div className="select-container">
            <label>Role</label>
            <FilterDropdown
              options={optionSelectAccountRoles.filter(r => hasGreaterAccess(r.name, getRoleValue(accountRole)))}
              value={accessLevel}
              onChange={e => {
                setAccessLevel({ value: e.value, name: e.name });
              }}
            />
          </div>
          {!updatingTeam && <Loader loaderSize={'small'} simple />}

          <button onClick={handleUpdate} className="button blue">
            Update
          </button>
        </div>
      </div>
    </>
  );
};

export default EditTeam;
