import { Route, Routes } from 'react-router-dom';
import AnimalOverview from '../pages/animal-management/animal-overview/AnimalOverview';
import EditAnimal from '../pages/animal-management/edit-animal/EditAnimal';
import SpecimenViewEdit from '../pages/animal-management/SpecimenViewEdit';
import { ANIMAL_MANAGEMENT_PATHS } from '../constants/routePaths';

const AnimalManagementRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path={ANIMAL_MANAGEMENT_PATHS.ITEM} element={<AnimalOverview />} />
        <Route path={ANIMAL_MANAGEMENT_PATHS.ITEM_ + ANIMAL_MANAGEMENT_PATHS.EDIT} element={<EditAnimal />} />
        <Route path={ANIMAL_MANAGEMENT_PATHS.ITEM_ + ANIMAL_MANAGEMENT_PATHS.SPECIMEN} element={<SpecimenViewEdit />} />
      </Routes>
    </div>
  );
};

export default AnimalManagementRoutes;
