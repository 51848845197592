import Modal from '../../../../components/modal/Modal';
import './deactivateProfileModal.scss';

export type DeactivateProfileConfirmModalProps = {
  isOpen: boolean;
  width?: number;
  onClose: () => void;
  onConfirm: () => void;
};

function DeactivateProfileConfirmModal({ isOpen, width = 700, onClose, onConfirm }: DeactivateProfileConfirmModalProps) {
  return (
    <div className="deactivate-profile-modal">
      <Modal width={width} isOpen={isOpen} onClose={onClose} ignoreBackdrop>
        <h2 className="modal-header">Deactivate Profile</h2>
        <div className="modal-body modal-body-confirm">
          <p>You are about to deactivate your profile, this action will:</p>
          <ul>
            <li>Deactivate any accounts owned by you. </li>
            <li>Remove all collaborators from your accounts.</li>
            <li>Remove you as a collaborator from accounts owned by others.</li>
            <li>Automatically log you out.</li>
          </ul>
        </div>
        <div className="modal-footer modal-footer-confirm">
          <button type="button" className="medium inverted blue button" onClick={onClose}>
            Cancel
          </button>
          <button type="button" className="medium red button" onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default DeactivateProfileConfirmModal;
