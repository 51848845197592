type SortArrowProps = {
  direction: string;
};

const SortArrow = ({direction}: SortArrowProps): JSX.Element => {
  return (
    <svg
      width="10"
      height="5"
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      {direction === 'down' ? (
        <path d="M10 0L5 5L0 0L10 0Z" fill="#272829" />
      ) : (
        <path d="M0 5L5 0L10 5H0Z" fill="#272829" />
      )}
    </svg>
  );
};
export default SortArrow;
