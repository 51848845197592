import Modal from '../modal/Modal';
import { BUTTON_CONSTANTS } from '../../constants/common';
import './lockUnlockAccountModal.scss';

export type LockUnlockAccountModalProps = {
  isOpen: boolean;
  accountLocked: boolean;
  width?: number;
  onClose: () => void;
  onConfirm: () => void;
};

const LockUnlockAccountModal = ({ isOpen, accountLocked, width = 600, onClose, onConfirm }: LockUnlockAccountModalProps) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} width={width}>
        <div className="lock-unlock-account-modal">
          <div className="header">
            <h4>{`${accountLocked ? 'Unlock' : 'Lock'} Account`}</h4>
          </div>
          <div className="body">
            <p>Are you sure you want to {`${accountLocked ? 'unlock' : 'lock'}`} the account?</p>
          </div>
          <div className="footer">
            <button className="button blue inverted small" onClick={onClose}>
              {BUTTON_CONSTANTS.CANCEL}
            </button>
            <button className="button red small" onClick={onConfirm}>
              {BUTTON_CONSTANTS.YES}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LockUnlockAccountModal;
