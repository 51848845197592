import { FC } from 'react';
import ProfileImage from '../profile-image/ProfileImage';
import { TeamManagementUser } from '../../types/interfaces/user.interfaces';
import './userCard.scss';

interface UserCardProps {
  user: TeamManagementUser;
  profileImage?: Blob;
}

const UserCard: FC<UserCardProps> = ({ user, profileImage }) => {
  return (
    <div className="user-card">
      <ProfileImage profileImageId={user.profileImageId} profileImageBlob={profileImage} className="user-profile-image" />
      <div className="user-card-name">
        <p>{user.fullName}</p>
        <p className="email-address">{user.username}</p>
      </div>
    </div>
  );
};

export default UserCard;
