import { FC, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import ValidatedInput, { ValidatedState } from '../../../components/custom-input/ValidatedInput';
import Modal from '../../../components/modal/Modal';
import TermsAndConditions from '../../../components/terms-and-conditions/TermsAndConditions';
import { BusinessFarmNameTooltip } from '../../../components/tooltip/customTooltips';
import StoragePlansModal from '../../auth/storage-plans/StoragePlansModal';
import { setActiveAccount } from '../../../services/account.service';
import { signOutUser } from '../../../services/auth.service';
import { showToast } from '../../../services/toast.service';
import { requestUserPersonalInfo } from '../../../services/user.service';
import { createAccount } from '../../../api/accountApi';
import { Account } from '../../../types/interfaces/account.interfaces';
import { Validators } from '../../../types/enums/validators.enum';
import { PromiseError, toastMessages } from '../../../constants/errorMessages';
import { ACCOUNT_CONSTANTS, BUTTON_CONSTANTS } from '../../../constants/common';
import './addAccountModal.scss';

type AddAccountModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  storagePlansModalOpen: boolean;
  setStoragePlansModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isActiveUser: boolean;
  setAddPaymentMethodModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setAccountId: (id: number) => void;
};

const AddAccountModal: FC<AddAccountModalProps> = ({
  isOpen,
  setIsOpen,
  storagePlansModalOpen,
  setStoragePlansModalOpen,
  isActiveUser,
  setAddPaymentMethodModalVisible,
  setAccountId,
}) => {
  const [contactFirstName, setContactFirstName] = useState<ValidatedState>({ value: '', valid: false });
  const [contactLastName, setContactLastName] = useState<ValidatedState>({ value: '', valid: false });
  const [contactEmail, setContactEmail] = useState<ValidatedState>({ value: '', valid: false });
  const [businessName, setBusinessName] = useState<ValidatedState>({ value: '', valid: true });
  const [contactPhoneNumber, setContactPhoneNumber] = useState<ValidatedState>({ value: '', valid: false });
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [validate, setValidate] = useState<boolean>(false);

  const user = useAppSelector(state => state.user);

  const handleOpenCloseModals = () => {
    setStoragePlansModalOpen(prev => !prev);
    setIsOpen(prev => !prev);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    {
      let newAccount: Account = {
        name: businessName.value ? businessName.value : contactFirstName.value + ' ' + contactLastName.value,
        contactFirstName: contactFirstName.value,
        contactLastName: contactLastName.value,
        contactEmail: contactEmail.value,
        contactPhone: contactPhoneNumber.value,
        owner: { userId: user.userId },
      };
      try {
        setStoragePlansModalOpen(false);
        setIsOpen(false);
        const { data: accountResponse } = await createAccount(newAccount);
        accountResponse.accountId && (await setActiveAccount(accountResponse?.accountId));
        await requestUserPersonalInfo();
        showToast.success(toastMessages.ACCOUNT_CREATED);
        setAccountId(accountResponse.accountId);
      } catch (error: any) {
        if (error.code === PromiseError.NETWORK) {
          showToast.error(toastMessages.NETWORK);
        } else {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      }
    }
    setAddPaymentMethodModalVisible(true);
  };

  return (
    <>
      <Modal isOpen={isOpen} width={724} ignoreBackdrop onClose={() => setIsOpen(false)}>
        <div className="add-account-modal">
          <div className="header">
            <h4>{ACCOUNT_CONSTANTS.ADD_ACCOUNT}</h4>
            {!isActiveUser && <p className="body1">{ACCOUNT_CONSTANTS.CREATE_ACCOUNT_SUBHEADER}</p>}
          </div>
          <br />
          <form action="submit" onSubmit={(e: React.FormEvent) => handleSubmit(e)}>
            <div className="input">
              <ValidatedInput
                useId="BusinessName"
                label="Business/Farm Name"
                placeholder={`${contactFirstName.value} ${contactLastName.value}`}
                validatedStateForAutoFill={businessName}
                setValidatedState={setBusinessName}
                validate={validate}
                customTooltip={<BusinessFarmNameTooltip />}
                validators={[]}
              />
            </div>

            <div className="input-row">
              <div className="input">
                <ValidatedInput
                  useId="firstName"
                  label="*Contact First Name"
                  validatedStateForAutoFill={contactFirstName}
                  setValidatedState={setContactFirstName}
                  validate={validate}
                  validators={[Validators.REQUIRED]}
                />
              </div>

              <div className="input">
                <ValidatedInput
                  useId="lastName"
                  label="*Contact Last Name"
                  validatedStateForAutoFill={contactLastName}
                  setValidatedState={setContactLastName}
                  validate={validate}
                  validators={[Validators.REQUIRED]}
                />
              </div>
            </div>

            <div className="input">
              <ValidatedInput
                useId="email"
                label="*Contact Email"
                validatedStateForAutoFill={contactEmail}
                setValidatedState={setContactEmail}
                validate={validate}
                validators={[Validators.REQUIRED, Validators.EMAIL]}
              />
            </div>

            <div className="input">
              <ValidatedInput
                type="phone"
                useId="PhoneNumber"
                label="*Contact Phone Number"
                validatedStateForAutoFill={contactPhoneNumber}
                setValidatedState={setContactPhoneNumber}
                validate={validate}
                validators={[Validators.REQUIRED, Validators.PHONE_LENGTH]}
              />
            </div>
            <TermsAndConditions
              acceptedTerms={acceptedTerms}
              setAcceptedTerms={setAcceptedTerms}
              openTermsModal={() => {
                handleOpenCloseModals();
              }}
            />
            <br />
            <div className="buttons">
              {!isActiveUser ? (
                <button type="button" className="button green inverted medium" onClick={() => signOutUser()}>
                  {BUTTON_CONSTANTS.SIGN_OUT}
                </button>
              ) : (
                <button type="button" className="button green inverted medium" onClick={() => setIsOpen(false)}>
                  {BUTTON_CONSTANTS.CANCEL}
                </button>
              )}
              <button
                className="button green medium"
                type="submit"
                disabled={
                  !contactEmail.valid ||
                  !contactFirstName.valid ||
                  !contactLastName.valid ||
                  !contactPhoneNumber.valid ||
                  !acceptedTerms
                }>
                {ACCOUNT_CONSTANTS.CREATE_ACCOUNT}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <StoragePlansModal isOpen={storagePlansModalOpen} closeStoragePlan={() => handleOpenCloseModals()} />
    </>
  );
};

export default AddAccountModal;
