import { ChangeEvent, Dispatch, FC, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import DefaultProfileImage from '../profile-image/default-profile-image/DefaultProfileImage';
import Loader from '../loader/Loader';
import { showToast } from '../../services/toast.service';
import { getAccountById, getAccounts } from '../../api/accountApi';
import { getUserProfileImageById } from '../../api/userApi';
import { Account } from '../../types/interfaces/account.interfaces';
import { InventoryTransaction } from '../../types/interfaces';
import { AccountRoles } from '../../types/enums';
import { toastMessages } from '../../constants/errorMessages';
import { handleSearchDropdownClasses, onBlurResetHandler } from '../../utils/commonUtils';
import './accountSearch.scss';

type AccountSearchProps = {
  onChange: (account: Account | undefined) => void;
  showIcon?: boolean;
  omitResults?: number[];
  disabled?: boolean;
  placeholder?: string;
  populateAccountObject?: PopulateAccountObject;
  account?: Account;
};

export type PopulateAccountObject = {
  transaction: InventoryTransaction | undefined;
  accountWasCleared: boolean;
  setAccountWasCleared: Dispatch<SetStateAction<boolean>>;
};

const AccountSearch: FC<AccountSearchProps> = ({
  onChange,
  showIcon = false,
  omitResults = [],
  disabled = false,
  placeholder = 'Search',
  populateAccountObject,
  account,
}): JSX.Element => {
  const [searchResults, setSearchResults] = useState<Account[]>([]);
  const [searchInput, setSearchInput] = useState<string | undefined>('');
  const [isDisplayingValue, setIsDisplayingValue] = useState<boolean>(false);
  const [displayedValue, setDisplayedValue] = useState<string>('');
  const [customerAccounts, setCustomerAccounts] = useState<{}[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [offset, setOffset] = useState<number>(0);

  const ref = useRef<any>(null);
  const hasAllResults: MutableRefObject<boolean> = useRef<boolean>(false);
  const isLoadingAdditionalPage: MutableRefObject<boolean> = useRef<boolean>(false);

  const minSearchLength: number = 2;
  const scrollGap = 32;
  const limit = 6;

  useEffect(
    function handleAccountPopulation() {
      if (populateAccountObject?.transaction?.inventoryTransactionId) {
        const { account } = populateAccountObject.transaction;
        const { accountWasCleared } = populateAccountObject;
        if (account && !accountWasCleared) {
          handleSearchClick(account);
        }
      } else if (account) {
        setDisplayedValue(account.name);
        setIsDisplayingValue(true);
      }
    },
    [account],
  );

  useEffect(() => {
    if (searchTerm.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        handleInput(searchTerm);
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm, offset]);

  useEffect(() => {
    if (searchResults.length > 0) {
      setAccountProfileImage();
    }
  }, [searchResults]);

  useEffect(() => {
    isLoadingAdditionalPage.current = false;
  }, [customerAccounts]);

  const handleSearchClick = (account: Account) => {
    setSearchInput('');
    setSearchResults([]);
    setDisplayedValue(account.name);
    setIsDisplayingValue(true);
    onChange(account);
  };

  const handleInput = async (search: string) => {
    if (hasAllResults.current) {
      return;
    }

    if (search.length >= minSearchLength) {
      try {
        const { data: response } = await getAccounts(undefined, {
          filter:
            'name like ' +
            search +
            '||contactFirstName like ' +
            search +
            '||contactLastName like ' +
            search +
            '||contactEmail like ' +
            search,
          limit: limit,
          offset: offset,
        });

        if (offset === 0 && 'current' in ref) {
          ref.current?.scroll({ top: 0 });
        }

        const result = Array.isArray(response) ? response : response.result;
        setSearchResults(result.filter((account: Account) => !omitResults.includes(account.accountId ?? -1)));

        hasAllResults.current = result.length === 0;
        isLoadingAdditionalPage.current = !(result.length === 0);

        if (result.length < limit) {
          isLoadingAdditionalPage.current = false;
        }
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    } else {
      setSearchResults([]);
    }
  };

  function reset(target?: HTMLInputElement) {
    populateAccountObject?.setAccountWasCleared(true);
    setSearchInput('');
    setIsDisplayingValue(false);
    onChange(undefined);

    if (target) target.className = '';
  }

  const setAccountProfileImage = async () => {
    await Promise.all(
      searchResults.map(async (account: any) => {
        try {
          const { data: accountResponse } = await getAccountById(account.accountId, { include: 'Users.Roles' });

          const owner = accountResponse.users.find(
            user => user.roles.findIndex(role => role.name === AccountRoles.AccountOwner) >= 0,
          )?.user;

          if (owner) {
            try {
              const accountProfile = await getUserProfileImageById(owner.userId, owner.profileImageId);
              const rsgAccounts = [...searchResults];
              rsgAccounts.map((acc: any) => {
                if (acc.accountId === account.accountId) {
                  acc.profileImage = accountProfile && URL.createObjectURL(accountProfile);
                }
              });
              setCustomerAccounts([...customerAccounts, ...rsgAccounts]);
            } catch {
              showToast.error(toastMessages.SOMETHING_WENT_WRONG);
            }
          }
        } catch {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      }),
    );
  };

  const loadMore = () => {
    setOffset(offset + limit);
  };

  const handleScroll = (e: any) => {
    const position = e.target.scrollHeight - e.target.scrollTop;
    const atBottom = position - e.target.clientHeight <= scrollGap;
    if (atBottom && !isLoadingAdditionalPage.current) {
      isLoadingAdditionalPage.current = true;
      loadMore();
    }
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    isLoadingAdditionalPage.current = false;
    hasAllResults.current = false;
    setSearchResults([]);
    setCustomerAccounts([]);
    setIsDisplayingValue(false);
    setSearchTerm(event.target.value);
  };

  return (
    <div className="account-search search-dropdown" onBlur={e => onBlurResetHandler(e, reset)}>
      <div className="search-input-wrapper">
        <input
          className={isDisplayingValue ? 'selected' : ''}
          placeholder={placeholder}
          value={isDisplayingValue ? displayedValue : searchInput}
          type="text"
          onFocus={e => e.target.select()}
          onChange={event => {
            setSearchInput(event.target.value);
            handleSearchChange(event);
          }}
          disabled={disabled}
        />
        <span className={handleSearchDropdownClasses(showIcon, searchInput, isDisplayingValue)} onClick={() => reset()}></span>
      </div>
      {searchInput && searchInput.length >= minSearchLength && (
        <div ref={ref} className="results" onScroll={handleScroll}>
          {customerAccounts.length > 0 &&
            customerAccounts.map((account: any, index) => {
              return (
                <div
                  className={
                    account.outstandingBalanceCents > 0 || account.penaltyCents > 0
                      ? 'result-item account-warning'
                      : 'result-item'
                  }
                  key={'account-result-' + account.accountId}
                  onMouseDown={() => handleSearchClick(account)}>
                  {account.profileImage ? (
                    <img className="result-item-image" src={account.profileImage} />
                  ) : (
                    <DefaultProfileImage className="result-item-image" />
                  )}
                  {account.name}
                  {account?.outstandingBalanceCents > 0 || account?.penaltyCents > 0 ? ' (Due Payment)' : ''}
                </div>
              );
            })}
          {customerAccounts.length === 0 && (
            <div className="result-item">{!hasAllResults.current ? 'Loading...' : 'No results found.'}</div>
          )}
          {customerAccounts.length > 0 && !hasAllResults.current && isLoadingAdditionalPage.current && (
            <div className="result-item-loader">
              <Loader loaderSize="small" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AccountSearch;
