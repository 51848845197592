export interface StandardParams {
  filter?: string;
  include?: string;
  limit?: number;
  offset?: number;
  sort?: string;
}

export interface ODataParams {
  $filter?: string;
  $orderby?: string;
  $select?: string;
  $skip?: string;
  $top?: string;
  include?: string;
}

export const StandardParamsToODataParams = (params: StandardParams): ODataParams => {
  let result = {};

  if (params.include !== undefined && params.include !== null && params.include.length > 0) {
    result = { ...result, include: params.include };
  }

  // TODO: convert ; delimiters to and
  if (params.filter !== undefined && params.filter !== null && params.filter.length > 0) {
    result = { ...result, $filter: params.filter };
  }

  // TODO: make work with list of sort values
  if (params.sort !== undefined && params.sort !== null && params.sort.length > 0) {
    const sortString = params.sort.includes('^') ? params.sort.replace('^', '') + ' desc' : params.sort;
    if (sortString.length > 0) {
      result = { ...result, $orderby: sortString };
    }
  }

  if (params.limit !== undefined && params.limit !== null) {
    result = { ...result, $top: params.limit };
  }

  if (params.offset !== undefined && params.offset !== null && params.offset > 0) {
    result = { ...result, $skip: params.offset };
  }

  return result;
};

export interface GetAccountAnimalParams {
  breedId?: number;
  include?: string;
  limit?: number;
  offset?: number;
  searchString?: string;
  sort?: string;
  speciesId?: number;
  specimenTypeId?: number;
}

export interface SpecimensInventoryParams {
  animalId?: number;
  canisterId?: number;
  include?: string;
  limit?: number;
  offset?: number;
  sort?: string[];
  specimenTypeId?: number;
}

export interface InventoryParams {
  accountId?: number;
  onlyCountAvailableInventory?: boolean;
}

export interface PagedResponse<T> {
  limit: number;
  offset: number;
  result: T[];
  total: number;
}

export interface GetVariancesResponse {
  animalCode: string;
  animalName: string;
  canisterCount: number;
  freezeDate: Date;
  originalQuantity: number;
  quantityOwned: number;
  specimenId: number;
  variance: number;
}
