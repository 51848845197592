import { FC } from 'react';
import FilterDropdown, { FilterOption } from '../custom-input/FilterDropdown';
import ValidatedInput from '../custom-input/ValidatedInput';
import { SearchSVG } from '../svgs';
import './searchBy.scss';

interface SearchByProps {
  search: { criteria: FilterOption<any>; query: string };
  setSearch: (e: { criteria: FilterOption<any>; query: string }) => void;
  searchCriteriaOptions: FilterOption<any>[];
}

const SearchBy: FC<SearchByProps> = ({ searchCriteriaOptions, search, setSearch }) => {
  return (
    <>
      <div className="search-by">
        <p className="search-by-label">Search By:</p>

        <div className="input-stack">
          <FilterDropdown
            placeholder="Select"
            options={searchCriteriaOptions}
            value={{
              name: search?.criteria.name ?? '',
              value: search?.criteria.value ?? '',
            }}
            onChange={e => {
              setSearch({ criteria: e, query: '' });
            }}
          />

          <ValidatedInput
            disabled={search?.criteria.name === ''}
            validatedStateForAutoFill={{ value: search?.query ?? '', valid: true }}
            placeholder={''}
            label={''}
            setValidatedState={e => setSearch({ ...search, query: e.value })}
            validate={false}
            validators={[]}
          />
          <input disabled type="text" className="icon-holder" />
          <SearchSVG />
        </div>
      </div>
    </>
  );
};

export default SearchBy;
