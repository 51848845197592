import { Route, Routes } from 'react-router-dom';
import TransactionCartWithdraw from '../pages/transaction-cart/TransactionCartWithdraw';
import { PermissionService } from '../services/permission.service';
import { TRANSACTION_CART_PATHS } from '../constants/routePaths';

type TransactionCartWithdrawProps = {
  permissionService: PermissionService;
};

const TransactionCartRoutes = ({ permissionService }: TransactionCartWithdrawProps) => {
  return (
    <div>
      <Routes>
        <Route
          path={TRANSACTION_CART_PATHS.WITHDRAW}
          element={<TransactionCartWithdraw permissionService={permissionService} />}
        />
      </Routes>
    </div>
  );
};

export default TransactionCartRoutes;
