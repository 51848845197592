import { FC, useCallback, useEffect, useState } from 'react';
import SortedTable from '../sorted-table/SortedTable';
import Loader from '../loader/Loader';
import { getLinkedStorageMovements } from '../../api/inventoryTransactionsApi';
import { InventoryTransaction } from '../../types/interfaces';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';
import { LABEL_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../constants/common';
import './linkedTransactions.scss';

export interface LinkedStorageMovement {
  added: number | null;
  animal?: string;
  beginningQuantity: number | null;
  removed: number | null;
  resultingQuantity: number;
  specimenLocationHistoryId: number;
  storageLocation: string;
}

interface LinkedStorageMovementsProps {
  inventoryTransactions: InventoryTransaction | InventoryTransaction[] | undefined;
}

const LinkedStorageMovements: FC<LinkedStorageMovementsProps> = ({ inventoryTransactions }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [storageMovements, setStorageMovements] = useState<LinkedStorageMovement[]>([]);

  const tableHeaders = [
    { displayName: TABLE_HEADER_CONSTANTS.ANIMAL },
    { displayName: TABLE_HEADER_CONSTANTS.LOCATION },
    { displayName: TABLE_HEADER_CONSTANTS.BEGINNING_QTY },
    { displayName: TABLE_HEADER_CONSTANTS.ADDED_QTY },
    { displayName: TABLE_HEADER_CONSTANTS.REMOVED_QTY },
    { displayName: TABLE_HEADER_CONSTANTS.RESULTING_QTY },
  ];

  const getStorageMovements = useCallback(async () => {
    try {
      setIsLoading(true);
      const InventoryTrans = inventoryTransactions
        ? Array.isArray(inventoryTransactions)
          ? inventoryTransactions
          : [inventoryTransactions]
        : [];

      const updatedStorageMovements = await Promise.all(
        InventoryTrans?.map(async (transaction: InventoryTransaction): Promise<any> => {
          const { data: linkedStorageMovements } = await getLinkedStorageMovements(transaction?.inventoryTransactionId!);
          return linkedStorageMovements.map((linkedStorageMovement: LinkedStorageMovement) => {
            return {
              ...linkedStorageMovement,
              animal: transaction?.specimen?.animal?.code + ' - ' + transaction?.specimen?.animal?.name,
            };
          });
        }),
      );
      setStorageMovements(updatedStorageMovements.flat());
    } catch (error: any) {
      showToast.error(toastMessages.FAILED_TO_RETRIEVE_LINKED_STORAGE_MOVEMENTS);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getStorageMovements();
  }, [getStorageMovements]);

  return (
    <div className="linked-storage-movements">
      {isLoading ? (
        <Loader loaderSize="small" addedSpace />
      ) : storageMovements.length ? (
        <>
          <div className="desk-storage-movements">
            <SortedTable
              headers={tableHeaders}
              data={storageMovements?.map((t: LinkedStorageMovement) => {
                return [
                  { content: t?.animal },
                  { content: t?.storageLocation },
                  { content: t?.beginningQuantity != null ? t?.beginningQuantity : '-' },
                  { content: t?.added != null ? t?.added : '-' },
                  { content: t?.removed != null ? t?.removed : '-' },
                  { content: t?.resultingQuantity != null ? t?.resultingQuantity : '-' },
                ];
              })}
            />
          </div>

          {/** Mobile View */}
          <div className="xs-storage-movements">
            {storageMovements &&
              storageMovements.map((storageMovement, index) => {
                return (
                  <div className="card max-width" key={'storage-movement-' + storageMovement.specimenLocationHistoryId + index}>
                    <div className="storage-movements-content">
                      <div className="transaction-info">
                        <div className="transaction-content">
                          <label>{TABLE_HEADER_CONSTANTS.ANIMAL} :</label>
                          <label>{`${storageMovement?.animal}`}</label>
                        </div>
                        <div className="transaction-content">
                          <label>{TABLE_HEADER_CONSTANTS.LOCATION} :</label>
                          <label>{`${storageMovement?.storageLocation}`}</label>
                        </div>
                        <div className="transaction-content">
                          <label>{TABLE_HEADER_CONSTANTS.BEGINNING_QTY} :</label>
                          <label>{`${
                            storageMovement?.beginningQuantity != null ? storageMovement?.beginningQuantity : '-'
                          }`}</label>
                        </div>
                      </div>
                      <div className="transaction-info">
                        <div className="transaction-content">
                          <label>{TABLE_HEADER_CONSTANTS.ADDED_QTY} :</label>
                          <label>{`${storageMovement?.added != null ? storageMovement?.added : '-'}`}</label>
                        </div>
                        <div className="transaction-content">
                          <label>{TABLE_HEADER_CONSTANTS.REMOVED_QTY} :</label>
                          <label>{`${storageMovement?.removed != null ? storageMovement?.removed : '-'}`}</label>
                        </div>
                        <div className="transaction-content">
                          <label>{TABLE_HEADER_CONSTANTS.RESULTING_QTY} :</label>
                          <label>{`${
                            storageMovement?.resultingQuantity != null ? storageMovement?.resultingQuantity : '-'
                          }`}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      ) : (
        <h4 className="no-linked-movements">{LABEL_CONSTANTS.NO_LINKED_STORAGE_MOVEMENTS}</h4>
      )}
    </div>
  );
};

export default LinkedStorageMovements;
