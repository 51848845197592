const OpenSVG = (): JSX.Element => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.125 17.25C1.75833 17.25 1.4375 17.1125 1.1625 16.8375C0.8875 16.5625 0.75 16.2417 0.75 15.875V2.125C0.75 1.75833 0.8875 1.4375 1.1625 1.1625C1.4375 0.8875 1.75833 0.75 2.125 0.75H7.83125C8.02604 0.75 8.18932 0.816267 8.32109 0.948802C8.45286 1.08135 8.51875 1.24559 8.51875 1.44151C8.51875 1.63745 8.45286 1.80035 8.32109 1.93021C8.18932 2.06007 8.02604 2.125 7.83125 2.125H2.125V15.875H15.875V10.1687C15.875 9.97396 15.9413 9.81068 16.0738 9.67891C16.2064 9.54714 16.3706 9.48125 16.5665 9.48125C16.7624 9.48125 16.9253 9.54714 17.0552 9.67891C17.1851 9.81068 17.25 9.97396 17.25 10.1687V15.875C17.25 16.2417 17.1125 16.5625 16.8375 16.8375C16.5625 17.1125 16.2417 17.25 15.875 17.25H2.125ZM6.2759 11.7181C6.15169 11.5866 6.08576 11.4292 6.07812 11.2458C6.07049 11.0625 6.13542 10.9021 6.27292 10.7646L14.9125 2.125H10.5813C10.3865 2.125 10.2232 2.05873 10.0914 1.9262C9.95964 1.79365 9.89375 1.62941 9.89375 1.43349C9.89375 1.23755 9.95964 1.07465 10.0914 0.944792C10.2232 0.814931 10.3865 0.75 10.5813 0.75H16.5625C16.7573 0.75 16.9206 0.815885 17.0523 0.947656C17.1841 1.07943 17.25 1.24271 17.25 1.4375V7.41875C17.25 7.61354 17.1837 7.77682 17.0512 7.90859C16.9186 8.04036 16.7544 8.10625 16.5585 8.10625C16.3626 8.10625 16.1997 8.04036 16.0698 7.90859C15.9399 7.77682 15.875 7.61354 15.875 7.41875V3.11042L7.23542 11.75C7.10644 11.8722 6.94816 11.9333 6.76056 11.9333C6.57296 11.9333 6.41141 11.8616 6.2759 11.7181Z"
        fill="#545556"
      />
    </svg>
  );
};
export default OpenSVG;
