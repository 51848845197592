import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import Loader from '../loader/Loader';
import { SpecimenInventoryWithUseRequested } from '../../types/interfaces/specimen.interfaces';
import { selectOption } from '../../types/interfaces';
import FilterDropdown from '../custom-input/FilterDropdown';

type PendingUseTransactionsTableProps = {
  useTransactions: SpecimenInventoryWithUseRequested[];
  setActivePendingUseTransaction: Dispatch<SetStateAction<SpecimenInventoryWithUseRequested | undefined>>;
  loading?: boolean;
  populateByTransactionId?: number | undefined;
};

const PendingUseTransactionsTable: FC<PendingUseTransactionsTableProps> = ({
  useTransactions,
  setActivePendingUseTransaction,
  loading = false,
  populateByTransactionId,
}) => {
  const initialState = { value: '', name: '' };
  const [pendingUseTransactions, setPendingUseTransactions] = useState<SpecimenInventoryWithUseRequested[]>();
  const [selectedRow, setSelectedRow] = useState<Partial<SpecimenInventoryWithUseRequested>>({});
  const [pendingUseTransactionOptions, setPendingUseTransactionOptions] = useState<selectOption[]>([initialState]);
  const [selectedPendingUseTransactionValue, setSelectedPendingUseTransactionValue] = useState<selectOption>(initialState);
  const [selectedPendingUseTransactionIndex, setSelectedPendingUseTransactionIndex] = useState<number>(-1);

  useEffect(() => {
    setPendingUseTransactionOptions(
      useTransactions.map((specimen: SpecimenInventoryWithUseRequested, index) => {
        return {
          name: `${specimen.animal?.code} - ${specimen.animal?.name} - ${new Date(
            specimen.freezeDate.toString() as string,
          ).toLocaleDateString()} - ${specimen.specimenId}`,
          value: `${index}`,
        };
      }),
    );

    setPendingUseTransactions(useTransactions);
  }, [useTransactions]);

  useEffect(() => {
    findAndSetRowById(populateByTransactionId);
  }, [pendingUseTransactions, populateByTransactionId]);

  function findAndSetRowById(inventoryTransactionId: number | undefined, index?: number) {
    if (Boolean(pendingUseTransactions?.length && inventoryTransactionId)) {
      const selected = pendingUseTransactions?.find(specimen => specimen.inventoryTransactionId === inventoryTransactionId);
      const specimenIndex = pendingUseTransactions?.findIndex(
        specimen => specimen.inventoryTransactionId === populateByTransactionId,
      );
      const selectedSpecimenIndex = index != undefined ? index : specimenIndex ?? -1;

      if (selected) {
        setSelectedRow(selected);
        setActivePendingUseTransaction(selected);
        setSelectedPendingUseTransactionIndex(selectedSpecimenIndex);
        setSelectedPendingUseTransactionValue(pendingUseTransactionOptions[selectedSpecimenIndex]);
      }
    }
  }

  return (
    <div className="pending-use-transaction-checkout">
      <div className="desk-pending-use-transaction">
        <div className="specimen-table">
          <table>
            <thead>
              <tr>
                <th>Select</th>
                <th>Link ID</th>
                <th>Animal</th>
                <th>Lot Date / Number</th>
                <th>Owned Qty</th>
                <th>Available Qty</th>
                <th>Use Qty</th>
              </tr>
            </thead>
            <tbody>
              {pendingUseTransactions != undefined &&
                pendingUseTransactions.map((specimen, index) => {
                  return (
                    <tr key={'specimen-row-' + specimen?.specimenId + '-index:' + index}>
                      <td>
                        <input
                          type="radio"
                          id={String(specimen.specimenId)}
                          onChange={() => findAndSetRowById(specimen.inventoryTransactionId, index)}
                          name="pending-use-transaction"
                          checked={
                            selectedRow.inventoryTransactionId !== undefined &&
                            selectedRow.inventoryTransactionId === specimen.inventoryTransactionId
                          }
                        />
                      </td>
                      <td>{specimen.inventoryTransactionId}</td>
                      <td>{`${specimen.animal?.code}-${specimen.animal?.name}`}</td>
                      <td>{`${new Date(specimen.freezeDate.toString() as string).toLocaleDateString()} - ${
                        specimen.specimenId
                      }`}</td>
                      <td>{specimen.quantityOwned}</td>
                      <td>{specimen.quantityAvailable}</td>
                      <td>{specimen.requestedUseQuantity}</td>
                    </tr>
                  );
                })}
              {pendingUseTransactions != undefined && pendingUseTransactions.length === 0 && (
                <tr>
                  <td colSpan={7}>
                    {loading ? (
                      <>
                        <br />
                        <br />
                        <Loader loaderSize="small" />
                        <br />
                        <br />
                      </>
                    ) : (
                      <>No Pending Use Transactions</>
                    )}
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={7}>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Mobile View */}
      <div className="xs-pending-use-transaction">
        {pendingUseTransactions != undefined && !loading ? (
          pendingUseTransactions.length === 0 ? (
            <label className="no-pending-use-transaction">No Pending Use Transactions</label>
          ) : (
            <>
              <div className="form-row">
                <div className="input-container wide-input-container">
                  <FilterDropdown
                    placeholder={'Select One'}
                    options={pendingUseTransactionOptions}
                    value={selectedPendingUseTransactionValue}
                    onChange={e => {
                      setSelectedPendingUseTransactionValue(e);
                      setActivePendingUseTransaction(pendingUseTransactions[e.value]);
                      setSelectedRow(pendingUseTransactions[e.value]);
                      setSelectedPendingUseTransactionIndex(Number(e.value) ?? -1);
                    }}
                  />
                </div>
              </div>
              {selectedPendingUseTransactionValue.name != '' && (
                <div className="selected-pending-use-transaction">
                  <div className="card max-width">
                    <div className="card-header">
                      <div className="animal-name">
                        <label>{`Animal Code/Name: ${pendingUseTransactions[selectedPendingUseTransactionIndex]?.animal?.code}-${pendingUseTransactions[selectedPendingUseTransactionIndex]?.animal?.name}`}</label>
                      </div>
                      <div className="lot-date-number">
                        <label>{`Lot Date / Number: ${new Date(
                          pendingUseTransactions[selectedPendingUseTransactionIndex]?.freezeDate.toString() as string,
                        ).toLocaleDateString()} - ${
                          pendingUseTransactions[selectedPendingUseTransactionIndex].specimenId
                        }`}</label>
                      </div>
                      <div className="transaction-link-id">
                        <label>{`Transaction Link ID: ${
                          pendingUseTransactions[selectedPendingUseTransactionIndex]?.inventoryTransactionId ?? '-'
                        }`}</label>
                      </div>
                    </div>
                    <hr />
                    <div className="body">
                      <div className="owned-transaction">
                        <label>{`Owned: ${pendingUseTransactions[selectedPendingUseTransactionIndex]?.quantityOwned}`}</label>
                      </div>
                      <div className="available-transaction">
                        <label>{`Available: ${pendingUseTransactions[selectedPendingUseTransactionIndex]?.quantityAvailable}`}</label>
                      </div>
                      <div className="requested-transaction">
                        <label>{`Requested: ${pendingUseTransactions[selectedPendingUseTransactionIndex]?.requestedUseQuantity}`}</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )
        ) : (
          loading && <Loader loaderSize="small" simple />
        )}
      </div>
    </div>
  );
};

export default PendingUseTransactionsTable;
