const TabMenuCloseSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 6L16 6V8L3 8V6ZM3 11H13V13H3L3 11ZM3 18L3 16H16V18H3ZM21 8.41L17.42 12L21 15.59L19.59 17L14.59 12L19.59 7L21 8.41Z"
        fill="#545556"
      />
    </svg>
  );
};

export default TabMenuCloseSvg;
