import { Route, Routes } from "react-router-dom";
import { USER_PROFILE_PATHS } from "../constants/routePaths";
import UserProfile from "../pages/user-profile/UserProfile";

const UserProfileRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path={USER_PROFILE_PATHS.UPDATE} element={<UserProfile />} />
      </Routes>
    </div>
  );
};

export default UserProfileRoutes;
