import { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { ValidatedState } from '../../components/custom-input/ValidatedInput';
import { ValidatedAddress } from '../../components/account-settings/AddressInformation';
import AccountInformation from '../../components/account-settings/AccountInformation';
import { EditSVG, PlusSvg, TrashCanSVG } from '../../components/svgs';
import { Loader } from '../../components';
import { FilterOption } from '../../components/custom-input/FilterDropdown';
import ManageBillingInformation from '../../components/account-settings/manage-billing-info/ManageBillingInformation';
import AddressInputModal from '../user-profile/address-input-modal/AddressInputModal';
import ConfirmModal from '../user-profile/user-edit/confirm-modal/ConfirmModal';
import { checkAddressTypesAreUnique, isValidAddress, tempId } from '../../utils/addressHelpers';
import { showToast } from '../../services/toast.service';
import { getAccountById, updateAccountSettings } from '../../api/accountApi';
import { ActiveAccount } from '../../types/interfaces/account.interfaces';
import { Address, emptyAddress } from '../../types/interfaces/common.interfaces';
import { AddressType } from '../../types/enums';
import { PromiseError, toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import {
  ACCOUNT_CONSTANTS,
  ADDRESS_CONSTANTS,
  BUTTON_CONSTANTS,
  PAGE_HEADER_CONSTANTS,
  PAYMENT_CONSTANTS,
} from '../../constants/common';
import './accountSettings.scss';

const AccountSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [account, setAccount] = useState<ActiveAccount>();
  const accountId = useAppSelector(state => state.activeAccount.accountId);
  const locationState = location.state;
  const [activeTab, setActiveTab] = useState(0);
  const [validate, setValidate] = useState<boolean>(false);
  const [farmName, setFarmName] = useState<ValidatedState>({ value: '', valid: false });
  const [firstName, setFirstName] = useState<ValidatedState>({ value: '', valid: false });
  const [lastName, setLastName] = useState<ValidatedState>({ value: '', valid: false });
  const [contactEmail, setContactEmail] = useState<ValidatedState>({ value: '', valid: false });
  const [contactPhone, setContactPhone] = useState<ValidatedState>({ value: '', valid: false });
  const [addressComponents, setAddressComponents] = useState<ValidatedAddress[]>([]);
  const [addressComponentsCopy, setAddressComponentsCopy] = useState<ValidatedAddress[]>([]);
  const [stateWasModified, setStateWasModified] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [addressId, setAddressId] = useState<number>(0);
  const [isRemoveAddress, setIsRemoveAddress] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpenAddAddressModal, setIsOpenAddAddressModal] = useState<boolean>(false);
  const [addressesValid, setAddressesValid] = useState<boolean[]>([]);
  const [editAddress, setEditAddress] = useState<boolean>(false);
  const [addressComponent, setAddressComponent] = useState<Address>(emptyAddress);
  const [updatingAccount, setUpdatingAccount] = useState<boolean>(true);

  const addressTypes: FilterOption<string>[] = [];

  for (const [addressTypekey, addressTypeValue] of Object.entries(AddressType)) {
    if (addressTypeValue != AddressType.BILLING) {
      addressTypes.push({ name: addressTypeValue, value: addressTypeValue });
    }
  }

  const [addressTypeOptions, setAddressTypeOptions] = useState<FilterOption<string>[]>(addressTypes);

  useEffect(() => {
    if (locationState != null) {
      setActiveTab(locationState?.activeTab);
    }
  }, [locationState]);

  useEffect(() => {
    if (accountId) {
      handleAccount();
    }
  }, [accountId]);

  useEffect(() => {
    const filteredAddressTypes = addressTypes.filter(
      addressType => !addressComponents.find(address => addressType.value === address.type.value),
    );
    setAddressTypeOptions(filteredAddressTypes);
  }, [addressComponents]);

  const handleAccount = async () => {
    let acc: ActiveAccount | undefined = account;

    try {
      acc = (await getAccountById(accountId!, { include: 'Addresses' })).data as ActiveAccount;
      setAccount(acc);
      mapAccountAddresses(acc.addresses);
      setValidatedStates(acc);
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setIsLoading(false);
    }
  };

  function mapAccountAddresses(addresses?: Address[]) {
    if (addresses) {
      const validatedAddressArr = addresses.map((address: Address) => {
        return mapAddressToValidatedAddress(address);
      });
      setAddressComponents(validatedAddressArr);
      setAddressComponentsCopy(_.cloneDeep(validatedAddressArr));
    }
  }

  const setValidatedStates = (account: ActiveAccount) => {
    setFarmName({ value: account.name, valid: true });
    setFirstName({ value: account.contactFirstName, valid: true });
    setLastName({ value: account.contactLastName, valid: true });
    setContactEmail({ value: account.contactEmail, valid: true });
    setContactPhone({ value: account.contactPhone, valid: true });
  };

  const createAddressInfoObjects = (
    addresses: ValidatedAddress[] = addressComponents,
  ): {
    country?: string | undefined;
    postalCode?: string | undefined;
    state?: string | undefined;
    city?: string | undefined;
    streetAddress?: string | undefined;
    type?: string | undefined;
  }[] => {
    return addresses.map(address => {
      return {
        ...(address.type.value && { type: address.type.value }),
        ...(address.streetAddress.value && { streetAddress: address.streetAddress.value }),
        ...(address.city.value && { city: address.city.value }),
        ...(address.state.value && { state: address.state.value }),
        ...(address.postalCode.value && { postalCode: address.postalCode.value }),
        ...(address.country.value && { country: address.country.value }),
      };
    });
  };

  const createAccountInfoObject = (addresses?: ValidatedAddress[]): {} => {
    const addressArray = createAddressInfoObjects(addresses);

    return {
      ...(farmName.value && { name: farmName.value }),
      ...(firstName.value && { contactFirstName: firstName.value }),
      ...(lastName.value && { contactLastName: lastName.value }),
      ...(contactEmail.value && { contactEmail: contactEmail.value }),
      ...(contactPhone.value && { contactPhone: contactPhone.value }),
      ...(addressArray.length && { addresses: addressArray }),
    };
  };

  const updateAccountInfo = (addresses: ValidatedAddress[] = addressComponents) => {
    if (!updatingAccount) return;

    setUpdatingAccount(false);
    setValidate(true);
    handleRequest(addresses, createAccountInfoObject(addresses));
  };

  const handleAddressDeletion = (id: number) => {
    const index = addressComponentsCopy.findIndex(address => address.addressId.value === id);

    addressComponents.splice(index, 1);
    if (index < addressComponentsCopy.length) {
      addressComponentsCopy.splice(index, 1);
      const payload = { addresses: createAddressInfoObjects(addressComponentsCopy) };
      handleRequest(addressComponentsCopy, payload);
    }

    setAddressComponents(addressComponentsCopy);
    setIsRemoveAddress(false);
  };

  const handleRequest = async (addresses: ValidatedAddress[], payload: any) => {
    try {
      await updateAccountSettings(account!.accountId!, payload);
      if (!isRemoveAddress) {
        setAddressComponents(addresses);
        setStateWasModified(false);
        setValidate(false);
        navigate(ROUTE_PATHS.APP_DASHBOARD);
      } else {
        setAddressComponents(addressComponents);
      }
      showToast.success(toastMessages.SETTINGS_UPDATED);
    } catch (error: any) {
      if (error.code === PromiseError.NETWORK) {
        showToast.error(toastMessages.NETWORK);
      } else if (error.code === PromiseError.BAD_REQUEST) {
        showToast.error(PromiseError.BAD_REQUEST);
      } else {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    } finally {
      setUpdatingAccount(true);
      setIsRemoveAddress(false);
    }
  };

  const handleClose = () => {
    setStateWasModified(false);
    setValidate(false);
    navigate(ROUTE_PATHS.APP_DASHBOARD);
    showToast.info(toastMessages.NO_CHANGES_SAVED);
  };

  function checkValidity(): boolean {
    return (
      farmName.valid &&
      firstName.valid &&
      lastName.valid &&
      contactEmail.valid &&
      contactPhone.valid &&
      stateWasModified &&
      checkAddressValidity()
    );
  }

  const checkAddressValidity = () => {
    return (
      addressesValid.every(valid => valid) && isValidAddress(addressComponents) && checkAddressTypesAreUnique(addressComponents)
    );
  };

  const showConfirmationModal = (id: number) => {
    setIsRemoveAddress(true);
    setAddressId(id);
    setIsConfirmModalOpen(true);
  };

  const showAddAddressModal = (address: ValidatedAddress) => {
    const addressObject: Address = mapValidatedAddressToAddress(address);
    setEditAddress(true);
    setAddressComponent(addressObject);
    setIsOpenAddAddressModal(true);
  };

  function handleAddressValidity(address: Address, valid: boolean): void {
    const addressObject: ValidatedAddress = mapAddressToValidatedAddress(address);
    const index = addressComponents.indexOf(addressObject);
    setAddressesValid(prev => {
      const newAddressesValid = [...prev];
      newAddressesValid[index] = valid;
      return newAddressesValid;
    });
  }

  const mapAddressToValidatedAddress = (address: Address) => {
    return {
      addressId: { value: address.addressId },
      type: { value: address.type, valid: address.type ? true : false },
      streetAddress: { value: address.streetAddress, valid: address.streetAddress ? true : false },
      streetAddress2: { value: address.streetAddress2, valid: address.streetAddress2 ? true : false },
      city: { value: address.city, valid: address.city ? true : false },
      state: { value: address.state, valid: address.state ? true : false },
      postalCode: { value: address.postalCode, valid: address.postalCode ? true : false },
      country: { value: address.country, valid: address.country ? true : false },
    } as ValidatedAddress;
  };

  const mapValidatedAddressToAddress = (address: ValidatedAddress) => {
    return {
      addressId: address.addressId.value,
      type: address.type.value as AddressType,
      streetAddress: address.streetAddress.value,
      city: address.city.value,
      state: address.state.value,
      postalCode: address.postalCode.value,
      country: address.country.value,
    } as Address;
  };

  const handleAddressInputConfirm = (address: Address) => {
    const addressObject: ValidatedAddress = mapAddressToValidatedAddress(address);
    let newAddresses;

    if (editAddress) {
      newAddresses = addressComponents.map(item =>
        item.addressId.value === addressObject.addressId.value
          ? {
              ...item,
              addressId: { value: addressObject.addressId.value },
              type: { value: addressObject.type.value, valid: addressObject.type.valid },
              streetAddress: { value: addressObject.streetAddress.value, valid: addressObject.streetAddress.valid },
              streetAddress2: { value: addressObject.streetAddress2.value, valid: addressObject.streetAddress2.valid },
              city: { value: addressObject.city.value, valid: addressObject.city.valid },
              state: { value: addressObject.state.value, valid: addressObject.state.valid },
              postalCode: { value: addressObject.postalCode.value, valid: addressObject.postalCode.valid },
              country: { value: addressObject.country.value, valid: addressObject.country.valid },
            }
          : item,
      );

      setEditAddress(false);
    } else {
      newAddresses = [
        ...addressComponents,
        {
          ...addressObject,
          addressId: { value: addressObject.addressId.value },
          type: { value: addressObject.type.value, valid: addressObject.type.valid },
          streetAddress: { value: addressObject.streetAddress.value, valid: addressObject.streetAddress.valid },
          streetAddress2: { value: addressObject.streetAddress2.value, valid: addressObject.streetAddress2.valid },
          city: { value: addressObject.city.value, valid: addressObject.city.valid },
          state: { value: addressObject.state.value, valid: addressObject.state.valid },
          postalCode: { value: addressObject.postalCode.value, valid: addressObject.postalCode.valid },
          country: { value: addressObject.country.value, valid: addressObject.country.valid },
        },
      ];
    }

    if (checkAddressValidity()) {
      handleRequest(newAddresses, createAccountInfoObject(newAddresses));
    }

    setIsOpenAddAddressModal(false);
  };

  const checkAddressOptionsAvailable = () => {
    return addressTypes.length === filterOnlyAccountAddresses(addressComponents).length + 1 ? true : false;
  };

  const filterOnlyAccountAddresses = (addresses: ValidatedAddress[]) => {
    return addresses.filter(address => address.type.value != AddressType.BILLING);
  };

  return (
    <div className="account-settings-page">
      {isLoading ? (
        <Loader loaderSize="medium" pageLoader simple />
      ) : (
        <>
          <div className="account-settings card">
            <div className="account-settings__header">
              <h2>{PAGE_HEADER_CONSTANTS.SETTINGS}</h2>
              <ul className="account-settings__tabs">
                <li className={activeTab === 0 ? 'active' : ''} onClick={() => setActiveTab(0)}>
                  <div className="account">{ACCOUNT_CONSTANTS.ACCOUNT}</div>
                </li>
                <li className={activeTab === 1 ? 'active' : ''} onClick={() => setActiveTab(1)}>
                  <div className="address">{ADDRESS_CONSTANTS.ADDRESS}</div>
                </li>
                <li className={activeTab === 2 ? 'active' : ''} onClick={() => setActiveTab(2)}>
                  <div className="payment-info">{PAYMENT_CONSTANTS.PAYMENT_INFO}</div>
                </li>
              </ul>
            </div>

            <div className="account-settings__body">
              {activeTab === 0 && (
                <AccountInformation
                  validate={validate}
                  account={account}
                  farmName={farmName}
                  firstName={firstName}
                  lastName={lastName}
                  contactEmail={contactEmail}
                  contactPhone={contactPhone}
                  setFarmName={e => {
                    setStateWasModified(true);
                    setFarmName(e);
                  }}
                  setFirstName={e => {
                    setStateWasModified(true);
                    setFirstName(e);
                  }}
                  setLastName={e => {
                    setStateWasModified(true);
                    setLastName(e);
                  }}
                  setContactEmail={e => {
                    setStateWasModified(true);
                    setContactEmail(e);
                  }}
                  setContactPhone={e => {
                    setStateWasModified(true);
                    setContactPhone(e);
                  }}
                />
              )}
              {!updatingAccount && <Loader loaderSize="small" simple />}

              {activeTab === 1 && (
                <>
                  <h3 className="address-header">{ADDRESS_CONSTANTS.ADDRESS_INFO}</h3>
                  {addressComponents.length != 0 && (
                    <>
                      {filterOnlyAccountAddresses(addressComponents).length === 1 ? (
                        <div className="account-single-address">
                          {filterOnlyAccountAddresses(addressComponents).map(address => (
                            <div className="card" key={address.addressId.value}>
                              <div className="address-type">{address.type.value}</div>
                              <div className="address-details">
                                <p>{address.streetAddress.value}</p>
                                <p>{address.city.value}</p>
                                <p>{address.state.value}</p>
                                <p>{address.postalCode.value}</p>
                                <p>{address.country.value}</p>
                              </div>
                              <div className="edit-trash-svg">
                                <div onClick={() => address.addressId.value && showAddAddressModal(address)}>
                                  <EditSVG />
                                </div>
                                <TrashCanSVG
                                  className={'address-delete-button'}
                                  onClick={() => showConfirmationModal(address.addressId.value)}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="account-addresses">
                          {filterOnlyAccountAddresses(addressComponents).map((address, index) => (
                            <div key={address.addressId.value} className="card">
                              <div className="address-card-header">
                                <h3>{address.type.value}</h3>
                                <div className="edit-trash-svg">
                                  <div onClick={() => address.addressId.value && showAddAddressModal(address)}>
                                    <EditSVG />
                                  </div>
                                  <TrashCanSVG
                                    className={'address-delete-button'}
                                    onClick={() => showConfirmationModal(address.addressId.value)}
                                  />
                                </div>
                              </div>
                              <div className="address-card-body">
                                <p>{address.streetAddress.value}</p>
                                <p>{address.city.value}</p>
                                <p>
                                  {address.state.value} &nbsp; {address.postalCode.value}
                                </p>
                                <p>{address.country.value}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}

                  {isOpenAddAddressModal && (
                    <AddressInputModal
                      isOpen={isOpenAddAddressModal}
                      address={addressComponent}
                      validate={validate}
                      addressTypeOptions={addressTypeOptions}
                      onClose={setIsOpenAddAddressModal}
                      handleAddressValidity={(address, value) => handleAddressValidity(address, value)}
                      confirm={value => handleAddressInputConfirm(value)}
                    />
                  )}
                  <button
                    className="button add-address"
                    onClick={() => {
                      setAddressComponent({ ...emptyAddress, addressId: tempId() });
                      setIsOpenAddAddressModal(true);
                    }}
                    disabled={checkAddressOptionsAvailable()}>
                    <PlusSvg /> {ADDRESS_CONSTANTS.ADD_ADDRESS}
                  </button>

                  <ConfirmModal
                    modalHeader="Remove Address"
                    modalBody="Are you sure you want to remove this address from your account?"
                    isOpen={isConfirmModalOpen}
                    firstBtn={
                      <button
                        type="button"
                        className="small inverted blue button"
                        onClick={() => {
                          setIsConfirmModalOpen(false);
                          setIsRemoveAddress(false);
                        }}>
                        {BUTTON_CONSTANTS.CANCEL}
                      </button>
                    }
                    secondBtn={
                      <button
                        type="button"
                        className="small red button"
                        onClick={() => {
                          handleAddressDeletion(addressId);
                          setIsConfirmModalOpen(false);
                        }}>
                        {BUTTON_CONSTANTS.REMOVE}
                      </button>
                    }
                  />
                </>
              )}
              {activeTab === 2 && <ManageBillingInformation locationState={locationState} />}
            </div>
          </div>
          <div className="account-settings__footer">
            <button className="button green inverted small" onClick={handleClose}>
              {BUTTON_CONSTANTS.CANCEL}
            </button>
            <button disabled={!checkValidity()} className="button green small" onClick={() => updateAccountInfo()}>
              {BUTTON_CONSTANTS.SAVE}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default AccountSettings;
