import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PagedTable from '../../components/sorted-table/PagedTable';
import TransactionsList, { TransactionFilterOption, TransactionFilterState } from '../../components/transaction/TransactionsList';
import { FilterOption } from '../../components/custom-input/FilterDropdown';
import ActionMenu from '../../components/action-buttons/ActionButtons';
import { Loader } from '../../components';
import { InventoryTransaction, StandardParamsToODataParams } from '../../types/interfaces';
import { showToast } from '../../services/toast.service';
import { getAccountTransactionsOdata } from '../../api/transactionsApi';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../constants/common';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import './transactionHistory.scss';

const TransactionHistory = (): JSX.Element => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const transactionFetchLimit = 20;

  const [filter, setFilter] = useState<string>(state?.filter ?? undefined);
  const [filterPreset, setFilterPreset] = useState<TransactionFilterState>(state?.filterPreset ?? undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [transactionsData, setTransactionsData] = useState<InventoryTransaction[]>([]);

  const callNumber: MutableRefObject<number> = useRef<number>(0);
  const hasAllResults: MutableRefObject<boolean> = useRef<boolean>(false);
  const isLoadingAdditionalPage: MutableRefObject<boolean> = useRef<boolean>(false);
  const ref = useRef<any>(null);

  const searchCriteriaOptions: FilterOption<TransactionFilterOption>[] = [
    { name: `${TABLE_HEADER_CONSTANTS.TRANSACTION_ID}`, value: { parameter: 'inventoryTransactionId', isNumber: true } },
    { name: `${LABEL_CONSTANTS.NOTES}`, value: { parameter: 'notes' } },
    { name: `${TABLE_HEADER_CONSTANTS.ANIMAL_CODE}`, value: { parameter: 'specimen/animal/code' } },
    { name: `${TABLE_HEADER_CONSTANTS.ANIMAL_NAME}`, value: { parameter: 'specimen/animal/name' } },
    { name: `${TABLE_HEADER_CONSTANTS.LOT_NO}`, value: { parameter: 'specimenId', isNumber: true } },
  ];

  const loadData = async () => {
    callNumber.current++;
    if (hasAllResults.current) {
      return;
    }
    const callNum = callNumber.current;
    try {
      setLoading(true);
      const { data: response } = await getAccountTransactionsOdata(
        StandardParamsToODataParams({
          include: 'Specimen.Animal',
          filter: filter,
          sort: '^createdDatetime',
          limit: transactionFetchLimit,
          offset: offset,
        }),
      );
      if (offset === 0 && 'current' in ref) {
        ref.current?.scroll({ top: 0 });
      }
      if (callNum === callNumber.current) {
        const newTableData = offset > 0 ? [...transactionsData, ...response] : response;
        setTransactionsData(newTableData);
        setLoading(false);
        hasAllResults.current = response.length === 0;
      }
    } catch (error: any) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    hasAllResults.current = false;
    setTransactionsData([]);
    if (offset === 0) {
      window.innerWidth <= 767 && loadData();
    } else {
      setOffset(0);
      if ('current' in ref) {
        ref.current?.scroll({ top: 0 });
      }
    }
  }, [filter]);

  useEffect(() => {
    window.innerWidth <= 767 && loadData();
  }, [offset]);

  useEffect(() => {
    window.addEventListener('touchmove', handleScroll);
    return () => window.removeEventListener('touchmove', handleScroll);
  }, []);

  useEffect(() => {
    isLoadingAdditionalPage.current = false;
  }, [transactionsData]);

  const loadMore = () => {
    setOffset(prevState => prevState + transactionFetchLimit);
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    const position = scrollHeight - scrollTop;
    const atBottom = position - clientHeight <= clientHeight;

    if (atBottom && !isLoadingAdditionalPage.current) {
      isLoadingAdditionalPage.current = true;
      loadMore();
    }
  };

  return (
    <div className="transaction-history">
      <TransactionsList
        header="Transaction History"
        searchCriteriaOptions={searchCriteriaOptions}
        setFilter={setFilter}
        setFilterPreset={setFilterPreset}
        filterPreset={filterPreset}
        isOData>
        <div className="desk-transaction-history">
          <PagedTable
            headers={[
              { displayName: `${TABLE_HEADER_CONSTANTS.TRANSACTION_ID}` },
              { displayName: `${TABLE_HEADER_CONSTANTS.ANIMAL}` },
              { displayName: `${TABLE_HEADER_CONSTANTS.TYPE}` },
              { displayName: `${TABLE_HEADER_CONSTANTS.CHANGED_QTY}` },
              { displayName: `${TABLE_HEADER_CONSTANTS.DATE}`, sortValue: 'CreatedDatetime' },
              { displayName: `${TABLE_HEADER_CONSTANTS.STATUS}` },
              { displayName: `${TABLE_HEADER_CONSTANTS.ACTION}` },
            ]}
            sortBy="^createdDatetime"
            getData={params => getAccountTransactionsOdata(StandardParamsToODataParams(params))}
            filter={filter}
            include="Specimen.Animal"
            height={500}
            pageSize={10}
            scrollBufferThickness={100}
            buildRow={t => {
              return [
                t.inventoryTransactionId,
                `${t.specimen?.animal?.animalId ?? ''} - ${t.specimen?.animal?.name ?? ''}`,
                t.transactionType,
                t.changeQuantity,
                new Date(t.createdDatetime).toLocaleDateString(),
                t.status,

                <ActionMenu
                  actionButtons={[
                    {
                      name: `${BUTTON_CONSTANTS.VIEW_DETAIL}`,
                      action: () =>
                        navigate(ROUTE_PATHS.APP_TRANSACTION_HISTORY_VIEW + t.inventoryTransactionId, {
                          state: { filterPreset: filterPreset, filter: filter },
                        }),
                    },
                  ]}
                />,
              ];
            }}
          />
        </div>

        {/** Mobile View */}
        <div className="xs-transaction-history">
          {!loading && transactionsData.length === 0 ? (
            <div className="result-item">{`${LABEL_CONSTANTS.NO_RESULTS_FOUND}.`}</div>
          ) : (
            transactionsData &&
            transactionsData.map((transaction, index) => {
              return (
                <div className="card max-width" key={'transaction-' + transaction.inventoryTransactionId + index}>
                  <div className="transaction-history-card">
                    <div className="transaction-history-content">
                      <div className="transaction-info">
                        <div className="transaction-content">
                          <label>{`${TABLE_HEADER_CONSTANTS.TRANSACTION_ID}: `}</label>
                          <label>{`${transaction.inventoryTransactionId ?? '-'}`}</label>
                        </div>
                        <div className="transaction-content">
                          <label>{`${TABLE_HEADER_CONSTANTS.ANIMAL}: `}</label>
                          <label>{`${transaction.specimen?.animal?.animalId ?? ''} - ${
                            transaction.specimen?.animal?.name ?? ''
                          }`}</label>
                        </div>
                        <div className="transaction-content">
                          <label>{`${TABLE_HEADER_CONSTANTS.TYPE}: `}</label>
                          <label>{`${transaction.transactionType ?? '-'}`}</label>
                        </div>
                      </div>
                      <div className="transaction-info">
                        <div className="transaction-content">
                          <label>{`${TABLE_HEADER_CONSTANTS.CHANGED_QTY}: `}</label>
                          <label>{`${transaction.changeQuantity ?? '-'}`}</label>
                        </div>
                        <div className="transaction-content">
                          <label>{`${TABLE_HEADER_CONSTANTS.DATE}: `}</label>
                          <label>{`${new Date(transaction.createdDatetime).toLocaleDateString() ?? '-'}`}</label>
                        </div>
                        <div className="transaction-content">
                          <label>{`${TABLE_HEADER_CONSTANTS.STATUS}: `}</label>
                          <label>{`${transaction.status ?? '-'}`}</label>
                        </div>
                      </div>
                    </div>
                    <div className="action-button">
                      <ActionMenu
                        actionButtons={[
                          {
                            name: `${BUTTON_CONSTANTS.VIEW_DETAIL}`,
                            action: () =>
                              navigate(ROUTE_PATHS.APP_TRANSACTION_HISTORY_VIEW + transaction.inventoryTransactionId, {
                                state: { filterPreset: filterPreset, filter: filter },
                              }),
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        {loading && <Loader addedSpace loaderSize="small" />}
      </TransactionsList>
    </div>
  );
};

export default TransactionHistory;
