import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton, Loader, SearchableTable } from '../../components';
import PlusCircleSvg from '../../components/svgs/PlusCircle.svg';
import { HeaderConfig } from '../../components/searchable-table/SearchableTable';
import UserCard from '../../components/account-card/UserCard';
import ActionMenu from '../../components/action-buttons/ActionButtons';
import AddUserModal from '../team-management/AddUserModal';
import RemoveUserModal from '../team-management/RemoveUserModal';
import { AppRoles } from '../../types/enums';
import { AdminInfo, TeamManagementUser } from '../../types/interfaces';
import { createUser, getUserProfileImageById, resendInvitationEmail } from '../../api/userApi';
import { showToast } from '../../services/toast.service';
import { removeUsersAppRole, requestRsgUsers, updateUserAppRole } from '../../services/user.service';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { BUTTON_CONSTANTS } from '../../constants/common';

const ApplicationTeam = () => {
  const navigate = useNavigate();

  const [isOpenAddUser, setIsOpenAddUser] = useState<boolean>(false);
  const [isOpenRemoveUser, setIsOpenRemoveUser] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<TeamManagementUser>();
  const [userProfileImages, setUserProfileImages] = useState<{ [key: number]: Blob }>();
  const [users, setUsers] = useState<Pick<AdminInfo, 'userId' | 'firstName' | 'lastName' | 'roles' | 'profileImageId'>[]>();

  const getAdminList = async () => {
    setIsLoading(true);
    try {
      const { data: admins } = await requestRsgUsers();
      const filteredAdmins = admins.filter(u => u.roles.some(r => r.name !== AppRoles.AppAdmin));
      const sortedUsers = filteredAdmins
        .map(u => {
          const firstName = u.firstName ?? '';
          const lastName = u.lastName ?? '';
          const fullName = `${firstName} ${lastName}`.trim();
          let role: AppRoles | null = null;
          if (Object.values(AppRoles).includes(u.roles[0].name as AppRoles)) {
            role = u.roles[0].name as AppRoles;
          }

          return {
            userId: u.userId,
            firstName: firstName,
            lastName: lastName,
            fullName: fullName,
            roles: role ? [role] : [],
            username: u.username,
            verified: u.verified,
            profileImageId: u.profileImageId,
          };
        })
        .sort((a, b) => {
          return a?.firstName.toUpperCase() > b?.firstName.toUpperCase() ? 1 : -1;
        });
      setUsers(sortedUsers);
    } catch {
      showToast.error(toastMessages.FAILED_TO_RETRIEVE_USERS);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAdminList();
  }, []);

  const onSuccessfulAdd = () => {
    getAdminList();
  };

  useEffect(() => {
    if (users?.length) {
      const loadUserImages = async () => {
        const results = await Promise.all(
          users
            .filter(user => user.userId && user.profileImageId)
            .map(user => getUserProfileImageById(user.userId, user.profileImageId).catch(() => new Blob())),
        );

        const userImages: { [key: number]: Blob } = {};
        users
          .filter(user => user.userId && user.profileImageId)
          .forEach((user, index) => {
            userImages[user.userId!] = results[index];
          });

        setUserProfileImages(userImages);
      };

      if (users?.length) {
        loadUserImages();
      }
    }
  }, [users]);

  const handleRemove = async () => {
    if (selectedUser) {
      setIsLoading(true);

      try {
        await removeUsersAppRole(selectedUser.userId);
        await getAdminList();
        showToast.success(toastMessages.REMOVE_TEAM_MEMBER_SUCCESS);
      } catch (error) {
        showToast.error(toastMessages.REMOVE_TEAM_MEMBER_FAIL);
      } finally {
        setIsOpenRemoveUser(false);
        setIsLoading(false);
      }
    }
  };

  const handleResend = async (user: TeamManagementUser) => {
    setIsLoading(true);
    resendInvitationEmail(user.userId)
      .then(() => {
        showToast.success(toastMessages.INVITE_RESENT);
      })
      .catch(() => {
        showToast.success(toastMessages.FAILED_TO_RESEND_INVITE);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEdit = (user: TeamManagementUser) => {
    navigate(ROUTE_PATHS.APP_APPLICATION_TEAM_EDIT + user.userId, { state: user });
  };

  const selectUserForRemove = (user: TeamManagementUser) => {
    setIsOpenRemoveUser(true);
    setSelectedUser(user);
  };

  const nameCell = (user: TeamManagementUser) => {
    return <UserCard user={user} profileImage={userProfileImages?.[user.userId]} />;
  };

  const roleCell = (user: TeamManagementUser) => {
    return <div className="role-container">{user.roles}</div>;
  };

  const editCell = (user: TeamManagementUser) => {
    return (
      <>
        <div className="desk-buttons-container">
          <div className="buttons-container">
            {user.verified ? (
              <button onClick={() => handleEdit(user)} className="button blue">
                {BUTTON_CONSTANTS.EDIT}
              </button>
            ) : (
              <button onClick={() => handleResend(user)} className="button blue">
                {BUTTON_CONSTANTS.RESEND}
              </button>
            )}
            <button onClick={() => selectUserForRemove(user)} className="button red inverted">
              {BUTTON_CONSTANTS.REMOVE}
            </button>
          </div>
        </div>
        <div className="xs-buttons-container">
          {user.verified ? (
            <ActionMenu
              actionButtons={[
                { name: 'Edit', action: () => handleEdit(user) },
                { name: 'Remove', action: () => selectUserForRemove(user) },
              ]}
            />
          ) : (
            <ActionMenu
              actionButtons={[
                { name: 'Resend', action: () => handleResend(user) },
                { name: 'Remove', action: () => selectUserForRemove(user) },
              ]}
            />
          )}
        </div>
      </>
    );
  };

  const headers: HeaderConfig[] = [
    {
      propertyName: 'fullName',
      displayName: 'Name',
      searchable: true,
      cell: nameCell,
    },
    {
      propertyName: 'username',
      searchable: true,
    },
    {
      propertyName: 'roles',
      displayName: 'Role',
      cell: roleCell,
    },
    {
      propertyName: 'edit',
      cell: editCell,
    },
  ];

  return (
    <div className="manage-team">
      <div className="header">
        <BackButton />
        <button type="button" className="button outlined" onClick={() => setIsOpenAddUser(true)}>
          <PlusCircleSvg />
          &nbsp;&nbsp;{BUTTON_CONSTANTS.ADD_USER}&nbsp;
        </button>
      </div>
      {isLoading && <Loader loaderSize={'medium'} simple pageLoader />}
      <SearchableTable headers={headers} data={users!} />

      {isOpenAddUser && (
        <AddUserModal
          isOpen={isOpenAddUser}
          onClose={() => setIsOpenAddUser(false)}
          AddExistingUser={(username, roles, accountId, userId) => updateUserAppRole(userId, roles as AppRoles)}
          AddNonExistingUser={createUser}
          roles={[{ name: AppRoles.Admin, value: AppRoles.Admin }]}
          onSuccessfulAdd={onSuccessfulAdd}
        />
      )}
      {isOpenRemoveUser && (
        <RemoveUserModal
          isOpen={isOpenRemoveUser}
          onClose={() => setIsOpenRemoveUser(false)}
          handleRemove={handleRemove}
          selectedUser={selectedUser}
        />
      )}
    </div>
  );
};

export default ApplicationTeam;
