import { FilterOption } from '../../../components/custom-input/FilterDropdown';
import { AlertSVG } from '../../../components/svgs';
import StorageLocationRow from './StorageLocationRow';
import { StorageTankOptions } from '../../../types/enums/storageTank.enum';
import { StorageLocation } from '../../../types/interfaces/inventoryTransaction.interfaces';
import { hashCode } from '../../../utils/commonUtils';
import { BUTTON_CONSTANTS, VALIDATION_ALERT_CONSTANTS } from '../../../constants/common';

interface SelectStorageLocationsProps {
  storageTankOptions?: StorageTankOptions;
  selectedStorageLocations: StorageLocation[];
  setSelectedStorageLocations: (e: StorageLocation[]) => void;
  quantityToMatch: number;
  isValidationAlertActive?: boolean;
  activeLocationsOnly?: boolean;
}

const SelectStorageLocations = ({
  selectedStorageLocations,
  setSelectedStorageLocations,
  storageTankOptions = StorageTankOptions.Both,
  quantityToMatch,
  isValidationAlertActive = false,
  activeLocationsOnly = false,
}: SelectStorageLocationsProps) => {
  const removeStorageLocation = (index: number) => {
    const selectedStorageLocationsCopy = [...selectedStorageLocations];
    selectedStorageLocationsCopy.splice(index, 1);
    setSelectedStorageLocations(selectedStorageLocationsCopy);
  };

  const handleSiteChange = (e: FilterOption<any>, index: number) => {
    const selectedStorageLocationsCopy = [...selectedStorageLocations];
    selectedStorageLocationsCopy[index] = {
      ...selectedStorageLocationsCopy[index],
      site: e,
      tank: { name: '', value: '' },
      canister: { name: '', value: '' },
    };
    setSelectedStorageLocations(selectedStorageLocationsCopy);
  };

  const handleTankChange = (e: FilterOption<any>, index: number) => {
    const selectedStorageLocationsCopy = [...selectedStorageLocations];
    selectedStorageLocationsCopy[index] = {
      ...selectedStorageLocationsCopy[index],
      tank: e,
      canister: { name: '', value: '' },
    };
    setSelectedStorageLocations(selectedStorageLocationsCopy);
  };

  const handleCanisterChange = (e: FilterOption<any>, index: number) => {
    const selectedStorageLocationsCopy = [...selectedStorageLocations];
    selectedStorageLocationsCopy[index] = { ...selectedStorageLocationsCopy[index], canister: e };
    setSelectedStorageLocations(selectedStorageLocationsCopy);
  };

  const handleQuantityChange = (e: string, index: number) => {
    const selectedStorageLocationsCopy = [...selectedStorageLocations];
    selectedStorageLocationsCopy[index] = {
      ...selectedStorageLocationsCopy[index],
      quantity: e.length > 0 && e !== '-' ? { value: Number(e) } : { value: null },
    };
    setSelectedStorageLocations(selectedStorageLocationsCopy);
  };

  const addAnotherLocation = () => {
    setSelectedStorageLocations([
      ...selectedStorageLocations,
      {
        site: { name: '', value: '' },
        tank: { name: '', value: '' },
        canister: { name: '', value: '' },
        quantity: { value: null },
        id: hashCode(new Date().toString()),
      },
    ]);
  };

  return (
    <div className="select-storage-items">
      {selectedStorageLocations.map((sl: StorageLocation, index: number) => {
        return (
          <StorageLocationRow
            key={sl.id}
            index={index}
            storageTankOptions={storageTankOptions}
            selectedStorageLocations={selectedStorageLocations}
            removeStorageLocation={removeStorageLocation}
            handleSiteChange={handleSiteChange}
            handleTankChange={handleTankChange}
            handleCanisterChange={handleCanisterChange}
            handleQuantityChange={handleQuantityChange}
            activeLocationsOnly={activeLocationsOnly}
          />
        );
      })}
      {quantityToMatch !== selectedStorageLocations.reduce((a, b) => a + Number(b.quantity.value), 0) &&
        isValidationAlertActive && (
          <div className="validated-input-message-error">
            <AlertSVG />
            {VALIDATION_ALERT_CONSTANTS.STORAGE_QTY_MUST_EQUAL} {quantityToMatch}
          </div>
        )}
      {selectedStorageLocations.some(sl => sl.quantity.value === 0) && isValidationAlertActive && (
        <div className="validated-input-message-error">
          <AlertSVG />
          {VALIDATION_ALERT_CONSTANTS.STORAGE_QTY_GREATER_THAN_0}
        </div>
      )}
      {selectedStorageLocations.some(sl => !sl.site.value || !sl.canister.value || !sl.tank.value) && isValidationAlertActive && (
        <div className="validated-input-message-error">
          <AlertSVG />
          {VALIDATION_ALERT_CONSTANTS.SITE_TANK_CANISTER_REQUIRED}
        </div>
      )}
      <button className="button xs-add-location" type="button" onClick={addAnotherLocation}>
        + {BUTTON_CONSTANTS.ADD_LOCATION}
      </button>
    </div>
  );
};

export default SelectStorageLocations;
