import { FormEvent, useCallback, useEffect, useState } from 'react';
import { FilterDropdown, Loader } from '../../components';
import { FilterOption, defaultFilterOption } from '../../components/custom-input/FilterDropdown';
import DataPointKeyModal, { NewDataPoint } from './custom-data-points-components/DataPointKeyModal';
import PagedTable from '../../components/sorted-table/PagedTable';
import ActionMenu from '../../components/action-buttons/ActionButtons';
import { CancelSVG, CheckMarkSVG, PlusSvg } from '../../components/svgs';
import { SpecimenCustomDataKey, SpecimenType } from '../../types/interfaces';
import { toastMessages } from '../../constants/errorMessages';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS, PAGE_HEADER_CONSTANTS } from '../../constants/common';
import { createCustomDataKey, getCustomDataKeys, updateCustomDataKey } from '../../api/customDataPoints.api';
import { getSpecimenTypes } from '../../api/specimensTypesApi';
import { showToast } from '../../services/toast.service';
import './customDataPoints.scss';

const CustomDataPoints = () => {
  const [specimenTypeOptions, setSpecimenTypeOptions] = useState<FilterOption<SpecimenType>[]>([]);
  const [specimenType, setSpecimenType] = useState<FilterOption<SpecimenType>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDataPointModalOpen, setIsDataPointModalOpen] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [customDataPoints, setCustomDataPoints] = useState<SpecimenCustomDataKey[]>([]);
  const [updatingCustomDataPoints, setUpdatingCustomDataPoints] = useState<boolean>(true);

  const defaultNewDataPoint = {
    specimenCustomDataKeyId: undefined,
    keyName: { value: '', valid: false },
    required: { value: true, name: 'Yes' },
    active: { value: true, name: 'Yes' },
    dataType: defaultFilterOption,
    specimenType: defaultFilterOption,
    minValue: undefined,
    maxValue: undefined,
  };

  const [newDataPoint, setNewDataPoint] = useState<NewDataPoint>(defaultNewDataPoint);

  const handleGetCustomDataPoints = useCallback(async () => {
    if (specimenType?.value.specimenTypeId) {
      try {
        const customDataKeys = (
          await getCustomDataKeys({
            filter: `specimenTypeId eq ${specimenType?.value.specimenTypeId}`,
            include: 'SpecimenType',
          })
        ).data;
        setCustomDataPoints(customDataKeys);
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  }, [specimenType]);

  useEffect(() => {
    handleGetCustomDataPoints();
  }, [handleGetCustomDataPoints]);

  useEffect(() => {
    const handleLoad = async () => {
      const { data } = await getSpecimenTypes();
      const mappedItemType = data.map((item: SpecimenType) => {
        return { name: item.name ?? '', value: item };
      });
      setSpecimenTypeOptions(mappedItemType);
      setIsLoading(false);
    };
    handleLoad();
  }, []);

  const handleNewDataPointSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!updatingCustomDataPoints) return;
    setUpdatingCustomDataPoints(false);

    if (isValid()) {
      try {
        setIsLoading(true);
        await createCustomDataKey({
          keyName: newDataPoint.keyName.value,
          required: newDataPoint.required.value,
          active: newDataPoint.active.value,
          isNumeric: newDataPoint.dataType.value === 'Number',
          specimenTypeId: Number(newDataPoint.specimenType.value),
          minValue: newDataPoint.minValue,
          maxValue: newDataPoint.maxValue,
        });

        setIsDataPointModalOpen(false);
        handleGetCustomDataPoints();
        showToast.success(toastMessages.CUSTOM_DATA_POINT_CREATED);
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setIsLoading(false);
        setUpdatingCustomDataPoints(true);
      }
    } else {
      setUpdatingCustomDataPoints(true);
      setShowErrors(true);
    }
  };

  const handleEditDataPointSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!updatingCustomDataPoints) return;
    setUpdatingCustomDataPoints(false);

    if (isValid()) {
      try {
        setIsLoading(true);
        await updateCustomDataKey({
          specimenCustomDataKeyId: newDataPoint.specimenCustomDataKeyId,
          keyName: newDataPoint.keyName.value,
          required: newDataPoint.required.value,
          active: newDataPoint.active.value,
          isNumeric: newDataPoint.dataType.value === 'Number',
          specimenTypeId: Number(newDataPoint.specimenType.value),
          minValue: newDataPoint.minValue,
          maxValue: newDataPoint.maxValue,
        });
        setIsDataPointModalOpen(false);
        handleGetCustomDataPoints();
        showToast.success(toastMessages.CUSTOM_DATA_POINT_UPDATED);
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setIsLoading(false);
        setUpdatingCustomDataPoints(true);
      }
    } else {
      setUpdatingCustomDataPoints(true);
      setShowErrors(true);
    }
  };

  const isValid = () => {
    return (
      newDataPoint.keyName.value != '' &&
      newDataPoint.specimenType.value != '' &&
      newDataPoint.dataType.value != '' &&
      newDataPoint.required.name != '' &&
      newDataPoint.active.name != '' &&
      isValidMinMaxValue()
    );
  };

  const isValidMinMaxValue = () => {
    return newDataPoint.minValue !== undefined &&
      newDataPoint.maxValue !== undefined &&
      newDataPoint.minValue > newDataPoint.maxValue
      ? false
      : true;
  };

  const handleActivate = async (customDataKeyId: number | string, active: boolean) => {
    try {
      setIsLoading(true);
      await updateCustomDataKey({ specimenCustomDataKeyId: Number(customDataKeyId), active: active });
      handleGetCustomDataPoints();
      showToast.success(active ? toastMessages.CUSTOM_DATA_POINT_ACTIVATED : toastMessages.CUSTOM_DATA_POINT_DEACTIVATED);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setIsLoading(false);
    }
  };

  const actionMenuButton = (customDataPoint: any) => {
    return (
      <ActionMenu
        actionButtons={[
          {
            name: 'Edit',
            action: () => {
              setEditMode(true);
              setShowErrors(false);
              setNewDataPoint({
                specimenCustomDataKeyId: customDataPoint.specimenCustomDataKeyId,
                keyName: { value: customDataPoint.keyName, valid: true },
                required: { value: customDataPoint.required, name: customDataPoint.required ? 'Yes' : 'No' },
                active: { value: customDataPoint.active, name: customDataPoint.active ? 'Yes' : 'No' },
                dataType: {
                  value: customDataPoint.isNumeric ? 'Number' : 'Text',
                  name: customDataPoint.isNumeric ? 'Number' : 'Text',
                },
                specimenType: { value: customDataPoint?.specimenType.specimenTypeId, name: customDataPoint?.specimenType.name },
                minValue: customDataPoint.minValue,
                maxValue: customDataPoint.maxValue,
              });
              setIsDataPointModalOpen(true);
            },
          },
          {
            name: customDataPoint.active ? 'Deactivate' : 'Activate',
            action: () => handleActivate(customDataPoint.specimenCustomDataKeyId, !customDataPoint.active),
          },
        ]}
      />
    );
  };

  return (
    <div className="custom-data-points-inventory">
      <DataPointKeyModal
        isDataPointModalOpen={isDataPointModalOpen}
        setIsDataPointModalOpen={setIsDataPointModalOpen}
        handleDataPointSubmit={editMode ? handleEditDataPointSubmit : handleNewDataPointSubmit}
        newDataPoint={newDataPoint}
        setNewDataPoint={setNewDataPoint}
        editMode={editMode}
        specimenTypeOptions={specimenTypeOptions}
        showErrors={showErrors}
        isValid={isValid()}
      />
      <div className="inventory-action card">
        <h1>{PAGE_HEADER_CONSTANTS.CUSTOM_DATA_POINTS}</h1>
        {isLoading ? (
          <Loader loaderSize={'small'} simple />
        ) : (
          <>
            <h2>{LABEL_CONSTANTS.SPECIMEN_TYPE}</h2>
            <div className="form-row">
              <label htmlFor="specimen-type">{LABEL_CONSTANTS.SELECT_TYPE}:</label>
              <div className="input-container">
                <FilterDropdown
                  options={specimenTypeOptions}
                  value={specimenType ?? defaultFilterOption}
                  onChange={setSpecimenType}
                />
              </div>
            </div>
            {specimenType && (
              <>
                <h2>{LABEL_CONSTANTS.DATA_POINT_SELECT}</h2>
                <div className="desk-custom-data-point-table">
                  <PagedTable
                    key={specimenType.value.specimenTypeId}
                    headers={[
                      { displayName: 'Name' },
                      { displayName: 'Required' },
                      { displayName: 'Active' },
                      { displayName: 'Type' },
                      { displayName: 'Action' },
                    ]}
                    getData={params =>
                      getCustomDataKeys({
                        ...params,
                        filter: `specimenTypeId eq ${specimenType?.value.specimenTypeId}`,
                        include: 'SpecimenType',
                      })
                    }
                    footerElement={
                      <button
                        onClick={() => {
                          setIsDataPointModalOpen(true);
                          setNewDataPoint(defaultNewDataPoint);
                          setEditMode(false);
                        }}
                        className="new-data-point-button">
                        +
                      </button>
                    }
                    buildRow={item => {
                      return [
                        item.keyName,
                        item.required ? <CheckMarkSVG /> : <CancelSVG />,
                        item.active ? <CheckMarkSVG /> : <CancelSVG />,
                        item.isNumeric ? 'Number' : 'Text',
                        actionMenuButton(item),
                      ];
                    }}
                  />
                </div>

                {/** Mobile View */}
                <div className="xs-custom-data-point-table">
                  {customDataPoints.length > 0 &&
                    customDataPoints &&
                    customDataPoints.map((customDataPoint: any, index: number) => {
                      return (
                        <div className="card max-width" key={customDataPoint.keyName + index}>
                          <div className="custom-data-point">
                            <div className="custom-data-point-content">
                              <div className="card-content-section">
                                <div className="left">
                                  <label>{`Name: ${customDataPoint?.keyName}`}</label>
                                </div>
                                <div className="right">
                                  <label>{`Required: ${customDataPoint?.required ? 'Yes' : 'No'}`}</label>
                                </div>
                              </div>
                              <div className="card-content-section">
                                <div className="left">
                                  <label>{`Active: ${customDataPoint?.active ? 'Yes' : 'No'}`}</label>
                                </div>
                                <div className="right">
                                  <label>{`Type: ${customDataPoint?.isNumeric ? 'Number' : 'Text'}`}</label>
                                </div>
                              </div>
                            </div>
                            <div className="action-button">{actionMenuButton(customDataPoint)}</div>
                          </div>
                        </div>
                      );
                    })}
                  <button
                    className="add-data-point"
                    onClick={() => {
                      setIsDataPointModalOpen(true);
                      setNewDataPoint(defaultNewDataPoint);
                      setEditMode(false);
                    }}>
                    <PlusSvg /> &nbsp; {BUTTON_CONSTANTS.ADD_DATA_POINT}
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CustomDataPoints;
