import { toast, ToastOptions } from 'react-toastify';
import { hashCode } from '../utils/commonUtils';

const toastProps: ToastOptions = {
  position: 'top-right',
  autoClose: 4000, //ms
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

let activeToasts: Record<string, number | string> = {};

const showToast = {
  default: (content: string, props?: ToastOptions) => {
    const id = hashCode(content).toString();
    if (!toast.isActive(id)) {
      activeToasts[content] = toast(content, { ...toastProps, toastId: id, ...props });
    }
  },
  info: (content: string, props?: ToastOptions) => {
    const id = hashCode(content).toString();
    if (!toast.isActive(id)) {
      activeToasts[content] = toast.info(content, { ...toastProps, toastId: id, ...props });
    }
  },
  success: (content: string, props?: ToastOptions) => {
    const id = hashCode(content).toString();
    if (!toast.isActive(id)) {
      activeToasts[content] = toast.success(content, {
        ...toastProps,
        toastId: id,
        ...props,
      });
    }
  },
  warning: (content: string, props?: ToastOptions) => {
    const id = hashCode(content).toString();
    if (!toast.isActive(id)) {
      activeToasts[content] = toast.warning(content, {
        ...toastProps,
        toastId: id,
        ...props,
      });
    }
  },
  error: (content: string, props?: ToastOptions) => {
    const id = hashCode(content).toString();
    if (!toast.isActive(id)) {
      activeToasts[content] = toast.error(content, {
        ...toastProps,
        toastId: id,
        ...props,
      });
    }
  },
};

export { showToast };
