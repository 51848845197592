import { FC, useEffect, useState } from 'react';
import { FilterDropdown, Modal, ValidatedInput } from '../../../components';
import { FilterOption } from '../../../components/custom-input/FilterDropdown';
import { ValidatedState } from '../../../components/custom-input/ValidatedInput';
import { AddressType, Validators } from '../../../types/enums';
import { Address } from '../../../types/interfaces';
import './addressInputModal.scss';

type AddressInputModalProps = {
  address: Address;
  validate: boolean;
  isOpen: boolean;
  addressTypeOptions: FilterOption<string>[];
  handleAddressValidity: (address: Address, valid: boolean) => void;
  onClose: (event: boolean) => void;
  confirm: (address: Address) => void;
};

const AddressInputModal: FC<AddressInputModalProps> = ({
  address,
  isOpen,
  validate,
  addressTypeOptions,
  onClose,
  handleAddressValidity,
  confirm,
}) => {
  const [addressType, setAddressType] = useState<ValidatedState>({
    value: address.type!,
    valid: address.type ? true : false,
  });
  const [street, setStreet] = useState<ValidatedState>({
    value: address.streetAddress,
    valid: address.streetAddress ? true : false,
  });
  const [city, setCity] = useState<ValidatedState>({
    value: address.city,
    valid: address.city ? true : false,
  });
  const [state, setState] = useState<ValidatedState>({
    value: address.state,
    valid: address.state ? true : false,
  });
  const [postalCode, setPostalCode] = useState<ValidatedState>({
    value: address.postalCode,
    valid: address.postalCode ? true : false,
  });
  const [country, setCountry] = useState<ValidatedState>({
    value: address.country,
    valid: address.country ? true : false,
  });

  useEffect(() => {
    if (addressType.valid && street.valid && city.valid && state.valid && postalCode.valid && country.valid) {
      handleAddressValidity(address, true);
    } else {
      handleAddressValidity(address, false);
    }
  }, [addressType, street, city, state, postalCode, country]);

  const checkAddressValidity = () => {
    let isAddressValid: boolean = false;
    if (addressType.valid && street.valid && city.valid && state.valid && postalCode.valid && country.valid) {
      isAddressValid = true;
    }
    return isAddressValid;
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onClose(false)}>
      <div className="address-input-container">
        <h2>Add New Address</h2>
        <label className="address-type-label">Address Type</label>
        <FilterDropdown
          options={addressTypeOptions}
          value={{ name: addressType.value || 'Address Type', value: '' }}
          onChange={e => setAddressType({ valid: true, value: e.value })}
        />
        <div className="input">
          <ValidatedInput
            label={'Street'}
            validatedStateForAutoFill={street}
            setValidatedState={setStreet}
            validators={[Validators.REQUIRED]}
            validate={validate}
          />
        </div>
        <div className="input-row">
          <div className="input">
            <ValidatedInput
              label={'City'}
              validatedStateForAutoFill={city}
              setValidatedState={setCity}
              validators={[Validators.REQUIRED]}
              validate={validate}
            />
          </div>
          <div className="input">
            <ValidatedInput
              label={'State'}
              validatedStateForAutoFill={state}
              setValidatedState={setState}
              validators={[Validators.REQUIRED]}
              validate={validate}
            />
          </div>
          <div className="input">
            <ValidatedInput
              label={'Zip Code'}
              type="number"
              validatedStateForAutoFill={postalCode}
              setValidatedState={setPostalCode}
              validators={[Validators.REQUIRED]}
              validate={validate}
            />
          </div>
        </div>
        <div className="input">
          <ValidatedInput
            label={'Country'}
            validatedStateForAutoFill={country}
            setValidatedState={setCountry}
            validators={[Validators.REQUIRED]}
            validate={validate}
          />
        </div>

        <div className="footer">
          <button type="reset" onClick={() => onClose(false)} className="button green inverted mr-1">
            Cancel
          </button>
          <button
            type="submit"
            className="button green"
            onClick={() => {
              confirm({
                ...address,
                type: addressType.value as AddressType,
                streetAddress: street.value,
                city: city.value,
                state: state.value,
                postalCode: postalCode.value,
                country: country.value,
              });
            }}
            disabled={!checkAddressValidity()}>
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddressInputModal;
