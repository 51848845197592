import {Link} from 'react-router-dom';
import {ROUTE_PATHS} from '../../constants/routePaths';
import {AddUserToAccount} from '../dashboard/dashboard-components/AddUserToAccount';

const Settings = (): JSX.Element => {
  return (
    <div className="settings">
      Settings
      <Link to={ROUTE_PATHS.APP_SETTINGS_PASSWORD}>Reset Password</Link>
      <AddUserToAccount />
    </div>
  );
};

export default Settings;
