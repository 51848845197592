const DataSVG = (): JSX.Element => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 0H1.5C1.08516 0 0.75 0.335156 0.75 0.75V20.25C0.75 20.6648 1.08516 21 1.5 21H16.5C16.9148 21 17.25 20.6648 17.25 20.25V0.75C17.25 0.335156 16.9148 0 16.5 0ZM2.4375 1.6875H15.5625V6.5625H2.4375V1.6875ZM15.5625 12.9375H2.4375V8.0625H15.5625V12.9375ZM15.5625 19.3125H2.4375V14.4375H15.5625V19.3125ZM4.125 4.125C4.125 4.37364 4.22377 4.6121 4.39959 4.78791C4.5754 4.96373 4.81386 5.0625 5.0625 5.0625C5.31114 5.0625 5.5496 4.96373 5.72541 4.78791C5.90123 4.6121 6 4.37364 6 4.125C6 3.87636 5.90123 3.6379 5.72541 3.46209C5.5496 3.28627 5.31114 3.1875 5.0625 3.1875C4.81386 3.1875 4.5754 3.28627 4.39959 3.46209C4.22377 3.6379 4.125 3.87636 4.125 4.125ZM4.125 10.5C4.125 10.7486 4.22377 10.9871 4.39959 11.1629C4.5754 11.3387 4.81386 11.4375 5.0625 11.4375C5.31114 11.4375 5.5496 11.3387 5.72541 11.1629C5.90123 10.9871 6 10.7486 6 10.5C6 10.2514 5.90123 10.0129 5.72541 9.83709C5.5496 9.66127 5.31114 9.5625 5.0625 9.5625C4.81386 9.5625 4.5754 9.66127 4.39959 9.83709C4.22377 10.0129 4.125 10.2514 4.125 10.5ZM4.125 16.875C4.125 17.1236 4.22377 17.3621 4.39959 17.5379C4.5754 17.7137 4.81386 17.8125 5.0625 17.8125C5.31114 17.8125 5.5496 17.7137 5.72541 17.5379C5.90123 17.3621 6 17.1236 6 16.875C6 16.6264 5.90123 16.3879 5.72541 16.2121C5.5496 16.0363 5.31114 15.9375 5.0625 15.9375C4.81386 15.9375 4.5754 16.0363 4.39959 16.2121C4.22377 16.3879 4.125 16.6264 4.125 16.875Z"
        fill="#545556"
      />
    </svg>
  );
};
export default DataSVG;
