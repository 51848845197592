import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountSearch, Loader, AnimalSearch, Modal, DateInput, SpecimenOwnershipBreakdown, BackButton } from '../../components';
import PlusCircleSvg from '../../components/svgs/PlusCircle.svg';
import AddAnimal from '../animal-management/AddAnimal';
import { OwnerItemDistribution } from '../../components/specimen-ownership-breakdown/SpecimenOwnershipBreakdown';
import FilterDropdown, { FilterOption, defaultFilterOption } from '../../components/custom-input/FilterDropdown';
import ValidatedInput, { ValidatedState } from '../../components/custom-input/ValidatedInput';
import { AlertSVG } from '../../components/svgs';
import SelectStorageLocations from './select-storage-locations/SelectStorageLocations';
import {
  Account,
  Animal,
  InventoryTransaction,
  SpecimenType,
  StorageLocation,
  StorageSite,
  UserInfo,
} from '../../types/interfaces';
import { QualityType, TransactionTypeEnum, Validators, specimenUOM } from '../../types/enums';
import { SpecimenCustomDataKey, SpecimenCustomDataValue } from '../../types/interfaces/specimenCustomDataKeyModel.interfaces';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import {
  BUTTON_CONSTANTS,
  INVENTORY_CONSTANTS,
  LABEL_CONSTANTS,
  PAGE_HEADER_CONSTANTS,
  TRANSACTION_CONSTANTS,
  VALIDATION_ALERT_CONSTANTS,
} from '../../constants/common';
import { showToast } from '../../services/toast.service';
import { requestRsgUsers } from '../../services/user.service';
import { requestPendingDepositTransactionsByAccountId } from '../../services/inventoryTransaction.service';
import { createCollectTransaction, isCollectDepositValid, updateDepositTransaction } from '../../services/collectDeposit.service';
import { cleanCustomData, handleCustomDataStateChange } from '../../services/customData.service';
import { getSpecimenTypes } from '../../api/specimensTypesApi';
import { getStorageSites } from '../../api/storageItemsApi';
import { getCustomDataKeys } from '../../api/customDataPoints.api';
import { getAnimalById } from '../../api/animalsApi';
import { clampValue, hashCode, redirectToTopOfThePage } from '../../utils/commonUtils';

export type CollectDepositSelectedStates = {
  animal?: Animal;
  account?: Account;
  collectedBy?: FilterOption<UserInfo>;
  freezeLocation?: FilterOption<StorageSite>;
  processedBy?: FilterOption<UserInfo>;
  frozenBy?: FilterOption<UserInfo>;
  quantity?: ValidatedState;
  qualityType?: FilterOption<QualityType>;
  specimensType?: FilterOption<SpecimenType>;
  depositTransaction?: FilterOption<InventoryTransaction>;
  specimenUOM?: FilterOption<specimenUOM>;
  qualityPercentage?: number | null;
  freezeDate?: Date;
  notes?: string;
  storageLocations?: StorageLocation[];
  ownerItemDistribution?: OwnerItemDistribution[];
  specimenCustomDataValues?: SpecimenCustomDataValue[];
};

export const RequireAlert: FC<{ isActive: boolean }> = ({ isActive }) => {
  return isActive ? (
    <div className="validated-input-message-error">
      <AlertSVG />
      {VALIDATION_ALERT_CONSTANTS.REQUIRED_FIELD}
    </div>
  ) : (
    <></>
  );
};

const InventoryManagementCollectDeposit = (): JSX.Element => {
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const transaction: InventoryTransaction | undefined = useLocation().state;

  const [accountWasCleared, setAccountWasCleared] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [transactionType, setTransactionType] = useState<TransactionTypeEnum>(TransactionTypeEnum.Default);
  const [transactionDepositNotes, setTransactionDepositNotes] = useState<string>('');
  const [pendingDepositTransactions, setPendingDepositTransactions] = useState<FilterOption<InventoryTransaction>[]>([]);
  const [specimensType, setSpecimensType] = useState<FilterOption<SpecimenType>[]>([]);
  const [rsgUsers, setRsgUsers] = useState<FilterOption<UserInfo>[]>([]);
  const [freezeLocations, setFreezeLocations] = useState<FilterOption<StorageSite>[]>([]);
  const [addAnimalModal, setAddAnimalModal] = useState(false);
  const [childKey, setChildKey] = useState<number>(0);
  const [isValidationAlertActive, setIsValidationAlertActive] = useState<boolean>(false);
  const [animalAutofill, setAnimalAutofill] = useState<Animal | undefined>();
  const [collectingInventory, setCollectingInventory] = useState<boolean>(true);

  const [selectedStates, setSelectedStates] = useState<CollectDepositSelectedStates>({
    storageLocations: [
      {
        site: { name: '', value: '' },
        tank: { name: '', value: '' },
        canister: { name: '', value: '' },
        quantity: { value: 0 },
        id: hashCode(new Date().toString()),
      },
    ],
  });

  useEffect(() => {
    if (selectedStates?.animal?.owners?.length! < 1 && transactionType === TransactionTypeEnum.Collect) {
      showToast.error(toastMessages.NO_OWNERS_FOUND);
    }
  }, [selectedStates?.animal?.owners]);

  const handleGetPendingDepositTransactions = useCallback(async () => {
    try {
      setSelectedStates(prevStates => ({ ...prevStates, depositTransaction: undefined }));
      setPendingDepositTransactions([]);
      setTransactionDepositNotes('');

      if (selectedStates?.account?.accountId) {
        const { data: requestedPendingDepositTransactions } = (await requestPendingDepositTransactionsByAccountId(
          selectedStates?.account.accountId,
        )) as { data: InventoryTransaction[] };

        const mappedPendingDepositTransactions = requestedPendingDepositTransactions.map(transaction => ({
          name: ` ${transaction.transactionType} - ${new Date(transaction.createdDatetime).toLocaleDateString()} - Qty: ${
            transaction.changeQuantity
          }`,
          value: transaction,
        }));

        setPendingDepositTransactions(mappedPendingDepositTransactions);
      }
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  }, [selectedStates?.account]);

  useEffect(() => {
    handleGetPendingDepositTransactions();
  }, [handleGetPendingDepositTransactions]);

  const handlePageLoad = useCallback(async () => {
    try {
      const [{ data: reqItemType }, { data: reqSiteLocations }, { data: reqRsgUsers }] = await Promise.all([
        getSpecimenTypes(),
        getStorageSites({ filter: 'Active eq true' }),
        requestRsgUsers(),
      ]);

      const mappedItemType = reqItemType.map((item: SpecimenType) => {
        return { name: item.name ?? '', value: item };
      });

      const mappedSiteLocations = (reqSiteLocations as StorageSite[]).map((item: StorageSite) => {
        return { name: item.name ?? '', value: item };
      });

      const usersWithNames = reqRsgUsers.filter(user => user.firstName || user.lastName);

      const mappedRsgUsers = usersWithNames.map(item => {
        return {
          name: `${item.firstName ?? 'Error'} ${item.lastName ?? 'Error'}`,
          value: item,
        };
      });

      setSpecimensType(mappedItemType);
      setFreezeLocations(mappedSiteLocations);
      setRsgUsers(mappedRsgUsers);
      setIsLoading(false);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  }, []);

  useEffect(() => {
    handlePageLoad();
  }, [handlePageLoad]);

  const isValidForm = useCallback(() => {
    if (!selectedStates) return false;
    return isCollectDepositValid(selectedStates, transactionType);
  }, [selectedStates, transactionType]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!collectingInventory) return;
    setCollectingInventory(false);

    if (isValidForm() && selectedStates) {
      try {
        cleanCustomData(selectedStates.specimenCustomDataValues);
        setIsLoading(true);

        if (transactionType === TransactionTypeEnum.Collect) {
          await createCollectTransaction(selectedStates);
          showToast.success(toastMessages.COLLECT_SUCCESSFUL);
        } else {
          await updateDepositTransaction(selectedStates);
          showToast.success(toastMessages.DEPOSIT_SUCCESSFUL);
        }

        handleClearState();
        setIsLoading(false);
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        setIsLoading(false);
      } finally {
        setCollectingInventory(true);
      }
    } else {
      setCollectingInventory(true);
      setIsValidationAlertActive(true);
      if (
        ((selectedStates?.ownerItemDistribution && selectedStates?.ownerItemDistribution?.length <= 0) ||
          selectedStates?.animal?.owners?.length! < 1) &&
        transactionType === TransactionTypeEnum.Collect
      ) {
        showToast.error(toastMessages.NO_OWNERS_FOUND);
      } else if (
        Number(selectedStates?.quantity?.value) !==
        selectedStates?.storageLocations?.reduce((a, b) => a + Number(b.quantity.value), 0)
      ) {
        showToast.error(toastMessages.TOTAL_QTY_MISMATCH);
      } else {
        showToast.error(toastMessages.MISSING_REQUIRED_FIELDS);
      }
    }
  };

  const handleClearState = () => {
    setSelectedStates({
      storageLocations: [
        {
          site: { name: '', value: '' },
          tank: { name: '', value: '' },
          canister: { name: '', value: '' },
          quantity: { value: 0 },
          id: hashCode(new Date().toString()),
        },
      ],
    });
    setTransactionDepositNotes('');
    setPendingDepositTransactions([]);
    setSpecimensType([]);
    setRsgUsers([]);
    setFreezeLocations([]);
    setChildKey(prev => prev + 1);
    setIsValidationAlertActive(false);
    handlePageLoad();
    redirectToTopOfThePage();
  };

  useEffect(() => {
    async function getCustomDataPoints() {
      let customDataArray: SpecimenCustomDataValue[] = [];

      if (selectedStates.specimensType?.value.specimenTypeId) {
        const { data } = await getCustomDataKeys({
          filter: `specimenTypeId eq ${selectedStates.specimensType?.value.specimenTypeId}`,
          include: 'SpecimenType',
        });
        customDataArray = data.map((data: SpecimenCustomDataKey) => {
          return {
            specimenCustomDataKeyId: data.specimenCustomDataKeyId,
            specimenCustomDataKey: data,
            value: '',
          };
        });

        setSelectedStates(prevStates => ({ ...prevStates, specimenCustomDataValues: customDataArray }));
      }
    }
    getCustomDataPoints();
  }, [selectedStates.specimensType?.value.specimenTypeId]);

  function handlePendingDepositChange(option: FilterOption<InventoryTransaction>) {
    setSelectedStates(prevStates => ({ ...prevStates, depositTransaction: option }));
    setTransactionDepositNotes(option.value.notes ?? '');
  }

  useEffect(
    function populatePendingDeposit() {
      if (transaction?.transactionType) {
        setTransactionType(transaction?.transactionType);

        if (Boolean(pendingDepositTransactions.length)) {
          const found = pendingDepositTransactions.find(
            deposit => deposit.value.inventoryTransactionId === transaction?.inventoryTransactionId,
          );

          if (found) handlePendingDepositChange(found);
        }
      }
    },
    [transaction, pendingDepositTransactions],
  );

  const handleAddAnimalAutofill = async (animalId?: number) => {
    if (animalId !== undefined) {
      try {
        const { data: animal } = await getAnimalById(animalId.toString(), { include: 'Owners.Account' });

        setSelectedStates(prevStates => ({ ...prevStates, animal: animal }));
        setAnimalAutofill(animal);
      } catch {}
    }
  };

  return (
    <>
      {isLoading && <Loader loaderSize="medium" pageLoader simple />}
      <div className="inventory-management inventory-management-collect">
        {locationState && locationState.transactionManagementFilterPreset && (
          <BackButton
            onClick={() =>
              navigate(ROUTE_PATHS.APP_TRANSACTION_MANAGEMENT, {
                state: { filterPreset: locationState.transactionManagementFilterPreset, filter: locationState.filter },
              })
            }
          />
        )}
        <div className="inventory-action card">
          <h1>{PAGE_HEADER_CONSTANTS.COLLECT_DEPOSIT_INVENTORY}</h1>
          <form action="submit" onSubmit={e => handleSubmit(e)}>
            <div className="form-row transaction-type-header">
              <div>
                <h2>{TRANSACTION_CONSTANTS.TRANSACTION_TYPE}</h2>
                <RequireAlert isActive={isValidationAlertActive && !transactionType} />
              </div>

              <div className="radio-group">
                <div>
                  <input type="radio" name="transactionType" onChange={() => setTransactionType(TransactionTypeEnum.Collect)} />
                  <label>{LABEL_CONSTANTS.COLLECT}</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="transactionType"
                    onChange={() => setTransactionType(TransactionTypeEnum.Deposit)}
                    checked={transactionType === TransactionTypeEnum.Deposit}
                  />
                  <label>{LABEL_CONSTANTS.DEPOSIT}</label>
                </div>
              </div>
            </div>
            <br />
            {transactionType === TransactionTypeEnum.Deposit && (
              <>
                <div className="form-row">
                  <label>
                    <div>
                      *{LABEL_CONSTANTS.ACCOUNT_NAME}:
                      <RequireAlert isActive={isValidationAlertActive && !selectedStates.account?.name} />
                    </div>
                  </label>
                  <div className="input-container">
                    <AccountSearch
                      populateAccountObject={{ transaction: transaction, accountWasCleared, setAccountWasCleared }}
                      key={childKey}
                      showIcon
                      onChange={(account: Account | undefined) => {
                        if (transactionDepositNotes !== '') setTransactionDepositNotes('');

                        setSelectedStates(prevStates => ({ ...prevStates, account: account }));
                      }}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <label>
                    <div>
                      *{LABEL_CONSTANTS.PENDING_DEPOSIT}
                      <RequireAlert isActive={isValidationAlertActive && !selectedStates.depositTransaction} />
                    </div>
                  </label>
                  <div className="input-container">
                    <FilterDropdown
                      options={pendingDepositTransactions}
                      value={selectedStates?.depositTransaction ?? defaultFilterOption}
                      onChange={option => handlePendingDepositChange(option)}
                    />
                  </div>
                </div>
                {transactionDepositNotes && (
                  <div className="form-row expanded-text-area">
                    <label htmlFor="notes">{TRANSACTION_CONSTANTS.TRANSACTION_NOTES}</label>
                    <textarea
                      id="notes"
                      name="notes"
                      placeholder="Notes"
                      style={{ border: '1', padding: '0.5rem' }}
                      value={transactionDepositNotes}
                      disabled
                      rows={4}
                      cols={50}
                    />
                  </div>
                )}
              </>
            )}
            <h2>{LABEL_CONSTANTS.ITEM_INFO}</h2>
            <div>
              <div className="form-row">
                <label>
                  <div>
                    *{INVENTORY_CONSTANTS.ANIMAL}:
                    <RequireAlert isActive={isValidationAlertActive && !selectedStates.animal} />
                  </div>
                </label>
                <div className="input-container animal-container">
                  <AnimalSearch
                    key={childKey}
                    onChange={animal => setSelectedStates(prevStates => ({ ...prevStates, animal: animal }))}
                    showIcon
                    disabled={!transactionType}
                    includeOwners
                    includeCustomData
                    animalAutofill={animalAutofill}
                  />
                  <button type="button" className="button" disabled={!transactionType} onClick={() => setAddAnimalModal(true)}>
                    <div className="add-animal-button">
                      <PlusCircleSvg />
                      &nbsp;&nbsp;{BUTTON_CONSTANTS.ADD_ANIMAL}
                    </div>
                  </button>
                </div>
              </div>
              <div className="form-row">
                <label>
                  <div>
                    *{INVENTORY_CONSTANTS.ITEM_TYPE}:
                    <RequireAlert isActive={isValidationAlertActive && !selectedStates.specimensType} />
                  </div>
                </label>
                <div className="input-container">
                  <FilterDropdown
                    placeholder="Select Item Type"
                    options={specimensType}
                    value={selectedStates?.specimensType ?? defaultFilterOption}
                    onChange={option => setSelectedStates(prevStates => ({ ...prevStates, specimensType: option }))}
                    disabled={!transactionType}
                  />
                </div>
              </div>

              {Boolean(selectedStates.specimenCustomDataValues?.length) && (
                <div className="custom-data-container" key={selectedStates.animal?.animalId}>
                  {selectedStates.specimenCustomDataValues?.map((data: SpecimenCustomDataValue, index: number) => {
                    if (data.specimenCustomDataKey?.active) {
                      const { isNumeric, keyName, required, minValue, maxValue, specimenTypeId, specimenCustomDataKeyId } =
                        data.specimenCustomDataKey;
                      return (
                        <div
                          className="custom-data form-row"
                          key={specimenTypeId + (selectedStates.specimensType?.name ?? '') + index}>
                          <label key={keyName}>
                            {required ? '*' : ''}
                            {keyName}:
                            <RequireAlert
                              isActive={isValidationAlertActive && data.specimenCustomDataKey.required && !data.value?.trim()}
                            />
                          </label>
                          <div className="input-container" key={specimenCustomDataKeyId! + index}>
                            <ValidatedInput
                              label={<></>}
                              type={`${isNumeric ? 'number' : 'text'}`}
                              placeholder={`${data.value ? data.value : isNumeric ? 'Numeric' : 'Text'}`}
                              min={minValue ?? undefined}
                              max={maxValue ?? undefined}
                              setValidatedState={value =>
                                handleCustomDataStateChange(
                                  value,
                                  data,
                                  selectedStates.specimenCustomDataValues,
                                  setSelectedStates,
                                )
                              }
                              validators={required ? [Validators.REQUIRED] : []}
                            />
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              )}

              <div className="form-row">
                <label>
                  <div>
                    *{INVENTORY_CONSTANTS.ITEM_UNIT_MEASURE}:
                    <RequireAlert isActive={isValidationAlertActive && !selectedStates.specimenUOM} />
                  </div>
                </label>
                <div className="input-container">
                  <FilterDropdown
                    placeholder="Select UoM"
                    options={Object.values(specimenUOM).map(item => ({ name: item, value: item })) as FilterOption<specimenUOM>[]}
                    value={selectedStates?.specimenUOM ?? defaultFilterOption}
                    onChange={option => setSelectedStates(prevStates => ({ ...prevStates, specimenUOM: option }))}
                    disabled={!transactionType}
                  />
                </div>
              </div>
              <div className="form-row">
                <label>{INVENTORY_CONSTANTS.QUALITY}:</label>
                <div className="input-container">
                  <FilterDropdown
                    placeholder="Quality Score"
                    disabled={!transactionType}
                    options={Object.values(QualityType).map(item => ({ name: item, value: item })) as FilterOption<QualityType>[]}
                    value={selectedStates?.qualityType ?? defaultFilterOption}
                    onChange={option => setSelectedStates(prevStates => ({ ...prevStates, qualityType: option }))}
                  />
                </div>
              </div>
              <div className="form-row">
                <ValidatedInput
                  setValidatedState={e =>
                    setSelectedStates(prevStates => ({
                      ...prevStates,
                      qualityPercentage: e.value.length > 0 && e.value !== '-' ? clampValue(0, 100, Number(e.value)) : undefined,
                    }))
                  }
                  placeholder={'Quality %'}
                  min={0}
                  max={100}
                  disabled={!transactionType}
                  label="Quality %:"
                  validators={[]}
                  type="number"
                  validatedStateForAutoFill={{ value: (selectedStates?.qualityPercentage ?? '').toString(), valid: true }}
                />
              </div>
              <div className="form-row">
                <label>
                  <div>
                    *{INVENTORY_CONSTANTS.LOT_DATE}:
                    <RequireAlert isActive={isValidationAlertActive && !selectedStates.freezeDate} />
                  </div>
                </label>
                <div className="input-container">
                  <DateInput
                    disabled={!transactionType}
                    value={selectedStates?.freezeDate}
                    onChange={(date: Date) => setSelectedStates(prevStates => ({ ...prevStates, freezeDate: date }))}
                    maxDate={new Date()}
                  />
                </div>
              </div>
              <div className="form-row">
                <label>{INVENTORY_CONSTANTS.COLLECTION_SITE}:</label>
                <div className="input-container">
                  <FilterDropdown
                    disabled={!transactionType}
                    options={freezeLocations}
                    value={selectedStates?.freezeLocation ?? defaultFilterOption}
                    onChange={option => setSelectedStates(prevStates => ({ ...prevStates, freezeLocation: option }))}
                  />
                </div>
              </div>
              <div className="form-row">
                <label>{INVENTORY_CONSTANTS.COLLECTED_BY}:</label>
                <div className="input-container">
                  <FilterDropdown
                    disabled={!transactionType}
                    placeholder="Select Employee"
                    options={rsgUsers}
                    value={selectedStates?.collectedBy ?? defaultFilterOption}
                    onChange={option => setSelectedStates(prevStates => ({ ...prevStates, collectedBy: option }))}
                  />
                </div>
              </div>
              <div className="form-row">
                <label>{INVENTORY_CONSTANTS.PROCESSED_BY}:</label>
                <div className="input-container">
                  <FilterDropdown
                    disabled={!transactionType}
                    placeholder="Select Employee"
                    options={rsgUsers}
                    value={selectedStates?.processedBy ?? defaultFilterOption}
                    onChange={option => setSelectedStates(prevStates => ({ ...prevStates, processedBy: option }))}
                  />
                </div>
              </div>
              <div className="form-row">
                <label>{INVENTORY_CONSTANTS.FROZEN_BY}:</label>
                <div className="input-container">
                  <FilterDropdown
                    placeholder="Select Employee"
                    disabled={!transactionType}
                    options={rsgUsers}
                    value={selectedStates?.frozenBy ?? defaultFilterOption}
                    onChange={option => setSelectedStates(prevStates => ({ ...prevStates, frozenBy: option }))}
                  />
                </div>
              </div>
              <div className="form-row">
                <ValidatedInput
                  key={childKey}
                  disabled={!transactionType}
                  validate={isValidationAlertActive}
                  type="number"
                  label="*Quantity:"
                  placeholder="0"
                  min={0}
                  validatedStateForAutoFill={selectedStates?.quantity ?? { value: '', valid: false }}
                  setValidatedState={validatedValue =>
                    setSelectedStates(prevStates => ({ ...prevStates, quantity: validatedValue }))
                  }
                  validators={[Validators.REQUIRED]}
                />
              </div>
              <div className="form-row expanded-text-area">
                <label htmlFor="notes">{LABEL_CONSTANTS.NOTES}</label>
                <textarea
                  id="notes"
                  name="notes"
                  placeholder="Notes"
                  onChange={event => setSelectedStates(prevStates => ({ ...prevStates, notes: event.target.value }))}
                  value={selectedStates?.notes ?? ''}
                  rows={4}
                  cols={50}
                  disabled={!transactionType}
                />
              </div>
            </div>
            {transactionType === TransactionTypeEnum.Collect && (
              <>
                <h2>{INVENTORY_CONSTANTS.ITEM_OWNERSHIP_BREAKDOWN}</h2>
                {selectedStates?.animal &&
                  selectedStates?.animal.owners !== undefined &&
                  selectedStates.animal.owners.length > 0 && (
                    <SpecimenOwnershipBreakdown
                      animalOwners={selectedStates.animal.owners}
                      quantity={String(selectedStates?.quantity?.value ?? '')}
                      ownerItemDistribution={selectedStates.ownerItemDistribution ?? []}
                      setOwnerItemDistribution={e =>
                        setSelectedStates(prevStates => ({ ...prevStates, ownerItemDistribution: e }))
                      }
                    />
                  )}
              </>
            )}
            <h2>{LABEL_CONSTANTS.STORAGE_LOCATION}</h2>
            {transactionType && (
              <SelectStorageLocations
                selectedStorageLocations={selectedStates?.storageLocations ?? []}
                isValidationAlertActive={isValidationAlertActive}
                setSelectedStorageLocations={newStorageLocations =>
                  setSelectedStates(prevStates => ({ ...prevStates, storageLocations: newStorageLocations }))
                }
                quantityToMatch={Number(selectedStates?.quantity?.value ?? 0)}
                activeLocationsOnly
              />
            )}
            <div className="submit-button">
              <button type="submit" className={isValidForm() ? 'button small green' : 'disabled button small green'}>
                {BUTTON_CONSTANTS.SUBMIT}
              </button>
            </div>
          </form>
          <Modal isOpen={addAnimalModal} onClose={() => setAddAnimalModal(false)} width={1014} ignoreBackdrop>
            <AddAnimal
              onCancel={(animalId?: number) => {
                handleAddAnimalAutofill(animalId);
                setAddAnimalModal(false);
              }}
            />
          </Modal>
        </div>
      </div>
    </>
  );
};

export default InventoryManagementCollectDeposit;
