import { createSlice } from '@reduxjs/toolkit';
import { Role } from '../../types/interfaces/role.interfaces';
import { selectOption } from '../../types/interfaces/selectOption.interface';
import { AccountRoles, getRoleValue } from '../../types/enums/accountRoles.enum';
import { AppRoles } from '../../types/enums';

interface Roles {
  optionSelectAccountRoles: selectOption[];
  accountRoles: selectOption[];
  allRoles: Role<AppRoles | AccountRoles>[];
}

const initialState = {
  optionSelectAccountRoles: [],
  accountRoles: [],
  allRoles: [],
} as Roles;

const filterRolesForOptionSelect = (allRoles: Role<AppRoles | AccountRoles>[]): selectOption[] => {
  return allRoles
    .filter(r => r.name.includes('Account'))
    .map(r => {
      return {
        value: r.name,
        name: getRoleValue(r.name),
      };
    });
};

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    storeRoles: (state, action) => {
      state.allRoles = action.payload;
      state.accountRoles = filterRolesForOptionSelect(action.payload);
      state.optionSelectAccountRoles = filterRolesForOptionSelect(action.payload).filter(r => !r.name.includes('Owner'));
    },
  },
});

export const { storeRoles } = rolesSlice.actions;

export default rolesSlice.reducer;
