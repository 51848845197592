import { AxiosResponse } from 'axios';
import { appContextGet, appContextPost, appContextDelete } from './httpCommon/appAxios.service';
import { CartSpecimenRequestBody, CartItem, CartTransactionResponse } from '../types/interfaces/transactionCart.interfaces';
import { TRANSACTION_CART_API_ENDPOINTS } from '../constants/apiEndpoints';

export const addSelectedSpecimenToCart = (payload: CartSpecimenRequestBody): Promise<AxiosResponse<{}, any>> => {
  return appContextPost(TRANSACTION_CART_API_ENDPOINTS.ADD_TO_CART, payload);
};

export const getCartCompletionTransaction = (
  userId: Number,
  accountId: number,
  transactionType: string,
): Promise<AxiosResponse<CartTransactionResponse, any>> => {
  return appContextGet(TRANSACTION_CART_API_ENDPOINTS.GET_CART_TRANSACTION, { userId, accountId, transactionType });
};

export const deleteSpecimenFromTransactionCart = (transactionCartItemId: number): Promise<AxiosResponse<{}, any>> => {
  return appContextDelete(TRANSACTION_CART_API_ENDPOINTS.DELETE_SPECIMEN + transactionCartItemId);
};

export const getTransactionCartItems = (params: {}): Promise<AxiosResponse<CartItem[], any>> => {
  return appContextGet(TRANSACTION_CART_API_ENDPOINTS.GET_TRANSACTION_CART_ITEMS, params);
};

export const deleteTransactionCartItem = (userId: number, accountId: number, type: string): Promise<AxiosResponse<{}, any>> => {
  return appContextDelete(TRANSACTION_CART_API_ENDPOINTS.DELETE_TRANSACTION_CART_ITEM, {
    userId: userId,
    transactionType: type,
    accountId: accountId,
  });
};
