import { FC, useState } from 'react';
import DropDownSVG from '../svgs/DropDown.svg';
import Loader from '../loader/Loader';
import { LABEL_CONSTANTS } from '../../constants/common';

type SortedTableProps = {
  headers: TableSortHeader[];
  data?: TableSortCell[][];
  sortIndex?: number;
  loading?: boolean;
  noResultsMessage?: string;
};

export interface TableSortHeader {
  displayName: string;
  sortable?: boolean;
}

export interface TableSortCell {
  content: any;
  sortValue?: string | number | Date;
}

const SortedTable: FC<SortedTableProps> = ({
  headers,
  data,
  sortIndex = 0,
  loading = false,
  noResultsMessage = LABEL_CONSTANTS.NO_RESULTS_FOUND,
}: SortedTableProps) => {
  const [sortHeader, setSortHeader] = useState<TableSortHeader>();
  const [currentSortIndex, setSortIndex] = useState<number>(sortIndex);
  const [sortAsc, setSortAsc] = useState<boolean>(false);

  const toggleSort = (header: TableSortHeader): void => {
    const index = headers.indexOf(header);
    if (index === sortIndex) {
      setSortAsc(!sortAsc);
    } else {
      setSortIndex(index);
      setSortHeader(header);
    }
  };

  const sortTable = (cells: TableSortCell[][]) => {
    return cells.sort((rowA, rowB) => {
      const A = rowA[sortIndex].sortValue ?? rowA[sortIndex].content;
      const B = rowB[sortIndex].sortValue ?? rowB[sortIndex].content;

      return A === B ? 0 : A < B ? (sortAsc ? 1 : -1) : sortAsc ? -1 : 1;
    });
  };

  return (
    <div className="sorted-table">
      <table>
        <thead>
          <tr>
            {headers.map((header, i) => (
              <th
                key={header + '-' + i}
                className={header.sortable === true ? 'sortable' : ''}
                onClick={() => header.sortable && toggleSort(header)}>
                {header.displayName}
                {header.sortable === true && (
                  <span className={(sortAsc ? 'sort-arrow reverse ' : 'sort-arrow ') + (i === sortIndex ? 'sorting' : '')}>
                    <DropDownSVG />
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            sortTable(data).map((row, r) => (
              <tr key={'r' + r}>
                {row.map((item, i) => (
                  <td key={r + '-' + i}>{item.content}</td>
                ))}
              </tr>
            ))}
          {loading && (
            <tr>
              <td colSpan={headers.length}>
                <>
                  <br />
                  <br />
                  <Loader loaderSize="small" />
                  <br />
                  <br />
                </>
              </td>
            </tr>
          )}
          {!loading && data?.length === 0 && (
            <tr>
              <td colSpan={headers.length}>
                <br />
                {noResultsMessage}
                <br />
                <br />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SortedTable;
