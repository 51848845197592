import { useState } from 'react';
import Modal from '../modal/Modal';
import SignOutSvg from '../svgs/SignOut';
import { signOutUser } from '../../services/auth.service';
import './signOutModal.scss';

const SignOutModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="nav-link" onClick={() => setIsOpen(true)}>
        <SignOutSvg />
        <label>Sign Out</label>
      </div>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} width={490}>
        <div className="sign-out-modal">
          <div className="header">
            <h4>Sign Out</h4>
          </div>
          <div className="body">
            <p>Are you sure you want to Sign Out?</p>
          </div>
          <div className="footer">
            <button className="button blue inverted small" onClick={() => setIsOpen(false)}>
              Cancel
            </button>
            <button className="button red small" onClick={() => signOutUser()}>
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SignOutModal;
