import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AnimalBanner from '../../../components/animal/AnimalBanner';
import SortedTable from '../../../components/sorted-table/SortedTable';
import ActionMenu from '../../../components/action-buttons/ActionButtons';
import QuickView from '../../../components/quick-view/QuickView';
import PagedTable from '../../../components/sorted-table/PagedTable';
import { BackButton, Loader } from '../../../components';
import AnimalManagementHeader from '../AnimalManagementHeader';
import { ANIMAL_MANAGEMENT_PATHS, ROUTE_PATHS } from '../../../constants/routePaths';
import { PromiseError, toastMessages } from '../../../constants/errorMessages';
import { ANIMAL_CONSTANTS, BUTTON_CONSTANTS, LABEL_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../../constants/common';
import { showToast } from '../../../services/toast.service';
import { AnimalOwner } from '../../../types/interfaces/animal.interfaces';
import { Account } from '../../../types/interfaces/account.interfaces';
import { PagedResponse, StandardParams } from '../../../types/interfaces/apiParams.interfaces';
import { Specimen } from '../../../types/interfaces/specimen.interfaces';
import { getSpecimens } from '../../../api/specimensApi';
import { getCountBySpecimen } from '../../../api/inventoryApi';
import { getAnimalOwnership } from '../../../api/animalOwnersApi';
import { getAccounts } from '../../../api/accountApi';
import './animalOverview.scss';

interface HistoryItem extends Specimen {
  currentQuantity: number;
}

const AnimalOverview = (): JSX.Element => {
  const { animalId } = useParams();
  const navigate = useNavigate();

  const [ownership, setOwnership] = useState<AnimalOwner[]>();
  const [history, setHistory] = useState<HistoryItem[]>();
  const [name, setName] = useState<string>('');
  const [isQuickViewOpen, setIsQuickViewOpen] = useState<boolean>(false);
  const [specimen, setSpecimen] = useState<Specimen>();
  const [animalInventoryHistory, setAnimalInventoryHistory] = useState<HistoryItem[]>();

  const handleOwnership = useCallback(async () => {
    if (animalId) {
      try {
        const { data: animalOwnership } = await getAnimalOwnership({
          include: 'Animal,Account',
          filter: `AnimalId eq ${animalId}`,
        });

        if (Array.isArray(animalOwnership)) {
          const accountIds: number[] = Array.from(new Set(animalOwnership.map((o: AnimalOwner) => o.accountId!)));

          if (animalOwnership.length > 0 && animalOwnership[0].animal?.name) {
            setName(animalOwnership[0].animal?.name);
          }

          await handleOwnerAccount(accountIds, animalOwnership);
        } else {
          throw new Error('Received unexpected type');
        }
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  }, [animalId]);

  const handleHistory = useCallback(
    async (params?: StandardParams) => {
      const res = await getHistory(params);
      setHistory(res?.data);
    },
    [animalId],
  );

  const getHistory = async (params?: StandardParams) => {
    if (animalId) {
      try {
        const { data: specimens } = await getSpecimens({
          filter: params?.filter ?? `AnimalId eq ${animalId}`,
          include: params?.include,
          sort: params?.sort,
        });

        const quantityPromises = specimens.map(async specimenItem => {
          const { data: currentQuantity } = await getCountBySpecimen(specimenItem.specimenId);
          return { ...specimenItem, currentQuantity };
        });

        setAnimalInventoryHistory(await Promise.all(quantityPromises));
        return { data: await Promise.all(quantityPromises) };
      } catch (error: any) {
        if (error.code === PromiseError.NETWORK) {
          showToast.error(toastMessages.NETWORK);
        } else {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      }
    }
  };

  useEffect(() => {
    handleHistory();
  }, [handleHistory]);

  useEffect(() => {
    handleOwnership();
  }, [handleOwnership]);

  const handleOwnerAccount = async (ids: number[], owners: AnimalOwner[]) => {
    if (ids && owners) {
      try {
        const { data: accounts } = await getAccounts(ids);
        const enrichedOwners = owners.map(owner => ({
          ...owner,
          account: getAccountById(accounts, owner),
        }));
        setOwnership(enrichedOwners);
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  };

  const getAccountById = (accounts: Account[] | PagedResponse<Account>, owner: AnimalOwner): Account | undefined => {
    if (Array.isArray(accounts)) {
      return accounts.find((a: Account) => a.accountId === owner.accountId);
    }
  };

  const handleQuickViewOpen = (item: HistoryItem) => {
    if (!item || !item.specimenId) throw Error('Cannot open QuickView Modal because inventory was undefined.');

    setSpecimen(item);
    setIsQuickViewOpen(true);
  };

  const actionMenuButton = (item: HistoryItem) => {
    return (
      <ActionMenu
        actionButtons={[
          {
            name: BUTTON_CONSTANTS.VIEW_DETAILS,
            action: () => navigate(ROUTE_PATHS.APP_ANIMAL_MANAGEMENT_ + animalId + '/' + item.specimenId),
          },

          {
            name: BUTTON_CONSTANTS.QUICK_VIEW,
            action: () => handleQuickViewOpen(item),
          },
        ]}
      />
    );
  };

  const getMobileInventoryTableView = () => {
    return (
      animalInventoryHistory &&
      animalInventoryHistory.map((item, index) => {
        return (
          <div className="card max-width" key={'animal-' + item?.specimenId + index}>
            <div className="animal-inventory-card">
              <div className="animal-inventory-content">
                <div className="card-content-section">
                  <div className="inventory-content">
                    <label>{TABLE_HEADER_CONSTANTS.INVENTORY_TYPE}:</label>
                    <label>{`${item.specimenType?.name ?? '-'}`}</label>
                  </div>
                  <div className="inventory-content">
                    <label>{TABLE_HEADER_CONSTANTS.LOT_DATE_NO}:</label>
                    <label>{`${new Date(item.freezeDate).toLocaleDateString()} - ${item.specimenId}`}</label>
                  </div>
                  <div className="inventory-content">
                    <label>{TABLE_HEADER_CONSTANTS.QUALITY}:</label>
                    <label>{`${item.quality ?? '-'}`}</label>
                  </div>
                </div>
                <div className="card-content-section">
                  <div className="inventory-content">
                    <label>{TABLE_HEADER_CONSTANTS.QUALITY_PERCENTAGE}:</label>
                    <label>{`${item.qualityPercentage ?? '-'}`}</label>
                  </div>
                  <div className="inventory-content">
                    <label>{TABLE_HEADER_CONSTANTS.ORIGINAL_QTY}:</label>
                    <label>{`${item.originalQuantity ?? '-'}`}</label>
                  </div>
                  <div className="inventory-content">
                    <label>{TABLE_HEADER_CONSTANTS.CURRENT_QTY}:</label>
                    <label>{`${item.currentQuantity ?? '-'}`}</label>
                  </div>
                </div>
              </div>
              <div className="action-button">{actionMenuButton(item)}</div>
            </div>
          </div>
        );
      })
    );
  };

  return (
    <div className="animal-overview">
      {specimen && (
        <QuickView
          specimen={specimen}
          isOpen={isQuickViewOpen}
          onClose={() => setIsQuickViewOpen(false)}
          setIsOpen={setIsQuickViewOpen}
        />
      )}

      <AnimalManagementHeader />
      <BackButton />
      <AnimalBanner animalId={animalId}>
        <div className="action-buttons">
          <button
            className="button blue small"
            onClick={() => navigate(ROUTE_PATHS.APP_ANIMAL_MANAGEMENT_ + animalId + '/' + ANIMAL_MANAGEMENT_PATHS.EDIT)}>
            {BUTTON_CONSTANTS.EDIT}
          </button>
        </div>
      </AnimalBanner>

      <div className="animal-overview-ownership">
        <h2>{name || `Animal's`} Inventory</h2>
        {history === undefined ? (
          <Loader addedSpace loaderSize="small" />
        ) : history?.length > 0 ? (
          <>
            <div className="desk-animal-overview-ownership">
              <PagedTable
                headers={[
                  TABLE_HEADER_CONSTANTS.INVENTORY_TYPE,
                  TABLE_HEADER_CONSTANTS.LOT_DATE_NO,
                  TABLE_HEADER_CONSTANTS.QUALITY,
                  TABLE_HEADER_CONSTANTS.QUALITY_PERCENTAGE,
                  TABLE_HEADER_CONSTANTS.ORIGINAL_QUANTITY,
                  TABLE_HEADER_CONSTANTS.CURRENT_QTY,
                  TABLE_HEADER_CONSTANTS.ACTION_ITEM,
                ].map(header => {
                  return { displayName: header };
                })}
                filter={'AnimalId eq ' + animalId}
                include="SpecimenType,SpecimenCustomDataValues.SpecimenCustomDataKey"
                sortBy="^freezeDate"
                getData={getHistory}
                buildRow={(item: HistoryItem) => {
                  return [
                    item.specimenType?.name,
                    `${new Date(item.freezeDate).toLocaleDateString()} - ${item.specimenId}`,
                    item.quality,
                    item.qualityPercentage,
                    item.originalQuantity,
                    item.currentQuantity,
                    actionMenuButton(item),
                  ];
                }}
                pageSize={0}
                height={300}
                minHeight={130}
              />
            </div>

            {/** Mobile View */}
            <div className="xs-animal-overview-ownership">{getMobileInventoryTableView()}</div>
          </>
        ) : (
          <h4 className="no-ownership">{LABEL_CONSTANTS.NO_INVENTORY}</h4>
        )}

        <h2>{ANIMAL_CONSTANTS.ANIMAL_OWNERSHIP_BREAKDOWN}</h2>
        {ownership === undefined ? (
          <Loader addedSpace loaderSize="small" />
        ) : ownership?.length > 0 ? (
          <div className="animal-ownership-table">
            <SortedTable
              headers={[
                { displayName: TABLE_HEADER_CONSTANTS.ACCOUNT_BUSINESS_FARM },
                { displayName: TABLE_HEADER_CONSTANTS.EMAIL_ADDRESS },
                { displayName: TABLE_HEADER_CONSTANTS.OWNERSHIP_PERCENTAGE },
              ]}
              data={ownership?.map((o: any) => {
                return [{ content: o.account.name }, { content: o.account.contactEmail }, { content: o.ownershipPercentage }];
              })}
            />
          </div>
        ) : (
          <h4 className="no-ownership">{ANIMAL_CONSTANTS.NO_OWNERSHIP}</h4>
        )}
      </div>
    </div>
  );
};

export default AnimalOverview;
