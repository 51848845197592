import { useState } from 'react';
import SortArrow from '../svgs/SortArrow.svg';

interface SearchTableProps {
  headers: HeaderConfig[];
  data: any[];
}

export interface HeaderConfig {
  propertyName: string;
  displayName?: string;
  searchable?: boolean;
  sortable?: boolean;
  cell?: (data: any) => JSX.Element;
}

const SearchableTable = ({ headers, data }: SearchTableProps): JSX.Element => {
  const [searchText, setSearchText] = useState<string>('');
  const [sortedColumn, setSortedColumn] = useState<string>('');
  const [sortAscending, setSortAscending] = useState<boolean>(false);

  const searchableHeaders = headers.filter((h: any) => h.searchable).map((h: any) => h.propertyName);

  const handleSort = (columnName: string) => {
    if (columnName === sortedColumn) {
      setSortAscending(!sortAscending);
    }
    setSortedColumn(columnName);
  };

  const sortCriteria = (a: any, b: any) => {
    if (sortAscending && sortedColumn) {
      return a[sortedColumn].toUpperCase() < b[sortedColumn].toUpperCase() ? -1 : 1;
    } else if (!sortAscending && sortedColumn) {
      return a[sortedColumn].toUpperCase() > b[sortedColumn].toUpperCase() ? -1 : 1;
    } else {
      return 0;
    }
  };

  const filterCriteria = (item: any) => {
    if (searchText !== '') {
      let containsSearchText = false;
      searchableHeaders.forEach((header: any) => {
        if (item[header].trim().toUpperCase().includes(searchText.trim().toUpperCase())) {
          containsSearchText = true;
        }
      });
      return containsSearchText;
    } else {
      return true;
    }
  };

  return (
    <div className="searchable-table">
      <div className="search-bar">
        <input type="search" value={searchText} onChange={e => setSearchText(e.target.value)} placeholder="Search User" />
      </div>

      <div className="scrollable">
        <table>
          <thead>
            <tr className="header-row">
              {headers.map((h: any, index: number) => {
                return (
                  h?.cell && (
                    <th
                      key={'table-header-' + index}
                      onClick={() => h?.sortable && handleSort(h.propertyName)}
                      className={sortedColumn === h?.propertyName ? 'sorted-column' : undefined}>
                      <div className="sorted-table-header">
                        <p>{h?.displayName}</p>
                        {h?.sortable && (
                          <SortArrow direction={sortAscending && sortedColumn === h.propertyName ? 'up' : 'down'} />
                        )}
                      </div>
                    </th>
                  )
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data
              ?.filter((row: any) => {
                return filterCriteria(row);
              })
              ?.sort((a: any, b: any) => {
                return sortCriteria(a, b);
              })
              ?.map((row: any, index: number) => {
                return (
                  <tr key={'table-row-' + index}>
                    {headers?.map((h: any, i: number) => {
                      return h?.cell && <td key={'table-data-' + i}>{h.cell(row)}</td>;
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SearchableTable;
