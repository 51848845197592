import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import BackButton from '../../components/back-button/BackButton';
import SortedTable from '../../components/sorted-table/SortedTable';
import AnimalBanner from '../../components/animal/AnimalBanner';
import ActionMenu from '../../components/action-buttons/ActionButtons';
import QuickView from '../../components/quick-view/QuickView';
import { INVENTORY_PATHS, ROUTE_PATHS } from '../../constants/routePaths';
import { toastMessages } from '../../constants/errorMessages';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../constants/common';
import { getSpecimensByOwnership } from '../../api/specimensApi';
import { getCatalogQuantity } from '../../api/catalogApi';
import { getAnimalOwnershipsByAccountId } from '../../api/animalOwnersApi';
import { PermissionService } from '../../services/permission.service';
import { showToast } from '../../services/toast.service';
import { AppRoles } from '../../types/enums/appRoles.enum';
import { AccountRoles } from '../../types/enums/accountRoles.enum';
import { Specimen, SpecimenInventory } from '../../types/interfaces/specimen.interfaces';

export type SpecimenRow = {
  availableQty: number;
  ownedQty: number;
  specimen: Specimen;
};

const InventoryItem = (): JSX.Element => {
  const { animalId } = useParams();
  const navigate = useNavigate();

  const { accountId } = useAppSelector(state => state.activeAccount);
  const accountLocked = useAppSelector(state => state.activeAccount.locked);
  const accountRoles = useAppSelector(state => state.auth.accountContext.roles) as AccountRoles[];
  const activeAccount = useAppSelector(state => state.activeAccount);
  const appRoles = useAppSelector(state => state.auth.appContext.roles) as AppRoles[];
  const enabled = useAppSelector(state => state.user.enabled);
  const paymentStatus = useAppSelector(state => state.payment.addedPaymentMethod);
  const verified = useAppSelector(state => state.user.verified);

  const [catalogQuantity, setCatalogQuantity] = useState<number>();
  const [doesAccountHasAnimalOwnership, setDoesAccountHasAnimalOwnership] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isQuickViewOpen, setIsQuickViewOpen] = useState<boolean>(false);
  const [quantityOwned, setQuantityOwned] = useState<number>();
  const [specimen, setSpecimen] = useState<Specimen>();
  const [specimens, setSpecimens] = useState<SpecimenRow[]>();

  const permissionService = new PermissionService(accountRoles, appRoles, enabled, verified);

  const handleGetSpecimens = useCallback(async () => {
    if (animalId && accountId) {
      try {
        setIsLoading(true);
        const params = { include: 'SpecimenType, SpecimenCustomDataValue' };
        const specs = await getSpecimensByOwnership(animalId, accountId, params);
        let owned = 0;

        const quants = await Promise.all(
          specs.data.map(async (spec: SpecimenInventory): Promise<any> => {
            owned += spec.quantityOwned;
            return { specimen: spec, availableQty: spec.quantityAvailable, ownedQty: spec.quantityOwned };
          }),
        );
        setQuantityOwned(owned);
        setSpecimens(quants);
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setIsLoading(false);
      }
    }
  }, [animalId, accountId]);

  const handleGetCatalogQuantity = useCallback(async () => {
    if (animalId && accountId && doesAccountHasAnimalOwnership) {
      try {
        const { data: catalogUnits } = await getCatalogQuantity(animalId);
        catalogUnits != undefined && setCatalogQuantity(catalogUnits);
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  }, [doesAccountHasAnimalOwnership, animalId, accountId]);

  const handleGetAnimalOwnership = useCallback(async () => {
    try {
      const { data: animalOwnership } = await getAnimalOwnershipsByAccountId({ filter: `accountId eq ${accountId}` });
      const found = animalOwnership.some(animal => animal.animalId === Number(animalId));
      setDoesAccountHasAnimalOwnership(found);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  }, [animalId, accountId]);

  useEffect(() => {
    handleGetAnimalOwnership();
  }, [handleGetAnimalOwnership]);

  useEffect(() => {
    handleGetSpecimens();
  }, [handleGetSpecimens]);

  useEffect(() => {
    handleGetCatalogQuantity();
  }, [handleGetCatalogQuantity]);

  const onSell = () => {
    navigate(ROUTE_PATHS.APP_INVENTORY + '/' + animalId + '/' + INVENTORY_PATHS.SELL);
  };

  const onWithdraw = () => {
    navigate(ROUTE_PATHS.APP_INVENTORY + '/' + animalId + '/' + INVENTORY_PATHS.WITHDRAW);
  };

  const onUse = () => {
    navigate(ROUTE_PATHS.APP_INVENTORY + '/' + animalId + '/' + INVENTORY_PATHS.USE);
  };

  const onDiscard = () => {
    navigate(ROUTE_PATHS.APP_INVENTORY + '/' + animalId + '/' + INVENTORY_PATHS.DISCARD), { animalId };
  };

  const handleQuickViewOpen = (item: Specimen) => {
    setSpecimen(item);
    setIsQuickViewOpen(true);
  };

  const getInventoryItems = (headers: string[], className: string, heading: string) => {
    return (
      <div className={className}>
        <h1>{heading}</h1>
        <SortedTable
          headers={headers.map((header: string) => {
            return { displayName: header };
          })}
          data={specimens?.map((spec: SpecimenRow) => {
            return className === 'mobile-view'
              ? [
                  { content: `${spec.specimen.specimenId} ` },
                  { content: spec.availableQty },
                  { content: spec.ownedQty },
                  {
                    content: (
                      <ActionMenu
                        actionButtons={[{ name: BUTTON_CONSTANTS.QUICK_VIEW, action: () => handleQuickViewOpen(spec.specimen) }]}
                      />
                    ),
                  },
                ]
              : [
                  { content: spec.specimen.specimenType?.name },
                  { content: spec.specimen.specimenUOM },
                  {
                    content: `${new Date(spec.specimen.freezeDate.toString() as string).toLocaleDateString()} - ${
                      spec.specimen.specimenId
                    } `,
                  },
                  { content: spec.specimen.quality },
                  { content: spec.availableQty },
                  { content: spec.ownedQty },
                  {
                    content: (
                      <ActionMenu
                        actionButtons={[{ name: BUTTON_CONSTANTS.QUICK_VIEW, action: () => handleQuickViewOpen(spec.specimen) }]}
                      />
                    ),
                  },
                ];
          })}
          loading={isLoading}
          noResultsMessage={LABEL_CONSTANTS.NO_INVENTORY}
        />
      </div>
    );
  };

  const checkValidity = () => {
    return (
      (paymentStatus != undefined &&
        (!paymentStatus ||
          activeAccount.billingAmount.outstandingBalanceCents > 0 ||
          activeAccount.billingAmount.penaltyCents > 0)) ||
      accountLocked
    );
  };

  return (
    <div className="inventory-item">
      <BackButton />

      <AnimalBanner animalId={animalId}>
        <div className="quantity">
          <h3>{LABEL_CONSTANTS.QUANTITY_OWNED}:</h3>
          <span> {quantityOwned ?? '--'} Units</span>
        </div>

        {doesAccountHasAnimalOwnership && (
          <div className="quantity">
            <h3>{LABEL_CONSTANTS.CATALOG_QUANTITY}:</h3>
            <span> {catalogQuantity ?? '--'} Units</span>
          </div>
        )}

        {permissionService.userHasInventoryPermission() ? (
          <div className="action-buttons">
            <button className="button medium blue" type="button" onClick={onSell} disabled={checkValidity()}>
              {BUTTON_CONSTANTS.SELL_TRANSFER}
            </button>
            <button className="button large blue inverted" type="button" onClick={onWithdraw} disabled={checkValidity()}>
              {BUTTON_CONSTANTS.WITHDRAW}
            </button>
            {/* Uncomment the below part when we want users to create use transactions. */}
            {/* <button className="button small blue inverted" type="button" onClick={onUse} disabled={checkValidity()}>
              {BUTTON_CONSTANTS.USE}
            </button> */}
            <button className="button small red inverted" type="button" onClick={onDiscard} disabled={checkValidity()}>
              {BUTTON_CONSTANTS.DISCARD}
            </button>
          </div>
        ) : (
          <div>
            <br />
            <br />
          </div>
        )}
      </AnimalBanner>

      <div className="inventory-items">
        {getInventoryItems(
          [
            TABLE_HEADER_CONSTANTS.INVENTORY_TYPE,
            TABLE_HEADER_CONSTANTS.UNIT_OF_MEASURE,
            TABLE_HEADER_CONSTANTS.LOT_DATE_NO,
            TABLE_HEADER_CONSTANTS.QUALITY,
            TABLE_HEADER_CONSTANTS.AVAILABLE_QTY,
            TABLE_HEADER_CONSTANTS.OWNED_QTY,
            TABLE_HEADER_CONSTANTS.ACTION_ITEM,
          ],
          'desktop-view',
          'Inventory Items',
        )}
        {getInventoryItems(
          [
            TABLE_HEADER_CONSTANTS.LOT_NO,
            TABLE_HEADER_CONSTANTS.AVAILABLE_QTY,
            TABLE_HEADER_CONSTANTS.OWNED_QTY,
            TABLE_HEADER_CONSTANTS.ACTION_ITEM,
          ],
          'mobile-view',
          'Collection History',
        )}
      </div>

      {specimen && (
        <QuickView
          specimen={specimen}
          isOpen={isQuickViewOpen}
          onClose={() => setIsQuickViewOpen(false)}
          setIsOpen={setIsQuickViewOpen}
        />
      )}
    </div>
  );
};

export default InventoryItem;
