import { useCallback, useEffect, useState } from 'react';
import { showToast } from '../../services/toast.service';
import { getStorageCanisterByTank, getStorageSites, getStorageTanksBySite } from '../../api/storageItemsApi';
import FilterDropdown, { FilterOption } from '../custom-input/FilterDropdown';
import { selectOption } from '../../types/interfaces/selectOption.interface';
import { StorageCanister, StorageSite, StorageTank } from '../../types/interfaces/storage.interfaces';
import { StorageTankOptions } from '../../types/enums/storageTank.enum';
import { toastMessages } from '../../constants/errorMessages';

interface SelectStorageItemsProps {
  selectedSite: selectOption;
  selectedTank: selectOption;
  selectedCanister: selectOption;
  setSelectedSite: (e: FilterOption<any>) => void;
  setSelectedTank: (e: FilterOption<any>) => void;
  setSelectedCanister: (e: FilterOption<any>) => void;
  storageTankOptions?: StorageTankOptions;
  activeLocationsOnly?: boolean;
}

const SelectStorageItems = ({
  selectedSite,
  selectedTank,
  selectedCanister,
  setSelectedSite,
  setSelectedTank,
  setSelectedCanister,
  storageTankOptions = StorageTankOptions.Stationary,
  activeLocationsOnly = false,
}: SelectStorageItemsProps) => {
  const initialState = { value: '', name: '' };
  const [storageSites, setStorageSites] = useState<selectOption[]>([initialState]);
  const [storageTanks, setStorageTanks] = useState<selectOption[]>([initialState]);
  const [storageCanisters, setStorageCanisters] = useState<selectOption[]>([initialState]);
  const [isLoading, setIsLoading] = useState({
    sites: true,
    tanks: false,
    canisters: false,
  });

  const getSites = useCallback(async () => {
    try {
      const response = await getStorageSites({ filter: activeLocationsOnly ? 'Active eq true' : undefined, sort: 'Name' });
      const storageSites = (response.data as StorageSite[]).map((s: StorageSite) => {
        return { value: String(s.storageSiteId), name: s.name + (s.active ? '' : ' - (Inactive)') };
      });
      setStorageSites(storageSites);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setIsLoading(prevIsLoading => ({ ...prevIsLoading, sites: false }));
    }
  }, []);

  useEffect(() => {
    getSites();
  }, [getSites]);

  const getTanks = async (siteId: string) => {
    setIsLoading(prevIsLoading => ({ ...prevIsLoading, tanks: true }));

    try {
      const response = await getStorageTanksBySite(siteId, storageTankOptions, {
        filter: activeLocationsOnly ? 'Active eq true' : undefined,
        sort: 'Name',
      });
      const tanks = response.data.map((s: StorageTank) => {
        return { value: String(s.storageTankId), name: s.name + (s.active ? '' : ' - (Inactive)') };
      });
      setStorageTanks(tanks);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setIsLoading(prevIsLoading => ({ ...prevIsLoading, tanks: false }));
    }
  };

  const getCanisters = async (tankId: string) => {
    setIsLoading(prevIsLoading => ({ ...prevIsLoading, canisters: true }));

    try {
      const response = await getStorageCanisterByTank(tankId, {
        include: 'SpecimenLocations',
        filter: activeLocationsOnly ? 'Active eq true' : undefined,
        sort: 'Name',
      });
      const canisters = (response.data as StorageCanister[]).map((s: StorageCanister) => {
        return {
          value: String(s.storageCanisterId),
          name:
            s.name + (s.active ? '' : ' - (Inactive)') + (s.canisterInventoryCount ? '	(' + s.canisterInventoryCount + ')' : ''),
        };
      });
      setStorageCanisters(canisters);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setIsLoading(prevIsLoading => ({ ...prevIsLoading, canisters: false }));
    }
  };

  const isLoadingPlaceHolder = (isLoadingOptions: boolean, filterDropdownName: string) => {
    return isLoadingOptions ? `Loading ${filterDropdownName}(s)` : `Select ${filterDropdownName}`;
  };

  useEffect(() => {
    if (selectedSite.value.length > 0) {
      getTanks(selectedSite.value);
    }
  }, [selectedSite]);

  useEffect(() => {
    if (selectedTank.value.length > 0) {
      getCanisters(selectedTank.value);
    }
  }, [selectedTank]);

  return (
    <div className="select-storage-items">
      <div className="form-row">
        <label>Site: </label>
        <div className="input-container">
          <FilterDropdown
            placeholder={isLoadingPlaceHolder(isLoading.sites, 'Site')}
            options={storageSites}
            validate={false}
            value={selectedSite}
            onChange={e => {
              setSelectedSite(e);
              setSelectedTank(initialState);
              setSelectedCanister(initialState);
            }}
            disabled={isLoading.sites && storageSites[0] === initialState}
          />
        </div>
      </div>
      <div className="form-row">
        <label>Tank: </label>
        <div className="input-container">
          <FilterDropdown
            placeholder={isLoadingPlaceHolder(isLoading.tanks, 'Tank')}
            options={storageTanks}
            validate={false}
            value={selectedTank}
            onChange={e => {
              setSelectedTank(e);
              setSelectedCanister(initialState);
            }}
            disabled={!selectedSite.value || isLoading.tanks}
          />
        </div>
      </div>
      <div className="form-row">
        <label>Canister: </label>
        <div className="input-container">
          <FilterDropdown
            placeholder={isLoadingPlaceHolder(isLoading.canisters, 'Canister')}
            options={storageCanisters}
            validate={false}
            value={selectedCanister}
            onChange={e => {
              setSelectedCanister(e);
            }}
            disabled={!selectedTank.value || !selectedSite.value || isLoading.canisters}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectStorageItems;
