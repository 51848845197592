import {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {transientAuth} from '../api/authApi';

interface urlParamsForVerificationProps {
  onSuccess: (e: any) => void;
  onError: (e: Error) => void;
}

const useURLParamsForVerification = ({
  onSuccess,
  onError,
}: urlParamsForVerificationProps) => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let isCancelled = false;

    const getAuth = () => {
      transientAuth({
        email: searchParams.get('state') ?? '',
        password: searchParams.get('code') ?? '',
      })
        .then((e: any) => {
          onSuccess(e);
        })
        .catch((e: Error) => {
          onError(e);
        });
    };
    if (!isCancelled) {
      getAuth();
    }

    return () => {
      isCancelled = true;
    };
  }, []);
};

export default useURLParamsForVerification;
