export enum Validators {
  REQUIRED,
  PASSWORD_LENGTH,
  PHONE_LENGTH,
  NEEDS_UPPER,
  NEEDS_LOWER,
  NEEDS_NUMBER,
  NEEDS_SPECIAL,
  NEEDS_UPPER_LOWER,
  EMAIL,
  MUST_MATCH,
  MIN_VALUE,
  MAX_VALUE,
  CARD_NUMBER,
  EXPIRY_DATE_LENGTH,
  ACCOUNT_NUMBER,
  ROUTING_NUMBER,
  CVV_NUMBER,
  ZIP_CODE,
}

export const PASSWORD_VALIDATORS: Validators[] = [
  Validators.PASSWORD_LENGTH,
  Validators.NEEDS_UPPER,
  Validators.NEEDS_LOWER,
  Validators.NEEDS_NUMBER,
  Validators.NEEDS_SPECIAL,
];
