import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { storeAppAuth } from '../../redux/reducers/authSlice';
import ValidatedInput, { ValidatedState } from '../../components/custom-input/ValidatedInput';
import { transientAuth } from '../../api/authApi';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { PromiseError, toastMessages } from '../../constants/errorMessages';
import { BUTTON_CONSTANTS, PASSWORDS_CONSTANTS, RETURN_TO_SIGN_IN } from '../../constants/common';
import { Validators } from '../../types/enums/validators.enum';
import { showToast } from '../../services/toast.service';

const ForgotPasswordCode = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState<string>(searchParams.get('email') ?? '');
  const [code, setCode] = useState<ValidatedState>({ value: '', valid: false });
  const [validate, setValidate] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    setValidate(true);

    if (code.valid) {
      transientAuth({ email, password: code.value })
        .then((e: any) => {
          dispatch(storeAppAuth(e.data));
          navigate(ROUTE_PATHS.RESET_PASSWORD);
        })
        .catch((error: any) => {
          if (error.code === PromiseError.BAD_REQUEST) {
            showToast.error(toastMessages.INCORRECT_CODE);
          } else if (error.code === PromiseError.NETWORK) {
            showToast.error(toastMessages.NETWORK);
          } else {
            showToast.error(toastMessages.SOMETHING_WENT_WRONG);
          }
        });
    }
  };

  return (
    <div className="auth">
      <div className="content-container">
        <div className="content">
          <div className="form">
            <div>
              <h1>{PASSWORDS_CONSTANTS.CONFIRM_CODE}</h1>
              <p className="password-subheader">{PASSWORDS_CONSTANTS.CONFIRM_CODE_SUBHEADER}</p>
            </div>
            <br />
            <form onSubmit={e => handleSubmit(e)}>
              <div className="input">
                <ValidatedInput label="Code" setValidatedState={setCode} validators={[Validators.REQUIRED]} validate={validate} />
              </div>
              <button disabled={!code.valid} type="submit" className="green button">
                {BUTTON_CONSTANTS.CONTINUE}
              </button>
            </form>
            <p className="return-link">
              {PASSWORDS_CONSTANTS.DIDNT_GET_CODE} <Link to={ROUTE_PATHS.SIGN_IN}>{RETURN_TO_SIGN_IN}</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordCode;
