import { FC, useEffect, useState } from 'react';
import DefaultProfileImage from '../profile-image/default-profile-image/DefaultProfileImage';
import { toastMessages } from '../../constants/errorMessages';
import { getAccountAnimalsOdata, getAnimals } from '../../api/animalsApi';
import { Animal } from '../../types/interfaces/animal.interfaces';
import { StandardParamsToODataParams } from '../../types/interfaces';
import { getAnimalAttachmentURL } from '../../services/animal.service';
import { showToast } from '../../services/toast.service';
import { handleSearchDropdownClasses, onBlurResetHandler } from '../../utils/commonUtils';

type AnimalSearchProps = {
  onChange: (animal: Animal | undefined) => void;
  showIcon?: boolean;
  disabled?: boolean;
  placeholder?: string;
  includeOwners?: boolean;
  includeCustomData?: boolean;
  accountId?: number;
  animalAutofill?: Animal;
  clearInputField?: boolean;
};

const AnimalSearch: FC<AnimalSearchProps> = ({
  onChange,
  showIcon = false,
  disabled = false,
  placeholder = 'Search Animal',
  includeOwners = false,
  includeCustomData = false,
  accountId,
  animalAutofill,
  clearInputField,
}): JSX.Element => {
  const [searchResults, setSearchResults] = useState<Animal[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [loadingResults, setLoadingResults] = useState<boolean>(false);
  const [isDisplayingValue, setIsDisplayingValue] = useState<boolean>(false);
  const [displayedValue, setDisplayedValue] = useState<string>('');

  const minSearchLength: number = 2;

  function handleInclude(includeOwners: boolean, includeCustomData: boolean) {
    let output = '';

    if (includeOwners) output += 'Owners.Account,';

    if (includeCustomData) output += 'Specimens.SpecimenCustomDataValues.SpecimenCustomDataKey,';

    output += 'Attachments';

    return output;
  }

  const handleInput = async (search: string) => {
    setIsDisplayingValue(false);
    setLoadingResults(true);
    setSearchInput(search);

    if (search.length >= minSearchLength) {
      if (accountId !== undefined) {
        getAccountAnimalsOdata(
          accountId as number,
          StandardParamsToODataParams({
            filter: `contains(Name, '${search}') OR contains(Code, '${search}')`,
            include: 'Attachments',
          }),
        )
          .then((e: any) => {
            setSearchResults(e.data);
            setLoadingResults(false);
          })
          .catch(e => {
            setLoadingResults(false);
            showToast.error(toastMessages.SOMETHING_WENT_WRONG);
          });
      } else {
        getAnimals({
          filter: 'Name like ' + search + '||Code like ' + search,
          include: handleInclude(includeOwners, includeCustomData),
        })
          .then((e: any) => {
            setSearchResults(e.data);
            setLoadingResults(false);
          })
          .catch(e => {
            setLoadingResults(false);
            showToast.error(toastMessages.SOMETHING_WENT_WRONG);
          });
      }
    } else {
      setSearchResults([]);
      setLoadingResults(false);
    }
  };
  useEffect(() => {
    if (clearInputField) {
      setSearchInput('');
      setIsDisplayingValue(false);
    }
  }, [clearInputField]);

  useEffect(() => {
    if (animalAutofill !== undefined) {
      handleSearchClick(animalAutofill);
    }
  }, [animalAutofill]);

  const handleSearchClick = (animal: Animal) => {
    setSearchInput('');
    setSearchResults([]);

    setIsDisplayingValue(true);
    setDisplayedValue(animal.code + ' - ' + animal.name);

    onChange(animal);
  };

  function reset(target?: HTMLInputElement) {
    setSearchInput('');
    setIsDisplayingValue(false);
    onChange(undefined);

    if (target) target.className = '';
  }

  useEffect(() => {
    reset();
  }, [accountId]);

  return (
    <div className={'animal-search search-dropdown'} onBlur={e => onBlurResetHandler(e, reset)}>
      <div className="search-input-wrapper">
        <input
          className={isDisplayingValue ? 'selected' : ''}
          disabled={disabled}
          placeholder={placeholder}
          value={isDisplayingValue ? displayedValue : searchInput}
          type="text"
          onFocus={e => e.target.select()}
          onChange={e => handleInput(e.target.value)}
        />
        <span className={handleSearchDropdownClasses(showIcon, searchInput, isDisplayingValue)} onClick={() => reset()}></span>
      </div>
      {searchInput.length >= minSearchLength && (
        <div className="results">
          {searchResults.length > 0 ? (
            searchResults.map((animal: Animal) => {
              return (
                <div className="result-item" key={animal.animalId} onMouseDown={() => handleSearchClick(animal)}>
                  {animal && animal.animalId && animal.attachments && animal.attachments[0] ? (
                    <img
                      className="result-item-image"
                      src={getAnimalAttachmentURL(
                        animal?.animalId,
                        animal.attachments[0].attachmentId,
                        animal.attachments[0].accessToken,
                      )}
                    />
                  ) : (
                    <DefaultProfileImage className="result-item-image" />
                  )}
                  {animal.code} - {animal.name}
                </div>
              );
            })
          ) : (
            <div className="result-item">{loadingResults ? 'Loading...' : 'No results found.'}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default AnimalSearch;
