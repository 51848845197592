import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Loader } from '../../components';
import PagedTable from '../../components/sorted-table/PagedTable';
import AnimalManagementHeader from './AnimalManagementHeader';
import { getAnimals } from '../../api/animalsApi';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { toastMessages } from '../../constants/errorMessages';
import { showToast } from '../../services/toast.service';
import { Animal } from '../../types/interfaces';

const ManageAnimals = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [animalsData, setAnimalsData] = useState<Animal[]>([]);

  const handleGetAnimals = useCallback(async () => {
    const query = { include: 'Breed.Species', sort: 'AnimalId' };
    try {
      const response = await getAnimals(query);
      const animals = response.data as Animal[];
      setAnimalsData(animals);
      setLoading(false);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    handleGetAnimals();
  }, [handleGetAnimals]);

  return (
    <div className="animal-management">
      <AnimalManagementHeader />
      <div className="desk-animal-management">
        <div className="card">
          <PagedTable
            headers={[
              { displayName: 'Animal Id', sortValue: 'AnimalId' },
              { displayName: 'Animal Code', sortValue: 'Code' },
              { displayName: 'Name', sortValue: 'Name' },
              { displayName: 'Gender', sortValue: 'Gender' },
              { displayName: 'Species-Breed' },
              { displayName: '' },
            ]}
            include="Breed.Species"
            getData={getAnimals}
            sortBy="AnimalId"
            buildRow={animal => {
              return [
                animal.animalId,
                animal.code,
                animal.name,
                animal.gender,
                `${animal.breed.species.name}-${animal.breed.name}`,
                <Link to={ROUTE_PATHS.APP_ANIMAL_MANAGEMENT_ + animal.animalId}>View</Link>,
              ];
            }}
            pageSize={10}
            height={500}
          />
        </div>
      </div>

      {/** Mobile View */}
      <div className="xs-animal-management">
        {loading ? (
          <Loader addedSpace loaderSize="small" />
        ) : !loading && animalsData.length === 0 ? (
          <div className="result-item">{'No results found.'}</div>
        ) : (
          animalsData?.length > 0 &&
          animalsData &&
          animalsData.map((animal, index) => {
            return (
              <div className="card max-width" key={'animal-' + animal?.code + index}>
                <div className="animal-management-card">
                  <div className="animal-management-content">
                    <div className="card-content-section">
                      <div className="animal-content">
                        <label>{`Animal Id : `}</label>
                        <label>{`${animal.animalId ?? '-'}`}</label>
                      </div>
                      <div className="animal-content">
                        <label>{`Animal Code : `}</label>
                        <label>{`${animal.code ?? '-'}`}</label>
                      </div>
                      <div className="animal-content">
                        <label>{`Name : `}</label>
                        <label>{`${animal.name ?? '-'}`}</label>
                      </div>
                    </div>
                    <div className="card-content-section">
                      <div className="animal-content">
                        <label>{`Gender : `}</label>
                        <label>{`${animal.gender ?? '-'}`}</label>
                      </div>
                      <div className="animal-content">
                        <label>{`Species-Breed : `}</label>
                        <label>{`${animal?.breed?.species ? animal?.breed?.species.name + '-' + animal.breed.name : '-'}`}</label>
                      </div>
                    </div>
                  </div>
                  <Link className="view-animal-link" to={ROUTE_PATHS.APP_ANIMAL_MANAGEMENT_ + animal.animalId}>
                    View
                  </Link>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default ManageAnimals;
