export const CheckMark = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.16771 6.46706L9.57604 1.05872C9.7441 0.890668 9.95799 0.806641 10.2177 0.806641C10.4774 0.806641 10.6913 0.890668 10.8594 1.05872C11.0274 1.22678 11.1115 1.44067 11.1115 1.70039C11.1115 1.96011 11.0274 2.174 10.8594 2.34206L4.80937 8.39206C4.62604 8.57539 4.41215 8.66706 4.16771 8.66706C3.92326 8.66706 3.70938 8.57539 3.52604 8.39206L1.14271 6.00872C0.974652 5.84067 0.890625 5.62678 0.890625 5.36706C0.890625 5.10733 0.974652 4.89345 1.14271 4.72539C1.31076 4.55733 1.52465 4.47331 1.78437 4.47331C2.0441 4.47331 2.25799 4.55733 2.42604 4.72539L4.16771 6.46706Z"
        fill="#545556"
      />
    </svg>
  );
};
export default CheckMark;
