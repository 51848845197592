import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SettingsSVG } from '../../svgs';
import { ACCOUNT_PATHS } from '../../../constants/routePaths';
import { PermissionService } from '../../../services/permission.service';
import { redirectToTopOfThePage } from '../../../utils/commonUtils';

type AccountSettingsProps = {
  currentRoute: string[];
  permissionService: PermissionService;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenNavBar: React.Dispatch<React.SetStateAction<boolean>>;
};

const AccountSettingsNav: FC<AccountSettingsProps> = ({ currentRoute, permissionService, setOpen, setOpenNavBar }) => {
  const activeLinkClass = (path: string) => {
    return currentRoute.includes(path) ? 'nav-link active' : 'nav-link';
  };

  return (
    <div>
      {permissionService.userHasSettingsPermission() && (
        <Link
          className={activeLinkClass(ACCOUNT_PATHS.ACCOUNT_SETTINGS)}
          to={ACCOUNT_PATHS.ACCOUNT_SETTINGS}
          onClick={() => {
            setOpen(false);
            setOpenNavBar(false);
            redirectToTopOfThePage();
          }}>
          <SettingsSVG />
          Settings
        </Link>
      )}
    </div>
  );
};

export default AccountSettingsNav;
