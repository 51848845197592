const TransactionLabelField: React.FC<{ label: string | JSX.Element; value: any }> = ({ label, value }) => {
  return (
    <div className="form-row">
      <label>{label}</label>
      <div className="input-container">
        <div className="form-label">{value ? value : '-'}</div>
      </div>
    </div>
  );
};

export default TransactionLabelField;
