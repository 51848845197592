import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { storeAppAuth } from '../../redux/reducers/authSlice';
import useURLParamsForVerification from '../../hooks/useURLParams';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { REDIRECTING } from '../../constants/common';
import { showToast } from '../../services/toast.service';

const VerificationToken = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useURLParamsForVerification({
    onSuccess: e => {
      dispatch(storeAppAuth(e.data));
      navigate(ROUTE_PATHS.APP_DASHBOARD);
      showToast.success(toastMessages.VERIFIED_ACCOUNT_EMAIL);
    },
    onError: e => {
      navigate(ROUTE_PATHS.SIGN_IN);
      showToast.error(toastMessages.EXPIRED_INCORRECT_LINK);
    },
  });

  return <h1>{REDIRECTING}</h1>;
};

export default VerificationToken;
