import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams, Link } from 'react-router-dom';
import ValidatedInput, { ValidatedState } from '../../components/custom-input/ValidatedInput';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { PromiseError, toastMessages } from '../../constants/errorMessages';
import { BUTTON_CONSTANTS, PASSWORDS_CONSTANTS, RETURN_TO_SIGN_IN } from '../../constants/common';
import { passwordReset } from '../../api/userApi';
import { Validators } from '../../types/enums/validators.enum';
import { showToast } from '../../services/toast.service';

const ForgotPassword = (): JSX.Element => {
  const [email, setEmail] = useState<ValidatedState>({ value: '', valid: false });
  const [validate, setValidate] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();

    setValidate(true);

    if (email.valid) {
      const emailValue: string = email.value;
      passwordReset({ username: emailValue })
        .then((e: any) => {
          navigate({
            pathname: ROUTE_PATHS.FORGOT_PASSWORD_CODE,
            search: `${createSearchParams({ email: emailValue })}`,
          });
        })
        .catch((error: any) => {
          if (error.code === PromiseError.NETWORK) {
            showToast.error(toastMessages.NETWORK);
          } else {
            showToast.error(toastMessages.SOMETHING_WENT_WRONG);
          }
          navigate({
            pathname: ROUTE_PATHS.FORGOT_PASSWORD_CODE,
            search: `${createSearchParams({ email: emailValue })}`,
          });
        });
    }
  };

  return (
    <div className="auth">
      <div className="content-container">
        <div className="content">
          <div className="form">
            <div>
              <h1>{PASSWORDS_CONSTANTS.FORGOT_PASSWORD}</h1>
              <p className="password-subheader">{PASSWORDS_CONSTANTS.FORGOT_PASSWORD_SUBHEADER}</p>
            </div>
            <br />
            <form onSubmit={e => handleSubmit(e)}>
              <div className="input">
                <ValidatedInput
                  useId="email"
                  label="Email"
                  type="email"
                  setValidatedState={setEmail}
                  validators={[Validators.EMAIL, Validators.REQUIRED]}
                  validate={validate}
                />
              </div>

              <button disabled={!email.valid} type="submit" className="button green">
                {BUTTON_CONSTANTS.SEND}
              </button>
            </form>

            <p className="return-link">
              <Link to={ROUTE_PATHS.SIGN_IN}>{RETURN_TO_SIGN_IN}</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
