import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectStorageItems from '../../components/select-storage-items/SelectStorageItems';
import SelectSpecimenCheckIn, { SpecimenCheckInQuantity } from '../../components/specimen-tables/SelectSpecimenCheckIn';
import { BackButton, Modal } from '../../components';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS, PAGE_HEADER_CONSTANTS } from '../../constants/common';
import { showToast } from '../../services/toast.service';
import { checkIn } from '../../api/inventoryApi';
import { getSpecimenLocations } from '../../api/specimensApi';
import { SpecimenLocation } from '../../types/interfaces';
import { StorageTankOptions } from '../../types/enums/storageTank.enum';
import { selectOption } from '../../types/interfaces/selectOption.interface';
import { redirectToTopOfThePage } from '../../utils/commonUtils';
import './inventoryManagement.scss';

const InventoryManagementCheckIn = (): JSX.Element => {
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const transactionId = locationState != null ? locationState.inventoryTransactionId : undefined;
  const init: selectOption = { name: '', value: '' };

  const [travelingSite, setTravelingSite] = useState<selectOption>(init);
  const [travelingTank, setTravelingTank] = useState<selectOption>(init);
  const [travelingCanister, setTravelingCanister] = useState<selectOption>(init);
  const [checkInSite, setCheckInSite] = useState<selectOption>(init);
  const [checkInTank, setCheckInTank] = useState<selectOption>(init);
  const [checkInCanister, setCheckInCanister] = useState<selectOption>(init);
  const [checkInValues, setCheckInValues] = useState<SpecimenCheckInQuantity>();
  const [notes, setNotes] = useState<string>('');
  const [errorModalIsOpen, setErrorModalIsOpen] = useState<boolean>(false);
  const [modalError, setModalError] = useState<string>();

  const isValid = () => {
    if (travelingSite.value && travelingTank.value && travelingCanister.value && checkInValues) {
      const { checkInQty, usedQty, lostQty, qtyInCanister } = checkInValues;

      if (checkInQty && !checkInSite.value && !checkInTank.value && !checkInCanister.value) {
        return false;
      }

      if (checkInQty + usedQty + lostQty === qtyInCanister) {
        return true;
      } else {
        if (checkInQty + usedQty + lostQty > qtyInCanister) {
          showToast.error(toastMessages.QUANTITIES_GREATER_THAN_CANISTER_QTY);
        } else {
          showToast.error(toastMessages.QUANTITIES_LESS_THAN_CANISTER_QTY);
        }
        return false;
      }
    }
  };

  const handleSubmit = async () => {
    if (isValid()) {
      try {
        await checkIn(
          {
            specimenId: checkInValues?.specimenId!,
            quantity: checkInValues?.checkInQty!,
            quantityUsed: checkInValues?.usedQty!,
            quantityLost: checkInValues?.lostQty!,
            transactionLinkId: checkInValues?.transactionLinkId!,
            selectedCanisterId: Number(travelingCanister.value),
            destinationCanisterId: Number(checkInCanister.value),
            notes: notes,
          },
          {
            accountId: checkInValues?.accountId!,
          },
        );
        showToast.success(toastMessages.CHECK_IN_SUCCESS);
        clearPageValues();
      } catch (error: any) {
        if (error?.response?.status === 409) {
          try {
            const detailObject = JSON.parse(error.response.data.detail.substring(error.response.data.detail.indexOf('{')));
            const errMsg = detailObject.ErrMsg;
            setModalError(errMsg);
          } catch {
            setModalError(error.response.data.detail);
          }
          clearPageValues();
          setErrorModalIsOpen(true);
        } else {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      }
    }
  };

  const clearPageValues = () => {
    setTravelingSite({ value: '', name: '' });
    setTravelingTank({ value: '', name: '' });
    setTravelingCanister({ value: '', name: '' });
    clearCheckInLocation();
    setCheckInValues(undefined);
  };

  const clearCheckInLocation = () => {
    setCheckInSite({ value: '', name: '' });
    setCheckInTank({ value: '', name: '' });
    setCheckInCanister({ value: '', name: '' });
    setNotes('');
  };

  const handleGetLocations = useCallback(async () => {
    if (locationState?.specimenId) {
      const query = {
        include: 'StorageCanister.StorageTank.StorageSite, transactionLink',
        filter: `specimenId eq ${locationState.specimenId}`,
        useTransactionId: transactionId,
      };

      try {
        const specimenLocations = (await getSpecimenLocations(query)).data;

        const traveling = specimenLocations.find(
          (location: SpecimenLocation) =>
            location.storageCanister?.storageTank?.isTravelingTank &&
            location.transactionLink?.transactionLinkId === transactionId,
        );

        if (traveling?.storageCanister?.storageTank?.storageSite) {
          setTravelingSite({
            name: traveling?.storageCanister?.storageTank?.storageSite.name,
            value: traveling?.storageCanister?.storageTank?.storageSite.storageSiteId.toString(),
          });
        }

        if (traveling?.storageCanister?.storageTank) {
          setTravelingTank({
            name: traveling?.storageCanister?.storageTank?.name,
            value: traveling?.storageCanister?.storageTank?.storageTankId.toString(),
          });
        }

        if (traveling?.storageCanister) {
          setTravelingCanister({
            name: traveling?.storageCanister?.name,
            value: traveling?.storageCanister.storageCanisterId.toString(),
          });
        }
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  }, [locationState?.specimenId]);

  useEffect(() => {
    handleGetLocations();
  }, [handleGetLocations]);

  return (
    <>
      <Modal isOpen={errorModalIsOpen} ignoreBackdrop onClose={() => {}}>
        <div className="sign-out-modal">
          <div className="header">
            <h4>{LABEL_CONSTANTS.CHECK_IN_WARNING}</h4>
          </div>
          <div className="body">
            <p className="modal-p-side-margin">{modalError}</p>
          </div>
          <div className="footer">
            <button className="button green small" onClick={() => setErrorModalIsOpen(false)}>
              {BUTTON_CONSTANTS.OK}
            </button>
          </div>
        </div>
      </Modal>
      <div className="inventory-management inventory-management-check-in">
        {locationState && locationState.transactionManagementFilterPreset && (
          <BackButton
            onClick={() =>
              navigate(ROUTE_PATHS.APP_TRANSACTION_MANAGEMENT, {
                state: { filterPreset: locationState.transactionManagementFilterPreset, filter: locationState.filter },
              })
            }
          />
        )}
        <div className="inventory-action card">
          <h1>{PAGE_HEADER_CONSTANTS.CHECK_IN_INVENTORY}</h1>
          <h2>{LABEL_CONSTANTS.TRAVELING_TANK}:</h2>
          <SelectStorageItems
            selectedSite={travelingSite}
            selectedTank={travelingTank}
            selectedCanister={travelingCanister}
            setSelectedSite={setTravelingSite}
            setSelectedTank={setTravelingTank}
            setSelectedCanister={setTravelingCanister}
            storageTankOptions={StorageTankOptions.Travel}
            activeLocationsOnly
          />
          <br />
          <h2>{LABEL_CONSTANTS.INVENTORY}:</h2>
          {travelingCanister.value && (
            <SelectSpecimenCheckIn
              canisterId={travelingCanister.value}
              specimenIdAutofill={locationState?.specimenId}
              transactionId={transactionId}
              clearCheckInLocation={clearCheckInLocation}
              onChange={e => {
                setCheckInValues(e ? e : undefined);
              }}
            />
          )}
          {checkInValues?.selected && checkInValues?.checkInQty > 0 && (
            <>
              <br />
              <h2>{LABEL_CONSTANTS.CHECK_INTO}:</h2>
              <SelectStorageItems
                selectedSite={checkInSite}
                selectedTank={checkInTank}
                selectedCanister={checkInCanister}
                setSelectedSite={setCheckInSite}
                setSelectedTank={setCheckInTank}
                setSelectedCanister={setCheckInCanister}
                activeLocationsOnly
              />
            </>
          )}
          <br />
          <div className="form-row expanded-text-area">
            <label htmlFor="notes">{LABEL_CONSTANTS.NOTES}:</label>
            <textarea
              id="notes"
              name="notes"
              placeholder="Notes"
              value={notes}
              onChange={e => setNotes(e.target.value)}
              rows={4}
              cols={50}
            />
          </div>
          <br />
          <div className="submit-button">
            <button
              className="button green small"
              disabled={(!checkInCanister.value && checkInValues?.checkInQty! > 0) || !checkInValues?.selected}
              onClick={() => {
                handleSubmit();
                redirectToTopOfThePage();
              }}>
              {BUTTON_CONSTANTS.SUBMIT}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryManagementCheckIn;
