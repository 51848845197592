import React, { FC } from 'react';
import { InfoSVG } from '../svgs';
import './notificationBanner.scss';

type NotificationBannerProps = {
  children: React.ReactNode;
  infoIconSize?: string;
};

const NotificationBanner: FC<NotificationBannerProps> = ({ children, infoIconSize = '19' }) => {
  return (
    <div className="notification-banner">
      <div className="card">
        <div className="notification-banner-title">
          <div className="info-icon">
            <InfoSVG size={infoIconSize} color="#b0131d" />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default NotificationBanner;
