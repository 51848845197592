import { AxiosResponse } from 'axios';
import { BREEDS_API_ENDPOINTS } from '../constants/apiEndpoints';
import { appContextGet, appContextPost, appContextPut } from './httpCommon/appAxios.service';
import { Breed, PagedResponse, StandardParams } from '../types/interfaces';

export const createBreed = (payload: Breed): Promise<AxiosResponse<number, any>> => {
  return appContextPost(BREEDS_API_ENDPOINTS.CREATE, {
    name: payload.name,
    active: payload.active,
    speciesId: payload.speciesId,
  });
};

export const updateBreed = (breedId: number, payload: Breed): Promise<AxiosResponse<{}, any>> => {
  return appContextPut(BREEDS_API_ENDPOINTS.PUT + breedId.toString(), payload);
};

export const getBreeds = (params?: StandardParams): Promise<AxiosResponse<Breed[] | PagedResponse<Breed>, any>> => {
  return appContextGet(BREEDS_API_ENDPOINTS.GET, params);
};

export const getBreedsOfSpecies = (speciesId: number): Promise<AxiosResponse<Breed[], any>> => {
  return appContextGet(BREEDS_API_ENDPOINTS.GET, {
    filter: 'SpeciesId eq ' + speciesId,
  });
};
