import {AxiosResponse} from 'axios';
import {STORAGE_PLANS_API_ENDPOINTS} from '../constants/apiEndpoints';
import {StoragePlan} from '../types/interfaces/account.interfaces';
import {commonAxios} from './httpCommon/axiosInstances';
import {StandardParams} from '../types/interfaces/apiParams.interfaces';

const {STORAGE_PLANS} = STORAGE_PLANS_API_ENDPOINTS;

export const getStoragePlans = (
  params?: StandardParams,
): Promise<AxiosResponse<[], StoragePlan>> => {
  return commonAxios.get(STORAGE_PLANS, params as any);
};
