import { FC, useEffect, useState } from 'react';
import Loader from '../loader/Loader';
import { Specimen } from '../../types/interfaces/specimen.interfaces';
import { InventoryTransaction } from '../../types/interfaces';
import { TransactionStatusEnum } from '../../types/enums';
import { showToast } from '../../services/toast.service';
import { formatPositiveNumber } from '../../utils/commonUtils';
import { getOriginationTransactions } from '../../api/transactionsApi';
import { toastMessages } from '../../constants/errorMessages';
import { LABEL_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../constants/common';

export type OriginationQuantity = {
  actualQty: number;
  oldQty: number;
  ownershipPercentage?: number;
  transaction: InventoryTransaction;
};

type ReconcileOriginationProps = {
  specimen: Specimen;
  onChange: (e: { value: OriginationQuantity[]; valid: boolean }) => void;
};

const ReconcileOrigination: FC<ReconcileOriginationProps> = ({ specimen, onChange }: ReconcileOriginationProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [specimens, setSpecimens] = useState<OriginationQuantity[]>([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data: originTransactions } = await getOriginationTransactions(specimen.specimenId);
        const specimenOwnership = originTransactions.map((trans: InventoryTransaction) => {
          const quant = trans?.changeQuantity!;
          const perc = specimen.animal?.owners?.find(i => i.accountId === trans.accountId)?.ownershipPercentage;
          return { transaction: trans, ownershipPercentage: perc, oldQty: quant, actualQty: quant };
        });
        setSpecimens(specimenOwnership);
      } catch {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setLoading(false);
      }
    };

    if (specimen) {
      fetch();
    }
  }, [specimen]);

  useEffect(() => {
    const filteredSpecimens = specimens.filter(specimen => {
      return specimen?.transaction?.status === TransactionStatusEnum.Complete;
    });

    onChange({ value: filteredSpecimens, valid: !loading });
  }, [specimens]);

  const getTotal = (): number => {
    return specimens
      .filter(spec => spec?.transaction?.status === TransactionStatusEnum.Complete)
      .reduce((acc: number, spec: OriginationQuantity): number => acc + spec?.actualQty, 0);
  };

  const onAdjustedQtyChange = (e: any, index: number) => {
    const count = formatPositiveNumber(e.target.value);
    let newSpecimens = [...specimens];
    newSpecimens[index].actualQty = count;
    setSpecimens(newSpecimens);
    onChange({ value: newSpecimens, valid: true });
  };

  return (
    <>
      <div className="desk-reconcile-origination">
        <div className="specimen-table">
          <table>
            <thead>
              <tr>
                <th>{TABLE_HEADER_CONSTANTS.TYPE}</th>
                <th>{TABLE_HEADER_CONSTANTS.ACCOUNT_OWNER}</th>
                <th>{TABLE_HEADER_CONSTANTS.ANIMAL_OWNERSHIP_PER}</th>
                <th>{TABLE_HEADER_CONSTANTS.ORIGINAL_QTY}</th>
                <th>{TABLE_HEADER_CONSTANTS.ADJUSTED_QTY}</th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                specimens?.map((trans: OriginationQuantity, index: number) => {
                  const isTransactionComplete = trans?.transaction?.status === TransactionStatusEnum.Complete;
                  return (
                    <tr
                      key={`canister-specimen-row: ${trans?.transaction?.inventoryTransactionId}-index:${index}`}
                      className={`${isTransactionComplete ? '' : 'disabled'}`}>
                      <td>{trans?.transaction?.transactionType}</td>
                      <td>{trans?.transaction?.account?.name}</td>
                      <td>{trans?.ownershipPercentage}</td>
                      <td>{trans?.oldQty}</td>

                      <td>
                        {isTransactionComplete ? (
                          <input
                            type="text"
                            placeholder="0"
                            value={trans?.actualQty}
                            onChange={e => onAdjustedQtyChange(e, index)}
                          />
                        ) : (
                          trans?.transaction?.status
                        )}
                      </td>
                    </tr>
                  );
                })}
              {!loading && specimens?.length === 0 && (
                <tr>
                  <td colSpan={5}>
                    <>{LABEL_CONSTANTS.NO_RESULTS_FOUND}</>
                  </td>
                </tr>
              )}
              {loading && (
                <tr>
                  <td colSpan={5}>
                    <>
                      <br />
                      <br />
                      <Loader loaderSize="small" />
                      <br />
                      <br />
                    </>
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={4} />
                <td>
                  <div className="original-quantity quantity">{getTotal().toString()}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/** Mobile View */}
      <div className="xs-reconcile-origination">
        {loading ? (
          <Loader addedSpace loaderSize="small" />
        ) : !loading && specimens?.length === 0 ? (
          <div className="result-item">{LABEL_CONSTANTS.NO_RESULTS_FOUND}</div>
        ) : (
          <div>
            {specimens.length > 0 &&
              specimens &&
              specimens.map((trans, index) => {
                const isTransactionComplete = trans?.transaction?.status === TransactionStatusEnum.Complete;
                return (
                  <div
                    className={`card max-width ${isTransactionComplete ? '' : 'disabled'}`}
                    key={trans?.transaction?.transactionLinkId! + index}>
                    <div className="reconcile-origination">
                      <div className="card-content-section">
                        <div className="left center">
                          <label>{`${TABLE_HEADER_CONSTANTS.TYPE}: ${trans?.transaction?.transactionType}`}</label>
                        </div>
                        <div className="right column center">
                          <label>{TABLE_HEADER_CONSTANTS.ACCOUNT_OWNER}:</label>
                          <label>{`${trans?.transaction?.account?.name}`}</label>
                        </div>
                      </div>
                      <div className="card-content-section">
                        <div className="left center column">
                          <label>{TABLE_HEADER_CONSTANTS.ANIMAL_OWNERSHIP_PER}:</label>
                          <label>{`${trans?.ownershipPercentage}`}</label>
                        </div>
                        <div className="left center">
                          <label>{TABLE_HEADER_CONSTANTS.ORIGINAL_QTY}:</label>
                          <label>{`${trans?.oldQty}`}</label>
                        </div>
                        <div className="right center column">
                          {isTransactionComplete ? (
                            <>
                              <label>{TABLE_HEADER_CONSTANTS.ADJUSTED_QTY}:</label>
                              <input
                                type="text"
                                placeholder="0"
                                value={trans?.actualQty}
                                onChange={e => onAdjustedQtyChange(e, index)}
                              />
                            </>
                          ) : (
                            <label>{trans?.transaction?.status}</label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className="total-quantity">
              <label>{TABLE_HEADER_CONSTANTS.ORIGINAL_QTY}: &nbsp;</label>
              {getTotal().toString()}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReconcileOrigination;
