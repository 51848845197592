import React, { FC } from 'react';
import './numberInput.scss';

type NumberInputProps = {
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  value: string | number | readonly string[] | undefined;
  min?: number;
  max?: number;
  maxLength?: number;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  name?: string;
  placeholder?: string;
  id?: string;
  step?: string;
};

const NumberInput: FC<NumberInputProps> = ({
  id,
  required = false,
  min = 0,
  disabled = false,
  max,
  onChange,
  value,
  className,
  name,
  maxLength,
  placeholder,
  step,
}) => {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value === '') {
      onChange?.({ ...event, target: { ...event.target, value: required ? '0' : '' } });
    } else {
      onChange?.(event);
    }
  }

  function handleFocus(event: React.FocusEvent<HTMLInputElement>) {
    event.target.select();
  }

  return (
    <div className={disabled ? 'disabled number-input' : 'number-input'}>
      <input
        id={id}
        placeholder={placeholder}
        type="number"
        inputMode="numeric"
        min={min}
        max={max}
        step={step ?? ''}
        value={disabled ? '---' : value}
        onChange={handleChange}
        disabled={disabled}
        onFocus={handleFocus}
        aria-label="Number Input"
        required={required}
        className={className}
        name={name}
        maxLength={maxLength}
        ref={input => {
          if (input !== null) {
            input?.addEventListener(
              'wheel',
              e => {
                if (document.activeElement === input) {
                  input.blur();
                }
              },
              { passive: false },
            );
          }
        }}
      />
    </div>
  );
};

export default NumberInput;
