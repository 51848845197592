import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import FilterDropdown, { FilterOption } from '../custom-input/FilterDropdown';
import ToggleSwitch from '../custom-input/ToggleSwitch';
import DateInput from '../custom-input/DateInput';
import { ClearFilterSVG, PlusCircleSvg } from '../svgs';
import { TRANSACTION_MANAGEMENT_PATHS } from '../../constants/routePaths';
import { LABEL_CONSTANTS } from '../../constants/common';
import { sortObjectsAlphabetically } from '../../utils/commonUtils';
import { TransactionResolutionEnum, TransactionStatusEnum, TransactionTypeEnum } from '../../types/enums/transaction.enum';
import './filterBy.scss';

interface FilterByProps {
  type: string;
  setType: (e: string) => void;
  status: string;
  setStatus: (e: string) => void;
  resolution: string;
  setResolution: (e: string) => void;
  date: Date | undefined;
  setDate: (e: Date | undefined) => void;
  showBulkTransactions?: boolean;
  onToggleShowBulkTransactions?: () => void;
  includeAdminFilters?: boolean;
  resetFilters?: () => void;
}

const FilterBy: FC<FilterByProps> = ({
  type,
  setType,
  status,
  setStatus,
  resolution,
  setResolution,
  date,
  setDate,
  showBulkTransactions,
  onToggleShowBulkTransactions,
  includeAdminFilters = false,
  resetFilters,
}) => {
  const navigate = useNavigate();
  let typeOptions: FilterOption<string>[] = [];
  let statusOptions: FilterOption<string>[] = [];
  let resolutionOptions: FilterOption<string>[] = [];

  for (const [key, value] of Object.entries(TransactionTypeEnum)) {
    typeOptions.push({ name: key.replace('Default', 'All'), value: value });
  }

  for (const [key, value] of Object.entries(TransactionStatusEnum)) {
    statusOptions.push({ name: key.replace('Default', 'All'), value: value });
  }

  for (const [key, value] of Object.entries(TransactionResolutionEnum)) {
    resolutionOptions.push({ name: key.replace('Default', 'All').replace('Pickup', 'Picked Up'), value: value });
  }

  return (
    <div className="filter-by">
      <div className="label">
        <div className="reset-filter-row">
          <p>{LABEL_CONSTANTS.FILTER_BY}:</p>
          {resetFilters && (
            <button type="button" className="reset-filters-button" onClick={() => resetFilters()}>
              <ClearFilterSVG />
              <u>{LABEL_CONSTANTS.RESET_FILTERS}</u>
            </button>
          )}
        </div>
        {onToggleShowBulkTransactions != undefined && showBulkTransactions != undefined && (
          <ToggleSwitch label="Bulk Transactions" checked={showBulkTransactions} onToggle={onToggleShowBulkTransactions} />
        )}
      </div>

      <div className="button-row">
        <div className="filters">
          <FilterDropdown
            placeholder="Type"
            options={sortObjectsAlphabetically(typeOptions).filter(item => {
              return (
                includeAdminFilters ||
                ![
                  TransactionTypeEnum.CheckIn,
                  TransactionTypeEnum.CheckOut,
                  TransactionTypeEnum.Adjust,
                  TransactionTypeEnum.Move,
                  TransactionTypeEnum.Reconcile,
                ].includes(item.value as TransactionTypeEnum)
              );
            })}
            value={{ name: type, value: type }}
            onChange={e => setType(e.value)}
          />

          <FilterDropdown
            placeholder="Status"
            options={statusOptions}
            value={{ name: status, value: status }}
            onChange={e => setStatus(e.value)}
          />

          {includeAdminFilters && (
            <FilterDropdown
              placeholder="Resolution"
              options={resolutionOptions}
              value={{ name: resolution, value: resolution }}
              onChange={e => setResolution(e.value)}
            />
          )}

          <div className="date-input">
            <DateInput value={date} onChange={e => setDate(e)} isClearable />
          </div>
        </div>

        {includeAdminFilters && (
          <button
            className="button outlined create-transaction"
            onClick={() => navigate(TRANSACTION_MANAGEMENT_PATHS.CREATE_CLIENT_TRANSACTION)}>
            <PlusCircleSvg />
            &nbsp;&nbsp;Create&nbsp;Client&nbsp;Transaction
          </button>
        )}
      </div>
    </div>
  );
};

export default FilterBy;
