const PasswordRequirementsTooltip = () => {
  return (
    <>
      <h2>Password Requirements</h2>
      <hr />
      <ul>
        <li>Must contain at least 12 characters</li>
        <li>Must contain at least 1 Upper Case</li>
        <li>Must contain at least 1 Lower Case</li>
        <li>Must contain at least 1 Special Character {'(!@#$%)'}</li>
      </ul>
    </>
  );
};

export default PasswordRequirementsTooltip;
