import axios from 'axios';
import { store } from '../../redux/store';
import { REACT_APP_API_URL } from '../../constants/apiEndpoints';
import { activeContexts } from '../../types/enums/activeContexts.enum';

export const appAxios = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

appAxios.interceptors.request.use(
  config => {
    const appToken = store.getState().auth.appContext.token;
    const accountToken = store.getState().auth.accountContext.token;
    const activeContext = store.getState().auth.activeContext;

    if (activeContext === activeContexts.account) {
      config.headers.Authorization = `Bearer ${accountToken}`;
    } else {
      config.headers.Authorization = `Bearer ${appToken}`;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

export const commonAxios = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

export const accountAxios = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

accountAxios.interceptors.request.use(
  config => {
    const accountToken = store.getState().auth.accountContext.token;
    config.headers.Authorization = `Bearer ${accountToken}`;
    return config;
  },
  error => {
    Promise.reject(error);
  },
);
