import { FC, useState, useEffect } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import PagedTable from '../../components/sorted-table/PagedTable';
import ActionMenu from '../../components/action-buttons/ActionButtons';
import { deleteTransactionCartItem, getTransactionCartItems } from '../../api/transactionCartApi';
import { showToast } from '../../services/toast.service';
import { PermissionService } from '../../services/permission.service';
import { fetchTransactionCartItems } from '../../services/transactionCart.service';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { PAGE_HEADER_CONSTANTS, TRANSACTION_CONSTANTS } from '../../constants/common';
import './transactionCart.scss';

type TransactionCartProps = {
  permissionService: PermissionService;
};

const TransactionCart: FC<TransactionCartProps> = ({ permissionService }): JSX.Element => {
  const navigate = useNavigate();

  const { user } = useAppSelector(state => state);
  const accountId = useAppSelector(state => state.activeAccount.accountId);

  const [refresh, setRefresh] = useState<number>(0);

  useEffect(() => {
    setRefresh(refresh => refresh + 1);
  }, []);

  const removeTransactionCartItem = async (userId: number, accountId: number, transactionType: string) => {
    try {
      await deleteTransactionCartItem(userId, accountId, transactionType);
      handleTransactionCart();
      setRefresh(refresh => refresh + 1);
      showToast.success(toastMessages.REMOVE_TRANSACTION_CART_ITEM);
    } catch (error) {
      showToast.error(toastMessages.REMOVE_TRANSACTION_CART_ITEM_FAIL);
    }
  };

  const handleTransactionCart = () => {
    if (permissionService.userHasRSGAdminPermission()) {
      fetchTransactionCartItems(user.userId);
    } else {
      fetchTransactionCartItems(user.userId, accountId);
    }
  };

  const handleNextStep = (ownerAccountId: number) => {
    ownerAccountId && navigate(ROUTE_PATHS.APP_TRANSACTION_CART_WITHDRAW + ownerAccountId);
  };

  const getTransactions = (headers: string[], type: string) => {
    return (
      <div className="card">
        <h2>
          {type} {TRANSACTION_CONSTANTS.TRANSACTIONS}
        </h2>
        <div className="transaction-table">
          <PagedTable
            headers={headers?.map((header: string) => {
              return { displayName: header };
            })}
            filter={`transactionType eq ${type}`}
            getData={params => {
              return (
                user.userId &&
                (permissionService.userHasRSGAdminPermission()
                  ? getTransactionCartItems({ userId: user.userId, ...params })
                  : accountId != undefined && getTransactionCartItems({ userId: user.userId, accountId, ...params }))
              );
            }}
            buildRow={rowItem => [
              rowItem.account.name,
              rowItem.totalQuantity,
              <ActionMenu
                actionButtons={[
                  {
                    name: 'Next Step',
                    action: () => handleNextStep(rowItem.accountId),
                  },
                  {
                    name: 'Delete',
                    action: () => user.userId && removeTransactionCartItem(user.userId, rowItem.accountId, type),
                  },
                ]}
              />,
            ]}
            refresh={refresh}
            minHeight={140}
            noResultsMessage="No transactions found"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="transaction-cart-page">
      <div className="transaction-cart">
        <div className="header">
          <h2 className="transaction-cart-header">{PAGE_HEADER_CONSTANTS.TRANSACTION_CART}</h2>
        </div>
        <hr className="header-hr" />
        <div className="inventory-transactions">{getTransactions(['Account / Owner', 'Total Qty', 'Action'], 'Withdraw')}</div>
      </div>
    </div>
  );
};

export default TransactionCart;
