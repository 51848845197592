import { getAccountById } from '../api/accountApi';
import { getOutstandingBalance } from '../api/paymentGatewayApi';
import { storeActiveAccount, storeBillingAmount } from '../redux/reducers/accountSlice';
import { storeAccountAuth } from '../redux/reducers/authSlice';
import { store } from '../redux/store';
import { AccountRoles } from '../types/enums';
import { AuthResponse } from '../types/interfaces/auth.interfaces';
import {
  getLocalActiveAccountId,
  getLocalRefreshToken,
  setLocalActiveAccountId,
  setLocalRefreshToken,
} from '../utils/localStorageHandler';
import { getAccountContextById } from './auth.service';

export const setActiveAccount = async (accountId: number) => {
  try {
    const { data: account } = await getAccountById(accountId, { include: 'Users.Roles,Addresses' });
    const { data: accountContextAuth } = await getAccountContextById({ accountId: accountId });
    const { refreshToken, refreshTokenExpiry } = accountContextAuth as AuthResponse;

    store.dispatch(storeActiveAccount(account));
    setLocalActiveAccountId(accountId);
    store.dispatch(storeAccountAuth(accountContextAuth));

    const { data: billingAmountResponse } = await getOutstandingBalance(accountId);
    store.dispatch(storeBillingAmount(billingAmountResponse));

    const staySignedIn = getLocalRefreshToken() ? true : false;
    if (refreshToken && refreshTokenExpiry) {
      setLocalRefreshToken(refreshToken, staySignedIn);
    }
  } catch (err: any) {
    throw err;
  }
};

export const requestActiveAccount = async () => {
  try {
    let activeAccountId = getLocalActiveAccountId();

    if (!activeAccountId) {
      const accounts = store.getState().user.accounts;

      const ownedAccounts = accounts.filter(account => account.roles.some(role => role.name.includes(AccountRoles.AccountOwner)));

      if (ownedAccounts.length > 0) {
        activeAccountId = ownedAccounts[0].accountId;
      } else if (accounts.length > 0) {
        activeAccountId = accounts[0].accountId;
      }
    }
    if (activeAccountId) {
      setActiveAccount(activeAccountId);
    } else {
      throw new Error('No active account found');
    }
  } catch (err: any) {
    throw err;
  }
};

export const centsToDollars = (cents?: number) => {
  if (cents) {
    return cents % 100 === 0 ? cents / 100 : (cents / 100).toFixed(2);
  }
  return 0;
};
