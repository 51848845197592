const BusinessFarmNameTooltip = () => {
  return (
    <>
      <h2>Business/Farm Name</h2>
      <hr />
      <p>
        If a business or farm name is not specified, this field will default to
        your first and last name.
      </p>
    </>
  );
};

export default BusinessFarmNameTooltip;
