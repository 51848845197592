import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { Animal } from '../../types/interfaces/animal.interfaces';
import { SpecimenInventory } from '../../types/interfaces/specimen.interfaces';
import { getAnimalById } from '../../api/animalsApi';
import { getSpecimensByOwnership } from '../../api/specimensApi';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';
import { ANIMAL_CONSTANTS, LABEL_CONSTANTS } from '../../constants/common';

const AnimalInfo: FC<{ animalId?: string; showQuantityAvailable?: boolean }> = ({
  animalId,
  showQuantityAvailable = false,
}): JSX.Element => {
  const { accountId } = useAppSelector(state => state.activeAccount);

  const [animal, setAnimal] = useState<Animal>();
  const [totalQuantityAvailable, setTotalQuantityAvailable] = useState<number>();

  useEffect(() => {
    if (animalId) {
      fetchAnimal();
      if (showQuantityAvailable) {
        getQuantityAvailable();
      }
    } else {
      setAnimal(undefined);
      setTotalQuantityAvailable(undefined);
    }
  }, [animalId, showQuantityAvailable]);

  const fetchAnimal = async () => {
    try {
      const response = await getAnimalById(animalId ?? '', { include: 'Breed.Species' });
      setAnimal(response.data);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const getQuantityAvailable = async () => {
    if (animalId && accountId) {
      try {
        const specs = await getSpecimensByOwnership(animalId, accountId);
        let quantityAvailable = 0;
        specs.data.forEach((specimen: SpecimenInventory) => (quantityAvailable += specimen.quantityAvailable));
        setTotalQuantityAvailable(quantityAvailable);
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  };

  return (
    <div className="animal-info">
      <h2>{ANIMAL_CONSTANTS.ANIMAL_INFO}</h2>
      <div>
        {ANIMAL_CONSTANTS.ANIMAL_CODE}: &nbsp;
        {animal === undefined ? '-------' : animal.code}
      </div>
      <div>
        {ANIMAL_CONSTANTS.ANIMAL_NAME}: &nbsp;
        {animal === undefined ? '-------' : animal.name}
      </div>
      {showQuantityAvailable && (
        <div>
          {LABEL_CONSTANTS.QUANTITY_AVAILABLE}: &nbsp;
          {totalQuantityAvailable === undefined ? '--' : totalQuantityAvailable}
          &nbsp;{LABEL_CONSTANTS.UNITS}
        </div>
      )}
    </div>
  );
};

export default AnimalInfo;
