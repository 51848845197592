import { FC, useState } from 'react';
import Modal from '../modal/Modal';
import Loader from '../loader/Loader';
import { signOutUser } from '../../services/auth.service';
import { showToast } from '../../services/toast.service';
import { resendVerificationEmail } from '../../api/userApi';
import { toastMessages } from '../../constants/errorMessages';

type VerifyUserModalProps = {
  isOpen: boolean;
  userId: number;
};

const VerifyUserModal: FC<VerifyUserModalProps> = ({ isOpen, userId }) => {
  const [resendingLink, setResendingLink] = useState<boolean>(true);

  const handleResend = async () => {
    if (!resendingLink) return;
    setResendingLink(false);

    resendVerificationEmail(userId)
      .then(() => {
        showToast.success(toastMessages.INVITE_RESENT);
      })
      .catch(() => {
        showToast.success(toastMessages.FAILED_TO_RESEND_INVITE);
      })
      .finally(() => {
        setResendingLink(true);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} width={700}>
      <div className="sign-out-modal">
        <div className="header">
          <h4>Verify Account</h4>
        </div>
        <div className="body">
          {!resendingLink && <Loader loaderSize="small" simple />}
          <p>
            Please check your email and click the link to verify your email address in order to continue. If you did not receive a
            verification email or if your link expired, click the button below to send a new link.
          </p>
        </div>
        <div className="footer">
          <button className="button blue inverted medium" onClick={() => signOutUser()}>
            Sign Out
          </button>
          <button className="button green medium" onClick={() => handleResend()}>
            Resend Link
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyUserModal;
