const UnlockSVG = (): JSX.Element => {
  return (
    <svg
      fill="#2dae3c"
      height="36px"
      width="36px"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 280.417 280.417"
      stroke="#2dae3c">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M206.527,4.542c-40.742,0-73.889,33.146-73.889,73.889v32.111H16.125C7.22,110.542,0,117.761,0,126.667V259.75 c0,8.906,7.22,16.125,16.125,16.125h165.25c8.906,0,16.125-7.219,16.125-16.125V126.667c0-8.906-7.219-16.125-16.125-16.125h-8.736 V78.431c0-18.686,15.202-33.889,33.889-33.889c18.687,0,33.89,15.202,33.89,33.889c0,11.046,8.954,20,20,20s20-8.954,20-20 C280.417,37.689,247.27,4.542,206.527,4.542z M114.416,194.777v26.432c0,8.652-7.014,15.666-15.666,15.666 s-15.666-7.014-15.666-15.666v-26.432c-5.885-4.639-9.668-11.826-9.668-19.901c0-13.992,11.342-25.334,25.334-25.334 s25.334,11.342,25.334,25.334C124.084,182.951,120.301,190.138,114.416,194.777z"></path>{' '}
      </g>
    </svg>
  );
};

export default UnlockSVG;
