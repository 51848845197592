import { Route, Routes } from 'react-router-dom';
import { TRANSACTION_HISTORY_PATHS } from '../constants/routePaths';
import TransactionView from '../components/transaction/TransactionView';

const TransactionHistoryRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path={TRANSACTION_HISTORY_PATHS.VIEW} element={<TransactionView />} />
      </Routes>
    </div>
  );
};

export default TransactionHistoryRoutes;
