import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AnimalSearch from '../../components/custom-input/AnimalSearch';
import Modal from '../../components/modal/Modal';
import PlusCircleSvg from '../../components/svgs/PlusCircle.svg';
import AddAnimal from './AddAnimal';
import { getAnimalCount } from '../../api/animalsApi';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { ANIMAL_CONSTANTS, PAGE_HEADER_CONSTANTS } from '../../constants/common';
import { Animal } from '../../types/interfaces/animal.interfaces';

const AnimalManagementHeader = (): JSX.Element => {
  const [addAnimalModal, setAddAnimalModal] = useState<boolean>(false);
  const [animalCount, setAnimalCount] = useState<number>(-1);

  const navigate = useNavigate();

  useEffect(() => {
    getAnimalCount()
      .then((e: any) => {
        setAnimalCount(e.data);
      })
      .catch();
  }, []);

  const navigateToAnimal = (animalId: number) => {
    navigate(ROUTE_PATHS.APP_ANIMAL_MANAGEMENT_ + animalId);
  };

  return (
    <div className="animal-management-header info-search-header">
      <div className="header-row">
        <div className="header-section">
          <div className="section">
            <h1>{PAGE_HEADER_CONSTANTS.ANIMAL_INVENTORY}</h1>
          </div>
          <div className="section">
            <span className="bold-count">{animalCount < 0 ? '--' : animalCount}</span>
            {ANIMAL_CONSTANTS.ANIMALS}
          </div>
        </div>
        <div>
          <button type="button" className="button outlined" onClick={() => setAddAnimalModal(true)}>
            <PlusCircleSvg />
            &nbsp;&nbsp;{ANIMAL_CONSTANTS.ADD_NEW_ANIMAL}&nbsp;
          </button>
        </div>
        <Modal isOpen={addAnimalModal} onClose={() => setAddAnimalModal(false)} width={1014} ignoreBackdrop>
          <AddAnimal onCancel={() => setAddAnimalModal(false)} />
        </Modal>
      </div>

      <div className="search-bar">
        <AnimalSearch
          onChange={(animal: Animal | undefined) => animal && animal.animalId && navigateToAnimal(animal.animalId!)}
        />
      </div>
    </div>
  );
};

export default AnimalManagementHeader;
