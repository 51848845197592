import { FC } from 'react';
import { InfoSVG } from '../svgs';
import './infoText.scss';

type InfoWarningProps = {
  message: string;
  className?: string;
  style?: React.CSSProperties;
  infoIconSize?: string;
};

const InfoWarning: FC<InfoWarningProps> = ({ message, className, style, infoIconSize = '24' }) => {
  return (
    <div className={`info-warning ${className}`} style={style}>
      <div className="info-warning-icon">
        <InfoSVG size={infoIconSize} />
      </div>
      <div className="info-warning-message">{message}</div>
    </div>
  );
};

export default InfoWarning;
