import { FC, useState } from 'react';
import OwnershipOverview, { OwnerPercentage } from './OwnershipOverview';
import AnimalInfoForm from './AnimalInfoForm';
import { AlertSVG } from '../../components/svgs';
import { createAnimal } from '../../api/animalsApi';
import { showToast } from '../../services/toast.service';
import { PromiseError, toastMessages } from '../../constants/errorMessages';
import { ANIMAL_CONSTANTS, LABEL_CONSTANTS, VALIDATION_ALERT_CONSTANTS } from '../../constants/common';
import { Genders } from '../../types/enums/genders.enum';
import { Animal } from '../../types/interfaces/animal.interfaces';

const AddAnimal: FC<{ onCancel: (animalId?: number) => void }> = ({ onCancel }): JSX.Element => {
  const [step, setStep] = useState<number>(1);
  const [animalFormIsValid, setAnimalFormIsValid] = useState<boolean>(false);
  const [hasOwnership, setHasOwnership] = useState<boolean | undefined>(undefined);
  const [ownerPercentages, setOwnerPercentages] = useState<OwnerPercentage[]>([]);
  const [ownershipValid, setOwnershipValid] = useState<boolean>(false);
  const [validate, setValidate] = useState<boolean>(false);
  const [creatingAnimal, setCreatingAnimal] = useState<boolean>(true);
  const [animalInfo, setAnimalInfo] = useState<Animal>({
    name: '',
    gender: Genders.Default,
    birthDate: undefined,
    sire: '',
    dam: '',
    dna: '',
    code: '',
    registration: '',
    description: '',
    breed: { name: '', species: { name: '' } },
  });

  const handleContinue = () => {
    if (!creatingAnimal) return;
    setValidate(true);

    if (animalFormIsValid && hasOwnership !== undefined) {
      if (step === 1) {
        if (hasOwnership === false) {
          createNewAnimal();
        } else {
          setStep(2);
          setValidate(false);
          setCreatingAnimal(true);
        }
      } else if (step === 2) {
        if (ownershipValid) {
          createNewAnimal();
        }
      }
    } else {
      setCreatingAnimal(true);
    }
  };

  const createNewAnimal = async () => {
    setCreatingAnimal(false);

    try {
      const { data: newAnimalId } = await createAnimal({
        name: animalInfo.name,
        code: animalInfo.code,
        dna: animalInfo.dna,
        registration: animalInfo.registration,
        gender: animalInfo.gender,
        birthDate: animalInfo.birthDate!,
        sire: animalInfo.sire,
        dam: animalInfo.dam,
        description: animalInfo.description,
        breedId: animalInfo.breed?.breedId,
        owners: ownerPercentages.map(owner => {
          return {
            accountId: Number(owner.id),
            ownershipPercentage: Number(owner.percentage),
          };
        }),
      });
      onCancel(newAnimalId);
      showToast.success(toastMessages.ANIMAL_CREATED);
    } catch (error: any) {
      if (error.code === PromiseError.NETWORK) {
        showToast.error(toastMessages.NETWORK);
      } else {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    } finally {
      setCreatingAnimal(true);
    }
  };

  return (
    <div className="add-animal">
      <div className="add-animal-header">
        <h4>{ANIMAL_CONSTANTS.ADD_NEW_ANIMAL}</h4>
      </div>
      <div className="step-counter">
        <div className={step === 1 ? 'step-bubble active' : 'step-bubble'}>1</div>
        <div className="step-connector"></div>
        <div className={step === 2 ? 'step-bubble active' : 'step-bubble'}>2</div>
      </div>
      {step === 1 ? (
        <div>
          <div className="row">
            <h2>{ANIMAL_CONSTANTS.ANIMAL_INFO}</h2>
          </div>
          <div className="animal-info-container">
            <div className="animal-info-image"></div>
            <div className="animal-info-column">
              <AnimalInfoForm
                animalInfo={animalInfo}
                setAnimalInfo={setAnimalInfo}
                validate={validate}
                setValid={setAnimalFormIsValid}
              />

              <div className="has-owners">
                <p>*{ANIMAL_CONSTANTS.ANIMAL_OWNERSHIP_QUESTION}</p>
                <div className="radio-row">
                  <input
                    type="radio"
                    id="has-owners-true"
                    name="has-owners"
                    checked={hasOwnership ?? false}
                    onChange={() => setHasOwnership(true)}
                  />
                  <label htmlFor="has-owners-true">{LABEL_CONSTANTS.YES}</label>
                  <input type="radio" id="has-owners-false" name="has-owners" onChange={() => setHasOwnership(false)} />
                  <label htmlFor="has-owners-false">{LABEL_CONSTANTS.NO}</label>
                  {hasOwnership === undefined && validate && (
                    <div className="validated-input-message-error">
                      <AlertSVG /> {VALIDATION_ALERT_CONSTANTS.REQUIRED_FIELD}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <OwnershipOverview
            ownerPercentages={ownerPercentages}
            setOwnerPercentages={setOwnerPercentages}
            setValid={setOwnershipValid}
          />
        </div>
      )}
      <div className="button-row">
        <button
          className="button medium green inverted"
          type="button"
          onClick={() => {
            if (step <= 1) {
              onCancel();
            } else {
              setStep(step - 1);
            }
          }}>
          {step <= 1 ? 'Cancel' : 'Back'}
        </button>
        <button
          className={
            (step === 1 && (!animalFormIsValid || hasOwnership === undefined)) || (step === 2 && !ownershipValid)
              ? 'button medium green disabled'
              : 'button medium green'
          }
          type="button"
          onClick={handleContinue}>
          {hasOwnership === false || step === 2 ? 'Submit' : 'Continue'}
        </button>
      </div>
    </div>
  );
};

export default AddAnimal;
