import React, { FC, useCallback, useEffect, useState } from 'react';
import { Animal } from '../../types/interfaces/animal.interfaces';
import { getAnimalById } from '../../api/animalsApi';
import { getAnimalAttachmentURL } from '../../services/animal.service';
import { showToast } from '../../services/toast.service';
import { oneBitImage } from '../../utils/commonUtils';
import { ANIMAL_CONSTANTS } from '../../constants/common';
import { toastMessages } from '../../constants/errorMessages';

type AnimalBannerProps = {
  animalId: string | undefined;
  children: React.ReactNode | React.ReactNode[];
};

const AnimalBanner: FC<AnimalBannerProps> = ({ animalId, children }): JSX.Element => {
  const [animal, setAnimal] = useState<Animal>();

  const fetchAnimal = useCallback(async () => {
    if (animalId) {
      try {
        const response = await getAnimalById(animalId ?? '', { include: 'Breed.Species, Attachments' });
        setAnimal(response.data);
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  }, [animalId]);

  useEffect(() => {
    fetchAnimal();
  }, [fetchAnimal]);

  return (
    <div className="card animal-info">
      <div className="animal-header-info">
        <div className="name">
          <h2>{animal?.name}</h2>
          <div>
            {ANIMAL_CONSTANTS.ANIMAL_CODE}: {animal?.code}
          </div>
        </div>
        <hr />
      </div>
      <div className="animal-banner">
        {animal && animal.attachments && animal.attachments.length > 0 ? (
          <div className="animal-photos">
            <img
              src={
                animal && animalId && animal.attachments && animal.attachments.length > 0
                  ? getAnimalAttachmentURL(animalId, animal.attachments[0].attachmentId, animal.attachments[0].accessToken)
                  : oneBitImage
              }
              alt="Animal Photos"
            />
            {animal.attachments.length > 1 && (
              <div className="animal-photos-row">
                {animal.attachments.map((attachment, index) => {
                  if (index > 0) {
                    return (
                      <img
                        key={attachment.attachmentId}
                        src={
                          animal && animalId && animal.attachments && animal.attachments.length > 0
                            ? getAnimalAttachmentURL(animalId, attachment.attachmentId, attachment.accessToken)
                            : oneBitImage
                        }
                        alt={`Animal's Photo ${index}`}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            )}
          </div>
        ) : (
          <div className="specimen-image">{ANIMAL_CONSTANTS.NO_IMAGE}</div>
        )}
        <div className="specimen-info">
          <div className="stats">
            <div>
              <h3>{ANIMAL_CONSTANTS.DOB}:</h3>
              <div>{animal?.birthDate ? new Date(animal?.birthDate).toLocaleDateString() : 'Unknown'}</div>
              <br />
              <h3>{ANIMAL_CONSTANTS.DAM}:</h3>
              <div>{animal?.dam}</div>
            </div>
            <div>
              <h3>{ANIMAL_CONSTANTS.GENDER}</h3>
              <div>{animal?.gender}</div>
              <br />
              <h3>{ANIMAL_CONSTANTS.DNA}:</h3>
              <div>{animal?.dna}</div>
            </div>
            <div>
              <h3>{ANIMAL_CONSTANTS.BREED}:</h3>
              <div>{animal?.breed?.name}</div>
              <br />
              <h3>{ANIMAL_CONSTANTS.REGISTRATION}:</h3>
              <div>{animal?.registration}</div>
            </div>
            <div>
              <h3>{ANIMAL_CONSTANTS.SIRE}:</h3>
              <div>{animal?.sire}</div>
              <br />
              <h3>{ANIMAL_CONSTANTS.SPECIES}:</h3>
              <div>{animal?.breed?.species?.name}</div>
            </div>
          </div>

          <div className="description">
            <h3>{ANIMAL_CONSTANTS.DESCRIPTION}: &nbsp; </h3>
            <div>{animal?.description}</div>
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};

export default AnimalBanner;
