import CustomerInfoHeader from './CustomerInfoHeader';
import './customerInfo.scss';

const CustomerInfo = (): JSX.Element => {
  return (
    <div className="customer-info">
      <CustomerInfoHeader />
    </div>
  );
};

export default CustomerInfo;
