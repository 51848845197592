import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import SignOutModal from '../sign-out-modal/SignOutModal';
import { PermissionService } from '../../services/permission.service';
import { ACCOUNT_PATHS, APP_PATHS, ROUTE_PATHS } from '../../constants/routePaths';
import ProfileImage from '../profile-image/ProfileImage';
import { DashboardSVG, DataSVG, GoatSVG, HistorySVG, InventorySVG, LocationSVG } from '../svgs';
import { PersonsSVG, SettingsSVG, TabMenuSvg } from '../svgs';

type NavBarTabletViewProps = {
  permissionService: PermissionService;
  setOpenNavBar: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAdminNav: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAccountNav: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAnimalManagementOptions: React.Dispatch<React.SetStateAction<boolean>>;
  setShowInventoryManagementOptions: React.Dispatch<React.SetStateAction<boolean>>;
  currentRoute: string[];
};

const NavBarTabletView: FC<NavBarTabletViewProps> = ({
  permissionService,
  setOpenNavBar,
  setShowAdminNav,
  setShowAccountNav,
  setShowAnimalManagementOptions,
  setShowInventoryManagementOptions,
  currentRoute,
}) => {
  const location = useLocation();
  const appPage = location.pathname.split('/')[2];
  const user = useAppSelector(state => state.user);
  const profileImageBlob = useAppSelector(state => state.user.profileImageBlob);
  const profileImageId = useAppSelector(state => state.user.profileImageId);

  const activeLinkClass = (path: string) => {
    return currentRoute.includes(path) ? 'nav-link active' : 'nav-link';
  };

  return (
    <div>
      <button type="button" onClick={() => setOpenNavBar(true)}>
        <TabMenuSvg />
      </button>
      <div>
        <Link to={ROUTE_PATHS.APP_USER_PROFILE}>
          <ProfileImage profileImageId={profileImageId} profileImageBlob={profileImageBlob} />
        </Link>
      </div>

      {user.accounts.length > 0 ? (
        <>
          {permissionService.userHasRSGAdminPermission() && !Object.values(ACCOUNT_PATHS).includes(appPage) ? (
            <div className="tab-grid-nav-bar">
              <button
                type="button"
                className={activeLinkClass(APP_PATHS.ANIMAL_MANAGEMENT)}
                onClick={() => {
                  setOpenNavBar(true);
                  setShowAdminNav(true);
                  setShowAccountNav(false);
                  setShowAnimalManagementOptions(true);
                }}>
                <GoatSVG />
              </button>
              <button
                type="button"
                className={activeLinkClass(APP_PATHS.INVENTORY_MANAGEMENT)}
                onClick={() => {
                  setOpenNavBar(true);
                  setShowAdminNav(true);
                  setShowAccountNav(false);
                  setShowInventoryManagementOptions(true);
                }}>
                <InventorySVG />
              </button>
              <Link
                className={activeLinkClass(APP_PATHS.TRANSACTION_MANAGEMENT)}
                to={APP_PATHS.TRANSACTION_MANAGEMENT}
                onClick={() => setShowAdminNav(true)}>
                <HistorySVG />
              </Link>
              <Link
                className={activeLinkClass(APP_PATHS.CUSTOMER_INFO)}
                to={APP_PATHS.CUSTOMER_INFO}
                onClick={() => setShowAdminNav(true)}>
                <DataSVG />
              </Link>
              <Link
                className={activeLinkClass(APP_PATHS.LOCATION_MANAGEMENT)}
                to={APP_PATHS.LOCATION_MANAGEMENT}
                onClick={() => setShowAdminNav(true)}>
                <LocationSVG />
              </Link>
              <Link
                className={activeLinkClass(APP_PATHS.APPLICATION_TEAM)}
                to={APP_PATHS.APPLICATION_TEAM}
                onClick={() => setShowAdminNav(true)}>
                <PersonsSVG />
              </Link>
              <Link
                className={activeLinkClass(APP_PATHS.ADMIN_SETTINGS)}
                to={APP_PATHS.ADMIN_SETTINGS}
                onClick={() => setShowAdminNav(true)}>
                <SettingsSVG />
              </Link>
            </div>
          ) : (
            <>
              <div className="tab-grid-nav-bar">
                <Link
                  className={activeLinkClass(ACCOUNT_PATHS.DASHBOARD)}
                  to={ACCOUNT_PATHS.DASHBOARD}
                  onClick={() => setShowAccountNav(true)}>
                  <DashboardSVG />
                </Link>
                <Link
                  className={activeLinkClass(ACCOUNT_PATHS.INVENTORY)}
                  to={ACCOUNT_PATHS.INVENTORY}
                  onClick={() => setShowAccountNav(true)}>
                  <InventorySVG />
                </Link>
                <Link
                  className={activeLinkClass(ACCOUNT_PATHS.TRANSACTION_HISTORY)}
                  to={ACCOUNT_PATHS.TRANSACTION_HISTORY}
                  onClick={() => setShowAccountNav(true)}>
                  <HistorySVG />
                </Link>
                {permissionService.userHasTeamManagementPermission() && (
                  <Link
                    className={activeLinkClass(ACCOUNT_PATHS.TEAM_MANAGEMENT)}
                    to={ACCOUNT_PATHS.TEAM_MANAGEMENT}
                    onClick={() => setShowAccountNav(true)}>
                    <PersonsSVG />
                  </Link>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {permissionService.userHasRSGAdminPermission() && Object.values(APP_PATHS).includes(appPage) && (
            <div className="tab-grid-nav-bar">
              <button
                type="button"
                className={activeLinkClass(APP_PATHS.ANIMAL_MANAGEMENT)}
                onClick={() => {
                  setOpenNavBar(true);
                  setShowAdminNav(true);
                  setShowAccountNav(false);
                  setShowAnimalManagementOptions(true);
                }}>
                <GoatSVG />
              </button>
              <button
                type="button"
                className={activeLinkClass(APP_PATHS.INVENTORY_MANAGEMENT)}
                onClick={() => {
                  setOpenNavBar(true);
                  setShowAdminNav(true);
                  setShowAccountNav(false);
                  setShowInventoryManagementOptions(true);
                }}>
                <InventorySVG />
              </button>
              <Link
                className={activeLinkClass(APP_PATHS.TRANSACTION_MANAGEMENT)}
                to={APP_PATHS.TRANSACTION_MANAGEMENT}
                onClick={() => setShowAdminNav(true)}>
                <HistorySVG />
              </Link>
              <Link
                className={activeLinkClass(APP_PATHS.CUSTOMER_INFO)}
                to={APP_PATHS.CUSTOMER_INFO}
                onClick={() => setShowAdminNav(true)}>
                <DataSVG />
              </Link>
              <Link
                className={activeLinkClass(APP_PATHS.LOCATION_MANAGEMENT)}
                to={APP_PATHS.LOCATION_MANAGEMENT}
                onClick={() => setShowAdminNav(true)}>
                <LocationSVG />
              </Link>
              <Link
                className={activeLinkClass(APP_PATHS.APPLICATION_TEAM)}
                to={APP_PATHS.APPLICATION_TEAM}
                onClick={() => setShowAdminNav(true)}>
                <PersonsSVG />
              </Link>
              <Link
                className={activeLinkClass(APP_PATHS.ADMIN_SETTINGS)}
                to={APP_PATHS.ADMIN_SETTINGS}
                onClick={() => setShowAdminNav(true)}>
                <SettingsSVG />
              </Link>
            </div>
          )}
        </>
      )}

      <hr />
      {user.accounts.length > 0 && permissionService.userHasSettingsPermission() && (
        <div className="tab-setting-nav-bar">
          <Link className={activeLinkClass(ACCOUNT_PATHS.ACCOUNT_SETTINGS)} to={ACCOUNT_PATHS.ACCOUNT_SETTINGS}>
            <SettingsSVG />
          </Link>
        </div>
      )}
      <div className="tab-sign-out">
        <SignOutModal />
      </div>
    </div>
  );
};

export default NavBarTabletView;
