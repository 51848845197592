import { FC, useEffect, useState } from 'react';
import ValidatedInput, { ValidatedState } from './ValidatedInput';
import { RequireAlert } from '../../pages/inventory-management/InventoryManagementCollectDeposit';
import DateInput from './DateInput';
import { Validators } from '../../types/enums';

export interface PickupInfo {
  name: string;
  phone: string;
  date?: Date;
}

interface ValidatedPickupInfoProps {
  validate: boolean;
  setValid: (valid: boolean) => void;
  setPickupInfo: (pickupInfo: PickupInfo) => void;
}

const ValidatedPickupInfo: FC<ValidatedPickupInfoProps> = ({ validate, setValid, setPickupInfo }): JSX.Element => {
  const [pickupNameInput, setPickupNameInput] = useState<ValidatedState>({
    value: '',
    valid: false,
  });
  const [pickupPhoneInput, setPickupPhoneInput] = useState<ValidatedState>({
    value: '',
    valid: false,
  });
  const [pickupDateInput, setPickupDateInput] = useState<Date>();

  useEffect(() => {
    setPickupInfo({
      name: pickupNameInput.value,
      phone: pickupPhoneInput.value,
      date: pickupDateInput,
    });
    setValid(pickupNameInput.valid && pickupPhoneInput.valid && pickupDateInput !== undefined && pickupDateInput !== null);
  }, [pickupNameInput, pickupPhoneInput, pickupDateInput]);
  return (
    <div>
      <div className="form-row">
        <ValidatedInput
          useId="name"
          label="*Who is Picking Up Item (Name):"
          type="text"
          validate={validate}
          setValidatedState={setPickupNameInput}
          validators={[Validators.REQUIRED]}
        />
      </div>
      <div className="form-row">
        <ValidatedInput
          useId="contact-phone"
          label="*Contact Phone:"
          type="phone"
          validate={validate}
          setValidatedState={setPickupPhoneInput}
          validators={[Validators.REQUIRED, Validators.PHONE_LENGTH]}
        />
      </div>
      <div className="form-row">
        <label>*Requested Pickup Date:
        <RequireAlert isActive={!pickupDateInput} />
        </label>
        <DateInput value={pickupDateInput} onChange={setPickupDateInput} isClearable minDate={new Date()} maxDate={null} />
      </div>
    </div>
  );
};

export default ValidatedPickupInfo;
