const HamburgerMenuSVG = (): JSX.Element => {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.15625 16.9688H14.5M1.15625 9.46875H17M1.15625 1.96875H20.8438"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default HamburgerMenuSVG;
