import { FC } from 'react';

type UploadImageProps = {
  className?: string;
};

const UploadImage: FC<UploadImageProps> = ({ className }) => {
  return (
    <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.125 17.25C1.74688 17.25 1.42318 17.1154 1.15391 16.8461C0.884635 16.5768 0.75 16.2531 0.75 15.875V2.125C0.75 1.74688 0.884635 1.42318 1.15391 1.15391C1.42318 0.884635 1.74688 0.75 2.125 0.75H10.8104C11.0052 0.75 11.1685 0.816267 11.3003 0.948802C11.432 1.08135 11.4979 1.24559 11.4979 1.44151C11.4979 1.63745 11.432 1.80035 11.3003 1.93021C11.1685 2.06007 11.0052 2.125 10.8104 2.125H2.125V15.875H15.875V7.2125C15.875 7.01771 15.9413 6.85443 16.0738 6.72266C16.2064 6.59089 16.3706 6.525 16.5665 6.525C16.7624 6.525 16.9253 6.59089 17.0552 6.72266C17.1851 6.85443 17.25 7.01771 17.25 7.2125V15.875C17.25 16.2531 17.1154 16.5768 16.8461 16.8461C16.5768 17.1154 16.2531 17.25 15.875 17.25H2.125ZM14.0417 3.98125H12.8729C12.6781 3.98125 12.5148 3.91498 12.3831 3.78245C12.2513 3.6499 12.1854 3.48566 12.1854 3.28974C12.1854 3.0938 12.2513 2.9309 12.3831 2.80104C12.5148 2.67118 12.6781 2.60625 12.8729 2.60625H14.0417V1.4375C14.0417 1.24271 14.1079 1.07943 14.2405 0.947656C14.373 0.815885 14.5373 0.75 14.7332 0.75C14.9291 0.75 15.092 0.815885 15.2219 0.947656C15.3517 1.07943 15.4167 1.24271 15.4167 1.4375V2.60625H16.5854C16.7802 2.60625 16.9435 2.67252 17.0753 2.80505C17.207 2.9376 17.2729 3.10184 17.2729 3.29776C17.2729 3.4937 17.207 3.6566 17.0753 3.78646C16.9435 3.91632 16.7802 3.98125 16.5854 3.98125H15.4167V5.15C15.4167 5.34479 15.3504 5.50807 15.2179 5.63984C15.0853 5.77161 14.9211 5.8375 14.7252 5.8375C14.5292 5.8375 14.3663 5.77161 14.2365 5.63984C14.1066 5.50807 14.0417 5.34479 14.0417 5.15V3.98125ZM8.28958 12.9646L6.41042 10.4896C6.33403 10.3979 6.24236 10.3521 6.13542 10.3521C6.02847 10.3521 5.93681 10.3979 5.86042 10.4896L3.93542 12.9875C3.84375 13.1097 3.83229 13.2319 3.90104 13.3542C3.96979 13.4764 4.07292 13.5375 4.21042 13.5375H13.8125C13.9424 13.5375 14.0398 13.4764 14.1047 13.3542C14.1696 13.2319 14.1639 13.1097 14.0875 12.9875L11.475 9.50417C11.3986 9.4125 11.3069 9.36667 11.2 9.36667C11.0931 9.36667 11.0014 9.4125 10.925 9.50417L8.28958 12.9646Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UploadImage;
