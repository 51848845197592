import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../components/back-button/BackButton';
import FilterDropdown from '../../components/custom-input/FilterDropdown';
import ProfileImage from '../../components/profile-image/ProfileImage';
import { Loader } from '../../components';
import { showToast } from '../../services/toast.service';
import { updateUserAppRole } from '../../services/user.service';
import { getUserProfileImageById } from '../../api/userApi';
import { selectOption } from '../../types/interfaces/selectOption.interface';
import { AppRoles } from '../../types/enums';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS } from '../../constants/common';

const AdminEditTeam: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state: location } = useLocation();
  const { firstName, lastName, roles: userRoles, userId, username, profileImageId } = location;

  const [profileImage, setProfileImage] = useState<Blob>();
  const [editingTeam, setEditingTeam] = useState<boolean>(true);
  const [accessLevel, setAccessLevel] = useState<selectOption<AppRoles>>({ name: '', value: AppRoles.Default });

  useEffect(() => {
    const loadUserImage = async () => {
      try {
        const blobImage = await getUserProfileImageById(userId, profileImageId);
        setProfileImage(blobImage);
      } catch (e) {
        setProfileImage(new Blob());
      }
    };
    loadUserImage();
  }, [userId]);

  const handleUpdate = async () => {
    if (!editingTeam) return;
    setEditingTeam(false);

    if (id) {
      await updateUserAppRole(id, accessLevel.value)
        .then(() => {
          showToast.success(toastMessages.SUCCESS);
          navigate(ROUTE_PATHS.APP_APPLICATION_TEAM);
        })
        .catch(() => {
          showToast.error(toastMessages.FAILED_TO_UPDATE_TEAM_MEMBER);
        })
        .finally(() => {
          setEditingTeam(true);
        });
    } else {
      setEditingTeam(true);
    }
  };

  return (
    <>
      <BackButton />
      <div className="card user-profile">
        <div className="image-header" style={{ backgroundImage: '' }}>
          <ProfileImage profileImageBlob={profileImage} profileImageId={profileImageId} />
        </div>

        <div className="edit-user-container">
          <h3>
            Edit permissions for {firstName} {lastName}
          </h3>

          <div className="select-container">
            <label>{LABEL_CONSTANTS.ROLE}</label>
            <FilterDropdown
              options={[{ name: AppRoles.Admin, value: AppRoles.Admin }]}
              value={accessLevel}
              onChange={e => setAccessLevel({ value: e.value, name: e.name })}
            />
          </div>
          {!editingTeam && <Loader loaderSize={'small'} simple />}

          <button onClick={handleUpdate} className="button blue" disabled={!accessLevel.value}>
            {BUTTON_CONSTANTS.UPDATE}
          </button>
        </div>
      </div>
    </>
  );
};

export default AdminEditTeam;
