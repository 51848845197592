import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TransactionsList, { TransactionFilterOption, TransactionFilterState } from '../../components/transaction/TransactionsList';
import PagedTable from '../../components/sorted-table/PagedTable';
import { FilterOption } from '../../components/custom-input/FilterDropdown';
import ActionMenu, { ActionButton } from '../../components/action-buttons/ActionButtons';
import { Loader } from '../../components';
import { StandardParams, StandardParamsToODataParams } from '../../types/interfaces/apiParams.interfaces';
import { TransactionStatusEnum, TransactionTypeEnum } from '../../types/enums';
import { Account, InventoryTransaction } from '../../types/interfaces';
import { getTransactionsOdata } from '../../api/transactionsApi';
import { getBulkTransactionsOdata } from '../../api/bulkTransactionApi';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { toastMessages } from '../../constants/errorMessages';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS, PAGE_HEADER_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../constants/common';
import { pendingUseHasCheckout } from '../../services/inventoryTransaction.service';
import { showToast } from '../../services/toast.service';
import './transactionManagement.scss';

const TransactionManagement = (): JSX.Element => {
  const currentLocation: string = location.pathname;
  const { state } = useLocation();
  const navigate = useNavigate();

  const [filter, setFilter] = useState<string>(state?.filter ?? undefined);
  const [filterPreset, setFilterPreset] = useState<TransactionFilterState>(state?.filterPreset ?? undefined);
  const [transactionsData, setTransactionsData] = useState<InventoryTransaction[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [showBulkTransactions, setShowBulkTransactions] = useState<boolean>(state?.bulkOrder ?? false);
  const [refresh, setRefresh] = useState<number>(0);

  const transactionFetchLimit = 20;

  const ref = useRef<any>(null);
  const isLoadingAdditionalPage: MutableRefObject<boolean> = useRef<boolean>(false);
  const hasAllResults: MutableRefObject<boolean> = useRef<boolean>(false);
  const callNumber: MutableRefObject<number> = useRef<number>(0);

  const searchCriteriaOptions: FilterOption<TransactionFilterOption>[] = [
    {
      name: `${TABLE_HEADER_CONSTANTS.TRANSACTION_ID}`,
      value: { parameter: showBulkTransactions ? 'bulkOrderId' : 'inventoryTransactionId', isNumber: true },
    },
    { name: `${TABLE_HEADER_CONSTANTS.ACCOUNT_OWNER}`, value: { parameter: 'accountId' } },
    { name: `${LABEL_CONSTANTS.NOTES}`, value: { parameter: 'notes' } },
    { name: `${TABLE_HEADER_CONSTANTS.ANIMAL_CODE}`, value: { parameter: 'specimen/animal/code' } },
    { name: `${TABLE_HEADER_CONSTANTS.ANIMAL_NAME}`, value: { parameter: 'specimen/animal/name' } },
    { name: `${TABLE_HEADER_CONSTANTS.LOT_NO}`, value: { parameter: 'specimenId', isNumber: true } },
  ];

  const handleActionButtons = (t: InventoryTransaction) => {
    const nextStep: ActionButton = { name: `${BUTTON_CONSTANTS.NEXT_STEP}`, action: () => handleNextStep(t) };

    const adjust: ActionButton = {
      name: `${BUTTON_CONSTANTS.ADJUST}`,
      action: () =>
        navigate(ROUTE_PATHS.APP_TRANSACTION_MANAGEMENT_ADJUST + (t.inventoryTransactionId ?? t.bulkOrderId), {
          state: {
            inventoryTransactionId: t.inventoryTransactionId,
            filterPreset: filterPreset,
            filter: filter,
            bulkOrder: showBulkTransactions,
          },
        }),
    };

    const viewEdit: ActionButton = {
      name: `${BUTTON_CONSTANTS.VIEW_EDIT}`,
      action: () =>
        navigate(ROUTE_PATHS.APP_TRANSACTION_MANAGEMENT_VIEW + (t?.inventoryTransactionId ?? t?.bulkOrderId), {
          state: { filterPreset: filterPreset, filter: filter, bulkOrder: showBulkTransactions },
        }),
    };

    let output: ActionButton[] = [viewEdit];

    if (
      (t.status === TransactionStatusEnum.Pending || t.status === TransactionStatusEnum.Processed) &&
      t.transactionType !== TransactionTypeEnum.Sell &&
      t.transactionType !== TransactionTypeEnum.Purchase &&
      t.account?.enabled
    ) {
      output.push(nextStep);
    }

    if (t.status === TransactionStatusEnum.Complete && t.account?.enabled) {
      output.push(adjust);
    }

    return output;
  };

  const handleNextStep = async (t: InventoryTransaction) => {
    if (t.transactionType === TransactionTypeEnum.Withdraw) {
      navigate(ROUTE_PATHS.APP_TRANSACTION_MANAGEMENT_WITHDRAW + (t.inventoryTransactionId ?? t.bulkOrderId), {
        state: {
          transactionManagementFilterPreset: filterPreset,
          filter: filter,
          bulkOrder: showBulkTransactions && t.bulkOrderId,
        },
      });
    } else if (t.transactionType === TransactionTypeEnum.Discard) {
      navigate(ROUTE_PATHS.APP_INVENTORY_MANAGEMENT_DISCARD + '/' + t.inventoryTransactionId, {
        state: { transactionManagementFilterPreset: filterPreset, filter: filter },
      });
    } else if (t.transactionType === TransactionTypeEnum.Deposit) {
      navigate(ROUTE_PATHS.APP_INVENTORY_MANAGEMENT_COLLECT_DEPOSIT, {
        state: { ...t, transactionManagementFilterPreset: filterPreset, filter: filter },
      });
    } else if (t.transactionType === TransactionTypeEnum.Use && t.status === TransactionStatusEnum.Pending) {
      if (await pendingUseHasCheckout(t)) {
        navigate(ROUTE_PATHS.APP_INVENTORY_MANAGEMENT_CHECK_IN, {
          state: { ...t, transactionManagementFilterPreset: filterPreset, filter: filter },
        });
      } else {
        navigate(ROUTE_PATHS.APP_INVENTORY_MANAGEMENT_CHECK_OUT, {
          state: { ...t, transactionManagementFilterPreset: filterPreset, filter: filter },
        });
      }
    }
  };

  const loadData = async () => {
    callNumber.current++;
    if (hasAllResults.current) return;
    const callNum = callNumber.current;

    try {
      setLoading(true);

      const oDataParams = StandardParamsToODataParams({
        include: showBulkTransactions ? 'Account' : 'Specimen.Animal.Owners.Account',
        filter: `${
          !showBulkTransactions ? (filter ? filter.concat(' and ', 'BulkOrderId eq null') : 'BulkOrderId eq null') : filter
        }`,
        sort: '^' + 'CreatedDatetime',
        limit: transactionFetchLimit,
        offset: offset,
      });

      const { data: response } = showBulkTransactions
        ? await getBulkTransactionsOdata(oDataParams)
        : await getTransactionsOdata(oDataParams);

      if (offset === 0 && 'current' in ref) {
        ref.current?.scroll({ top: 0 });
      }

      if (callNum === callNumber.current) {
        const newTableData = offset > 0 ? [...transactionsData, ...response] : response;
        setTransactionsData(newTableData);
        hasAllResults.current = response.length === 0;
      }
    } catch (error: any) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    hasAllResults.current = false;
    setTransactionsData([]);
    if (offset === 0) {
      window.innerWidth <= 767 && loadData();
    } else {
      setOffset(0);
      if ('current' in ref) {
        ref.current?.scroll({ top: 0 });
      }
    }
  }, [filter, showBulkTransactions]);

  useEffect(() => {
    window.innerWidth <= 767 && loadData();
  }, [offset]);

  useEffect(() => {
    window.addEventListener('touchmove', handleScroll);
    return () => window.removeEventListener('touchmove', handleScroll);
  }, []);

  useEffect(() => {
    isLoadingAdditionalPage.current = false;
  }, [transactionsData]);

  const loadMore = () => {
    setOffset(prevState => prevState + transactionFetchLimit);
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    const position = scrollHeight - scrollTop;
    const atBottom = position - clientHeight <= clientHeight;

    if (atBottom && !isLoadingAdditionalPage.current) {
      isLoadingAdditionalPage.current = true;
      loadMore();
    }
  };

  const getAccount = (account: Account) => {
    if (account) {
      return (
        <>
          {account.name}
          {account.outstandingBalanceCents != undefined &&
          account.penaltyCents != undefined &&
          (account.outstandingBalanceCents > 0 || account.penaltyCents > 0) ? (
            <label className={'account-warning'}> {`(${LABEL_CONSTANTS.DUE_PAYMENT})`}</label>
          ) : !account.enabled ? (
            <label className={'account-warning'}> {`(${LABEL_CONSTANTS.DEACTIVATED})`}</label>
          ) : (
            <></>
          )}
        </>
      );
    }
  };

  const modifySortParam = (params: StandardParams) => {
    if (
      (showBulkTransactions &&
        (params?.sort?.includes('InventoryTransactionId') ||
          (params?.sort?.includes('TransactionLinkId') && !params?.sort?.includes('bulkTransactionLinkId')))) ||
      (!showBulkTransactions && (params?.sort?.includes('bulkOrderId') || params?.sort?.includes('bulkTransactionLinkId')))
    ) {
      return '^CreatedDatetime';
    }

    return params?.sort;
  };

  return (
    <div className="transaction-management">
      <TransactionsList
        header={PAGE_HEADER_CONSTANTS.TRANSACTION_MANAGEMENT}
        searchCriteriaOptions={searchCriteriaOptions}
        setFilter={setFilter}
        setFilterPreset={setFilterPreset}
        filterPreset={filterPreset}
        showBulkTransactions={showBulkTransactions}
        onToggleShowBulkTransactions={() => {
          setShowBulkTransactions(!showBulkTransactions);
          setRefresh(refresh => refresh + 1);
        }}
        isOData
        includeAdminFilters>
        <div className="desk-transaction-management">
          <PagedTable
            headers={[
              {
                displayName: `${TABLE_HEADER_CONSTANTS.ID}`,
                sortValue: showBulkTransactions ? 'bulkOrderId' : 'InventoryTransactionId',
              },
              { displayName: `${TABLE_HEADER_CONSTANTS.LINK_ID}`, sortValue: showBulkTransactions ? '' : 'TransactionLinkId' },
              { displayName: `${TABLE_HEADER_CONSTANTS.ACCOUNT_OWNER}` },
              { displayName: `${TABLE_HEADER_CONSTANTS.TYPE}`, sortValue: 'TransactionType' },
              { displayName: `${TABLE_HEADER_CONSTANTS.DATE}`, sortValue: 'CreatedDatetime' },
              { displayName: `${TABLE_HEADER_CONSTANTS.STATUS}`, sortValue: 'Status' },
              { displayName: `${TABLE_HEADER_CONSTANTS.ACTION}` },
            ]}
            getData={params => {
              return showBulkTransactions
                ? getBulkTransactionsOdata(
                    StandardParamsToODataParams({ ...params, include: 'Account', sort: modifySortParam(params) }),
                  )
                : getTransactionsOdata(
                    StandardParamsToODataParams({
                      ...params,
                      filter: `${params?.filter ? params?.filter.concat(' and ', 'BulkOrderId eq null') : 'BulkOrderId eq null'}`,
                      sort: modifySortParam(params),
                    }),
                  );
            }}
            sortBy="CreatedDatetime"
            sortDescByDefault
            filter={filter}
            include="Specimen.Animal.Owners.Account"
            height={500}
            minHeight={156}
            pageSize={10}
            scrollBufferThickness={100}
            refresh={refresh}
            buildRow={t => {
              return [
                t?.inventoryTransactionId ?? t?.bulkOrderId,
                t.transactionLinkId,
                getAccount(t.account),
                t.transactionType,
                new Date(t.createdDatetime).toLocaleDateString(),
                t.status,
                <ActionMenu actionButtons={handleActionButtons(t)} />,
              ];
            }}
          />
        </div>

        {/** Mobile View */}
        <div className="xs-transaction-management">
          {!loading && transactionsData.length === 0 ? (
            <div className="result-item">{`${LABEL_CONSTANTS.NO_RESULTS_FOUND}.`}</div>
          ) : (
            transactionsData &&
            transactionsData.map((transaction, index) => {
              return (
                <div className="card max-width" key={'transaction-' + transaction.inventoryTransactionId + index}>
                  <div className="transaction-management-card">
                    <div className="transaction-management-content">
                      <div className="transaction-info">
                        <div className="transaction-content">
                          <label>{`${TABLE_HEADER_CONSTANTS.ID} : `}</label>
                          <label>{`${transaction?.inventoryTransactionId ?? transaction?.bulkOrderId ?? '-'}`}</label>
                        </div>
                        <div className="transaction-content">
                          <label>{`${TABLE_HEADER_CONSTANTS.LINK_ID} : `}</label>
                          <label>{`${transaction.transactionLinkId ?? '-'}`}</label>
                        </div>
                        <div className="transaction-content">
                          <label>{`${TABLE_HEADER_CONSTANTS.ACCOUNT_OWNER} : `}</label>
                          <label>
                            {transaction.account?.name ?? '-'}
                            {transaction.account?.outstandingBalanceCents != undefined &&
                            transaction.account?.penaltyCents != undefined &&
                            (transaction.account?.outstandingBalanceCents > 0 || transaction.account?.penaltyCents > 0) ? (
                              <>
                                <label className={'account-warning'}> {`(${LABEL_CONSTANTS.DUE_PAYMENT})`}</label>
                              </>
                            ) : transaction.account?.enabled && !transaction.account?.enabled ? (
                              <label className={'account-warning'}> {`(${LABEL_CONSTANTS.DEACTIVATED})`}</label>
                            ) : (
                              <></>
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="transaction-info">
                        <div className="transaction-content">
                          <label>{`${TABLE_HEADER_CONSTANTS.TYPE} : `}</label>
                          <label>{`${transaction.transactionType ?? '-'}`}</label>
                        </div>
                        <div className="transaction-content">
                          <label>{`${TABLE_HEADER_CONSTANTS.DATE} : `}</label>
                          <label>{`${new Date(transaction.createdDatetime).toLocaleDateString() ?? '-'}`}</label>
                        </div>
                        <div className="transaction-content">
                          <label>{`${TABLE_HEADER_CONSTANTS.STATUS} : `}</label>
                          <label>{`${transaction.status ?? '-'}`}</label>
                        </div>
                      </div>
                    </div>
                    <div className="action-button">
                      <ActionMenu actionButtons={handleActionButtons(transaction)} />
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        {loading && <Loader addedSpace loaderSize="small" />}
      </TransactionsList>
    </div>
  );
};

export default TransactionManagement;
