import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import AnimalInfoForm from '../AnimalInfoForm';
import OwnershipOverview, { OwnerPercentage } from '../OwnershipOverview';
import UploadAttachmentsModal from '../../../components/upload-image-modal/UploadAttachmentsModal';
import UploadImage from '../../../components/svgs/UploadImage.svg';
import Loader from '../../../components/loader/Loader';
import { PromiseError, apiErrorTitles, toastMessages } from '../../../constants/errorMessages';
import { ROUTE_PATHS } from '../../../constants/routePaths';
import { ANIMAL_CONSTANTS, BUTTON_CONSTANTS } from '../../../constants/common';
import { Animal, AnimalOwner } from '../../../types/interfaces/animal.interfaces';
import { Genders } from '../../../types/enums/genders.enum';
import { Account } from '../../../types/interfaces/account.interfaces';
import { getAccounts } from '../../../api/accountApi';
import { deleteMultipleAnimalAttachments, editAnimal, uploadAnimalAttachment } from '../../../api/animalsApi';
import { getAnimalAttachmentURL, requestAnimalOwnersBreedAndSpeciesById } from '../../../services/animal.service';
import { showToast } from '../../../services/toast.service';
import './editAnimal.scss';

const EditAnimal = (): JSX.Element => {
  const { animalId } = useParams();
  const [validate, setValidate] = useState<boolean>(false);
  const [animalFormIsValid, setAnimalFormIsValid] = useState<boolean>(false);
  const [ownerPercentages, setOwnerPercentages] = useState<OwnerPercentage[]>([]);
  const [ownershipValid, setOwnershipValid] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [stateWasModified, setStateWasModified] = useState(false);
  const [isUploadImageModalOpen, setIsUploadImageModalOpen] = useState<boolean>(false);
  const [updatingAnimal, setUpdatingAnimal] = useState<boolean>(true);
  const [animalInfo, setAnimalInfo] = useState<Animal>({
    name: '',
    gender: Genders.Default,
    birthDate: undefined,
    sire: '',
    dam: '',
    dna: '',
    code: '',
    registration: '',
    description: '',
    owners: [],
    breed: { name: '', species: { name: '' } },
  });

  const navigate = useNavigate();

  const updateAnimal = async () => {
    if (!updatingAnimal) return;

    setUpdatingAnimal(false);

    if (checkValidity()) {
      const owners: AnimalOwner[] = ownerPercentages.map(owner => {
        return {
          accountId: Number(owner.id),
          ownershipPercentage: Number(owner.percentage),
        };
      });
      const animalRequest: Animal = {
        animalId: Number(animalId),
        name: animalInfo.name,
        code: animalInfo.code,
        dna: animalInfo.dna,
        registration: animalInfo.registration,
        gender: animalInfo.gender,
        birthDate: animalInfo.birthDate,
        sire: animalInfo.sire,
        dam: animalInfo.dam,
        description: animalInfo.description,
        breedId: animalInfo.breed?.breedId,
        owners: owners,
      };

      if (animalFormIsValid && ownershipValid) {
        try {
          await editAnimal(animalId!, animalRequest);
          setValidate(false);
          showToast.success(toastMessages.ANIMAL_EDITED);
          handleSubmitNavigation();
        } catch (error: any) {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        } finally {
          setUpdatingAnimal(true);
        }
      } else {
        setValidate(true);
      }
    } else {
      setUpdatingAnimal(true);
      setValidate(true);
    }
  };

  const getAnimalInfo = useCallback(async () => {
    try {
      const reqAnimal = await requestAnimalOwnersBreedAndSpeciesById(animalId!);
      setAnimalInfo(reqAnimal);
      const animalAccountIds = reqAnimal.owners?.map(owner => owner.accountId) as number[];

      if (animalAccountIds.length !== 0) {
        const ownerList = await getAccounts(animalAccountIds);

        const animalOwners: OwnerPercentage[] = (ownerList.data as Account[]).map((owner: Account) => {
          return {
            id: owner.accountId?.toString()!,
            name: owner.name!,
            email: owner.contactEmail!,
            percentage: reqAnimal.owners?.find(animalOwner => animalOwner.accountId === owner.accountId)?.ownershipPercentage!,
          };
        });
        setOwnerPercentages(animalOwners);
      }
      setIsLoading(false);
    } catch (error: any) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  }, [animalId]);

  useEffect(() => {
    getAnimalInfo();
  }, [getAnimalInfo]);

  function checkValidity(): boolean {
    return stateWasModified && animalFormIsValid && ownershipValid;
  }

  const handlePostImage = async (image: FormData) => {
    try {
      if (!animalId) throw new Error('Animal id is not defined');
      await uploadAnimalAttachment(animalId, image);
      setIsUploadImageModalOpen(false);
      showToast.success(toastMessages.ANIMAL_IMAGE_ADDED);
    } catch (error: any) {
      const errorTitle = error.response.data.title;
      if (errorTitle === apiErrorTitles.BAD_FILE_TYPE) {
        showToast.error(toastMessages.INVALID_FILE_TYPE);
      } else if (error.code === PromiseError.NETWORK) {
        showToast.error(toastMessages.NETWORK);
      } else {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
      showToast.error(toastMessages.FAILED_TO_ADD_ANIMAL_IMAGE);
    }
  };

  const handleDeleteAttachments = async (attachmentIds: number[]) => {
    if (!animalId) throw new Error('Animal id is not defined');
    try {
      await deleteMultipleAnimalAttachments(animalId, attachmentIds);
    } catch {
      showToast.error(toastMessages.FAILED_TO_REMOVE_ANIMAL_IMAGE);
    }
  };

  const handleSubmitNavigation = async () => {
    navigate(`${ROUTE_PATHS.APP_ANIMAL_MANAGEMENT_}${animalId}`);
  };

  return (
    <div className="edit-animal">
      <div className="card">
        {isLoading ? (
          <Loader loaderSize={'small'} simple />
        ) : (
          <>
            <UploadAttachmentsModal
              isOpen={isUploadImageModalOpen}
              onClose={() => setIsUploadImageModalOpen(false)}
              postAttachments={data => handlePostImage(data)}
              existingAttachments={animalInfo.attachments ?? []}
              animalId={animalId!}
              handleDeleteAttachments={attachmentIds => handleDeleteAttachments(attachmentIds)}
              handleSubmitNavigation={handleSubmitNavigation}
            />
            <div className="row">
              <h3>{ANIMAL_CONSTANTS.ANIMAL_INFO}</h3>
            </div>
            <div className="wrapper">
              {animalInfo && animalId && animalInfo.attachments ? (
                <div className="animal-photos">
                  <div className="animal-main-photo">
                    <div className="upload-image-button" onClick={() => setIsUploadImageModalOpen(true)}>
                      <UploadImage className="upload-image-icon" />
                    </div>
                    {animalInfo.attachments.length > 0 ? (
                      <img
                        src={getAnimalAttachmentURL(
                          animalId,
                          animalInfo.attachments[0].attachmentId,
                          animalInfo.attachments[0].accessToken,
                        )}
                        alt="Animal Photo"
                      />
                    ) : (
                      <div className="no-animal-image">{ANIMAL_CONSTANTS.NO_IMAGE}</div>
                    )}
                  </div>
                  {animalInfo.attachments.length > 1 && (
                    <div className="animal-photos-row">
                      {animalInfo.attachments.map((attachment, index) => {
                        if (index > 0) {
                          return (
                            <img
                              key={attachment.attachmentId}
                              src={getAnimalAttachmentURL(animalId, attachment.attachmentId, attachment.accessToken)}
                              alt="Animal Photo"
                            />
                          );
                        }
                        return null;
                      })}
                    </div>
                  )}
                </div>
              ) : (
                <div className="specimen-image">{ANIMAL_CONSTANTS.NO_IMAGE}</div>
              )}
              <AnimalInfoForm
                animalInfo={animalInfo}
                setAnimalInfo={setAnimalInfo}
                validate={validate}
                setValid={setAnimalFormIsValid}
                startsValid={true}
                setStateWasModified={setStateWasModified}
              />
            </div>
            <div className="divider" />
            <OwnershipOverview
              ownerPercentages={ownerPercentages}
              setOwnerPercentages={setOwnerPercentages}
              setValid={setOwnershipValid}
              setStateWasModified={setStateWasModified}
            />
            <div className="buttons">
              <button
                onClick={() => navigate(`${ROUTE_PATHS.APP_ANIMAL_MANAGEMENT_}${animalId}`)}
                className="small green button inverted">
                {BUTTON_CONSTANTS.CANCEL}
              </button>
              <button
                className={!checkValidity() ? 'small green button update-button disabled' : 'button small green update-button'}
                onClick={() => updateAnimal()}>
                {BUTTON_CONSTANTS.UPDATE}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EditAnimal;
