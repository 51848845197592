import { Route, Routes } from 'react-router-dom';
import { APPLICATION_TEAM_PATHS } from '../constants/routePaths';
import AdminEditTeam from '../pages/application-team/AdminEditTeam';

const ApplicationTeamRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path={APPLICATION_TEAM_PATHS.EDIT} element={<AdminEditTeam />} />
      </Routes>
    </div>
  );
};

export default ApplicationTeamRoutes;
