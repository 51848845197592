import { AxiosResponse } from 'axios';
import { INVENTORY_API_ENDPOINTS } from '../constants/apiEndpoints';
import { appContextGet, appContextPost, appContextPut } from './httpCommon/appAxios.service';
import {
  AdjustRequestBody,
  TransactionForExistingUserRequestBody,
  TransactionForNewUserRequestBody,
  UseRequestBody,
  WithdrawRequestBody,
  DiscardRequest,
  DiscardComplete,
  ReconcileInventoryBody,
  AdminAccountUse,
  ReconcileAdjustInventoryBody,
} from '../types/interfaces/inventoryTransaction.interfaces';
import { InventoryParams } from '../types/interfaces/apiParams.interfaces';
import {
  CheckIn,
  CheckOut,
  CollectApiRequestBody,
  CollectDepositBaseApiRequestBody,
  InventoryTransaction,
  MoveInventory,
} from '../types/interfaces/transaction.interfaces';

export const getCountByAnimal = (id: number, params?: InventoryParams): Promise<AxiosResponse<number, any>> => {
  return appContextGet(INVENTORY_API_ENDPOINTS.GET_ANIMALS_ + id, params);
};

export const getCountBySpecimen = (id: number, params?: InventoryParams): Promise<AxiosResponse<number, any>> => {
  return appContextGet(INVENTORY_API_ENDPOINTS.GET_SPECIMENS_ + id, params);
};

export const getCountBySpecimenType = (id: number, params?: InventoryParams): Promise<AxiosResponse<number, any>> => {
  return appContextGet(INVENTORY_API_ENDPOINTS.GET_TYPES_ + id, params);
};

export const getInventoryCount = (params?: InventoryParams): Promise<AxiosResponse<number, any>> => {
  return appContextGet(INVENTORY_API_ENDPOINTS.GET, params);
};

export const sellToExistingUser = (transactionForKnownUser: TransactionForExistingUserRequestBody): Promise<AxiosResponse> => {
  return appContextPost(INVENTORY_API_ENDPOINTS.SELL, transactionForKnownUser);
};

export const sellUseToExistingUser = (transactionForKnownUser: TransactionForExistingUserRequestBody): Promise<AxiosResponse> => {
  return appContextPost(INVENTORY_API_ENDPOINTS.SELL_USE, transactionForKnownUser);
};

export const sellToNewUser = (transaction: TransactionForNewUserRequestBody): Promise<AxiosResponse<any, any>> => {
  return appContextPost(INVENTORY_API_ENDPOINTS.SELL_TO_NEW_USER, transaction);
};

export const usesInventory = (transaction: UseRequestBody): Promise<AxiosResponse<any, any>> => {
  return appContextPut(
    INVENTORY_API_ENDPOINTS.USE,
    { specimens: transaction.specimenSelections, notes: transaction.notes },
    { accountId: transaction.accountId },
  );
};

export const withdrawInventory = (
  transaction: WithdrawRequestBody,
  accountId: string | number,
): Promise<AxiosResponse<any, any>> => {
  return appContextPost(INVENTORY_API_ENDPOINTS.WITHDRAW, transaction, { accountId: accountId });
};

export const redeemInventory = (
  inventoryTransaction: InventoryTransaction[],
  buyerAccountId: number,
): Promise<AxiosResponse<{}, any>> => {
  return appContextPut(INVENTORY_API_ENDPOINTS.REDEEM, inventoryTransaction, {
    buyerAccountId: buyerAccountId,
  });
};

export const inventoryDeposit = (
  accountId: number,
  quantity: number,
  notes: string,
): Promise<AxiosResponse<InventoryTransaction, any>> => {
  return appContextPost(INVENTORY_API_ENDPOINTS.DEPOSIT, notes, {
    accountId,
    quantity,
  });
};

export const checkIn = (checkInValues: CheckIn, params: { accountId: number }): Promise<AxiosResponse<number, any>> => {
  return appContextPut(INVENTORY_API_ENDPOINTS.CHECK_IN, checkInValues, params);
};

export const requestCheckOut = (checkOutValues: CheckOut, params: { accountId: number }): Promise<AxiosResponse<number, any>> => {
  return appContextPut(INVENTORY_API_ENDPOINTS.CHECK_OUT, checkOutValues, params);
};

export const collectPost = (collectValues: CollectApiRequestBody): Promise<AxiosResponse<number, any>> => {
  return appContextPost(INVENTORY_API_ENDPOINTS.COLLECT, collectValues);
};

export const depositPut = (
  depositValues: CollectDepositBaseApiRequestBody,
  inventoryTransactionId: number,
): Promise<AxiosResponse<number, any>> => {
  return appContextPut(INVENTORY_API_ENDPOINTS.DEPOSIT + inventoryTransactionId, depositValues);
};

export const requestMoveInventory = (moveInventoryValues: MoveInventory): Promise<AxiosResponse<number, any>> => {
  return appContextPut(INVENTORY_API_ENDPOINTS.MOVE, moveInventoryValues);
};

export const discard = (accountId: number, specimens: DiscardRequest[], notes: string): Promise<AxiosResponse<number, any>> => {
  return appContextPost(
    INVENTORY_API_ENDPOINTS.DISCARD,
    {
      specimens,
      notes,
    },
    { accountId },
  );
};

export const completeDiscard = (
  inventoryTransactionId: number,
  specimens: DiscardComplete[],
  notes: string,
): Promise<AxiosResponse<number, any>> => {
  return appContextPut(INVENTORY_API_ENDPOINTS.DISCARD + '/' + inventoryTransactionId, {
    specimens,
    notes,
  });
};

export const adjustPut = (body: AdjustRequestBody, accountId: number): Promise<AxiosResponse<number, any>> => {
  return appContextPut(INVENTORY_API_ENDPOINTS.ADJUST, body, { accountId: accountId });
};

export const bulkOrderAdjustPut = (body: AdjustRequestBody[], accountId: number, notes: string) => {
  return appContextPut(INVENTORY_API_ENDPOINTS.BULK_ADJUST, body, { accountId, notes });
};

export const reconcileInventory = (body: ReconcileInventoryBody): Promise<AxiosResponse<number, any>> => {
  return appContextPost(INVENTORY_API_ENDPOINTS.RECONCILE, body);
};

export const adminAccountUse = (accountId: number | string, uses: AdminAccountUse): Promise<AxiosResponse<number, any>> => {
  return appContextPut(INVENTORY_API_ENDPOINTS.USE + '/' + accountId, uses);
};

export const reconcileAdjustInventory = (body: ReconcileAdjustInventoryBody): Promise<AxiosResponse<number, any>> => {
  return appContextPost(INVENTORY_API_ENDPOINTS.RECONCILE_ADJUST, body);
};

export const checkZeroVariance = (specimenId: number): Promise<AxiosResponse<boolean, any>> => {
  return appContextGet(INVENTORY_API_ENDPOINTS.CHECK_ZERO_VARIANCE_ + specimenId);
};
