import { FC, useEffect, useState } from 'react';
import Loader from '../loader/Loader';
import { SelectedSpecimenQuantity } from './SelectSpecimens';
import { TransactionTypeEnum } from '../../types/enums';
import { Specimen, SpecimenLocation } from '../../types/interfaces';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';
import { LABEL_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../constants/common';
import './itemStorageLocations.scss';

type ItemStorageLocationsProps = {
  specimen: Specimen;
  transactionType: TransactionTypeEnum.Withdraw | TransactionTypeEnum.Discard;
  quantities: SelectedSpecimenQuantity[];
  setQuantities: React.Dispatch<React.SetStateAction<SelectedSpecimenQuantity[]>>;
};

const ItemStorageLocations: FC<ItemStorageLocationsProps> = ({ specimen, transactionType, quantities, setQuantities }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { specimenLocations } = specimen;

  const headers: string[] = [
    TABLE_HEADER_CONSTANTS.SELECT,
    TABLE_HEADER_CONSTANTS.LOCATION,
    TABLE_HEADER_CONSTANTS.ANIMAL,
    TABLE_HEADER_CONSTANTS.QTY_IN_CANISTER,
    `${transactionType === TransactionTypeEnum.Discard ? TransactionTypeEnum.Discard : TransactionTypeEnum.Withdraw} Qty`,
  ];

  useEffect(() => {
    if (!specimenLocations) return;
    setQuantities(
      specimenLocations.map(location => {
        return {
          selectedCanisterId: location.storageCanisterId,
          quantity: 0,
          availableQuantity: location.quantity,
          selected: false,
          transactionLinkId: location.transactionLinkId,
          specimenId: specimen.specimenId,
        };
      }),
    );
  }, [specimenLocations, specimen.specimenId, setQuantities]);

  const handleErrors = (index: number) => {
    if (quantities?.length && quantities[index] && specimenLocations?.length && quantities[index].selected) {
      if (quantities[index]?.quantity > specimenLocations[index]?.quantity) {
        showToast.error(toastMessages.CHOSE_MORE_THAN_TOTAL);
        return 'error';
      } else if (quantities[index]?.quantity === 0) {
        return 'warning';
      } else {
        return '';
      }
    }
  };

  const handleSelected = (changeEvent: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let newQuantities = [...quantities];
    newQuantities[index] = {
      ...quantities[index],
      selected: changeEvent.target.checked,
      quantity: changeEvent.target.checked ? quantities[index].quantity : 0,
    };

    setQuantities(newQuantities);
  };

  const handleQuantities = (changeEvent: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let newQuantities = [...quantities];
    newQuantities[index] = {
      ...quantities[index],
      quantity: isNaN(Number(changeEvent.target.value)) ? 0 : Number(changeEvent.target.value),
    };
    setQuantities(newQuantities);
  };

  const totalQuantity = (): number => {
    if (quantities && quantities.length) {
      return quantities
        .filter(q => q.selected)
        .reduce((acc: number, qua: SelectedSpecimenQuantity): number => acc + qua.quantity, 0);
    } else {
      return 0;
    }
  };

  const renderTableRows = (location: SpecimenLocation, index: number) => {
    const isTravelingTank = location.storageCanister?.storageTank?.isTravelingTank;
    const rowClass = `${handleErrors(index)} ${isTravelingTank ? 'disabled' : ''}`;
    const locationPath = `${location.storageCanister?.storageTank?.storageSite?.name}/${location.storageCanister?.storageTank?.name}/${location.storageCanister?.name}`;
    const animalDetails = `${specimen?.animal?.code} - ${specimen?.animal?.name}`;

    return (
      <tr key={location.transactionLinkId + index} className={rowClass}>
        <td>
          <input
            type="checkbox"
            id={`location-selected-${location.transactionLinkId}`}
            checked={quantities[index]?.selected ?? false}
            onChange={e => handleSelected(e, index)}
            disabled={isTravelingTank}
          />
        </td>
        <td>{locationPath}</td>
        <td>{animalDetails}</td>
        <td>{location.quantity}</td>
        <td>
          {isTravelingTank ? (
            LABEL_CONSTANTS.TRAVELING_TANK
          ) : (
            <input
              type="text"
              placeholder="0"
              value={quantities[index]?.selected ? (quantities[index].quantity > 0 ? quantities[index].quantity : '') : '-'}
              onChange={e => handleQuantities(e, index)}
              disabled={!quantities[index]?.selected}
            />
          )}
        </td>
      </tr>
    );
  };

  const renderMobileRow = (location: SpecimenLocation, index: number) => {
    const isTravelingTank = location.storageCanister?.storageTank?.isTravelingTank;
    const locationPath = `${location.storageCanister?.storageTank?.storageSite?.name} / ${location.storageCanister?.storageTank?.name} / ${location.storageCanister?.name}`;
    const animalDetails = `${specimen?.animal?.code} - ${specimen?.animal?.name}`;

    return (
      <div
        key={location.transactionLinkId + index}
        className={`card max-width ${handleErrors(index)} ${isTravelingTank ? 'disabled' : ''}`}>
        <div className="item-storage-locations-row">
          <div className="input-check">
            <input
              type="checkbox"
              id={`location-selected-${index}`}
              checked={quantities[index]?.selected ?? false}
              onChange={e => handleSelected(e, index)}
              disabled={isTravelingTank}
            />
          </div>
          <div className="item-storage-locations-content">
            <label>{locationPath}</label>
            <label>{animalDetails}</label>
            <label>{`${TABLE_HEADER_CONSTANTS.QTY_IN_CANISTER} : ${location.quantity}`}</label>
          </div>
          <div className="number-input-section">
            {isTravelingTank ? (
              <label>{LABEL_CONSTANTS.TRAVELING_TANK}</label>
            ) : (
              <>
                <label>{`${transactionType} Qty`}</label>
                <input
                  type="text"
                  placeholder="0"
                  value={quantities[index]?.selected ? (quantities[index].quantity > 0 ? quantities[index].quantity : '') : '-'}
                  onChange={e => handleQuantities(e, index)}
                  disabled={!quantities[index]?.selected}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="item-storage-locations">
      {loading ? (
        <Loader addedSpace simple loaderSize={'small'} />
      ) : specimenLocations?.length ? (
        <>
          <div className="desk-item-storage-locations">
            <div className="specimen-table">
              <table>
                <thead>
                  <tr>
                    {headers.map(header => (
                      <th key={header}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {specimenLocations.map((location, index) => renderTableRows(location, index))}
                  <tr>
                    <td colSpan={4}></td>
                    <td colSpan={1}>
                      <div className="total-selected-quantity">
                        <p>{totalQuantity()}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="xs-item-storage-locations">
            {specimenLocations.map((location, index) => renderMobileRow(location, index))}
            <div className="total-quantity">
              <label>{LABEL_CONSTANTS.TOTAL_QUANTITY_SELECTED}: &nbsp; </label>
              {quantities.reduce(
                (acc: number, qua: SelectedSpecimenQuantity): number => (qua.selected ? acc + qua.quantity : acc),
                0,
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="no-locations-available">
          <h2>{LABEL_CONSTANTS.NO_LOCATIONS_AVAILABLE}</h2>
        </div>
      )}
    </div>
  );
};

export default ItemStorageLocations;
