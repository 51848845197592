import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Loader, FilterDropdown, BackButton, NumberInput } from '../../components';
import { AlertSVG } from '../../components/svgs';
import { FilterOption } from '../../components/custom-input/FilterDropdown';
import TransactionLabelField from '../../components/transaction/TransactionLabelField';
import { dateFormatMMDDYYYY, splitStringWithoutBreakingWords, usDateFormatMMDDYYYY } from '../../utils/commonUtils';
import { showToast } from '../../services/toast.service';
import { getBulkTransactionById } from '../../api/bulkTransactionApi';
import {
  completeBulkWithdraw,
  completeWithdraw,
  downloadSpecimenLocations,
  getInventoryTransactionById,
  getInventoryTransactionLocations,
  removeInventoryTransactionLocations,
  storeInventoryTransactionLocations,
} from '../../api/inventoryTransactionsApi';
import { getSpecimenLocations } from '../../api/specimensApi';
import { TransactionResolutionEnum, TransactionStatusEnum, TransactionTypeEnum } from '../../types/enums';
import {
  BulkTransaction,
  InputShippingAddress,
  InventoryTransaction,
  InventoryTransactionLocation,
  NotesInfo,
  SpecimenLocation,
} from '../../types/interfaces';
import {
  BulkOrderSelectedSpecimen,
  GeneticsMovementAccountInfo,
  SpecimenQuantity,
} from '../../types/interfaces/bulkOrder.interfaces';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import {
  BUTTON_CONSTANTS,
  LABEL_CONSTANTS,
  PAGE_HEADER_CONSTANTS,
  TABLE_HEADER_CONSTANTS,
  TRANSACTION_CONSTANTS,
  VALIDATION_ALERT_CONSTANTS,
} from '../../constants/common';
import './transactionManagement.scss';

const TransactionWithdraw = () => {
  const { state: locationState } = useLocation();
  const { transactionId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resolution, setResolution] = useState<FilterOption<string>>({ name: '', value: '' });
  const [notes, setNotes] = useState<string>('');
  const [validate, setValidate] = useState<boolean>(false);
  const [bulkOrderTransaction, setBulkOrderTransaction] = useState<BulkTransaction>();
  const [associatedInventoryTrans, setAssociatedInventoryTrans] = useState<BulkOrderSelectedSpecimen[]>([]);
  const [statusUpdated, setStatusUpdated] = useState<boolean>();
  const [completingWithdraw, setCompletingWithdraw] = useState<boolean>(true);

  const resolutionOptions: FilterOption<string>[] = [
    { name: TransactionResolutionEnum.Shipped, value: TransactionResolutionEnum.Shipped },
    { name: 'Picked Up', value: TransactionResolutionEnum.Pickup },
  ];

  const specimenHeaders: string[] = [
    TABLE_HEADER_CONSTANTS.SELECT,
    TABLE_HEADER_CONSTANTS.ANIMAL,
    TABLE_HEADER_CONSTANTS.LOT_DATE_NO,
    TABLE_HEADER_CONSTANTS.QTY_TO_WITHDRAW,
  ];

  const locationHeaders: string[] = [
    TABLE_HEADER_CONSTANTS.SELECT,
    TABLE_HEADER_CONSTANTS.LOCATION,
    TABLE_HEADER_CONSTANTS.ANIMAL,
    TABLE_HEADER_CONSTANTS.LOT_DATE_NO,
    TABLE_HEADER_CONSTANTS.QTY_IN_CANISTER,
    TABLE_HEADER_CONSTANTS.WITHDRAW_QTY,
  ];

  const totalSelectedQuantity: number = associatedInventoryTrans.reduce(
    (acc, specimen) =>
      acc + (specimen.selected ? specimen.specimenLocations?.reduce((locAcc, loc) => locAcc + loc.quantity, 0) ?? 0 : 0),
    0,
  );

  useEffect(() => {
    setIsLoading(true);
    locationState.bulkOrder ? handleBulkOrderInventoryTransaction() : handleInventoryTransaction();
  }, [transactionId, statusUpdated]);

  const handleBulkOrderInventoryTransaction = async () => {
    try {
      const { data: bulkInventoryTransaction } = await getBulkTransactionById(+transactionId!, {
        include: 'Account,inventoryTransactions,inventoryTransactions.Specimen.Animal',
      });

      const transactions =
        bulkInventoryTransaction.inventoryTransactions &&
        (await fetchTransactionsWithLocations(bulkInventoryTransaction?.inventoryTransactions));

      if (transactions) {
        if (bulkInventoryTransaction?.status === TransactionStatusEnum.Processed) {
          const { data: inventoryTransactionLocations } = await getInventoryTransactionLocations({
            bulkOrderId: Number(transactionId),
          });

          const updatedTransactions = mapInventoryTransactionLocations(inventoryTransactionLocations, transactions);
          setAssociatedInventoryTrans(updatedTransactions);
        } else {
          setAssociatedInventoryTrans(transactions);
        }
      }

      setBulkOrderTransaction(bulkInventoryTransaction);
      setNotes(bulkInventoryTransaction.notes ?? '');
      setResolution({ name: bulkInventoryTransaction.resolution, value: bulkInventoryTransaction.resolution });
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInventoryTransaction = async () => {
    try {
      const { data: inventoryTransaction } = await getInventoryTransactionById(+transactionId!, {
        include: 'Account, Specimen.Animal',
      });

      const inventoryTransactions = {
        account: inventoryTransaction.account!,
        accountId: inventoryTransaction.accountId,
        createdDatetime: inventoryTransaction.createdDatetime,
        inventoryTransactions: [inventoryTransaction],
        modifiedBy: inventoryTransaction.modifiedBy,
        modifiedDatetime: inventoryTransaction.modifiedDatetime,
        notes: inventoryTransaction.notes ?? '',
        resolution: inventoryTransaction.resolution,
        status: inventoryTransaction.status,
        totalQuantity: inventoryTransaction.changeQuantity,
        transactionType: inventoryTransaction.transactionType,
        breedDate: inventoryTransaction.breedDate,
      };

      const transactions = await fetchTransactionsWithLocations([inventoryTransaction]);

      if (inventoryTransactions?.status === TransactionStatusEnum.Processed) {
        const { data: inventoryTransactionLocations } = await getInventoryTransactionLocations({
          inventoryTransactionId: Number(transactionId),
        });

        const updatedTransactions = mapInventoryTransactionLocations(inventoryTransactionLocations, transactions!);
        setAssociatedInventoryTrans(updatedTransactions);
      } else {
        setAssociatedInventoryTrans(transactions);
      }

      setBulkOrderTransaction(inventoryTransactions);
      setNotes(inventoryTransactions.notes ?? '');
      setResolution({ name: inventoryTransactions.resolution, value: inventoryTransactions.resolution });
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTransactionsWithLocations = async (transactions: InventoryTransaction[]) => {
    return await Promise.all(
      transactions.map(async transaction => {
        const query = {
          include: 'Specimen.Animal, Specimen.SpecimenLocations.StorageCanister.StorageTank.StorageSite',
          filter: `specimenId eq ${transaction.specimenId}`,
        };
        const specimenLocations = (await getSpecimenLocations(query)).data;
        return mapInventoryTransaction(transaction, specimenLocations);
      }),
    );
  };

  const mapInventoryTransaction = (
    transaction: InventoryTransaction,
    specimenLocations: SpecimenLocation[],
  ): BulkOrderSelectedSpecimen => {
    return {
      transactionId: transaction.inventoryTransactionId!,
      accountId: transaction.accountId,
      accountUserId: transaction.accountUserId,
      animalId: transaction.specimen?.animal?.animalId!,
      animalName: transaction.specimen?.animal?.name!,
      animalCode: transaction.specimen?.animal?.code!,
      specimenId: transaction.specimen?.specimenId!,
      quantityToWithdraw: String(Math.abs(transaction.changeQuantity!)),
      selected: false,
      specimenLocations: specimenLocations?.map(specimenLocation => {
        return {
          specimenId: specimenLocation.specimenId!,
          availableQuantity: specimenLocation.quantity,
          quantity: 0,
          selected: false,
          freezeDate: transaction.specimen?.freezeDate!,
          selectedCanisterId: specimenLocation.storageCanisterId!,
          specimenLocationId: specimenLocation.specimenLocationId!,
          storageCanister: specimenLocation.storageCanister!,
          transactionLinkId: specimenLocation.transactionLinkId,
        };
      }),
    };
  };

  const mapInventoryTransactionLocations = (
    inventoryTransactionLocations: InventoryTransactionLocation[],
    transactions: BulkOrderSelectedSpecimen[],
  ) => {
    let newTransactions = [...transactions];

    inventoryTransactionLocations.forEach((transactionLocation: InventoryTransactionLocation) => {
      let index = newTransactions.findIndex(transaction => transaction.specimenId === transactionLocation.specimenId);

      if (index !== -1) {
        newTransactions[index] = {
          ...newTransactions[index],
          selected: true,
          specimenLocations: newTransactions[index].specimenLocations?.map((location: SpecimenQuantity) => {
            return location.selectedCanisterId === transactionLocation.storageCanisterId
              ? { ...location, selected: true, quantity: transactionLocation.quantity }
              : { ...location };
          }),
        };
      }
    });

    return newTransactions;
  };

  const handleSelectedSpecimen = (changeEvent: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let newTransactions = [...associatedInventoryTrans];

    newTransactions[index] = {
      ...associatedInventoryTrans[index],
      selected: changeEvent.target.checked,
      specimenLocations: associatedInventoryTrans[index].specimenLocations?.map(location =>
        changeEvent.target.checked ? location : { ...location, selected: false, quantity: 0 },
      ),
    };

    setAssociatedInventoryTrans(newTransactions);
  };

  const handleLocationSelected = (changeEvent: React.ChangeEvent<HTMLInputElement>, id: number, index: number) => {
    let newTransactions = [...associatedInventoryTrans];

    let updatedTransactions = newTransactions.map(transaction => {
      if (transaction.transactionId === id && transaction.specimenLocations) {
        const updatedLocations = [...transaction.specimenLocations];
        updatedLocations[index] = {
          ...transaction.specimenLocations[index],
          selected: changeEvent.target.checked,
          quantity: changeEvent.target.checked ? transaction.specimenLocations[index].quantity : 0,
        };

        return { ...transaction, specimenLocations: updatedLocations };
      } else {
        return transaction;
      }
    });

    setAssociatedInventoryTrans(updatedTransactions);
  };

  const handleQuantities = (changeEvent: React.ChangeEvent<HTMLInputElement>, id: number, index: number) => {
    let newTransactions = [...associatedInventoryTrans];

    let updatedQuantities = newTransactions.map(transaction => {
      if (transaction.transactionId === id && transaction.specimenLocations) {
        const updatedLocationQuantities = [...transaction.specimenLocations];
        updatedLocationQuantities[index] = {
          ...transaction.specimenLocations[index],
          quantity: Number(changeEvent.target.value),
        };

        return { ...transaction, specimenLocations: updatedLocationQuantities };
      } else {
        return transaction;
      }
    });

    setAssociatedInventoryTrans(updatedQuantities);
  };

  const isAnySpecimenSelected = () => {
    return associatedInventoryTrans.some(specimen => specimen.selected);
  };

  const selectedQuantity = (specimenId: number) => {
    const selectedSpecimen = associatedInventoryTrans.find(specimen => specimen.specimenId === specimenId);
    return selectedSpecimen?.specimenLocations?.reduce((accumulator, location) => accumulator + location.quantity, 0) ?? 0;
  };

  const isValid = () => {
    return totalSelectedQuantity === Math.abs(bulkOrderTransaction?.totalQuantity!) && isQuantityValid();
  };

  const isQuantityValid = () => {
    return associatedInventoryTrans.every(specimen => {
      const areLocationsValid = specimen.specimenLocations?.every(location => {
        return !location.selected || location.quantity <= location.availableQuantity;
      });
      const totalSelectedQty = specimen.specimenLocations?.reduce((accumulator, location) => accumulator + location.quantity, 0);
      return specimen.selected && areLocationsValid && Number(specimen.quantityToWithdraw) === totalSelectedQty;
    });
  };

  const isWithdrawValid = () => {
    return associatedInventoryTrans.some(transaction => {
      const totalSelectedQty = transaction.specimenLocations?.reduce(
        (accumulator, location) => accumulator + location.quantity,
        0,
      );
      return totalSelectedQty != undefined && totalSelectedQty > Number(transaction.quantityToWithdraw);
    });
  };

  const printSpecimenLocations = async () => {
    if (!completingWithdraw) return;

    try {
      setCompletingWithdraw(false);

      if (isValid()) {
        const updatedTransactions = associatedInventoryTrans.map(transaction =>
          mapInventoryStorageLocations(transaction, bulkOrderTransaction?.status!),
        );

        const downloadSpecimenLocationsRequestBody = {
          bulkWithdrawFulfillment: { withdrawComplete: updatedTransactions },
          geneticsMovementAccountInfo: getGeneticsMovementAccountInfo(),
        };

        const response = await downloadSpecimenLocations(downloadSpecimenLocationsRequestBody);

        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const currentDate = dateFormatMMDDYYYY(new Date());
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${bulkOrderTransaction?.account?.name}_Gens_MovementReport_${currentDate}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setCompletingWithdraw(true);
    }
  };

  const handleProcessedStatus = async () => {
    if (!completingWithdraw) return;
    setCompletingWithdraw(false);
    setIsLoading(true);

    try {
      if (isValid()) {
        const updatedTransactions = associatedInventoryTrans.map(transaction =>
          mapInventoryStorageLocations(transaction, TransactionStatusEnum.Processed),
        );

        await storeInventoryTransactionLocations(updatedTransactions);
        showToast.success(toastMessages.MARKED_AS_PROCESSED);
        setStatusUpdated(true);
      }
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setCompletingWithdraw(true);
      setIsLoading(false);
    }
  };

  const getGeneticsMovementAccountInfo = () => {
    const notesInfo = extractNotesInfo(notes);

    const geneticsMovementAccountInfo: GeneticsMovementAccountInfo = {
      accountName: bulkOrderTransaction?.account?.name!,
      accountOwner: `${bulkOrderTransaction?.account?.contactFirstName} ${bulkOrderTransaction?.account?.contactLastName}`,
      phone: bulkOrderTransaction?.account?.contactPhone!,
      dateOrdered: usDateFormatMMDDYYYY(bulkOrderTransaction?.createdDatetime!),
      breedDate: bulkOrderTransaction?.breedDate ? usDateFormatMMDDYYYY(bulkOrderTransaction.breedDate) : notesInfo.breedDate,
      pickUpDate: notesInfo.pickupInfo != undefined ? notesInfo.pickupInfo?.pickupDate! : '',
      shippingAddress: notesInfo.shippingAddress != undefined ? getShippingInfo(notesInfo.shippingAddress) : [],
      notes: getUserEnteredNotes(notesInfo),
    };

    return geneticsMovementAccountInfo;
  };

  const extractNotesInfo = (inputString: string) => {
    const notesInfo: NotesInfo = { breedDate: '', liquidNitrogen: '', remainingText: '' };

    const regexPatterns = {
      breedDate: /Breed Date:\s*(\d{1,2}\/\d{1,2}\/\d{4})/,
      liquidNitrogen: /Liquid nitrogen tank for shipping or pick up:\s*(Yes|No)/,
      pickupInfo: /Pickup Information:\s*Name:\s*([\w\s]+)\s*Phone:\s*([\d\s+-]+)\s*Pickup Date:\s*(\d{1,2}\/\d{1,2}\/\d{4})/,
      shippingAddress:
        /Shipping Address\s+Address 1:\s*(.*?)\s*Address 2:\s*(.*?)\s*City:\s*(.*?)\s*State:\s*(.*?)\s*Zip Code:\s*([\d\s-]+)\s*Country:\s*(.*)/,
    };

    const matches = {
      breedDateMatch: inputString.match(regexPatterns.breedDate),
      liquidNitrogenMatch: inputString.match(regexPatterns.liquidNitrogen),
      pickupInfoMatch: inputString.match(regexPatterns.pickupInfo),
      shippingAddressMatch: inputString.match(regexPatterns.shippingAddress),
    };

    if (matches.breedDateMatch) {
      notesInfo.breedDate = matches.breedDateMatch[1];
    }

    if (matches.liquidNitrogenMatch) {
      notesInfo.liquidNitrogen = matches.liquidNitrogenMatch[0];
    }

    if (matches.pickupInfoMatch) {
      notesInfo.pickupInfo = {
        name: matches.pickupInfoMatch[1].replace(/\n+/g, '').trim(),
        phone: matches.pickupInfoMatch[2].replace(/\n+/g, '').trim(),
        pickupDate: matches.pickupInfoMatch[3],
      };
    }

    if (matches.shippingAddressMatch) {
      notesInfo.shippingAddress = {
        address1: matches.shippingAddressMatch[1],
        address2: matches.shippingAddressMatch[2],
        city: matches.shippingAddressMatch[3],
        state: matches.shippingAddressMatch[4],
        zipCode: matches.shippingAddressMatch[5].replace(/\n+/g, '').trim(),
        country: matches.shippingAddressMatch[6],
      };
    }

    let remainingText = inputString;
    for (const match of Object.values(matches)) {
      if (match) remainingText = remainingText.replace(match[0], '');
    }

    notesInfo.remainingText = remainingText.replace(/\n+/g, ' ').trim();
    return notesInfo;
  };

  const getShippingInfo = (shippingInfo: InputShippingAddress) => {
    const address1 = splitStringWithoutBreakingWords(shippingInfo.address1, 40);
    const address2 = splitStringWithoutBreakingWords(shippingInfo.address2, 55);
    const cityStateZipCountryString = `${shippingInfo.city}, ${shippingInfo.state} ${shippingInfo.zipCode}, ${shippingInfo.country}`;
    const location = splitStringWithoutBreakingWords(cityStateZipCountryString, 60);

    return [...address1, ...address2, ...location];
  };

  const getUserEnteredNotes = (notesInfo: NotesInfo) => {
    const userEnteredText =
      notesInfo.remainingText.trim().length > 0 ? splitStringWithoutBreakingWords(notesInfo.remainingText.trim(), 60) : [];
    const liquidNitrogenInfo = splitStringWithoutBreakingWords(notesInfo.liquidNitrogen.trim(), 60);
    const pickUpDetails = notesInfo.pickupInfo
      ? [
          'Pickup Information:',
          `Name:${notesInfo.pickupInfo?.name}`,
          `Phone:${notesInfo.pickupInfo?.phone}`,
          `Pickup Date:${notesInfo.pickupInfo?.pickupDate}`,
        ]
      : [];

    return [...userEnteredText, ...liquidNitrogenInfo, ...pickUpDetails];
  };

  const onSubmit = async () => {
    if (!completingWithdraw) return;
    setCompletingWithdraw(false);
    setValidate(true);

    if (isValid() && resolution.value) {
      setValidate(false);
      setIsLoading(true);

      try {
        if (locationState.bulkOrder) {
          const updatedTransactions = associatedInventoryTrans.map(transaction =>
            mapInventoryStorageLocations(transaction, TransactionStatusEnum.Complete),
          );

          await completeBulkWithdraw(updatedTransactions);
          navigate(ROUTE_PATHS.APP_TRANSACTION_MANAGEMENT, {
            state: {
              filterPreset: locationState.transactionManagementFilterPreset,
              filter: locationState.filter,
              bulkOrder: locationState.bulkOrder,
            },
          });
          await deleteSpecimenLocations();
          showToast.success(toastMessages.WITHDRAW_SUCCESS);
        } else {
          const transaction = associatedInventoryTrans[0];
          const specimenLocations: SpecimenLocation[] = [];

          const inventoryTransaction = {
            accountId: transaction.accountId,
            accountUserId: transaction.accountUserId!,
            changeQuantity: Math.abs(Number(transaction.quantityToWithdraw)),
            inventoryTransactionId: transaction.transactionId,
            notes: notes,
            resolution: resolution.value as TransactionResolutionEnum,
            specimenId: transaction.specimenId,
            status: TransactionStatusEnum.Complete,
            transactionType: bulkOrderTransaction?.transactionType as TransactionTypeEnum,
          };

          transaction.specimenLocations?.forEach(location => {
            if (location.selected && location.quantity > 0) {
              specimenLocations.push({
                specimenLocationId: location.specimenLocationId!,
                storageCanisterId: location.selectedCanisterId!,
                quantity: location.quantity,
                transactionLinkId: location.transactionLinkId,
                specimenId: location.specimenId,
              });
            }
          });

          const updatedTransaction = { inventoryTransaction, specimenLocations };

          await completeWithdraw(updatedTransaction);
          navigate(ROUTE_PATHS.APP_TRANSACTION_MANAGEMENT, {
            state: { filterPreset: locationState.transactionManagementFilterPreset, filter: locationState.filter },
          });
          await deleteSpecimenLocations();
          showToast.success(toastMessages.WITHDRAW_SUCCESS);
        }
      } catch {
        showToast.error(toastMessages.WITHDRAW_SUBMIT_FAIL);
      } finally {
        setCompletingWithdraw(true);
        setIsLoading(false);
      }
    } else {
      setCompletingWithdraw(true);
    }
  };

  const mapInventoryStorageLocations = (transaction: BulkOrderSelectedSpecimen, status: string) => {
    const specimenLocations: SpecimenLocation[] = [];

    const inventoryTransaction = {
      accountId: transaction.accountId,
      accountUserId: transaction.accountUserId!,
      changeQuantity: Math.abs(Number(transaction.quantityToWithdraw)),
      inventoryTransactionId: transaction.transactionId,
      notes: notes,
      resolution: resolution.value as TransactionResolutionEnum,
      specimenId: transaction.specimenId,
      status: status as TransactionStatusEnum,
      transactionType: bulkOrderTransaction?.transactionType as TransactionTypeEnum,
      bulkOrderId: locationState.bulkOrder ? Number(transactionId) : undefined,
    };

    transaction.specimenLocations?.forEach(location => {
      if (location.selected && location.quantity > 0) {
        specimenLocations.push({
          specimenLocationId: location.specimenLocationId!,
          storageCanisterId: location.selectedCanisterId!,
          quantity: location.quantity,
          transactionLinkId: location.transactionLinkId,
          specimenId: location.specimenId,
        });
      }
    });

    return { inventoryTransaction, specimenLocations };
  };

  const deleteSpecimenLocations = async () => {
    const idKey = locationState.bulkOrder ? 'bulkOrderId' : 'inventoryTransactionId';
    const idValue = Number(transactionId);
    await removeInventoryTransactionLocations({ [idKey]: idValue });
  };

  const renderTableHeader = (headers: string[]) => (
    <thead>
      <tr>
        {headers.map((header, index) => (
          <th key={`${header}-${index}`}>{header}</th>
        ))}
      </tr>
    </thead>
  );

  const renderSpecimenSelectionsTableRows = () => (
    <>
      {bulkOrderTransaction?.inventoryTransactions?.map((transaction, index) => {
        const isQuantityExceeding = selectedQuantity(transaction.specimenId!) > Math.abs(transaction.changeQuantity!);
        const animalDetails = `${transaction.specimen?.animal?.code} - ${transaction.specimen?.animal?.name}`;
        const freezeDateText = transaction?.specimen?.freezeDate
          ? `${dateFormatMMDDYYYY(transaction?.specimen?.freezeDate)} - ${transaction?.specimen?.specimenId}`
          : '';

        return (
          <tr key={`specimen-row-${transaction.specimen?.specimenId}-${index}`} className={isQuantityExceeding ? 'error' : ''}>
            <td>
              <input
                type="checkbox"
                id={`location-selected-${index}`}
                checked={associatedInventoryTrans[index]?.selected ?? false}
                onChange={e => handleSelectedSpecimen(e, index)}
              />
            </td>
            <td>{animalDetails}</td>
            <td>{freezeDateText}</td>
            <td>{String(Math.abs(transaction.changeQuantity!))}</td>
          </tr>
        );
      })}
    </>
  );

  const handleErrors = (location: SpecimenQuantity) => {
    return location.selected
      ? location.quantity > location.availableQuantity
        ? 'error'
        : location.quantity === 0
        ? 'warning'
        : ''
      : '';
  };

  const renderStorageLocationTableRows = (location: SpecimenQuantity, transaction: BulkOrderSelectedSpecimen, index: number) => {
    const isTravelingTank = location.storageCanister?.storageTank?.isTravelingTank;
    const rowClass = `${handleErrors(location)} ${isTravelingTank ? 'disabled' : ''}`;
    const locationPath = `${location.storageCanister?.storageTank?.storageSite?.name}/${location.storageCanister?.storageTank?.name}/${location.storageCanister?.name}`;
    const animalDetails = `${transaction.animalCode}-${transaction.animalName}`;
    const lotDetails = `${dateFormatMMDDYYYY(location.freezeDate)} - ${location.specimenId}`;

    return (
      <tr className={rowClass} key={`location-row-${location.specimenId}-${index}`}>
        <td>
          <input
            type="checkbox"
            id={`location-selected-${index}`}
            checked={location.selected ?? false}
            onChange={e => handleLocationSelected(e, transaction.transactionId, index)}
            disabled={isTravelingTank}
          />
        </td>
        <td>{locationPath}</td>
        <td>{animalDetails}</td>
        <td>{lotDetails}</td>
        <td>{location.availableQuantity}</td>
        <td>
          {isTravelingTank ? (
            LABEL_CONSTANTS.TRAVELING_TANK
          ) : (
            <input
              type="text"
              placeholder="0"
              value={location.selected ? (location.quantity > 0 ? location.quantity : '') : '-'}
              onChange={e => handleQuantities(e, transaction.transactionId, index)}
              disabled={!location.selected}
            />
          )}
        </td>
      </tr>
    );
  };

  const renderStorageLocationMobileRow = (location: SpecimenQuantity, transaction: BulkOrderSelectedSpecimen, index: number) => {
    const isTravelingTank = location.storageCanister?.storageTank?.isTravelingTank;
    const rowClass = `card max-width ${handleErrors(location)} ${isTravelingTank ? 'disabled' : ''}`;
    const locationPath = `${location.storageCanister?.storageTank?.storageSite?.name} / ${location.storageCanister?.storageTank?.name} / ${location.storageCanister?.name}`;
    const animalDetails = `${transaction.animalCode}-${transaction.animalName}`;
    const lotDetails = `${dateFormatMMDDYYYY(location.freezeDate)} - ${location.specimenId}`;

    return (
      <div key={`location-row-${location.specimenId}-${index}`} className={rowClass}>
        <div className="item-storage-locations-row">
          <div className="input-check">
            <input
              type="checkbox"
              id={`location-selected-${index}`}
              checked={location.selected ?? false}
              onChange={e => handleLocationSelected(e, transaction.transactionId, index)}
              disabled={isTravelingTank}
            />
          </div>
          <div className="item-storage-locations-content">
            <label>{locationPath}</label>
            <label>{animalDetails}</label>
            <label>{lotDetails}</label>
            <label>{`${TABLE_HEADER_CONSTANTS.QTY_IN_CANISTER} : ${location.availableQuantity}`}</label>
          </div>
          <div className="number-input-section">
            {isTravelingTank ? (
              <label>{LABEL_CONSTANTS.TRAVELING_TANK}</label>
            ) : (
              <>
                <label>{TABLE_HEADER_CONSTANTS.WITHDRAW_QTY}</label>
                <NumberInput
                  onChange={e => handleQuantities(e, transaction.transactionId, index)}
                  value={location.quantity ?? 0}
                  disabled={!location.selected}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {!isLoading ? (
        <>
          {locationState && locationState.transactionManagementFilterPreset && (
            <BackButton
              onClick={() =>
                navigate(ROUTE_PATHS.APP_TRANSACTION_MANAGEMENT, {
                  state: {
                    filterPreset: locationState.transactionManagementFilterPreset,
                    filter: locationState.filter,
                    bulkOrder: locationState.bulkOrder,
                  },
                })
              }
            />
          )}
          <div className="inventory-action card inventory-withdraw">
            <h1>{PAGE_HEADER_CONSTANTS.COMPLETE_WITHDRAW}</h1>
            <h4>{TRANSACTION_CONSTANTS.TRANSACTION_INFORMATION}</h4>
            <hr />
            <TransactionLabelField
              label={
                locationState.bulkOrder ? `${LABEL_CONSTANTS.BULK_TRANSACTION_ID}:` : `${TRANSACTION_CONSTANTS.TRANSACTION_ID}:`
              }
              value={transactionId}
            />
            <TransactionLabelField
              label={`${LABEL_CONSTANTS.ACCOUNT_OWNER_ID}:`}
              value={`${bulkOrderTransaction?.account?.accountId}-${bulkOrderTransaction?.account?.name}`}
            />
            <TransactionLabelField label={`${LABEL_CONSTANTS.STATUS}:`} value={bulkOrderTransaction?.status} />
            <TransactionLabelField
              label={`${TRANSACTION_CONSTANTS.TRANSACTION_DATE}:`}
              value={dateFormatMMDDYYYY(bulkOrderTransaction?.createdDatetime)}
            />

            {bulkOrderTransaction?.status === TransactionStatusEnum.Processed && (
              <TransactionLabelField
                label={`*${LABEL_CONSTANTS.RESOLUTION}:`}
                value={
                  <div className="input-container filter-dropdown-mobile">
                    <FilterDropdown value={resolution} options={resolutionOptions} onChange={setResolution} validate={validate} />
                  </div>
                }
              />
            )}

            <div className="form-row">
              <label htmlFor="transaction-notes">{LABEL_CONSTANTS.NOTES}:</label>
              <div className="input-container">
                <textarea id="transaction-notes" onChange={e => setNotes(e.target.value)} value={notes} placeholder="Notes" />
              </div>
            </div>

            {!completingWithdraw && <Loader loaderSize="small" simple />}

            <div className="select-animal">
              <h4>{LABEL_CONSTANTS.SPECIMENS}</h4>
              <hr />
              <div className="specimen-table">
                <table>
                  {renderTableHeader(specimenHeaders)}
                  <tbody>
                    {renderSpecimenSelectionsTableRows()}
                    <tr>
                      <td colSpan={7}>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <h4>{LABEL_CONSTANTS.ITEM_STORAGE_LOCATIONS}</h4>
            <hr />
            {isAnySpecimenSelected() && (
              <>
                <div className="desk-item-storage-locations">
                  <div className="specimen-table">
                    <table>
                      {renderTableHeader(locationHeaders)}
                      <tbody>
                        {associatedInventoryTrans?.map(transaction => {
                          return (
                            transaction.selected &&
                            transaction?.specimenLocations?.map((location, index) =>
                              renderStorageLocationTableRows(location, transaction, index),
                            )
                          );
                        })}
                        <tr>
                          <td colSpan={5}></td>
                          <td colSpan={1}>
                            <div className="total-selected-quantity">
                              <input type="text" disabled value={totalSelectedQuantity} />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {/** Mobile View */}
                <div className="xs-item-storage-locations">
                  {associatedInventoryTrans?.map(transaction => {
                    return (
                      transaction.selected &&
                      transaction.specimenLocations?.map((location, index) =>
                        renderStorageLocationMobileRow(location, transaction, index),
                      )
                    );
                  })}

                  <div className="total-quantity">
                    <label>{LABEL_CONSTANTS.TOTAL_QUANTITY_SELECTED}: &nbsp; </label>
                    {totalSelectedQuantity}
                  </div>
                </div>
              </>
            )}

            {isWithdrawValid() && (
              <div className="validated-input-message-error margin-top">
                <AlertSVG />
                {VALIDATION_ALERT_CONSTANTS.WITHDRAW_QTY_EXCEEDED}
              </div>
            )}

            <div className="flex-right margin-top withdraw-buttons">
              {bulkOrderTransaction?.status === TransactionStatusEnum.Pending ? (
                <button
                  className={'button green large'}
                  disabled={!isValid()}
                  onClick={() => {
                    printSpecimenLocations();
                    handleProcessedStatus();
                  }}>
                  {BUTTON_CONSTANTS.PRINT_AND_PROCESS}
                </button>
              ) : (
                <>
                  <button
                    className={!isValid() ? 'button green small disabled' : 'button green small'}
                    onClick={() => printSpecimenLocations()}>
                    {BUTTON_CONSTANTS.PRINT}
                  </button>
                  <button
                    type="submit"
                    className={!(isValid() && resolution.value) ? 'button green small disabled' : 'button green small'}
                    onClick={onSubmit}>
                    {BUTTON_CONSTANTS.COMPLETE}
                  </button>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <Loader loaderSize={'small'} pageLoader />
      )}
    </div>
  );
};

export default TransactionWithdraw;
