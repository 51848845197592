const ClosedEyeSVG = (): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5292 4.53082C20.6617 4.38865 20.7338 4.2006 20.7304 4.0063C20.7269 3.812 20.6482 3.62661 20.5108 3.4892C20.3734 3.35179 20.188 3.27308 19.9937 3.26965C19.7994 3.26622 19.6114 3.33834 19.4692 3.47082L3.46918 19.4708C3.39549 19.5395 3.33639 19.6223 3.2954 19.7143C3.2544 19.8063 3.23236 19.9056 3.23059 20.0063C3.22881 20.107 3.24733 20.207 3.28505 20.3004C3.32278 20.3938 3.37892 20.4786 3.45014 20.5499C3.52136 20.6211 3.60619 20.6772 3.69958 20.7149C3.79297 20.7527 3.893 20.7712 3.9937 20.7694C4.0944 20.7676 4.19372 20.7456 4.28572 20.7046C4.37771 20.6636 4.46052 20.6045 4.52918 20.5308L7.56418 17.4958C8.88218 18.1038 10.3912 18.5008 11.9992 18.5008C14.6172 18.5008 16.9712 17.4498 18.6672 16.1478C19.5172 15.4958 20.2142 14.7718 20.7022 14.0678C21.1822 13.3758 21.4992 12.6498 21.4992 12.0008C21.4992 11.3518 21.1822 10.6258 20.7022 9.93482C20.2142 9.22982 19.5172 8.50582 18.6672 7.85482C18.3972 7.64682 18.1092 7.44482 17.8072 7.25382L20.5292 4.53082ZM15.1292 9.93282L14.0292 11.0308C14.2297 11.4507 14.2951 11.9224 14.2164 12.381C14.1378 12.8396 13.9188 13.2625 13.5898 13.5915C13.2608 13.9205 12.8379 14.1394 12.3793 14.2181C11.9207 14.2968 11.449 14.2313 11.0292 14.0308L9.92918 15.1308C10.6503 15.6083 11.5143 15.8218 12.3748 15.7352C13.2354 15.6487 14.0395 15.2673 14.6511 14.6557C15.2626 14.0442 15.644 13.24 15.7306 12.3795C15.8172 11.519 15.6036 10.655 15.1262 9.93382L15.1292 9.93282Z"
        fill="black"
      />
      <path
        d="M12.67 8.31C12.7111 8.31771 12.7535 8.31537 12.7936 8.30318C12.8336 8.29099 12.8701 8.26932 12.9 8.24L14.85 6.29C14.8803 6.26019 14.9023 6.22294 14.9137 6.18198C14.9251 6.14102 14.9256 6.09779 14.915 6.05659C14.9045 6.0154 14.8834 5.97769 14.8537 5.94722C14.824 5.91676 14.7869 5.89462 14.746 5.883C13.8523 5.63112 12.9285 5.50226 12 5.5C9.382 5.5 7.028 6.551 5.332 7.853C4.482 8.505 3.785 9.229 3.296 9.933C2.816 10.625 2.5 11.351 2.5 12C2.5 12.649 2.817 13.375 3.296 14.066C3.77186 14.7339 4.33408 15.3358 4.968 15.856C5.01544 15.8952 5.07584 15.9152 5.13731 15.9121C5.19877 15.9089 5.25682 15.8828 5.3 15.839L8.24 12.899C8.26932 12.8691 8.29099 12.8326 8.30318 12.7926C8.31537 12.7525 8.31771 12.7101 8.31 12.669C8.20163 12.0726 8.23945 11.4588 8.42022 10.8803C8.60099 10.3017 8.91932 9.77555 9.34794 9.34694C9.77655 8.91832 10.3027 8.59999 10.8813 8.41922C11.4598 8.23845 12.0736 8.20063 12.67 8.309V8.31Z"
        fill="black"
      />
    </svg>
  );
};
export default ClosedEyeSVG;
