import { FC, useEffect, useState } from 'react';
import Loader from '../loader/Loader';
import { SelectedSpecimenQuantity } from '../specimen-tables/SelectSpecimens';
import { getSpecimensInventoryByCanisterId } from '../../api/specimensApi';
import { Specimen } from '../../types/interfaces/specimen.interfaces';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';
import './selectSpecimenFromCanisterTable.scss';

type SelectSpecimenFromCanisterTableProps = {
  canisterId?: number;
  onChange: (e: { valid: boolean; value: SelectedSpecimenQuantity[] }) => void;
  selectedSpecimenId?: number;
};

const SelectSpecimenFromCanisterTable: FC<SelectSpecimenFromCanisterTableProps> = ({
  canisterId,
  onChange,
  selectedSpecimenId,
}) => {
  const [specimens, setSpecimens] = useState<Specimen[]>([]);
  const [quantities, setQuantities] = useState<SelectedSpecimenQuantity[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(
    function getInventory() {
      if (canisterId) {
        try {
          setLoading(true);
          getSpecimensInventoryByCanisterId(canisterId).then((res: any) => {
            setSpecimens(res.data);
            const quants = res.data.map((spec: any) => {
              return {
                specimenId: spec.specimenId,
                availableQuantity: spec.quantityAvailable,
                selectedCanisterId: spec.specimenLocations?.[0]?.storageCanisterId,
                quantity: 0,
                selected: spec.specimenId === selectedSpecimenId,
              };
            });
            setQuantities(quants);
            setLoading(false);
          });
        } catch (error) {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      } else {
        setSpecimens([]);
      }
    },
    [canisterId],
  );

  useEffect(() => {
    const quants = quantities.filter(q => q.selected);
    const valid = quants.reduce((acc: boolean, q: SelectedSpecimenQuantity): boolean => {
      return acc && q.quantity > 0 && q.quantity <= q.availableQuantity;
    }, quants.length > 0);

    onChange({ valid: valid, value: quants });
    quants.every((q: SelectedSpecimenQuantity) => {
      if (q.quantity > q.availableQuantity) {
        showToast.error(toastMessages.CHOSE_MORE_THAN_TOTAL);
      }
    });
  }, [quantities]);

  const inputCheckbox = (index: number) => {
    return (
      <input
        type="checkbox"
        checked={quantities[index]?.selected ?? false}
        onChange={e => {
          let newQuantities = [...quantities];
          newQuantities[index] = {
            ...quantities[index],
            selected: e.target.checked,
            quantity: e.target.checked ? quantities[index].quantity : 0,
          };
          setQuantities(newQuantities);
        }}
      />
    );
  };

  return (
    <>
      <div className="desk-move-select-specimen">
        <div className="specimen-table">
          <table>
            <thead>
              <tr>
                <th>Select</th>
                <th>Animal</th>
                <th>Inventory&nbsp;Type</th>
                <th>Lot Date / Number</th>
                <th>Quality</th>
                <th>Qty in Canister</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {specimens?.map((spec, index) => {
                return (
                  <tr
                    className={
                      quantities[index]?.selected
                        ? quantities[index]?.quantity > quantities[index]?.availableQuantity
                          ? 'error'
                          : quantities[index]?.quantity === 0
                          ? 'warning'
                          : ''
                        : ''
                    }
                    key={'specimen-row:' + spec.specimenId + '-index:' + index}>
                    <td>{inputCheckbox(index)}</td>
                    <td>
                      {spec.animal?.code}-{spec.animal?.name}
                    </td>
                    <td>{spec.specimenType?.name}</td>
                    <td>{`${new Date(spec.freezeDate.toString() as string).toLocaleDateString()} - ${spec.specimenId} `}</td>
                    <td>{spec.quality}</td>
                    <td>{quantities[index]?.availableQuantity}</td>
                    <td>
                      <input
                        type="text"
                        placeholder="0"
                        value={
                          quantities[index]?.selected ? (quantities[index]?.quantity > 0 ? quantities[index]?.quantity : '') : '-'
                        }
                        disabled={!quantities[index]?.selected}
                        onChange={e => {
                          const count = +e.target.value.replace(/[^\d]+/, '').replace(/^0+/, ''); //Remove all non-digits and leading zeros
                          let newQuantities = [...quantities];
                          newQuantities[index] = {
                            ...quantities[index],
                            quantity: count,
                          };
                          setQuantities(newQuantities);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan={6}>Total Quantity:</td>
                <td>
                  <input
                    type="text"
                    disabled
                    value={quantities.reduce(
                      (acc: number, qua: SelectedSpecimenQuantity): number => (qua.selected ? acc + qua.quantity : acc),
                      0,
                    )}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Mobile View */}
      <div className="xs-move-select-specimen">
        {loading ? (
          <Loader addedSpace simple loaderSize={'small'} />
        ) : specimens?.length === 0 && !loading ? (
          <label className="no-inventory-found">No Inventory Found</label>
        ) : (
          <>
            {specimens?.map((spec, index) => {
              return (
                <div className="select-specimen-container" key={'specimen-row:' + spec.specimenId + '-index:' + index}>
                  <div className="card max-width">
                    {quantities[index]?.selected ? (
                      <div className="selected-specimen-row">
                        <div className="input-check">{inputCheckbox(index)}</div>
                        <div className="row-content">
                          <div className="header">
                            <label>
                              {spec.animal?.code}-{spec.animal?.name}
                            </label>
                            <label>{`${new Date(spec.freezeDate.toString() as string).toLocaleDateString()} - ${
                              spec.specimenId
                            }`}</label>
                          </div>
                          <hr />
                          <div className="body">
                            <div className="spec-info">
                              <label>{`Lot Number/Type: ${spec.specimenId} -${spec.specimenType?.name} `}</label>
                              <label>{`Quality: ${spec.quality}`}</label>
                              <label>{`Qty in Canister: ${quantities[index]?.availableQuantity}`}</label>
                            </div>
                            <div className="input-body">
                              <label>Selected Qty</label>
                              <input
                                type="text"
                                placeholder="0"
                                value={
                                  quantities[index]?.selected
                                    ? quantities[index]?.quantity > 0
                                      ? quantities[index]?.quantity
                                      : ''
                                    : '-'
                                }
                                disabled={!quantities[index]?.selected}
                                onChange={e => {
                                  const count = +e.target.value.replace(/[^\d]+/, '').replace(/^0+/, '');
                                  let newQuantities = [...quantities];
                                  newQuantities[index] = {
                                    ...quantities[index],
                                    quantity: count,
                                  };
                                  setQuantities(newQuantities);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="select-specimen-row">
                        <div className="body">
                          <div className="input-body">
                            {inputCheckbox(index)}
                            <label>
                              {spec.animal?.code}-{spec.animal?.name}
                            </label>
                          </div>
                          <label>{`${new Date(spec.freezeDate.toString() as string).toLocaleDateString()} - ${
                            spec.specimenId
                          }`}</label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            <div className="total-quantity">
              <label>Total Selected: </label>
              {quantities.reduce(
                (acc: number, qua: SelectedSpecimenQuantity): number => (qua.selected ? acc + qua.quantity : acc),
                0,
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SelectSpecimenFromCanisterTable;
