const FilterSVG = (): JSX.Element => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.08414 14.8337C6.82441 14.8337 6.60655 14.7457 6.43055 14.5697C6.25455 14.3937 6.16686 14.1761 6.16747 13.917V8.41699L0.850802 1.63366C0.621635 1.3281 0.587108 1.00727 0.747219 0.671159C0.90733 0.335048 1.1863 0.166992 1.58414 0.166992H14.4175C14.8147 0.166992 15.0937 0.335048 15.2544 0.671159C15.4151 1.00727 15.3806 1.3281 15.1508 1.63366L9.83414 8.41699V13.917C9.83414 14.1767 9.74614 14.3946 9.57014 14.5706C9.39414 14.7466 9.17658 14.8343 8.91747 14.8337H7.08414ZM8.0008 7.77533L12.5383 2.00033H3.4633L8.0008 7.77533Z"
        fill="black"
      />
    </svg>
  );
};
export default FilterSVG;
