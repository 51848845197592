import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { storeUserProfileImageBlobUrl } from '../../../redux/reducers/userSlice';
import BackButton from '../../../components/back-button/BackButton';
import { EditSVG, PlusSignSVG, TrashCanSVG } from '../../../components/svgs';
import ValidatedInput, { ValidatedState } from '../../../components/custom-input/ValidatedInput';
import { Loader, ProfileImage } from '../../../components';
import UploadImageModal from '../../../components/upload-image-modal/UploadImageModal';
import { FilterOption } from '../../../components/custom-input/FilterDropdown';
import ConfirmModal from './confirm-modal/ConfirmModal';
import ResetPasswordModal from './reset-password-modal/ResetPasswordModal';
import EmailChangeConfirmModal from './email-change-confirm-modal/EmailChangeConfirmModal';
import AddressInputModal from '../address-input-modal/AddressInputModal';
import DeactivateProfile from '../deactivate-profile/DeactivateProfile';
import { showToast } from '../../../services/toast.service';
import { requestUserPersonalInfo } from '../../../services/user.service';
import { signOutUser } from '../../../services/auth.service';
import { PermissionService } from '../../../services/permission.service';
import { getUser, getUserProfileImage, postUserProfileImage, updateUser } from '../../../api/userApi';
import { Address, emptyAddress } from '../../../types/interfaces/common.interfaces';
import { UserInfo, UpdateUserInterface } from '../../../types/interfaces/user.interfaces';
import { Validators } from '../../../types/enums/validators.enum';
import { AddressType } from '../../../types/enums';
import { checkAddressTypesAreUnique, isValidAddress, removeAddressIds, tempId } from '../../../utils/addressHelpers';
import { PromiseError, toastMessages } from '../../../constants/errorMessages';
import { ROUTE_PATHS } from '../../../constants/routePaths';
import './userProfileEdit.scss';

type UserProfileEditProps = {
  permissionService: PermissionService;
  userId?: number;
};

const UserProfileEdit: FC<UserProfileEditProps> = ({ userId, permissionService }) => {
  const currentUser = useAppSelector(state => state.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [addressesValid, setAddressesValid] = useState<boolean[]>([]);
  const [firstName, setFirstName] = useState<ValidatedState>({ value: '', valid: false });
  const [lastName, setLastName] = useState<ValidatedState>({ value: '', valid: false });
  const [email, setEmail] = useState<ValidatedState>({ value: '', valid: false });
  const [phone, setPhone] = useState<ValidatedState>({ value: '', valid: false });
  const [validate, setValidate] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
  const [addressId, setAddressId] = useState<number>(0);
  const [isOpenResetPasswordModal, setIsOpenResetPasswordModal] = useState<boolean>(false);
  const [isOpenEmailChangeModal, setIsOpenEmailChangeModal] = useState<boolean>(false);
  const [emailIsModified, setEmailIsModified] = useState<boolean>(false);
  const [isConfirmEmailUpdate, setIsConfirmEmailUpdate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenAddAddressModal, setIsOpenAddAddressModal] = useState<boolean>(false);
  const [address, setAddress] = useState<Address>(emptyAddress);
  const [editAddress, setEditAddress] = useState<boolean>(false);
  const [updatingUser, setUpdatingUser] = useState<boolean>(true);

  const addressTypes: FilterOption<string>[] = [];

  for (const [addressTypekey, addressTypeValue] of Object.entries(AddressType)) {
    addressTypes.push({ name: addressTypeValue, value: addressTypeValue });
  }

  const [addressTypeOptions, setAddressTypeOptions] = useState<FilterOption<string>[]>(addressTypes);

  const checkValidity = (): boolean => {
    return (
      firstName.valid &&
      lastName.valid &&
      email.valid &&
      phone.valid &&
      addressesValid.every(valid => valid) &&
      isValidAddress(addresses) &&
      checkAddressTypesAreUnique(addresses)
    );
  };

  const handleImageSubmit = async (image: FormData) => {
    setIsLoading(true);
    try {
      await postUserProfileImage(image);
      showToast.success(toastMessages.USER_IMAGE_UPDATE_SUCCESS);
      setIsImageModalOpen(false);
      const url = await getUserProfileImage();
      dispatch(storeUserProfileImageBlobUrl(url));
    } catch (error: any) {
      if (error.code === PromiseError.NETWORK) {
        showToast.error(toastMessages.NETWORK);
      } else {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
    setIsLoading(false);
  };

  const handleProfileLoad = useCallback(async () => {
    setIsLoading(true);
    let user: UserInfo;
    if (userId && userId !== currentUser.userId) {
      const { data } = await getUser(userId);
      user = data as UserInfo;
    } else {
      user = currentUser;
    }
    setFirstName({ value: user.firstName, valid: true });
    setLastName({ value: user.lastName, valid: true });
    setEmail({ value: user.username, valid: true });
    setPhone({ value: user.contactPhone, valid: true });
    setAddresses(user.addresses);
    setIsLoading(false);
  }, [userId, currentUser]);

  const handleCancelButton = () => {
    if (currentUser.accounts.length != 0) {
      navigate(ROUTE_PATHS.APP_DASHBOARD);
    } else {
      handleProfileLoad();
    }
    showToast.info(toastMessages.NO_CHANGES_SAVED);
  };

  useEffect(() => {
    addresses.map((item, index) => {
      setAddressesValid(prev => {
        const newAddressesValid = [...prev];
        newAddressesValid[index] = true;
        return newAddressesValid;
      });
    });
  }, [addresses]);

  useEffect(() => {
    if (currentUser.userId) {
      handleProfileLoad();
    }
  }, [currentUser, handleProfileLoad]);

  useEffect(() => {
    if (isConfirmEmailUpdate) {
      handleEmailUpdate();
    }
  }, [isConfirmEmailUpdate]);

  useEffect(() => {
    const filteredAddressTypes = addressTypes.filter(
      addressType => !addresses.find(address => addressType.value === address.type),
    );
    setAddressTypeOptions(filteredAddressTypes);
  }, [addresses]);

  const handleSubmit = (event?: React.FormEvent, addressesPayload: Address[] = addresses) => {
    if (event) {
      event.preventDefault();
    }

    if (!updatingUser) return;
    setUpdatingUser(false);
    setValidate(true);

    if (checkValidity()) {
      if (emailIsModified) {
        setUpdatingUser(true);
        setIsOpenEmailChangeModal(true);
      } else {
        updateUserInfoRequest({
          firstName: firstName.value,
          lastName: lastName.value,
          contactPhone: phone.value,
          addresses: removeAddressIds(addressesPayload),
        });
      }
    } else {
      setUpdatingUser(true);
    }
  };

  const handleEmailUpdate = (addressesPayload: Address[] = addresses) => {
    if (checkValidity()) {
      updateUserInfoRequest({
        firstName: firstName.value,
        lastName: lastName.value,
        username: email.value,
        contactPhone: phone.value,
        addresses: removeAddressIds(addressesPayload),
      });
    }
  };

  const updateUserInfoRequest = async (userInfo: UpdateUserInterface) => {
    if (checkValidity()) {
      setIsLoading(true);
      try {
        await updateUser(userId || currentUser.userId, userInfo);

        if (emailIsModified && isConfirmEmailUpdate) {
          setIsConfirmEmailUpdate(false);
          setEmailIsModified(false);
          signOutUser();
        } else {
          showToast.success(toastMessages.USER_UPDATE_SUCCESS);
          if (currentUser.accounts.length != 0) {
            navigate(ROUTE_PATHS.APP_DASHBOARD);
          } else {
            handleProfileLoad();
          }
          setValidate(false);
          if (!userId) {
            await requestUserPersonalInfo();
          }
        }
      } catch (error: any) {
        if (error.code === PromiseError.NETWORK) {
          showToast.error(toastMessages.NETWORK);
        } else {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      } finally {
        setUpdatingUser(true);
      }

      setIsLoading(false);
    }
  };

  const handleRemoveAddress = (id: number) => {
    const newAddresses = [...addresses];
    const index = newAddresses.findIndex((a: Address) => a.addressId === id);
    newAddresses.splice(index, 1);
    setAddresses(newAddresses);

    setAddressesValid(prev => {
      const newAddressesValid = [...prev];
      newAddressesValid.splice(index, 1);
      return newAddressesValid;
    });

    updateUserInfoRequest({
      firstName: firstName.value,
      lastName: lastName.value,
      contactPhone: phone.value,
      addresses: removeAddressIds(newAddresses),
    });
  };

  const handleAddressValidity = (address: Address, valid: boolean) => {
    const index = addresses.indexOf(address);
    setAddressesValid(prev => {
      const newAddressesValid = [...prev];
      newAddressesValid[index] = valid;
      return newAddressesValid;
    });
  };

  function showConfirmationModal(id: number) {
    setAddressId(id);
    setIsOpen(true);
  }

  const showAddAddressModal = (address: Address) => {
    setEditAddress(true);
    setAddress(address);
    setIsOpenAddAddressModal(true);
  };

  const isAddressOptionsAvailable = () => {
    return addressTypes.length === addresses.length + 1 ? true : false;
  };

  const handleAddressInputConfirm = (address: Address) => {
    let newAddresses;

    if (editAddress) {
      newAddresses = addresses.map(item =>
        item.addressId === address.addressId
          ? {
              ...item,
              type: address.type as AddressType,
              streetAddress: address.streetAddress,
              city: address.city,
              state: address.state,
              postalCode: address.postalCode,
              country: address.country,
            }
          : item,
      );

      setEditAddress(false);
    } else {
      newAddresses = [
        ...addresses,
        {
          ...address,
          type: address.type as AddressType,
          streetAddress: address.streetAddress,
          city: address.city,
          state: address.state,
          postalCode: address.postalCode,
          country: address.country,
        },
      ];
    }

    updateUserInfoRequest({
      firstName: firstName.value,
      lastName: lastName.value,
      contactPhone: phone.value,
      addresses: removeAddressIds(newAddresses),
    });

    setIsOpenAddAddressModal(false);
  };

  return (
    <>
      {currentUser.accounts.length != 0 && <BackButton />}
      {isLoading && <Loader simple pageLoader loaderSize="medium" />}
      <div className="card user-profile-edit">
        <div className="header">
          <div className="user-profile-image-container">
            <ProfileImage
              profileImageId={currentUser.profileImageId}
              profileImageBlob={currentUser.profileImageBlob}
              className="user-profile-image"
            />
            <div className="user-profile-image__edit-btn" onClick={() => setIsImageModalOpen(true)}>
              <PlusSignSVG />
            </div>
          </div>
        </div>
        <br />
        <div className="reset-password">
          <span onClick={() => setIsOpenResetPasswordModal(true)}>Reset Password</span>
        </div>
        <br />
        {isOpenResetPasswordModal && (
          <ResetPasswordModal isOpen={isOpenResetPasswordModal} onClose={() => setIsOpenResetPasswordModal(false)} />
        )}

        <div className="user-profile-info">
          <form action="submit" onSubmit={event => handleSubmit(event)}>
            <div className="input-row">
              <div className="input">
                <ValidatedInput
                  useId="FirstName"
                  label={'First Name'}
                  validatedStateForAutoFill={firstName}
                  setValidatedState={setFirstName}
                  type={'text'}
                  validators={[Validators.REQUIRED]}
                  validate={validate}
                />
              </div>
              <div className="input">
                <ValidatedInput
                  useId="LastName"
                  label={'Last Name'}
                  validatedStateForAutoFill={lastName}
                  setValidatedState={setLastName}
                  type={'text'}
                  validators={[Validators.REQUIRED]}
                  validate={validate}
                />
              </div>
            </div>
            <div className="input">
              <ValidatedInput
                useId="Email"
                label={'Email'}
                type={'email'}
                validatedStateForAutoFill={email}
                disabled={permissionService.userHasRSGAdminPermission()}
                setValidatedState={e => {
                  setEmail(e);
                  setEmailIsModified(true);
                }}
                validators={[Validators.REQUIRED, Validators.EMAIL]}
                validate={validate}
              />
            </div>
            <div className="input">
              <ValidatedInput
                type="phone"
                useId="PhoneNumber"
                label={'Phone'}
                validatedStateForAutoFill={phone}
                setValidatedState={setPhone}
                validators={[Validators.REQUIRED, Validators.PHONE_LENGTH]}
                validate={validate}
              />
            </div>

            {addresses.length != 0 && (
              <>
                {addresses.length === 1 ? (
                  <div className="user-single-address">
                    <div className="card">
                      <div className="address-type">{addresses[0].type}</div>
                      <div className="address-details">
                        <p>{addresses[0].streetAddress}</p>
                        <p>{addresses[0].city}</p>
                        <p>{addresses[0].state}</p>
                        <p>{addresses[0].postalCode}</p>
                        <p>{addresses[0].country}</p>
                      </div>
                      <div className="edit-trash-svg">
                        <div onClick={() => addresses[0].addressId && showAddAddressModal(addresses[0])}>
                          <EditSVG />
                        </div>
                        <TrashCanSVG
                          className={'address-delete-button'}
                          onClick={() => addresses[0].addressId && showConfirmationModal(addresses[0].addressId)}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="user-addresses">
                    {addresses.map((address, index) => (
                      <div key={address.addressId} className="card">
                        <div className="address-card-header">
                          <h3>{address.type}</h3>
                          <div className="edit-trash-svg">
                            <div onClick={() => address.addressId && showAddAddressModal(address)}>
                              <EditSVG />
                            </div>
                            <TrashCanSVG
                              className={'address-delete-button'}
                              onClick={() => address.addressId && showConfirmationModal(address.addressId)}
                            />
                          </div>
                        </div>
                        <div className="address-card-body">
                          <p>{address.streetAddress}</p>
                          <p>{address.city}</p>
                          <p>
                            {address.state} &nbsp; {address.postalCode}
                          </p>
                          <p>{address.country}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}

            {isOpenAddAddressModal && (
              <AddressInputModal
                isOpen={isOpenAddAddressModal}
                address={address}
                validate={validate}
                addressTypeOptions={addressTypeOptions}
                onClose={setIsOpenAddAddressModal}
                handleAddressValidity={(address, value) => handleAddressValidity(address, value)}
                confirm={value => handleAddressInputConfirm(value)}
              />
            )}
            <div
              className={isAddressOptionsAvailable() ? 'add-address-button disabled' : 'add-address-button'}
              onClick={() => {
                setAddress({ ...emptyAddress, addressId: tempId() });
                setIsOpenAddAddressModal(true);
              }}>
              <PlusSignSVG />
              Add Address
            </div>
            <br />
            <div className="button-row">
              <button className="button blue inverted" type="button" onClick={() => handleCancelButton()}>
                Cancel
              </button>
              <button
                type="submit"
                className="button green"
                disabled={!checkValidity()}
                onClick={handleSubmit}
                onSubmit={e => handleSubmit(e)}>
                Update
              </button>
            </div>
          </form>
        </div>
        <DeactivateProfile />
      </div>
      <ConfirmModal
        modalHeader="Remove Address"
        modalBody="Are you sure you want to remove this address from your account?"
        isOpen={isOpen}
        firstBtn={
          <button type="button" className="small inverted blue button" onClick={() => setIsOpen(false)}>
            Cancel
          </button>
        }
        secondBtn={
          <button
            type="button"
            className="small red button"
            onClick={() => {
              handleRemoveAddress(addressId);
              setIsOpen(false);
            }}>
            Remove
          </button>
        }
      />
      <UploadImageModal
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
        postImage={data => handleImageSubmit(data)}
      />
      <EmailChangeConfirmModal
        newEmail={email.value}
        isOpen={isOpenEmailChangeModal}
        onClose={() => {
          setIsOpenEmailChangeModal(false);
          setEmailIsModified(false);
          handleProfileLoad();
        }}
        onConfirm={() => {
          setIsOpenEmailChangeModal(false);
          setIsConfirmEmailUpdate(true);
        }}
      />
    </>
  );
};

export default UserProfileEdit;
