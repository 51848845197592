import { FC, useEffect } from 'react';
import Loader from '../../components/loader/Loader';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { requestActiveAccount } from '../../services/account.service';
import { handleUnauthorizedUser } from '../../services/auth.service';
import { requestUserPersonalInfo } from '../../services/user.service';
import { PermissionService } from '../../services/permission.service';
import { ACCOUNT_PATHS, APP_PATHS, ROUTE_PATHS } from '../../constants/routePaths';
import { setActiveContext } from '../../redux/reducers/authSlice';

type AuthWrapperProps = {
  children: React.ReactNode;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  permissionService: PermissionService;
};

const AuthWrapper: FC<AuthWrapperProps> = ({ children, setLoading, loading, permissionService }) => {
  const token = useAppSelector(state => state.auth.appContext.token);
  const { accounts } = useAppSelector(state => state.user);
  const { firstName } = useAppSelector(state => state.user);
  const accountToken = useAppSelector(state => state.auth.accountContext.token);

  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleUnauthorizedUser();
  }, []);

  useEffect(() => {
    if (token) {
      requestUserPersonalInfo();
    }
  }, [token]);

  useEffect(() => {
    if (firstName) {
      if (accounts && accounts.length > 0) {
        requestActiveAccount();
      } else if (permissionService.userHasRSGAdminPermission()) {
        navigate(ROUTE_PATHS.APP_TRANSACTION_MANAGEMENT, { replace: true });
      }
      setLoading(false);
    }
  }, [firstName, accounts, setLoading]);

  useEffect(() => {
    const routeBase = location.pathname.split('/')[2];

    if (Object.values(ACCOUNT_PATHS).includes(routeBase) && accountToken) {
      dispatch(setActiveContext('account'));
    } else {
      dispatch(setActiveContext('app'));
    }
  }, [location, dispatch, accountToken]);

  return loading ? (
    <Loader loaderSize="large" pageLoader />
  ) : !token ? (
    <Navigate to={ROUTE_PATHS.SIGN_IN} replace />
  ) : (
    <div>{children}</div>
  );
};

export default AuthWrapper;
