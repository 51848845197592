import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectStorageItems from '../../components/select-storage-items/SelectStorageItems';
import SelectSpecimenFromCanisterTable from '../../components/select-specimen-from-canister/SelectSpecimenFromCanisterTable';
import { SelectedSpecimenQuantity } from '../../components/specimen-tables/SelectSpecimens';
import { BackButton } from '../../components';
import { StorageTankOptions } from '../../types/enums/storageTank.enum';
import { MoveInventory, selectOption } from '../../types/interfaces';
import { requestMoveInventory } from '../../api/inventoryApi';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS, PAGE_HEADER_CONSTANTS } from '../../constants/common';
import { redirectToTopOfThePage } from '../../utils/commonUtils';

export interface InventoryManagementMoveAutoFill {
  site: selectOption;
  tank: selectOption;
  canister: selectOption;
  selectedSpecimenId: number;
}

const InventoryManagementMove = (): JSX.Element => {
  const { state: autoFill } = useLocation();
  const navigate = useNavigate();

  const [movingFromSite, setMovingFromSite] = useState<selectOption>(autoFill?.site ?? { value: '', name: '' });
  const [movingFromTank, setMovingFromTank] = useState<selectOption>(autoFill?.tank ?? { value: '', name: '' });
  const [movingFromCanister, setMovingFromCanister] = useState<selectOption>(autoFill?.canister ?? { value: '', name: '' });
  const [movingToSite, setMovingToSite] = useState<selectOption>({ value: '', name: '' });
  const [movingToTank, setMovingToTank] = useState<selectOption>({ value: '', name: '' });
  const [movingToCanister, setMovingToCanister] = useState<selectOption>({ value: '', name: '' });
  const [notes, setNotes] = useState('');
  const [selectedQuantities, setSelectedQuantities] = useState<{ valid: boolean; value: SelectedSpecimenQuantity[] }>({
    valid: false,
    value: [],
  });

  const isValid = () => {
    return (
      movingFromCanister.value.length &&
      movingToCanister.value.length &&
      selectedQuantities.value.length &&
      selectedQuantities.valid
    );
  };

  const getSelectedSpecimens = () => {
    const moveSpecimens = selectedQuantities.value.map((selectedQuantity: SelectedSpecimenQuantity) => {
      return {
        specimenId: selectedQuantity.specimenId,
        quantity: selectedQuantity.quantity,
        quantityUsed: 0,
        quantityLost: 0,
        selectedCanisterId: Number(movingFromCanister.value),
        destinationCanisterId: Number(movingToCanister.value),
      };
    });
    return moveSpecimens;
  };

  const clearPageValues = () => {
    setMovingFromSite({ value: '', name: '' });
    setMovingFromTank({ value: '', name: '' });
    setMovingFromCanister({ value: '', name: '' });
    setMovingToSite({ value: '', name: '' });
    setMovingToTank({ value: '', name: '' });
    setMovingToCanister({ value: '', name: '' });
    setNotes('');
    setSelectedQuantities({ valid: false, value: [] });
  };

  const handleSubmit = async () => {
    const selectedSpecimens = getSelectedSpecimens();
    const moveSpecimensRequestBody: MoveInventory = { specimens: selectedSpecimens, notes: notes };
    try {
      await requestMoveInventory(moveSpecimensRequestBody);
      showToast.success(toastMessages.TRANSACTION_SUCCESS);
      clearPageValues();
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  return (
    <div className="inventory-management inventory-management-move">
      {autoFill && (
        <BackButton onClick={() => navigate(ROUTE_PATHS.APP_INVENTORY_MANAGEMENT_CANISTER_CONTENTS, { state: autoFill })} />
      )}
      <div className="inventory-action card">
        <h1>{PAGE_HEADER_CONSTANTS.MOVE_INVENTORY}</h1>
        <h2>{LABEL_CONSTANTS.MOVING_FROM}</h2>
        <SelectStorageItems
          selectedSite={movingFromSite}
          selectedTank={movingFromTank}
          selectedCanister={movingFromCanister}
          setSelectedSite={setMovingFromSite}
          setSelectedTank={setMovingFromTank}
          setSelectedCanister={setMovingFromCanister}
          storageTankOptions={StorageTankOptions.Both}
          activeLocationsOnly
        />

        <h2>{LABEL_CONSTANTS.INVENTORY}:</h2>
        {movingFromCanister.value && (
          <SelectSpecimenFromCanisterTable
            canisterId={Number(movingFromCanister.value)}
            onChange={e => setSelectedQuantities(e)}
            selectedSpecimenId={autoFill?.selectedSpecimenId}
          />
        )}

        <h2>{LABEL_CONSTANTS.MOVING_TO}</h2>
        <SelectStorageItems
          selectedSite={movingToSite}
          selectedTank={movingToTank}
          selectedCanister={movingToCanister}
          setSelectedSite={setMovingToSite}
          setSelectedTank={setMovingToTank}
          setSelectedCanister={setMovingToCanister}
          storageTankOptions={StorageTankOptions.Both}
          activeLocationsOnly
        />
        <div className="form-row expanded-text-area">
          <label htmlFor="notes">{LABEL_CONSTANTS.NOTES}</label>
          <textarea
            id="notes"
            name="notes"
            placeholder="Notes"
            value={notes}
            onChange={e => setNotes(e.target.value)}
            rows={4}
            cols={50}
          />
        </div>
        <br />

        <br />
        <div className="submit-button">
          <button
            className="button green small"
            disabled={!isValid()}
            onClick={() => {
              handleSubmit();
              redirectToTopOfThePage();
            }}>
            {BUTTON_CONSTANTS.SUBMIT}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InventoryManagementMove;
