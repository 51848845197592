import { createSlice } from '@reduxjs/toolkit';
import { ActiveAccount } from '../../types/interfaces/account.interfaces';
import { getLocalActiveAccountId } from '../../utils/localStorageHandler';

const initialState: ActiveAccount = {
  accountId: getLocalActiveAccountId(),
  name: '',
  billingEnabled: true,
  isCatalogAccount: false,
  locked: false,
  contactFirstName: '',
  contactLastName: '',
  contactEmail: '',
  contactPhone: '',
  timezoneInfo: '',
  users: [],
  attributes: {
    AccountStoragePlan: undefined,
  },
  addresses: [],
  owner: {
    userId: 0,
    username: '',
    firstName: '',
    lastName: '',
    contactPhone: '',
    verified: false,
    roles: [],
    scopes: [],
    accounts: [],
    tenantId: '',
    timezoneInfo: '',
    addresses: [],
    enabled: false,
    profileImageId: '',
  },
  billingAmount: {
    outstandingBalanceCents: 0,
    lateFeeCents: 0,
    penaltyCents: 0,
    financeChargeRate: 0,
    financeChargeCents: 0,
    gracePeriodDaysToClearOutstanding: 0,
    totalFineAmountCents: 0,
  },
};

export const activeAccountSlice = createSlice({
  name: 'activeAccount',
  initialState,
  reducers: {
    storeActiveAccount: (state, action) => {
      state.accountId = action.payload.accountId;
      state.name = action.payload.name;
      state.billingEnabled = action.payload.billingEnabled;
      state.isCatalogAccount = action.payload.isCatalogAccount;
      state.locked = action.payload.locked;
      state.contactFirstName = action.payload.contactFirstName;
      state.contactLastName = action.payload.contactLastName;
      state.contactEmail = action.payload.contactEmail;
      state.contactPhone = action.payload.contactPhone;
      state.timezoneInfo = action.payload.timezoneInfo;
      state.addresses = action.payload.addresses;
      state.owner = action.payload.owner;
      state.users = action.payload.users;
      state.attributes = action.payload.attributes;
    },
    clearActiveAccount: () => {
      return { ...initialState, accountId: getLocalActiveAccountId() };
    },
    storeBillingAmount: (state, action) => {
      state.billingAmount = {
        outstandingBalanceCents: action.payload.outstandingBalanceCents,
        lateFeeCents: action.payload.lateFeeCents,
        penaltyCents: action.payload.penaltyCents,
        financeChargeRate: action.payload.financeChargeRate,
        financeChargeCents: action.payload.financeChargeCents,
        gracePeriodDaysToClearOutstanding: action.payload.gracePeriodDaysToClearOutstanding,
        totalFineAmountCents: action.payload.totalFineAmountCents,
      };
    },
  },
});

export const { storeActiveAccount, clearActiveAccount, storeBillingAmount } = activeAccountSlice.actions;

export default activeAccountSlice.reducer;
