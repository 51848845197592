import { FC, useEffect, useState } from 'react';
import Loader from '../loader/Loader';
import AddStorageLocationModal from './modal/AddStorageLocationModal';
import { Specimen, SpecimenLocation } from '../../types/interfaces/specimen.interfaces';
import { formatPositiveNumber } from '../../utils/commonUtils';
import { getSpecimenLocations } from '../../api/specimensApi';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';
import { LABEL_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../constants/common';

export type CanisterQuantity = {
  actualQty: number;
  oldQty: number;
  specimenLocation: SpecimenLocation;
};

type ReconcileCanisterSpecimensProps = {
  specimen: Specimen;
  onChange: (e: { value: CanisterQuantity[]; valid: boolean }) => void;
};

const ReconcileCanisterSpecimens: FC<ReconcileCanisterSpecimensProps> = ({
  specimen,
  onChange,
}: ReconcileCanisterSpecimensProps): JSX.Element => {
  const [isAddStorageLocationModalOpen, setIsAddStorageLocationModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [specimens, setSpecimens] = useState<CanisterQuantity[]>([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data: specimenLocations } = await getSpecimenLocations({
          include: 'StorageCanister.StorageTank.StorageSite',
          filter: `specimenId eq ${specimen.specimenId}`,
        });
        setSpecimens(
          specimenLocations.map((spec: SpecimenLocation) => {
            return { specimenLocation: spec, oldQty: spec.quantity, actualQty: spec.quantity };
          }),
        );
      } catch {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setLoading(false);
      }
    };

    if (specimen) {
      fetch();
    }
  }, [specimen]);

  useEffect(() => {
    onChange({ value: specimens, valid: !loading });
  }, [specimens]);

  const getStorageTotal = (): number => {
    return specimens.reduce((acc: number, spec: CanisterQuantity): number => acc + spec.actualQty, 0);
  };

  const onActualQtyChange = (e: any, index: number) => {
    const count = formatPositiveNumber(e.target.value);
    let newSpecimens = [...specimens];
    newSpecimens[index].actualQty = count;
    setSpecimens(newSpecimens);
  };

  const handleAddNewSpecimenLocation = (specimenLocation: CanisterQuantity) => {
    const isDuplicateLocation = specimens.some(
      specimen => specimen.specimenLocation.storageCanisterId === specimenLocation.specimenLocation.storageCanisterId,
    );

    if (isDuplicateLocation) {
      showToast.error(toastMessages.DUPLICATE_LOCATION);
    } else {
      let newSpecimens = [...specimens, specimenLocation];
      setSpecimens(newSpecimens);
      setIsAddStorageLocationModalOpen(false);
    }
  };

  return (
    <>
      <AddStorageLocationModal
        isOpen={isAddStorageLocationModalOpen}
        setIsOpen={setIsAddStorageLocationModalOpen}
        onConfirm={specimenLocation => handleAddNewSpecimenLocation(specimenLocation)}
        specimenId={specimen?.specimenId}
      />
      <div className="desk-reconcile-canister-specimens">
        <div className="specimen-table">
          <table>
            <thead>
              <tr>
                <th>{TABLE_HEADER_CONSTANTS.LOCATION}</th>
                <th>{TABLE_HEADER_CONSTANTS.ITEM}</th>
                <th>{TABLE_HEADER_CONSTANTS.QTY_IN_CANISTER}</th>
                <th>{TABLE_HEADER_CONSTANTS.ACTUAL_QTY}</th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                specimens?.map((spec: CanisterQuantity, index: number) => {
                  return (
                    <tr key={`canister-specimen-row:${spec.specimenLocation.specimenId}-index:${index}`}>
                      <td>
                        {spec.specimenLocation?.storageCanister?.storageTank?.storageSite?.name}/
                        {spec.specimenLocation?.storageCanister?.storageTank?.name}/{spec.specimenLocation?.storageCanister?.name}
                      </td>
                      <td>
                        {specimen.animal?.code} - {new Date(specimen.freezeDate!).toLocaleDateString()}
                      </td>
                      <td>{spec.oldQty}</td>
                      <td>
                        <input type="text" placeholder="0" value={spec.actualQty} onChange={e => onActualQtyChange(e, index)} />
                      </td>
                    </tr>
                  );
                })}
              {!loading && specimens?.length === 0 && (
                <tr>
                  <td colSpan={6}>
                    <>{LABEL_CONSTANTS.NO_LOCATION_FOUND}</>
                  </td>
                </tr>
              )}
              {loading && (
                <tr>
                  <td colSpan={6}>
                    <>
                      <br />
                      <br />
                      <Loader loaderSize="small" />
                      <br />
                      <br />
                    </>
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={2} />
                <td>
                  <div className="storage-total-quantity quantity">{getStorageTotal().toString()}</div>
                </td>
                <td>
                  <button type="button" onClick={() => setIsAddStorageLocationModalOpen(true)} className="add-location-button ">
                    +
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/** Mobile View */}
      <div className="xs-reconcile-canister-specimens">
        {loading ? (
          <Loader addedSpace loaderSize="small" />
        ) : !loading && specimens?.length === 0 ? (
          <div className="result-item">{LABEL_CONSTANTS.NO_LOCATION_FOUND}</div>
        ) : (
          <div>
            {specimens.length > 0 &&
              specimens &&
              specimens.map((spec, index) => {
                return (
                  <div className="card max-width" key={`${spec?.specimenLocation?.specimenId}-index:${index}`}>
                    <div className="reconcile-canister-specimens">
                      <div className="card-content-section">
                        <div className="left center column">
                          <label>{`${TABLE_HEADER_CONSTANTS.LOCATION}: `}</label>
                          <label>{`${spec.specimenLocation?.storageCanister?.storageTank?.storageSite?.name} /  ${spec.specimenLocation?.storageCanister?.storageTank?.name} / ${spec.specimenLocation?.storageCanister?.name} `}</label>
                        </div>
                        <div className="right center column">
                          <label>{`${TABLE_HEADER_CONSTANTS.ITEM}: `}</label>
                          <label>{`${specimen.animal?.code} - ${new Date(specimen.freezeDate!).toLocaleDateString()}`}</label>
                        </div>
                      </div>
                      <div className="card-content-section">
                        <div className="left center">
                          <label>{`${TABLE_HEADER_CONSTANTS.QTY_IN_CANISTER}: ${spec.oldQty}`}</label>
                        </div>
                        <div className="right center column">
                          <label>{`${TABLE_HEADER_CONSTANTS.ACTUAL_QTY}: `}</label>
                          <input type="text" placeholder="0" value={spec.actualQty} onChange={e => onActualQtyChange(e, index)} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className="location-footer">
              <button type="button" onClick={() => setIsAddStorageLocationModalOpen(true)} className="add-location-button ">
                +
              </button>
              <div className="total-quantity">
                <label>{TABLE_HEADER_CONSTANTS.ACTUAL_QTY}: &nbsp;</label>
                {getStorageTotal().toString()}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReconcileCanisterSpecimens;
