const PlusCircleSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.9987 14.5833C10.2584 14.5833 10.4763 14.4953 10.6523 14.3193C10.8283 14.1433 10.916 13.9257 10.9154 13.6666V10.9166H13.6883C13.948 10.9166 14.1619 10.8286 14.33 10.6526C14.498 10.4766 14.582 10.259 14.582 9.99992C14.582 9.7402 14.494 9.52234 14.318 9.34634C14.142 9.17034 13.9245 9.08264 13.6654 9.08325H10.9154V6.31034C10.9154 6.05061 10.8274 5.83672 10.6514 5.66867C10.4754 5.50061 10.2578 5.41659 9.9987 5.41659C9.73898 5.41659 9.52112 5.50459 9.34512 5.68059C9.16912 5.85659 9.08142 6.07414 9.08203 6.33325V9.08325H6.30911C6.04939 9.08325 5.8355 9.17125 5.66745 9.34725C5.49939 9.52325 5.41537 9.74081 5.41537 9.99992C5.41537 10.2596 5.50337 10.4775 5.67937 10.6535C5.85537 10.8295 6.07292 10.9172 6.33203 10.9166H9.08203V13.6895C9.08203 13.9492 9.17003 14.1631 9.34603 14.3312C9.52203 14.4992 9.73959 14.5833 9.9987 14.5833ZM9.9987 19.1666C8.73064 19.1666 7.53898 18.9258 6.4237 18.4443C5.30842 17.9627 4.33828 17.3097 3.51328 16.4853C2.68828 15.6603 2.03531 14.6902 1.55436 13.5749C1.07342 12.4596 0.832642 11.268 0.832031 9.99992C0.832031 8.73186 1.07281 7.5402 1.55436 6.42492C2.03592 5.30964 2.68889 4.3395 3.51328 3.5145C4.33828 2.6895 5.30842 2.03653 6.4237 1.55559C7.53898 1.07464 8.73064 0.833863 9.9987 0.833252C11.2668 0.833252 12.4584 1.07403 13.5737 1.55559C14.689 2.03714 15.6591 2.69011 16.4841 3.5145C17.3091 4.3395 17.9624 5.30964 18.4439 6.42492C18.9255 7.5402 19.166 8.73186 19.1654 9.99992C19.1654 11.268 18.9246 12.4596 18.443 13.5749C17.9615 14.6902 17.3085 15.6603 16.4841 16.4853C15.6591 17.3103 14.689 17.9636 13.5737 18.4452C12.4584 18.9267 11.2668 19.1672 9.9987 19.1666Z"
        fill="#272829"
      />
    </svg>
  );
};

export default PlusCircleSvg;
