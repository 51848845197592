import { AccountRoles } from '../types/enums/accountRoles.enum';
import { AppRoles } from '../types/enums/appRoles.enum';
import { AccountUserInfo, UserRole } from '../types/interfaces/user.interfaces';

export class PermissionService {
  accountRoles: AccountRoles[];
  appRoles: AppRoles[];
  enabled: boolean;
  verified: boolean;

  constructor(accountRoles: AccountRoles[], appRoles: AppRoles[], enabled: boolean, verified: boolean) {
    this.accountRoles = accountRoles;
    this.appRoles = appRoles;
    this.enabled = enabled;
    this.verified = verified;
  }

  userIsEnabled() {
    return this.enabled;
  }

  userIsVerified() {
    return this.verified;
  }

  userHasRSGAdminPermission() {
    return this.appRoles?.includes(AppRoles.Admin) || this.appRoles?.includes(AppRoles.AppAdmin);
  }

  userHasTeamManagementPermission() {
    return (
      this.accountRoles?.includes(AccountRoles.AccountAdmin) ||
      this.accountRoles?.includes(AccountRoles.AccountOwner) ||
      this.accountRoles?.includes(AccountRoles.AccountManager)
    );
  }

  userHasSettingsPermission() {
    return this.accountRoles?.includes(AccountRoles.AccountAdmin) || this.accountRoles?.includes(AccountRoles.AccountOwner);
  }

  userHasInventoryPermission() {
    return (
      this.accountRoles?.includes(AccountRoles.AccountManager) ||
      this.accountRoles?.includes(AccountRoles.AccountUser) ||
      this.accountRoles?.includes(AccountRoles.AccountOwner)
    );
  }
}

export const specifiedAccountUserHasInventoryPermission = (user: AccountUserInfo): boolean => {
  const hasForbiddenRole = user.roles.reduce((acc: boolean, role: UserRole): boolean => {
    return acc || role.name === AccountRoles.AccountAdmin || role.name === AccountRoles.AccountStakeholder;
  }, false);
  return !hasForbiddenRole;
};
