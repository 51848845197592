import { FC } from 'react';

type DropDownSVGProps = {
  reversed?: boolean;
  right?: boolean;
};

const DropDownSVG: FC<DropDownSVGProps> = ({ reversed = false, right = false }): JSX.Element => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={right ? { rotate: '-90deg' } : {}}>
      {reversed ? (
        <path d="M6 0.1L12 6.1L10.6 7.5L6 2.9L1.4 7.5L0 6.1L6 0.1Z" fill="currentColor" />
      ) : (
        <path d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z" fill="currentColor" />
      )}
    </svg>
  );
};
export default DropDownSVG;
