import React, { useState } from 'react';
import { FilterDropdown, Modal } from '../../../components';
import { FilterOption } from '../../../components/custom-input/FilterDropdown';
import { filtersType } from '../Inventory';
import { ANIMAL_CONSTANTS, BUTTON_CONSTANTS, LABEL_CONSTANTS } from '../../../constants/common';

interface FilterInventoryModalProps {
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
  speciesOptions: FilterOption<number | null>[];
  breedOptions: FilterOption<number | null>[];
  handleGetBreedOptions: (speciesId: number) => void;
  setFilters: (filters: filtersType) => void;
}

const FilterInventoryModal = ({
  isOpen,
  setIsOpen,
  speciesOptions,
  breedOptions,
  handleGetBreedOptions,
  setFilters,
}: FilterInventoryModalProps) => {
  const [filterInventory, setFilterInventory] = useState<filtersType>({
    search: '',
    type: { name: '', value: null },
    species: { name: '', value: null },
    breed: { name: '', value: null },
    sort: { name: '', value: null },
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setFilters(filterInventory);
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className="filter-inventory">
        <h4>{LABEL_CONSTANTS.FILTER}</h4>
        <hr />
        <form action="submit" onSubmit={(e: React.FormEvent) => handleSubmit(e)}>
          <div className="inventory-type">
            <h5>{LABEL_CONSTANTS.INVENTORY_TYPE}</h5>
            <div className="radio-row">
              <input
                type="radio"
                id="semen-type-true"
                name="semen-type"
                value={filterInventory.type.name}
                onChange={() => setFilterInventory({ ...filterInventory, type: { name: 'Semen', value: 1 } })}
                checked={filterInventory.type.name === 'Semen'}
              />
              <label htmlFor="semen">{LABEL_CONSTANTS.SEMEN}</label>
              <input
                type="radio"
                id="embryo-type-true"
                name="embryos-type"
                value={filterInventory.type.name}
                onChange={() => setFilterInventory({ ...filterInventory, type: { name: 'Embryo', value: 2 } })}
                checked={filterInventory.type.name === 'Embryo'}
              />
              <label htmlFor="embryo">{LABEL_CONSTANTS.EMBRYOS}</label>
            </div>
          </div>
          <hr />
          <div className="filter-option">
            <label>{ANIMAL_CONSTANTS.SPECIES}</label>
            <FilterDropdown
              options={speciesOptions}
              value={filterInventory.species}
              onChange={species => {
                handleGetBreedOptions(species.value);
                setFilterInventory({ ...filterInventory, breed: { name: '', value: null }, species: species });
              }}
            />
          </div>
          <hr />
          <div className="filter-option">
            <label>{ANIMAL_CONSTANTS.BREED}</label>
            <FilterDropdown
              options={breedOptions}
              value={filterInventory.breed}
              onChange={breed => setFilterInventory({ ...filterInventory, breed: breed })}
            />
          </div>
          <hr />
          <div className="sort-inventory-by">
            <h5>{LABEL_CONSTANTS.SORT_BY}</h5>
            <div className="radio-row">
              <input
                type="radio"
                id="oldest-to-newest"
                name="oldest-to-newest"
                value={filterInventory.sort.name}
                onChange={() => {
                  setFilterInventory({ ...filterInventory, sort: { name: 'oldest-to-newest', value: 'birthDate' } });
                }}
                checked={filterInventory.sort.name === 'oldest-to-newest'}
              />
              <label htmlFor="oldest-to-newest">{LABEL_CONSTANTS.OLDEST_TO_NEWEST}</label>
              <input
                type="radio"
                id="newest-to-oldest"
                name="newest-to-oldest"
                value={filterInventory.sort.name}
                onChange={() => {
                  setFilterInventory({ ...filterInventory, sort: { name: 'newest-to-oldest', value: '^birthDate' } });
                }}
                checked={filterInventory.sort.name === 'newest-to-oldest'}
              />
              <label htmlFor="newest-to-oldest">{LABEL_CONSTANTS.NEWEST_TO_OLDEST}</label>
            </div>
          </div>
          <hr />
          <div className="footer">
            <button type="reset" onClick={() => handleClose()} className="button green inverted mr-1">
              {BUTTON_CONSTANTS.CANCEL}
            </button>
            <button type="submit" className="button green">
              {BUTTON_CONSTANTS.APPLY}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default FilterInventoryModal;
