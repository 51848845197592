import { refreshToken, signIn } from '../api/authApi';
import { toastMessages } from '../constants/errorMessages';
import { ROUTE_PATHS } from '../constants/routePaths';
import { clearAuth, storeAppAuth } from '../redux/reducers/authSlice';
import { storeUserInfo } from '../redux/reducers/userSlice';
import { store } from '../redux/store';
import { SignInFormData } from '../types/interfaces/auth.interfaces';
import { decodeJwtToken } from '../utils/decodeJwtToken';
import {
  setLocalRefreshToken,
  setLocalUser,
  clearLocalData,
  setLocalRefreshTokenExpiry,
  getLocalRefreshTokenExpiry,
  setLocalAccessToken,
  getLocalAccessToken,
} from '../utils/localStorageHandler';
import { showToast } from './toast.service';
import { AUTH_API_ENDPOINTS } from '../constants/apiEndpoints';
import { appContextGet } from '../api/httpCommon/appAxios.service';
import { AxiosResponse } from 'axios';

export const signInUser = async (formData: SignInFormData, rememberMe: boolean) => {
  try {
    const { email } = formData;

    const { data } = await signIn(formData);
    const { sub } = data.token && decodeJwtToken(data.token);
    let userId = sub;

    clearLocalData();
    if (data.refreshToken && data.refreshTokenExpiry && data.token) {
      setLocalAccessToken(data.token);
      setLocalUser(userId, email, rememberMe);
      setLocalRefreshToken(data.refreshToken, rememberMe);
      setLocalRefreshTokenExpiry(data.refreshTokenExpiry, rememberMe);
    }
    store.dispatch(storeAppAuth(data));
    store.dispatch(storeUserInfo({ userId }));
  } catch (err: any) {
    throw err;
  }
};

export const getAccountContextById = (params: { accountId: number }): Promise<AxiosResponse<{}, any>> => {
  return appContextGet(AUTH_API_ENDPOINTS.ACCOUNT, params);
};

export const signOutUser = async (attempts = 0) => {
  const maxAttempts = 5;
  try {
    await clearData();
  } catch {
    if (attempts < maxAttempts) {
      signOutUser(attempts + 1);
    } else {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  } finally {
    window.location.href = ROUTE_PATHS.SIGN_IN;
  }
};

const clearData = () => {
  return new Promise<void>((resolve, reject) => {
    try {
      store.dispatch(clearAuth());
      clearLocalData();
      resolve();
    } catch (err) {
      reject(err);
    }
  });
};

export const handleUnauthorizedUser = () => {
  if (isAccessTokenExpired()) {
    if (isRefreshTokenExpired()) {
      showToast.error(toastMessages.SESSION_EXPIRED);
      signOutUser();
    } else {
      refreshAccessToken();
    }
  }
};

const refreshAccessToken = async () => {
  try {
    const { data } = await refreshToken();
    if (data.token && data.refreshToken && data.refreshTokenExpiry) {
      setLocalAccessToken(data.token);
      setLocalRefreshToken(data.refreshToken, true);
      store.dispatch(storeAppAuth(data));
    } else {
      signOutUser();
    }
  } catch (err: any) {
    signOutUser();
    throw err;
  }
};

const isAccessTokenExpired = () => {
  const accessToken = getLocalAccessToken();
  if (accessToken) {
    const { exp } = decodeJwtToken(accessToken);
    const expiry = new Date(exp * 1000);
    const now = new Date();
    if (expiry > now) {
      return false;
    }
  }
  return true;
};

const isRefreshTokenExpired = () => {
  const refreshTokenExpiry = getLocalRefreshTokenExpiry();
  if (refreshTokenExpiry) {
    const expiry = new Date(refreshTokenExpiry);
    const now = new Date();
    if (expiry > now) {
      return false;
    }
  }
  return true;
};
