import { AxiosResponse } from 'axios';
import { ANIMALS_API_ENDPOINTS } from '../constants/apiEndpoints';
import { appContextDelete, appContextGet, appContextPost, appContextPut } from './httpCommon/appAxios.service';
import { Animal } from '../types/interfaces/animal.interfaces';
import { ODataParams, PagedResponse, StandardParams, GetAccountAnimalParams } from '../types/interfaces/apiParams.interfaces';
import { Attachment } from '../types/interfaces';

export const createAnimal = (animal: Animal): Promise<AxiosResponse<number, any>> => {
  return appContextPost(ANIMALS_API_ENDPOINTS.CREATE, animal);
};

export const deleteMultipleAnimalAttachments = (animalId: string, attachmentIds: number[]): Promise<AxiosResponse<{}, any>> => {
  return appContextDelete(ANIMALS_API_ENDPOINTS.ANIMALS + animalId + ANIMALS_API_ENDPOINTS.ATTACHMENTS, {}, attachmentIds);
};

export const editAnimal = (id: string, animal: Animal, params?: {}): Promise<AxiosResponse<{}, any>> => {
  return appContextPut(ANIMALS_API_ENDPOINTS.EDIT + `/${id}`, animal, params);
};

export const getAccountAnimals = (
  accountId: number,
  params?: GetAccountAnimalParams,
): Promise<AxiosResponse<Animal[] | PagedResponse<Animal>, any>> => {
  return appContextGet(ANIMALS_API_ENDPOINTS.GET_ACCOUNT_ + accountId, params);
};

export const getAccountAnimalsOdata = (
  accountId: number,
  params?: ODataParams,
): Promise<AxiosResponse<Animal[] | PagedResponse<Animal>, any>> => {
  return appContextGet(ANIMALS_API_ENDPOINTS.GET_ACCOUNT_ + accountId + ANIMALS_API_ENDPOINTS.ODATA, params);
};

export const getAllAccountAnimals = (
  accountId: number,
  params?: GetAccountAnimalParams,
): Promise<AxiosResponse<Animal[] | PagedResponse<Animal>, any>> => {
  return appContextGet(ANIMALS_API_ENDPOINTS.GET_ACCOUNT_ + accountId + ANIMALS_API_ENDPOINTS.INVENTORY_OWNERSHIP, params);
};

export const getAllAnimalAttachments = (animalId: string, params?: {}): Promise<AxiosResponse<Attachment[], any>> => {
  return appContextGet(ANIMALS_API_ENDPOINTS.ANIMALS + animalId + ANIMALS_API_ENDPOINTS.ATTACHMENTS, params);
};

export const getAnimalAttachment = (
  animalId: string,
  attachmentId: number,
  accessToken: string,
): Promise<AxiosResponse<{}, any>> => {
  return appContextGet(
    ANIMALS_API_ENDPOINTS.ANIMALS + animalId + ANIMALS_API_ENDPOINTS.ATTACHMENTS + `/${attachmentId}/` + accessToken,
  );
};

export const getAnimalById = (id: string, params?: { include: string }): Promise<AxiosResponse<Animal, any>> => {
  return appContextGet(ANIMALS_API_ENDPOINTS.GET + `/${id}`, params);
};

export const getAnimalCount = (params?: { filter: string }): Promise<AxiosResponse<{}, any>> => {
  return appContextGet(ANIMALS_API_ENDPOINTS.GET_COUNT, params);
};

export const getAnimals = (params?: StandardParams): Promise<AxiosResponse<Animal[] | PagedResponse<Animal>, any>> => {
  return appContextGet(ANIMALS_API_ENDPOINTS.GET, params);
};

export const setGlobalThreshold = (thresholdValue: number): Promise<AxiosResponse<{}, any>> => {
  return appContextPut(ANIMALS_API_ENDPOINTS.SET_GLOBAL_THRESHOLD, {}, { threshold: thresholdValue });
};

export const uploadAnimalAttachment = (animalId: string, attachmentFiles: FormData): Promise<AxiosResponse<{}, any>> => {
  return appContextPost(ANIMALS_API_ENDPOINTS.ANIMALS + animalId + ANIMALS_API_ENDPOINTS.ATTACHMENTS, attachmentFiles);
};
