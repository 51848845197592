import { FC, useEffect, useState } from 'react';
import StoragePlanCard from '../../pages/auth/storage-plans/storage-plan-card/StoragePlanCard';
import './paymentInformation.scss';

type ColumnData = {
  storagePlanId: number;
  quantityHeader: string;
  costHeader: number;
  unitOfMeasurement: string;
  period: string;
};

interface PaymentInformationProps {
  header: string;
  description: string;
  columnsData: ColumnData[];
}

const PaymentInformation: FC<PaymentInformationProps> = ({ header, description, columnsData }) => {
  const [splicedStoragePlans, setSplicedStoragePlans] = useState<ColumnData[][]>([]);

  useEffect(() => {
    if (columnsData.length != 0) {
      const storagePlansCopy = [...columnsData];
      const splicedStoragePlansArray = [];
      while (storagePlansCopy.length > 0) {
        splicedStoragePlansArray.push(storagePlansCopy.splice(0, 2));
      }
      setSplicedStoragePlans(splicedStoragePlansArray);
    }
  }, [columnsData]);

  return (
    <div className="payment-information">
      <h3 className="top-left-header">{header}</h3>
      <p className="description">{description}</p>
      <hr className="divider" />
      <div className="row">
        {columnsData.map((data, index) => (
          <StoragePlanCard
            key={index}
            storagePlanId={data.storagePlanId}
            name={data.quantityHeader || 'Error'}
            costInCents={data.costHeader}
            unitOfMeasurement={data.unitOfMeasurement}
            period={data.period}
          />
        ))}
      </div>
      <div className="xs-storage-plans-list">
        <div className="storage-plan-row">
          {splicedStoragePlans.map((splicedStoragePlan, index) => (
            <div key={index} className="row">
              {splicedStoragePlan.map((storagePlan, index) => (
                <div key={index + storagePlan.storagePlanId} className="cell">
                  <StoragePlanCard
                    key={index}
                    storagePlanId={storagePlan.storagePlanId}
                    name={storagePlan.quantityHeader}
                    costInCents={storagePlan.costHeader}
                    unitOfMeasurement={storagePlan.unitOfMeasurement}
                    period={storagePlan.period}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <hr className="divider" />
    </div>
  );
};

export default PaymentInformation;
