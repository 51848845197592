import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { FilterDropdown, Loader, ValidatedInput } from '../../components';
import { FilterOption } from '../../components/custom-input/FilterDropdown';
import { ValidatedState } from '../../components/custom-input/ValidatedInput';
import { Account, Animal, PagedResponse } from '../../types/interfaces';
import { getAccounts } from '../../api/accountApi';
import { getAccountAnimals, setGlobalThreshold } from '../../api/animalsApi';
import { getCatalogQuantity } from '../../api/catalogApi';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS, PAGE_HEADER_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../constants/common';

const InventoryManagementThreshold = (): JSX.Element => {
  const [dropdownAccounts, setDropdownAccounts] = useState<FilterOption<string>[]>([]);
  const [account, setAccount] = useState<FilterOption<string>>({ name: '', value: '' });
  const [globalThresholdUpdated, setGlobalThresholdUpdated] = useState<boolean>(false);
  const [globalThresholdValue, setGlobalThresholdValue] = useState<ValidatedState>({ valid: false, value: '--' });
  const [searchAnimal, setSearchAnimal] = useState<string>('');
  const [tableData, setTableData] = useState<any[][]>([]);
  const [offset, setOffset] = useState<number>(0);

  const limit = 8;
  const scrollGap = 32;
  const minHeight = 0;

  const ref = useRef<any>(null);
  const hasAllAnimalResults: MutableRefObject<boolean> = useRef<boolean>(false);
  const isLoadingAdditionalPage: MutableRefObject<boolean> = useRef<boolean>(false);
  const callNumber: MutableRefObject<number> = useRef<number>(0);

  useEffect(() => {
    fetchCatalogAccounts();
  }, []);

  const fetchCatalogAccounts = async () => {
    try {
      const { data: filteredAccounts } = await getAccounts(undefined, { filter: 'isCatalogAccount eq true' });
      if (Array.isArray(filteredAccounts)) {
        const dropdownAccountOptions = filteredAccounts.map((account: Account): FilterOption<string> => {
          return { name: account!.name, value: account?.accountId?.toString() ?? '' };
        });

        if (dropdownAccountOptions.length > 0) {
          setDropdownAccounts(dropdownAccountOptions);
          getGlobalThreshold(dropdownAccountOptions[0]);
        }
      }
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const getGlobalThreshold = async (account: FilterOption<string>) => {
    try {
      if (account.value) {
        const { data: responses } = await getAccountAnimals(Number(account.value), { limit: 1 });
        if (responses) {
          const animals = (responses as PagedResponse<Animal>).result;
          setGlobalThresholdValue({ valid: true, value: String(animals[0]?.threshold) });
        }
      }
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const loadAnimalData = async () => {
    callNumber.current++;
    if (hasAllAnimalResults.current) {
      return;
    }
    const callNum = callNumber.current;
    try {
      const { data: response } = await getAccountAnimals(Number(account.value), {
        limit: limit,
        offset: offset,
        searchString: searchAnimal?.length > 0 ? searchAnimal : undefined,
      });

      if (offset === 0 && 'current' in ref) {
        ref.current?.scroll({ top: 0 });
      }

      if (callNum === callNumber.current) {
        const Animals = Array.isArray(response) ? response : response.result;
        const rows = await Promise.all(
          Animals.map(async (animal: Animal): Promise<{ catalogQty: number; animal: Animal }> => {
            const { data: catalogUnits } = await getCatalogQuantity(animal.animalId!.toString());
            return { catalogQty: catalogUnits, animal: animal };
          }),
        );
        const rowData = rows.map((row: any) => {
          return [`${row?.animal.code} - ${row?.animal.name}`, row.catalogQty, row.animal.threshold];
        });
        const newTableData = offset > 0 ? [...tableData, ...rowData] : rowData;
        setTableData(newTableData);
        hasAllAnimalResults.current = rows.length === 0;
      }
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    hasAllAnimalResults.current = false;
    setTableData([]);
    if (offset === 0) {
      loadAnimalData();
    } else {
      setOffset(0);
      if ('current' in ref) {
        ref.current?.scroll({ top: 0 });
      }
    }
  }, [account, searchAnimal]);

  useEffect(() => {
    loadAnimalData();
  }, [offset]);

  const loadMore = () => {
    setOffset(offset + limit);
  };

  useEffect(() => {
    isLoadingAdditionalPage.current = false;
  }, [tableData]);

  const handleScroll = (e: any) => {
    const position = e.target.scrollHeight - e.target.scrollTop;
    const atBottom = position - e.target.clientHeight <= scrollGap;
    if (atBottom && !isLoadingAdditionalPage.current) {
      isLoadingAdditionalPage.current = true;
      loadMore();
    }
  };

  const handleSubmit = async () => {
    if (checkValidity()) {
      try {
        await setGlobalThreshold(Number(globalThresholdValue.value));
        showToast.success(toastMessages.GLOBAL_THRESHOLD_SUCCESS);
        clearPageValues();
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  };

  const checkValidity = () => {
    return globalThresholdUpdated && globalThresholdValue.valid && !isNaN(Number(globalThresholdValue.value));
  };

  const clearPageValues = () => {
    setGlobalThresholdUpdated(false);
    getGlobalThreshold(dropdownAccounts[0]);
    hasAllAnimalResults.current = false;
    setTableData([]);
    loadAnimalData();
  };

  return (
    <div className="inventory-management inventory-management-threshold">
      <div className="inventory-action card">
        <h1>{PAGE_HEADER_CONSTANTS.THRESHOLD}</h1>
        <div className="form-row">
          <ValidatedInput
            label="Global Threshold Value:"
            type="number"
            min={1}
            setValidatedState={threshold => {
              setGlobalThresholdValue(threshold);
              setGlobalThresholdUpdated(true);
            }}
            validatedStateForAutoFill={globalThresholdValue}
            validators={[]}
          />
        </div>
        <div className="form-row">
          <label>{LABEL_CONSTANTS.ACCOUNT_NAME}:</label>
          <div className="input-container">
            <FilterDropdown
              options={dropdownAccounts}
              placeholder="Choose Account"
              value={account}
              onChange={e => setAccount(e)}
            />
          </div>
        </div>
        <br />

        {account?.value && (
          <>
            <div className="search-bar">
              <div className="search-input-wrapper">
                <input
                  placeholder={'Search Animal'}
                  type="text"
                  value={searchAnimal}
                  onChange={e => setSearchAnimal(e.target.value)}
                />
              </div>
            </div>
            <br />
            <div className="sorted-table">
              <table>
                <thead className="paged-table">
                  <tr>
                    <th>{TABLE_HEADER_CONSTANTS.ANIMAL}</th>
                    <th>{TABLE_HEADER_CONSTANTS.CATALOG_QTY}</th>
                    <th>{TABLE_HEADER_CONSTANTS.THRESHOLD}</th>
                  </tr>
                </thead>
                <tbody ref={ref} onScroll={handleScroll} style={{ minHeight: minHeight + 'px' }}>
                  {tableData &&
                    tableData.map((row: any[], rowIndex: number) => {
                      return (
                        <tr key={'sorted-table-row-' + rowIndex}>
                          {row.map((item: any, itemIndex: number) => {
                            return <td key={'sorted-table-item-' + rowIndex + '-' + itemIndex}>{item}</td>;
                          })}
                        </tr>
                      );
                    })}
                  {!hasAllAnimalResults.current && (tableData.length === 0 || isLoadingAdditionalPage.current) && (
                    <tr>
                      <td colSpan={3}>
                        <br />
                        <br />
                        <br />
                        <Loader loaderSize="small" />
                        <br />
                        <br />
                        <br />
                      </td>
                    </tr>
                  )}
                  {tableData.length === 0 && hasAllAnimalResults.current && (
                    <tr>
                      <td colSpan={3}>
                        <br />
                        {'No Animals Found'}
                        <br />
                        <br />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
        <br />

        <br />
        <div className="submit-button">
          <button className="button green small" disabled={!checkValidity()} onClick={() => handleSubmit()}>
            {BUTTON_CONSTANTS.SAVE}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InventoryManagementThreshold;
