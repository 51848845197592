import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../constants/routePaths';
import './errorPages.scss';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="response-wrapper">
      <div className="response-container page-not-found">
        <h1>404</h1>
        <div className="response-info">
          <h3>Oops! This Page Could Not Be Found</h3>
          <p>Sorry but the page you are looking for does not exist, or have been removed.</p>
          <div className="button-group">
            <button className={'button blue large'} onClick={() => navigate(ROUTE_PATHS.APP_DASHBOARD)}>
              Go To Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
