import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AlertSVG } from '../../components/svgs';
import ValidatedInput, { ValidatedState } from '../../components/custom-input/ValidatedInput';
import TermsAndConditions from '../../components/terms-and-conditions/TermsAndConditions';
import { BusinessFarmNameTooltip, PasswordRequirementsTooltip } from '../../components/tooltip/customTooltips';
import { Loader } from '../../components';
import StoragePlansModal from './storage-plans/StoragePlansModal';
import { PASSWORD_VALIDATORS, Validators } from '../../types/enums/validators.enum';
import { CreateUserAndAccount } from '../../types/interfaces/account.interfaces';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { apiErrorTitles, customFormErrors, PromiseError, toastMessages } from '../../constants/errorMessages';
import { BUTTON_CONSTANTS, RSG_CONSTANTS } from '../../constants/common';
import { showToast } from '../../services/toast.service';
import { signInUser } from '../../services/auth.service';
import { signUp } from '../../api/authApi';

const SignUp = (): JSX.Element => {
  const [firstName, setFirstName] = useState<ValidatedState>({ value: '', valid: false });
  const [lastName, setLastName] = useState<ValidatedState>({ value: '', valid: false });
  const [businessName, setBusinessName] = useState<ValidatedState>({ value: '', valid: true });
  const [phoneNumber, setPhoneNumber] = useState<ValidatedState>({ value: '', valid: false });
  const [email, setEmail] = useState<ValidatedState>({ value: '', valid: false });
  const [password, setPassword] = useState<ValidatedState>({ value: '', valid: false });
  const [confirmPassword, setConfirmPassword] = useState<ValidatedState>({ value: '', valid: false });
  const [validate, setValidate] = useState<boolean>(false);
  const [customError, setCustomError] = useState<boolean>(false);
  const [storagePlanIsOpen, setStoragePlanIsOpen] = useState<boolean>(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [signingUp, setSigningUp] = useState<boolean>(true);

  const navigate = useNavigate();

  const formIsValid = (): boolean => {
    return (
      firstName.valid &&
      lastName.valid &&
      email.valid &&
      phoneNumber.valid &&
      password.valid &&
      password.value === confirmPassword.value &&
      termsAccepted
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!signingUp) return;
    setSigningUp(false);
    setValidate(true);

    if (formIsValid()) {
      const AccountUser: CreateUserAndAccount = {
        name: businessName.value === '' ? firstName.value + ' ' + lastName.value : businessName.value,
        contactFirstName: firstName.value,
        contactLastName: lastName.value,
        contactEmail: email.value,
        contactPhone: phoneNumber.value,
        owner: {
          firstName: firstName.value,
          lastName: lastName.value,
          username: email.value,
          userpass: password.value,
          contactPhone: phoneNumber.value,
        },
      };

      try {
        setStoragePlanIsOpen(false);
        setCustomError(false);
        await signUp(AccountUser);
        await signInUser({ email: email.value, password: password.value }, false);
        navigate(ROUTE_PATHS.APP_DASHBOARD);
      } catch (error: any) {
        const errorTitle = error.response.data.title;
        if (error.code === PromiseError.NETWORK) {
          showToast.error(toastMessages.NETWORK);
        } else if (errorTitle === apiErrorTitles.USER_ALREADY_EXISTS) {
          setCustomError(true);
        } else {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      } finally {
        setSigningUp(true);
      }
    } else {
      setSigningUp(true);
    }
  };

  return (
    <>
      <StoragePlansModal isOpen={storagePlanIsOpen} closeStoragePlan={() => setStoragePlanIsOpen(false)} />
      <div className="auth">
        <div className="content-container">
          <div className="content">
            <form className="form" action="submit" onSubmit={(e: React.FormEvent) => handleSubmit(e)}>
              <h1 className="welcome">{RSG_CONSTANTS.RSG_WELCOME}</h1>
              <p className="subheader">{RSG_CONSTANTS.RSG_SIGN_UP_SUBHEADER}</p>

              <div className="input-row">
                <div className="input">
                  <ValidatedInput
                    useId="firstName"
                    label="*First Name"
                    setValidatedState={setFirstName}
                    validators={[Validators.REQUIRED]}
                    validate={validate}
                  />
                </div>

                <div className="input">
                  <ValidatedInput
                    useId="lastName"
                    label="*Last Name"
                    setValidatedState={setLastName}
                    validators={[Validators.REQUIRED]}
                    validate={validate}
                  />
                </div>
              </div>

              <div className="input">
                <ValidatedInput
                  useId="BusinessName"
                  label="Business/Farm Name"
                  placeholder={`${firstName.value} ${lastName.value}`}
                  type="text"
                  setValidatedState={setBusinessName}
                  validate={validate}
                  customTooltip={<BusinessFarmNameTooltip />}
                  validators={[]}
                />
              </div>

              <div className="input">
                <ValidatedInput
                  useId="email"
                  label="*Email"
                  type="email"
                  setValidatedState={setEmail}
                  validators={[Validators.REQUIRED, Validators.EMAIL]}
                  validate={validate}
                />
                {customError && (
                  <div className="validated-input-message-error">
                    <AlertSVG /> {customFormErrors.EMAIL_IN_USE}
                  </div>
                )}
              </div>
              {!signingUp && <Loader loaderSize={'small'} simple />}

              <div className="input">
                <ValidatedInput
                  useId="PhoneNumber"
                  label="*Phone Number"
                  type="phone"
                  setValidatedState={setPhoneNumber}
                  validators={[Validators.REQUIRED, Validators.PHONE_LENGTH]}
                  validate={validate}
                />
              </div>

              <div className="input">
                <ValidatedInput
                  label="*Password "
                  type="password"
                  setValidatedState={setPassword}
                  validators={[Validators.REQUIRED, ...PASSWORD_VALIDATORS]}
                  validate={validate}
                  customTooltip={<PasswordRequirementsTooltip />}
                />
              </div>
              <div className="input">
                <ValidatedInput
                  label="*Confirm Password "
                  type="password"
                  setValidatedState={setConfirmPassword}
                  validators={[Validators.REQUIRED]}
                  validate={validate}
                  matchString={password.value}
                  customTooltip={<PasswordRequirementsTooltip />}
                />
              </div>
              <TermsAndConditions
                acceptedTerms={termsAccepted}
                setAcceptedTerms={setTermsAccepted}
                openTermsModal={() => setStoragePlanIsOpen(true)}
              />
              <br />
              <button disabled={!formIsValid()} className="green button">
                {BUTTON_CONSTANTS.SIGN_UP}
              </button>
            </form>
            <hr />
            <p className="bottom-text">
              {RSG_CONSTANTS.RSG_SIGN_UP_FOOTER} &nbsp;
              <Link to={ROUTE_PATHS.SIGN_IN}>{BUTTON_CONSTANTS.SIGN_IN}</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
