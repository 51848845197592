import { store } from '../redux/store';
import { storeCartItems } from '../redux/reducers/transactionCart';
import { getTransactionCartItems } from '../api/transactionCartApi';

export const fetchTransactionCartItems = async (userId: number, accountId?: number) => {
  try {
    const { data: cartItems } = await getTransactionCartItems({ userId, accountId });
    store.dispatch(storeCartItems(cartItems));
  } catch (error: any) {
    throw error;
  }
};
