const LocationSVG = (): JSX.Element => {
  return (
    <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00222 10.25C8.48407 10.25 8.89583 10.0784 9.2375 9.73528C9.57917 9.39214 9.75 8.97964 9.75 8.49778C9.75 8.01593 9.57843 7.60417 9.23528 7.2625C8.89214 6.92083 8.47964 6.75 7.99778 6.75C7.51593 6.75 7.10417 6.92157 6.7625 7.26472C6.42083 7.60786 6.25 8.02036 6.25 8.50222C6.25 8.98407 6.42157 9.39583 6.76472 9.7375C7.10786 10.0792 7.52036 10.25 8.00222 10.25ZM8 18.525C10.2167 16.5083 11.8542 14.6792 12.9125 13.0375C13.9708 11.3958 14.5 9.95 14.5 8.7C14.5 6.73683 13.8726 5.12937 12.6177 3.87762C11.3629 2.62587 9.82362 2 8 2C6.17638 2 4.63715 2.62587 3.3823 3.87762C2.12743 5.12937 1.5 6.73683 1.5 8.7C1.5 9.95 2.04167 11.3958 3.125 13.0375C4.20833 14.6792 5.83333 16.5083 8 18.525ZM8.0125 20.2C7.90417 20.2 7.8 20.1833 7.7 20.15C7.6 20.1167 7.50833 20.0667 7.425 20C4.94167 17.8167 3.08333 15.7925 1.85 13.9276C0.616667 12.0627 0 10.3202 0 8.7C0 6.2 0.804167 4.20833 2.4125 2.725C4.02083 1.24167 5.88333 0.5 8 0.5C10.1167 0.5 11.9792 1.24167 13.5875 2.725C15.1958 4.20833 16 6.2 16 8.7C16 10.3167 15.3833 12.0583 14.15 13.925C12.9167 15.7917 11.0583 17.8167 8.575 20C8.49167 20.0667 8.40417 20.1167 8.3125 20.15C8.22083 20.1833 8.12083 20.2 8.0125 20.2Z"
        fill="#545556"
      />
    </svg>
  );
};
export default LocationSVG;
