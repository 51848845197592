const DashboardSVG = (): JSX.Element => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 6.5V0.5H18V6.5H10ZM0 10.5V0.5H8V10.5H0ZM10 18.5V8.5H18V18.5H10ZM0 18.5V12.5H8V18.5H0ZM2 8.5H6V2.5H2V8.5ZM12 16.5H16V10.5H12V16.5ZM12 4.5H16V2.5H12V4.5ZM2 16.5H6V14.5H2V16.5Z"
        fill="#545556"
      />
    </svg>
  );
};
export default DashboardSVG;
