import { ValidatedAddress } from '../components/account-settings/AddressInformation';
import { PickupInfo } from '../components/custom-input/ValidatedPickupInfo';
import { Address, phoneKey, streetAddress2Key } from '../types/interfaces/common.interfaces';
import { dateFormatMMDDYYYY } from './commonUtils';

function isAddressType(address: Address | ValidatedAddress): address is Address {
  const doesValueExistOnType = (address as ValidatedAddress).type.value === undefined;
  return doesValueExistOnType;
}

export function checkAddressTypesAreUnique(addresses: Address[] | ValidatedAddress[]): boolean {
  const types: Address[] = addresses.map((address: any) => {
    if (isAddressType(address)) {
      return address.type;
    } else {
      return address.type.value;
    }
  });

  const arrayHasNoDuplicates = () => new Set(types).size === types.length;
  return arrayHasNoDuplicates();
}

export function isValidAddress(addresses: Address[] | ValidatedAddress[]): boolean {
  let validAddress: boolean = true;
  addresses.forEach((address: any) => {
    for (const key in address) {
      if (typeof address[key] === 'object') {
        if (typeof address[key]?.value === 'string' && !address[key]?.value.trim().length && key != streetAddress2Key) {
          validAddress = false;
        }
      } else {
        if (typeof address[key] === 'string' && !address[key]?.trim().length && key != streetAddress2Key && key != phoneKey) {
          validAddress = false;
        }
      }
    }
  });

  return validAddress;
}

export function tempId(): number {
  const uniqueNumber = Date.now();
  const fitNumberInsideInt32 = () => uniqueNumber >>> 0;
  return fitNumberInsideInt32();
}

export function removeAddressIds(addresses: Address[]): Address[] {
  const addressesWithoutIds = addresses.map(address => {
    const { addressId, ...addressWithoutId } = address;
    return addressWithoutId;
  });
  return addressesWithoutIds;
}

export function getShippingNotes(address?: Address): string {
  if (!address) {
    return `
Missing Address`;
  }
  return `
Shipping Address 
Address 1: ${address.streetAddress} 
Address 2: ${address.streetAddress2} 
City: ${address.city} 
State: ${address.state} 
Zip Code: ${address.postalCode} 
Country: ${address.country}`;
}

export function getPickupNotes(pickupInfo?: PickupInfo): string {
  if (!pickupInfo) {
    return `
Pickup Information Missing`;
  }
  return `
Pickup Information:
Name: ${pickupInfo.name}
Phone: ${pickupInfo.phone}
Pickup Date: ${dateFormatMMDDYYYY(pickupInfo.date)}`;
}
