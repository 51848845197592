import { FC, useEffect, useState } from 'react';
import SortedTable from '../sorted-table/SortedTable';
import Loader from '../loader/Loader';
import { InventoryTransaction } from '../../types/interfaces/transaction.interfaces';
import { LABEL_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../constants/common';
import './linkedTransactions.scss';

interface AssociatedTransactionsProps {
  associatedTransactions: InventoryTransaction[] | undefined;
}

const AssociatedTransactions: FC<AssociatedTransactionsProps> = ({ associatedTransactions }): JSX.Element => {
  const [associatedInventoryTrans, setAssociatedInventoryTrans] = useState<InventoryTransaction[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const tableHeaders = [
    { displayName: TABLE_HEADER_CONSTANTS.TRANSACTION_ID },
    { displayName: TABLE_HEADER_CONSTANTS.ACCOUNT_OWNER },
    { displayName: TABLE_HEADER_CONSTANTS.TYPE },
    { displayName: TABLE_HEADER_CONSTANTS.DATE },
    { displayName: TABLE_HEADER_CONSTANTS.STATUS },
  ];

  useEffect(() => {
    setIsLoading(true);
    if (associatedTransactions?.length) {
      setAssociatedInventoryTrans(associatedTransactions);
    }
    setIsLoading(false);
  }, [associatedTransactions]);

  return (
    <div className="associated-transaction">
      {isLoading ? (
        <Loader loaderSize="small" addedSpace />
      ) : associatedInventoryTrans.length ? (
        <>
          <div className="desk-associated-transaction">
            <SortedTable
              headers={tableHeaders}
              data={associatedInventoryTrans?.map((t: InventoryTransaction) => {
                return [
                  { content: t.inventoryTransactionId },
                  { content: t?.account?.name },
                  { content: t?.transactionType },
                  { content: new Date(t.createdDatetime).toLocaleDateString() },
                  { content: t.status },
                ];
              })}
            />
          </div>

          {/** Mobile View */}
          <div className="xs-associated-transaction">
            {associatedInventoryTrans &&
              associatedInventoryTrans.map((transaction, index) => {
                return (
                  <div className="card max-width" key={'transaction-' + transaction.inventoryTransactionId + index}>
                    <div className="associated-transaction-content">
                      <div className="transaction-info">
                        <div className="transaction-content">
                          <label>{TABLE_HEADER_CONSTANTS.TRANSACTION_ID} :</label>
                          <label>{`${transaction.inventoryTransactionId ?? '-'}`}</label>
                        </div>
                        <div className="transaction-content">
                          <label>{TABLE_HEADER_CONSTANTS.ACCOUNT_OWNER} :</label>
                          <label>{`${transaction.account?.name ?? '-'}`}</label>
                        </div>
                      </div>
                      <div className="transaction-info">
                        <div className="transaction-content">
                          <label>{TABLE_HEADER_CONSTANTS.TYPE} :</label>
                          <label>{`${transaction.transactionType ?? '-'}`}</label>
                        </div>
                        <div className="transaction-content">
                          <label>{TABLE_HEADER_CONSTANTS.DATE} :</label>
                          <label>{`${new Date(transaction.createdDatetime).toLocaleDateString() ?? '-'}`}</label>
                        </div>
                        <div className="transaction-content">
                          <label>{TABLE_HEADER_CONSTANTS.STATUS} :</label>
                          <label>{`${transaction.status ?? '-'}`}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      ) : (
        <h4 className="no-associated-transaction">{LABEL_CONSTANTS.NO_ASSOCIATED_TRANSACTIONS}</h4>
      )}
    </div>
  );
};

export default AssociatedTransactions;
