import { useState } from 'react';
import { ValidatedInput } from '../../components';
import { ValidatedState } from '../../components/custom-input/ValidatedInput';
import { SpecimenLocation } from '../../types/interfaces';
import { Validators } from '../../types/enums';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';
import { httpStatusCodes } from '../../constants/httpStatusCodes';
import { ANIMAL_CONSTANTS, BUTTON_CONSTANTS, LABEL_CONSTANTS } from '../../constants/common';
import { reconcileInventory } from '../../api/inventoryApi';
import './inventoryManagement.scss';

interface ReconcileInventoryProps {
  item: SpecimenLocation;
  close: (success: boolean, message?: string) => void;
}

const ReconcileInventoryModal = ({ item, close }: ReconcileInventoryProps): JSX.Element => {
  const [notes, setNotes] = useState<string>('');
  const [quantity, setQuantity] = useState<ValidatedState>({ valid: false, value: '' });
  const [validate, setValidate] = useState<boolean>(false);
  const [updatingInventory, setUpdatingInventory] = useState<boolean>(true);

  const formIsValid = (): boolean => {
    return quantity.valid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!updatingInventory) return;
    setUpdatingInventory(false);
    setValidate(true);

    if (formIsValid() && item.specimenId) {
      try {
        await reconcileInventory({
          specimenId: item.specimenId,
          quantity: +quantity.value,
          selectedCanisterId: item.storageCanisterId,
          transactionLinkId: item.transactionLinkId,
          notes: notes,
        });
        showToast.success(toastMessages.QUANTITIES_ADJUSTED_SUCCESSFULLY);
        close(true);
      } catch (err: any) {
        const error = err.response.data;
        if (error.status === httpStatusCodes[409]) {
          const detailObject = JSON.parse(error.detail.substring(error.detail.indexOf('{')));
          const errMsg = detailObject.ErrMsg;
          close(true, errMsg);
        } else {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
          close(false);
        }
      } finally {
        setUpdatingInventory(true);
      }
    } else {
      setUpdatingInventory(true);
    }
  };

  return (
    <div className="reconcile-modal card inventory-action">
      <form onSubmit={handleSubmit}>
        <h2>{LABEL_CONSTANTS.RECONCILE}</h2>
        <div className="form-row">
          <label>{ANIMAL_CONSTANTS.ANIMAL_CODE}:</label>
          <div className="input-container">
            <label>
              {item.specimen?.animal?.code} - {item.specimen?.animal?.name}
            </label>
          </div>
        </div>
        <div className="form-row">
          <label>{LABEL_CONSTANTS.LOT_DATE_NUMBER}:</label>
          <div className="input-container">
            <label>{new Date(item.specimen!.freezeDate).toLocaleDateString() + ' - ' + item.specimenId}</label>
          </div>
        </div>
        <div className="form-row">
          <label>{LABEL_CONSTANTS.CURRENT_QTY}:</label>
          <div className="input-container">
            <label>{item.quantity}</label>
          </div>
        </div>
        <div className="form-row">
          <ValidatedInput
            type="number"
            min={1}
            label={'New Quantity:'}
            validate={validate}
            setValidatedState={setQuantity}
            validators={[Validators.REQUIRED]}
          />
        </div>
        <div className="form-row expanded-text-area">
          <label>{LABEL_CONSTANTS.NOTES}:</label>
          <textarea id="notes" name="notes" value={notes} onChange={e => setNotes(e.target.value)} rows={4} cols={50} />
        </div>
        <div className="flex-right">
          <button type="button" className="small green inverted button" onClick={() => close(false)}>
            {BUTTON_CONSTANTS.CANCEL}
          </button>
          &emsp;
          <button type="submit" className={formIsValid() ? 'small green button' : 'small green button disabled'}>
            {BUTTON_CONSTANTS.UPDATE}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ReconcileInventoryModal;
