import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import FilterDropdown from '../../components/custom-input/FilterDropdown';
import ValidatedInput, { ValidatedState } from '../../components/custom-input/ValidatedInput';
import Modal from '../../components/modal/Modal';
import { Loader } from '../../components';
import { AlertSVG } from '../../components/svgs';
import { toastMessages, accountSearchErrors } from '../../constants/errorMessages';
import { httpStatusCodes } from '../../constants/httpStatusCodes';
import { showToast } from '../../services/toast.service';
import { getUserByUsername } from '../../api/userApi';
import { AxiosResponse } from 'axios';
import { Validators } from '../../types/enums/validators.enum';
import { AccountRoles, getRoleValue, hasGreaterAccess } from '../../types/enums/accountRoles.enum';
import { ModifyUserInterface, UserInfo } from '../../types/interfaces/user.interfaces';
import { selectOption } from '../../types/interfaces';
import { AppRoles } from '../../types/enums';

interface UserModalProps {
  isOpen: boolean;
  onClose: () => void;
  AddExistingUser: (
    username: string,
    roles: AccountRoles | AppRoles,
    accountId: number,
    userId: number,
  ) => Promise<AxiosResponse<{}, any>>;
  AddNonExistingUser: (userInfo: ModifyUserInterface) => Promise<AxiosResponse<{}, any>>;
  roles?: selectOption[];
  onSuccessfulAdd: () => void;
}

const AddUserModal: FC<UserModalProps> = ({ isOpen, onClose, roles, AddExistingUser, AddNonExistingUser, onSuccessfulAdd }) => {
  const initialFormState = { value: '', valid: false };

  const { accountId } = useAppSelector(state => state.activeAccount);
  const { optionSelectAccountRoles } = useAppSelector(state => state.roles);
  const accountRole = useAppSelector(state => state.auth.accountContext.roles);

  const [email, setEmail] = useState<ValidatedState>(initialFormState);
  const [firstName, setFirstName] = useState<ValidatedState>(initialFormState);
  const [lastName, setLastName] = useState<ValidatedState>(initialFormState);
  const [accessLevel, setAccessLevel] = useState<any>(initialFormState);
  const [phoneNumber, setPhoneNumber] = useState<ValidatedState>(initialFormState);
  const [foundAccount, setFoundAccount] = useState<UserInfo>();
  const [searchResult, setSearchResult] = useState<string>('');
  const [validate, setValidate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addingUser, setAddingUser] = useState<boolean>(true);

  useEffect(() => {
    if (foundAccount && foundAccount?.username && foundAccount?.username.toLowerCase() !== email.value.toLowerCase()) {
      setFoundAccount(undefined);
      setSearchResult('');
    }
  }, [email.value, foundAccount]);

  const isDisabled = () => {
    return (
      !accessLevel.value ||
      !email.valid ||
      (searchResult === accountSearchErrors.ACCOUNT_NOT_FOUND && (!firstName.valid || !lastName.valid || !phoneNumber.valid))
    );
  };

  const handleError = (status: number) => {
    if (status === httpStatusCodes[409]) {
      showToast.error(toastMessages.TEAM_MEMBER_EXISTS_ERROR);
    } else {
      showToast.error(toastMessages.ADD_TEAM_MEMBER_FAIL);
    }
  };

  const addExistingUserToAccount = async () => {
    setIsLoading(true);

    try {
      await AddExistingUser(foundAccount?.username!, accessLevel.value, Number(accountId), foundAccount?.userId!);
      onClose();
      onSuccessfulAdd();
      showToast.success(toastMessages.ADD_TEAM_MEMBER_SUCCESS);
    } catch (error: any) {
      handleError(error?.request?.status);
    } finally {
      setAddingUser(true);
      setIsLoading(false);
    }
  };

  const addNonExistentUserToAccount = async () => {
    setIsLoading(true);

    try {
      await AddNonExistingUser({
        accountId: Number(accountId),
        username: email.value,
        firstName: firstName.value,
        lastName: lastName.value,
        contactPhone: phoneNumber.value,
        roles: accessLevel.value,
      });
      onClose();
      onSuccessfulAdd();
      showToast.success(toastMessages.ADD_TEAM_MEMBER_SUCCESS);
    } catch (error: any) {
      handleError(error?.request?.status);
      showToast.error(toastMessages.ADD_TEAM_MEMBER_FAIL);
    } finally {
      setAddingUser(true);
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!addingUser) return;
    setAddingUser(false);
    setValidate(true);

    if (!isDisabled()) {
      if (searchResult === accountSearchErrors.ACCOUNT_FOUND) {
        addExistingUserToAccount();
      } else if (searchResult === accountSearchErrors.ACCOUNT_NOT_FOUND) {
        addNonExistentUserToAccount();
      } else if (searchResult === '') {
        setAddingUser(true);
        searchByEmail(e);
      }

      setValidate(false);
    } else {
      setAddingUser(true);
    }
  };

  const searchByEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await getUserByUsername(email.value);
      const user = response?.data as UserInfo;
      setSearchResult(accountSearchErrors.ACCOUNT_FOUND);
      setFoundAccount(user);
    } catch (error) {
      setSearchResult(accountSearchErrors.ACCOUNT_NOT_FOUND);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} width={700} onClose={onClose} ignoreBackdrop={true}>
      <div className="add-user-modal">
        <h4>Add User</h4>
        <p>Please complete the form to add a member to your team.</p>
        <form onSubmit={e => handleSubmit(e)}>
          <div className="input-group">
            <label>Access Level</label>
            <FilterDropdown
              options={roles ? roles : optionSelectAccountRoles.filter(r => hasGreaterAccess(r.name, getRoleValue(accountRole)))}
              validate={validate}
              value={accessLevel}
              onChange={setAccessLevel}
            />
          </div>
          <div className="input-group">
            <div className="input">
              <ValidatedInput
                useId="new-user-last-name"
                label={'Email'}
                type="email-search"
                onButtonClick={email.valid ? searchByEmail : undefined}
                setValidatedState={setEmail}
                validate={validate}
                validators={[Validators.REQUIRED, Validators.EMAIL]}
                placeholder="Email"
              />
            </div>
            {(isLoading || !addingUser) && <Loader loaderSize={'small'} simple />}
            {searchResult === accountSearchErrors.ACCOUNT_FOUND && (
              <div className="account-found">
                <p className="account-found-text">{searchResult}</p>
                <h3>Confirm the information below: </h3>
                <p>First Name: {foundAccount?.firstName}</p>
                <p>Last Name: {foundAccount?.lastName}</p>
              </div>
            )}
          </div>
          {searchResult === accountSearchErrors.ACCOUNT_NOT_FOUND && (
            <>
              <div className="account-not-found">
                <AlertSVG />
                <p className="account-not-found-text">{accountSearchErrors.ACCOUNT_NOT_FOUND}</p>
              </div>
              <div className="name-inputs-container input-group">
                <div className="input">
                  <ValidatedInput
                    useId="new-user-first-name"
                    label={'First Name'}
                    type="text"
                    setValidatedState={setFirstName}
                    validate={validate}
                    validators={[Validators.REQUIRED]}
                    placeholder="First Name"
                  />
                </div>

                <div className="input">
                  <ValidatedInput
                    useId="new-user-last-name"
                    label={'Last Name'}
                    type="text"
                    setValidatedState={setLastName}
                    validate={validate}
                    validators={[Validators.REQUIRED]}
                    placeholder="Last Name"
                  />
                </div>
              </div>

              <div className="input-group">
                <div className="input">
                  <ValidatedInput
                    useId="new-user-phone-number"
                    label={'Phone Number'}
                    type="phone"
                    setValidatedState={setPhoneNumber}
                    validate={validate}
                    validators={[Validators.REQUIRED, Validators.PHONE_LENGTH]}
                    placeholder="Phone Number"
                  />
                </div>
              </div>
            </>
          )}
          <div className="footer">
            <button type="reset" onClick={onClose} className="button green inverted mr-1">
              Cancel
            </button>
            <button type="submit" className="button green">
              Submit
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddUserModal;
