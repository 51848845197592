const InventorySVG = (): JSX.Element => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 20.5H2C1.45 20.5 0.979 20.3043 0.587 19.913C0.195667 19.521 0 19.05 0 18.5V4.5C0 3.95 0.195667 3.479 0.587 3.087C0.979 2.69567 1.45 2.5 2 2.5H6.175C6.35833 1.91667 6.71667 1.43733 7.25 1.062C7.78333 0.687333 8.36667 0.5 9 0.5C9.66667 0.5 10.2627 0.687333 10.788 1.062C11.3127 1.43733 11.6667 1.91667 11.85 2.5H16C16.55 2.5 17.021 2.69567 17.413 3.087C17.8043 3.479 18 3.95 18 4.5V9.5H16V4.5H14V5.5C14 6.05 13.8043 6.52067 13.413 6.912C13.021 7.304 12.55 7.5 12 7.5H6C5.45 7.5 4.97933 7.304 4.588 6.912C4.196 6.52067 4 6.05 4 5.5V4.5H2V18.5H8V20.5ZM12.5 16.625L17.45 11.675C17.6333 11.4917 17.8667 11.4 18.15 11.4C18.4333 11.4 18.6667 11.4917 18.85 11.675C19.0333 11.8583 19.125 12.0917 19.125 12.375C19.125 12.6583 19.0333 12.8917 18.85 13.075L13.2 18.725C13 18.925 12.7667 19.025 12.5 19.025C12.2333 19.025 12 18.925 11.8 18.725L8.95 15.875C8.76667 15.6917 8.675 15.4583 8.675 15.175C8.675 14.8917 8.76667 14.6583 8.95 14.475C9.13333 14.2917 9.36667 14.2 9.65 14.2C9.93333 14.2 10.1667 14.2917 10.35 14.475L12.5 16.625ZM9 4.5C9.28333 4.5 9.521 4.404 9.713 4.212C9.90433 4.02067 10 3.78333 10 3.5C10 3.21667 9.90433 2.979 9.713 2.787C9.521 2.59567 9.28333 2.5 9 2.5C8.71667 2.5 8.47933 2.59567 8.288 2.787C8.096 2.979 8 3.21667 8 3.5C8 3.78333 8.096 4.02067 8.288 4.212C8.47933 4.404 8.71667 4.5 9 4.5Z"
        fill="#545556"
      />
    </svg>
  );
};
export default InventorySVG;
