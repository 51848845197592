import { FC } from 'react';
import Modal from '../../../../components/modal/Modal';
import { BUTTON_CONSTANTS, INVENTORY_MANAGEMENT_CONSTANTS, LABEL_CONSTANTS } from '../../../../constants/common';

type ConfirmOverrideModalProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
};

const OverrideQuantityModal: FC<ConfirmOverrideModalProps> = ({ showModal, setShowModal, handleSubmit }) => {
  return (
    <Modal isOpen={showModal} onClose={() => setShowModal(false)} ignoreBackdrop={true}>
      <div className="sign-out-modal">
        <div className="header">
          <h4>{LABEL_CONSTANTS.CONFIRM_OVERRIDE}</h4>
        </div>
        <div className="body">
          <p>{INVENTORY_MANAGEMENT_CONSTANTS.CONFIRM_OVERRIDE_QUESTION}</p>
        </div>
        <div className="footer">
          <button className="button blue inverted small" onClick={() => setShowModal(false)}>
            {BUTTON_CONSTANTS.CANCEL}
          </button>
          <button className="button green small" onClick={() => handleSubmit()}>
            {BUTTON_CONSTANTS.YES}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default OverrideQuantityModal;
