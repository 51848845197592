import { useEffect, useState } from 'react';
import Modal from '../../modal/Modal';
import AccountSearch from '../../custom-input/AccountSearch';
import { AccountOwnershipQuantity } from '../ReconcileAccountOwnership';
import { Account } from '../../../types/interfaces';
import { showToast } from '../../../services/toast.service';
import { getCountBySpecimen } from '../../../api/inventoryApi';
import { toastMessages } from '../../../constants/errorMessages';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS } from '../../../constants/common';
import './addLocationAndOwnershipModal.scss';

export type AddAccountOwnershipModalProps = {
  isOpen: boolean;
  onConfirm: (accountOwnership: AccountOwnershipQuantity) => void;
  setIsOpen: (e: boolean) => void;
  specimenId: number;
  width?: number;
};

const AddAccountOwnershipModal = ({ isOpen, width = 700, setIsOpen, onConfirm, specimenId }: AddAccountOwnershipModalProps) => {
  const [newAccountOwnership, setNewAccountOwnership] = useState<AccountOwnershipQuantity>();
  const [searchAccount, setSearchAccount] = useState<Account>();

  useEffect(() => {
    if (searchAccount) {
      modifyAccountOwnershipObject();
    }
  }, [searchAccount]);

  const modifyAccountOwnershipObject = async () => {
    try {
      const { data: quant } = await getCountBySpecimen(specimenId, { accountId: searchAccount?.accountId });
      const accountOwnershipObject = { oldQty: quant, actualQty: quant, account: searchAccount! };
      setNewAccountOwnership(accountOwnershipObject);
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const onAddAccountOwnershipHandler = () => {
    if (newAccountOwnership) {
      onConfirm(newAccountOwnership);
    }
  };

  const onCancelHandler = () => {
    setNewAccountOwnership(undefined);
    setSearchAccount(undefined);
    setIsOpen(false);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onCancelHandler} width={width} ignoreBackdrop>
        <div className="add-account-ownership-modal">
          <div className="header">
            <h4>{LABEL_CONSTANTS.ADD_ACCOUNT_OWNERSHIP}</h4>
          </div>
          <div className="body">
            <AccountSearch showIcon onChange={(account: Account | undefined) => setSearchAccount(account)} />
            <div className="account-details">
              <p>
                {LABEL_CONSTANTS.ACCOUNT_NAME} : {searchAccount?.name ?? '--'}
              </p>
              <p>
                {LABEL_CONSTANTS.ACCOUNT_ID} : {searchAccount?.accountId ?? '--'}
              </p>
              <p>
                {LABEL_CONSTANTS.FIRST_NAME} : {searchAccount?.contactFirstName ?? '--'}
              </p>
              <p>
                {LABEL_CONSTANTS.LAST_NAME} : {searchAccount?.contactLastName ?? '--'}
              </p>
            </div>
          </div>
          <div className="footer">
            <button type="button" className="button blue inverted small" onClick={onCancelHandler}>
              {BUTTON_CONSTANTS.CANCEL}
            </button>
            <button type="button" className="button green small" onClick={onAddAccountOwnershipHandler} disabled={!searchAccount}>
              {BUTTON_CONSTANTS.ADD}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddAccountOwnershipModal;
