import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import SortedTable from '../../../components/sorted-table/SortedTable';
import { getAnimalOwnershipsByAccountId } from '../../../api/animalOwnersApi';
import { getAnimalCatalogDataById } from '../../../api/catalogApi';
import { AnimalOwner, CatalogAnimalsData } from '../../../types/interfaces';
import { showToast } from '../../../services/toast.service';
import { toastMessages } from '../../../constants/errorMessages';
import { LABEL_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../../constants/common';

const CatalogSell = (): JSX.Element => {
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [catalogAnimals, setCatalogAnimals] = useState<CatalogAnimalsData[]>();

  const accountId = useAppSelector(state => state.activeAccount.accountId);

  const getTotalAnimalOwnershipsForAccount = useCallback(async () => {
    if (accountId) {
      setLoadingItems(true);
      try {
        const { data: animalOwnership } = await getAnimalOwnershipsByAccountId({ filter: `accountId eq ${accountId}` });
        const Animals = await Promise.all(
          animalOwnership.map(async (animal: AnimalOwner) => {
            const animalData = (await getAnimalCatalogDataById(animal.animalId!.toString()))?.data;
            return animalData;
          }),
        );
        const filteredAnimals = Animals.filter(animal => animal && animal.totalCatalogQuantities > 0);
        setCatalogAnimals(filteredAnimals);
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        setCatalogAnimals([]);
      }
      setLoadingItems(false);
    }
  }, [accountId]);

  useEffect(() => {
    getTotalAnimalOwnershipsForAccount();
  }, [getTotalAnimalOwnershipsForAccount]);

  const getSortedTable = (headers: string[]) => {
    return (
      <div>
        <SortedTable
          headers={headers?.map((header: string) => {
            return { displayName: header };
          })}
          data={catalogAnimals?.map((catalogAnimal: CatalogAnimalsData) => {
            return [
              { content: `${catalogAnimal?.animal?.code} - ${catalogAnimal?.animal?.name}` },
              { content: catalogAnimal.totalCatalogQuantities },
              { content: catalogAnimal.remainingCatalogQuantities },
              { content: catalogAnimal.soldCatalogQuantities },
            ];
          })}
          loading={loadingItems}
          noResultsMessage={LABEL_CONSTANTS.NO_INVENTORY}
        />
      </div>
    );
  };

  return (
    <div className="card full catalog-sell">
      <h2 className="catalog-sell-header">{LABEL_CONSTANTS.CATALOG_REPORT}</h2>
      <div className="catalog-sell-table">
        {getSortedTable([
          TABLE_HEADER_CONSTANTS.ANIMAL,
          TABLE_HEADER_CONSTANTS.TOTAL_CATALOG_QTY,
          TABLE_HEADER_CONSTANTS.AVAILABLE_QTY,
          TABLE_HEADER_CONSTANTS.SOLD_QTY,
        ])}
      </div>
    </div>
  );
};

export default CatalogSell;
