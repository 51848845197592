import { ReactNode } from 'react';
import Modal from '../../../../components/modal/Modal';
import './confirmModal.scss';

export type ConfirmModalProps = {
  modalHeader: string;
  modalBody: string;
  firstBtn: ReactNode;
  secondBtn: ReactNode;
  isOpen: boolean;
  width?: number;
};

function ConfirmModal({ modalHeader, modalBody, firstBtn, secondBtn, isOpen, width = 700 }: ConfirmModalProps) {
  return (
    <div className="confirm-address-modal">
      <Modal width={width} isOpen={isOpen} onClose={() => {}}>
        <>
          <h2 className="modal-header">{modalHeader}</h2>
          <hr />
          <p className="modal-body">{modalBody}</p>
          <div className="modal-footer">
            {firstBtn}
            {secondBtn}
          </div>
        </>
      </Modal>
    </div>
  );
}

export default ConfirmModal;
