import BackButton from "../../components/back-button/BackButton";

const UserProfile = (): JSX.Element => {
  return (
    <>
      <BackButton />
      <div className="card update-user-profile">User Profile</div>
    </>
  );
};

export default UserProfile;
