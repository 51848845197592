import { PromiseError, toastMessages } from '../constants/errorMessages';
import { showToast } from '../services/toast.service';

export const clampValue = (min: number, max: number, value: number) => {
  if (value <= min) {
    return min;
  }
  if (value >= max) {
    return max;
  }
  return value;
};

export const dateFormatMMDDYYYY = (dateTime: Date | null | undefined) => {
  if (dateTime) {
    return new Date(dateTime).toLocaleDateString();
  } else {
    return '';
  }
};

export const hashCode = (s: string = new Date().toString()) =>
  s.split('').reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);

export const oneBitImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const redirectToTopOfThePage = () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

export const handleResponseErrors = (error: any) => {
  if (error.code === PromiseError.NETWORK) {
    showToast.error(toastMessages.NETWORK);
  } else if (error.code === PromiseError.BAD_REQUEST) {
    showToast.error(PromiseError.BAD_REQUEST);
  } else {
    showToast.error(toastMessages.SOMETHING_WENT_WRONG);
  }
};

export function onBlurResetHandler(event: React.FocusEvent, reset: (target: HTMLInputElement) => void) {
  const target = event.target as HTMLInputElement;
  const clickedOutsideDropdown = !(target.className === 'selected');

  if (clickedOutsideDropdown) {
    reset(target);
  }
}

export function handleSearchDropdownClasses(showIcon: boolean, searchInput: string | undefined, isDisplayingValue?: boolean) {
  if ((showIcon && searchInput) || isDisplayingValue) {
    return 'delete-icon';
  } else if (showIcon) {
    return 'show-icon';
  } else {
    return 'search-dropdown';
  }
}

export function sortObjectsAlphabetically(arr: any[]): any[] {
  return arr.sort((a, b) => {
    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  });
}

export const formatPositiveNumber = (num: string): number => {
  return +num
    .replace(/[^\d]+/, '') //Remove all non-digits
    .replace(/^0+/, ''); //Remove all leading zeros
};

export const usDateFormatMMDDYYYY = (dateTime: Date | null | undefined) => {
  if (dateTime) {
    const usDateFormat = new Date(dateTime).toLocaleDateString('en-US');
    return usDateFormat;
  } else {
    return '';
  }
};

export const splitStringWithoutBreakingWords = (inputString: string, maxChunkSize: number) => {
  let words = inputString.split(' ');
  let chunks = [];
  let currentChunk = '';

  for (let word of words) {
    if (currentChunk.length + word.length + 1 > maxChunkSize) {
      chunks.push(currentChunk.trim());
      currentChunk = word + ' ';
    } else {
      currentChunk += word + ' ';
    }
  }
  if (currentChunk.trim()) {
    chunks.push(currentChunk.trim());
  }
  return chunks;
};
