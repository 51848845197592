import { getAnimalById } from '../api/animalsApi';
import { ANIMALS_API_ENDPOINTS, REACT_APP_API_URL } from '../constants/apiEndpoints';
import { Animal } from '../types/interfaces/animal.interfaces';

export const requestAnimalOwnersBreedAndSpeciesById = async (id: string) => {
  try {
    const { data } = await getAnimalById(id!, {
      include: 'Owners, Breed.Species, Attachments',
    });
    return data as Animal;
  } catch (err: any) {
    throw err;
  }
};

export const getAnimalAttachmentURL = (animalId: string | number, attachmentId: number, accessToken: string) => {
  return (
    REACT_APP_API_URL +
    ANIMALS_API_ENDPOINTS.ANIMALS +
    animalId +
    ANIMALS_API_ENDPOINTS.ATTACHMENTS +
    `/${attachmentId}/` +
    accessToken
  );
};
