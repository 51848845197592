import { Dispatch } from 'react';
import { ValidatedState } from '../components/custom-input/ValidatedInput';
import { SpecimenCustomDataValue } from '../types/interfaces/specimenCustomDataKeyModel.interfaces';
import { CollectDepositSelectedStates } from '../pages/inventory-management/InventoryManagementCollectDeposit';

export function handleCustomDataStateChange(
  state: ValidatedState,
  data: SpecimenCustomDataValue,
  dataArray: SpecimenCustomDataValue[] | undefined,
  setSelectedStates: Dispatch<React.SetStateAction<CollectDepositSelectedStates>>,
) {
  if (dataArray && Boolean(dataArray?.length) && data) {
    data.value = state.value;

    const i = dataArray?.findIndex(value => value.specimenCustomDataKeyId === data.specimenCustomDataKeyId);
    if (i === -1) throw Error('Error getting customDataStateChange index');

    dataArray[i] = data;

    setSelectedStates(prevState => ({
      ...prevState,
      specimenCustomDataValues: dataArray,
    }));
  } else {
    throw Error(`Guard failed in handleCustomDataStateChange: state ${state.valid}`);
  }
}

export function cleanCustomData(array: SpecimenCustomDataValue[] | undefined) {
  if (Boolean(array?.length))
    array?.forEach((value: SpecimenCustomDataValue) => {
      delete value.specimenCustomDataKey;
      delete value.specimenId;
    });
}
