import { Route, Routes } from 'react-router-dom';
import { APP_PATHS, CUSTOMER_INFO_PATHS, TEAM_MANAGEMENT_PATHS } from '../constants/routePaths';
import EditTeam from '../pages/team-management/EditTeam';
import ManageTeam from '../pages/team-management/ManageTeam';
import CustomerInfoView from '../pages/customer-info/CustomerInfoView';

const CustomerInfoRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path={CUSTOMER_INFO_PATHS.VIEW} element={<CustomerInfoView />} />
      </Routes>
    </div>
  );
};

export default CustomerInfoRoutes;
