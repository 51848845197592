import { Route, Routes } from "react-router-dom";
import { SETTINGS_PATHS } from "../constants/routePaths";
import ChangePassword from "../pages/settings/ChangePassword";

const SettingsRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path={SETTINGS_PATHS.PASSWORD} element={<ChangePassword />} />
      </Routes>
    </div>
  );
};

export default SettingsRoutes;
