import { getAccountById } from '../api/accountApi';
import { getPaymentTransactions, getPaymentTransactionsOdata } from '../api/paymentGatewayApi';
import { AccountRoles } from '../types/enums';
import { StandardParamsToODataParams } from '../types/interfaces';

export const doesUserHaveSettingAccess = async (accountId: number, userId: number) => {
  try {
    const { data: accountResponse } = await getAccountById(accountId, { include: 'Users.Roles' });
    const accountUser = accountResponse.users.find(accountUser => accountUser.userId === userId);

    return accountUser?.roles.some(role => role.name === AccountRoles.AccountOwner || role.name === AccountRoles.AccountAdmin);
  } catch (error: any) {
    throw error;
  }
};

export const doesAccountHasPendingPaymentTransaction = async (accountId?: number) => {
  try {
    if (accountId) {
      const params = { filter: `accountId eq ${accountId}`, sort: '^billedDate' };
      const { data: paymentHistoryResponse } = await getPaymentTransactionsOdata(StandardParamsToODataParams(params));
      return paymentHistoryResponse?.findIndex(paymentHistory => paymentHistory?.status === 'Pending') >= 0;
    } else {
      const params = { sort: '^billedDate' };
      const { data: paymentHistoryResponse } = await getPaymentTransactions(params);
      return paymentHistoryResponse?.findIndex(paymentHistory => paymentHistory?.status === 'Pending') >= 0;
    }
  } catch (error: any) {
    throw error;
  }
};
