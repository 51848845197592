const BackSVG = (): JSX.Element => {
  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1675 13.6435L0.127441 7.25439L10.1675 0.865234L7.04142 6.34165H18.3822V8.16712H7.04142L10.1675 13.6435Z"
        fill="#545556"
      />
    </svg>
  );
};
export default BackSVG;
