import { FC } from "react";

type TrashCanProps = {
    onClick?: () => void;
    className?: string;
}

const TrashCan: FC<TrashCanProps> = ({ onClick, className }) => {
    return (
        <div className={className}>
            <svg onClick={onClick} width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 18.4277C2.45 18.4277 1.97933 18.2321 1.588 17.8407C1.196 17.4487 1 16.9777 1 16.4277V3.42773C0.716667 3.42773 0.479 3.33207 0.287 3.14073C0.0956668 2.94873 0 2.71107 0 2.42773C0 2.1444 0.0956668 1.90673 0.287 1.71473C0.479 1.5234 0.716667 1.42773 1 1.42773H5C5 1.1444 5.096 0.906734 5.288 0.714734C5.47933 0.523401 5.71667 0.427734 6 0.427734H10C10.2833 0.427734 10.521 0.523401 10.713 0.714734C10.9043 0.906734 11 1.1444 11 1.42773H15C15.2833 1.42773 15.5207 1.5234 15.712 1.71473C15.904 1.90673 16 2.1444 16 2.42773C16 2.71107 15.904 2.94873 15.712 3.14073C15.5207 3.33207 15.2833 3.42773 15 3.42773V16.4277C15 16.9777 14.8043 17.4487 14.413 17.8407C14.021 18.2321 13.55 18.4277 13 18.4277H3ZM5 13.4277C5 13.7111 5.096 13.9484 5.288 14.1397C5.47933 14.3317 5.71667 14.4277 6 14.4277C6.28333 14.4277 6.521 14.3317 6.713 14.1397C6.90433 13.9484 7 13.7111 7 13.4277V6.42773C7 6.1444 6.90433 5.90673 6.713 5.71473C6.521 5.5234 6.28333 5.42773 6 5.42773C5.71667 5.42773 5.47933 5.5234 5.288 5.71473C5.096 5.90673 5 6.1444 5 6.42773V13.4277ZM9 13.4277C9 13.7111 9.096 13.9484 9.288 14.1397C9.47933 14.3317 9.71667 14.4277 10 14.4277C10.2833 14.4277 10.521 14.3317 10.713 14.1397C10.9043 13.9484 11 13.7111 11 13.4277V6.42773C11 6.1444 10.9043 5.90673 10.713 5.71473C10.521 5.5234 10.2833 5.42773 10 5.42773C9.71667 5.42773 9.47933 5.5234 9.288 5.71473C9.096 5.90673 9 6.1444 9 6.42773V13.4277Z" fill="currentColor" />
            </svg>
        </div>
    )
}

export default TrashCan;