import { FC, FormEvent } from 'react';
import { Modal, ValidatedInput } from '../../../components';
import FilterDropdown, { FilterOption } from '../../../components/custom-input/FilterDropdown';
import { AlertSVG } from '../../../components/svgs';
import NumberInput from '../../../components/custom-input/NumberInput';
import { ValidatedState } from '../../../components/custom-input/ValidatedInput';
import { Validators } from '../../../types/enums';
import { SpecimenType } from '../../../types/interfaces';
import { FormErrors } from '../../../constants/errorMessages';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS, VALIDATION_ALERT_CONSTANTS } from '../../../constants/common';
import './dataPointKeyModal.scss';

type DataPointKeyModalProps = {
  isDataPointModalOpen: boolean;
  setIsDataPointModalOpen: (value: boolean) => void;
  handleDataPointSubmit: (event: FormEvent) => void;
  newDataPoint: NewDataPoint;
  setNewDataPoint: (value: NewDataPoint) => void;
  editMode: boolean;
  isValid: boolean;
  specimenTypeOptions: FilterOption<SpecimenType>[];
  showErrors?: boolean;
};

export type NewDataPoint = {
  specimenCustomDataKeyId: number | undefined;
  keyName: ValidatedState;
  required: FilterOption<boolean>;
  active: FilterOption<boolean>;
  dataType: FilterOption<string>;
  specimenType: FilterOption<string>;
  minValue: number | undefined;
  maxValue: number | undefined;
};

const DataPointKeyModal: FC<DataPointKeyModalProps> = ({
  isDataPointModalOpen,
  setIsDataPointModalOpen,
  newDataPoint,
  setNewDataPoint,
  handleDataPointSubmit,
  editMode = false,
  isValid = false,
  specimenTypeOptions,
  showErrors = false,
}) => {
  return (
    <Modal width={446} isOpen={isDataPointModalOpen} onClose={() => setIsDataPointModalOpen(false)}>
      <div className="new-data-point-modal">
        <form action="submit" onSubmit={handleDataPointSubmit}>
          <h2>{editMode ? 'Edit' : 'New'} Data Point</h2>
          <br />
          <ValidatedInput
            label={'Name'}
            validate={showErrors}
            placeholder="Name"
            validatedStateForAutoFill={newDataPoint.keyName ?? { value: '', valid: false }}
            setValidatedState={(value: ValidatedState) => setNewDataPoint({ ...newDataPoint, keyName: value })}
            validators={[Validators.REQUIRED]}
          />
          <br />
          <label htmlFor="specimen-type">{LABEL_CONSTANTS.SPECIMEN_TYPE}</label>
          <FilterDropdown
            id="specimen-type"
            value={newDataPoint.specimenType}
            validate={showErrors}
            options={specimenTypeOptions}
            onChange={option => {
              setNewDataPoint({
                ...newDataPoint,
                specimenType: { name: option.name, value: option.value.specimenTypeId },
              });
            }}
          />
          <br />

          <label htmlFor="required-dropdown">{LABEL_CONSTANTS.REQUIRED}</label>
          <FilterDropdown
            id="required-dropdown"
            value={newDataPoint.required}
            options={[
              { value: true, name: 'Yes' },
              { value: false, name: 'No' },
            ]}
            onChange={option => {
              setNewDataPoint({
                ...newDataPoint,
                required: { name: option.name, value: option.value },
              });
            }}
          />
          {showErrors && newDataPoint.required.name === '' && (
            <div className="validated-input-message-error">
              <AlertSVG /> {FormErrors[0].message}
            </div>
          )}
          <br />
          <label htmlFor="active">{LABEL_CONSTANTS.ACTIVE}</label>
          <FilterDropdown
            id="active"
            value={newDataPoint.active}
            options={[
              { value: true, name: 'Yes' },
              { value: false, name: 'No' },
            ]}
            onChange={option => {
              setNewDataPoint({
                ...newDataPoint,
                active: { name: option.name, value: option.value },
              });
            }}
          />
          {showErrors && newDataPoint.active.name === '' && (
            <div className="validated-input-message-error">
              <AlertSVG /> {FormErrors[0].message}
            </div>
          )}
          <br />
          <label htmlFor="dataType">{LABEL_CONSTANTS.DATA_TYPE}</label>
          <FilterDropdown
            id="dataType"
            value={newDataPoint.dataType}
            options={[
              { value: 'Text', name: 'Text' },
              { value: 'Number', name: 'Number' },
            ]}
            onChange={option => {
              setNewDataPoint({
                ...newDataPoint,
                dataType: { name: option.name, value: option.value },
              });
            }}
          />
          {showErrors && !newDataPoint.dataType.value && (
            <div className="validated-input-message-error">
              <AlertSVG /> {FormErrors[0].message}
            </div>
          )}
          <br />
          <div className="row">
            <div>
              <label aria-disabled={newDataPoint.dataType.value != 'Number'} htmlFor="minValue">
                {LABEL_CONSTANTS.MIN}
              </label>
              <NumberInput
                id="minValue"
                value={(newDataPoint.minValue ?? '').toString()}
                disabled={newDataPoint.dataType.value != 'Number'}
                step="0.01"
                onChange={event => {
                  const newMinValue = event.target.value;
                  setNewDataPoint({
                    ...newDataPoint,
                    minValue: newMinValue.length > 0 ? Number(newMinValue) : undefined,
                  });
                }}
              />
              {newDataPoint.minValue !== undefined &&
                newDataPoint.maxValue !== undefined &&
                newDataPoint.minValue > newDataPoint.maxValue && (
                  <div className="validated-input-message-error">
                    <AlertSVG />
                    <p>{VALIDATION_ALERT_CONSTANTS.MIN_LESS_THAN_MAX}</p>
                  </div>
                )}
            </div>
            <div>
              <label htmlFor="maxValue" aria-disabled={newDataPoint.dataType.value != 'Number'}>
                {LABEL_CONSTANTS.MAX}
              </label>
              <NumberInput
                id="maxValue"
                disabled={newDataPoint.dataType.value != 'Number'}
                step="0.01"
                value={(newDataPoint.maxValue ?? '').toString()}
                onChange={event => {
                  const newMaxValue = event.target.value;
                  setNewDataPoint({
                    ...newDataPoint,
                    maxValue: newMaxValue.length > 0 ? Number(newMaxValue) : undefined,
                  });
                }}
              />
              {newDataPoint.maxValue !== undefined &&
                newDataPoint.minValue !== undefined &&
                newDataPoint.maxValue < newDataPoint.minValue && (
                  <div className="validated-input-message-error">
                    <AlertSVG />
                    <p>{VALIDATION_ALERT_CONSTANTS.MAX_GREATER_THAN_MIN}</p>
                  </div>
                )}
            </div>
          </div>
          <br />
          <div className="button-row">
            <button type="button" className="button green inverted medium" onClick={() => setIsDataPointModalOpen(false)}>
              {BUTTON_CONSTANTS.CANCEL}
            </button>
            <button aria-disabled={!isValid} className="button green medium">
              {BUTTON_CONSTANTS.SAVE}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default DataPointKeyModal;
