const HistorySVG = (): JSX.Element => {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0.5C9.61305 0.5 7.32387 1.44821 5.63604 3.13604C3.94821 4.82387 3 7.11305 3 9.5H0L3.89 13.39L3.96 13.53L8 9.5H5C5 5.63 8.13 2.5 12 2.5C15.87 2.5 19 5.63 19 9.5C19 13.37 15.87 16.5 12 16.5C10.07 16.5 8.32 15.71 7.06 14.44L5.64 15.86C6.47341 16.698 7.46449 17.3627 8.55606 17.8158C9.64764 18.269 10.8181 18.5015 12 18.5C14.3869 18.5 16.6761 17.5518 18.364 15.864C20.0518 14.1761 21 11.8869 21 9.5C21 7.11305 20.0518 4.82387 18.364 3.13604C16.6761 1.44821 14.3869 0.5 12 0.5ZM11 5.5V10.5L15.28 13.04L16 11.83L12.5 9.75V5.5H11Z"
        fill="#545556"
      />
    </svg>
  );
};
export default HistorySVG;
