import React, { FC, useState } from 'react';
import { AlertSVG, CancelSVG, PlusSvg, TrashSvg } from '../../components/svgs';
import AccountSearch from '../../components/custom-input/AccountSearch';
import { Account } from '../../types/interfaces/account.interfaces';
import { ACCOUNT_CONSTANTS, ANIMAL_CONSTANTS, BUTTON_CONSTANTS, LABEL_CONSTANTS } from '../../constants/common';

export type OwnerPercentage = {
  id: string;
  name: string;
  email: string;
  percentage: number | null;
};

type OwnershipOverviewProps = {
  ownerPercentages: OwnerPercentage[];
  setOwnerPercentages: React.Dispatch<React.SetStateAction<OwnerPercentage[]>>;
  validate?: boolean;
  setValid?: (e: boolean) => void;
  setStateWasModified?: (e: boolean) => void;
};

const OwnershipOverview: FC<OwnershipOverviewProps> = ({
  ownerPercentages,
  setOwnerPercentages,
  setValid = (e: boolean) => {},
  setStateWasModified = (e: boolean) => {},
}: OwnershipOverviewProps) => {
  const [addingOwner, setAddingOwner] = useState<boolean>(true);

  const addsTo100 = (percentages: OwnerPercentage[]): boolean => {
    return (
      percentages.length === 0 ||
      percentages.reduce((acc: number, owner: OwnerPercentage): number => acc + (owner.percentage ?? 0), 0) === 100
    );
  };

  const allOwnershipComplete = (percentages: OwnerPercentage[]): boolean => {
    return (
      percentages.length === 0 ||
      percentages.reduce(
        (acc: boolean, owner: OwnerPercentage): boolean => owner.percentage !== null && owner.percentage > 0 && acc,
        true,
      )
    );
  };

  const handleSelectAccount = (account: Account) => {
    setOwnerPercentages([
      ...ownerPercentages,
      {
        id: '' + account.accountId,
        name: account.name,
        email: account.contactEmail,
        percentage: null,
      },
    ]);
    setAddingOwner(false);
  };

  const handleDeleteButton = async (id: string) => {
    const newOwnerPercentages = ownerPercentages.filter(e => {
      return e.id !== id;
    });
    setOwnerPercentages(newOwnerPercentages);
    setValid(addsTo100(newOwnerPercentages) && allOwnershipComplete(newOwnerPercentages));
    if (newOwnerPercentages.length === 0) {
      setAddingOwner(true);
    }
    setStateWasModified(true);
  };

  const editPercentage = async (owner: OwnerPercentage, percentage: number) => {
    const newOwnerPercentages = ownerPercentages.map(item => {
      if (owner === item) {
        return { ...owner, percentage: percentage };
      } else {
        return item;
      }
    });
    setOwnerPercentages(newOwnerPercentages);
    setValid(addsTo100(newOwnerPercentages) && allOwnershipComplete(newOwnerPercentages));
    setStateWasModified(true);
  };

  return (
    <div className="ownership-overview">
      <h2>{ANIMAL_CONSTANTS.OWNERSHIP_OVERVIEW}</h2>
      <p>{ANIMAL_CONSTANTS.OWNERSHIP_OVERVIEW_HEADER}</p>
      <div className="owner-list">
        {ownerPercentages.map((row: OwnerPercentage, index: number) => {
          return (
            <div className="owner-row" key={'owner-id-' + row.id + 'row-' + index}>
              <div className="owner-input-row">
                <div className="owner-input">
                  <label>{ANIMAL_CONSTANTS.ACCOUNT_BUSINESS_FARM}:</label>
                  <input placeholder={row.name} disabled />
                </div>
                <div className="owner-input">
                  <label>{LABEL_CONSTANTS.EMAIL}:</label>
                  <input placeholder={row.email} disabled />
                </div>
                <div className="owner-input">
                  <label>{ANIMAL_CONSTANTS.OWNERSHIP_PERCENTAGE}</label>
                  <input
                    type="text"
                    value={(row.percentage ?? 0) <= 0 ? '' : row.percentage!}
                    onChange={e => {
                      let count = e.target.value
                        .replace(/[^\d]+/, '') //Remove all non-digits
                        .replace(/^0+/, ''); //Remove all leading zeros
                      if (count.length > 2) {
                        count = count.substring(0, 3) === '100' ? '100' : count.substring(0, 2);
                      }
                      editPercentage(row, +count);
                    }}
                  />
                </div>
              </div>
              <button className="icon button" type="button" onClick={() => handleDeleteButton(row.id)}>
                <TrashSvg />
              </button>
            </div>
          );
        })}

        {!addsTo100(ownerPercentages) && (
          <div className="validated-input-message-error">
            <AlertSVG /> {ANIMAL_CONSTANTS.OWNERSHIP_100_PERCENT}
          </div>
        )}

        {!allOwnershipComplete(ownerPercentages) && (
          <div className="validated-input-message-error">
            <AlertSVG /> {ANIMAL_CONSTANTS.OWNERSHIP_REQUIRED}
          </div>
        )}

        {addingOwner ? (
          <div className="account-search-row">
            <label>{ACCOUNT_CONSTANTS.SEARCH_ACCOUNT}:</label>
            <div className="account-input">
              <AccountSearch
                onChange={(account: Account | undefined) => {
                  if (account) {
                    setAddingOwner(false);
                    handleSelectAccount(account);
                  }
                }}
                omitResults={ownerPercentages.map(owner => +owner.id)}
                showIcon
              />
            </div>
            <button className="button icon" type="button" onClick={() => setAddingOwner(false)}>
              <CancelSVG />
              <span className="sr-only">{BUTTON_CONSTANTS.CANCEL}</span>
            </button>
          </div>
        ) : (
          <div className="add-owner">
            <button className="button" type="button" onClick={() => setAddingOwner(true)}>
              <PlusSvg />
              {ANIMAL_CONSTANTS.ADD_OWNER}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OwnershipOverview;
