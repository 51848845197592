import { AxiosResponse } from 'axios';
import { appContextGet } from './httpCommon/appAxios.service';
import { InventoryTransaction } from '../types/interfaces/transaction.interfaces';
import { INVENTORY_TRANSACTIONS_ENDPOINTS } from '../constants/apiEndpoints';
import { StandardParams } from '../types/interfaces/apiParams.interfaces';
import { TransactionTypeEnum } from '../types/enums';

export const getTransactions = (params?: StandardParams): Promise<AxiosResponse<InventoryTransaction[], any>> => {
  return appContextGet(INVENTORY_TRANSACTIONS_ENDPOINTS.INVENTORY_TRANSACTIONS, params);
};

export const getLinkedTransactionsById = async (id: number): Promise<AxiosResponse<InventoryTransaction[], any>> => {
  return appContextGet(
    INVENTORY_TRANSACTIONS_ENDPOINTS.INVENTORY_TRANSACTIONS_ +
      id.toString() +
      INVENTORY_TRANSACTIONS_ENDPOINTS.LINKED_TRANSACTIONS,
  );
};

export const getTransactionById = async (id: string): Promise<AxiosResponse<InventoryTransaction, any>> => {
  return appContextGet(INVENTORY_TRANSACTIONS_ENDPOINTS.INVENTORY_TRANSACTIONS + `/${id}`, {
    include: 'Specimen.Animal,Account',
  });
};

export const getTransactionsOdata = (query?: {}): Promise<AxiosResponse<InventoryTransaction[], any>> => {
  return appContextGet(INVENTORY_TRANSACTIONS_ENDPOINTS.INVENTORY_TRANSACTIONS_ODATA, query);
};

export const getOriginationTransactions = (specimenId: number): Promise<AxiosResponse<InventoryTransaction[], any>> => {
  return appContextGet(INVENTORY_TRANSACTIONS_ENDPOINTS.INVENTORY_TRANSACTIONS_ODATA, {
    include: 'Account',
    filter: `specimenId eq ${specimenId} and (transactionType eq '${TransactionTypeEnum.Collect}' or transactionType eq '${TransactionTypeEnum.Deposit}')`,
  });
};

export const getAccountTransactionsOdata = (query?: {}): Promise<AxiosResponse<InventoryTransaction[], any>> => {
  return appContextGet(INVENTORY_TRANSACTIONS_ENDPOINTS.INVENTORY_TRANSACTIONS_ODATA, query);
};
