import { AxiosResponse } from 'axios';
import { AuthResponse, SignInFormData } from '../types/interfaces/auth.interfaces';
import { ACCOUNT_API_ENDPOINTS, AUTH_API_ENDPOINTS, SIGN_IN_API_URL } from '../constants/apiEndpoints';
import { commonAxios } from './httpCommon/axiosInstances';
import { Buffer } from 'buffer';
import { getLocalRefreshToken, getLocalUser } from '../utils/localStorageHandler';
import { CreateUserAndAccount } from '../types/interfaces/account.interfaces';

export const signIn = (formData: SignInFormData): Promise<AxiosResponse<AuthResponse, any>> => {
  const parameter = formData.email + ':' + formData.password;
  const encoded = Buffer.from(parameter).toString('base64');
  return commonAxios.get<AuthResponse>(SIGN_IN_API_URL, {
    headers: {
      Authorization: 'Basic ' + encoded,
    },
  });
};

export const transientAuth = (formData: SignInFormData): Promise<AxiosResponse<AuthResponse, any>> => {
  const parameter = formData.email + ':' + formData.password;
  const encoded = Buffer.from(parameter).toString('base64');
  return commonAxios.get<AuthResponse>(AUTH_API_ENDPOINTS.TRANSIENT, {
    headers: {
      Authorization: 'Basic ' + encoded,
    },
  });
};

export const signUp = (args: CreateUserAndAccount): any => {
  return commonAxios.post(ACCOUNT_API_ENDPOINTS.SIGNUP, args);
};

export const refreshToken = (): Promise<AxiosResponse<AuthResponse>> => {
  const username = getLocalUser()?.username;
  const token = getLocalRefreshToken();
  const parameter = username + ':' + token;
  const encoded = Buffer.from(parameter).toString('base64');
  return commonAxios.get<AuthResponse>(AUTH_API_ENDPOINTS.REFRESH, {
    headers: {
      authorization: `Basic ${encoded}`,
    },
  });
};
