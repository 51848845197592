import { FC, useEffect, useCallback } from 'react';
import { AlertSVG } from '../svgs';
import { AnimalOwner } from '../../types/interfaces/animal.interfaces';
import { clampValue } from '../../utils/commonUtils';
import NumberInput from '../custom-input/NumberInput';
import './specimenOwnershipBreakdown.scss';

export type OwnerItemDistribution = {
  owner: AnimalOwner;
  ownedQuantity: number;
};

type SpecimenOwnershipBreakdownProps = {
  animalOwners: AnimalOwner[];
  quantity: string;
  ownerItemDistribution: OwnerItemDistribution[];
  setOwnerItemDistribution: (ownerItemDistribution: OwnerItemDistribution[]) => void;
};

const SpecimenOwnershipBreakdown: FC<SpecimenOwnershipBreakdownProps> = ({
  animalOwners,
  quantity,
  ownerItemDistribution,
  setOwnerItemDistribution,
}) => {
  const handleOwnerItemDistribution = useCallback(() => {
    totalPercentMustBe100();

    const newOwnerItemDistribution: OwnerItemDistribution[] = animalOwners.map((owner: AnimalOwner) => {
      return {
        owner,
        ownedQuantity: Math.floor((Number(quantity) * owner.ownershipPercentage) / 100),
      };
    });

    setOwnerItemDistribution(handleFractionalQuantities(newOwnerItemDistribution));
  }, [animalOwners, quantity]);

  useEffect(() => {
    handleOwnerItemDistribution();
  }, [handleOwnerItemDistribution]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>, distribution: OwnerItemDistribution) {
    setOwnerItemDistribution(
      ownerItemDistribution.map(item => {
        if (item.owner === distribution.owner) {
          return {
            ...item,
            ownedQuantity: clampValue(0, Number(quantity ?? 0), Number(e.target.value)),
          };
        } else {
          return item;
        }
      }),
    );
  }

  function handleFractionalQuantities(ownerItemDist: OwnerItemDistribution[]): OwnerItemDistribution[] {
    const totalDistribution = ownerItemDist.reduce((total, owner) => total + owner.ownedQuantity, 0);
    let remaining = Number(quantity) - totalDistribution;

    while (remaining > 0) {
      for (let owner of ownerItemDist.sort((a, b) => b.owner.ownershipPercentage + a.owner.ownershipPercentage)) {
        if (remaining <= 0) break;

        owner.ownedQuantity++;
        remaining--;
      }
    }

    return ownerItemDist;
  }

  function totalPercentMustBe100(): void {
    const totalPercent = animalOwners.reduce((total, owner) => total + owner.ownershipPercentage, 0);
    if (totalPercent !== 100) throw Error(`${totalPercent} must equal 100 percent`);
  }

  return (
    <div className="specimen-ownership-breakdown">
      {ownerItemDistribution.length >= 1 ? (
        ownerItemDistribution.map((distribution: OwnerItemDistribution, index) => {
          return (
            <div className="form-row" key={index}>
              <div className="label-percent">
                <label htmlFor="">{distribution.owner.account?.name} : </label>
                <p>{distribution.owner.ownershipPercentage}%</p>
              </div>
              <div className="input-container">
                <NumberInput
                  disabled={quantity === '0' || !quantity}
                  max={quantity ? Number(quantity) : 0}
                  value={(distribution.ownedQuantity ?? 0).toString()}
                  onChange={e => handleChange(e, distribution)}
                />
              </div>
              <div className="account-due-payment">
                <label>
                  {distribution.owner.account?.outstandingBalanceCents != undefined &&
                  distribution.owner.account?.penaltyCents != undefined &&
                  (distribution.owner.account?.outstandingBalanceCents > 0 || distribution.owner.account?.penaltyCents > 0)
                    ? ' (Due Payment)'
                    : ''}
                </label>
              </div>
            </div>
          );
        })
      ) : (
        <div className="no-owners-error">
          <AlertSVG />
          <p>Animal ownership is undefined and must be specified before continuing</p>
        </div>
      )}
      {ownerItemDistribution.length > 0 &&
        ownerItemDistribution.reduce((acc, curr) => {
          return acc + (curr.ownedQuantity ?? 0);
        }, 0) !== Number(quantity) && (
          <div className="validated-input-message-error">
            <AlertSVG />
            Quantity must equal {quantity}
          </div>
        )}
    </div>
  );
};

export default SpecimenOwnershipBreakdown;
