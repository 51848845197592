import { useEffect, useState } from 'react';
import Modal from '../../modal/Modal';
import SelectStorageItems from '../../select-storage-items/SelectStorageItems';
import { CanisterQuantity } from '../ReconcileCanisterSpecimens';
import { StorageTankOptions } from '../../../types/enums';
import { selectOption } from '../../../types/interfaces';
import { showToast } from '../../../services/toast.service';
import { getStorageCanisterById } from '../../../api/storageItemsApi';
import { toastMessages } from '../../../constants/errorMessages';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS } from '../../../constants/common';
import './addLocationAndOwnershipModal.scss';

export type AddStorageLocationModalProps = {
  isOpen: boolean;
  onConfirm: (specimenLocation: CanisterQuantity) => void;
  setIsOpen: (e: boolean) => void;
  specimenId: number;
  width?: number;
};

const AddStorageLocationModal = ({ isOpen, width = 700, setIsOpen, onConfirm, specimenId }: AddStorageLocationModalProps) => {
  const initialState = { value: '', name: '' };
  const [site, setSite] = useState<selectOption>(initialState);
  const [tank, setTank] = useState<selectOption>(initialState);
  const [canister, setCanister] = useState<selectOption>(initialState);
  const [newSpecimenLocation, setNewSpecimenLocation] = useState<CanisterQuantity>();

  useEffect(() => {
    if (canister.value) {
      fetchStorageLocation(Number(canister.value));
    }
  }, [canister]);

  const fetchStorageLocation = async (id: number) => {
    try {
      const { data: storageCanisters } = await getStorageCanisterById(id, { include: 'StorageTank.StorageSite' });

      const specimenLocationObject = {
        oldQty: 0,
        actualQty: 0,
        specimenLocation: {
          quantity: 0,
          specimen: undefined,
          specimenId: specimenId,
          storageCanister: storageCanisters,
          storageCanisterId: storageCanisters.storageCanisterId,
          transactionLink: undefined,
          transactionLinkId: 0,
        },
      };
      setNewSpecimenLocation(specimenLocationObject);
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const onAddLocationHandler = () => {
    if (newSpecimenLocation) {
      onConfirm(newSpecimenLocation);
    }
  };

  const onCancelHandler = () => {
    setSite(initialState);
    setTank(initialState);
    setCanister(initialState);
    setIsOpen(false);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onCancelHandler} width={width} ignoreBackdrop>
        <div className="add-location-modal">
          <div className="header">
            <h4>{LABEL_CONSTANTS.ADD_LOCATION}</h4>
          </div>
          <div className="body">
            <SelectStorageItems
              selectedCanister={canister}
              selectedSite={site}
              selectedTank={tank}
              setSelectedCanister={setCanister}
              setSelectedSite={setSite}
              setSelectedTank={setTank}
              storageTankOptions={StorageTankOptions.Both}
            />
          </div>
          <div className="footer">
            <button type="button" className="button blue inverted small" onClick={onCancelHandler}>
              {BUTTON_CONSTANTS.CANCEL}
            </button>
            <button type="button" className="button green small" onClick={onAddLocationHandler} disabled={!canister.value}>
              {BUTTON_CONSTANTS.ADD}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddStorageLocationModal;
