import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../loader/Loader';
import { Animal } from '../../types/interfaces/animal.interfaces';
import { SpecimenInventory } from '../../types/interfaces/specimen.interfaces';
import { getSpecimensByOwnership } from '../../api/specimensApi';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { toastMessages } from '../../constants/errorMessages';
import { ANIMAL_CONSTANTS, BUTTON_CONSTANTS, LABEL_CONSTANTS } from '../../constants/common';
import { getAnimalAttachmentURL } from '../../services/animal.service';
import { showToast } from '../../services/toast.service';

const AnimalCard: FC<{ animal: Animal; accountId?: number }> = ({ animal, accountId }): JSX.Element => {
  const navigate = useNavigate();

  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [unitsOwned, setUnitsOwned] = useState<number>();

  const onViewProfile = () => {
    navigate(ROUTE_PATHS.APP_INVENTORY + '/' + animal.animalId);
  };

  const getUnits = async () => {
    if (animal.animalId && accountId) {
      try {
        const specs = await getSpecimensByOwnership(animal.animalId, accountId);
        let quantityOwned = 0;
        specs.data.forEach((specimen: SpecimenInventory) => (quantityOwned += specimen.quantityOwned));
        setUnitsOwned(quantityOwned);
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  };

  useEffect(() => {
    getUnits();
  }, [animal.animalId, accountId]);

  useEffect(() => {
    if (animal.attachments && animal.attachments.length > 0) {
      const url = getAnimalAttachmentURL(animal.animalId!, animal.attachments[0].attachmentId, animal.attachments[0].accessToken);
      setImageUrl(url);
    }
  }, [animal]);

  return (
    <div className="animal-card">
      {imageUrl ? (
        <div className="image-container">
          {imageLoading && (
            <div className="image-loader-container">
              <Loader loaderSize={'small'} />
            </div>
          )}
          <img
            src={imageUrl}
            alt="Animal Image"
            onLoad={() => setImageLoading(false)}
            onError={() => setImageLoading(false)}
            style={{ display: imageLoading ? 'none' : 'block' }}
          />
        </div>
      ) : (
        <div className="animal-image-placeholder">{ANIMAL_CONSTANTS.NO_IMAGE}</div>
      )}
      <div className="animal-info">
        <h3>{animal.name}</h3>
        <p className="animal-short-details">
          {ANIMAL_CONSTANTS.SIRE}: {animal.sire} | {ANIMAL_CONSTANTS.DAM}: ({animal.dam}) | {ANIMAL_CONSTANTS.DNA}: {animal.dna}
        </p>
        <p>
          <u>
            {LABEL_CONSTANTS.UNITS_OWNED}: {unitsOwned ?? '--'}
          </u>
        </p>
        <button className="button green" type="button" onClick={onViewProfile}>
          {BUTTON_CONSTANTS.VIEW_PROFILE}
        </button>
      </div>
    </div>
  );
};

export default AnimalCard;
