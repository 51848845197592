import './toggleSwitch.scss';

type ToggleSwitchProps = {
  label?: string;
  checked: boolean;
  onToggle: () => void;
};

const ToggleSwitch = ({ label, checked, onToggle }: ToggleSwitchProps) => {
  return (
    <div className="toggle-switch-container">
      <label>{label} </label>
      <div className="toggle-switch">
        <label className="switch">
          <input type="checkbox" checked={checked} onChange={onToggle} />
          <span className="slider round"></span>
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
