const PlusSvg = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.16797 11.8332V6.83317H0.167969V5.1665H5.16797V0.166504H6.83464V5.1665H11.8346V6.83317H6.83464V11.8332H5.16797Z"
        fill="#545556"
      />
    </svg>
  );
};

export default PlusSvg;
