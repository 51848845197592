import { FC, useCallback, useEffect, useState } from 'react';
import { SpecimenLocation } from '../../../types/interfaces/specimen.interfaces';
import { getSpecimenLocations } from '../../../api/specimensApi';
import { showToast } from '../../../services/toast.service';
import { toastMessages } from '../../../constants/errorMessages';
import { LABEL_CONSTANTS } from '../../../constants/common';
import './listStorageLocations.scss';

type ListStorageLocationsProps = {
  inventoryId: number | string;
};

type RowProps = {
  location: SpecimenLocation;
};

type LabeledValueProps = {
  label: string;
  value?: string;
};

export const LabeledValue: FC<LabeledValueProps> = ({ label, value }) => {
  return (
    <div className={'labeled-value-info'}>
      <label>
        {label} <span>{value ?? ''}</span>
      </label>
    </div>
  );
};

const Row: FC<RowProps> = ({ location }) => {
  return (
    <div className="location-row card">
      <LabeledValue label="Site :" value={location.storageCanister?.storageTank?.storageSite?.name} />
      <LabeledValue label="Tank :" value={location.storageCanister?.storageTank?.name} />
      <LabeledValue label="Canister :" value={location.storageCanister?.name} />
      <LabeledValue label="Quantity :" value={String(location.quantity)} />
    </div>
  );
};

const ListStorageLocations: FC<ListStorageLocationsProps> = ({ inventoryId }) => {
  const [locations, setLocations] = useState<SpecimenLocation[]>();

  const handleGetLocations = useCallback(async () => {
    const query = { include: 'StorageCanister.StorageTank.StorageSite', filter: `specimenId eq ${inventoryId}` };

    try {
      const specimenLocations = (await getSpecimenLocations(query)).data;
      setLocations(specimenLocations);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  }, [inventoryId]);

  useEffect(() => {
    handleGetLocations();
  }, [handleGetLocations]);

  return (
    <div className="list-storage-locations">
      {locations && locations.length > 0 ? (
        locations.map((location: SpecimenLocation) => {
          return (
            <>
              <div className="desk-list-storage-locations">
                <Row key={'row - ' + location.storageCanister?.storageCanisterId} location={location} />
              </div>

              {/** Mobile View */}
              <div className="xs-list-storage-locations">
                <div className="card" key={'row - ' + location.storageCanister?.storageCanisterId}>
                  <div className="list-storage-location">
                    <div className="storage-location-content">
                      <div className="location-content">
                        <label>{`Site: ${location.storageCanister?.storageTank?.storageSite?.name}`}</label>
                      </div>
                      <div className="location-content">
                        <label>{`Tank: ${location.storageCanister?.storageTank?.name}`}</label>
                      </div>
                    </div>
                    <div className="storage-location-content">
                      <div className="location-content">
                        <label>{`Canister: ${location.storageCanister?.name}`}</label>
                      </div>
                      <div className="location-content">
                        <label>{`Quantity: ${String(location.quantity)}`}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })
      ) : (
        <div className="no-locations">{LABEL_CONSTANTS.NO_LOCATION_FOUND}</div>
      )}
    </div>
  );
};

export default ListStorageLocations;
