import { useEffect, useState } from 'react';
import SortedTable from '../sorted-table/SortedTable';
import Loader from '../loader/Loader';
import { InventoryTransaction } from '../../types/interfaces/transaction.interfaces';
import { Account } from '../../types/interfaces/account.interfaces';
import { getLinkedTransactionsById } from '../../api/transactionsApi';
import { getAccounts } from '../../api/accountApi';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';
import { LABEL_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../constants/common';
import './linkedTransactions.scss';

export interface LinkedTransaction {
  id: string;
  owner: string;
  type: string;
  date: Date;
  status: string;
}

const LinkedTransactions = ({ transactionId }: { transactionId: number }): JSX.Element => {
  const [linkedTransactions, setLinkedTransactions] = useState<InventoryTransaction[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const tableHeaders = [
    { displayName: TABLE_HEADER_CONSTANTS.ID },
    { displayName: TABLE_HEADER_CONSTANTS.ACCOUNT_OWNER },
    { displayName: TABLE_HEADER_CONSTANTS.TYPE },
    { displayName: TABLE_HEADER_CONSTANTS.DATE },
    { displayName: TABLE_HEADER_CONSTANTS.STATUS },
  ];

  useEffect(() => {
    const getTransactions = async () => {
      setIsLoading(true);

      try {
        const { data: transactions } = await getLinkedTransactionsById(transactionId);
        const accountIds = Array.from(new Set(transactions.map(transaction => transaction.accountId)));

        if (accountIds.length) {
          const { data: accounts } = await getAccounts(accountIds);
          setLinkedTransactions(
            transactions
              .filter(transaction => transaction.inventoryTransactionId !== transactionId)
              .map(transaction => {
                return {
                  ...transaction,
                  account: (accounts as Account[]).find((a: Account) => a.accountId === transaction.accountId),
                };
              }),
          );
        }
      } catch {
        showToast.error(toastMessages.FAILED_TO_RETRIEVE_LINKED_TRANSACTIONS);
      } finally {
        setIsLoading(false);
      }
    };

    getTransactions();
  }, [transactionId]);

  return (
    <div className="linked-transaction">
      {isLoading ? (
        <Loader loaderSize="small" addedSpace />
      ) : linkedTransactions.length ? (
        <>
          <div className="desk-linked-transaction">
            <SortedTable
              headers={tableHeaders}
              data={linkedTransactions?.map((t: InventoryTransaction) => {
                return [
                  { content: t.inventoryTransactionId },
                  { content: t?.account?.name },
                  { content: t?.transactionType },
                  { content: new Date(t.createdDatetime).toLocaleDateString() },
                  { content: t.status },
                ];
              })}
            />
          </div>

          {/** Mobile View */}
          <div className="xs-linked-transaction">
            {linkedTransactions &&
              linkedTransactions.map((transaction, index) => {
                return (
                  <div className="card max-width" key={'transaction-' + transaction.inventoryTransactionId + index}>
                    <div className="linked-transaction-content">
                      <div className="transaction-info">
                        <div className="transaction-content">
                          <label>{TABLE_HEADER_CONSTANTS.ID}:</label>
                          <label>{`${transaction.inventoryTransactionId ?? '-'}`}</label>
                        </div>
                        <div className="transaction-content">
                          <label>{TABLE_HEADER_CONSTANTS.ACCOUNT_OWNER}:</label>
                          <label>{`${transaction.account?.name ?? '-'}`}</label>
                        </div>
                      </div>
                      <div className="transaction-info">
                        <div className="transaction-content">
                          <label>{TABLE_HEADER_CONSTANTS.TYPE}:</label>
                          <label>{`${transaction.transactionType ?? '-'}`}</label>
                        </div>
                        <div className="transaction-content">
                          <label>{TABLE_HEADER_CONSTANTS.DATE}:</label>
                          <label>{`${new Date(transaction.createdDatetime).toLocaleDateString() ?? '-'}`}</label>
                        </div>
                        <div className="transaction-content">
                          <label>{TABLE_HEADER_CONSTANTS.STATUS}:</label>
                          <label>{`${transaction.status ?? '-'}`}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      ) : (
        <h4 className="no-linked-transaction">{LABEL_CONSTANTS.NO_LINKED_TRANSACTIONS}</h4>
      )}
    </div>
  );
};

export default LinkedTransactions;
