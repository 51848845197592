import { FC } from 'react';

type DefaultProfileImageProps = {
  className?: string;
};

const DefaultProfileImage: FC<DefaultProfileImageProps> = ({ className }) => {
  return <img className={`default-profile-image ${className}`} src="/images/rsg-default-profile-image.jpg" alt="profile-rsg" />;
};

export default DefaultProfileImage;
