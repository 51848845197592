const ArrowSvg = () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.08854 0L19.1719 7L9.08854 14L12.2281 8L0.838541 8V6L12.2281 6L9.08854 0Z"
        fill="#ADABAA"
      />
    </svg>
  );
};
export default ArrowSvg;
