import { AxiosResponse } from 'axios';
import { appContextGet, appContextPut } from './httpCommon/appAxios.service';
import { BulkTransaction } from '../types/interfaces';
import { BULK_TRANSACTIONS_ENDPOINTS } from '../constants/apiEndpoints';

export const getBulkTransactionsOdata = (query?: {}): Promise<AxiosResponse<any[], any>> => {
  return appContextGet(BULK_TRANSACTIONS_ENDPOINTS.BULK_ORDER_ODATA, query);
};

export const getBulkTransactionById = (
  id: number,
  params?: { include: string },
): Promise<AxiosResponse<BulkTransaction, any>> => {
  return appContextGet(BULK_TRANSACTIONS_ENDPOINTS.BULK_ORDER_ + id, params);
};

export const updateBulkInventoryTransaction = (id: number, data: any): Promise<AxiosResponse<any, any>> => {
  return appContextPut(BULK_TRANSACTIONS_ENDPOINTS.BULK_ORDER_ + id, data);
};
