const GoatSVG = (): JSX.Element => {
  return (
    <svg className="stroke" width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.65156 6.94984C3.37722 5.5735 2.37622 5.29817 2.37622 5.29817C1.80089 5.5735 1.45056 5.19817 1.45056 5.19817C0.84989 4.6225 1.45056 4.04717 1.45056 4.04717C2.80189 3.09617 2.79122 2.72283 2.79122 2.72283L2.21522 1.81917C2.21522 1.81917 3.02689 1.04417 3.85289 2.1705C3.85289 2.1705 5.82956 -0.632499 8.00722 1.9705"
        stroke="#545556"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.65234 5.07768C3.65234 5.07768 4.95334 5.22301 4.97834 3.82168M5.11034 2.40234C5.11034 2.40234 5.87468 2.50601 6.40234 3.69368C6.66534 4.28568 7.87434 6.19368 8.00668 6.27034C8.00668 6.27034 16.515 4.39701 18.192 7.20034C18.192 7.20034 18.7673 7.37534 19.1177 6.42434C19.468 5.47334 20.319 5.34834 20.319 5.34834"
        stroke="#545556"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4694 11.6308C11.4914 13.2575 9.28469 11.1795 9.28469 11.1795L9.44603 14.1452L9.61269 16.1525L9.05536 17.3898C9.02897 17.4483 8.98625 17.498 8.93232 17.5328C8.87839 17.5677 8.81556 17.5862 8.75136 17.5862H8.26336C8.20695 17.5862 8.15147 17.5719 8.1021 17.5446C8.05273 17.5173 8.01109 17.4779 7.98109 17.4302C7.95108 17.3824 7.93368 17.3278 7.93052 17.2715C7.92736 17.2152 7.93855 17.159 7.96303 17.1082L8.35069 16.1125C8.35069 16.1125 8.33403 15.5342 8.18369 14.3078C8.03336 13.0815 7.40803 11.4048 7.40803 11.4048C5.90636 10.5538 6.15669 9.01549 6.15669 9.01549C4.25469 7.63182 4.38469 4.91016 4.38469 4.91016"
        stroke="#545556"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2282 9.56426C15.2282 9.56426 13.5422 11.7136 15.1748 13.7973C15.1748 13.7973 15.2088 15.6026 14.5708 16.4846L14.1845 17.0673C14.1512 17.1175 14.1321 17.1758 14.1293 17.2361C14.1265 17.2963 14.14 17.3562 14.1686 17.4093C14.1971 17.4624 14.2395 17.5068 14.2913 17.5377C14.343 17.5687 14.4022 17.585 14.4625 17.5849H14.9442C15.0422 17.5849 15.1342 17.5426 15.1978 17.4683C15.5035 17.1113 16.3625 15.9513 16.3625 14.1929C16.3625 12.4463 16.7108 11.1783 16.7108 11.1783C18.4002 9.69559 18.4632 8.55859 18.4632 8.55859M17.6822 12.0049C17.6822 12.0049 17.3268 13.0826 18.1402 13.7833L16.7235 17.5193"
        stroke="#545556"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default GoatSVG;
