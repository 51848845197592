import { FC } from 'react';
import { centsToDollars } from '../../../../services/account.service';
import { StoragePlanUOM } from '../../../../types/enums/storagePlanUOM.enum';
import { STORAGE_PLAN_CONSTANTS } from '../../../../constants/common';
import './storagePlanCard.scss';

export type StoragePlanCardProps = {
  storagePlanId: number;
  name: string;
  costInCents: number;
  unitOfMeasurement: string;
  period: string;
};

const StoragePlanCard: FC<StoragePlanCardProps> = ({ name, costInCents, unitOfMeasurement, period }) => {
  const cost = centsToDollars(costInCents);

  return (
    <div className="storage-plan-card">
      <h4 className="storage-plan-header">{name}</h4>
      <br />
      <h5 className="cost-amount">${cost}</h5>
      <br />
      {unitOfMeasurement === StoragePlanUOM.FLAT_RATE ? (
        <p>{STORAGE_PLAN_CONSTANTS.FLAT_RATE}</p>
      ) : (
        <p>{STORAGE_PLAN_CONSTANTS.PER_UNIT}</p>
      )}
      {period === StoragePlanUOM.SIX_MONTHS ? (
        <p>{STORAGE_PLAN_CONSTANTS.FOR_6_MONTHS}</p>
      ) : (
        <p>{STORAGE_PLAN_CONSTANTS.PER_MONTH}</p>
      )}
    </div>
  );
};

export default StoragePlanCard;
