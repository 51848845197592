import { createSlice } from '@reduxjs/toolkit';
import { PaymentStatus } from '../../types/interfaces';

const initialState = {
  addedPaymentMethod: undefined,
} as PaymentStatus;

export const paymentGatewaySlice = createSlice({
  name: 'paymentGatewaySlice',
  initialState,
  reducers: {
    storePaymentStatus: (state, action) => {
      state.addedPaymentMethod = action.payload;
    },
  },
});

export const { storePaymentStatus } = paymentGatewaySlice.actions;

export default paymentGatewaySlice.reducer;
