import { FC, useEffect, useState } from 'react';
import Loader from '../loader/Loader';
import AddAccountOwnershipModal from './modal/AddAccountOwnershipModal';
import { Specimen } from '../../types/interfaces/specimen.interfaces';
import { Account } from '../../types/interfaces';
import { formatPositiveNumber } from '../../utils/commonUtils';
import { getSpecimenOwners } from '../../api/specimensApi';
import { getCountBySpecimen } from '../../api/inventoryApi';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';
import { LABEL_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../constants/common';

export type AccountOwnershipQuantity = {
  account: Account;
  actualQty: number;
  oldQty: number;
};

type ReconcileAccountOwnershipProps = {
  specimen: Specimen;
  onChange: (e: { value: AccountOwnershipQuantity[]; valid: boolean }) => void;
};

const ReconcileAccountOwnership: FC<ReconcileAccountOwnershipProps> = ({
  specimen,
  onChange,
}: ReconcileAccountOwnershipProps): JSX.Element => {
  const [isAddAccountOwnershipModalOpen, setIsAddAccountOwnershipModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [specimens, setSpecimens] = useState<AccountOwnershipQuantity[]>([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data: specimenAccounts } = await getSpecimenOwners(specimen.specimenId, { includeNegativeInventory: true });
        const specimenOwnership = await Promise.all(
          specimenAccounts.map(async (acc: Account) => {
            const { data: quant } = await getCountBySpecimen(specimen.specimenId, { accountId: acc.accountId });
            return { account: acc, oldQty: quant, actualQty: quant };
          }),
        );
        setSpecimens(specimenOwnership);
      } catch {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setLoading(false);
      }
    };

    if (specimen) {
      fetch();
    }
  }, [specimen]);

  useEffect(() => {
    onChange({ value: specimens, valid: !loading });
  }, [specimens, loading]);

  const getTotal = (): number => {
    return specimens.reduce((acc: number, spec: AccountOwnershipQuantity): number => acc + spec.actualQty, 0);
  };

  const onAdjustedQtyChange = (e: any, index: number) => {
    const count = formatPositiveNumber(e.target.value);
    let newSpecimens = [...specimens];
    newSpecimens[index].actualQty = count;
    setSpecimens(newSpecimens);
    onChange({ value: newSpecimens, valid: true });
  };

  const handleAddNewAccountOwnership = (accountOwnership: AccountOwnershipQuantity) => {
    const isDuplicateAccount = specimens.some(specimen => specimen?.account?.accountId === accountOwnership?.account?.accountId);

    if (isDuplicateAccount) {
      showToast.error(toastMessages.DUPLICATE_ACCOUNT);
    } else {
      let newSpecimens = [...specimens, accountOwnership];
      setSpecimens(newSpecimens);
      setIsAddAccountOwnershipModalOpen(false);
    }
  };

  return (
    <>
      <AddAccountOwnershipModal
        isOpen={isAddAccountOwnershipModalOpen}
        setIsOpen={setIsAddAccountOwnershipModalOpen}
        onConfirm={accountOwnership => handleAddNewAccountOwnership(accountOwnership)}
        specimenId={specimen?.specimenId}
      />
      <div className="desk-reconcile-account-ownership">
        <div className="specimen-table">
          <table>
            <thead>
              <tr>
                <th>{TABLE_HEADER_CONSTANTS.ACCOUNT_BUSINESS_FARM}</th>
                <th>{TABLE_HEADER_CONSTANTS.OWNED_QTY}</th>
                <th>{TABLE_HEADER_CONSTANTS.ADJUSTED_QTY}</th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                specimens?.map((acc: AccountOwnershipQuantity, index: number) => {
                  return (
                    <tr key={'canister-specimen-row:' + acc.account.accountId + '-index:' + index}>
                      <td>{acc.account.name}</td>
                      <td>{acc.oldQty}</td>
                      <td>
                        <input type="text" placeholder="0" value={acc.actualQty} onChange={e => onAdjustedQtyChange(e, index)} />
                      </td>
                    </tr>
                  );
                })}
              {!loading && specimens?.length === 0 && (
                <tr>
                  <td colSpan={6}>
                    <>{LABEL_CONSTANTS.NO_ACCOUNTS_FOUND}</>
                  </td>
                </tr>
              )}
              {loading && (
                <tr>
                  <td colSpan={6}>
                    <>
                      <br />
                      <br />
                      <Loader loaderSize="small" />
                      <br />
                      <br />
                    </>
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={1} />
                <td>
                  <div className="owned-quantity quantity">{getTotal().toString()}</div>
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => setIsAddAccountOwnershipModalOpen(true)}
                    className="add-account-ownership-button">
                    +
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/** Mobile View */}
      <div className="xs-reconcile-account-ownership">
        {loading ? (
          <Loader addedSpace loaderSize="small" />
        ) : !loading && specimens?.length === 0 ? (
          <div className="result-item">{LABEL_CONSTANTS.NO_ACCOUNTS_FOUND}</div>
        ) : (
          <div>
            {specimens.length > 0 &&
              specimens &&
              specimens.map((acc, index) => {
                return (
                  <div className="card max-width" key={acc?.account.name + index}>
                    <div className="reconcile-account-ownership">
                      <div className="card-content-section">
                        <div className="left center">
                          <label>{`${TABLE_HEADER_CONSTANTS.ACCOUNT_BUSINESS_FARM} : ${acc?.account.name}`}</label>
                        </div>
                        <div className="left center">
                          <label>{`${TABLE_HEADER_CONSTANTS.OWNED_QTY}:`}</label>
                          <label>{`${acc?.oldQty}`}</label>
                        </div>
                        <div className="right column center">
                          <label>{`${TABLE_HEADER_CONSTANTS.ADJUSTED_QTY}:`}</label>
                          <input
                            type="text"
                            placeholder="0"
                            value={acc.actualQty}
                            onChange={e => onAdjustedQtyChange(e, index)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className="account-ownership-footer">
              <button
                type="button"
                onClick={() => setIsAddAccountOwnershipModalOpen(true)}
                className="add-account-ownership-button">
                +
              </button>
              <div className="total-quantity">
                <label>{TABLE_HEADER_CONSTANTS.OWNED_QTY}: &nbsp;</label>
                {getTotal().toString()}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReconcileAccountOwnership;
