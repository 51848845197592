import { useEffect, useState } from 'react';
import ValidatedInput, { ValidatedState } from '../../components/custom-input/ValidatedInput';
import { Validators } from '../../types/enums';
import { StorageSite } from '../../types/interfaces';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';
import { updateStorageSite } from '../../api/storageItemsApi';
import './locationManagement.scss';

type EditSiteModalProps = {
  close: () => void;
  site: StorageSite;
};

const EditSiteModal = ({ close, site }: EditSiteModalProps): JSX.Element => {
  const [nameInput, setNameInput] = useState<ValidatedState>({ valid: true, value: site.name });
  const [activeInput, setActiveInput] = useState<boolean>(site.active);
  const [validate, setValidate] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);

  useEffect(() => {
    const action = async () => {
      try {
        await updateStorageSite(site.storageSiteId, { name: nameInput.value, active: activeInput });
        showToast.success(toastMessages.SITE_UPDATE_SUCCESS);
      } catch {
        showToast.success(toastMessages.SITE_UPDATE_FAIL);
      } finally {
        setSubmit(false);
        close();
      }
    };
    if (submit) {
      action();
    }
  }, [submit]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setValidate(true);
    if (nameInput.valid) {
      setSubmit(true);
    }
  };

  return (
    <div className="management-columns-modal">
      <form onSubmit={handleSubmit}>
        <h2>Edit Site</h2>
        <p>Please complete the form to update your site.</p>
        <ValidatedInput
          useId="name-input-field"
          type="text"
          label={'Site Name'}
          validate={validate}
          validatedStateForAutoFill={nameInput}
          setValidatedState={setNameInput}
          validators={[Validators.REQUIRED]}
        />
        <div className="form-row">
          <label htmlFor="active-checkbox">Active</label>
          <div className="input-container">
            <input
              id="active-checkbox"
              type="checkbox"
              defaultChecked={site.active}
              onChange={e => setActiveInput(e.target.checked)}
            />
          </div>
        </div>
        <div className="flex-right submit-button">
          <button type="button" className="small green inverted button" onClick={close}>
            Cancel
          </button>
          &emsp;
          <button type="submit" className={nameInput.valid ? 'small green button' : 'small green button disabled'}>
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditSiteModal;
