import { AxiosResponse } from 'axios';
import { appContextDelete, appContextGet, appContextPost, appContextPut } from './httpCommon/appAxios.service';
import { LinkedStorageMovement } from '../components/transaction/LinkedStorageMovements';
import {
  INVENTORY_API_ENDPOINTS,
  INVENTORY_TRANSACTIONS_ENDPOINTS,
  INVENTORY_TRANSACTION_LOCATIONS_ENDPOINTS,
} from '../constants/apiEndpoints';
import { InventoryTransaction, InventoryTransactionLocation } from '../types/interfaces/transaction.interfaces';
import { CompleteWithdraw } from '../types/interfaces';
import { PagedResponse } from '../types/interfaces/apiParams.interfaces';
import { BulkOrderCompleteWithdraw, SpecimensLocationReport } from '../types/interfaces/bulkOrder.interfaces';

export const getInventoryTransactionBySellCode = (sellCode: string): Promise<AxiosResponse<InventoryTransaction[], any>> => {
  return appContextGet(INVENTORY_TRANSACTIONS_ENDPOINTS.INVENTORY_TRANSACTIONS, {
    include: 'Specimen.Animal',
    filter: `TransferCode eq ${sellCode}`,
  });
};

export const getInventoryTransactions = (params?: {
  include?: string;
  filter?: string;
  sort?: string;
  limit?: number;
  offset?: number;
}): Promise<AxiosResponse<InventoryTransaction[] | PagedResponse<InventoryTransaction[]>, any>> => {
  return appContextGet(INVENTORY_TRANSACTIONS_ENDPOINTS.INVENTORY_TRANSACTIONS, params);
};

export const getAdminInventoryTransactions = (params?: {
  include?: string;
  filter?: string;
  sort?: string;
  limit?: number;
  offset?: number;
}): Promise<AxiosResponse<InventoryTransaction[] | PagedResponse<InventoryTransaction[]>, any>> => {
  return appContextGet(INVENTORY_TRANSACTIONS_ENDPOINTS.INVENTORY_TRANSACTIONS, params);
};

export const getLinkedStorageMovements = (transactionId: number): Promise<AxiosResponse<LinkedStorageMovement[], any>> => {
  return appContextGet(
    INVENTORY_TRANSACTIONS_ENDPOINTS.GET_ + transactionId + INVENTORY_TRANSACTIONS_ENDPOINTS.LINKED_STORAGE_MOVEMENT,
  );
};

export const getInventoryTransactionById = (
  id: number,
  params?: { include: string },
): Promise<AxiosResponse<InventoryTransaction, any>> => {
  return appContextGet(INVENTORY_TRANSACTIONS_ENDPOINTS.GET_ + id, params);
};

export const updateInventoryTransaction = (transactionId: number, data: any): Promise<AxiosResponse<any, any>> => {
  return appContextPut(INVENTORY_TRANSACTIONS_ENDPOINTS.GET + `/${transactionId}`, data);
};

export const completeWithdraw = (transaction: CompleteWithdraw): Promise<AxiosResponse<any, any>> => {
  return appContextPut(INVENTORY_API_ENDPOINTS.WITHDRAW, transaction);
};

export const completeBulkWithdraw = (withdrawComplete: BulkOrderCompleteWithdraw[]): Promise<AxiosResponse<any, any>> => {
  return appContextPut(INVENTORY_API_ENDPOINTS.BULK_WITHDRAW, { withdrawComplete });
};

export const downloadSpecimenLocations = (payload: SpecimensLocationReport): Promise<AxiosResponse<any, any>> => {
  return appContextPost(INVENTORY_API_ENDPOINTS.SPECIMEN_LOCATION_REPORT, payload, {}, { responseType: 'blob' });
};

export const getInventoryTransactionLocations = (query: {}): Promise<AxiosResponse<InventoryTransactionLocation[], any>> => {
  return appContextGet(INVENTORY_TRANSACTION_LOCATIONS_ENDPOINTS.TRANSACTION_LOCATIONS, query);
};

export const storeInventoryTransactionLocations = (payload: BulkOrderCompleteWithdraw[]): Promise<AxiosResponse<any, any>> => {
  return appContextPost(INVENTORY_TRANSACTION_LOCATIONS_ENDPOINTS.TRANSACTION_LOCATIONS, { withdrawComplete: payload });
};

export const removeInventoryTransactionLocations = (query: {}): Promise<AxiosResponse<{}, any>> => {
  return appContextDelete(INVENTORY_TRANSACTION_LOCATIONS_ENDPOINTS.TRANSACTION_LOCATIONS, query);
};
