import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AccountNav from '../AccountNav/AccountNav';
import AnimalManagementLinks from './adminLinks/AnimalManagementLinks';
import InventoryManagement from './adminLinks/InventoryManagementLinks';
import { DataSVG, DropDownSVG, HistorySVG, LocationSVG, PersonsSVG, SettingsSVG } from '../../svgs';
import { APP_PATHS } from '../../../constants/routePaths';
import { PermissionService } from '../../../services/permission.service';
import { AccountUserInfo } from '../../../types/interfaces';
import { redirectToTopOfThePage } from '../../../utils/commonUtils';

type AdminNavProps = {
  currentRoute: string[];
  accounts: AccountUserInfo[];
  permissionService: PermissionService;
  showAdminNav: boolean;
  setShowAdminNav: React.Dispatch<React.SetStateAction<boolean>>;
  showAccountNav: boolean;
  setShowAccountNav: React.Dispatch<React.SetStateAction<boolean>>;
  showAnimalManagementOptions: boolean;
  setShowAnimalManagementOptions: React.Dispatch<React.SetStateAction<boolean>>;
  showInventoryManagementOptions: boolean;
  setShowInventoryManagementOptions: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenNavBar: React.Dispatch<React.SetStateAction<boolean>>;
};

const AdminNav: FC<AdminNavProps> = ({
  currentRoute,
  accounts,
  permissionService,
  showAdminNav,
  setShowAdminNav,
  showAccountNav,
  setShowAccountNav,
  showAnimalManagementOptions,
  setShowAnimalManagementOptions,
  showInventoryManagementOptions,
  setShowInventoryManagementOptions,
  setOpen,
  setOpenNavBar,
}) => {
  const [showAnimalManagementPages, setShowAnimalManagementPages] = useState<boolean>(false);
  const [showInventoryManagementPages, setShowInventoryManagementPages] = useState<boolean>(false);

  useEffect(() => {
    setShowAnimalManagementPages(showAnimalManagementOptions);
    setShowInventoryManagementPages(showInventoryManagementOptions);
  }, [showAnimalManagementOptions, showInventoryManagementOptions]);

  const activeLinkClass = (path: string) => {
    return currentRoute.includes(path) ? 'nav-link active' : 'nav-link';
  };

  const closeSideBar = () => {
    setOpen(false);
    setOpenNavBar(false);
    redirectToTopOfThePage();
  };

  const AdminLinks = () => {
    return (
      <>
        <AnimalManagementLinks
          showAnimalManagementPages={showAnimalManagementPages}
          setShowAnimalManagementPages={e => {
            setShowAnimalManagementPages(e);
            setShowAnimalManagementOptions(e);
          }}
          currentRoute={currentRoute}
          setOpen={setOpen}
          setOpenNavBar={setOpenNavBar}
        />
        <InventoryManagement
          showInventoryManagementPages={showInventoryManagementPages}
          setShowInventoryManagementPages={e => {
            setShowInventoryManagementPages(e);
            setShowInventoryManagementOptions(e);
          }}
          currentRoute={currentRoute}
          setOpen={setOpen}
          setOpenNavBar={setOpenNavBar}
        />
        <Link
          className={activeLinkClass(APP_PATHS.TRANSACTION_MANAGEMENT)}
          to={APP_PATHS.TRANSACTION_MANAGEMENT}
          onClick={closeSideBar}>
          <HistorySVG />
          Transaction Management
        </Link>
        <Link className={activeLinkClass(APP_PATHS.CUSTOMER_INFO)} to={APP_PATHS.CUSTOMER_INFO} onClick={closeSideBar}>
          <DataSVG />
          Customer Info
        </Link>
        <Link
          className={activeLinkClass(APP_PATHS.LOCATION_MANAGEMENT)}
          to={APP_PATHS.LOCATION_MANAGEMENT}
          onClick={closeSideBar}>
          <LocationSVG />
          Location Management
        </Link>
        <Link className={activeLinkClass(APP_PATHS.APPLICATION_TEAM)} to={APP_PATHS.APPLICATION_TEAM} onClick={closeSideBar}>
          <PersonsSVG />
          Application Team
        </Link>
        <Link className={activeLinkClass(APP_PATHS.ADMIN_SETTINGS)} to={APP_PATHS.ADMIN_SETTINGS} onClick={closeSideBar}>
          <SettingsSVG />
          Admin Settings
        </Link>
      </>
    );
  };

  return accounts && accounts.length ? (
    <div>
      <hr />
      <button
        type="button"
        className={showAdminNav ? 'nav-link active' : 'nav-link'}
        onClick={() => {
          setShowAdminNav(prev => !prev);
          setShowAccountNav(false);
        }}>
        Application Admin
        <DropDownSVG reversed={showAdminNav} />
      </button>
      <div className={showAdminNav ? 'account-nav' : 'account-nav hide'}>
        <AdminLinks />
      </div>
      <hr />
      <button
        type="button"
        className={showAccountNav ? 'nav-link active' : 'nav-link'}
        onClick={() => {
          setShowAccountNav(prev => !prev);
          setShowAdminNav(false);
        }}>
        Account
        <DropDownSVG reversed={showAccountNav} />
      </button>
      <AccountNav
        showMenu={showAccountNav}
        currentRoute={currentRoute}
        permissionService={permissionService}
        setOpen={setOpen}
        setOpenNavBar={setOpenNavBar}
      />
    </div>
  ) : (
    <div>
      <AdminLinks />
    </div>
  );
};

export default AdminNav;
