import React, { FC, useCallback, useEffect, useState } from 'react';
import FilterDropdown, { FilterOption } from '../../components/custom-input/FilterDropdown';
import ValidatedInput, { ValidatedState } from '../../components/custom-input/ValidatedInput';
import DateInput from '../../components/custom-input/DateInput';
import { AlertSVG } from '../../components/svgs';
import { showToast } from '../../services/toast.service';
import { getAnimals } from '../../api/animalsApi';
import { getSpecies } from '../../api/speciesApi';
import { getBreedsOfSpecies } from '../../api/breedsApi';
import { Validators } from '../../types/enums/validators.enum';
import { Animal, Breed, Species } from '../../types/interfaces/animal.interfaces';
import { Genders } from '../../types/enums/genders.enum';
import { toastMessages } from '../../constants/errorMessages';
import { ANIMAL_CONSTANTS, VALIDATION_ALERT_CONSTANTS } from '../../constants/common';

type AnimalInfoFormProps = {
  animalInfo: Animal;
  setAnimalInfo: React.Dispatch<React.SetStateAction<Animal>>;
  validate?: boolean;
  startsValid?: boolean;
  setValid?: (e: boolean) => void;
  setStateWasModified?: (e: boolean) => void;
};

type AnimalInfoForm = {
  name: ValidatedState;
  species: FilterOption<number>;
  breed: FilterOption<number>;
  gender: FilterOption<number>;
  birthDate: Date | undefined;
  sire: ValidatedState;
  dam: ValidatedState;
  dna: ValidatedState;
  code: ValidatedState;
  registration: ValidatedState;
  description: ValidatedState;
};

const AnimalInfoForm: FC<AnimalInfoFormProps> = ({
  animalInfo,
  setAnimalInfo,
  validate = false,
  startsValid = false,
  setValid = (e: boolean) => {},
  setStateWasModified = (e: boolean) => {},
}: AnimalInfoFormProps): JSX.Element => {
  const [codeUnique, setCodeUnique] = useState<boolean>(true);
  const [speciesOptions, setSpeciesOptions] = useState<FilterOption<number>[]>([]);
  const [breedOptions, setBreedOptions] = useState<FilterOption<number>[]>([]);
  const [startingAnimalCode] = useState<string>(animalInfo.code ?? '');

  const [animalFormValues, setAnimalFormValues] = useState<AnimalInfoForm>({
    name: { value: animalInfo.name, valid: startsValid },
    species: { name: animalInfo.breed?.species?.name ?? '', value: animalInfo.breed?.species?.speciesId ?? 0 },
    breed: { name: animalInfo.breed?.name ?? '', value: animalInfo.breed?.breedId ?? 0 },
    gender: { name: animalInfo.gender ?? Genders.Default, value: Object.values(Genders).indexOf(animalInfo.gender ?? '') },
    birthDate: animalInfo.birthDate ? new Date(animalInfo.birthDate) : undefined,
    sire: { value: animalInfo.sire ?? '', valid: startsValid },
    dam: { value: animalInfo.dam ?? '', valid: startsValid },
    dna: { value: animalInfo.dna ?? '', valid: startsValid },
    code: { value: animalInfo.code ?? '', valid: startsValid },
    registration: { value: animalInfo.registration ?? '', valid: startsValid },
    description: { value: animalInfo.description ?? '', valid: startsValid },
  });

  const handleAnimalCode = async (code: ValidatedState) => {
    setAnimalFormValues({
      ...animalFormValues,
      code: { value: code?.value?.replace(/\s+/g, ' '), valid: code?.valid && code?.value?.trim().length > 0 },
    });
    setStateWasModified(true);
    if (code?.valid && code?.value?.trim().length > 0) {
      if (code.value.toLowerCase() === startingAnimalCode.toLowerCase()) {
        setCodeUnique(true);
      } else {
        try {
          const response: any = await getAnimals({ filter: 'Code eq ' + code.value });
          const isUnique = !(response.data.length > 0);
          setCodeUnique(isUnique);
        } catch (error: any) {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      }
    }
  };

  const handleGetBreedOptions = useCallback(async (speciesId: number) => {
    try {
      const response = await getBreedsOfSpecies(speciesId);
      const newBreeds = response.data as Breed[];
      const activeBreeds = newBreeds.filter(breed => breed.active);

      setBreedOptions(
        activeBreeds.map((breeds: any) => {
          return { name: breeds.name, value: breeds.breedId };
        }),
      );
    } catch (error: any) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  }, []);

  const handleGetAllSpecies = useCallback(async () => {
    try {
      const response = await getSpecies();
      const newSpecies = response.data as Species[];
      const activeSpecies = newSpecies.filter(species => species.active);

      setSpeciesOptions(
        activeSpecies.map((species: any) => {
          return { name: species.name, value: species.speciesId };
        }),
      );
    } catch (error: any) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  }, []);

  useEffect(() => {
    handleGetAllSpecies();
  }, [handleGetAllSpecies]);

  useEffect(() => {
    if (animalInfo.breed?.species?.speciesId) {
      handleGetBreedOptions(animalInfo.breed?.species?.speciesId);
    }
  }, [animalInfo.breed?.species?.speciesId, handleGetBreedOptions]);

  useEffect(() => {
    setAnimalInfo(prevAnimalInfo => ({
      ...prevAnimalInfo,
      name: animalFormValues.name.value,
      breed: {
        breedId: animalFormValues.breed.value,
        name: animalFormValues.breed.name,
        species: { speciesId: animalFormValues.species.value, name: animalFormValues.species.name },
      },
      gender: animalFormValues.gender.name as Genders,
      birthDate: animalFormValues.birthDate!,
      sire: animalFormValues.sire.value,
      dam: animalFormValues.dam.value,
      dna: animalFormValues.dna.value,
      code: animalFormValues.code.value.trim(),
      registration: animalFormValues.registration.value,
      description: animalFormValues.description.value,
    }));
  }, [animalFormValues, setAnimalInfo]);

  useEffect(() => {
    setValid(
      animalFormValues.name.value.trim().length > 0 &&
        animalFormValues.species.value > 0 &&
        animalFormValues.breed.value > 0 &&
        animalFormValues.gender.value > 0 &&
        animalFormValues.code.value.trim().length > 0 &&
        codeUnique,
    );
  }, [animalFormValues, codeUnique, setValid]);

  return (
    <div className="animal-info-form">
      <form>
        <div className="row">
          <div className="input">
            <ValidatedInput
              label="*Name:"
              useId="name"
              type="text"
              placeholder="Enter Name"
              validatedStateForAutoFill={animalFormValues.name}
              setValidatedState={name => {
                setAnimalFormValues({
                  ...animalFormValues,
                  name: { value: name.value.replace(/\s+/g, ' '), valid: name.valid && name.value.trim().length > 0 },
                });
                setStateWasModified(true);
              }}
              validators={[Validators.REQUIRED]}
              validate={validate}
            />
          </div>
          <div className="input">
            <label>*{ANIMAL_CONSTANTS.SPECIES}:</label>
            <FilterDropdown
              options={speciesOptions}
              value={animalFormValues.species}
              onChange={species => {
                setAnimalFormValues({ ...animalFormValues, species, breed: { name: '', value: 0 } });
                setStateWasModified(true);
              }}
              validate={validate}
            />
          </div>
        </div>
        <div className="row">
          <div className="input">
            <label>*{ANIMAL_CONSTANTS.BREED}:</label>
            <FilterDropdown
              options={breedOptions}
              value={animalFormValues.breed}
              onChange={breed => {
                setAnimalFormValues({ ...animalFormValues, breed });
                setStateWasModified(true);
              }}
              disabled={!animalFormValues.species.value}
              validate={validate}
            />
          </div>
          <div className="input">
            <label htmlFor="gender">*{ANIMAL_CONSTANTS.GENDER}:</label>
            <FilterDropdown
              options={[
                { name: Genders.Default, value: 0 },
                { name: Genders.Male, value: 1 },
                { name: Genders.Female, value: 2 },
              ]}
              value={animalFormValues.gender}
              onChange={gender => {
                setAnimalFormValues({ ...animalFormValues, gender });
                setStateWasModified(true);
              }}
              validate={validate}
            />
          </div>
        </div>
        <div className="row">
          <div className="input">
            <label>{ANIMAL_CONSTANTS.DATE_OF_BIRTH}:</label>
            <DateInput
              value={animalFormValues.birthDate}
              onChange={(date: Date) => {
                setAnimalFormValues({ ...animalFormValues, birthDate: date });
                setStateWasModified(true);
              }}
              isClearable
            />
          </div>
          <div className="input">
            <ValidatedInput
              label="Sire:"
              useId="sire"
              type="text"
              validatedStateForAutoFill={animalFormValues.sire}
              validators={[]}
              placeholder="Enter Sire Name"
              setValidatedState={sire => {
                setAnimalFormValues({ ...animalFormValues, sire });
                setStateWasModified(true);
              }}
              validate={validate}
            />
          </div>
        </div>
        <div className="row">
          <div className="input">
            <ValidatedInput
              label="Dam:"
              useId="dam"
              type="text"
              placeholder="Enter Dam"
              validatedStateForAutoFill={animalFormValues.dam}
              setValidatedState={dam => {
                setAnimalFormValues({ ...animalFormValues, dam });
                setStateWasModified(true);
              }}
              validators={[]}
              validate={validate}
            />
          </div>
          <div className="input">
            <ValidatedInput
              label="DNA:"
              useId="dna"
              type="text"
              placeholder="Enter DNA"
              validatedStateForAutoFill={animalFormValues.dna}
              setValidatedState={dna => {
                setAnimalFormValues({ ...animalFormValues, dna });
                setStateWasModified(true);
              }}
              validators={[]}
              validate={validate}
            />
          </div>
        </div>
        <div className="row">
          <div className="input">
            <ValidatedInput
              label="*Animal Code:"
              useId="animal-code"
              validatedStateForAutoFill={animalFormValues.code}
              placeholder="Enter Animal Code"
              type="text"
              setValidatedState={e => handleAnimalCode(e)}
              validators={[Validators.REQUIRED]}
              validate={validate}
            />
            {!codeUnique && (
              <div className="validated-input-message-error">
                <AlertSVG /> {VALIDATION_ALERT_CONSTANTS.UNIQUE_ANIMAL_CODE}
              </div>
            )}
          </div>
          <div className="input">
            <ValidatedInput
              label="Registration:"
              useId="registration"
              type="text"
              validatedStateForAutoFill={animalFormValues.registration}
              setValidatedState={registration => {
                setAnimalFormValues({ ...animalFormValues, registration });
                setStateWasModified(true);
              }}
              validators={[]}
              validate={validate}
            />
          </div>
        </div>
        <div className="input">
          <label>{ANIMAL_CONSTANTS.DESCRIPTION}:</label>
          <textarea
            value={animalFormValues.description.value}
            onChange={e => {
              setAnimalFormValues({ ...animalFormValues, description: { value: e.target.value, valid: true } });
              setStateWasModified(true);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default AnimalInfoForm;
