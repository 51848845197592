import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import ValidatedInput, { ValidatedState } from '../../components/custom-input/ValidatedInput';
import { PasswordRequirementsTooltip } from '../../components/tooltip/customTooltips';
import { PromiseError, toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { PASSWORDS_CONSTANTS } from '../../constants/common';
import { PASSWORD_VALIDATORS, Validators } from '../../types/enums/validators.enum';
import { setNewPassword } from '../../api/userApi';
import { showToast } from '../../services/toast.service';

const ResetPassword = (): JSX.Element => {
  const [password, setPassword] = useState<ValidatedState>({ value: '', valid: false });
  const [confirmPassword, setConfirmPassword] = useState<ValidatedState>({ value: '', valid: false });
  const [validate, setValidate] = useState<boolean>(false);

  const navigate = useNavigate();

  const formIsValid = (): boolean => {
    return password.valid && password.value === confirmPassword.value;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setValidate(true);

    if (formIsValid()) {
      try {
        await setNewPassword({ new: password.value });
        showToast.success(toastMessages.PASSWORD_RESET_SUCCESS);
        navigate(ROUTE_PATHS.SIGN_IN);
      } catch (error: any) {
        if (error.code === PromiseError.BAD_REQUEST) {
          showToast.error(toastMessages.PASSWORD_CANNOT_BE_SAME);
        } else if (error.code === PromiseError.NETWORK) {
          showToast.error(toastMessages.NETWORK);
        } else {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      }
    }
  };

  return (
    <div className="auth">
      <div className="content-container">
        <div className="content">
          <div className="form">
            <h1>{PASSWORDS_CONSTANTS.RESET_PASSWORD}</h1>
            <p className="password-subheader">{PASSWORDS_CONSTANTS.RESET_PASSWORD_SUBHEADER}</p>
            <br />

            <form onSubmit={e => handleSubmit(e)}>
              <div className="input">
                <ValidatedInput
                  label="New Password"
                  type="password"
                  customTooltip={<PasswordRequirementsTooltip />}
                  setValidatedState={setPassword}
                  validators={[Validators.REQUIRED, ...PASSWORD_VALIDATORS]}
                  validate={validate}
                />
              </div>

              <div className="input">
                <ValidatedInput
                  label="Confirm Password"
                  type="password"
                  customTooltip={<PasswordRequirementsTooltip />}
                  setValidatedState={setConfirmPassword}
                  validators={[Validators.REQUIRED]}
                  validate={validate}
                  matchString={password.value}
                />
              </div>

              <button type="submit" className="green button" disabled={!formIsValid()}>
                {PASSWORDS_CONSTANTS.RESET_PASSWORD}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
