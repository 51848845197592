import { Route, Routes } from 'react-router-dom';
import InventoryManagementCheckIn from '../pages/inventory-management/InventoryManagementCheckIn';
import InventoryManagementCheckOut from '../pages/inventory-management/InventoryManagementCheckOut';
import InventoryManagementCollectDeposit from '../pages/inventory-management/InventoryManagementCollectDeposit';
import InventoryManagementMove from '../pages/inventory-management/InventoryManagementMove';
import InventoryManagementOnSiteSell from '../pages/inventory-management/InventoryManagementOnSiteSell';
import TransactionDiscard from '../pages/transaction-management/TransactionDiscard';
import InventoryManagementCanisterContents from '../pages/inventory-management/InventoryManagementCanisterContents';
import CustomDataPoints from '../pages/custom-data-points/CustomDataPoints';
import InventoryManagementReconcile from '../pages/inventory-management/InventoryManagementReconcile';
import InventoryManagementReconcileItem from '../pages/inventory-management/InventoryManagementReconcileItem';
import InventoryManagementClinicUse from '../pages/inventory-management/InventoryManagementClinicUse';
import InventoryManagementThreshold from '../pages/inventory-management/InventoryManagementThreshold';
import { INVENTORY_MANAGEMENT_PATHS } from '../constants/routePaths';

const InventoryManagementRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path={INVENTORY_MANAGEMENT_PATHS.COLLECT_DEPOSIT} element={<InventoryManagementCollectDeposit />} />
        <Route path={INVENTORY_MANAGEMENT_PATHS.MOVE} element={<InventoryManagementMove />} />
        <Route path={INVENTORY_MANAGEMENT_PATHS.CHECK_IN} element={<InventoryManagementCheckIn />} />
        <Route path={INVENTORY_MANAGEMENT_PATHS.CHECK_OUT} element={<InventoryManagementCheckOut />} />
        <Route path={INVENTORY_MANAGEMENT_PATHS.DISCARD + '/:id'} element={<TransactionDiscard />} />
        <Route path={INVENTORY_MANAGEMENT_PATHS.ON_SITE_SELL} element={<InventoryManagementOnSiteSell />} />
        <Route path={INVENTORY_MANAGEMENT_PATHS.CUSTOM_DATA_POINTS} element={<CustomDataPoints />} />
        <Route path={INVENTORY_MANAGEMENT_PATHS.CANISTER_CONTENTS} element={<InventoryManagementCanisterContents />} />
        <Route path={INVENTORY_MANAGEMENT_PATHS.RECONCILE} element={<InventoryManagementReconcile />} />
        <Route path={INVENTORY_MANAGEMENT_PATHS.RECONCILE_ITEM} element={<InventoryManagementReconcileItem />} />
        <Route path={INVENTORY_MANAGEMENT_PATHS.RSG_CLINIC_USE} element={<InventoryManagementClinicUse />} />
        <Route path={INVENTORY_MANAGEMENT_PATHS.THRESHOLD} element={<InventoryManagementThreshold />} />
      </Routes>
    </div>
  );
};

export default InventoryManagementRoutes;
