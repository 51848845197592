import { AxiosResponse } from 'axios';
import { SPECIES_API_ENDPOINTS } from '../constants/apiEndpoints';
import { appContextGet, appContextPost, appContextPut } from './httpCommon/appAxios.service';
import { PagedResponse, Species, StandardParams } from '../types/interfaces';

export const createSpecies = (payload: Species): Promise<AxiosResponse<number, any>> => {
  return appContextPost(SPECIES_API_ENDPOINTS.CREATE, {
    name: payload.name,
    active: payload.active,
  });
};

export const updateSpecies = (speciesId: number, payload: Species): Promise<AxiosResponse<{}, any>> => {
  return appContextPut(SPECIES_API_ENDPOINTS.PUT + speciesId.toString(), payload);
};

export const getSpecies = (params?: StandardParams): Promise<AxiosResponse<Species[] | PagedResponse<Species>, any>> => {
  return appContextGet(SPECIES_API_ENDPOINTS.GET, params);
};
