export enum PaymentMethods {
  CARD = 'RecurringCard',
  ACH = 'ACH',
}

export enum PayNowPaymentMethods {
  EXISTING = 'ExistingMethod',
  NEW = 'NewMethod',
}

export enum PaymentActions {
  ADD_PAYMENT_METHOD = 'AddPaymentInfo',
  UPDATE_PAYMENT_METHOD = 'UpdatePaymentInfo',
  PAY_NOW_METHOD = 'PayNow',
}

export enum AccountTypesEnum {
  CHECKING = 'Checking',
  SAVINGS = 'Savings',
}
