import { useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { Loader } from '../../../components';
import DeactivateProfileWarningModal from './deactivate-profile-modal/DeactivateProfileWarningModal';
import DeactivateProfileConfirmModal from './deactivate-profile-modal/DeactivateProfileConfirmModal';
import { AccountRoles } from '../../../types/enums';
import { AccountUserInfo } from '../../../types/interfaces';
import { getInventoryCount } from '../../../api/inventoryApi';
import { getAnimalOwnershipsByAccountId } from '../../../api/animalOwnersApi';
import { deactivateUserProfileById } from '../../../api/configurationApi';
import { showToast } from '../../../services/toast.service';
import { signOutUser } from '../../../services/auth.service';
import { toastMessages } from '../../../constants/errorMessages';
import './deactivateProfile.scss';

const DeactivateProfile = (): JSX.Element => {
  const userAccounts = useAppSelector(state => state.user.accounts);
  const userId = useAppSelector(state => state.user.userId);
  const [isOpenDeactivateProfileWarningModal, setIsOpenDeactivateProfileWarningModal] = useState<boolean>(false);
  const [isOpenDeactivateProfileConfirmModal, setIsOpenDeactivateProfileConfirmModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDeactivateProfile = async () => {
    if (userAccounts.length !== 0) {
      const ownedAccounts = userAccounts.filter(account =>
        account.roles.some(role => role.name.includes(AccountRoles.AccountOwner)),
      );
      if (ownedAccounts.length != 0 && (await userHaveAnyOwnership(ownedAccounts))) {
        setIsOpenDeactivateProfileWarningModal(true);
      } else {
        setIsOpenDeactivateProfileConfirmModal(true);
      }
    } else {
      setIsOpenDeactivateProfileConfirmModal(true);
    }
  };

  const userHaveAnyOwnership = async (ownedAccounts: AccountUserInfo[]) => {
    setIsLoading(true);
    let accountHasOwnership = false;

    for (let account of ownedAccounts) {
      try {
        const { data: totalInventory } = await getInventoryCount({ accountId: account.accountId });
        const { data: animalOwnership } = await getAnimalOwnershipsByAccountId({ filter: 'accountId eq ' + account.accountId });
        if (totalInventory != 0 || animalOwnership.length != 0) {
          accountHasOwnership = true;
          setIsLoading(false);
          return true;
        }
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        accountHasOwnership = true;
      }
    }
    setIsLoading(false);
    return accountHasOwnership;
  };

  const deactivateProfile = async () => {
    setIsOpenDeactivateProfileConfirmModal(false);
    if (userId) {
      try {
        await deactivateUserProfileById(userId);
        signOutUser();
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader loaderSize={'small'} pageLoader simple />}

      <div className="deactivate-profile">
        <p>Click on the link to deactivate your profile.</p>
        <div className="deactivate-label" onClick={() => handleDeactivateProfile()}>
          Deactivate Profile
        </div>
      </div>

      <DeactivateProfileWarningModal
        isOpen={isOpenDeactivateProfileWarningModal}
        onClose={() => setIsOpenDeactivateProfileWarningModal(false)}
      />
      <DeactivateProfileConfirmModal
        isOpen={isOpenDeactivateProfileConfirmModal}
        onClose={() => setIsOpenDeactivateProfileConfirmModal(false)}
        onConfirm={() => deactivateProfile()}
      />
    </>
  );
};

export default DeactivateProfile;
