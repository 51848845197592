import { ChangeEvent, FC, useState, DragEvent } from 'react';
import { Modal } from '..';
import { UploadImageSVG, TrashCanSVG } from '../svgs';
import { toastMessages } from '../../constants/errorMessages';
import { showToast } from '../../services/toast.service';
import { Attachment } from '../../types/interfaces';
import { getAnimalAttachmentURL } from '../../services/animal.service';
import './uploadImageModal.scss';

type UploadAttachmentsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  postAttachments: (formData: FormData) => Promise<void>;
  handleDeleteAttachments: (attachmentId: number[]) => void;
  existingAttachments: Attachment[];
  animalId: string;
  handleSubmitNavigation: () => void;
};

const UploadAttachmentsModal: FC<UploadAttachmentsModalProps> = ({
  isOpen,
  animalId,
  existingAttachments,
  onClose,
  postAttachments,
  handleDeleteAttachments,
  handleSubmitNavigation,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const [deletedAttachmentIds, setDeletedAttachmentIds] = useState<number[]>([]);

  const resetHandler = () => {
    setSelectedFiles([]);
    setPreviewUrls([]);
    setDeletedAttachmentIds([]);
    onClose();
  };

  const deleteAttachment = async (attachmentId: number) => {
    setDeletedAttachmentIds(prev => [...prev, attachmentId]);
  };

  const deletePreviewUrl = (index: number) => {
    const newPreviewUrls = [...previewUrls];
    newPreviewUrls.splice(index, 1);
    setPreviewUrls(newPreviewUrls);
  };

  const validFileTypes = ['image/jpeg', 'image/png'];

  const validateFiles = (files: File[]) => {
    return files.every(file => {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      const isValidMimeType = validFileTypes.includes(file.type);
      const isNotJFIF = fileExtension !== 'jfif';
      return isValidMimeType && isNotJFIF;
    });
  };

  const updateFilesAndPreviews = (newFiles: File[]) => {
    const newPreviewUrls = newFiles.map(file => URL.createObjectURL(file));
    setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, ...newFiles]);
    setPreviewUrls(prevPreviewUrls => [...prevPreviewUrls, ...newPreviewUrls]);
  };

  const fileChangedHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    const newFiles = Array.from(event.target.files);

    if (!validateFiles(newFiles)) {
      showToast.error(toastMessages.INVALID_FILE_TYPE);
      return;
    }

    const totalAttachments = selectedFiles.length + newFiles.length + existingAttachments.length - deletedAttachmentIds.length;

    if (totalAttachments > 5) {
      showToast.error(toastMessages.UPLOAD_MAX_5_IMAGES);
      return;
    }

    updateFilesAndPreviews(newFiles);
  };

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const newFiles = Array.from(event.dataTransfer.files);

    if (!validateFiles(newFiles)) {
      showToast.error(toastMessages.INVALID_FILE_TYPE);
      return;
    }

    const totalAttachments = selectedFiles.length + newFiles.length + existingAttachments.length - deletedAttachmentIds.length;

    if (totalAttachments > 5) {
      showToast.error(toastMessages.UPLOAD_MAX_5_IMAGES);
      return;
    }

    updateFilesAndPreviews(newFiles);
  };

  const uploadHandler = async () => {
    if (!selectedFiles.length && !deletedAttachmentIds.length) {
      onClose();
      return;
    }

    const maxSize = 3000000;
    const oversizedFiles = selectedFiles.filter(file => file.size > maxSize);
    if (oversizedFiles.length) {
      showToast.error(toastMessages.SOME_IMAGES_LARGER_THAN_3MB);
      return;
    }

    const formData = new FormData();

    selectedFiles.forEach(file => {
      formData.append('attachments', file);
    });
    try {
      if (deletedAttachmentIds.length > 0) {
        await handleDeleteAttachments(deletedAttachmentIds);
      }
      if (selectedFiles.length > 0) {
        await postAttachments(formData);
      }
    } catch (error: any) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      handleSubmitNavigation();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={resetHandler} ignoreBackdrop width={556}>
      <div className="upload-modal-content">
        <h2>Upload Images</h2>
        <br />
        <div
          className="drop-area"
          onDragOver={event => event.preventDefault()}
          onDrop={onDrop}
          style={{ backgroundImage: previewUrls[0] ? `url(${previewUrls[0]})` : 'none' }}>
          {!previewUrls.length && (
            <>
              <UploadImageSVG className="upload-icon" />
              <br />
              <p className="drag-n-drop-text">Drag and Drop files here</p>
              <p className="file-types-text">(png, jpg, jpeg)</p>
            </>
          )}
        </div>
        <div className="mini-preview-url-container">
          {existingAttachments
            .filter(attachment => !deletedAttachmentIds.includes(attachment.attachmentId))
            .map((attachment, index) => (
              <div key={index} className="existing-attachment">
                <img
                  src={getAnimalAttachmentURL(animalId, attachment.attachmentId, attachment.accessToken)}
                  alt="Existing Attachment"
                />
                <button onClick={() => deleteAttachment(attachment.attachmentId)} className="delete-attachment-button">
                  <TrashCanSVG className="trash-can-icon" />
                </button>
              </div>
            ))}
          {previewUrls.map((url, index) => (
            <div key={url} className="mini-preview-url">
              <img src={previewUrls[index] ? previewUrls[index] : ''} alt="Animal Photo" />
              <button onClick={() => deletePreviewUrl(index)} className="delete-attachment-button">
                <TrashCanSVG className="trash-can-icon" />
              </button>
            </div>
          ))}
        </div>
        <br />
        <div className="file-select">
          <button className="red button medium" onClick={resetHandler}>
            Cancel
          </button>
          <label htmlFor="uploadFile" className="button green upload-label">
            Choose Files
          </label>
          <input id="uploadFile" type="file" accept="image/*" onChange={fileChangedHandler} multiple />
          <br />
          <button className="green button medium" onClick={() => uploadHandler()}>
            Update
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UploadAttachmentsModal;
