import React, { FC } from 'react';
import InfoSVG from '../svgs/Info.svg';

type TooltipProps = {
  children: React.ReactNode;
  className?: string;
  size?: string;
};

const Tooltip: FC<TooltipProps> = ({ children, className = '', size = '16' }: TooltipProps) => {
  return (
    <div className={`tooltip ${className}`} tabIndex={0}>
      <InfoSVG size={size} />
      <span className="tooltip-text">{children}</span>
    </div>
  );
};

export default Tooltip;
