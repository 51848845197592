import { useEffect, useState } from 'react';
import ValidatedInput, { ValidatedState } from '../../../components/custom-input/ValidatedInput';
import { toastMessages } from '../../../constants/errorMessages';
import { ANIMAL_CONSTANTS, BUTTON_CONSTANTS, LABEL_CONSTANTS } from '../../../constants/common';
import { showToast } from '../../../services/toast.service';
import { Validators } from '../../../types/enums';
import { Breed, Species } from '../../../types/interfaces';
import { updateBreed } from '../../../api/breedsApi';

type EditBreedModalProps = {
  close: () => void;
  species: Species;
  breed: Breed;
};

const EditBreedModal = ({ close, species, breed }: EditBreedModalProps): JSX.Element => {
  const [nameInput, setNameInput] = useState<ValidatedState>({ valid: true, value: breed.name });
  const [activeInput, setActiveInput] = useState<boolean>(breed.active === true);
  const [validate, setValidate] = useState<boolean>(false);

  const [submit, setSubmit] = useState<boolean>(false);
  useEffect(() => {
    const action = async () => {
      try {
        await updateBreed(breed.breedId!, { name: nameInput.value, active: activeInput });
        showToast.success(toastMessages.BREED_UPDATE_SUCCESS);
      } catch {
        showToast.success(toastMessages.BREED_UPDATE_FAIL);
      } finally {
        setSubmit(false);
        close();
      }
    };
    if (submit) {
      action();
    }
  }, [submit]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setValidate(true);
    if (nameInput.valid) {
      setSubmit(true);
    }
  };
  return (
    <div className="management-columns-modal">
      <form onSubmit={handleSubmit}>
        <h2>{ANIMAL_CONSTANTS.EDIT_BREED}</h2>
        <p>{ANIMAL_CONSTANTS.EDIT_BREED_HEADER}</p>
        <div className="form-row">
          <label>{ANIMAL_CONSTANTS.SPECIES_NAME}:</label>
          <div className="input-container">
            <label>{species.name}</label>
          </div>
        </div>
        <ValidatedInput
          useId="name-input-field"
          type="text"
          label={'Breed Name'}
          validate={validate}
          validatedStateForAutoFill={nameInput}
          setValidatedState={setNameInput}
          validators={[Validators.REQUIRED]}
        />
        <div className="form-row">
          <label htmlFor="active-checkbox">{LABEL_CONSTANTS.ACTIVE}</label>
          <div className="input-container">
            <input
              id="active-checkbox"
              type="checkbox"
              defaultChecked={breed.active}
              onChange={e => setActiveInput(e.target.checked)}
            />
          </div>
        </div>
        <div className="flex-right">
          <button type="button" className="small green inverted button" onClick={close}>
            {BUTTON_CONSTANTS.CANCEL}
          </button>
          &emsp;
          <button type="submit" className={nameInput.valid ? 'small green button' : 'small green button disabled'}>
            {BUTTON_CONSTANTS.UPDATE}
          </button>
        </div>
      </form>
    </div>
  );
};
export default EditBreedModal;
