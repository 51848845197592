import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { store } from '../../redux/store';
import { storeRoles } from '../../redux/reducers/rolesSlice';
import { updateUserAccounts } from '../../redux/reducers/userSlice';
import { clearActiveAccount } from '../../redux/reducers/accountSlice';
import { clearAuthAccountContext } from '../../redux/reducers/authSlice';
import BackButton from '../../components/back-button/BackButton';
import SearchableTable, { HeaderConfig } from '../../components/searchable-table/SearchableTable';
import PlusCircleSvg from '../../components/svgs/PlusCircle.svg';
import UserCard from '../../components/account-card/UserCard';
import ActionMenu from '../../components/action-buttons/ActionButtons';
import AddUserModal from './AddUserModal';
import RemoveUserModal from './RemoveUserModal';
import { showToast } from '../../services/toast.service';
import { setActiveAccount } from '../../services/account.service';
import { requestUserPersonalInfo } from '../../services/user.service';
import {
  addUnknownUserToAccount,
  addUserToAccountByUsername,
  removeUserFromAccount,
  resendAccountInvite,
} from '../../api/accountApi';
import { getRoles } from '../../api/rolesApi';
import { getUserProfileImageById } from '../../api/userApi';
import { TeamManagementUser, UserRole } from '../../types/interfaces/user.interfaces';
import { AccountRole, AccountRoles, getRoleValue, hasGreaterAccess } from '../../types/enums/accountRoles.enum';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { toastMessages } from '../../constants/errorMessages';
import { localStorageConstants } from '../../constants/localStorage.constants';
import { BUTTON_CONSTANTS } from '../../constants/common';

const TeamManagement: FC = () => {
  const defaultSelectedUser: TeamManagementUser = {
    firstName: '',
    lastName: '',
    fullName: '',
    roles: '',
    username: '',
    userId: 0,
    verified: false,
    profileImageId: '',
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isOpenRemoveUser, setIsOpenRemoveUser] = useState<boolean>(false);
  const [isOpenAddUser, setIsOpenAddUser] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<TeamManagementUser>(defaultSelectedUser);
  const [userProfileImages, setUserProfileImages] = useState<{ [key: number]: Blob }>({});

  const { users } = useAppSelector(state => state.activeAccount);
  const { accountId } = useAppSelector(state => state.activeAccount);
  const accountRole = useAppSelector(state => state.auth.accountContext.roles);
  const { userId } = useAppSelector(state => state.user);
  const paymentStatus = useAppSelector(state => state.payment.addedPaymentMethod);
  const userAccounts = useAppSelector(state => state.user.accounts);
  const activeAccount = useAppSelector(state => state.activeAccount);
  const accountLocked = useAppSelector(state => state.activeAccount.locked);

  useEffect(() => {
    const getAccountRoles = () => {
      getRoles()
        .then(res => {
          dispatch(storeRoles(res.data));
        })
        .catch(e => {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        });
    };

    getAccountRoles();
  }, [dispatch]);

  const flattenRoles = (roles: UserRole[]) => {
    return roles.map(role => getRoleValue(role.name)).join(', ');
  };

  const sortedUsers = useMemo(() => {
    const sortUsers = (userArray: TeamManagementUser[]) => {
      const index = userArray.findIndex((u: TeamManagementUser) => u.roles.includes(AccountRole.AccountOwner));
      const accountOwner = userArray.splice(index, 1);
      userArray.unshift(...accountOwner);
      return userArray;
    };

    return sortUsers(
      users
        .map(u => {
          return {
            userId: u.userId,
            firstName: u.user.firstName,
            lastName: u.user.lastName,
            fullName: u.user.firstName + ' ' + u.user.lastName,
            roles: flattenRoles(u.roles),
            username: u.user.username,
            verified: u.user.verified,
            profileImageId: u.user.profileImageId,
          };
        })
        .sort((a: TeamManagementUser, b: TeamManagementUser) => {
          return a.firstName.toUpperCase() > b.firstName.toUpperCase() ? 1 : -1;
        }),
    );
  }, [users]);

  useEffect(() => {
    const loadUserImages = async () => {
      const results = await Promise.all(
        sortedUsers.map(user => getUserProfileImageById(user.userId, user.profileImageId).catch(() => new Blob())),
      );

      const userImages: { [key: number]: Blob } = {};
      sortedUsers.forEach((user, index) => (userImages[user.userId] = results[index]));

      setUserProfileImages(userImages);
    };

    loadUserImages();
  }, [sortedUsers]);

  const onSuccessfulAdd = () => {
    setActiveAccount(Number(accountId));
  };

  const handleEdit = (user: TeamManagementUser) => {
    navigate(ROUTE_PATHS.APP_TEAM_MANAGEMENT_EDIT + user.userId, { state: user });
  };

  const handleResend = (user: TeamManagementUser) => {
    resendAccountInvite(Number(accountId), user.userId)
      .then(() => {
        showToast.success(toastMessages.INVITE_RESENT);
      })
      .catch(() => {
        showToast.error(toastMessages.FAILED_TO_RESEND_INVITE);
      });
  };

  const selectUserForRemove = (user: TeamManagementUser) => {
    setSelectedUser(user);
    setIsOpenRemoveUser(true);
  };

  const handleRemove = async () => {
    try {
      await removeUserFromAccount(Number(accountId), selectedUser.userId);
      showToast.success(toastMessages.REMOVE_TEAM_MEMBER_SUCCESS);

      if (userId != selectedUser.userId) {
        setActiveAccount(Number(accountId));
      } else {
        handleSwitchAccount();
      }
    } catch (error) {
      showToast.error(toastMessages.REMOVE_TEAM_MEMBER_FAIL);
    } finally {
      setIsOpenRemoveUser(false);
      setSelectedUser(defaultSelectedUser);
    }
  };

  const handleSwitchAccount = async () => {
    const accounts = userAccounts.filter(item => item.accountId !== accountId);

    if (accounts.length > 0) {
      await setActiveAccount(accounts[0].accountId);
      await requestUserPersonalInfo();
      navigate(ROUTE_PATHS.APP_DASHBOARD);
    } else {
      sessionStorage.removeItem(localStorageConstants.ACTIVE_ACCOUNT_ID);
      store.dispatch(updateUserAccounts({ accounts }));
      store.dispatch(clearActiveAccount());
      store.dispatch(clearAuthAccountContext());
      navigate(ROUTE_PATHS.APP_USER_PROFILE);
    }
  };

  const checkValidity = () => {
    return (
      (paymentStatus != undefined &&
        (!paymentStatus ||
          activeAccount.billingAmount.outstandingBalanceCents > 0 ||
          activeAccount.billingAmount.penaltyCents > 0)) ||
      accountLocked
    );
  };

  const nameCell = (user: TeamManagementUser) => {
    return <UserCard user={user} profileImage={userProfileImages[user.userId]} />;
  };

  const roleCell = (user: TeamManagementUser) => {
    return <div className="role-container">{user.roles}</div>;
  };

  const editCell = (user: TeamManagementUser) => {
    const displayManageButtons = hasGreaterAccess(user.roles, getRoleValue(accountRole)) || user.userId === userId;

    return (
      <>
        <div className="desk-buttons-container">
          <div className="buttons-container">
            {displayManageButtons && !user.roles.includes(AccountRole.AccountOwner) && (
              <>
                {user.verified ? (
                  <button onClick={() => handleEdit(user)} className="button blue" disabled={checkValidity()}>
                    {BUTTON_CONSTANTS.EDIT}
                  </button>
                ) : (
                  <button onClick={() => handleResend(user)} className="button blue" disabled={checkValidity()}>
                    {BUTTON_CONSTANTS.RESEND}
                  </button>
                )}
                <button onClick={() => selectUserForRemove(user)} className="button red inverted" disabled={checkValidity()}>
                  {BUTTON_CONSTANTS.REMOVE}
                </button>
              </>
            )}
          </div>
        </div>
        <div className="xs-buttons-container">
          {displayManageButtons &&
            !user.roles.includes(AccountRole.AccountOwner) &&
            paymentStatus != undefined &&
            paymentStatus &&
            !(activeAccount.billingAmount.outstandingBalanceCents > 0 || activeAccount.billingAmount.penaltyCents > 0) &&
            !accountLocked && (
              <>
                {user.verified ? (
                  <ActionMenu
                    actionButtons={[
                      { name: 'Edit', action: () => handleEdit(user) },
                      { name: 'Remove', action: () => selectUserForRemove(user) },
                    ]}
                  />
                ) : (
                  <ActionMenu
                    actionButtons={[
                      { name: 'Resend', action: () => handleResend(user) },
                      { name: 'Remove', action: () => selectUserForRemove(user) },
                    ]}
                  />
                )}
              </>
            )}
        </div>
      </>
    );
  };

  const headers: HeaderConfig[] = [
    {
      propertyName: 'fullName',
      displayName: 'Name',
      searchable: true,
      cell: nameCell,
    },
    {
      propertyName: 'username',
      searchable: true,
    },
    {
      propertyName: 'roles',
      displayName: 'Role',
      cell: roleCell,
    },
    {
      propertyName: 'edit',
      cell: editCell,
    },
  ];

  return (
    <div className="manage-team">
      <div className="header">
        <BackButton />
        <button type="button" className="button outlined" onClick={() => setIsOpenAddUser(true)} disabled={checkValidity()}>
          <PlusCircleSvg />
          &nbsp;&nbsp;{BUTTON_CONSTANTS.ADD_USER}&nbsp;
        </button>
      </div>

      <SearchableTable headers={headers} data={sortedUsers} />
      {isOpenAddUser && (
        <AddUserModal
          isOpen={isOpenAddUser}
          onClose={() => setIsOpenAddUser(false)}
          AddExistingUser={(username, roles, accountId) => addUserToAccountByUsername(username, roles as AccountRoles, accountId)}
          AddNonExistingUser={addUnknownUserToAccount}
          onSuccessfulAdd={onSuccessfulAdd}
        />
      )}

      {isOpenRemoveUser && (
        <RemoveUserModal
          isOpen={isOpenRemoveUser}
          onClose={() => setIsOpenRemoveUser(false)}
          handleRemove={handleRemove}
          selectedUser={selectedUser}
        />
      )}
    </div>
  );
};

export default TeamManagement;
