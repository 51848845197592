import { OwnerItemDistribution } from '../components/specimen-ownership-breakdown/SpecimenOwnershipBreakdown';
import { CollectDepositSelectedStates } from '../pages/inventory-management/InventoryManagementCollectDeposit';
import { collectPost, depositPut } from '../api/inventoryApi';
import { TransactionTypeEnum } from '../types/enums';
import { StorageLocation } from '../types/interfaces';

export const isCollectDepositValid = (selectedStates: CollectDepositSelectedStates, transactionType: TransactionTypeEnum) => {
  const {
    animal,
    specimensType,
    specimenUOM,
    quantity,
    freezeDate,
    ownerItemDistribution,
    depositTransaction,
    account,
    storageLocations,
    specimenCustomDataValues,
  } = selectedStates;
  if (!transactionType || !animal || !specimensType?.value || !specimenUOM?.value || !quantity?.value || !freezeDate) {
    return false;
  }

  let specimenCustomDataValuesValid = true;
  specimenCustomDataValues?.forEach(specimenCustomData => {
    if (
      specimenCustomData.specimenCustomDataKey?.active &&
      specimenCustomData.specimenCustomDataKey?.required &&
      !specimenCustomData.value?.trim()
    ) {
      specimenCustomDataValuesValid = false;
    }
  });
  if (!specimenCustomDataValuesValid) {
    return false;
  }

  if (transactionType === TransactionTypeEnum.Collect) {
    let total = ownerItemDistribution?.reduce((sum, item) => sum + item.ownedQuantity, 0);
    if (total !== Number(quantity?.value)) {
      return false;
    }
  } else if (transactionType === TransactionTypeEnum.Deposit) {
    if (!depositTransaction?.value || !account) {
      return false;
    }
  }

  const totalStorageLocationQuantities = selectedStates?.storageLocations?.reduce((a, b) => a + Number(b.quantity.value), 0);
  if (totalStorageLocationQuantities !== Number(quantity?.value)) {
    return false;
  }

  let storageLocationsValid = true;
  storageLocations?.forEach(storageLocation => {
    if (
      !storageLocation?.site.value ||
      !storageLocation?.quantity.value ||
      !storageLocation?.tank.value ||
      !storageLocation?.canister.value
    ) {
      storageLocationsValid = false;
    }
  });
  if (!storageLocationsValid) {
    return false;
  }

  return true;
};

export const createCollectTransaction = async (selectedStates: CollectDepositSelectedStates) => {
  try {
    if (isCollectDepositValid(selectedStates, TransactionTypeEnum.Collect)) {
      await collectPost({
        specimenCustomDataValues: selectedStates.specimenCustomDataValues,
        specimenUOM: selectedStates.specimenUOM?.value!,
        quality: selectedStates.qualityType?.value,
        qualityPercentage: selectedStates.qualityPercentage,
        originalQuantity: Number(selectedStates.quantity?.value!),
        freezeDate: selectedStates.freezeDate!,
        collectedBy: selectedStates.collectedBy?.value.userId,
        processedBy: selectedStates.processedBy?.value.userId,
        frozenBy: selectedStates.frozenBy?.value.userId,
        frozenAtSiteId: selectedStates.freezeLocation?.value.storageSiteId,
        animalId: selectedStates.animal?.animalId!,
        specimenTypeId: selectedStates.specimensType?.value.specimenTypeId!,
        specimenLocations: selectedStates.storageLocations?.map((sl: StorageLocation) => ({
          quantity: Number(sl.quantity.value!),
          storageCanisterId: Number(sl.canister.value),
        }))!,
        notes: selectedStates.notes,
        specimenOwnership: selectedStates.ownerItemDistribution?.map((oid: OwnerItemDistribution) => ({
          accountId: oid.owner.accountId!,
          quantity: oid.ownedQuantity,
        }))!,
      });
    } else {
      throw new Error('Invalid Collect Transaction');
    }
  } catch (error) {
    throw error;
  }
};

export const updateDepositTransaction = async (selectedStates: CollectDepositSelectedStates) => {
  try {
    if (selectedStates.depositTransaction?.value && isCollectDepositValid(selectedStates, TransactionTypeEnum.Deposit)) {
      await depositPut(
        {
          specimenCustomDataValues: selectedStates.specimenCustomDataValues,
          specimenUOM: selectedStates.specimenUOM?.value!,
          quality: selectedStates.qualityType?.value,
          qualityPercentage: selectedStates.qualityPercentage,
          originalQuantity: Number(selectedStates.quantity?.value!),
          freezeDate: selectedStates.freezeDate!,
          collectedBy: selectedStates.collectedBy?.value.userId,
          processedBy: selectedStates.processedBy?.value.userId,
          frozenBy: selectedStates.frozenBy?.value.userId,
          frozenAtSiteId: selectedStates.freezeLocation?.value.storageSiteId,
          animalId: selectedStates.animal?.animalId!,
          specimenTypeId: selectedStates.specimensType?.value.specimenTypeId!,
          specimenLocations: selectedStates.storageLocations?.map((sl: StorageLocation) => ({
            quantity: Number(sl.quantity.value!),
            storageCanisterId: Number(sl.canister.value),
          }))!,
          notes: selectedStates.notes,
        },
        selectedStates.depositTransaction?.value?.inventoryTransactionId!,
      );
    } else {
      throw new Error('No deposit transaction found');
    }
  } catch (error) {
    throw error;
  }
};
