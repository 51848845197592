import { FC } from 'react';
import { Link } from 'react-router-dom';
import { DropDownSVG, InventorySVG } from '../../../svgs';
import { redirectToTopOfThePage } from '../../../../utils/commonUtils';
import { APP_PATHS, INVENTORY_MANAGEMENT_PATHS } from '../../../../constants/routePaths';
import { NAVIGATION_LINK_CONSTANTS } from '../../../../constants/common';

type InventoryManagementLinksProps = {
  currentRoute: string[];
  showInventoryManagementPages: boolean;
  setShowInventoryManagementPages: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenNavBar: React.Dispatch<React.SetStateAction<boolean>>;
};

const InventoryManagementLinks: FC<InventoryManagementLinksProps> = ({
  currentRoute,
  showInventoryManagementPages,
  setShowInventoryManagementPages,
  setOpen,
  setOpenNavBar,
}) => {
  const activeLinkClass = (path: string) => {
    return currentRoute.includes(path) ? 'nav-link active' : 'nav-link';
  };

  const activeSubLinkClass = (path: string) => {
    return currentRoute.includes(path) ? 'nav-sub-link active' : 'nav-sub-link';
  };

  const closeSideBar = () => {
    setOpen(false);
    setOpenNavBar(false);
    redirectToTopOfThePage();
  };

  return (
    <div>
      <button
        type="button"
        className={activeLinkClass(APP_PATHS.INVENTORY_MANAGEMENT)}
        onClick={() => setShowInventoryManagementPages(!showInventoryManagementPages)}>
        <InventorySVG />
        {NAVIGATION_LINK_CONSTANTS.INVENTORY_MANAGEMENT}
        <DropDownSVG reversed={showInventoryManagementPages} />
      </button>
      {showInventoryManagementPages && (
        <div>
          <Link
            className={activeSubLinkClass(INVENTORY_MANAGEMENT_PATHS.COLLECT_DEPOSIT)}
            to={APP_PATHS.INVENTORY_MANAGEMENT_ + INVENTORY_MANAGEMENT_PATHS.COLLECT_DEPOSIT}
            onClick={closeSideBar}>
            {NAVIGATION_LINK_CONSTANTS.COLLECT_DEPOSIT}
          </Link>
          <Link
            className={activeSubLinkClass(INVENTORY_MANAGEMENT_PATHS.MOVE)}
            to={APP_PATHS.INVENTORY_MANAGEMENT_ + INVENTORY_MANAGEMENT_PATHS.MOVE}
            onClick={closeSideBar}>
            {NAVIGATION_LINK_CONSTANTS.MOVE}
          </Link>
          <Link
            className={activeSubLinkClass(INVENTORY_MANAGEMENT_PATHS.CHECK_IN)}
            to={APP_PATHS.INVENTORY_MANAGEMENT_ + INVENTORY_MANAGEMENT_PATHS.CHECK_IN}
            onClick={closeSideBar}>
            {NAVIGATION_LINK_CONSTANTS.CHECK_IN}
          </Link>
          <Link
            className={activeSubLinkClass(INVENTORY_MANAGEMENT_PATHS.CHECK_OUT)}
            to={APP_PATHS.INVENTORY_MANAGEMENT_ + INVENTORY_MANAGEMENT_PATHS.CHECK_OUT}
            onClick={closeSideBar}>
            {NAVIGATION_LINK_CONSTANTS.CHECK_OUT}
          </Link>
          <Link
            className={activeSubLinkClass(INVENTORY_MANAGEMENT_PATHS.ON_SITE_SELL)}
            to={APP_PATHS.INVENTORY_MANAGEMENT_ + INVENTORY_MANAGEMENT_PATHS.ON_SITE_SELL}
            onClick={closeSideBar}>
            {NAVIGATION_LINK_CONSTANTS.ON_SITE_SELL}
          </Link>
          <Link
            className={activeSubLinkClass(INVENTORY_MANAGEMENT_PATHS.RSG_CLINIC_USE)}
            to={APP_PATHS.INVENTORY_MANAGEMENT_ + INVENTORY_MANAGEMENT_PATHS.RSG_CLINIC_USE}
            onClick={closeSideBar}>
            {NAVIGATION_LINK_CONSTANTS.RSG_CLINIC_USE}
          </Link>
          <Link
            className={activeSubLinkClass(INVENTORY_MANAGEMENT_PATHS.CUSTOM_DATA_POINTS)}
            to={APP_PATHS.INVENTORY_MANAGEMENT_ + INVENTORY_MANAGEMENT_PATHS.CUSTOM_DATA_POINTS}
            onClick={closeSideBar}>
            {NAVIGATION_LINK_CONSTANTS.CUSTOM_DATA_POINTS}
          </Link>
          <Link
            className={activeSubLinkClass(INVENTORY_MANAGEMENT_PATHS.CANISTER_CONTENTS)}
            to={APP_PATHS.INVENTORY_MANAGEMENT_ + INVENTORY_MANAGEMENT_PATHS.CANISTER_CONTENTS}
            onClick={closeSideBar}>
            {NAVIGATION_LINK_CONSTANTS.CANISTER_CONTENTS}
          </Link>
          <Link
            className={activeSubLinkClass(INVENTORY_MANAGEMENT_PATHS.RECONCILE)}
            to={APP_PATHS.INVENTORY_MANAGEMENT_ + INVENTORY_MANAGEMENT_PATHS.RECONCILE}
            onClick={closeSideBar}>
            {NAVIGATION_LINK_CONSTANTS.RECONCILE}
          </Link>
          <Link
            className={activeSubLinkClass(INVENTORY_MANAGEMENT_PATHS.THRESHOLD)}
            to={APP_PATHS.INVENTORY_MANAGEMENT_ + INVENTORY_MANAGEMENT_PATHS.THRESHOLD}
            onClick={closeSideBar}>
            {NAVIGATION_LINK_CONSTANTS.THRESHOLD}
          </Link>
        </div>
      )}
    </div>
  );
};

export default InventoryManagementLinks;
