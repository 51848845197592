import { createSlice } from '@reduxjs/toolkit';
import { CartItem } from '../../types/interfaces/transactionCart.interfaces';

interface transactionCart {
  transactionCartItems: CartItem[];
}

const initialState = {
  transactionCartItems: [],
} as transactionCart;

export const transactionCartSlice = createSlice({
  name: 'transactionCart',
  initialState,
  reducers: {
    storeCartItems: (state, action) => {
      state.transactionCartItems = action.payload;
    },
  },
});

export const { storeCartItems } = transactionCartSlice.actions;

export default transactionCartSlice.reducer;
