const TrashSvg = () => {
  return (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.75 23.25C3.0625 23.25 2.47417 23.0054 1.985 22.5163C1.495 22.0263 1.25 21.4375 1.25 20.75V4.5C0.895833 4.5 0.59875 4.38042 0.35875 4.14125C0.119584 3.90125 0 3.60417 0 3.25C0 2.89583 0.119584 2.59875 0.35875 2.35875C0.59875 2.11958 0.895833 2 1.25 2H6.25C6.25 1.64583 6.37 1.34875 6.61 1.10875C6.84917 0.869583 7.14583 0.75 7.5 0.75H12.5C12.8542 0.75 13.1513 0.869583 13.3913 1.10875C13.6304 1.34875 13.75 1.64583 13.75 2H18.75C19.1042 2 19.4008 2.11958 19.64 2.35875C19.88 2.59875 20 2.89583 20 3.25C20 3.60417 19.88 3.90125 19.64 4.14125C19.4008 4.38042 19.1042 4.5 18.75 4.5V20.75C18.75 21.4375 18.5054 22.0263 18.0163 22.5163C17.5263 23.0054 16.9375 23.25 16.25 23.25H3.75ZM6.25 17C6.25 17.3542 6.37 17.6508 6.61 17.89C6.84917 18.13 7.14583 18.25 7.5 18.25C7.85417 18.25 8.15125 18.13 8.39125 17.89C8.63042 17.6508 8.75 17.3542 8.75 17V8.25C8.75 7.89583 8.63042 7.59875 8.39125 7.35875C8.15125 7.11958 7.85417 7 7.5 7C7.14583 7 6.84917 7.11958 6.61 7.35875C6.37 7.59875 6.25 7.89583 6.25 8.25V17ZM11.25 17C11.25 17.3542 11.37 17.6508 11.61 17.89C11.8492 18.13 12.1458 18.25 12.5 18.25C12.8542 18.25 13.1513 18.13 13.3913 17.89C13.6304 17.6508 13.75 17.3542 13.75 17V8.25C13.75 7.89583 13.6304 7.59875 13.3913 7.35875C13.1513 7.11958 12.8542 7 12.5 7C12.1458 7 11.8492 7.11958 11.61 7.35875C11.37 7.59875 11.25 7.89583 11.25 8.25V17Z"
        fill="#B0131D"
      />
    </svg>
  );
};

export default TrashSvg;
