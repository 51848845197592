const AlertSVG = (): JSX.Element => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.0013 13.166C9.23741 13.166 9.43547 13.086 9.59547 12.926C9.75491 12.7666 9.83464 12.5688 9.83464 12.3327C9.83464 12.0966 9.75491 11.8985 9.59547 11.7385C9.43547 11.5791 9.23741 11.4993 9.0013 11.4993C8.76519 11.4993 8.56741 11.5791 8.40797 11.7385C8.24797 11.8985 8.16797 12.0966 8.16797 12.3327C8.16797 12.5688 8.24797 12.7666 8.40797 12.926C8.56741 13.086 8.76519 13.166 9.0013 13.166ZM9.0013 9.83268C9.23741 9.83268 9.43547 9.75268 9.59547 9.59268C9.75491 9.43324 9.83464 9.23546 9.83464 8.99935V5.66602C9.83464 5.4299 9.75491 5.23185 9.59547 5.07185C9.43547 4.91241 9.23741 4.83268 9.0013 4.83268C8.76519 4.83268 8.56741 4.91241 8.40797 5.07185C8.24797 5.23185 8.16797 5.4299 8.16797 5.66602V8.99935C8.16797 9.23546 8.24797 9.43324 8.40797 9.59268C8.56741 9.75268 8.76519 9.83268 9.0013 9.83268ZM9.0013 17.3327C7.84852 17.3327 6.76519 17.1138 5.7513 16.676C4.73741 16.2388 3.85547 15.6452 3.10547 14.8952C2.35547 14.1452 1.76186 13.2632 1.32464 12.2493C0.886858 11.2355 0.667969 10.1521 0.667969 8.99935C0.667969 7.84657 0.886858 6.76324 1.32464 5.74935C1.76186 4.73546 2.35547 3.85352 3.10547 3.10352C3.85547 2.35352 4.73741 1.75963 5.7513 1.32185C6.76519 0.884627 7.84852 0.666016 9.0013 0.666016C10.1541 0.666016 11.2374 0.884627 12.2513 1.32185C13.2652 1.75963 14.1471 2.35352 14.8971 3.10352C15.6471 3.85352 16.2407 4.73546 16.678 5.74935C17.1157 6.76324 17.3346 7.84657 17.3346 8.99935C17.3346 10.1521 17.1157 11.2355 16.678 12.2493C16.2407 13.2632 15.6471 14.1452 14.8971 14.8952C14.1471 15.6452 13.2652 16.2388 12.2513 16.676C11.2374 17.1138 10.1541 17.3327 9.0013 17.3327ZM9.0013 15.666C10.8624 15.666 12.4388 15.0202 13.7305 13.7285C15.0221 12.4368 15.668 10.8605 15.668 8.99935C15.668 7.13824 15.0221 5.56185 13.7305 4.27018C12.4388 2.97852 10.8624 2.33268 9.0013 2.33268C7.14019 2.33268 5.5638 2.97852 4.27214 4.27018C2.98047 5.56185 2.33464 7.13824 2.33464 8.99935C2.33464 10.8605 2.98047 12.4368 4.27214 13.7285C5.5638 15.0202 7.14019 15.666 9.0013 15.666Z"
        fill="#B0131D"
      />
    </svg>
  );
};

export default AlertSVG;
