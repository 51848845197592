import { FC } from 'react';
import { REACT_APP_EXPERIMENTAL_MODE } from '../../constants/apiEndpoints';
import './experimentalMode.scss';

interface ExperimentalModeProps {
  children: React.ReactNode | React.ReactNode[];
}

const ExperimentalMode: FC<ExperimentalModeProps> = ({ children }) => {
  return REACT_APP_EXPERIMENTAL_MODE ? <div className="experimental-mode">{children}</div> : <></>;
};

export default ExperimentalMode;
