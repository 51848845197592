import { AxiosResponse } from 'axios';
import { ANIMAL_OWNERS_API_ENDPOINTS } from '../constants/apiEndpoints';
import { appContextGet } from './httpCommon/appAxios.service';
import { Animal, AnimalOwner } from '../types/interfaces/animal.interfaces';
import { PagedResponse, StandardParams } from '../types/interfaces/apiParams.interfaces';

export const getAnimalOwnersById = (
  id: number,
  params?: { include: string },
): Promise<AxiosResponse<Animal[] | PagedResponse<Animal>, any>> => {
  return appContextGet(ANIMAL_OWNERS_API_ENDPOINTS.GET_ + id, params);
};

export const getAnimalOwnership = (params?: {}): Promise<AxiosResponse<AnimalOwner[] | PagedResponse<AnimalOwner[]>, any>> => {
  return appContextGet(ANIMAL_OWNERS_API_ENDPOINTS.GET_, params);
};

export const getAnimalOwnershipsByAccountId = (params?: StandardParams): Promise<AxiosResponse<AnimalOwner[], any>> => {
  return appContextGet(ANIMAL_OWNERS_API_ENDPOINTS.GET, params);
};
