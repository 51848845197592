import { accountAxios, appAxios } from './axiosInstances';
import { AxiosRequestConfig } from 'axios';
import { handleUnauthorizedUser } from '../../services/auth.service';

export const appContextGet = async (api: string, params?: {}, config?: {}) => {
  const axiosConfig = { ...config, params: params };

  try {
    return await appAxios.get(api, axiosConfig);
  } catch (err: any) {
    if (err.response.status === 401) {
      try {
        await handleUnauthorizedUser();
        return await appAxios.get(api, axiosConfig);
      } catch {
        throw err;
      }
    }
    throw err;
  }
};

export const appContextPost = async (api: string, data: any, params?: {}, config?: {}) => {
  const axiosConfig: AxiosRequestConfig = { ...config, params: params };

  if (data instanceof FormData) {
    axiosConfig.headers = { 'Content-Type': 'multipart/form-data' };
  }

  try {
    return await appAxios.post(api, data, axiosConfig);
  } catch (err: any) {
    if (err.response.status === 401) {
      try {
        await handleUnauthorizedUser();
        return await appAxios.post(api, data, axiosConfig);
      } catch {
        throw err;
      }
    }
    throw err;
  }
};

export const appContextPut = async (api: string, data: any, params?: {}) => {
  try {
    return await appAxios.put(api, data, { params: params });
  } catch (err: any) {
    if (err.response.status === 401) {
      try {
        await handleUnauthorizedUser();
        return await appAxios.put(api, data, { params: params });
      } catch {
        throw err;
      }
    }
    throw err;
  }
};

export const appContextDelete = async (api: string, params?: {}, data?: {}) => {
  const axiosConfig = { data: data, params: params };
  try {
    return await appAxios.delete(api, axiosConfig);
  } catch (err: any) {
    if (err.response.status === 401) {
      try {
        await handleUnauthorizedUser();
        return await appAxios.delete(api, axiosConfig);
      } catch {
        throw err;
      }
    }
    throw err;
  }
};

export const accountContextGet = async (api: string, params?: {}, config?: {}) => {
  const axiosConfig = { ...config, params: params };

  try {
    return await accountAxios.get(api, axiosConfig);
  } catch (err: any) {
    if (err.response.status === 401) {
      try {
        await handleUnauthorizedUser();
        return await accountAxios.get(api, axiosConfig);
      } catch {
        throw err;
      }
    }
    throw err;
  }
};
