import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { store } from '../../redux/store';
import { updateUserAccounts } from '../../redux/reducers/userSlice';
import { clearActiveAccount } from '../../redux/reducers/accountSlice';
import { clearAuthAccountContext } from '../../redux/reducers/authSlice';
import DeactivateAccountModal from './DeactivateAccountModal';
import ValidatedInput, { ValidatedState } from '../custom-input/ValidatedInput';
import ArrowSvg from '../svgs/ArrowSvg.svg';
import { showToast } from '../../services/toast.service';
import { setActiveAccount } from '../../services/account.service';
import { requestUserPersonalInfo } from '../../services/user.service';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { localStorageConstants } from '../../constants/localStorage.constants';
import { ACCOUNT_CONSTANTS, LABEL_CONSTANTS } from '../../constants/common';
import { ActiveAccount, AnimalOwner } from '../../types/interfaces';
import { AccountRole, AccountRoles, Validators, getRoleValue } from '../../types/enums';
import { getInventoryCount } from '../../api/inventoryApi';
import { getAnimalOwnershipsByAccountId } from '../../api/animalOwnersApi';
import { deactivateAccountById } from '../../api/accountApi';
import Loader from '../loader/Loader';
import './accountInformation.scss';

interface AccountInformationProps {
  account?: ActiveAccount;
  validate: boolean;
  farmName: ValidatedState;
  firstName: ValidatedState;
  lastName: ValidatedState;
  contactEmail: ValidatedState;
  contactPhone: ValidatedState;
  setFarmName: Dispatch<SetStateAction<ValidatedState>>;
  setFirstName: Dispatch<SetStateAction<ValidatedState>>;
  setLastName: Dispatch<SetStateAction<ValidatedState>>;
  setContactEmail: Dispatch<SetStateAction<ValidatedState>>;
  setContactPhone: Dispatch<SetStateAction<ValidatedState>>;
}

const AccountInformation = ({
  account,
  validate,
  farmName,
  firstName,
  lastName,
  contactEmail,
  contactPhone,
  setFarmName,
  setFirstName,
  setLastName,
  setContactEmail,
  setContactPhone,
}: AccountInformationProps) => {
  const accountRoles = useAppSelector(state => state.auth.accountContext.roles) as AccountRoles[];
  const activeAccountId = useAppSelector(state => state.activeAccount.accountId);
  const accountLocked = useAppSelector(state => state.activeAccount.locked);
  const userAccounts = useAppSelector(state => state.user.accounts);
  const navigate = useNavigate();

  const [totalInventory, setTotalInventory] = useState<number>(-1);
  const [totalAnimalOwnerships, setTotalAnimalOwnerships] = useState<AnimalOwner[]>([]);
  const [isOpenDeactivateAccountModal, setIsOpenDeactivateAccountModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const handleLoading = async () => {
      try {
        if (activeAccountId) {
          Promise.all([getTotalInventory(), getTotalAnimalOwnershipsForAccount()]);
        }
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setIsLoading(false);
      }
    };
    handleLoading();
  }, [activeAccountId]);

  const getTotalInventory = async () => {
    if (isUserAccountOwner()) {
      try {
        const { data: total } = await getInventoryCount({ accountId: activeAccountId });
        setTotalInventory(total);
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  };

  const getTotalAnimalOwnershipsForAccount = async () => {
    if (isUserAccountOwner()) {
      try {
        const { data: animalOwnership } = await getAnimalOwnershipsByAccountId({ filter: 'accountId eq ' + activeAccountId });
        setTotalAnimalOwnerships(animalOwnership);
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  };

  const isUserAccountOwner = () => {
    return getRoleValue(accountRoles) === AccountRole.AccountOwner;
  };

  const canUserDeactivateAccount = () => {
    return (
      totalInventory === 0 &&
      totalAnimalOwnerships.length === 0 &&
      account?.outstandingBalanceCents != undefined &&
      account.outstandingBalanceCents === 0 &&
      !accountLocked
    );
  };

  const deactivateAccount = async () => {
    setIsOpenDeactivateAccountModal(false);

    if (activeAccountId) {
      try {
        await deactivateAccountById(activeAccountId);
        handleSwitchAccount();
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  };

  const handleSwitchAccount = async () => {
    const accounts = userAccounts.filter(item => item.accountId !== activeAccountId);

    if (accounts.length > 0) {
      await setActiveAccount(accounts[0].accountId);
      await requestUserPersonalInfo();
      navigate(ROUTE_PATHS.APP_DASHBOARD);
    } else {
      sessionStorage.removeItem(localStorageConstants.ACTIVE_ACCOUNT_ID);
      store.dispatch(updateUserAccounts({ accounts }));
      store.dispatch(clearActiveAccount());
      store.dispatch(clearAuthAccountContext());
      navigate(ROUTE_PATHS.APP_USER_PROFILE);
    }

    showToast.success(toastMessages.DEACTIVATE_ACCOUNT_SUCCESS);
  };

  return isLoading ? (
    <Loader loaderSize={'small'} simple />
  ) : (
    <>
      <div className="account-information">
        <div className="header">
          <h3>{ACCOUNT_CONSTANTS.ACCOUNT_INFO}</h3>
        </div>
        <div className="columns">
          <div className="column">
            <div className="row">{LABEL_CONSTANTS.ACCOUNT_ID}:</div>
            <div className="row">{LABEL_CONSTANTS.ACCOUNT_FARM_NAME}:</div>
            <div className="row">{LABEL_CONSTANTS.CONTACT_FIRST_NAME}:</div>
            <div className="row">{LABEL_CONSTANTS.CONTACT_LAST_NAME}:</div>
            <div className="row">{LABEL_CONSTANTS.EMAIL}:</div>
            <div className="row">{LABEL_CONSTANTS.PHONE_NO}:</div>
          </div>
          <form className="column">
            <div className="row">
              <input type="text" placeholder={`${account?.accountId || 'Error: ID Not Found'}`} disabled />
            </div>
            <div className="row">
              <ValidatedInput
                validatedStateForAutoFill={farmName}
                label=""
                useId="farmName"
                type="text"
                setValidatedState={event => {
                  setFarmName({
                    value: event.value.replace(/\s+/g, ' '),
                    valid: event.valid && event.value.trim().length > 0,
                  });
                }}
                validators={[Validators.REQUIRED]}
                validate={validate}
                placeholder={account?.name || 'Farm Name'}
              />
            </div>
            <div className="row">
              <ValidatedInput
                validatedStateForAutoFill={firstName}
                label=""
                useId="firstName"
                type="text"
                setValidatedState={event => {
                  setFirstName({
                    value: event.value.replace(/\s+/g, ' '),
                    valid: event.valid && event.value.trim().length > 0,
                  });
                }}
                validators={[Validators.REQUIRED]}
                validate={validate}
                placeholder={account?.contactFirstName || 'First Name'}
              />
            </div>
            <div className="row">
              <ValidatedInput
                validatedStateForAutoFill={lastName}
                label=""
                useId="lastName"
                type="text"
                setValidatedState={event => {
                  setLastName({
                    value: event.value.replace(/\s+/g, ' '),
                    valid: event.valid && event.value.trim().length > 0,
                  });
                }}
                validators={[Validators.REQUIRED]}
                validate={validate}
                placeholder={account?.contactLastName || 'Last Name'}
              />
            </div>
            <div className="row">
              <ValidatedInput
                validatedStateForAutoFill={contactEmail}
                label=""
                useId="email"
                type="email"
                setValidatedState={event => {
                  setContactEmail({
                    value: event.value,
                    valid: event.valid && event.value.trim().length > 0,
                  });
                }}
                validators={[Validators.REQUIRED, Validators.EMAIL]}
                validate={validate}
                placeholder={account?.contactEmail || 'Email'}
              />
            </div>
            <div className="row">
              <ValidatedInput
                validatedStateForAutoFill={contactPhone}
                label=""
                useId="phone"
                type="phone"
                setValidatedState={event => {
                  setContactPhone({
                    value: event.value.replace(/\s+/g, ' '),
                    valid: event.valid && event.value.trim().length > 0,
                  });
                }}
                validators={[Validators.REQUIRED, Validators.PHONE_LENGTH]}
                validate={validate}
                placeholder={account?.contactPhone || 'Phone'}
              />
            </div>
          </form>
        </div>
        {isUserAccountOwner() && (
          <>
            <hr className="divider" />
            <div className="section-header">
              <h3>{ACCOUNT_CONSTANTS.DEACTIVATION}</h3>
            </div>
            <div className="section-content">
              <div className="deactivate">{ACCOUNT_CONSTANTS.ACCOUNT_DEACTIVATE_SUBHEADER}</div>
              <button
                className={canUserDeactivateAccount() ? 'deactivate-button button' : 'deactivate-button disabled button'}
                onClick={() => setIsOpenDeactivateAccountModal(true)}
                disabled={!canUserDeactivateAccount()}>
                {ACCOUNT_CONSTANTS.DEACTIVATE_ACCOUNT}
                <ArrowSvg />
              </button>
            </div>
            <hr className="divider" />
          </>
        )}
      </div>
      <DeactivateAccountModal
        isOpen={isOpenDeactivateAccountModal}
        onClose={() => setIsOpenDeactivateAccountModal(false)}
        onConfirm={() => deactivateAccount()}
      />
    </>
  );
};

export default AccountInformation;
