import { FC, useCallback, useEffect, useState } from 'react';
import FilterDropdown from '../custom-input/FilterDropdown';
import ValidatedInput from '../custom-input/ValidatedInput';
import { SpecimenOwnership, SpecimenWithOwnership } from '../../types/interfaces/specimen.interfaces';
import { Account, selectOption } from '../../types/interfaces';
import { getUsePendingSpecimensByCanisterId } from '../../api/specimensApi';
import { showToast } from '../../services/toast.service';
import { formatPositiveNumber } from '../../utils/commonUtils';
import { toastMessages } from '../../constants/errorMessages';
import { LABEL_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../constants/common';
import './specimenTable.scss';

export type SpecimenCheckInQuantity = {
  specimenId: number;
  transactionLinkId: number;
  accountId: number;
  qtyInCanister: number;
  usedQty: number;
  lostQty: number;
  checkInQty: number;
  selected: boolean;
  account: Account;
};

type SpecimenQuantitiesCheckInProps = {
  canisterId?: string;
  specimenIdAutofill?: number;
  transactionId?: number;
  clearCheckInLocation: () => void;
  onChange: (e: SpecimenCheckInQuantity | null) => void;
};

const SelectSpecimenCheckIn: FC<SpecimenQuantitiesCheckInProps> = ({
  canisterId,
  specimenIdAutofill,
  transactionId,
  clearCheckInLocation,
  onChange,
}): JSX.Element => {
  const [checkInSpecimens, setCheckInSpecimens] = useState<SpecimenWithOwnership[]>();
  const [quantities, setQuantities] = useState<SpecimenCheckInQuantity[]>([]);

  const initialState = { value: '', name: '' };
  const [checkInSpecimenOptions, setCheckInSpecimenOptions] = useState<selectOption[]>([initialState]);
  const [checkInSpecimenValue, setCheckInSpecimenValue] = useState<selectOption>(initialState);
  const [selectedSpecimenIndex, setSelectedSpecimenIndex] = useState<number>(-1);

  const isSpecimenSelected = (specimenId: number, specimenOwnership: SpecimenOwnership) => {
    return transactionId != undefined
      ? specimenId === specimenIdAutofill && specimenOwnership.transactionLinkId === transactionId
      : specimenId === specimenIdAutofill;
  };

  const handleGetPendingUseTransactions = useCallback(async () => {
    try {
      if (!canisterId) return;
      const { data: specimensWithUseTransactions } = await getUsePendingSpecimensByCanisterId(canisterId, {
        include: 'Animal',
      });

      specimensWithUseTransactions.sort(
        (a, b) => b.specimenOwnership[0]?.transactionLinkId! - a.specimenOwnership[0]?.transactionLinkId!,
      );

      setCheckInSpecimenOptions(
        specimensWithUseTransactions.map((specimen: SpecimenWithOwnership, index) => {
          return {
            name: `${specimen?.animal?.code}- ${specimen?.specimenOwnership[0].account?.name}-  ${new Date(
              specimen.freezeDate.toString() as string,
            ).toLocaleDateString()} - ${specimen.specimenId}`,
            value: `${index}`,
          };
        }),
      );

      setCheckInSpecimens(specimensWithUseTransactions);
      const newQuantities = specimensWithUseTransactions.map((specimen: SpecimenWithOwnership) => {
        return {
          specimenId: specimen.specimenId,
          accountId: specimen.specimenOwnership[0].accountId,
          transactionLinkId: specimen.specimenOwnership[0].transactionLinkId!,
          qtyInCanister: specimen.specimenOwnership[0].quantity,
          usedQty: 0,
          lostQty: 0,
          checkInQty: 0,
          selected: isSpecimenSelected(specimen.specimenId, specimen.specimenOwnership[0]),
          account: specimen.specimenOwnership[0].account,
        };
      });
      setQuantities(newQuantities);
      setSelectedSpecimenIndex(newQuantities.findIndex(q => q.selected));
    } catch (error: any) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  }, [canisterId]);

  useEffect(() => {
    handleGetPendingUseTransactions();
  }, [handleGetPendingUseTransactions]);

  useEffect(() => {
    onChange(
      quantities.reduce((acc: SpecimenCheckInQuantity | null, q: SpecimenCheckInQuantity) => {
        return q.selected ? q : acc;
      }, null),
    );

    const specimenIndex = quantities.findIndex(
      (specimen: SpecimenCheckInQuantity) =>
        specimen.specimenId === specimenIdAutofill && specimen.transactionLinkId === transactionId,
    );

    if (specimenIndex != -1) {
      setCheckInSpecimenValue(checkInSpecimenOptions[specimenIndex]);
    } else {
      setCheckInSpecimenValue(initialState);
    }
  }, [quantities]);

  const getPlaceholder = (specimen: SpecimenCheckInQuantity, quantity: number): string => {
    return specimen?.selected ? (quantity > 0 ? quantity.toString() : '') : '-';
  };

  const handleOnChangeSpecimen = (e: selectOption) => {
    setCheckInSpecimenValue(e);
    clearCheckInLocation();
    setQuantities(
      quantities.map((quantity, index) => {
        return index === Number(e.value)
          ? { ...quantity, selected: true }
          : { ...quantity, selected: false, usedQty: 0, lostQty: 0, checkInQty: 0 };
      }),
    );

    setSelectedSpecimenIndex(Number(e.value) ?? -1);
  };

  return (
    <div className="select-specimen-checkin">
      <div className="desk-select-specimen">
        <div className="specimen-table">
          <table>
            <thead>
              <tr>
                <th>{TABLE_HEADER_CONSTANTS.SELECT}</th>
                <th>{TABLE_HEADER_CONSTANTS.LINK_ID}</th>
                <th>{TABLE_HEADER_CONSTANTS.ITEMS}</th>
                <th>{TABLE_HEADER_CONSTANTS.ACCOUNT}</th>
                <th>{TABLE_HEADER_CONSTANTS.QTY_IN_CANISTER}</th>
                <th>{TABLE_HEADER_CONSTANTS.USED_QTY}</th>
                <th>{TABLE_HEADER_CONSTANTS.UNUSABLE_QTY}</th>
                <th>{TABLE_HEADER_CONSTANTS.CHECK_IN_QTY}</th>
              </tr>
            </thead>
            <tbody>
              {checkInSpecimens?.length === 0 && (
                <tr>
                  <td colSpan={7}>{LABEL_CONSTANTS.NO_SPECIMENS_AVAILABLE_FOR_CHECK_IN}</td>
                </tr>
              )}
              {checkInSpecimens?.map((spec, index) => {
                return (
                  <tr
                    key={'specimen-checkin-row:' + spec.specimenId + '-index:' + index}
                    className={
                      spec.specimenOwnership[0].account.outstandingBalanceCents != undefined &&
                      spec.specimenOwnership[0].account.penaltyCents != undefined &&
                      (spec.specimenOwnership[0].account.outstandingBalanceCents > 0 ||
                        spec.specimenOwnership[0].account.penaltyCents > 0)
                        ? 'warning'
                        : ''
                    }>
                    <td>
                      <input
                        type="radio"
                        name="selected-checkin-specimen-row"
                        checked={quantities[index]?.selected ?? false}
                        onChange={() => {
                          clearCheckInLocation();
                          setSelectedSpecimenIndex(index);
                          setCheckInSpecimenValue(checkInSpecimenOptions[index]);
                          setQuantities(
                            quantities.map((q, i) => {
                              return i === index
                                ? { ...q, selected: true }
                                : { ...q, selected: false, usedQty: 0, lostQty: 0, checkInQty: 0 };
                            }),
                          );
                        }}
                      />
                    </td>
                    <td>{spec.specimenOwnership[0].transactionLinkId}</td>
                    <td>
                      {spec.animal?.code} - {new Date(spec.freezeDate.toString() as string).toLocaleDateString()}
                    </td>
                    <td>
                      {spec.specimenOwnership[0].account?.name}{' '}
                      <label className="account-due-payment">
                        {spec.specimenOwnership[0].account.outstandingBalanceCents != undefined &&
                        spec.specimenOwnership[0].account.penaltyCents != undefined &&
                        (spec.specimenOwnership[0].account.outstandingBalanceCents > 0 ||
                          spec.specimenOwnership[0].account.penaltyCents > 0)
                          ? '(Due Payment)'
                          : ''}
                      </label>
                    </td>
                    <td>{quantities[index]?.qtyInCanister}</td>
                    <td>
                      <input
                        type="text"
                        placeholder="0"
                        value={getPlaceholder(quantities[index], quantities[index].usedQty)}
                        disabled={!quantities[index]?.selected}
                        onChange={e => {
                          const count = formatPositiveNumber(e.target.value);
                          let newQuantities = [...quantities];
                          newQuantities[index] = {
                            ...quantities[index],
                            usedQty: count,
                          };
                          setQuantities(newQuantities);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        placeholder="0"
                        value={getPlaceholder(quantities[index], quantities[index].lostQty)}
                        disabled={!quantities[index]?.selected}
                        onChange={e => {
                          const count = formatPositiveNumber(e.target.value);
                          let newQuantities = [...quantities];
                          newQuantities[index] = {
                            ...quantities[index],
                            lostQty: count,
                          };
                          setQuantities(newQuantities);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        placeholder="0"
                        value={getPlaceholder(quantities[index], quantities[index].checkInQty)}
                        disabled={!quantities[index]?.selected}
                        onChange={e => {
                          const count = formatPositiveNumber(e.target.value);
                          let newQuantities = [...quantities];
                          newQuantities[index] = {
                            ...quantities[index],
                            checkInQty: count,
                          };
                          setQuantities(newQuantities);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan={8}>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Mobile View */}
      <div className="xs-select-specimen">
        {checkInSpecimens?.length === 0 ? (
          <label className="no-specimen">{LABEL_CONSTANTS.NO_SPECIMENS_AVAILABLE_FOR_CHECK_IN}</label>
        ) : (
          <>
            <div className="form-row">
              <label>{TABLE_HEADER_CONSTANTS.ITEMS}: </label>
              <div className="input-container wide-input-container">
                <FilterDropdown
                  placeholder={'Select One'}
                  options={checkInSpecimenOptions}
                  value={checkInSpecimenValue}
                  onChange={e => handleOnChangeSpecimen(e)}
                />
              </div>
            </div>
            {quantities[selectedSpecimenIndex] && (
              <>
                <div className="form-row">
                  <label>{TABLE_HEADER_CONSTANTS.ACCOUNT}:</label>
                  <div className="input-container">
                    <label>
                      {quantities[selectedSpecimenIndex]?.account.name ?? '-'}
                      {quantities[selectedSpecimenIndex].account?.outstandingBalanceCents === 0 &&
                      quantities[selectedSpecimenIndex].account?.penaltyCents === 0 ? (
                        <></>
                      ) : (
                        <label className="account-due-payment">{LABEL_CONSTANTS.DUE_PAYMENT}</label>
                      )}
                    </label>
                  </div>
                </div>
                <div className="form-row">
                  <label>{LABEL_CONSTANTS.TRANSACTION_LINK_ID}:</label>
                  <div className="input-container">
                    <label>{quantities[selectedSpecimenIndex]?.transactionLinkId ?? '-'}</label>
                  </div>
                </div>
              </>
            )}
            <div className="form-row">
              <label>{TABLE_HEADER_CONSTANTS.QTY_IN_CANISTER}:</label>
              <div className="input-container">
                <label>{quantities[selectedSpecimenIndex]?.qtyInCanister ?? '-'}</label>
              </div>
            </div>
            <div className="form-row">
              <ValidatedInput
                label="Used:"
                type="number"
                validatedStateForAutoFill={{ value: (quantities[selectedSpecimenIndex]?.usedQty ?? '').toString(), valid: true }}
                setValidatedState={e => {
                  let newQuantities = [...quantities];
                  newQuantities[selectedSpecimenIndex] = {
                    ...quantities[selectedSpecimenIndex],
                    usedQty: Number(e.value),
                  };
                  setQuantities(newQuantities);
                }}
                validators={[]}
              />
            </div>
            <div className="form-row">
              <ValidatedInput
                label="Lost:"
                type="number"
                validatedStateForAutoFill={{ value: (quantities[selectedSpecimenIndex]?.lostQty ?? '').toString(), valid: true }}
                setValidatedState={e => {
                  let newQuantities = [...quantities];
                  newQuantities[selectedSpecimenIndex] = {
                    ...quantities[selectedSpecimenIndex],
                    lostQty: Number(e.value),
                  };
                  setQuantities(newQuantities);
                }}
                validators={[]}
              />
            </div>
            <div className="form-row">
              <ValidatedInput
                label="Check In Qty:"
                type="number"
                validatedStateForAutoFill={{
                  value: (quantities[selectedSpecimenIndex]?.checkInQty ?? '').toString(),
                  valid: true,
                }}
                setValidatedState={e => {
                  let newQuantities = [...quantities];
                  newQuantities[selectedSpecimenIndex] = {
                    ...quantities[selectedSpecimenIndex],
                    checkInQty: Number(e.value),
                  };
                  setQuantities(newQuantities);
                }}
                validators={[]}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SelectSpecimenCheckIn;
