import { FC, useEffect, useState } from 'react';
import InfoWarning from '../info-text/InfoWarning';
import NumberInput from '../custom-input/NumberInput';
import { SpecimenInventoryWithUseRequested, SpecimenLocation } from '../../types/interfaces/specimen.interfaces';
import { CheckOutSpecimen } from '../../types/interfaces/transaction.interfaces';
import { toastMessages } from '../../constants/errorMessages';
import { showToast } from '../../services/toast.service';

type PendingUseSelectQuantityTableProps = {
  pendingUseTransaction: SpecimenInventoryWithUseRequested;
  setCheckOutSpecimens: React.Dispatch<React.SetStateAction<CheckOutSpecimen[]>>;
  checkOutSpecimens: CheckOutSpecimen[];
  setCheckOutRowInvalid: React.Dispatch<React.SetStateAction<boolean>>;
};

const PendingUseSelectQuantityTable: FC<PendingUseSelectQuantityTableProps> = ({
  pendingUseTransaction,
  setCheckOutSpecimens,
  checkOutSpecimens,
  setCheckOutRowInvalid,
}) => {
  const totalSelectedQuantity = checkOutSpecimens.reduce((accumulator, current) => accumulator + current.quantity, 0);
  const [isTravelingTank, setIsTravelingTank] = useState<boolean>(false);

  useEffect(() => {
    setIsTravelingTank(
      pendingUseTransaction.specimenLocations?.some(
        (loc: SpecimenLocation) => loc.storageCanister?.storageTank?.isTravelingTank,
      ) ?? false,
    );
  }, [pendingUseTransaction.specimenLocations]);

  const checkIfTransactionIsValid = (checkOutQty: number, canisterQty: number) => {
    if (checkOutQty > canisterQty) {
      showToast.error(toastMessages.CHOSE_MORE_THAN_TOTAL);
      setCheckOutRowInvalid(true);
    } else if (checkOutQty < 0 || totalSelectedQuantity < 0) {
      showToast.error(toastMessages.CHOSE_LESS_THAN_ZERO);
      setCheckOutRowInvalid(true);
    } else {
      setCheckOutRowInvalid(false);
    }
  };

  function removeTravelingTanks(inventory: SpecimenInventoryWithUseRequested[]): SpecimenInventoryWithUseRequested[] {
    inventory.forEach((i: SpecimenInventoryWithUseRequested) => {
      i.specimenLocations = i?.specimenLocations?.filter(
        (loc: SpecimenLocation) => !loc.storageCanister?.storageTank?.isTravelingTank,
      );
    });

    return inventory.filter((i: SpecimenInventoryWithUseRequested) => i.specimenLocations?.length);
  }

  const handleCheckoutQuantityChange = (e: React.ChangeEvent<HTMLInputElement>, specimenLocation: SpecimenLocation) => {
    let newQuantity = parseInt(e.target.value);
    const newCheckOutSpecimens = [...checkOutSpecimens];
    const index = newCheckOutSpecimens.findIndex(
      checkOutSpecimen => checkOutSpecimen.selectedCanisterId === specimenLocation.storageCanisterId,
    );

    if (isNaN(newQuantity)) {
      newQuantity = 0;
    }

    checkIfTransactionIsValid(newQuantity, specimenLocation?.quantity!);

    if (index != -1) {
      newCheckOutSpecimens[index].quantity = newQuantity;
      setCheckOutSpecimens(newCheckOutSpecimens);
    }
  };

  function handleSummaryClass() {
    if (totalSelectedQuantity > pendingUseTransaction.quantityOwned) {
      showToast.error(toastMessages.CHOSE_MORE_THAN_OWNED);
      setCheckOutRowInvalid(true);
      return 'invalid-row';
    } else if (totalSelectedQuantity > pendingUseTransaction.quantityAvailable) {
      return 'override-row';
    }
  }

  function handleRowClass(index: number, location: SpecimenLocation, mobileView: boolean, disabled: boolean): string {
    if (index < checkOutSpecimens.length && checkOutSpecimens[index].quantity !== undefined) {
      return `${mobileView ? 'card max-width' : 'specimen-row'} ${
        checkOutSpecimens[index].quantity > location.quantity && !disabled ? 'invalid-row' : ''
      }`;
    } else {
      return '';
    }
  }

  const getSelectedQuantity = (storageCanisterId: number) => {
    const newCheckOutSpecimens = [...checkOutSpecimens];
    const index = newCheckOutSpecimens.findIndex(checkOutSpecimen => checkOutSpecimen.selectedCanisterId === storageCanisterId);
    return index != -1 ? checkOutSpecimens[index]?.quantity.toString() : '';
  };

  return (
    <div className="pending-use-selected-checkout">
      <div className="desk-pending-use-selected">
        <div className="specimen-table">
          <table>
            <thead>
              <tr>
                <th>Site</th>
                <th>Tank</th>
                <th>Canister</th>
                <th>Canister Qty</th>
                <th>Check Out Qty</th>
              </tr>
            </thead>
            {pendingUseTransaction.specimenLocations &&
              pendingUseTransaction.specimenLocations?.length > 0 &&
              checkOutSpecimens &&
              checkOutSpecimens.length > 0 && (
                <tbody>
                  {pendingUseTransaction.specimenLocations
                    .filter(location => location.storageCanister?.storageTank?.isTravelingTank === false)
                    .map((specimenLocation, index) => {
                      return (
                        <tr
                          className={handleRowClass(index, specimenLocation, false, false)}
                          key={'specimen-row-' + specimenLocation?.storageCanisterId}>
                          <td>{specimenLocation?.storageCanister?.storageTank?.storageSite?.name}</td>

                          <td>{specimenLocation?.storageCanister?.storageTank?.name}</td>
                          <td>{specimenLocation?.storageCanister?.name}</td>
                          <td>{specimenLocation?.quantity}</td>
                          <td>
                            <NumberInput
                              onChange={e => handleCheckoutQuantityChange(e, specimenLocation)}
                              value={getSelectedQuantity(specimenLocation.storageCanisterId)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  {pendingUseTransaction.specimenLocations
                    .filter(location => location.storageCanister?.storageTank?.isTravelingTank === true)
                    .map((specimenLocation, index) => {
                      return (
                        <tr
                          className={handleRowClass(index, specimenLocation, false, true) + ' disabled'}
                          key={'specimen-row-' + specimenLocation?.storageCanisterId}>
                          <td>{specimenLocation?.storageCanister?.storageTank?.storageSite?.name}</td>

                          <td>{specimenLocation?.storageCanister?.storageTank?.name}</td>
                          <td>{specimenLocation?.storageCanister?.name}</td>
                          <td>{specimenLocation?.quantity}</td>
                          <td>Traveling Tank</td>
                        </tr>
                      );
                    })}
                  <tr className={handleSummaryClass()}>
                    <td colSpan={4}></td>
                    <td colSpan={1}>
                      <div className="quantity">{totalSelectedQuantity}</div>
                    </td>
                  </tr>
                </tbody>
              )}
          </table>
        </div>
        {isTravelingTank && (
          <div>
            <br />
            <InfoWarning
              message={`Items stored in Traveling tanks can't be checked out. Please process a Move transaction if necessary`}
            />
          </div>
        )}
      </div>

      {/* Mobile View */}
      <div className="xs-pending-use-selected">
        {pendingUseTransaction.specimenLocations &&
          pendingUseTransaction.specimenLocations?.length > 0 &&
          checkOutSpecimens &&
          checkOutSpecimens.length > 0 && (
            <>
              {pendingUseTransaction.specimenLocations
                .filter(location => location.storageCanister?.storageTank?.isTravelingTank === false)
                .map((specimenLocation, index) => {
                  return (
                    <div
                      className={handleRowClass(index, specimenLocation, true, false)}
                      key={specimenLocation?.storageCanisterId}>
                      <div className="body">
                        <div className="specimen-location">
                          <label className="specimen-location-label">{`${specimenLocation?.storageCanister?.storageTank?.storageSite?.name}/${specimenLocation?.storageCanister?.storageTank?.name}/${specimenLocation?.storageCanister?.name}`}</label>
                          <label>{`Canister Qty: ${specimenLocation?.quantity}`}</label>
                        </div>
                        <div className="selected-specimen-qty">
                          <label>{'Selected Qty'}</label>
                          <NumberInput
                            onChange={e => handleCheckoutQuantityChange(e, specimenLocation)}
                            value={getSelectedQuantity(specimenLocation.storageCanisterId)}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              {pendingUseTransaction.specimenLocations
                .filter(location => location.storageCanister?.storageTank?.isTravelingTank === true)
                .map((specimenLocation, index) => {
                  return (
                    <div
                      className={handleRowClass(index, specimenLocation, true, true) + ' disabled'}
                      key={specimenLocation?.storageCanisterId}>
                      <div className="body">
                        <div className="specimen-location">
                          <label className="specimen-location-label">{`${specimenLocation?.storageCanister?.storageTank?.storageSite?.name}/${specimenLocation?.storageCanister?.storageTank?.name}/${specimenLocation?.storageCanister?.name}`}</label>
                          <label>{`Canister Qty: ${specimenLocation?.quantity}`}</label>
                        </div>
                        <div className="selected-specimen-qty">
                          <label>{'Traveling Tank'}</label>
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div className={handleSummaryClass()}>
                <div className="total-quantity">
                  <label>Total Selected: </label>
                  {totalSelectedQuantity}
                </div>
              </div>
            </>
          )}
        {isTravelingTank && (
          <div>
            <br />
            <InfoWarning
              message={`Items stored in Traveling tanks can't be checked out. Please process a Move transaction if necessary`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PendingUseSelectQuantityTable;
