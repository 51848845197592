import { useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import SetupDeposit from './SetupDeposit';
import RedeemSellCode from './RedeemSellCode';
import PurchaseDetails from './PurchaseDetails';
import Modal from '../../../components/modal/Modal';
import Loader from '../../../components/loader/Loader';
import { getInventoryTransactionBySellCode } from '../../../api/inventoryTransactionsApi';
import { getAccountById } from '../../../api/accountApi';
import { inventoryDeposit, redeemInventory } from '../../../api/inventoryApi';
import { showToast } from '../../../services/toast.service';
import { toastMessages } from '../../../constants/errorMessages';
import { BUTTON_CONSTANTS, INVENTORY_CONSTANTS, LABEL_CONSTANTS } from '../../../constants/common';
import { InventoryTransaction } from '../../../types/interfaces/transaction.interfaces';

export interface PurchaseDetailsInterface {
  animalCode: string;
  animalName: string;
  quantity: number;
  sellerName: string;
  sellerEmail: string;
}

interface AddInventoryModalProps {
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
}

const defaultPurchaseDetails = {
  animalCode: '',
  animalName: '',
  quantity: 0,
  sellerName: '',
  sellerEmail: '',
};

const AddInventoryModal = ({ isOpen, setIsOpen }: AddInventoryModalProps) => {
  const [hasSellCode, setHasSellCode] = useState<boolean>(false);
  const [hasNoSellCode, setHasNoSellCode] = useState<boolean>(false);
  const [sellCode, setSellCode] = useState<string>('');
  const [quantity, setQuantity] = useState<number>();
  const [notes, setNotes] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [purchaseDetails, setPurchaseDetails] = useState<PurchaseDetailsInterface>(defaultPurchaseDetails);
  const { accountId } = useAppSelector(state => state.activeAccount);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    if (hasSellCode) {
      handleSellCodeRedeem();
    } else if (hasNoSellCode) {
      handleInventoryDeposit();
    }
  };

  const handleSellCodeRedeem = async () => {
    try {
      const { data: transaction } = await getInventoryTransactionBySellCode(sellCode);
      if (!transaction.length) {
        throw new Error();
      } else {
        const { data: sellerAccount } = await getAccountById(transaction[0].accountId);
        const seller = sellerAccount.users.find((u: any) => Number(u.accountId) === Number(sellerAccount.accountId)!);
        let totalPurchaseQuantity = 0;
        setPurchaseDetails({
          animalCode: transaction[0].specimen?.animal?.code ?? '',
          animalName: transaction[0].specimen?.animal?.name ?? '',
          quantity: transaction.reduce(
            (accumulator, currentValue) => accumulator + Math.abs(currentValue?.changeQuantity!),
            totalPurchaseQuantity,
          ),
          sellerName: seller?.user.firstName + ' ' + seller?.user.lastName,
          sellerEmail: seller?.user.username ?? '',
        });

        confirmTransaction(transaction);
        setIsInvalid(false);
      }
    } catch {
      setIsInvalid(true);
      showToast.error(toastMessages.INVALID_SELL_CODE);
    }
    setIsLoading(false);
  };

  const handleInventoryDeposit = async () => {
    await inventoryDeposit(Number(accountId), Number(quantity), notes)
      .then(() => {
        setIsOpen(false);
        setHasSellCode(false);
        setHasNoSellCode(false);
        setNotes('');
        setQuantity(0);
        showToast.success(toastMessages.DEPOSIT_INITIATED);
      })
      .catch(() => {
        showToast.error(toastMessages.FAILED_DEPOSIT_INITIATED);
      });
    setIsLoading(false);
  };

  const confirmTransaction = async (inventoryTransaction: InventoryTransaction[]) => {
    // redeemInventory doesn't accept inventory transactions with specimen data.
    const removedSpecimenInventoryTransactions = inventoryTransaction.map(it => {
      it.specimen = undefined;
      return it;
    });

    redeemInventory(removedSpecimenInventoryTransactions, Number(accountId))
      .then(() => {
        showToast.success(toastMessages.SUCCESS);
      })
      .catch(() => {
        showToast.error(toastMessages.FAILED_TO_UPDATE_INVENTORY);
      });
  };

  const handleClose = () => {
    setIsOpen(false);
    setPurchaseDetails(defaultPurchaseDetails);
    setHasSellCode(false);
    setHasNoSellCode(false);
    setNotes('');
    setQuantity(0);
    setIsInvalid(false);
    window.location.reload();
  };

  const handleSetSellCode = (newSellCode: string) => {
    setSellCode(newSellCode);
    setIsInvalid(false);
  };

  const checkValidity = (): boolean => {
    return (
      isLoading ||
      (!hasSellCode && !hasNoSellCode) ||
      (hasSellCode && !sellCode.trim().length) ||
      (hasNoSellCode && (!notes || !(quantity && quantity > 0)))
    );
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} ignoreBackdrop={true}>
        {!purchaseDetails.animalCode ? (
          <div className="add-inventory-modal">
            <div className="add-inventory-title">
              <h4>{BUTTON_CONSTANTS.ADD_INVENTORY}</h4>
              <hr />
              <p>{INVENTORY_CONSTANTS.HAVE_SELL_CODE}</p>
              <div className="radio-row">
                <input
                  type="radio"
                  id="has-sell-true"
                  name="has-sell"
                  onChange={() => {
                    setHasNoSellCode(false);
                    setHasSellCode(true);
                  }}
                />
                <label htmlFor="has-sell-true">{LABEL_CONSTANTS.YES}</label>
                <input
                  type="radio"
                  id="has-sell-false"
                  name="has-sell"
                  onChange={() => {
                    setHasSellCode(false);
                    setHasNoSellCode(true);
                  }}
                />
                <label htmlFor="has-sell-false">{LABEL_CONSTANTS.NO}</label>
              </div>
            </div>
            {isLoading && <Loader loaderSize={'medium'} simple />}

            <form onSubmit={handleSubmit}>
              {hasSellCode && <RedeemSellCode isInvalid={isInvalid} setSellCode={handleSetSellCode} />}
              {hasNoSellCode && <SetupDeposit setNotes={setNotes} setQuantity={setQuantity} quantity={quantity} />}

              <div className="footer">
                <button type="reset" onClick={() => setIsOpen(false)} className="button green inverted mr-1">
                  {BUTTON_CONSTANTS.CANCEL}
                </button>
                <button type="submit" className="button green" disabled={checkValidity()}>
                  {BUTTON_CONSTANTS.CONFIRM}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <PurchaseDetails purchaseDetails={purchaseDetails} handleClose={handleClose} />
        )}
      </Modal>
    </>
  );
};

export default AddInventoryModal;
