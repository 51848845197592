const EditSVG = (): JSX.Element => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.75 13.2508H2.8L9.26875 6.78203L8.21875 5.73203L1.75 12.2008V13.2508ZM12.475 5.69453L9.2875 2.54453L11.4062 0.425781L14.575 3.59453L12.475 5.69453ZM0.25 14.7508V11.5633L8.2 3.61328L11.3875 6.80078L3.4375 14.7508H0.25ZM8.74375 6.25703L8.21875 5.73203L9.26875 6.78203L8.74375 6.25703Z"
        fill="#1379C1"
      />
    </svg>
  );
};
export default EditSVG;
