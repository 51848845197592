import { FC, useEffect, useState } from 'react';
import FilterDropdown, { FilterOption } from '../custom-input/FilterDropdown';
import { getSpecimenOwners, getSpecimensInventory } from '../../api/specimensApi';
import { Specimen } from '../../types/interfaces/specimen.interfaces';
import { Animal } from '../../types/interfaces/animal.interfaces';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';

type SelectSpecimenFromCanisterProps = {
  canisterId?: number;
  selectedSpecimen?: FilterOption<number | undefined>;
  setSelectedSpecimen: (e: FilterOption<number | undefined>) => void;
  selectedOwner: FilterOption<number | undefined>;
  setSelectedOwner: (e: FilterOption<any | undefined>) => void;
};

const SelectSpecimenFromCanister: FC<SelectSpecimenFromCanisterProps> = ({
  canisterId,
  selectedSpecimen,
  setSelectedSpecimen,
  selectedOwner,
  setSelectedOwner,
}) => {
  const [specimens, setSpecimens] = useState<Specimen[]>([]);
  const [owners, setOwners] = useState<FilterOption<number>[]>([]);
  const [specimenOptions, setSpecimenOptions] = useState<FilterOption<number | undefined>[]>([]);
  const [animal, setAnimal] = useState<Animal>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    function getInventory() {
      if (canisterId) {
        setIsLoading(true);
        const query = { include: 'Animal.Owners', canisterId: canisterId };

        try {
          getSpecimensInventory(query)
            .then((res: any) => {
              setSpecimens(res.data);
              setSpecimenOptions(getSpecimenOptions(res.data));
            })
            .finally(() => {
              setIsLoading(false);
            });
        } catch (error) {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      } else {
        setSpecimens([]);
        setSelectedSpecimen({ name: '', value: undefined });
        setSelectedOwner({ name: '', value: undefined });
        setAnimal(undefined);
      }
    },
    [canisterId, setSelectedOwner, setSelectedSpecimen],
  );

  const handleSpecimen = (specimen: FilterOption<number>) => {
    if (specimen.value === undefined) {
      return;
    }

    setSelectedSpecimen(specimen);
    setSelectedOwner({ name: '', value: undefined });
    const fullSpecimen = specimens.find(s => s.specimenId === specimen.value);
    setAnimal(fullSpecimen?.animal);

    getSpecimenOwners(specimen.value).then(accounts => {
      setOwners(
        accounts.data.map(account => {
          return {
            name: account.name,
            value: Number(account.accountId),
            optionWarning:
              account.outstandingBalanceCents != undefined &&
              account.penaltyCents != undefined &&
              (account.outstandingBalanceCents > 0 || account.penaltyCents > 0)
                ? true
                : false,
          };
        }),
      );
    });
  };

  function getSpecimenOptions(specimensData: any): FilterOption<number | undefined>[] {
    const data: FilterOption<number | undefined>[] = specimensData.map((specimen: Specimen) => {
      return {
        name: `${specimen.animal?.code}-${new Date(specimen.freezeDate).toLocaleDateString()}-${specimen.specimenId}`,
        value: specimen.specimenId,
      };
    });

    return data;
  }

  return (
    <>
      <div className="select-specimen-from-canister">
        <div className="form-row">
          <div className="text">
            <label>Inventory Items:</label>
          </div>
          <div className="input-container">
            <FilterDropdown
              disabled={!canisterId || isLoading}
              placeholder="Choose One"
              searchPlaceholder="Animal Code - Lot Date - Lot Number"
              options={specimenOptions}
              value={selectedSpecimen ?? { name: '', value: '' }}
              onChange={handleSpecimen}
            />
          </div>
        </div>

        <div className="form-row">
          <label>Animal Name:</label>
          <div className="input-container">
            <label>{canisterId && selectedSpecimen?.value ? animal?.name : '-'}</label>
          </div>
        </div>

        <div className="form-row">
          <div className="text">
            <label>Account/Owner:</label>
          </div>
          <div className="input-container">
            <FilterDropdown
              options={owners}
              value={selectedOwner ?? { name: '', value: undefined }}
              disabled={!animal?.name}
              onChange={setSelectedOwner}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectSpecimenFromCanister;
