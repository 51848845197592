import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountSearch } from '../../components';
import { showToast } from '../../services/toast.service';
import { getAccounts } from '../../api/accountApi';
import { getUserNoId } from '../../api/userApi';
import { Account, PagedResponse } from '../../types/interfaces';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { toastMessages } from '../../constants/errorMessages';
import { LABEL_CONSTANTS, PAGE_HEADER_CONSTANTS } from '../../constants/common';

type CustomerInfoHeaderProps = {
  customerAccount?: Account;
};

const CustomerInfoHeader: FC<CustomerInfoHeaderProps> = ({ customerAccount }): JSX.Element => {
  const navigate = useNavigate();

  const [accountsCount, setAccountsCount] = useState<number>();
  const [usersCount, setUsersCount] = useState<number>();

  useEffect(() => {
    const getAccountsCount = async () => {
      try {
        const { data: accountsResponse } = await getAccounts([], { limit: 1, offset: 0 });
        setAccountsCount((accountsResponse as PagedResponse<any>).total);

        const { data: usersResponse } = await getUserNoId({ limit: 1, offset: 0 });
        setUsersCount((usersResponse as PagedResponse<any>).total);
      } catch {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    };

    getAccountsCount();
  }, []);

  return (
    <div className="customer-info-header info-search-header">
      <div className="header-row">
        <div className="header-section">
          <div className="section">
            <h1>{PAGE_HEADER_CONSTANTS.CUSTOMER_INFO}</h1>
          </div>
          <div className="section">
            <span className="bold-count">{usersCount ?? '--'}</span>
            {LABEL_CONSTANTS.USERS}
          </div>
          <div className="section">
            <span className="bold-count">{accountsCount ?? '--'}</span>
            {LABEL_CONSTANTS.ACCOUNTS}
          </div>
        </div>
      </div>
      <div className="search-bar">
        <AccountSearch
          onChange={(account?: Account) => {
            if (account !== undefined) {
              navigate(ROUTE_PATHS.APP_CUSTOMER_INFO_VIEW + account?.accountId);
            }
          }}
          account={customerAccount}
        />
      </div>
    </div>
  );
};

export default CustomerInfoHeader;
