import { AxiosResponse } from 'axios';
import { PasswordResetRequest, SetNewPasswordRequest } from '../types/interfaces/auth.interfaces';
import { AccountUserInfo, ModifyUserInterface, UserInfo, UpdateUserInterface } from '../types/interfaces/user.interfaces';
import { ODataParams, StandardParams } from '../types/interfaces/apiParams.interfaces';
import { AppRoles } from '../types/enums';
import { Role } from '../types/interfaces';
import { USER_API_ENDPOINTS } from '../constants/apiEndpoints';
import { commonAxios } from './httpCommon/axiosInstances';
import { appContextDelete, appContextGet, appContextPost, appContextPut } from './httpCommon/appAxios.service';

export const passwordReset = (parameters: PasswordResetRequest): Promise<AxiosResponse<{}, any>> => {
  return commonAxios.post(USER_API_ENDPOINTS.PASSWORD_RESET, null, {
    headers: {},
    params: {
      username: parameters.username,
    },
  });
};

export const setNewPassword = (parameters: SetNewPasswordRequest): Promise<AxiosResponse<{}, any>> => {
  return appContextPut(USER_API_ENDPOINTS.SET_NEW_PASSWORD, parameters);
};

export const getUserByUsername = (
  username: string,
  params?: { include: string },
): Promise<AxiosResponse<AccountUserInfo, any>> => {
  return appContextGet(USER_API_ENDPOINTS.USER + '/' + username, params);
};

export const getUser = (id: number, params?: { include: string }): Promise<AxiosResponse<UserInfo, any>> => {
  return appContextGet(USER_API_ENDPOINTS.USER + '/' + id, params);
};

export const createUser = (userInfo: ModifyUserInterface): Promise<AxiosResponse<{}, any>> => {
  return appContextPost(
    USER_API_ENDPOINTS.USER,
    {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      username: userInfo.username,
      roles: [{ name: userInfo.roles }],
      contactPhone: userInfo.contactPhone,
    },
    { template: 'Invitation', verified: false },
  );
};

export const editAppRole = (userId: number | string, role: Role<AppRoles>[]): Promise<AxiosResponse<{}, any>> => {
  return appContextPut(USER_API_ENDPOINTS.USER + '/' + userId, { roles: role });
};

export const getUserNoId = (params?: StandardParams): Promise<AxiosResponse<{}, any>> => {
  return appContextGet(USER_API_ENDPOINTS.USER, params);
};

export const getUserOdata = (params?: ODataParams): Promise<AxiosResponse<UserInfo[], any>> => {
  return appContextGet(USER_API_ENDPOINTS.ODATA, params);
};

export const getAllUsers = (params?: { include: string }): Promise<AxiosResponse<UserInfo[], any>> => {
  return appContextGet(USER_API_ENDPOINTS.USER, params);
};

export const updateUser = (id: number, data: UpdateUserInterface): Promise<AxiosResponse<{}, any>> => {
  return appContextPut(USER_API_ENDPOINTS.USER + '/' + id, data);
};

export const deleteUser = (id: number): Promise<AxiosResponse<{}, any>> => {
  return appContextDelete(USER_API_ENDPOINTS.USER + '/' + id);
};

export const resendVerificationEmail = (id: number): Promise<AxiosResponse<{}, any>> => {
  return appContextPost(USER_API_ENDPOINTS.USER + '/' + id + USER_API_ENDPOINTS.VERIFICATION, id);
};

export const resendInvitationEmail = (id: number): Promise<AxiosResponse<{}, any>> => {
  return appContextPost(USER_API_ENDPOINTS.USER + '/' + id + USER_API_ENDPOINTS.INVITATION, undefined, { id: id });
};

export const postUserProfileImage = (data: FormData): Promise<AxiosResponse<{}, any>> => {
  return appContextPost(USER_API_ENDPOINTS.USER + USER_API_ENDPOINTS.PROFILE_IMAGE, data);
};

export const getUserProfileImage = async () => {
  try {
    const response = await appContextGet(
      USER_API_ENDPOINTS.USER + USER_API_ENDPOINTS.PROFILE_IMAGE,
      {},
      { responseType: 'blob' },
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserProfileImageById = async (userId: number, profileImageId: string) => {
  if (!profileImageId) return;
  try {
    const response = await appContextGet(
      USER_API_ENDPOINTS.USER + `/${userId}` + USER_API_ENDPOINTS.PROFILE_IMAGE,
      {},
      { responseType: 'blob' },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const adminPasswordReset = (username: string): Promise<AxiosResponse<string, any>> => {
  return appContextPost(USER_API_ENDPOINTS.ADMIN_PASSWORD_RESET, undefined, { username });
};
