import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import BackButton from '../back-button/BackButton';
import Loader from '../loader/Loader';
import LinkedTransactions from './LinkedTransactions';
import AssociatedTransactions from './AssociatedTransactions';
import TransactionEdit from './TransactionEdit';
import TransactionLabelField from './TransactionLabelField';
import SortedTable from '../sorted-table/SortedTable';
import { getInventoryItemId } from '../../services/inventoryTransaction.service';
import { showToast } from '../../services/toast.service';
import { APP_PATHS } from '../../constants/routePaths';
import { toastMessages } from '../../constants/errorMessages';
import {
  BUTTON_CONSTANTS,
  LABEL_CONSTANTS,
  PAGE_HEADER_CONSTANTS,
  TABLE_HEADER_CONSTANTS,
  TRANSACTION_CONSTANTS,
} from '../../constants/common';
import { getInventoryTransactionById } from '../../api/inventoryTransactionsApi';
import { getAccountById } from '../../api/accountApi';
import { getBulkTransactionById } from '../../api/bulkTransactionApi';
import { Account } from '../../types/interfaces/account.interfaces';
import { InventoryTrans } from '../../types/interfaces/transaction.interfaces';
import { TransactionTypeEnum } from '../../types/enums';
import './transactionView.scss';

const TransactionView = (): JSX.Element => {
  const { filterPreset, filter, bulkOrder } = useLocation().state;
  const { transactionId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [inventoryTransaction, setInventoryTransaction] = useState<InventoryTrans>();
  const [account, setAccount] = useState<Account>();
  const [onEdit, setOnEdit] = useState<boolean>(false);
  const [onTransactionManagement, setOnTransactionManagement] = useState<boolean>(false);

  useEffect(() => {
    if (window.location.href.includes(APP_PATHS.TRANSACTION_MANAGEMENT_)) {
      setOnTransactionManagement(true);
    }
    getTransactions();
  }, []);

  const getTransactions = async () => {
    setIsLoading(true);

    try {
      const { data: trans } = bulkOrder
        ? await getBulkTransactionById(+transactionId!, { include: 'InventoryTransactions.Specimen.Animal,Account' })
        : await getInventoryTransactionById(+transactionId!, { include: 'Account,Specimen.Animal' });

      setInventoryTransaction(trans);
      if (![TransactionTypeEnum.Reconcile, TransactionTypeEnum.Move].includes(trans.transactionType as TransactionTypeEnum)) {
        const { data: acc } = await getAccountById(trans.accountId);
        setAccount(acc);
      }
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeHandle = () => {
    setOnEdit(false);
    getTransactions();
  };

  return (
    <>
      {!onEdit ? (
        <div className="transaction-view">
          {!isLoading && inventoryTransaction != undefined ? (
            <div className="transaction-request">
              <BackButton state={{ filterPreset, filter, bulkOrder }} />
              <div className="inventory-action card">
                <h1>
                  {onTransactionManagement
                    ? `${PAGE_HEADER_CONSTANTS.VIEW_EDIT_TRANSACTION}`
                    : `${PAGE_HEADER_CONSTANTS.VIEW_TRANSACTION}`}
                </h1>
                <h2>{TRANSACTION_CONSTANTS.TRANSACTION_INFORMATION}</h2>

                <TransactionLabelField
                  label={bulkOrder ? `${LABEL_CONSTANTS.BULK_TRANSACTION_ID}:` : `${TRANSACTION_CONSTANTS.TRANSACTION_ID}:`}
                  value={transactionId}
                />

                <TransactionLabelField
                  label={
                    bulkOrder ? `${LABEL_CONSTANTS.BULK_TRANSACTION_LINK_ID}:` : `${TRANSACTION_CONSTANTS.TRANSACTION_LINK_ID}:`
                  }
                  value={(inventoryTransaction?.transactionLinkId ?? '').toString()}
                />

                <TransactionLabelField
                  label={`${TRANSACTION_CONSTANTS.TRANSACTION_TYPE}:`}
                  value={inventoryTransaction?.transactionType}
                />

                <TransactionLabelField
                  label={`${LABEL_CONSTANTS.ACCOUNT_OWNER_ID}:`}
                  value={
                    [TransactionTypeEnum.Reconcile, TransactionTypeEnum.Move].includes(
                      inventoryTransaction.transactionType as TransactionTypeEnum,
                    )
                      ? 'N/A'
                      : account?.accountId + ' - ' + account?.name
                  }
                />

                {bulkOrder && onTransactionManagement ? (
                  <>
                    <div className="specimens-table">
                      <SortedTable
                        headers={[
                          { displayName: TABLE_HEADER_CONSTANTS.ANIMAL_NAME },
                          { displayName: TABLE_HEADER_CONSTANTS.INVENTORY_LOT_NUMBER },
                          { displayName: TABLE_HEADER_CONSTANTS.CHANGED_QUANTITY },
                        ]}
                        data={inventoryTransaction.inventoryTransactions?.map((trans: InventoryTrans) => {
                          return [
                            {
                              content: trans?.specimen?.animal?.code
                                ? trans?.specimen?.animal?.code + ' - ' + trans?.specimen?.animal?.name
                                : '-',
                            },
                            { content: getInventoryItemId(trans?.specimen) },
                            { content: trans?.changeQuantity?.toString() },
                          ];
                        })}
                      />
                    </div>
                    <TransactionLabelField
                      label={`${LABEL_CONSTANTS.TOTAL_CHANGED_QUANTITY}:`}
                      value={inventoryTransaction?.totalQuantity?.toString()}
                    />
                  </>
                ) : (
                  <>
                    <TransactionLabelField
                      label={`${TABLE_HEADER_CONSTANTS.ANIMAL_NAME}:`}
                      value={
                        inventoryTransaction?.specimen?.animal?.code
                          ? inventoryTransaction?.specimen?.animal?.code + ' - ' + inventoryTransaction?.specimen?.animal?.name
                          : '-'
                      }
                    />
                    <TransactionLabelField
                      label={`${TABLE_HEADER_CONSTANTS.INVENTORY_LOT_NUMBER}:`}
                      value={getInventoryItemId(inventoryTransaction?.specimen)}
                    />
                    <TransactionLabelField
                      label={`${TABLE_HEADER_CONSTANTS.CHANGED_QUANTITY}:`}
                      value={inventoryTransaction?.changeQuantity?.toString()}
                    />
                  </>
                )}

                <TransactionLabelField label={`${LABEL_CONSTANTS.STATUS}:`} value={inventoryTransaction?.status} />

                <TransactionLabelField label={`${LABEL_CONSTANTS.RESOLUTION}:`} value={inventoryTransaction?.resolution} />

                <div className="form-row expanded-text-area">
                  <label>{LABEL_CONSTANTS.NOTES}:</label>
                  <div className="form-label-container">
                    <label className="form-label extended-transaction-notes">{inventoryTransaction?.notes}</label>
                  </div>
                </div>

                <TransactionLabelField
                  label={`${TRANSACTION_CONSTANTS.TRANSACTION_DATE}:`}
                  value={new Date(inventoryTransaction?.createdDatetime?.toString() as string).toLocaleDateString()}
                />

                <TransactionLabelField
                  label={`${LABEL_CONSTANTS.LAST_UPDATED}:`}
                  value={new Date(inventoryTransaction?.modifiedDatetime?.toString() as string).toLocaleDateString()}
                />

                <TransactionLabelField label={`${LABEL_CONSTANTS.LAST_UPDATED_BY}:`} value={inventoryTransaction?.modifiedBy} />
                {onTransactionManagement &&
                  (bulkOrder ? (
                    <>
                      <h2>{TRANSACTION_CONSTANTS.ASSOCIATED_TRANSACTIONS}</h2>
                      <AssociatedTransactions associatedTransactions={inventoryTransaction?.inventoryTransactions} />
                      <br />
                    </>
                  ) : (
                    <>
                      <h2>{TRANSACTION_CONSTANTS.LINKED_TRANSACTIONS}</h2>
                      <LinkedTransactions transactionId={+transactionId!} />
                      <br />
                    </>
                  ))}

                {onTransactionManagement && (
                  <div className="flex-right edit-transaction-buttons">
                    <button className="button small green" onClick={() => setOnEdit(true)}>
                      {BUTTON_CONSTANTS.EDIT}
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <Loader loaderSize={'small'} pageLoader />
          )}
        </div>
      ) : (
        <TransactionEdit onChange={onChangeHandle} bulkOrder={bulkOrder} filter={filter} filterPreset={filterPreset} />
      )}
    </>
  );
};

export default TransactionView;
