import { useEffect, useRef, useState } from 'react';
import { BackButton } from '../../../components';
import { FilterOption } from '../../../components/custom-input/FilterDropdown';
import ManagementColumns, { ManagementColumnFilterTypes } from '../../../components/management-columns/ManagementColumns';
import AddSpeciesModal from './AddSpeciesModal';
import EditSpeciesModal from './EditSpeciesModal';
import AddBreedModal from './AddBreedModal';
import EditBreedModal from './EditBreedModal';
import { getSpecies } from '../../../api/speciesApi';
import { getBreeds } from '../../../api/breedsApi';
import { getAnimals } from '../../../api/animalsApi';
import { Animal, Breed, PagedResponse, Species } from '../../../types/interfaces';
import { showToast } from '../../../services/toast.service';
import { toastMessages } from '../../../constants/errorMessages';
import './speciesBreeds.scss';

interface SpeciesOption extends Species {
  breedCount?: number;
}

interface BreedOption extends Breed {
  animalCount?: number;
}

const ManageSpeciesBreeds = (): JSX.Element => {
  const [totalSpecies, setTotalSpecies] = useState<number>();
  const [totalBreeds, setTotalBreeds] = useState<number>();

  const [showFilter, setShowFilter] = useState<FilterOption<ManagementColumnFilterTypes>>({
    name: 'Active & Inactive',
    value: ManagementColumnFilterTypes.BOTH,
  });

  const [species, setSpecies] = useState<SpeciesOption[]>([]);
  const [selectedSpecies, setSelectedSpecies] = useState<SpeciesOption>();
  const [newSpeciesId, setNewSpeciesId] = useState<number>();
  const [speciesRefresh, setSpeciesRefresh] = useState<number>(0);

  const [breeds, setBreeds] = useState<BreedOption[]>([]);
  const [selectedBreed, setSelectedBreed] = useState<BreedOption>();
  const [newBreedId, setNewBreedId] = useState<number>();
  const [breedRefresh, setBreedRefresh] = useState<number>(0);

  const [offsetSpecies, setOffsetSpecies] = useState<number>(0);
  const hasAllSpecies: React.MutableRefObject<boolean> = useRef<boolean>(false);
  const isLoadingAdditionalSpecies: React.MutableRefObject<boolean> = useRef<boolean>(false);
  const callNumberSpecies: React.MutableRefObject<number> = useRef<number>(0);
  const [loadingSpecies, setLoadingSpecies] = useState<boolean>(false);

  const [offsetBreeds, setOffsetBreeds] = useState<number>(0);
  const hasAllBreeds: React.MutableRefObject<boolean> = useRef<boolean>(false);
  const isLoadingAdditionalBreeds: React.MutableRefObject<boolean> = useRef<boolean>(false);
  const callNumberBreeds: React.MutableRefObject<number> = useRef<number>(0);
  const [loadingBreeds, setLoadingBreeds] = useState<boolean>(false);

  const limit: number = 10;

  const getTotals = async () => {
    try {
      const [{ data: speciesResponse }, { data: breedsResponse }] = await Promise.all([
        getSpecies({ limit: 1 }),
        getBreeds({ limit: 1 }),
      ]);
      setTotalSpecies((speciesResponse as PagedResponse<Species>).total);
      setTotalBreeds((breedsResponse as PagedResponse<Breed>).total);
    } catch {}
  };

  const fetchSpecies = async () => {
    if (hasAllSpecies.current) {
      return;
    }
    setLoadingSpecies(true);
    callNumberSpecies.current++;
    const callNum = callNumberSpecies.current;
    try {
      const { data: speciesResponse } = await getSpecies({
        filter: showFilter.value,
        limit: limit,
        offset: offsetSpecies,
        sort: 'Name',
      });

      if (callNum === callNumberSpecies.current) {
        const speciesList = (speciesResponse as PagedResponse<Species>).result;
        const newSpecies: Species[] = await Promise.all(
          speciesList.map(async (spec: Species) => {
            const { data: specBreedsResponse } = await getBreeds({ filter: 'speciesId eq ' + spec.speciesId, limit: 1 });
            return { ...spec, breedCount: (specBreedsResponse as PagedResponse<Breed>).total };
          }),
        );
        if (callNum === callNumberSpecies.current) {
          setSpecies(offsetSpecies > 0 ? [...species, ...newSpecies] : newSpecies);
          hasAllSpecies.current = newSpecies.length === 0;
          setLoadingSpecies(false);
        }
      }
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const fetchBreeds = async () => {
    if (hasAllBreeds.current) {
      return;
    }
    setLoadingBreeds(true);
    callNumberBreeds.current++;
    const callNum = callNumberBreeds.current;
    try {
      if (selectedSpecies) {
        const { data: breedsResponse } = await getBreeds({
          filter: showFilter.value + 'speciesId eq ' + selectedSpecies?.speciesId,
          limit: limit,
          offset: offsetBreeds,
          sort: 'Name',
        });
        if (callNum === callNumberBreeds.current) {
          const breedsList = (breedsResponse as PagedResponse<Breed>).result;
          const newBreeds: BreedOption[] = await Promise.all(
            breedsList.map(async (breed: Breed) => {
              const { data: animalResponse } = await getAnimals({ filter: 'breedId eq ' + breed.breedId, limit: 1 });
              return { ...breed, animalCount: (animalResponse as PagedResponse<Animal>).total };
            }),
          );
          setBreeds(offsetBreeds > 0 ? [...breeds, ...newBreeds] : newBreeds);
          hasAllBreeds.current = newBreeds.length === 0;
          setLoadingBreeds(false);
        }
      }
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    getTotals();
  }, [newSpeciesId, newBreedId]);

  useEffect(() => {
    hasAllSpecies.current = false;
    setSpecies([]);
    setSelectedSpecies(undefined);
    if (offsetSpecies === 0) {
      fetchSpecies();
    } else {
      setOffsetSpecies(0);
    }
  }, [showFilter, speciesRefresh]);

  useEffect(() => {
    hasAllBreeds.current = false;
    setBreeds([]);
    setSelectedBreed(undefined);
    if (offsetBreeds === 0) {
      fetchBreeds();
    } else {
      setOffsetBreeds(0);
    }
  }, [selectedSpecies, showFilter, breedRefresh]);

  useEffect(() => {
    fetchSpecies();
  }, [offsetSpecies]);

  useEffect(() => {
    fetchBreeds();
  }, [offsetBreeds]);

  const loadMoreSpecies = () => {
    setOffsetSpecies(offsetSpecies + limit);
  };

  const loadMoreBreeds = () => {
    setOffsetBreeds(offsetBreeds + limit);
  };

  useEffect(() => {
    isLoadingAdditionalSpecies.current = false;
  }, [species]);

  useEffect(() => {
    const getSingleSpecies = async (id: number) => {
      const { data: species } = await getSpecies({
        filter: `SpeciesId eq ${id}`,
      });
      if ((species as Species[]).length > 0) {
        const s = (species as Species[])[0];
        setSelectedSpecies(s);
      }
    };

    if (newSpeciesId) {
      getSingleSpecies(newSpeciesId);
    }
  }, [newSpeciesId]);

  useEffect(() => {
    isLoadingAdditionalBreeds.current = false;
  }, [breeds]);

  useEffect(() => {
    const getSingleBreed = async (id: number) => {
      const { data: breeds } = await getBreeds({
        filter: `BreedId eq ${id}`,
      });
      if ((breeds as Breed[]).length > 0) {
        const s = (breeds as Breed[])[0];
        setSelectedBreed(s);

        setSpecies(
          species.map((spec: SpeciesOption) => {
            if (spec.speciesId === s.speciesId) {
              return { ...spec, breedCount: (spec.breedCount ?? 0) + 1 };
            }
            return spec;
          }),
        );
      }
    };

    if (newBreedId) {
      getSingleBreed(newBreedId);
    }
  }, [newBreedId]);

  return (
    <>
      <BackButton />
      <div className="species-breeds">
        <ManagementColumns
          header="Species & Breeds"
          filter={showFilter}
          setFilter={setShowFilter}
          headerStats={[
            { value: totalSpecies, label: 'Species' },
            { value: totalBreeds, label: 'Breeds' },
          ]}
          columns={[
            {
              header: 'Species',
              items: species,
              selectedItemId: selectedSpecies?.speciesId,
              getItemId: (item: Species) => item.speciesId!,
              getItemAddModal: (close: () => void) => (
                <AddSpeciesModal
                  close={() => {
                    setSpeciesRefresh(p => p + 1);
                    close();
                  }}
                  setSelectedId={setNewSpeciesId}
                />
              ),
              addItemButton: { label: 'Species' },
              getItemLabel: (spec: SpeciesOption) => (
                <>
                  <span className={spec.active ? '' : 'inactive'}>
                    {spec.name}
                    {!spec.active && <>&emsp;(Inactive)</>}
                  </span>
                  {spec.breedCount !== undefined && spec.breedCount > 0 && <>({spec.breedCount})</>}
                </>
              ),
              itemSelect: spec => {
                setSelectedSpecies(spec);
              },
              getItemEditModal: (close: () => void, spec) => (
                <EditSpeciesModal
                  species={spec}
                  close={() => {
                    setSpeciesRefresh(p => p + 1);
                    close();
                  }}
                />
              ),
              scroll: () => {
                if (!isLoadingAdditionalSpecies.current) {
                  isLoadingAdditionalSpecies.current = true;
                  loadMoreSpecies();
                }
              },
              loading: loadingSpecies,
            },
            {
              header: 'Breeds',
              items: breeds,
              selectedItemId: selectedBreed?.breedId,
              getItemId: (item: Breed) => item.breedId!,
              getItemAddModal: (close: () => void) => (
                <AddBreedModal
                  species={selectedSpecies!}
                  close={() => {
                    setBreedRefresh(p => p + 1);
                    close();
                  }}
                  setSelectedId={setNewBreedId}
                />
              ),
              addItemButton: { label: 'Breeds' },
              getItemLabel: (breed: BreedOption) => (
                <>
                  <span className={breed.active ? '' : 'inactive'}>
                    {breed.name}
                    {!breed.active && <>&emsp;(Inactive)</>}
                  </span>

                  {breed.animalCount !== undefined && breed.animalCount > 0 && <>({breed.animalCount})</>}
                </>
              ),
              itemSelect: breed => {
                setSelectedBreed(breed);
              },
              getItemEditModal: (close: () => void, breed) => (
                <EditBreedModal
                  breed={breed}
                  species={selectedSpecies!}
                  close={() => {
                    setSelectedSpecies(undefined);
                    setBreedRefresh(p => p + 1);
                    close();
                  }}
                />
              ),
              scroll: () => {
                if (!isLoadingAdditionalBreeds.current) {
                  isLoadingAdditionalBreeds.current = true;
                  loadMoreBreeds();
                }
              },
              loading: loadingBreeds && selectedSpecies !== undefined,
            },
          ]}
        />
      </div>
    </>
  );
};

export default ManageSpeciesBreeds;
