import { FC, useEffect, useState } from 'react';
import ValidatedInput, { ValidatedState } from './ValidatedInput';
import { Validators } from '../../types/enums';
import { Address } from '../../types/interfaces';

interface ValidatedAddressProps {
  validate: boolean;
  setValid: (valid: boolean) => void;
  setAddress: (address: Address) => void;
}

const ValidatedAddress: FC<ValidatedAddressProps> = ({ validate, setValid, setAddress }): JSX.Element => {
  const [address1Input, setAddress1Input] = useState<ValidatedState>({
    value: '',
    valid: false,
  });
  const [address2Input, setAddress2Input] = useState<ValidatedState>({
    value: '',
    valid: false,
  });
  const [cityInput, setCityInput] = useState<ValidatedState>({
    value: '',
    valid: false,
  });
  const [stateInput, setStateInput] = useState<ValidatedState>({
    value: '',
    valid: false,
  });
  const [zipcodeInput, setZipcodeInput] = useState<ValidatedState>({
    value: '',
    valid: false,
  });
  const [countryInput, setCountryInput] = useState<ValidatedState>({
    value: '',
    valid: false,
  });

  useEffect(() => {
    setValid(address1Input.valid && cityInput.valid && stateInput.valid && zipcodeInput.valid && countryInput.valid);
    setAddress({
      streetAddress: address1Input.value,
      streetAddress2: address2Input.value,
      city: cityInput.value,
      state: stateInput.value,
      postalCode: zipcodeInput.value,
      country: countryInput.value,
    });
  }, [address1Input, address2Input, cityInput, stateInput, zipcodeInput, countryInput]);

  return (
    <div>
      <div className="form-row">
        <ValidatedInput
          useId="address-1"
          label="*Address Line 1:"
          type="text"
          validate={validate}
          setValidatedState={setAddress1Input}
          validators={[Validators.REQUIRED]}
        />
      </div>
      <div className="form-row">
        <ValidatedInput
          useId="address-2"
          label="Address Line 2:"
          type="text"
          validate={validate}
          setValidatedState={setAddress2Input}
          validators={[]}
        />
      </div>
      <div className="form-row">
        <ValidatedInput
          useId="city"
          label="*City:"
          type="text"
          validate={validate}
          setValidatedState={setCityInput}
          validators={[Validators.REQUIRED]}
        />
      </div>
      <div className="form-row">
        <ValidatedInput
          useId="State"
          label="*State:"
          type="text"
          validate={validate}
          setValidatedState={setStateInput}
          validators={[Validators.REQUIRED]}
        />
      </div>
      <div className="form-row">
        <ValidatedInput
          useId="zipcode"
          label="*Zip Code:"
          type="text"
          validate={validate}
          setValidatedState={setZipcodeInput}
          validators={[Validators.REQUIRED, Validators.ZIP_CODE]}
        />
      </div>
      <div className="form-row">
        <ValidatedInput
          useId="country"
          label="*Country:"
          type="text"
          validate={validate}
          setValidatedState={setCountryInput}
          validators={[Validators.REQUIRED]}
        />
      </div>
    </div>
  );
};

export default ValidatedAddress;
