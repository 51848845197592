import { FC, useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { Loader } from '../../components';
import AnimalCard from '../../components/animal/AnimalCard';
import FilterDropdown, { FilterOption } from '../../components/custom-input/FilterDropdown';
import { PlusCircleSvg, FilterIconSvg } from '../../components/svgs/index';
import FilterInventoryModal from './filter-inventory-modal/FilterInventoryModal';
import AddInventoryModal from './add-inventory-modal/AddInventoryModal';
import { showToast } from '../../services/toast.service';
import { PermissionService } from '../../services/permission.service';
import { getSpecies } from '../../api/speciesApi';
import { getBreedsOfSpecies } from '../../api/breedsApi';
import { getAllAccountAnimals } from '../../api/animalsApi';
import { getInventoryCount } from '../../api/inventoryApi';
import { getSpecimenTypes } from '../../api/specimensTypesApi';
import { PagedResponse } from '../../types/interfaces/apiParams.interfaces';
import { SpecimenType } from '../../types/interfaces/specimen.interfaces';
import { Animal, Breed, Species } from '../../types/interfaces/animal.interfaces';
import { PromiseError, toastMessages } from '../../constants/errorMessages';
import { ANIMAL_CONSTANTS, BUTTON_CONSTANTS, LABEL_CONSTANTS, PAGE_HEADER_CONSTANTS } from '../../constants/common';

export interface filtersType {
  search: string;
  type: FilterOption<number | null>;
  species: FilterOption<number | null>;
  breed: FilterOption<number | null>;
  sort: FilterOption<string | null>;
}

type InventoryProps = {
  permissionService: PermissionService;
};

const Inventory: FC<InventoryProps> = ({ permissionService }): JSX.Element => {
  const pagingSize = 8;
  const { accountId } = useAppSelector(state => state.activeAccount);
  const paymentStatus = useAppSelector(state => state.payment.addedPaymentMethod);
  const activeAccount = useAppSelector(state => state.activeAccount);
  const accountLocked = useAppSelector(state => state.activeAccount.locked);

  const [animals, setAnimals] = useState<Animal[]>([]);
  const [totalUnits, setTotalUnits] = useState<number>();
  const [pagingOffset, setPagingOffset] = useState<number>(0);
  const [pagingMax, setPagingMax] = useState<number>(0);
  const [isOpenAddInventoryModal, setIsOpenAddInventoryModal] = useState<boolean>(false);
  const [typeOptions, setTypeOptions] = useState<FilterOption<number | null>[]>([{ name: 'All', value: null }]);
  const [speciesOptions, setSpeciesOptions] = useState<FilterOption<number | null>[]>([{ name: 'All', value: null }]);
  const [breedOptions, setBreedOptions] = useState<FilterOption<number | null>[]>([{ name: 'All', value: null }]);
  const [isOpenFilterInventoryModal, setIsOpenFilterInventoryModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [filters, setFilters] = useState<filtersType>({
    search: '',
    type: { name: '', value: null },
    species: { name: '', value: null },
    breed: { name: '', value: null },
    sort: { name: '', value: null },
  });

  const sortOptions: FilterOption<string | null>[] = [
    { name: 'All', value: null },
    { name: 'Name (A-Z)', value: 'Name' },
    { name: 'Name (Z-A)', value: '^Name' },
    { name: 'Youngest', value: '^birthDate' },
    { name: 'Eldest', value: 'birthDate' },
  ];

  const handleGetSpecimenTypes = useCallback(async () => {
    try {
      const response = await getSpecimenTypes();
      const newType = response.data as SpecimenType[];
      setTypeOptions([
        { name: 'All', value: null },
        ...newType.map((specimenType: SpecimenType) => {
          return { name: specimenType.name!, value: specimenType.specimenTypeId };
        }),
      ]);
    } catch (error: any) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  }, []);

  const handleGetAllSpecies = useCallback(async () => {
    try {
      const response = await getSpecies();
      const newSpecies = response.data as Species[];
      setSpeciesOptions([
        { name: 'All', value: null },
        ...newSpecies.map((species: any) => {
          return { name: species.name, value: species.speciesId };
        }),
      ]);
    } catch (error: any) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  }, []);

  const handleGetBreedOptions = useCallback(async (speciesId: number) => {
    if (speciesId === null) {
      setBreedOptions([{ name: 'All', value: null }]);
    } else
      try {
        const response = await getBreedsOfSpecies(speciesId);
        const newBreeds = response.data as Breed[];
        setBreedOptions([
          { name: 'All', value: undefined },
          ...newBreeds.map((breeds: any) => {
            return { name: breeds.name, value: breeds.breedId };
          }),
        ]);
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
  }, []);

  useEffect(() => {
    handleGetSpecimenTypes();
  }, [handleGetSpecimenTypes]);

  useEffect(() => {
    handleGetAllSpecies();
  }, [handleGetAllSpecies]);

  useEffect(() => {
    getInventoryCount({ accountId: accountId })
      .then(e => {
        setTotalUnits(e.data);
      })
      .catch();
  }, []);

  useEffect(() => {
    fetchItems(0, []);
  }, [filters]);

  const fetchItems = async (offset = pagingOffset, currentAnimals = animals) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const response = await getAllAccountAnimals(accountId as number, {
        include: 'Breed.Species, Attachments',
        limit: pagingSize,
        offset: offset,
        sort: filters.sort.value ?? undefined,
        searchString: filters.search.length > 0 ? filters.search : undefined,
        breedId: filters.breed.value ?? undefined,
        speciesId: filters.species.value ?? undefined,
        specimenTypeId: filters.type.value ?? undefined,
      });

      const pagedResponse: PagedResponse<Animal> = response.data as PagedResponse<Animal>;
      const newAnimals = pagedResponse.result;

      setAnimals([...currentAnimals, ...newAnimals]);
      setPagingMax(pagedResponse.total);
      setPagingOffset(Math.min(offset + pagingSize, pagedResponse.total));
      setIsLoading(false);
    } catch (error: any) {
      const errorCode = error.code;
      if (errorCode === PromiseError.NETWORK) {
        showToast.error(toastMessages.NETWORK);
      } else if (errorCode === PromiseError.BAD_REQUEST) {
        showToast.error(PromiseError.BAD_REQUEST);
      } else {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
      setIsLoading(false);
    }
  };

  const checkPaymentValidity = () => {
    return (
      paymentStatus != undefined &&
      (!paymentStatus || activeAccount.billingAmount?.outstandingBalanceCents > 0 || activeAccount.billingAmount.penaltyCents > 0)
    );
  };

  return (
    <div>
      <div className="inventory">
        <div className="inventory-overview">
          <div>
            <h1>{PAGE_HEADER_CONSTANTS.MY_INVENTORY}</h1>
            <p>
              <span>{totalUnits}</span>
              {LABEL_CONSTANTS.TOTAL_UNITS}
            </p>
          </div>
          {permissionService.userHasInventoryPermission() && (
            <button
              className="button outlined"
              onClick={() => setIsOpenAddInventoryModal(true)}
              disabled={checkPaymentValidity() || accountLocked}>
              <PlusCircleSvg />
              <p>{BUTTON_CONSTANTS.ADD_INVENTORY}</p>
            </button>
          )}
        </div>
        <div className="inventory-search search-dropdown show-icon">
          <button className="mobile-view" onClick={() => setIsOpenFilterInventoryModal(true)}>
            <FilterIconSvg />
          </button>
          <div className="search-input-wrapper">
            <input
              type="text"
              placeholder="Search"
              value={filters.search}
              onChange={e => setFilters({ ...filters, search: e.target.value })}
            />
          </div>
        </div>
        <div className="filters desktop-view">
          <div className="filter-option">
            <label>{LABEL_CONSTANTS.SPECIMEN_TYPE}</label>
            <FilterDropdown
              placeholder="All"
              options={typeOptions}
              value={filters.type}
              onChange={type => setFilters({ ...filters, type: type })}
            />
          </div>
          <div className="filter-option">
            <label>{ANIMAL_CONSTANTS.SPECIES}</label>
            <FilterDropdown
              placeholder="All"
              options={speciesOptions}
              value={filters.species}
              onChange={species => {
                handleGetBreedOptions(species.value);
                setFilters({ ...filters, breed: { name: '', value: null }, species: species });
              }}
            />
          </div>
          <div className="filter-option">
            <label>{ANIMAL_CONSTANTS.BREED}</label>
            <FilterDropdown
              placeholder="All"
              options={breedOptions}
              value={filters.breed}
              onChange={breed => setFilters({ ...filters, breed: breed })}
            />
          </div>
          <div className="filter-option">
            <label>{LABEL_CONSTANTS.SORT_BY}</label>
            <FilterDropdown
              placeholder="All"
              options={sortOptions}
              value={filters.sort}
              onChange={sort => setFilters({ ...filters, sort: sort })}
            />
          </div>
        </div>
        <div className="gallery">
          <div className="gallery-heading">
            <h2>{LABEL_CONSTANTS.VIEWING}</h2>
            <p>
              {pagingOffset} of {pagingMax}
            </p>
          </div>
          {!isLoading && animals?.length === 0 ? (
            <div className="result-item">{LABEL_CONSTANTS.NO_INVENTORY}</div>
          ) : (
            <div className="items">
              {animals.map((animal: Animal) => {
                return (
                  <div key={'animal-card-id-' + animal.animalId}>
                    <AnimalCard animal={animal} accountId={accountId} />
                  </div>
                );
              })}
            </div>
          )}
          {isLoading && <Loader loaderSize="medium" appLoader />}
          {pagingOffset < pagingMax && (
            <button className="button blue" onClick={() => fetchItems()}>
              {BUTTON_CONSTANTS.VIEW_MORE}
            </button>
          )}
        </div>
      </div>
      {isOpenAddInventoryModal && <AddInventoryModal isOpen={isOpenAddInventoryModal} setIsOpen={setIsOpenAddInventoryModal} />}
      {isOpenFilterInventoryModal && (
        <FilterInventoryModal
          isOpen={isOpenFilterInventoryModal}
          setIsOpen={setIsOpenFilterInventoryModal}
          speciesOptions={speciesOptions}
          breedOptions={breedOptions}
          handleGetBreedOptions={handleGetBreedOptions}
          setFilters={setFilters}
        />
      )}
    </div>
  );
};

export default Inventory;
