import Modal from '../../../../components/modal/Modal';
import './deactivateProfileModal.scss';

export type ConfirmModalProps = {
  isOpen: boolean;
  width?: number;
  onClose: () => void;
};

function DeactivateProfileWarningModal({ isOpen, width = 700, onClose }: ConfirmModalProps) {
  return (
    <div className="deactivate-profile-modal">
      <Modal width={width} isOpen={isOpen} onClose={onClose} ignoreBackdrop>
        <h2 className="modal-header">Deactivate Profile</h2>
        <p className="modal-body">
          Deactivating your profile is unavailable at this time. For accounts that you own, inventory must be zero and must not
          have an animal ownership collection contract with RSG. If you need assistance, please contact us.
        </p>
        <div className="modal-footer">
          <button type="button" className="medium green button" onClick={onClose}>
            Okay
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default DeactivateProfileWarningModal;
