const CancelSVG = (): JSX.Element => {
  return (
    <svg width="17" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8767 0.637291C16.7611 0.521412 16.6237 0.429477 16.4725 0.366751C16.3213 0.304024 16.1592 0.271736 15.9955 0.271736C15.8318 0.271736 15.6697 0.304024 15.5185 0.366751C15.3672 0.429477 15.2299 0.521412 15.1142 0.637291L9.00174 6.73729L2.88924 0.624791C2.77352 0.509064 2.63613 0.417264 2.48492 0.354632C2.33372 0.292001 2.17166 0.259766 2.00799 0.259766C1.84433 0.259766 1.68227 0.292001 1.53107 0.354632C1.37986 0.417264 1.24247 0.509064 1.12674 0.624791C1.01102 0.740518 0.919217 0.877907 0.856585 1.02911C0.793954 1.18032 0.761719 1.34238 0.761719 1.50604C0.761719 1.6697 0.793954 1.83177 0.856585 1.98297C0.919217 2.13418 1.01102 2.27156 1.12674 2.38729L7.23924 8.49979L1.12674 14.6123C1.01102 14.728 0.919217 14.8654 0.856585 15.0166C0.793954 15.1678 0.761719 15.3299 0.761719 15.4935C0.761719 15.6572 0.793954 15.8193 0.856585 15.9705C0.919217 16.1217 1.01102 16.2591 1.12674 16.3748C1.24247 16.4905 1.37986 16.5823 1.53107 16.6449C1.68227 16.7076 1.84433 16.7398 2.00799 16.7398C2.17166 16.7398 2.33372 16.7076 2.48492 16.6449C2.63613 16.5823 2.77352 16.4905 2.88924 16.3748L9.00174 10.2623L15.1142 16.3748C15.23 16.4905 15.3674 16.5823 15.5186 16.6449C15.6698 16.7076 15.8318 16.7398 15.9955 16.7398C16.1592 16.7398 16.3212 16.7076 16.4724 16.6449C16.6236 16.5823 16.761 16.4905 16.8767 16.3748C16.9925 16.2591 17.0843 16.1217 17.1469 15.9705C17.2095 15.8193 17.2418 15.6572 17.2418 15.4935C17.2418 15.3299 17.2095 15.1678 17.1469 15.0166C17.0843 14.8654 16.9925 14.728 16.8767 14.6123L10.7642 8.49979L16.8767 2.38729C17.3517 1.91229 17.3517 1.11229 16.8767 0.637291Z"
        fill="#545556"
      />
    </svg>
  );
};
export default CancelSVG;
