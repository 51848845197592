import { tempId } from '../../utils/addressHelpers';
import { AddressType } from '../enums/addressType.enum';

export interface Address {
  addressId?: number;
  type?: AddressType;
  streetAddress: string;
  streetAddress2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  phone?: string;
}

export const emptyAddress: Address = {
  addressId: tempId(),
  streetAddress: '',
  type: AddressType.DEFAULT,
  streetAddress2: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
  phone: '',
};

export const streetAddress2Key = 'streetAddress2';
export const phoneKey = 'phone';
