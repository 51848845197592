import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { storeAppAuth } from '../../redux/reducers/authSlice';
import { useAppDispatch } from '../../redux/hooks';
import { transientAuth } from '../../api/authApi';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { toastMessages } from '../../constants/errorMessages';
import { showToast } from '../../services/toast.service';

const ResetPasswordLink = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    transientAuth({
      email: searchParams.get('state') ?? '',
      password: searchParams.get('code') ?? '',
    })
      .then((e: any) => {
        dispatch(storeAppAuth(e.data));
        navigate(ROUTE_PATHS.RESET_PASSWORD);
      })
      .catch(() => {
        navigate(ROUTE_PATHS.SIGN_IN);
        showToast.error(toastMessages.EXPIRED_INCORRECT_LINK);
      });
  }, [searchParams, dispatch, navigate]);

  return <></>;
};

export default ResetPasswordLink;
