import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PagedTable from '../../components/sorted-table/PagedTable';
import { GetVariancesResponse } from '../../types/interfaces';
import { showToast } from '../../services/toast.service';
import { getVariances } from '../../api/specimensApi';
import { toastMessages } from '../../constants/errorMessages';
import { LABEL_CONSTANTS, PAGE_HEADER_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../constants/common';

const InventoryManagementReconcile = (): JSX.Element => {
  const [inventoryDiscrepancies, setInventoryDiscrepancies] = useState<GetVariancesResponse[]>([]);

  const handleGetVariances = useCallback(async () => {
    try {
      const specimenLocations = (await getVariances()).data;
      setInventoryDiscrepancies(specimenLocations);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  }, []);

  useEffect(() => {
    handleGetVariances();
  }, [handleGetVariances]);

  return (
    <div className="inventory-management inventory-management-variances">
      <div className="header">
        <hr />
        <h1>{PAGE_HEADER_CONSTANTS.RECONCILE}</h1>
        <hr />
      </div>
      <div className="desk-inventory-management-variances">
        <PagedTable
          headers={[
            { displayName: TABLE_HEADER_CONSTANTS.LOT_DATE_NO },
            { displayName: TABLE_HEADER_CONSTANTS.ANIMAL_CODE_NAME },
            { displayName: TABLE_HEADER_CONSTANTS.ORIGINAL_QTY },
            { displayName: TABLE_HEADER_CONSTANTS.LEDGER_QTY },
            { displayName: TABLE_HEADER_CONSTANTS.CANISTER_QTY },
            { displayName: TABLE_HEADER_CONSTANTS.DIFFERENCE },
            { displayName: TABLE_HEADER_CONSTANTS.ACTION },
          ]}
          getData={getVariances}
          pageSize={10}
          height={500}
          buildRow={(item: GetVariancesResponse) => {
            return [
              `${new Date(item.freezeDate).toLocaleDateString()} - ${item.specimenId}`,
              `${item.animalCode} - ${item.animalName}`,
              item.originalQuantity,
              item.quantityOwned,
              item.canisterCount,
              item.variance,
              <Link to={item.specimenId.toString()}>{LABEL_CONSTANTS.RECONCILE}</Link>,
            ];
          }}
        />
      </div>

      {/** Mobile View */}
      <div className="xs-inventory-management-variances">
        {inventoryDiscrepancies.length > 0 &&
          inventoryDiscrepancies &&
          inventoryDiscrepancies.map((inventoryDiscrepancyItem, index) => {
            return (
              <div className="card max-width" key={inventoryDiscrepancyItem.animalCode + index}>
                <div className="inventory-variances">
                  <div className="inventory-variances-content">
                    <div className="card-content-section">
                      <div className="left">
                        <label>{`${TABLE_HEADER_CONSTANTS.ANIMAL_CODE_NAME}: ${inventoryDiscrepancyItem.animalCode} - ${inventoryDiscrepancyItem.animalName}`}</label>
                      </div>
                      <div className="right">
                        <label>{`${TABLE_HEADER_CONSTANTS.LOT_DATE_NO}: ${new Date(
                          inventoryDiscrepancyItem.freezeDate,
                        ).toLocaleDateString()} - ${inventoryDiscrepancyItem.specimenId}`}</label>
                      </div>
                    </div>
                    <div className="card-content-section">
                      <div className="left">
                        <label>{`${TABLE_HEADER_CONSTANTS.OWNED_QTY}: ${inventoryDiscrepancyItem.quantityOwned}`}</label>
                      </div>
                      <div className="left right">
                        <label>{`${TABLE_HEADER_CONSTANTS.CANISTER_QTY}: ${inventoryDiscrepancyItem?.canisterCount}`}</label>
                      </div>
                      <div className="right">
                        <label>{`${LABEL_CONSTANTS.VARIANCE}: ${inventoryDiscrepancyItem?.variance}`}</label>
                      </div>
                    </div>
                  </div>
                  <Link className="reconcile-link" to={inventoryDiscrepancyItem.specimenId.toString()}>
                    {LABEL_CONSTANTS.RECONCILE}
                  </Link>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default InventoryManagementReconcile;
