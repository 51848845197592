import Modal from '../modal/Modal';
import './deactivateAccountModal.scss';

export type DeactivateAccountModalProps = {
  isOpen: boolean;
  width?: number;
  onClose: () => void;
  onConfirm: () => void;
};

const DeactivateAccountModal = ({ isOpen, width = 600, onClose, onConfirm }: DeactivateAccountModalProps) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} width={width} ignoreBackdrop>
        <div className="deactivate-account-modal">
          <div className="header">
            <h4>Deactivate Account</h4>
          </div>
          <div className="body">
            <p>All users will be disassociated from the account. Are you sure you want to continue?</p>
          </div>
          <div className="footer">
            <button className="button blue inverted small" onClick={onClose}>
              Cancel
            </button>
            <button className="button red small" onClick={onConfirm}>
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeactivateAccountModal;
