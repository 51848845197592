import { FC } from 'react';
import Modal from '../modal/Modal';
import { SpecimenCustomDataValue } from '../../types/interfaces/specimenCustomDataKeyModel.interfaces';
import { Specimen } from '../../types/interfaces';
import { dateFormatMMDDYYYY } from '../../utils/commonUtils';
import './quickView.scss';

type QuickViewProps = {
  specimen: Specimen;
  lotDateNumber?: string;
  width?: number;
  height?: number;
  notes?: string;
  customData?: SpecimenCustomDataValue[];
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  onClose: () => void;
};

const QuickView: FC<QuickViewProps> = ({
  specimen,
  lotDateNumber = `${dateFormatMMDDYYYY(specimen.freezeDate) ?? 'Date Error'} - ${specimen.specimenId!}`,
  notes = specimen.notes,
  customData = specimen.specimenCustomDataValues,
  isOpen = false,
  onClose,
  setIsOpen,
  width = 446,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} width={width} ignoreBackdrop>
      <div className="quick-view-container">
        <div className="header">
          <h2 id="qv-title">Quick View</h2>
          <hr />
        </div>

        <div className="row">
          <div className="l-column">Lot Date / Number: </div>
          <div className="r-column">{lotDateNumber}</div>
        </div>

        {Boolean(customData.length) &&
          customData.map((data: SpecimenCustomDataValue) => {
            if (data.specimenCustomDataKey)
              return (
                <div className="row" key={data.specimenCustomDataKeyId + '-' + data.specimenId}>
                  <div className="l-column" key={data.specimenCustomDataKey.keyName + '-' + data.specimenId}>
                    {data.specimenCustomDataKey.keyName}:{' '}
                  </div>
                  <div className="r-column" key={data.value + '-' + data.specimenId}>
                    {data.value}
                  </div>
                </div>
              );
          })}
        <div className="notes">
          <p>Notes:</p>
          <p>{notes}</p>
        </div>
        <button className="button green" id="close-qv-button" onClick={() => setIsOpen(false)}>
          Close
        </button>
      </div>
    </Modal>
  );
};

export default QuickView;
