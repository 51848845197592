import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FilterOption } from '../custom-input/FilterDropdown';
import ToggleSwitch from '../custom-input/ToggleSwitch';
import FilterBy from '../filter-by/FilterBy';
import SearchBy from '../search-by/SearchBy';
import FilterByModal from '../filter-by/FilterByModal';
import { FilterSVG, ClearFilterSVG, PlusCircleSvg } from '../svgs';
import { dateFilter } from '../../utils/dateHandler';
import { getAccounts } from '../../api/accountApi';
import { Account, PagedResponse } from '../../types/interfaces';
import { TransactionTypeEnum } from '../../types/enums';
import { TRANSACTION_MANAGEMENT_PATHS } from '../../constants/routePaths';
import { LABEL_CONSTANTS, TRANSACTION_CONSTANTS } from '../../constants/common';
import './transactionsList.scss';

export interface TransactionFilterState {
  type: string;
  status: string;
  resolution: string;
  createdDatetime: Date | undefined;
  dateWasModified: boolean;
  search: { criteria: FilterOption<TransactionFilterOption>; query: string };
}

interface TransactionsListProps {
  searchCriteriaOptions: FilterOption<TransactionFilterOption>[];
  header: string;
  setFilter: (filters: string) => void;
  filterPreset?: TransactionFilterState;
  setFilterPreset?: (filters: TransactionFilterState) => void;
  children?: React.ReactNode | React.ReactNode[];
  isOData?: boolean;
  includeAdminFilters?: boolean;
  showBulkTransactions?: boolean;
  onToggleShowBulkTransactions?: () => void;
}

export interface TransactionFilterOption {
  parameter: string;
  isNumber?: boolean;
}

const defaultFilterPreset = {
  type: '',
  status: '',
  resolution: '',
  createdDatetime: undefined,
  dateWasModified: false,
  search: { criteria: { name: '', value: { parameter: '' } }, query: '' },
};

const TransactionsList: FC<TransactionsListProps> = ({
  searchCriteriaOptions,
  header,
  setFilter,
  setFilterPreset = (filters): void => {},
  filterPreset = defaultFilterPreset,
  isOData = false,
  includeAdminFilters = false,
  children,
  showBulkTransactions,
  onToggleShowBulkTransactions,
}): JSX.Element => {
  const [filterState, setFilterState] = useState<TransactionFilterState>(filterPreset);
  const [isOpenFilterByModal, setIsOpenFilterByModal] = useState<boolean>(false);
  const [onToggle, setOnToggle] = useState<boolean>(false);

  const navigate = useNavigate();

  const updateFilters = useCallback(async () => {
    let filters: string[] = [];

    if (!includeAdminFilters) {
      filters = [
        `transactionType ne '${TransactionTypeEnum.CheckIn}' and transactionType ne '${TransactionTypeEnum.CheckOut}' and transactionType ne '${TransactionTypeEnum.Adjust}' and transactionType ne '${TransactionTypeEnum.Move}'`,
      ];
    }

    if (filterState.type) {
      filters = [...filters, `transactionType eq '${filterState.type}'`];
    }

    if (filterState.status) {
      filters = [...filters, `status eq '${filterState.status}'`];
    }

    if (filterState.resolution) {
      filters = [...filters, `resolution eq '${filterState.resolution}'`];
    }

    if (filterState.createdDatetime && filterState.dateWasModified) {
      dateFilter(filterState.dateWasModified, filterState.createdDatetime);
      filters = [...filters, dateFilter(filterState.dateWasModified, filterState.createdDatetime)];
    }

    const minSearchLength = 2;
    if (filterState.search.query.length > 0) {
      if (filterState.search.criteria.value.parameter === 'accountId' && filterState.search.query.length >= minSearchLength) {
        try {
          const maxResults = 5;
          const { data: accountsResponse } = await getAccounts(undefined, {
            filter:
              'contactEmail like ' +
              filterState.search.query +
              '||name like ' +
              filterState.search.query +
              '||contactFirstName like ' +
              filterState.search.query +
              '||contactLastName like ' +
              filterState.search.query,
            limit: maxResults,
          });
          const accounts: Account[] = (accountsResponse as PagedResponse<Account>).result;
          if (accounts.length === 0) {
            setFilter('false');
            return;
          }
          filters = [...filters, accounts.map(acc => `accountId eq ${acc.accountId}`).join(' or ')];
        } catch (error) {
          setFilter('false');
          return;
        }
      } else {
        if (filterState.search.criteria.value.isNumber === true) {
          if ((+filterState.search.query).toString() === 'NaN') {
            setFilter('false');
            return;
          } else {
            if (showBulkTransactions && filterState.search.criteria.name != TRANSACTION_CONSTANTS.TRANSACTION_ID) {
              filters = [
                ...filters,
                `InventoryTransactions/any(it: it/${filterState.search.criteria.value.parameter} eq ${+filterState.search
                  .query})`,
              ];
            } else {
              filters = [...filters, `${filterState.search.criteria.value.parameter} eq ${+filterState.search.query}`];
            }
          }
        } else if (filterState.search.query.length >= minSearchLength) {
          if (isOData) {
            if (showBulkTransactions) {
              filters = [
                ...filters,
                `InventoryTransactions/any(it:contains(it/${filterState.search.criteria.value.parameter}, '${filterState.search.query}'))`,
              ];
            } else {
              filters = [...filters, `contains(${filterState.search.criteria.value.parameter}, '${filterState.search.query}')`];
            }
          } else {
            filters = [...filters, `${filterState.search.criteria.value.parameter} like ${filterState.search.query}`];
          }
        }
      }
    }

    const rawFilterString = filters.join(isOData ? ' and ' : ';');
    const filterString = isOData ? rawFilterString : rawFilterString.replace(/'/g, '');
    setFilter(filterString);
  }, [filterState]);

  useEffect(() => {
    updateFilters();
    setFilterPreset(filterState);
    if (onToggle) {
      onToggleShowBulkTransactions != undefined && onToggleShowBulkTransactions();
      setOnToggle(false);
    }
  }, [filterState]);

  const onToggleTransactions = () => {
    setFilterState({ ...filterState, search: { criteria: { name: '', value: { parameter: '' } }, query: '' } });
    setOnToggle(true);
  };

  return (
    <div className="transaction-list">
      <div className="header">
        <h2 className="transaction-header">{header}</h2>
        <div className="search-stack">
          <div className="search">
            <SearchBy
              search={filterState.search}
              setSearch={search => setFilterState({ ...filterState, search: search })}
              searchCriteriaOptions={searchCriteriaOptions}
            />
          </div>
        </div>
      </div>

      <hr className="header-hr" />

      <div className="filter-row">
        <FilterBy
          type={filterState.type}
          setType={type => setFilterState({ ...filterState, type: type })}
          status={filterState.status}
          setStatus={status => setFilterState({ ...filterState, status: status })}
          resolution={filterState.resolution}
          setResolution={resolution => setFilterState({ ...filterState, resolution: resolution })}
          date={filterState.createdDatetime}
          setDate={e => setFilterState({ ...filterState, createdDatetime: e, dateWasModified: true })}
          showBulkTransactions={showBulkTransactions}
          onToggleShowBulkTransactions={onToggleTransactions}
          includeAdminFilters={includeAdminFilters}
          resetFilters={() => setFilterState(defaultFilterPreset)}
        />
      </div>

      <div className="xs-filter-row">
        {includeAdminFilters && (
          <>
            <div className="create-transaction">
              <button
                className="button outlined"
                onClick={() => navigate(TRANSACTION_MANAGEMENT_PATHS.CREATE_CLIENT_TRANSACTION)}>
                <PlusCircleSvg />
                &nbsp;&nbsp;Create&nbsp;Client&nbsp;Transaction
              </button>
            </div>
            {onToggleShowBulkTransactions != undefined && showBulkTransactions != undefined && (
              <ToggleSwitch label="Bulk Transactions" checked={showBulkTransactions} onToggle={onToggleTransactions} />
            )}
            <hr className="header-hr" />
          </>
        )}

        <div className="filter-icon-row">
          <p onClick={() => setIsOpenFilterByModal(true)}>
            <FilterSVG />
            <label>{LABEL_CONSTANTS.FILTER_BY}</label>
          </p>
          <div className="reset-filters-button flex-right" onClick={() => setFilterState(defaultFilterPreset)}>
            <ClearFilterSVG />
            <label>{LABEL_CONSTANTS.RESET_FILTERS}</label>
          </div>
        </div>

        {isOpenFilterByModal && (
          <FilterByModal
            isOpen={isOpenFilterByModal}
            onClose={() => setIsOpenFilterByModal(false)}
            filterState={filterState}
            setFilterState={setFilterState}
            includeAdminFilters={includeAdminFilters}
            defaultFilterPreset={defaultFilterPreset}
          />
        )}
      </div>

      {children}
    </div>
  );
};

export default TransactionsList;
