import { FC } from 'react';
import Loader from '../loader/Loader';
import './gaugeChart.scss';

type gaugeLevel = {
  lower: number;
  upper?: number;
  label: string;
};

const GaugeChart: FC<{
  width?: number;
  height?: number;
  data: gaugeLevel[];
  value: number;
  loading?: boolean;
}> = ({ width = 128, height = 128, data, value, loading = false }): JSX.Element => {
  const availableValue = data.filter(level => {
    return value >= level.lower && level.upper && value <= level.upper;
  });
  const minAngle = -120;
  const maxAngle = 120;
  const angleRange = maxAngle - minAngle;
  const sectionSize: number = angleRange / data.length;

  const getLabels = () => {
    return (
      <>
        {data.map((section, index) => {
          const angle = index * sectionSize + sectionSize / 2;

          const minOffset = 270 + minAngle;
          const centerX: number = width / 2;
          const centerY: number = height / 2;
          const distanceFromCenter = 0.9;

          const labelX: number = centerX + distanceFromCenter * centerX * Math.cos(((angle + minOffset) / 180) * Math.PI);
          const labelY: number = centerX + distanceFromCenter * centerX * Math.sin(((angle + minOffset) / 180) * Math.PI);
          return (
            <div key={'gague-label-' + index} className="gauge-label-container" style={{ left: labelX, top: labelY }}>
              <div className="gauge-label">{section.label}</div>
            </div>
          );
        })}
      </>
    );
  };

  const getSections = () => {
    {
      return data.map((section, index) => {
        const dividerRotation: number = sectionSize * index + minAngle;
        return (
          <div key={'gague-section-' + index}>
            {index > 0 && <div className="divider" style={{ transform: `rotate(${dividerRotation}deg)` }} />}
          </div>
        );
      });
    }
  };

  const getNeedle = () => {
    return (
      <>
        {data.map((section, index) => {
          let rotation;
          if (value >= section.lower) {
            if (section.upper && value <= section.upper) {
              rotation =
                minAngle + sectionSize * index + ((value - section.lower) / (section.upper - section.lower)) * sectionSize;
            } else if (index === data.length - 1) {
              const magnitude = (value - section.lower) / section.lower;
              rotation = minAngle + sectionSize * index + (magnitude / (magnitude + 1)) * sectionSize;
            }
          }
          return rotation !== undefined ? (
            <div key={'gague-needle-' + index} className="needle" style={{ transform: `rotate(${rotation}deg)` }} />
          ) : (
            <div key={'no-gague-needle-' + index} />
          );
        })}
      </>
    );
  };

  return (
    <div className="gauge-chart" style={{ width: width, height: height }}>
      {loading ? (
        <Loader loaderSize="small" simple />
      ) : (
        <>
          <div className="outer-edge">
            <div className="inner-edge">
              <div className="value">{value}</div>
              <div className="available-value">{availableValue.length > 0 && <>Out of {availableValue[0].upper}</>}</div>
            </div>
            {getSections()}
            {getNeedle()}
          </div>
          {getLabels()}
        </>
      )}
    </div>
  );
};

export default GaugeChart;
