export const enum AccountRoles {
  AccountOwner = 'AccountOwner',
  AccountAdmin = 'AccountAdmin',
  AccountManager = 'AccountManager',
  AccountUser = 'AccountUser',
  AccountStakeholder = 'AccountStakeholder',
}
// ordered least permissions to greatest permissions
export enum AccountRole {
  AccountStakeholder = 'Account Viewer',
  AccountUser = 'Account User',
  AccountManager = 'Account Manager',
  AccountAdmin = 'Account Admin',
  AccountOwner = 'Account Owner',
}

export const getRoleKey = (value: AccountRole) => {
  return Object.keys(AccountRole)[Object.values(AccountRole).indexOf(value)];
};

export const getRoleValue = (key: any) => {
  return AccountRole[key as keyof typeof AccountRole];
};

export const hasGreaterAccess = (accessNeeded: any, currentAccess: any) => {
  return Object.values(AccountRole).indexOf(accessNeeded) < Object.values(AccountRole).indexOf(currentAccess);
};
export const hasSameOrGreaterAccess = (accessNeeded: any, currentAccess: any) => {
  return Object.values(AccountRole).indexOf(accessNeeded) <= Object.values(AccountRole).indexOf(currentAccess);
};
