import { AxiosResponse } from 'axios';
import { StandardParams } from '../types/interfaces';
import { SpecimenCustomDataKey } from '../types/interfaces/specimenCustomDataKeyModel.interfaces';
import { CUSTOM_DATA_POINTS_API_ENDPOINTS } from '../constants/apiEndpoints';
import { appContextGet, appContextPost, appContextPut } from './httpCommon/appAxios.service';

export const getCustomDataKeys = (params: StandardParams): Promise<AxiosResponse<SpecimenCustomDataKey[], any>> => {
  const queryString = '?sort=' + ['^Active', 'KeyName'].join('&sort=');
  return appContextGet(CUSTOM_DATA_POINTS_API_ENDPOINTS.GET + queryString, params);
};

export const createCustomDataKey = (
  customDataKey: SpecimenCustomDataKey,
  params?: StandardParams,
): Promise<AxiosResponse<{}, any>> => {
  return appContextPost(CUSTOM_DATA_POINTS_API_ENDPOINTS.CREATE, customDataKey, params);
};

export const updateCustomDataKey = (
  customDataKey: Partial<SpecimenCustomDataKey>,
  params?: StandardParams,
): Promise<AxiosResponse<{}, any>> => {
  return appContextPut(CUSTOM_DATA_POINTS_API_ENDPOINTS.PUT + customDataKey.specimenCustomDataKeyId, customDataKey, params);
};
