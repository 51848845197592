import { useState } from 'react';
import { addUserToAccountByUsername } from '../../../api/accountApi';
import Modal from '../../../components/modal/Modal';
import ValidatedInput, { ValidatedState } from '../../../components/custom-input/ValidatedInput';
import { useAppSelector } from '../../../redux/hooks';
import { AccountRoles } from '../../../types/enums/accountRoles.enum';
import { Validators } from '../../../types/enums/validators.enum';
import './addUserToAccount.scss';
import { showToast } from '../../../services/toast.service';
import { toastMessages } from '../../../constants/errorMessages';
import { FilterDropdown } from '../../../components';
import { FilterOption } from '../../../components/custom-input/FilterDropdown';
import { selectOption } from '../../../types/interfaces';
import { access } from 'fs';

export const AddUserToAccount = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<ValidatedState>({ value: '', valid: false });
  const [phoneNumber, setPhoneNumber] = useState<ValidatedState>({ value: '', valid: false });
  const [accessLevel, setAccessLevel] = useState<selectOption<AccountRoles>>({
    name: '',
    value: AccountRoles.AccountUser,
  });
  const [validate, setValidate] = useState<boolean>(false);

  const account = useAppSelector(state => state.activeAccount);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setValidate(true);
    if (email.valid && phoneNumber.valid && account.accountId) {
      try {
        await addUserToAccountByUsername(email.value, accessLevel.value, account.accountId);
      } catch {
        showToast.error(toastMessages.FAILED_ADD_USER_TO_ACCOUNT);
      }
    }
  };

  return (
    <>
      <button onClick={() => setIsOpen(true)}>Add User</button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} width={400}>
        <div className="add-user-to-account-modal">
          <h4>Add Team Member</h4>
          <p>Please fill out this form in order to send out an invitation to join the team.</p>
          <form onSubmit={e => handleSubmit(e)}>
            <div className="input">
              <ValidatedInput
                label={'Email'}
                useId="email"
                type="email"
                setValidatedState={setEmail}
                validators={[Validators.REQUIRED, Validators.EMAIL]}
                validate={validate}
              />
            </div>
            <div className="row">
              <div className="input">
                <ValidatedInput
                  label={'Phone Number'}
                  useId="phone-number"
                  type="text"
                  setValidatedState={setPhoneNumber}
                  validators={[Validators.REQUIRED]}
                  validate={validate}
                />
              </div>
              <FilterDropdown
                options={[
                  { name: AccountRoles.AccountUser, value: AccountRoles.AccountUser },
                  { name: AccountRoles.AccountAdmin, value: AccountRoles.AccountAdmin },
                ]}
                value={{
                  name: '',
                  value: undefined,
                }}
                onChange={e => {
                  setAccessLevel({ value: e.value, name: e.name });
                }}
              />
            </div>
            <button className="add-user-to-account-modal-btn" type="submit">
              Submit
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};
