import { useCallback, useEffect, useState } from 'react';
import FilterDropdown, { FilterOption } from '../../../components/custom-input/FilterDropdown';
import TrashCan from '../../../components/svgs/TrashCan.svg';
import { ValidatedInput } from '../../../components';
import { showToast } from '../../../services/toast.service';
import { getStorageCanisterByTank, getStorageSites, getStorageTanksBySite } from '../../../api/storageItemsApi';
import { selectOption } from '../../../types/interfaces/selectOption.interface';
import { StorageCanister, StorageSite, StorageTank } from '../../../types/interfaces/storage.interfaces';
import { StorageTankOptions } from '../../../types/enums/storageTank.enum';
import { LABEL_CONSTANTS } from '../../../constants/common';
import { toastMessages } from '../../../constants/errorMessages';

interface StorageLocationProps {
  index: number;
  removeStorageLocation: (index: number) => void;
  handleSiteChange: (e: FilterOption<any>, index: number) => void;
  handleTankChange: (e: FilterOption<any>, index: number) => void;
  handleCanisterChange: (e: FilterOption<any>, index: number) => void;
  handleQuantityChange: (e: string, index: number) => void;
  selectedStorageLocations: any;
  storageTankOptions: StorageTankOptions;
  activeLocationsOnly?: boolean;
}

const StorageLocationRow = ({
  index,
  storageTankOptions,
  selectedStorageLocations,
  removeStorageLocation,
  handleSiteChange,
  handleTankChange,
  handleCanisterChange,
  handleQuantityChange,
  activeLocationsOnly = false,
}: StorageLocationProps) => {
  const initialState = { value: '', name: '' };
  const [storageSites, setStorageSites] = useState<selectOption[]>([initialState]);
  const [storageTanks, setStorageTanks] = useState<selectOption[]>([initialState]);
  const [storageCanisters, setStorageCanisters] = useState<selectOption[]>([initialState]);
  const [isLoading, setIsLoading] = useState({ sites: true, tanks: false, canisters: false });

  const getSites = useCallback(async () => {
    try {
      const res = await getStorageSites({ filter: activeLocationsOnly ? 'Active eq true' : undefined });
      const storageSites = (res.data as StorageSite[]).map((s: StorageSite) => ({
        value: String(s.storageSiteId),
        name: s.name + (s.active ? '' : ' - (Inactive)'),
      }));
      setStorageSites(storageSites);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setIsLoading(prevIsLoading => ({ ...prevIsLoading, sites: false }));
    }
  }, []);

  useEffect(() => {
    getSites();
  }, [getSites]);

  const getTanks = async (siteId: string) => {
    setIsLoading(prevIsLoading => ({ ...prevIsLoading, tanks: true }));

    try {
      const res = await getStorageTanksBySite(siteId, storageTankOptions, {
        filter: activeLocationsOnly ? 'Active eq true' : undefined,
      });
      const tanks = res.data.map((s: StorageTank) => ({
        value: String(s.storageTankId),
        name: s.name + (s.active ? '' : ' - (Inactive)'),
      }));
      setStorageTanks(tanks);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setIsLoading(prevIsLoading => ({ ...prevIsLoading, tanks: false }));
    }
  };

  const getCanisters = async (tankId: string) => {
    setIsLoading(prevIsLoading => ({ ...prevIsLoading, canisters: true }));

    try {
      const res = await getStorageCanisterByTank(tankId, { filter: activeLocationsOnly ? 'Active eq true' : undefined });
      const canisters = (res.data as StorageCanister[]).map((s: StorageCanister) => ({
        value: String(s.storageCanisterId),
        name: s.name + (s.active ? '' : ' - (Inactive)'),
      }));
      setStorageCanisters(canisters);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setIsLoading(prevIsLoading => ({ ...prevIsLoading, canisters: false }));
    }
  };

  const isLoadingPlaceHolder = (isLoadingOptions: boolean, filterDropdownName: string) => {
    return isLoadingOptions ? `Loading ${filterDropdownName}(s)` : `Select ${filterDropdownName}`;
  };

  return (
    <div className="select-storage-item">
      <div className="select-storage-item-row">
        <div className="select-column">
          <label>{LABEL_CONSTANTS.SITE}: </label>
          <FilterDropdown
            placeholder={isLoadingPlaceHolder(isLoading.sites, 'Site')}
            options={storageSites}
            validate={false}
            value={selectedStorageLocations[index].site}
            onChange={e => {
              getTanks(e.value);
              handleSiteChange(e, index);
            }}
            disabled={isLoading.sites}
          />
        </div>
        <div className="select-column">
          <label>{LABEL_CONSTANTS.TANK}: </label>
          <FilterDropdown
            placeholder={isLoadingPlaceHolder(isLoading.tanks, 'Tank')}
            options={storageTanks}
            validate={false}
            value={selectedStorageLocations[index].tank}
            onChange={e => {
              getCanisters(e.value);
              handleTankChange(e, index);
            }}
            disabled={!selectedStorageLocations[index].site.value || isLoading.tanks}
          />
        </div>
        <div className="select-column">
          <label>{LABEL_CONSTANTS.CANISTER}: </label>
          <FilterDropdown
            placeholder={isLoadingPlaceHolder(isLoading.canisters, 'Canister')}
            options={storageCanisters.filter(sc => !selectedStorageLocations.some((s: any) => s.canister.value === sc.value))}
            validate={false}
            value={selectedStorageLocations[index].canister}
            onChange={e => handleCanisterChange(e, index)}
            disabled={
              !selectedStorageLocations[index].site.value || !selectedStorageLocations[index].tank.value || isLoading.canisters
            }
          />
        </div>
        <div className="select-column">
          <label>{LABEL_CONSTANTS.QUANTITY}: </label>
          <ValidatedInput
            setValidatedState={e => handleQuantityChange(e.value, index)}
            placeholder={'Enter Quantity'}
            label={''}
            validators={[]}
            type="number"
            validatedStateForAutoFill={{ value: (selectedStorageLocations[index].quantity.value ?? '').toString(), valid: true }}
          />
        </div>
      </div>
      {selectedStorageLocations.length > 1 && <TrashCan onClick={() => removeStorageLocation(index)} className="trash-can" />}
    </div>
  );
};

export default StorageLocationRow;
