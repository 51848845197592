import { AxiosResponse } from 'axios';
import { appContextGet } from './httpCommon/appAxios.service';
import { CatalogAnimalsData } from '../types/interfaces';
import { CATALOG_API_ENDPOINTS } from '../constants/apiEndpoints';

export const getCatalogQuantity = (animalId?: string): Promise<AxiosResponse<number, any>> => {
  return appContextGet(CATALOG_API_ENDPOINTS.GET_QUANTITY, { animalId });
};

export const getAnimalCatalogDataById = (
  animalId: string,
  stockReplenishDate?: Date,
): Promise<AxiosResponse<CatalogAnimalsData, any>> => {
  return appContextGet(CATALOG_API_ENDPOINTS.CATALOG_ + animalId + CATALOG_API_ENDPOINTS.GET_CATALOG_SELL_DATA, {
    stockReplenishDate,
  });
};
