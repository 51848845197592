import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import ProfileImage from '../profile-image/ProfileImage';
import { AddressType } from '../../types/enums/addressType.enum';
import { UserInfo } from '../../types/interfaces/user.interfaces';
import { Account } from '../../types/interfaces/account.interfaces';
import { AccountRoles, getRoleValue } from '../../types/enums/accountRoles.enum';
import { getAccountById } from '../../api/accountApi';
import { getUserProfileImageById } from '../../api/userApi';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';
import './accountInfo.scss';

interface LabeledValueProps {
  label?: string;
  value?: string;
}

const LabeledValue: FC<LabeledValueProps> = ({ label, value }) => {
  return (
    <>
      <div className={'labeled-value-info'}>
        <label>{label ?? ''}</label>
        <span>{value ?? ''}</span>
      </div>
      <hr className="divider" />
    </>
  );
};

interface AccountInfoProps {
  accountId: number;
  showAccountOwner?: boolean;
}

const AccountInfo: FC<AccountInfoProps> = ({ accountId, showAccountOwner = false }) => {
  const user: UserInfo = useAppSelector(state => state.user);
  const accountRoles = useAppSelector(state => state.auth.accountContext.roles) as AccountRoles[];
  const activeAccount = useAppSelector(state => state.activeAccount);

  const [account, setAccount] = useState<Account | undefined>(undefined);
  const [billingAddress, setBillingAddress] = useState<string>();
  const [displayUser, setDisplayUser] = useState<UserInfo>();

  useEffect(() => {
    const getAccountWithAddresses = async () => {
      if (accountId) {
        try {
          const { data: accountResponse } = await getAccountById(accountId, { include: 'Addresses,Users.Roles' });
          setAccount(accountResponse);

          if (showAccountOwner) {
            const owner = accountResponse.users.find(
              u => u.roles.findIndex(r => r.name === AccountRoles.AccountOwner) >= 0,
            )?.user;
            try {
              const blobResponse = await getUserProfileImageById(owner!.userId, owner!.profileImageId);
              setDisplayUser({ ...owner!, profileImageBlob: blobResponse });
            } catch (error) {
              setDisplayUser(owner);
              showToast.error(toastMessages.SOMETHING_WENT_WRONG);
            }
          } else {
            setDisplayUser(user);
          }
        } catch (error) {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      }
    };

    setDisplayUser(undefined);
    getAccountWithAddresses();
  }, [accountId, user, activeAccount]);

  useEffect(() => {
    const getBillingAddress = (): string => {
      const accountHasAddresses = () => account && account.addresses && account.addresses.length > 0;

      if (accountHasAddresses()) {
        const billing = account!.addresses!.find(address => address.type === AddressType.BILLING);

        if (billing) {
          return `${billing.streetAddress}, ${billing.city}, ${billing.state}, ${billing.country} ${billing.postalCode}`;
        }
      }
      return '';
    };

    setBillingAddress(getBillingAddress());
  }, [account]);

  return (
    <>
      <div className="account-info">
        <div className="header">
          <div className="image">
            <ProfileImage
              profileImageId={displayUser?.profileImageId}
              profileImageBlob={displayUser?.profileImageBlob}
              className="profile-image"
            />
          </div>

          <div className="name-stack">
            <div className="name">{displayUser && <p>{`${displayUser.firstName} ${displayUser.lastName}`}</p>}</div>
            <div className="role">
              <p>{getRoleValue(showAccountOwner ? AccountRoles.AccountOwner : accountRoles)}</p>
            </div>
          </div>

          <div className="account-stack">
            <div className="farm-name">
              <h3>{account?.name}</h3>
            </div>
            <div className="account-id">
              <p>Account ID: {account?.accountId}</p>
            </div>
          </div>
        </div>
        <div className="body">
          <LabeledValue
            label={'Point Of Contact:'}
            value={account != undefined ? account?.contactFirstName + ' ' + account?.contactLastName : ''}
          />
          <LabeledValue label={'Contact Email:'} value={account?.contactEmail} />
          <LabeledValue label="Contact Phone Number:" value={account?.contactPhone} />
          <LabeledValue label="Billing Address: " value={billingAddress} />
        </div>
      </div>
    </>
  );
};

export default AccountInfo;
