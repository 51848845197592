import { PurchaseDetailsInterface } from './AddInventoryModal';
import { ANIMAL_CONSTANTS, BUTTON_CONSTANTS, LABEL_CONSTANTS } from '../../../constants/common';

interface PurchaseDetailsProps {
  purchaseDetails: PurchaseDetailsInterface;
  handleClose: () => void;
}

const PurchaseDetails = ({ purchaseDetails, handleClose }: PurchaseDetailsProps) => {
  return (
    <div className="add-inventory-modal">
      <h4>{LABEL_CONSTANTS.PURCHASE_DETAILS}</h4>
      <h5>{ANIMAL_CONSTANTS.ANIMAL_INFO}: </h5>
      <hr />
      <p>
        {ANIMAL_CONSTANTS.ANIMAL_CODE}: {purchaseDetails.animalCode}
      </p>
      <p>
        {ANIMAL_CONSTANTS.ANIMAL_NAME}: {purchaseDetails.animalName}
      </p>
      <p>
        {LABEL_CONSTANTS.PURCHASE_QUANTITY}: {purchaseDetails.quantity}
      </p>
      <h5>{LABEL_CONSTANTS.SELLER_INFO}: </h5>
      <hr />
      <p>
        {LABEL_CONSTANTS.SELLER_NAME}: {purchaseDetails.sellerName}
      </p>
      <p>
        {LABEL_CONSTANTS.SELLER_EMAIL}: {purchaseDetails.sellerEmail}
      </p>
      <div className="footer">
        <button onClick={handleClose} className="button green">
          {BUTTON_CONSTANTS.CLOSE}
        </button>
      </div>
    </div>
  );
};

export default PurchaseDetails;
