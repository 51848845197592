import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import BackButton from '../../components/back-button/BackButton';
import { Loader } from '../../components';
import NumberInput from '../../components/custom-input/NumberInput';
import { DiscardRequest, SpecimenInventory } from '../../types/interfaces';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import {
  ANIMAL_CONSTANTS,
  BUTTON_CONSTANTS,
  INVENTORY_MANAGEMENT_CONSTANTS,
  LABEL_CONSTANTS,
  PAGE_HEADER_CONSTANTS,
  TABLE_HEADER_CONSTANTS,
} from '../../constants/common';
import { showToast } from '../../services/toast.service';
import { getSpecimensByOwnership } from '../../api/specimensApi';
import { discard } from '../../api/inventoryApi';

type DiscardItem = {
  availableQty: number;
  isSelected: boolean;
  quantity: number;
  specimenId: number;
};

const InventoryDiscard = (): JSX.Element => {
  const { animalId } = useParams();
  const navigate = useNavigate();

  const accountId = useAppSelector(state => state.activeAccount.accountId);
  const { user } = useAppSelector(state => state);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [notes, setNotes] = useState<string>('');
  const [ownership, setOwnership] = useState<SpecimenInventory[]>();
  const [selectedDiscardItems, setSelectedDiscardItems] = useState<DiscardItem[]>([]);
  const [totalAvailableOwnedQty, setTotalAvailableOwnedQty] = useState<number>();

  const totalDiscardQuantity = selectedDiscardItems
    .filter(item => item.isSelected)
    .reduce((accumulator, current) => accumulator + current.quantity, 0);

  useEffect(() => {
    const handleGetSpecimens = async () => {
      if (animalId && accountId) {
        try {
          const params = {
            include: 'Animal, SpecimenType',
            userId: user.userId,
            getUpdatedAvailableQuantities: true,
          };
          const { data: ownership } = await getSpecimensByOwnership(animalId, accountId, params);
          setOwnership(ownership);
          setTotalAvailableOwnedQty(ownership.reduce((acc, curr) => acc + curr.quantityOwned, 0));
          handleCreateDiscardItems(ownership);
          setIsLoading(false);
        } catch (error) {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      }
    };
    handleGetSpecimens();
  }, [animalId, accountId]);

  useEffect(() => {
    selectedDiscardItems.forEach(inventoryLot => {
      if (inventoryLot.isSelected && inventoryLot.quantity > inventoryLot.availableQty) {
        showToast.error(toastMessages.INVALID_DISCARD_QUANTITY);
      }
    });
  }, [selectedDiscardItems]);

  const handleCreateDiscardItems = (ownership: SpecimenInventory[]) => {
    const discardItems = ownership.map(item => {
      return {
        isSelected: false,
        specimenId: item.specimenId,
        quantity: 0,
        availableQty: item.quantityAvailable,
      };
    });
    setSelectedDiscardItems(discardItems);
  };

  const isValid = (isSubmit?: boolean) => {
    if (!selectedDiscardItems.find(item => item.isSelected)) {
      isSubmit && showToast.error(toastMessages.NO_ITEM_SELECTED);
      return false;
    } else if (selectedDiscardItems.some(item => item.isSelected && item.quantity > item.availableQty)) {
      isSubmit && showToast.error(toastMessages.INVALID_DISCARD_QUANTITY);
      return false;
    } else if (!totalAvailableOwnedQty || totalDiscardQuantity > totalAvailableOwnedQty) {
      isSubmit && showToast.error(toastMessages.INVALID_DISCARD_QUANTITY);
      return false;
    } else if (totalDiscardQuantity === 0) {
      isSubmit && showToast.error(toastMessages.INVALID_ZERO_QUANTITY);
      return false;
    }
    return true;
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isValid(true)) {
      try {
        setIsLoading(true);
        let newSelectedDiscardItems = selectedDiscardItems.filter(item => item.isSelected);
        const mappedDiscardSpecimens: DiscardRequest[] = newSelectedDiscardItems.map(item => {
          return { specimenId: item.specimenId, quantity: item.quantity };
        });
        if (!accountId) {
          throw new Error('Account Id is not defined');
        }
        await discard(accountId, mappedDiscardSpecimens, notes);
        showToast.success(toastMessages.DISCARD_SUCCESSFULLY_REQUESTED);
        navigate(ROUTE_PATHS.APP_INVENTORY);
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const inputCheckbox = (discardItem: DiscardItem | undefined) => {
    return (
      <input
        type="checkbox"
        name="selected-checkin-specimen-row"
        onChange={e =>
          setSelectedDiscardItems(prev => {
            discardItem ? (discardItem.isSelected = e.target.checked) : null;
            return [...prev];
          })
        }
        checked={discardItem?.isSelected || false}
      />
    );
  };

  return (
    <div className="inventory-discard">
      <BackButton />
      <div className="inventory-action card">
        <h1>{PAGE_HEADER_CONSTANTS.DISCARD_INVENTORY}</h1>
        {isLoading ? (
          <Loader loaderSize="medium" simple />
        ) : (
          <form action="submit" onSubmit={e => handleSubmit(e)}>
            <h2>{ANIMAL_CONSTANTS.ANIMAL_INFO}</h2>
            <p className="animal-info-text">
              {ANIMAL_CONSTANTS.ANIMAL_CODE}: {ownership?.length ? ownership[0].animal?.code : ''}
            </p>
            <p className="animal-info-text">
              {ANIMAL_CONSTANTS.ANIMAL_NAME}: {ownership?.length ? ownership[0].animal?.name : ''}
            </p>
            <p className="animal-info-text">
              {LABEL_CONSTANTS.QUANTITY_AVAILABLE}: {totalAvailableOwnedQty ?? ''}
            </p>
            <h2>{LABEL_CONSTANTS.SELECT_ITEM_QUANTITY}</h2>
            <div className="desk-select-specimens">
              <div className="specimen-table">
                <table>
                  <thead>
                    <tr>
                      <th>{TABLE_HEADER_CONSTANTS.SELECT}</th>
                      <th>{TABLE_HEADER_CONSTANTS.ANIMAL}</th>
                      <th>{TABLE_HEADER_CONSTANTS.INVENTORY_TYPE}</th>
                      <th>{TABLE_HEADER_CONSTANTS.LOT_NO}</th>
                      <th>{TABLE_HEADER_CONSTANTS.QUALITY}</th>
                      <th>{TABLE_HEADER_CONSTANTS.AVAILABLE_QTY}</th>
                      <th>{TABLE_HEADER_CONSTANTS.QUANTITY}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ownership?.map((item, index) => {
                      const discardItem = selectedDiscardItems.find(discardItem => discardItem.specimenId === item.specimenId);

                      return (
                        <tr
                          key={index}
                          className={
                            (discardItem?.quantity ?? 0) > item.quantityAvailable && discardItem?.isSelected ? 'invalid-row' : ''
                          }>
                          <td>{inputCheckbox(discardItem)}</td>
                          <td>
                            {item.animal?.code}-{item.animal?.name}
                          </td>
                          <td>{item.specimenType?.name}</td>
                          <td>{item.specimenId}</td>
                          <td>{item.quality}</td>
                          <td>{item.quantityAvailable}</td>
                          <td>
                            <input
                              type="text"
                              placeholder="0"
                              value={discardItem?.isSelected ? (discardItem.quantity > 0 ? discardItem?.quantity : '') : '-'}
                              disabled={!discardItem?.isSelected}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const count = +e.target.value.replace(/[^\d]+/, '').replace(/^0+/, '');
                                let newQuantities = [...selectedDiscardItems];
                                newQuantities[index] = { ...selectedDiscardItems[index], quantity: count };
                                setSelectedDiscardItems(newQuantities);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan={6}></td>
                      <td colSpan={1}>
                        <div className="quantity">{totalDiscardQuantity}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/** Mobile View */}
            <div className="xs-select-specimens">
              {ownership &&
                ownership?.length > 0 &&
                ownership?.map((item, index) => {
                  const discardItem = selectedDiscardItems.find(discardItem => discardItem.specimenId === item.specimenId);
                  const discardQuantity = discardItem ? discardItem.quantity : 0;
                  return (
                    <div
                      className={`card max-width ${
                        discardQuantity > item.quantityAvailable && discardItem?.isSelected ? 'invalid-row' : ''
                      }`}
                      key={'specimen-row:' + item.specimenId + '-index:' + index}>
                      <div className="selected-specimens-row">
                        <div className="input-check">{inputCheckbox(discardItem)}</div>
                        <div className="select-specimens">
                          <div className="card-content-section">
                            <div className="left column">
                              <label>{`${TABLE_HEADER_CONSTANTS.ANIMAL}: `}</label>
                              <label>{`${item.animal?.code} - ${item.animal?.name}`}</label>
                            </div>
                            <div className="left column">
                              <label>{`${TABLE_HEADER_CONSTANTS.INVENTORY_TYPE}: `}</label>
                              <label>{`${item.specimenType?.name}`}</label>
                            </div>
                            <div className="right column">
                              <label>{`${TABLE_HEADER_CONSTANTS.LOT_NO}: `}</label>
                              <label>{`${item.specimenId}`}</label>
                            </div>
                          </div>
                          <div className="card-content-section">
                            <div className="left column">
                              <label>{`${TABLE_HEADER_CONSTANTS.QUALITY}: `}</label>
                              <label>{`${item.quality}`}</label>
                            </div>
                            <div className="left column ">
                              <label>{`${TABLE_HEADER_CONSTANTS.AVAILABLE_QTY}: `}</label>
                              <label>{`${item.quantityAvailable}`}</label>
                            </div>
                            <div className="right center column">
                              <label>{`${TABLE_HEADER_CONSTANTS.QUANTITY}:`}</label>
                              <NumberInput
                                disabled={!discardItem?.isSelected}
                                value={discardQuantity}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                  setSelectedDiscardItems(prev => {
                                    discardItem ? (discardItem.quantity = parseInt(e.target.value)) : null;
                                    return [...prev];
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div className="total-quantity">
                <label>{LABEL_CONSTANTS.TOTAL_QUANTITY}: &nbsp; </label>
                {totalDiscardQuantity}
              </div>
            </div>
            <br />
            <div className="form-row expanded-text-area">
              <label>{LABEL_CONSTANTS.NOTES}:</label>
              <textarea id="notes" name="notes" placeholder="Notes" onChange={e => setNotes(e.target.value)} rows={4} cols={50} />
            </div>
            <br />
            <div className="button-container">
              <p className="discard-submit-guide">{INVENTORY_MANAGEMENT_CONSTANTS.COMMON_INVENTORY_FOOTER}</p>
              <button className={isValid() ? 'button green small' : 'button green small disabled'}>
                {BUTTON_CONSTANTS.SUBMIT}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default InventoryDiscard;
