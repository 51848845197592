import { FC, useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import Loader from '../loader/Loader';
import { Specimen, SpecimenInventory } from '../../types/interfaces/specimen.interfaces';
import { getSpecimensByOwnership } from '../../api/specimensApi';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';
import { LABEL_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../constants/common';

export type SelectedSpecimenQuantity = {
  availableQuantity: number;
  quantity: number;
  selected: boolean;
  selectedCanisterId?: number;
  specimenId: number;
  specimenLocationId?: number;
};

type SpecimenQuantitiesProps = {
  animalId?: number;
  accountId?: number;
  onChange: (e: { valid: boolean; value: SelectedSpecimenQuantity[] }) => void;
};

const SelectSpecimens: FC<SpecimenQuantitiesProps> = ({ animalId, accountId, onChange }): JSX.Element => {
  const { user } = useAppSelector(state => state);

  const [loading, setLoading] = useState<boolean>(false);
  const [quantities, setQuantities] = useState<SelectedSpecimenQuantity[]>([]);
  const [specimens, setSpecimens] = useState<Specimen[]>();

  const handleGetSpecimens = useCallback(async () => {
    if (animalId && accountId) {
      try {
        setLoading(true);
        const params = {
          include: 'Animal, SpecimenType, SpecimenLocation',
          userId: user.userId,
          getUpdatedAvailableQuantities: true,
        };

        const specs = await getSpecimensByOwnership(animalId, accountId, params);
        setSpecimens(specs.data);

        const quants = specs.data.map((spec: SpecimenInventory) => {
          return {
            specimenId: spec.specimenId,
            availableQuantity: spec.quantityAvailable,
            selectedCanisterId: spec.specimenLocations?.[0]?.storageCanisterId,
            quantity: 0,
            selected: false,
          };
        });
        setQuantities(quants);
        setLoading(false);
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    } else {
      setSpecimens([]);
      setQuantities([]);
    }
  }, [animalId, accountId]);

  const inputCheckbox = (index: number) => {
    return (
      <input
        type="checkbox"
        checked={quantities[index]?.selected ?? false}
        onChange={e => {
          let newQuantities = [...quantities];
          newQuantities[index] = {
            ...quantities[index],
            selected: e.target.checked,
            quantity: e.target.checked ? quantities[index].quantity : 0,
          };
          setQuantities(newQuantities);
        }}
      />
    );
  };

  const inputQuantityElement = (index: number) => {
    return (
      <input
        type="text"
        placeholder="0"
        value={quantities[index]?.selected ? (quantities[index]?.quantity > 0 ? quantities[index]?.quantity : '') : '-'}
        disabled={!quantities[index]?.selected}
        onChange={e => {
          const count = +e.target.value
            .replace(/[^\d]+/, '') //Remove all non-digits
            .replace(/^0+/, ''); //Remove all leading zeros
          let newQuantities = [...quantities];
          newQuantities[index] = { ...quantities[index], quantity: count };
          setQuantities(newQuantities);
        }}
      />
    );
  };

  useEffect(() => {
    handleGetSpecimens();
  }, [handleGetSpecimens]);

  useEffect(() => {
    const quants = quantities.filter(q => q.selected);
    const valid = quants.reduce((acc: boolean, q: SelectedSpecimenQuantity): boolean => {
      return acc && q.quantity > 0 && q.quantity <= q.availableQuantity;
    }, quants.length > 0);
    onChange({ valid: valid, value: quants });
  }, [quantities]);

  const handleErrors = (index: number) => {
    return quantities[index]?.selected
      ? quantities[index]?.quantity > quantities[index]?.availableQuantity
        ? 'error'
        : quantities[index]?.quantity === 0
        ? 'warning'
        : ''
      : '';
  };

  return loading ? (
    <Loader loaderSize="small" addedSpace />
  ) : (
    <>
      <div className="desk-select-specimens">
        <div className="specimen-table">
          <table>
            <thead>
              <tr>
                <th>{TABLE_HEADER_CONSTANTS.SELECT}</th>
                <th>{TABLE_HEADER_CONSTANTS.ANIMAL}</th>
                <th>{TABLE_HEADER_CONSTANTS.INVENTORY_TYPE}</th>
                <th>{TABLE_HEADER_CONSTANTS.LOT_NO}</th>
                <th>{TABLE_HEADER_CONSTANTS.QUALITY}</th>
                <th>{TABLE_HEADER_CONSTANTS.AVAILABLE_QTY}</th>
                <th>{TABLE_HEADER_CONSTANTS.QUANTITY}</th>
              </tr>
            </thead>
            <tbody>
              {specimens?.map((spec, index) => {
                return (
                  <tr className={handleErrors(index)} key={'specimen-row:' + spec.specimenId + '-index:' + index}>
                    <td>{inputCheckbox(index)}</td>
                    <td>
                      {spec.animal?.code}-{spec.animal?.name}
                    </td>
                    <td>{spec.specimenType?.name}</td>
                    <td>{spec.specimenId}</td>
                    <td>{spec.quality}</td>
                    <td>{quantities[index]?.availableQuantity}</td>
                    <td>{inputQuantityElement(index)}</td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan={6}>{LABEL_CONSTANTS.TOTAL_QUANTITY}:</td>
                <td>
                  <input
                    type="text"
                    disabled
                    value={quantities.reduce(
                      (acc: number, qua: SelectedSpecimenQuantity): number => (qua.selected ? acc + qua.quantity : acc),
                      0,
                    )}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/** Mobile View */}
      <div className="xs-select-specimens">
        {specimens &&
          specimens?.length > 0 &&
          specimens.map((spec, index) => {
            return (
              <div
                className={`card max-width ${handleErrors(index)}`}
                key={'specimen-row:' + spec.specimenId + '-index:' + index}>
                <div className="selected-specimens-row">
                  <div className="input-check">{inputCheckbox(index)}</div>
                  <div className="select-specimens">
                    <div className="card-content-section">
                      <div className="left column">
                        <label>{`${TABLE_HEADER_CONSTANTS.ANIMAL}: `}</label>
                        <label>{`${spec.animal?.code} - ${spec.animal?.name}`}</label>
                      </div>
                      <div className="left column">
                        <label>{`${TABLE_HEADER_CONSTANTS.INVENTORY_TYPE}: `}</label>
                        <label>{`${spec.specimenType?.name}`}</label>
                      </div>
                      <div className="right column">
                        <label>{`${TABLE_HEADER_CONSTANTS.LOT_NO}: `}</label>
                        <label>{`${spec.specimenId}`}</label>
                      </div>
                    </div>
                    <div className="card-content-section">
                      <div className="left column">
                        <label>{`${TABLE_HEADER_CONSTANTS.QUALITY}: `}</label>
                        <label>{`${spec.quality}`}</label>
                      </div>
                      <div className="left column ">
                        <label>{`${TABLE_HEADER_CONSTANTS.AVAILABLE_QTY}: `}</label>
                        <label>{`${quantities[index]?.availableQuantity}`}</label>
                      </div>
                      <div className="right center column">
                        <label>{`${TABLE_HEADER_CONSTANTS.QUANTITY}:`}</label>
                        {inputQuantityElement(index)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        <div className="total-quantity">
          <label>{LABEL_CONSTANTS.TOTAL_QUANTITY}: &nbsp; </label>
          {quantities.reduce(
            (acc: number, qua: SelectedSpecimenQuantity): number => (qua.selected ? acc + qua.quantity : acc),
            0,
          )}
        </div>
      </div>
    </>
  );
};

export default SelectSpecimens;
