import { getAdminInventoryTransactions, getInventoryTransactions } from '../api/inventoryTransactionsApi';
import { getTransactionsOdata } from '../api/transactionsApi';
import { TransactionStatusEnum, TransactionTypeEnum } from '../types/enums/transaction.enum';
import { InventoryTransaction, Specimen } from '../types/interfaces';

export const requestPendingUseTransactionsByCanisterId = async (canisterId: number) => {
  try {
    const response = await getInventoryTransactions({
      filter: `StorageCanisterId eq ${canisterId}; TransactionType eq 'PendingUse'`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const requestPendingDepositTransactionsByAccountId = async (accountId: number) => {
  try {
    const response = await getAdminInventoryTransactions({
      filter: `AccountId eq ${accountId}; TransactionType eq ${TransactionTypeEnum.Deposit}; Status eq ${TransactionStatusEnum.Pending}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getInventoryItemId = (specimen: Specimen | null | undefined) => {
  return specimen ? String(specimen?.specimenId + ' - ' + new Date(specimen?.freezeDate!).toLocaleDateString()) : '';
};

export async function pendingUseHasCheckout(pendingUse: InventoryTransaction): Promise<boolean> {
  if (!pendingUse.inventoryTransactionId)
    throw Error(`pendingUseHasCheckout() failed to receive transaction id: ${pendingUse.inventoryTransactionId}`);

  const filter = {
    filter: `transactionLinkId eq ${pendingUse.inventoryTransactionId} and transactionType eq '${TransactionTypeEnum.CheckOut}'`,
  };

  const { data } = await getTransactionsOdata(filter);

  return data.length > 0;
}
