import { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import SelectStorageItems from '../../components/select-storage-items/SelectStorageItems';
import SelectSpecimenFromCanister from '../../components/select-specimen-from-canister/SelectSpecimenFromCanister';
import { FilterOption } from '../../components/custom-input/FilterDropdown';
import ValidatedInput, { ValidatedState } from '../../components/custom-input/ValidatedInput';
import AccountSearch from '../../components/custom-input/AccountSearch';
import Loader from '../../components/loader/Loader';
import { Validators } from '../../types/enums/validators.enum';
import { Account } from '../../types/interfaces/account.interfaces';
import { SpecimenSelection } from '../../types/interfaces/inventoryTransaction.interfaces';
import { StorageTankOptions } from '../../types/enums/storageTank.enum';
import { sellToExistingUser, sellUseToExistingUser } from '../../api/inventoryApi';
import { getQuantityInCanister } from '../../api/specimensApi';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';
import { BUTTON_CONSTANTS, INVENTORY_MANAGEMENT_CONSTANTS, LABEL_CONSTANTS, PAGE_HEADER_CONSTANTS } from '../../constants/common';
import { redirectToTopOfThePage } from '../../utils/commonUtils';

const InventoryManagementOnSiteSell = (): JSX.Element => {
  const { activeAccount, user } = useAppSelector(state => state);
  const initialStateSelect = { value: '', name: '' };
  const initialStateValidatedInput = { valid: false, value: '' };
  const [selectedSite, setSelectedSite] = useState(initialStateSelect);
  const [selectedTank, setSelectedTank] = useState(initialStateSelect);
  const [selectedCanister, setSelectedCanister] = useState(initialStateSelect);
  const [selectedOwner, setSelectedOwner] = useState<FilterOption<any>>(initialStateSelect);
  const [selectedSpecimen, setSelectedSpecimen] = useState<FilterOption<any>>(initialStateSelect);
  const [quantityInCanister, setQuantityInCanister] = useState<number>();
  const [quantityToSell, setQuantityToSell] = useState<ValidatedState>(initialStateValidatedInput);
  const [quantityToUse, setQuantityToUse] = useState<ValidatedState>(initialStateValidatedInput);
  const [notes, setNotes] = useState<string>('');
  const [buyerAccount, setBuyerAccount] = useState<Account>();
  const [usingNow, setUsingNow] = useState<boolean>();
  const [validate, setValidate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const clearForm = () => {
    setSelectedSite(initialStateSelect);
    setSelectedTank(initialStateSelect);
    setSelectedCanister(initialStateSelect);
    setSelectedOwner(initialStateSelect);
    setSelectedSpecimen(initialStateSelect);
    setQuantityInCanister(undefined);
    setQuantityToSell(initialStateValidatedInput);
    setQuantityToUse(initialStateValidatedInput);
    setNotes('');
    setBuyerAccount(undefined);
    setUsingNow(false);
    setValidate(false);
  };

  const handleSubmit = () => {
    setValidate(true);
    if (
      selectedSite.value.length > 0 &&
      selectedTank.value.length > 0 &&
      selectedCanister.value.length > 0 &&
      selectedOwner.value &&
      selectedSpecimen.value &&
      quantityToSell.valid &&
      buyerAccount?.accountId &&
      (!usingNow || (usingNow && quantityToUse.valid))
    ) {
      if (selectedOwner.value == buyerAccount.accountId) {
        showToast.error(toastMessages.SELLER_AND_BUYER_MUST_BE_DIFFERENT);
        return;
      }

      const sell = usingNow ? sellUseToExistingUser : sellToExistingUser;
      setIsLoading(true);
      sell({
        sellerAccountId: Number(selectedOwner.value),
        sellerUserId: user.userId,
        buyerAccountId: buyerAccount.accountId!,
        specimenSelections: [
          {
            selectedCanisterId: Number(selectedCanister.value),
            specimenId: selectedSpecimen.value,
            quantity: +quantityToSell.value,
            ...(usingNow && { quantityUsed: +quantityToUse.value }),
          } as SpecimenSelection,
        ],
        notes: notes,
      })
        .then(() => {
          showToast.success(toastMessages.SALE_HAS_BEEN_SUBMITTED);
          clearForm();
        })
        .catch(() => {
          showToast.error(toastMessages.FAILED_TO_SUBMIT_SALE);
        })
        .finally(() => {
          setIsLoading(false);
          redirectToTopOfThePage();
        });
    }
  };

  useEffect(() => {
    const getQuantity = async () => {
      getQuantityInCanister(selectedCanister.value, selectedOwner.value, selectedSpecimen.value).then(res => {
        const { data: specimenInventory } = res;
        const specimenData = specimenInventory.find(s => s.specimenId == selectedSpecimen.value);
        const minimumAvailableQuantity = Math.min(specimenData?.quantityAvailable ?? 0, specimenData?.quantityOwned ?? 0);
        setQuantityInCanister(minimumAvailableQuantity);
      });
    };
    if (selectedCanister.value && selectedOwner.value && selectedSpecimen.value) {
      getQuantity();
    } else {
      setQuantityInCanister(0);
    }
  }, [selectedCanister, selectedSpecimen, selectedOwner]);

  return (
    <div className="inventory-management inventory-management-discard">
      {isLoading ? (
        <Loader loaderSize={'large'} pageLoader simple={true} />
      ) : (
        <div className="inventory-action card">
          <h1>{PAGE_HEADER_CONSTANTS.ON_SITE_SELL}</h1>
          <h2>{LABEL_CONSTANTS.SELL_INFO}</h2>
          <SelectStorageItems
            selectedSite={selectedSite}
            selectedTank={selectedTank}
            selectedCanister={selectedCanister}
            setSelectedSite={setSelectedSite}
            setSelectedTank={setSelectedTank}
            setSelectedCanister={setSelectedCanister}
            storageTankOptions={StorageTankOptions.Travel}
            activeLocationsOnly
          />
          <SelectSpecimenFromCanister
            canisterId={Number(selectedCanister.value)}
            setSelectedSpecimen={setSelectedSpecimen}
            selectedSpecimen={selectedSpecimen}
            selectedOwner={selectedOwner}
            setSelectedOwner={setSelectedOwner}
          />
          <div className="form-row">
            <label>{LABEL_CONSTANTS.QTY_IN_CANISTER}:</label>
            <div className="input-container">
              <label>{selectedOwner.value ? quantityInCanister : '-'}</label>
            </div>
          </div>
          <div className="form-row">
            <ValidatedInput
              useId="quantity-to-sell"
              label="Quantity To Sell:"
              type="number"
              min={1}
              max={quantityInCanister}
              setValidatedState={setQuantityToSell}
              validators={[Validators.REQUIRED]}
              validate={validate}
              disabled={!quantityInCanister}
            />
          </div>
          <div className="form-row expanded-text-area">
            <label htmlFor="inventory-notes-textarea">{LABEL_CONSTANTS.NOTES}:</label>
            <textarea value={notes} placeholder="Notes" id="inventory-notes-textarea" onChange={e => setNotes(e.target.value)} />
          </div>
          <br />
          <h2>{LABEL_CONSTANTS.BUYER_INFO}</h2>
          <div className="form-row">
            <label>{LABEL_CONSTANTS.BUYER_ACCOUNT}</label>
            <div className="input-container">
              <AccountSearch showIcon onChange={(account: Account | undefined) => setBuyerAccount(account)} />
            </div>
          </div>
          <div className="form-row">
            <label>{INVENTORY_MANAGEMENT_CONSTANTS.ON_SITE_SELL_QUESTION}</label>
            <div className="input-container">
              <div className="radio-row">
                <input type="radio" id="use-now-true" name="use-now" onChange={() => setUsingNow(true)} />
                <label htmlFor="use-now-true">{LABEL_CONSTANTS.YES}</label>
                <input type="radio" id="use-now-false" name="use-now" defaultChecked={true} onChange={() => setUsingNow(false)} />
                <label htmlFor="use-now-false">{LABEL_CONSTANTS.NO}</label>
              </div>
            </div>
          </div>

          {usingNow && (
            <>
              <h2>{LABEL_CONSTANTS.USE_INFO}</h2>
              <div className="form-row">
                <ValidatedInput
                  useId="quantity-to-use"
                  label="Quantity To Use:"
                  type="number"
                  min={0}
                  max={+quantityToSell.value}
                  setValidatedState={setQuantityToUse}
                  validate={validate}
                  validators={[Validators.REQUIRED]}
                />
              </div>
            </>
          )}

          <div className="flex-right">
            <button
              className="button small green"
              onClick={handleSubmit}
              disabled={
                !(
                  selectedSite.value.length > 0 &&
                  selectedTank.value.length > 0 &&
                  selectedCanister.value.length > 0 &&
                  selectedOwner.value &&
                  selectedSpecimen.value &&
                  quantityToSell.valid &&
                  buyerAccount?.name &&
                  (!usingNow || (usingNow && quantityToUse.value))
                )
              }>
              {BUTTON_CONSTANTS.SUBMIT}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InventoryManagementOnSiteSell;
