import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import ValidatedInput, { ValidatedState } from '../../components/custom-input/ValidatedInput';
import { Loader } from '../../components';
import { Validators } from '../../types/enums/validators.enum';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { PromiseError, toastMessages } from '../../constants/errorMessages';
import { BUTTON_CONSTANTS, PASSWORDS_CONSTANTS, RSG_CONSTANTS, STAY_SIGNED_IN } from '../../constants/common';
import { signInUser } from '../../services/auth.service';
import { showToast } from '../../services/toast.service';
import { getLocalUser } from '../../utils/localStorageHandler';
import { getUser } from '../../api/userApi';

const SignIn = (): JSX.Element => {
  const [email, setEmail] = useState<ValidatedState>({ value: '', valid: false });
  const [password, setPassword] = useState<ValidatedState>({ value: '', valid: false });
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [validate, setValidate] = useState<boolean>(false);
  const [signingIn, setSigningIn] = useState<boolean>(true);

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!signingIn) return;
    setSigningIn(false);
    setValidate(true);

    if (email.valid && password.valid) {
      try {
        await signInUser({ email: email.value, password: password.value }, rememberMe);

        const userId = getLocalUser()?.userId;

        if (userId) {
          const { data: userData } = await getUser(userId, { include: 'Accounts.Account' });
          if (userData.accounts.length === 0) {
            navigate(ROUTE_PATHS.APP_USER_PROFILE);
          } else {
            navigate(ROUTE_PATHS.APP_DASHBOARD);
          }
        }
      } catch (error: any) {
        if (error.code === PromiseError.BAD_REQUEST) {
          showToast.error(toastMessages.INCORRECT_EMAIL_PASSWORD);
        } else if (error.code === PromiseError.NETWORK) {
          showToast.error(toastMessages.NETWORK);
        } else {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      } finally {
        setSigningIn(true);
      }
    } else {
      setSigningIn(true);
    }
  };

  return (
    <div className="auth">
      <div className="content-container">
        <div className="content">
          <div className="form">
            <h1 className="welcome">{RSG_CONSTANTS.RSG_WELCOME}</h1>
            <p className="subheader">{RSG_CONSTANTS.RSG_SIGN_IN_SUBHEADER}</p>

            <form onSubmit={e => handleSubmit(e)}>
              <div className="input">
                <ValidatedInput
                  label="Email"
                  useId="email"
                  type="email"
                  setValidatedState={setEmail}
                  validators={[Validators.REQUIRED, Validators.EMAIL]}
                  validate={validate}
                />
              </div>

              <div className="input">
                <ValidatedInput
                  label="Password"
                  type="password"
                  setValidatedState={setPassword}
                  validators={[Validators.REQUIRED]}
                  validate={validate}
                />
                <div className="forgot-password">
                  <Link to={ROUTE_PATHS.FORGOT_PASSWORD}>{PASSWORDS_CONSTANTS.FORGOT_PASSWORD}</Link>
                </div>
              </div>
              {!signingIn && <Loader loaderSize={'small'} simple />}

              <div className="remember">
                <input type="checkbox" id="rememberMe" onChange={e => setRememberMe(e.target.checked)} />
                <label htmlFor="rememberMe">{STAY_SIGNED_IN}</label>
              </div>

              <button disabled={!email.valid || !password.valid} type="submit" className="green button">
                {BUTTON_CONSTANTS.SIGN_IN}
              </button>
            </form>
          </div>
          <hr />
          <p className="bottom-text">
            {RSG_CONSTANTS.RSG_SIGN_IN_FOOTER} &nbsp;
            <Link to={ROUTE_PATHS.SIGN_UP}>{BUTTON_CONSTANTS.SIGN_UP}</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
