import {AxiosResponse} from 'axios';
import {
  SPECIMEN_TYPES_API_ENDPOINTS,
} from '../constants/apiEndpoints';
import {appContextGet} from './httpCommon/appAxios.service';
import {StandardParams} from '../types/interfaces/apiParams.interfaces';
import { SpecimenType } from '../types/interfaces/specimen.interfaces';

export const getSpecimenTypes = (
  params?: StandardParams,
): Promise<AxiosResponse<SpecimenType[], any>> => {
  return appContextGet(SPECIMEN_TYPES_API_ENDPOINTS.GET, params);
};
