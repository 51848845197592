const TabMenuSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 6L8 6V8L21 8V6ZM21 11H11V13H21V11ZM21 18V16L8 16V18H21ZM3 8.41L6.58 12L3 15.59L4.41 17L9.41 12L4.41 7L3 8.41Z"
        fill="#545556"
      />
    </svg>
  );
};

export default TabMenuSvg;
