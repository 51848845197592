import { createSlice } from '@reduxjs/toolkit';
import { UserInfo } from '../../types/interfaces/user.interfaces';
import { getLocalUser } from '../../utils/localStorageHandler';

const initialState = {
  userId: getLocalUser().userId,
  username: getLocalUser().username,
  firstName: '',
  lastName: '',
  contactPhone: '',
  verified: false,
  roles: [],
  scopes: [],
  addresses: [],
  accounts: [],
  tenantId: '',
  timezoneInfo: '',
  enabled: false,
  profileImageBlob: undefined,
  profileImageId: '',
} as UserInfo;

export const userSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    storeUserInfo: (state, action) => {
      state.userId = action.payload.userId;
      state.username = action.payload.username;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.contactPhone = action.payload.contactPhone;
      state.verified = action.payload.verified;
      state.roles = action.payload.roles;
      state.scopes = action.payload.scopes;
      state.accounts = action.payload.accounts;
      state.tenantId = action.payload.tenantId;
      state.timezoneInfo = action.payload.timezoneInfo;
      state.addresses = action.payload.addresses;
      state.enabled = action.payload.enabled;
      state.profileImageId = action.payload.profileImageId;
    },
    storeUserProfileImageBlobUrl: (state, action) => {
      state.profileImageBlob = action.payload;
    },
    updateUserAccounts: (state, action) => {
      state.accounts = action.payload.accounts;
    },
  },
});

export const { storeUserInfo, storeUserProfileImageBlobUrl, updateUserAccounts } = userSlice.actions;

export default userSlice.reducer;
