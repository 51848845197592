import { FC } from 'react';
import './loader.scss';

enum LoaderSize {
  small = '.3',
  medium = '.5',
  large = '1',
}

type LoaderProps = {
  loaderSize: keyof typeof LoaderSize;
  pageLoader?: boolean;
  appLoader?: boolean;
  simple?: boolean;
  addedSpace?: boolean;
};

const Loader: FC<LoaderProps> = ({ loaderSize, pageLoader = false, appLoader = false, addedSpace = false, simple }) => {
  return (
    <div style={addedSpace ? { margin: '2rem', padding: '2rem' } : {}}>
      <div
        className={appLoader ? 'app-loader loader' : pageLoader ? 'full-page-loader loader' : 'loader'}
        style={{ scale: LoaderSize[loaderSize] }}>
        {!simple && (
          <>
            <div className="rsg-logo"></div>
            <div className="spinner-background"></div>
            <div className="spinner-background-custom-border"></div>
          </>
        )}
        <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" transform="rotate(90)" viewBox="0 0 100 100">
          <path
            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            fill="url(#paint0_linear_2148_41143)">
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur=".875s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite"
              calcMode="spline"
              keySplines="0.3 0.7 0.85 0.25"
              keyTimes="0; 1"
            />
          </path>
          <defs>
            <linearGradient
              id="paint0_linear_2148_41143"
              x1="150.498"
              y1="163"
              x2="0.998044"
              y2="0.999992"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#84A672" />
              <stop offset="0.822917" stopColor="#D2F2C2" stopOpacity="0.75" />
              <stop offset="1" stopColor="#F7FDF3" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default Loader;
